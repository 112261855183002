import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import { goalsGenericGraphql } from './query';

export const QUERY_KEYS = {
  goalsMetric: 'goalsMetric',
};

export const useGoalsChartGql = (
  templateId: any,
  metric: string,
  startDate: string,
  endDate: string,
  selectedTeam: string,
  sprintId: string
) => {
  return useQuery(
    [
      QUERY_KEYS.goalsMetric,
      metric,
      startDate,
      endDate,
      selectedTeam,
      sprintId,
    ],
    () =>
      goalsGenericGraphql(
        templateId,
        metric,
        startDate,
        endDate,
        selectedTeam,
        sprintId
      ),
    {
      select: (data) => ({
        ...data.WorkflowAuditMetricsByType,
        data: JSON.parse(data.WorkflowAuditMetricsByType.data),
      }),
      enabled: !!metric && !!startDate && !!endDate,
    }
  );
};
