import { Box } from '@chakra-ui/react';
import { DataGrid, Paginate, PeriodType } from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchCodeVelocityGql } from '../../../graphql';
import { useCallback, useMemo, useState } from 'react';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation } from 'react-router';

interface CodeVelocityProps {
  selected: PeriodType;
}

const CodeVelocity = ({ selected }: CodeVelocityProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { pathname } = useLocation();

  const { data: codeVelocityData, isLoading } = useFetchCodeVelocityGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname.split('/')[2]),
    currentPage,
    20,
    '',
    ''
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const rows: any[] = useMemo(
    () => codeVelocityData?.data || [],
    [codeVelocityData?.data]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('repositoryName', {
        cell: (info) => info.getValue(),
        header: 'Repository',
      }),
      columnHelper.accessor('prMerge', {
        cell: (info) => info.getValue(),
        header: 'PR Merged',
        size: 70,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('prOpen', {
        cell: (info) => info.getValue(),
        header: 'PR Open',
        size: 70,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('prReview', {
        cell: (info) => info.getValue(),
        header: 'PR Reviewed',
        size: 70,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('prCycleTime', {
        cell: (info) => info.getValue(),
        header: 'PR Cycle Time(mins)',
        size: 100,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('prChanges', {
        cell: (info) => info.getValue(),
        header: 'PR Changes(Avg)',
        size: 80,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('prComments', {
        cell: (info) => info.getValue(),
        header: 'Comments per pr(Avg)',
        size: 80,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('commits', {
        cell: (info) => info.getValue(),
        header: 'Commits',
        size: 60,
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('dependencyAlert', {
        cell: (info) => info.getValue(),
        header: 'Dependency Vulnerabilities',
        size: 60,
        meta: { isNumeric: true },
      }),
    ],
    [codeVelocityData]
  );

  return (
    <Box>
      <DataGrid
        showLoader={isLoading}
        columns={columns}
        data={rows}
        maxH="24rem"
        sticky="firstCol"
      />
      <Box marginLeft="-20px">
        <Paginate
          currentPage={codeVelocityData?.currentPage + 1}
          totalPageCount={codeVelocityData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
    </Box>
  );
};

export default CodeVelocity;
