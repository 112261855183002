import styled from 'styled-components';

export const TooltipContainer = styled.div`
  padding: 10px;
  width: fit-content;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.2);
`;

export const StyledTooltipHeading = styled.p`
  font-size: 14px;
  color: #333;
`;

export const StyledToopTipText = styled.p`
  font-size: 12px;
  color: gray;
`;
