import styled from 'styled-components';

type NameTypeProps = {
  color?: string;
};

export const DetailBoxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div<NameTypeProps>`
  font-size: 10px;
  font-weight: 700;
  font-family: 'Inter';
  color: ${({ color }) => color || '#333'};
  margin-right: 8px;
  word-break: break-all;
  cursor: pointer;
  text-decoration: underline;

  &::after {
    content: attr(data-break-chars);
    display: inline-block;
    word-break: normal;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Repo = styled.div<NameTypeProps>`
  font-size: 10px;
  font-weight: 700;
  font-family: 'Inter';
  color: ${({ color }) => color || '#333'};
  margin-right: 8px;
  word-break: break-all;
`;

// MetaData Styles

type MetaDataProps = {
  color?: string;
};

type CountProps = {
  color?: string;
};

type MetaDataIconProps = {
  color?: string;
};

export const MetaData = styled.p<MetaDataProps>`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  color: ${({ color }) => color || '#777'};
`;

export const MetaDataIcon = styled.span<MetaDataIconProps>`
  font-size: 10px;
  font-weight: 700;
  margin-right: 4px;
  color: ${({ color }) => color};
`;

export const Count = styled.span<CountProps>`
  color: ${({ color }) => color || 'blue'};
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.heading};
  margin-right: 4px;
`;
