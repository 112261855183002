import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import {
  ICategoryTypeOptions,
  InvestmentCategory,
} from '../helpers/investment-categories.types';
import {
  categoryTypeOptions,
  colorPallete,
  formatOptionLabel,
} from '../helpers/investment-categories.utils';
import { CustomSelect, eliminateHtmlTags } from '@devd-client/devd/components';
import { useStrings } from '../helpers/investment-categories.strings';
import { useCustomfield } from '../../dora/apis';

interface InvestmentCategoriesFormProps {
  handleAddButtonToggle: () => void;
  handleSaveClick: (params: InvestmentCategory) => void;
}

const InvestmentCategoriesForm: FC<InvestmentCategoriesFormProps> = ({
  handleAddButtonToggle,
  handleSaveClick,
}) => {
  const strings = useStrings();
  const [selectedColor, setSelectedColor] = useState<string>('#0B698B');
  const [selectedCategory, setSelectedCategory] =
    useState<ICategoryTypeOptions>(categoryTypeOptions[0].text);
  const [categoryValue, setCategoryValue] = useState<string>('');
  const [customFieldName, setCustomFieldName] = useState<string>('');
  const [customFieldValue, setCustomFieldValue] = useState<string>('');

  const { data: customFieldData, isLoading: customFieldLoading } =
    useCustomfield();

  const onSubmit = () =>
    handleSaveClick({
      integration: 'jira',
      category: categoryValue,
      type: categoryTypeOptions
        .filter((el) => el.text === selectedCategory)
        .map((obj) => obj.value)[0],
      sqlOperator: 'equal',
      value: customFieldValue,
      field: customFieldName,
      color: selectedColor,
    });

  return (
    <Box>
      <Box
        as="div"
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        p={4}
        justifyContent={'space-between'}
      >
        <Box
          as="div"
          display="flex"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={'space-between'}
        >
          <Menu>
            <MenuButton
              bg="white"
              border="1px solid #CBD5E0"
              borderRadius="4px"
              as={Button}
              rightIcon={<BiChevronDown />}
            >
              <Box
                as="div"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                mr={2}
                borderRadius="full"
                bg={selectedColor}
                w={2}
                h={2}
              />
            </MenuButton>
            <MenuList>
              <SimpleGrid columns={5} spacing={2}>
                {colorPallete.map((color) => (
                  <MenuItem
                    key={color}
                    onClick={() => setSelectedColor(color)}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      as="div"
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                      borderRadius="full"
                      bg={color}
                      w={2}
                      h={2}
                    />
                  </MenuItem>
                ))}
              </SimpleGrid>
            </MenuList>
          </Menu>
          <Input
            ml={2}
            variant="outline"
            value={categoryValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCategoryValue(eliminateHtmlTags(e.currentTarget.value))
            }
            placeholder="Enter Category Name"
            colorScheme={'primary'}
          />
        </Box>
        <Box
          as="div"
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          gap={2}
        >
          <Button
            variant="secondary"
            onClick={handleAddButtonToggle}
            size={'sm'}
          >
            {strings.Cancel}
          </Button>
          <Button
            disabled={!customFieldValue || !categoryValue}
            variant="primary"
            size={'sm'}
            onClick={onSubmit}
          >
            {strings.Save}
          </Button>
        </Box>
      </Box>
      <Text
        fontSize="sm"
        fontFamily="heading"
        color="text.secondary"
        fontWeight="normal"
        ml={4}
        mb={2}
      >
        {strings.StartBy}
      </Text>
      <Box
        as="div"
        display="flex"
        flexDirection="row"
        alignItems={selectedCategory === 'Custom Field' ? 'flex-end' : 'center'}
        p={4}
        mb={4}
      >
        <Menu>
          <MenuButton
            fontSize="sm"
            fontFamily="heading"
            fontWeight="normal"
            bg="white"
            border="1px solid #CBD5E0"
            borderRadius="4px"
            as={Button}
            rightIcon={<BiChevronDown />}
          >
            {selectedCategory}
          </MenuButton>
          <MenuList>
            {categoryTypeOptions.map(({ value, text }) => (
              <MenuItem
                key={value}
                onClick={() => setSelectedCategory(text)}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {text}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
        {selectedCategory === 'Custom Field' ? (
          <Flex ml={4} alignItems={'flex-end'}>
            <CustomSelect
              defaultItem={{
                label:
                  customFieldData?.dto?.find(
                    (option: any) => option.value === customFieldName
                  )?.name || '',
                value: customFieldName,
              }}
              label={'Select Field Name'}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setCustomFieldName(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                customFieldData?.dto?.map((e: any) => {
                  return {
                    label: e.name,
                    value: e.value,
                  };
                })
              )}
            />
            <Input
              w={'300px'}
              ml={4}
              value={customFieldValue}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setCustomFieldValue(eliminateHtmlTags(e.currentTarget.value))
              }
              variant="outline"
              placeholder="Type Field Value"
              colorScheme={'primary'}
            />
          </Flex>
        ) : (
          <Input
            w={'300px'}
            ml={2}
            value={customFieldValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCustomFieldValue(eliminateHtmlTags(e.currentTarget.value))
            }
            variant="outline"
            placeholder="Type Field Value"
            colorScheme={'primary'}
          />
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default React.memo(InvestmentCategoriesForm);
