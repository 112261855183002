import { Loader } from '@devd-client/devd/components';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from '@devd-client/api';
import { ConfirmModal } from '@devd-client/devd/components';

export function MsTeamsInstallation() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const state = searchParams.get('state');
  const consent = searchParams.get('admin_consent');
  const tenantId = searchParams.get('tenant');
  const code = searchParams.get('code');

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [headerText, setHeaderText] = useState<string>('');
  const [bodyText, setBodyText] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (error || errorDescription) {
      setLoading(false);
      setHeaderText('Integration Failed. Contact DevDynamics Support');
      setOpenConfirmModal(true);
      return;
    }

    if (consent === 'True' && tenantId && state) {
      // Call Outlook API
      apiClient(
        `/v1/integration/Outlook/org/${localStorage.getItem('orgId')}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            name: 'meetings',
            type: 'outlook',
            active: true,
            properties: [
              {
                name: 'tenant',
                value: tenantId,
              },
              {
                name: 'state',
                value: state,
              },
            ],
          }),
        }
      )
        .then((res: any) => {
          setLoading(false);
          setHeaderText('Integration successful and active');
          setBodyText(
            'Note: It might take 15 mins to 1 hour to pull data and update metrics.'
          );
          setOpenConfirmModal(true);
        })
        .catch((err) => {
          setLoading(false);
          setHeaderText(
            err.message
              ? `${err.message}`
              : 'Integration Failed. Contact DevDynamics Support'
          );
          setOpenConfirmModal(true);
        });
    } else if (consent === 'True' && tenantId) {
      // Call MicrosoftTeams API
      apiClient(
        `/v1/integration/MicrosoftTeams/org/${localStorage.getItem('orgId')}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            name: 'im',
            type: 'msteams',
            active: true,
            properties: [
              {
                name: 'tenantId',
                value: tenantId,
              },
            ],
          }),
        }
      )
        .then((res: any) => {
          setLoading(false);
          setHeaderText('IM Integration successful and active');
          setBodyText(
            'Please add the DevDynamics bot to any team by following the steps given in the Microsoft Teams integration.'
          );
          setOpenConfirmModal(true);
        })
        .catch((err) => {
          setLoading(false);
          setHeaderText(
            err.message
              ? `${err.message}`
              : 'Integration Failed. Contact DevDynamics Support'
          );
          setOpenConfirmModal(true);
        });
    } else if (code) {
      // Call CiscoWebexMessenger API
      apiClient(
        `/v1/integration/CiscoWebexMessenger/org/${localStorage.getItem(
          'orgId'
        )}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            name: 'im',
            type: 'webex',
            active: true,
            properties: [
              {
                name: 'code',
                value: code,
              },
            ],
          }),
        }
      )
        .then((res: any) => {
          setLoading(false);
          setHeaderText('IM Integration successful and active');
          setBodyText(
            'Please add the DevDynamics bot to any team by following the steps given in the Webex integration.'
          );
          setOpenConfirmModal(true);
        })
        .catch((err) => {
          setLoading(false);
          setHeaderText(
            err.message
              ? `${err.message}`
              : 'Integration Failed. Contact DevDynamics Support'
          );
          setOpenConfirmModal(true);
        });
    } else {
      setLoading(false);
      setHeaderText('Integration Failed. Contact DevDynamics Support');
      setOpenConfirmModal(true);
    }
  }, [state, code, consent, tenantId]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {openConfirmModal && (
            <ConfirmModal
              isOpen={openConfirmModal}
              headerText={headerText}
              bodyText={bodyText}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default MsTeamsInstallation;
