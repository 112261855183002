import { Box, Flex, Image, Tooltip, useTheme } from '@chakra-ui/react';
import {
  DownloadIcon,
  IconButtonContainer,
  ISelect,
  Loader,
  PeriodType,
} from '@devd-client/devd/components';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useReportsStore } from '../../../../../../store/index';
import ListView from '../ListView';
import { apiClient } from '@devd-client/api';
import {
  useContributorRankingGql,
  useIssueBugDistGql,
} from '../../../../../../graphql/index';

const ChartHeading = styled.h2`
  font-family: 'Inter';
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
`;

interface ChartCardProps {
  selected: PeriodType;
  item: any;
  selectedTeam: string;
}

const ChartCard: FC<ChartCardProps> = ({ selected, item, selectedTeam }) => {
  const { colors } = useTheme();
  const { zeroStates } = useReportsStore();

  const { data: contRanking, isLoading } = useContributorRankingGql(
    selected.startDate,
    selected.endDate,
    selected.sprintId,
    selectedTeam
  );

  const [pending, setPending] = useState(false);

  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `${localStorage.getItem('orgId')}`);
    a.click();
  };
  const downloadCSV = async () => {
    setPending(true);
    apiClient(`/v1/csv/contributor-performance-recruit-crm`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: selected.startDate,
        endDate: selected.endDate,
        teamId: selectedTeam,
      }),
    })
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  return (
    <Box
      border=".5px solid rgba(0, 0, 0, 0.1)"
      boxShadow="0 1px 4px rgb(0 0 0 / 5%)"
      padding="12px"
      borderRadius="4px"
      height="580px"
      overflow="hidden"
      width="100%"
    >
      {item?.zeroState ? (
        zeroStates
          .filter(
            (el: { type: string; url: string }) => el.type === item.zeroState
          )
          .map((item: { type: string; url: string }) => (
            <Image
              loading="lazy"
              boxSize="100%"
              objectFit="contain"
              src={item.url}
              alt="Metric images"
            />
          ))
      ) : (
        <>
          <Box w={'100%'}>
            <Flex align="center" width="full" mb={3} justify={'space-between'}>
              <ChartHeading>Contributor Ranking</ChartHeading>

              <Tooltip hasArrow placement="bottom-start" label="Download Data">
                <IconButtonContainer onClick={downloadCSV}>
                  <DownloadIcon
                    height="16px"
                    width="16px"
                    color={colors.text.secondary}
                  />
                </IconButtonContainer>
              </Tooltip>
            </Flex>
            <ListView data={contRanking?.data} loading={isLoading} />
          </Box>
        </>
      )}
      {pending && <Loader />}
    </Box>
  );
};

export default ChartCard;
