import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FlowActionTypes } from '../reducers/flowReducer';
import {
  getIssueDistributionGraphql,
  getIssueCycleTimeGraphql,
  cycleTimeProgressGraphql,
  getAgingDistributionGraphql,
} from './query';

const QUERY_KEYS = {
  flowGql: 'flowGql',
  issueCycleTimeGql: 'issueCycleTimeGql',
  agingDistributionGql: 'agingDistributionGql',
  cycleProgress: 'cycleProgress',
};

export const useFlowGql = (
  startDate: string,
  endDate: string,
  selectedTeam: string,
  sprintId: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.flowGql, startDate, endDate, selectedTeam, sprintId],
    () =>
      getIssueDistributionGraphql(startDate, endDate, selectedTeam, sprintId),
    {
      select: (data) => data.IssueDistribution,
      enabled: !!startDate && !!endDate,
      onSuccess: (data) => {
        dispatch({
          type: FlowActionTypes.SET_CUMULATIVE_DATA,
          payload: data,
        });
      },
    }
  );
};

export const useIssueCycleTimeGql = (
  startDate: string,
  endDate: string,
  selectedTeam: string,
  sprintId: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.issueCycleTimeGql, startDate, endDate, selectedTeam, sprintId],
    () => getIssueCycleTimeGraphql(startDate, endDate, selectedTeam, sprintId),
    {
      select: (data) => data.IssueCycleTime,
      enabled: !!startDate && !!endDate,
      onSuccess: (data) => {
        dispatch({
          type: FlowActionTypes.SET_SCATTER_DATA,
          payload: data,
        });
      },
    }
  );
};

export const useAgingDistributionGql = (
  startDate: string,
  endDate: string,
  selectedTeam: string,
  sprintId: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [
      QUERY_KEYS.agingDistributionGql,
      startDate,
      endDate,
      selectedTeam,
      sprintId,
    ],
    () =>
      getAgingDistributionGraphql(startDate, endDate, selectedTeam, sprintId),
    {
      select: (data) => data.AgingDistribution,
      enabled: !!startDate && !!endDate,
      onSuccess: (data) => {
        dispatch({
          type: FlowActionTypes.SET_SCATTER_DATA,
          payload: data,
        });
      },
    }
  );
};

export const useCycleTimeProgressGql = (
  startDate: string,
  endDate: string,
  teamId: string
) => {
  return useQuery(
    [QUERY_KEYS.cycleProgress, startDate, endDate, teamId],
    () => cycleTimeProgressGraphql(startDate, endDate, teamId),
    {
      select: (data) => ({
        ...data.AllCycleTimeMetricsByType,
        data: JSON.parse(data.AllCycleTimeMetricsByType.data),
      }),
      enabled: !!startDate && !!endDate,
    }
  );
};
