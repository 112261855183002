import { defineMessage, useIntl } from 'react-intl';

import { useMemo } from 'react';

export type SignUpStrings = {
  InvestmentCategory: string;
  AddCategory: string;
  Cancel: string;
  Save: string;
  StartBy: string;
  Edit: string;
};

export const useStrings = () => {
  const investmentCategory = defineMessage({
    description: 'Investment categories',
    defaultMessage: 'Investment Categories',
  });

  const addCategory = defineMessage({
    description: 'Add Category with symbol',
    defaultMessage: 'Add Category',
  });

  const cancel = defineMessage({
    description: 'cancel',
    defaultMessage: 'Cancel',
  });

  const save = defineMessage({
    description: 'save',
    defaultMessage: 'Save',
  });

  const startBy = defineMessage({
    description:
      'Please specify the information for the tickets to include in this category',
    defaultMessage:
      'Please specify the information for the tickets to include in this category',
  });

  const edit = defineMessage({
    description: 'edit',
    defaultMessage: 'Edit',
  });

  const intl = useIntl();
  const InvestmentCategory = intl.formatMessage(investmentCategory);
  const AddCategory = intl.formatMessage(addCategory);
  const Cancel = intl.formatMessage(cancel);
  const Save = intl.formatMessage(save);
  const StartBy = intl.formatMessage(startBy);
  const Edit = intl.formatMessage(edit);

  return useMemo(
    (): SignUpStrings => ({
      InvestmentCategory,
      AddCategory,
      Cancel,
      Save,
      StartBy,
      Edit,
    }),
    []
  );
};
