import { createContext, useReducer } from 'react';
import {
  InitiativesAction,
  InitiativesReducer,
  InitiativesState,
} from '../reducers/initiativesReducer';

const initialState = {
  initiativeData: {},
  initiativeDetails: {},
  taskList: {},
  changeLog: {},
  updatesData: {},
  contributors: {},
  sprint: {},
  teams: {},
};

export const InitiativeContext = createContext<{
  state: InitiativesState;
  dispatch: React.Dispatch<InitiativesAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const InitiativesProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(InitiativesReducer, initialState);

  return (
    <InitiativeContext.Provider value={{ state, dispatch }}>
      {children}
    </InitiativeContext.Provider>
  );
};

export default InitiativesProvider;
