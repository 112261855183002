export const goToLink = (link: string) =>
  window.open(`${link}`, '_blank', 'noopener,noreferrer');

export const statusLabels = {
  ideation: 'Ideation',
  prd: 'Product Requirement Document',
  uxDesign: 'UX Design',
  techDesign: 'Tech Design',
  development: 'Development',
  qa: 'Quality Assurance',
  blocked: 'Blocked',
  deployment: 'Deployment',
} as any;

export const getKeyByValue = (
  object: Record<string, number>,
  value: number
) => {
  return Object.entries(object).find(([key, val]) => val === value)?.[0];
};
