import { MetaTags } from '@devd-client/devd/components';
import TeamDetailsPage from './devd-team-details';
import { useFetchTeamsPage } from '../api';

export const TeamDetails: React.FC = () => {
  const { data: teamsData } = useFetchTeamsPage();
  return (
    <>
      <MetaTags title="Teams | Devdynamics" />
      <TeamDetailsPage teamsData={teamsData} />
    </>
  );
};
export default TeamDetails;
