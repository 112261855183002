import { useCustomMutation } from '@devd-client/devd/components';
import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  throughput: 'throughput',
  cycleTime: 'cycleTime',
  quality: 'quality',
  contributorInsight: 'contributorInsight',
  allProjects: 'allProjects',
  customDashboards: 'customDashboards',
  customDashboardsDetails: 'customDashboardsDetails',
  dimension: 'dimension',
  measure: 'measure',
  groupBy: 'groupBy',
  customField: 'customField',
  g2AccessToken: 'g2AccessToken',
  g2UserReview: 'g2UserReview',
  shownTrialEndModal: 'shownTrialEndModal',
};

export enum MetricType {
  throughput = 'throughput',
  cycleTime = 'cycle_time',
  quality = 'quality',
}

export const useInsightThroughput = () => {
  return useQuery([QUERY_KEYS.throughput], () =>
    ENDPOINTS.getInsights(MetricType.throughput)
  );
};

export const useInsightCycleTime = () => {
  return useQuery([QUERY_KEYS.cycleTime], () =>
    ENDPOINTS.getInsights(MetricType.cycleTime)
  );
};

export const useInsightQuality = () => {
  return useQuery([QUERY_KEYS.quality], () =>
    ENDPOINTS.getInsights(MetricType.quality)
  );
};

export const useFetchContributorInsights = (dataFromSummaryItem: any) => {
  return useQuery([QUERY_KEYS.contributorInsight, dataFromSummaryItem], () =>
    ENDPOINTS.getContributorInsights(dataFromSummaryItem)
  );
};

export const useFetchRepo = (source: string) => {
  return useQuery([QUERY_KEYS.allProjects, source], () =>
    ENDPOINTS.getAllRepos(source)
  );
};

export const useGetAllCustomDashboards = () => {
  return useQuery(
    [QUERY_KEYS.customDashboards],
    ENDPOINTS.getAllCustomDashboards
  );
};
export const useGetCustomDashboardDetails = (dashboard: string) => {
  return useQuery([QUERY_KEYS.customDashboardsDetails, dashboard], () =>
    ENDPOINTS.getCustomDashboardDetails(dashboard)
  );
};

export const useCreateCustomDashboard = () =>
  useCustomMutation(
    [QUERY_KEYS.customDashboards],
    ENDPOINTS.createCustomDashboard
  );

export const useRenameCustomDashboard = () =>
  useCustomMutation(
    [QUERY_KEYS.customDashboards],
    ENDPOINTS.renameCustomDashboard
  );

export const useDeleteCustomDashboard = () =>
  useCustomMutation(
    [QUERY_KEYS.customDashboards],
    ENDPOINTS.deleteCustomDashboard
  );

export const useUpdateCustomDashboard = () =>
  useCustomMutation(
    [QUERY_KEYS.customDashboards],
    ENDPOINTS.updateCustomDashboard
  );
export const useMeasure = () => {
  return useQuery([QUERY_KEYS.measure], ENDPOINTS.getMeasure);
};

export const useDimension = (entity: string) => {
  return useQuery(
    [QUERY_KEYS.dimension, entity],
    () => ENDPOINTS.getDimension(entity),
    {
      enabled: !!entity,
    }
  );
};

export const useGroupBy = (entity: string) => {
  return useQuery(
    [QUERY_KEYS.groupBy, entity],
    () => ENDPOINTS.getGroupBy(entity),
    {
      enabled: !!entity,
    }
  );
};

export const useCustomfield = () => {
  return useQuery([QUERY_KEYS.customField], ENDPOINTS.getCustomField);
};

export const useGetG2AccessToken = (email: string, orgId: string) => {
  return useQuery([QUERY_KEYS.g2AccessToken], () =>
    ENDPOINTS.getG2AccessToken(email, orgId)
  );
};

export const useG2UserReview = () => {
  return useCustomMutation([QUERY_KEYS.g2UserReview], ENDPOINTS.g2UserReview);
};

export const useShownTrialEndModal = () =>
  useCustomMutation(
    [QUERY_KEYS.shownTrialEndModal],
    ENDPOINTS.shownTrialEndModal
  );
