import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { eliminateHtmlTags } from '@devd-client/devd/components';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';
import { updatesModalStatusOptions } from '../../../../helpers/Initiatives.utils';

interface UpdateButtonProps {
  onSuccessAction: () => void;
  headerText: string;
  buttonText: string;
  selected: string;
  setSelected: (selected: string) => void;
  summary: string;
  setSummary: (summary: string) => void;
}

const UpdateModal = ({
  onSuccessAction,
  buttonText,
  headerText,
  selected,
  setSelected,
  summary,
  setSummary,
}: UpdateButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = () => {
    onSuccessAction();
    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        variant={'primary'}
        size="sm"
        leftIcon={<AiOutlinePlus />}
      >
        {buttonText}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily="Inter">{headerText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontFamily="Manrope">
              <Textarea
                w={'100%'}
                mb={4}
                value={summary}
                onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setSummary(eliminateHtmlTags(e.currentTarget.value))
                }
                variant="outline"
                placeholder="Type summary"
                colorScheme={'primary'}
              />
            </Box>
            <Box>
              <Menu>
                <MenuButton as={Button} rightIcon={<BiChevronDown />}>
                  <Text as="p" fontSize={'16px'} color={'gray.700'}>
                    {selected || 'Select'}
                  </Text>
                </MenuButton>
                <MenuList>
                  {updatesModalStatusOptions.map(({ value, text }) => (
                    <MenuItem
                      key={value}
                      value={selected}
                      onClick={() => setSelected(value)}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Text as="p" fontSize={'16px'} color={'gray.700'}>
                        {text}
                      </Text>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary" size="sm" onClick={onClose} mr={3}>
              Close
            </Button>
            <Button variant="filled" size="sm" onClick={onSubmit}>
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateModal;
