export function Workflow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.96875 12.0393H3.06312"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.7402 12.0393H15.0315"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.45703 12.0393H9.34641"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.874 10.8425L15.0309 12.0394L13.874 13.2363"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.06348 12.0394C3.06348 12.3568 3.18958 12.6613 3.41403 12.8857C3.63849 13.1102 3.94292 13.2363 4.26035 13.2363C4.57778 13.2363 4.88221 13.1102 5.10667 12.8857C5.33113 12.6613 5.45723 12.3568 5.45723 12.0394C5.45723 11.8822 5.42627 11.7266 5.36612 11.5814C5.30597 11.4362 5.21781 11.3042 5.10667 11.1931C4.99553 11.0819 4.86359 10.9938 4.71838 10.9336C4.57316 10.8735 4.41753 10.8425 4.26035 10.8425C4.10318 10.8425 3.94754 10.8735 3.80233 10.9336C3.65712 10.9938 3.52517 11.0819 3.41403 11.1931C3.30289 11.3042 3.21473 11.4362 3.15458 11.5814C3.09443 11.7266 3.06348 11.8822 3.06348 12.0394Z"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.34668 12.0394C9.34668 12.1966 9.37764 12.3522 9.43779 12.4974C9.49794 12.6426 9.5861 12.7746 9.69724 12.8857C9.80838 12.9969 9.94032 13.085 10.0855 13.1452C10.2307 13.2053 10.3864 13.2363 10.5436 13.2363C10.7007 13.2363 10.8564 13.2053 11.0016 13.1452C11.1468 13.085 11.2787 12.9969 11.3899 12.8857C11.501 12.7746 11.5892 12.6426 11.6493 12.4974C11.7095 12.3522 11.7404 12.1966 11.7404 12.0394C11.7404 11.8822 11.7095 11.7266 11.6493 11.5814C11.5892 11.4362 11.501 11.3042 11.3899 11.1931C11.2787 11.0819 11.1468 10.9938 11.0016 10.9336C10.8564 10.8735 10.7007 10.8425 10.5436 10.8425C10.3864 10.8425 10.2307 10.8735 10.0855 10.9336C9.94032 10.9938 9.80838 11.0819 9.69724 11.1931C9.5861 11.3042 9.49794 11.4362 9.43779 11.5814C9.37764 11.7266 9.34668 11.8822 9.34668 12.0394Z"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.543 10.8426V3.06323"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.25977 10.8424V2.76367"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.25977 2.76367L7.85039 5.11117L4.25977 7.06805"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.543 3.06323L14.1336 5.41073L10.543 7.36698"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Workflow;
