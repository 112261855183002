export function DownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 10 12"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 0.666687V11.3334M5 11.3334L9 7.33335M5 11.3334L1 7.33335"
        stroke={props.color}
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DownArrowIcon;
