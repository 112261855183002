import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import React, { FC } from 'react';
import * as Yup from 'yup';
import { useCreateCustomDashboard } from '../../../api';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../../api';
import {
  Loader,
  useToastHook,
  eliminateSpecialCharacters,
} from '@devd-client/devd/components';
import { useNavigate } from 'react-router-dom';

interface CreateDashboardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateDashboardModal: FC<CreateDashboardModalProps> = ({
  onClose,
  isOpen,
}) => {
  const queryClient = useQueryClient();
  const [newToast] = useToastHook();
  const { mutate: createCustomDashboard } = useCreateCustomDashboard();
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    dashboardName: Yup.string()
      .required('Dashboard name is required')
      .trim()
      .matches(/^[a-zA-Z0-9 ]*$/, 'No special characters allowed')
      .min(3, 'Dashboard name must be at least 3 characters long')
      .max(25, 'Dashboard name must be at under 25 characters'),
  });

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Dashboard</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ dashboardName: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              name: eliminateSpecialCharacters(
                values.dashboardName.trimStart()
              ),
              orgId: localStorage.getItem('orgId'),
              scope: 'org',
              layout: {
                grid: {
                  columns: null,
                  rows: null,
                },
                metrics: [],
              },
            };

            if (!values.dashboardName) {
              setSubmitting(false);
              newToast({
                message: 'Dasboard name is mendatory',
                status: 'error',
              });
            }

            createCustomDashboard(payload, {
              onSuccess() {
                newToast({
                  message: 'Created new custom dashboard',
                  status: 'success',
                });
                queryClient.invalidateQueries([QUERY_KEYS.customDashboards]);
                setSubmitting(false);
                setTimeout(() => {
                  onClose();
                  navigate(`/dashboard/custom-dashboard?id=${payload.name}`);
                }, 10);
              },
              onError: (err: any) => {
                newToast({
                  message: err.message ? err.message : 'Something went wrong.',
                  status: 'error',
                });
                setSubmitting(false);
                setTimeout(() => {
                  onClose();
                }, 10);
              },
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <Field name="dashboardName">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.dashboardName && form.touched.dashboardName
                      }
                    >
                      <FormLabel>Dashboard Name</FormLabel>
                      <Input {...field} placeholder="Enter dashboard name" />
                      <FormErrorMessage>
                        {form.errors.dashboardName}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>

              <ModalFooter>
                <Button mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button type="submit" variant="filled" isLoading={isSubmitting}>
                  Create
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CreateDashboardModal;
