import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;
`;

export const Element = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  background-color: #fff;
  min-height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: -1px;
`;
