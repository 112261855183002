import { apiClient } from '@devd-client/api';
import { parseLLMResponseData } from '../helpers/home.utils';

export const getInsights = async (metricType: string): Promise<any> => {
  try {
    const res = await fetch(
      `https://api.devdynamics.ai/app/gpt/insights/org/${localStorage.getItem(
        'orgId'
      )}/metricType/${metricType}`,
      {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    return res.json();
  } catch (error) {
    throw new Error('Error in fetching insights.');
  }
};
export const getContributorInsights = async (
  dataFromSummaryItem: any
): Promise<any> => {
  const requestBody = JSON.stringify(dataFromSummaryItem);
  const currentParams = new URLSearchParams(window.location.search);
  const params = new URLSearchParams({
    start_date: currentParams.get('start') || '',
    end_date: currentParams.get('end') || '',
    team: currentParams.get('team') || '',
  });
  try {
    const res = await fetch(
      `https://api.devdynamics.ai/app/gpt/metric/tooltip?` + params,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: requestBody,
      }
    );
    const data = await res.text();
    return parseLLMResponseData(data);
  } catch (error) {
    throw new Error('Error in fetching insights.');
  }
};
export const getAllRepos = (source: string): Promise<any> =>
  apiClient(
    `/v1/integration/${source}/org/${localStorage.getItem(
      'orgId'
    )}/projects/lite?active=true`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllCustomDashboards = (): Promise<any> =>
  apiClient(`/v1/custom-dashboard/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getCustomDashboardDetails = (dashboard: string): Promise<any> =>
  apiClient(
    `/v1/custom-dashboard/${localStorage.getItem('orgId')}/${dashboard}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const createCustomDashboard = (params: any): Promise<any> =>
  apiClient(`/v1/custom-dashboard/${localStorage.getItem('orgId')}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const renameCustomDashboard = (params: any): Promise<any> =>
  apiClient(`/v1/custom-dashboard/${localStorage.getItem('orgId')}/rename`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const deleteCustomDashboard = (params: any): Promise<any> =>
  apiClient(
    `/v1/custom-dashboard/${localStorage.getItem('orgId')}/${params.dashboard}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const updateCustomDashboard = (params: any): Promise<any> =>
  apiClient(`/v1/custom-dashboard/${localStorage.getItem('orgId')}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const getDimension = (entity: string): Promise<any> =>
  apiClient(`/v1/metrics-metadata?type=dimension&entity=${entity}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getMeasure = (): Promise<any> =>
  apiClient(`/v1/metrics-metadata?type=measure`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getGroupBy = (entity: string): Promise<any> =>
  apiClient(`/v1/metrics-metadata?type=groupby&entity=${entity}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getCustomField = (): Promise<any> =>
  apiClient(
    `/v1/integration/org/${localStorage.getItem('orgId')}/customFields`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getG2AccessToken = (email: string, orgId: string): Promise<any> =>
  apiClient(`/v1/user/org/${orgId}?email=${email}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const g2UserReview = (params: any): Promise<any> =>
  apiClient(`/v1/user/org/${params.orgId}`, {
    method: 'PUT',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: params.userId,
      orgCode: params.orgId,
      emailId: params.email,
    }),
  });

export const shownTrialEndModal = (params: any): Promise<any> =>
  apiClient(`/v1/user/${params.id}/preference/displayUpgrade`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      displayed: params.displayed,
    }),
  });
