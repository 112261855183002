import { useEffect } from 'react';
import { usePageViews } from '../hooks/usePageViews';
import AppRoutes from '../routes';
import { useNavigate } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import Fallback from '../screens/Status/Fallback';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  usePageViews();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the app is loaded within an iframe
    if (window !== window.top && window.top !== null) {
      // App is loaded within an iframe, take appropriate action (e.g., redirect, show an error message, etc.)
      // For example, you can redirect to another page or show an error message
      navigate('/error');
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <HelmetProvider>
        <AppRoutes />
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;
