import { Icon } from '@chakra-ui/react';
export function StackIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.122 0.392201C7.66498 0.0772741 8.33503 0.0772741 8.87801 0.392201L13.8806 3.29369C14.711 3.77536 14.711 4.9746 13.8806 5.45627L8.87801 8.35775C8.33503 8.67268 7.66498 8.67268 7.122 8.35775L2.11943 5.45627C1.28896 4.97459 1.28896 3.77536 2.11943 3.29369L7.122 0.392201ZM8.12544 1.68975C8.04787 1.64476 7.95215 1.64476 7.87458 1.68975L3.24487 4.37498L7.87458 7.06021C7.95215 7.1052 8.04787 7.1052 8.12544 7.06021L12.7551 4.37498L8.12544 1.68975ZM1.60123 7.78869C1.80905 7.43038 2.26799 7.30839 2.62629 7.5162L7.87458 10.5602C7.95214 10.6052 8.04787 10.6052 8.12544 10.5602L13.3737 7.5162C13.732 7.30839 14.191 7.43038 14.3988 7.78869C14.6066 8.147 14.4846 8.60593 14.1263 8.81375L8.87801 11.8578C8.33503 12.1727 7.66498 12.1727 7.122 11.8578L1.87372 8.81375C1.51541 8.60593 1.39341 8.147 1.60123 7.78869ZM1.60123 11.2887C1.80905 10.9304 2.26799 10.8084 2.62629 11.0162L7.87458 14.0602C7.95214 14.1052 8.04787 14.1052 8.12544 14.0602L13.3737 11.0162C13.732 10.8084 14.191 10.9304 14.3988 11.2887C14.6066 11.647 14.4846 12.1059 14.1263 12.3138L8.87801 15.3578C8.33503 15.6727 7.66498 15.6727 7.122 15.3578L1.87372 12.3138C1.51541 12.1059 1.39341 11.647 1.60123 11.2887Z"
        fill={props.color || '#1F2328'}
      />
    </svg>
  );
}
