import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { GoalsContext } from '../../context/goals.context';
import { FieldType } from '../../helpers/goals.types';
import { GoalsActionTypes } from '../../reducer/goals.reducer';
import GoalCards from './GoalCards';

const LeftSectionContainer = styled.div`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

interface LeftSectionProps {
  selectedGoal: string;
  setSelectedGoal: (goal: string) => void;
}

const LeftSection: FC<LeftSectionProps> = ({
  selectedGoal,
  setSelectedGoal,
}) => {
  const {
    state: { goalsList },
    dispatch,
  } = useContext(GoalsContext);

  const [searchParams] = useSearchParams();
  const goal = searchParams.get('goal');
  useEffect(() => {
    if (goalsList && goalsList.length === 0) return;
    if (!goal) setSelectedGoal(goalsList[0]?.name);
  }, [goalsList[0]?.name, goal]);

  useEffect(() => {
    dispatch({
      type: GoalsActionTypes.SET_CLICKED_GOAL,
      payload: goalsList
        .filter((goal: any) => goal.name === selectedGoal)
        .map((el: any) => el)[0],
    });
  }, [selectedGoal, goalsList, dispatch]);

  const activeGoals = useMemo(
    () => goalsList.filter((goal: any) => goal.enabled === true),
    [goalsList]
  );

  const otherGoals = useMemo(
    () => goalsList.filter((goal: any) => goal.enabled === false),
    [goalsList]
  );

  return (
    <LeftSectionContainer>
      {activeGoals.length > 0 && (
        <GoalCards
          goalHeader={'Active agreements'}
          goalsList={activeGoals}
          selectedGoal={selectedGoal}
          setSelectedGoal={setSelectedGoal}
        />
      )}
      <GoalCards
        goalHeader={
          otherGoals.length > 0
            ? 'Inactive agreements'
            : 'No Inactive agreement'
        }
        goalsList={otherGoals}
        selectedGoal={selectedGoal}
        setSelectedGoal={setSelectedGoal}
      />
    </LeftSectionContainer>
  );
};

export default LeftSection;
