import {
  Box,
  Button,
  ChakraStyledOptions,
  Collapse,
  ComponentWithAs,
  Flex,
  Icon,
  IconProps,
  Text,
  useOutsideClick,
  useTheme,
} from '@chakra-ui/react';

import React, { Children, useRef, useState } from 'react';

import { keyframes } from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';

export const fadeIn = keyframes({
  from: {
    transform: 'translateY(200px)',
    opacity: 0,
  },
  to: {
    transform: 'translateY(0)',
    opacity: 1,
  },
});

export const fadeOut = keyframes({
  from: {
    transform: 'translateY(0)',
    opacity: 1,
  },
  to: {
    transform: 'translateY(-20px)',
    opacity: 0,
  },
});

const collapseStyles: ChakraStyledOptions = {
  pt: 2,
  pb: 2,
  pl: 5,
  h: 'full',
  lineHeight: 1.5,
  borderRadius: 'md',
  outline: 'none',
  alignItems: 'center',
  _hover: {
    borderColor: 'foa.black',
  },
  _focus: {
    borderColor: 'grey.300',
    boxShadow: 'inputBoxShadow',
  },
  _disabled: {
    cursor: 'not-allowed',
    color: 'gray.300',
    borderColor: 'gray.200',
  },
  px: 5,
  border: 'none',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
};

export type collapseProps = ChakraStyledOptions & {
  label: string;
  isDisabled?: boolean;
  icon?: ComponentWithAs<'svg', IconProps>;
  LabelIcon?: any;
  hasIntegrated?: boolean;
  children?: React.ReactNode;
};

export const CustomCollapse = ({
  label,
  LabelIcon,
  hasIntegrated,
  icon,
  isDisabled = false,
  children,
  ...props
}: collapseProps) => {
  const { colors } = useTheme();
  const [show, setShow] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [animIn, setAnim] = useState<boolean>(false);
  const ref = useRef(null);
  const handleToggle = () => {
    setAnim(!animIn);
    if (show) {
      setTimeout(() => {
        setShow(!show);
      }, 300);
    } else {
      setShow(!show);
    }
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      setAnim(false);
      setTimeout(() => {
        setShow(false);
      }, 300);
    },
  });

  const handleClose = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      setAnim(false);
      setTimeout(() => {
        setShow(false);
      }, 300);
    }
  };

  return (
    <Box
      ref={ref}
      width="100%"
      borderBlock="1px solid"
      borderColor="gray.200"
      height="max-content"
      position="relative"
      border="none"
      {...props}
    >
      <Button
        width="100%"
        border={'none'}
        isDisabled={isDisabled || disabled}
        sx={{
          p: 0,
          bg: 'white',
          cursor: 'inherit',
          opacity: 1,
          position: 'relative',
          borderRadius: 0,
        }}
        onKeyDown={(e: unknown) => handleClose(e as KeyboardEvent)}
        onClick={() => {
          setDisabled(true);
          handleToggle();
        }}
      >
        <Box sx={collapseStyles}>
          <Flex align="center">
            {LabelIcon && (
              <LabelIcon
                height="16px"
                width="16px"
                color={
                  hasIntegrated
                    ? '#1DA44A'
                    : show
                    ? colors.text.primary
                    : colors.text.secondary
                }
              />
            )}
            <Text
              ml={1}
              color={
                hasIntegrated
                  ? '#1DA44A'
                  : show
                  ? 'text.primary'
                  : 'text.secondary2'
              }
              fontFamily="heading"
              fontWeight="medium"
            >
              {label}
            </Text>
          </Flex>

          {icon ? (
            <Icon
              as={icon}
              boxSize={5}
              transition={'all 0.2s linear'}
              transform={show ? 'rotate(180deg)' : 'rotate(0deg)'}
            />
          ) : (
            <Icon
              as={MdKeyboardArrowDown}
              boxSize={5}
              transition={'all 0.2s linear'}
              transform={show ? 'rotate(0deg)' : 'rotate(-90deg)'}
            />
          )}
        </Box>
      </Button>
      <Collapse
        onAnimationStartCapture={() => {
          setDisabled(true);
        }}
        onAnimationComplete={() => {
          setDisabled(false);
        }}
        startingHeight={0}
        in={show}
        onKeyDown={(e: unknown) => handleClose(e as KeyboardEvent)}
      >
        <Box sx={{ padding: 4 }}>
          {children &&
            Children.map(children, (child, index) => {
              return (
                <Box
                  as="span"
                  display="block"
                  sx={{
                    opacity: show ? 0 : 1,
                    animation: `${
                      animIn ? fadeIn : fadeOut
                    } 0.5s cubic-bezier(0,.64,.63,1) forwards`,
                    animationDelay: `${animIn ? index * 0.2 : 0}s`,
                  }}
                >
                  {child}
                </Box>
              );
            })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CustomCollapse;
