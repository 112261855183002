import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { BookMarkIcon, CrossIcon } from '../icons';
import { isHTML } from '../shared/utils';
import ReactHtmlParser from 'html-react-parser';
import { useSidebarStore } from '../hooks/useSidebarStore';

type Props = {
  appState: any;
};

export const Banner = ({ appState }: Props) => {
  const { hideBanner, setHideBanner } = useSidebarStore();

  return hideBanner ? (
    <></>
  ) : (
    <Box p={4} mb={2} bg={'#D1EAD9'} borderRadius="8px">
      <Flex justify={'space-between'} mb={1} align={'flex-start'}>
        <Flex align="center" gap={1}>
          {appState?.user?.dto?.announcement?.icon === 'bookmark' && (
            <BookMarkIcon color={'#1DA44A'} />
          )}

          <Text fontWeight="medium" color="#1DA44A">
            {appState?.user?.dto?.announcement?.title}
          </Text>
        </Flex>

        <IconButton
          size="xs"
          isRound
          bg="transparent"
          aria-label="cross"
          icon={<CrossIcon color="black" />}
          onClick={() => setHideBanner(true)}
        />
      </Flex>

      {appState?.user?.dto?.announcement &&
      isHTML(appState?.user?.dto?.announcement.description) ? (
        <Box>
          {ReactHtmlParser(appState?.user?.dto?.announcement.description)}
        </Box>
      ) : (
        <Text>{appState?.user?.dto?.announcement.description}</Text>
      )}
    </Box>
  );
};
