import { Box, Flex, SimpleGrid, Text, Tooltip } from '@chakra-ui/react';
import { InfoCircle } from '@devd-client/devd/components';
import OverviewCard from './OverviewCard';

const overviewCardCategories = [
  'VELOCITY',
  'QUALITY',
  'DORA',
  'INVESTMENT_DISTRIBUTION',
];

const MetricsOverview = () => {
  return (
    <Flex my={6} w="full" direction="column">
      <Flex w="full" align="center" justify="space-between">
        <Flex align="center">
          <Text mr={1} fontFamily="heading" fontSize="xl" fontWeight="semibold">
            Metrics Overview
          </Text>
          <Tooltip
            hasArrow
            aria-label="Metrics Overview"
            label="All your important metrics in one view. Click on view details to explore deeper insights."
          >
            <Box>
              <InfoCircle />
            </Box>
          </Tooltip>
        </Flex>
      </Flex>

      <SimpleGrid
        mt={4}
        width="full"
        gridTemplateColumns={'repeat(2, 1fr)'}
        minChildWidth="400px"
        spacing={4}
        mb={4}
      >
        {overviewCardCategories.map((item: string, index: number) => (
          <OverviewCard category={item} key={index} />
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default MetricsOverview;
