import { Box, SimpleGrid } from '@chakra-ui/react';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import {
  MetricDetails,
  MetricsCard,
  PageHeader,
  PageToolbar,
  TeamMenu,
  ISelect,
  PeriodType,
  durationData,
  useQueryState,
  removeEmptyQueryParams,
  DateFilter,
} from '@devd-client/devd/components';

import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { QUERY_KEYS } from '../../api';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';

interface DoraPageProps {
  metricsData: any;
}

const DoraPage: FC<DoraPageProps> = ({ metricsData }) => {
  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');
  const helpDocLink = metricsData?.dto?.helpDoc;

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [appState, dispatch] = useAuth();
  const { selected, selectedTeam, setSelected, setSelectedTeam } =
    useDashboardStore();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const doraMetrics = metricsData?.dto?.metrics;

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!metricType ? (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Box>
            <PageHeader
              headingText={metricsData?.dto?.name?.toUpperCase()}
              mb={'10px'}
              helpDocLink={helpDocLink}
            />
            <PageToolbar
              leftComp={
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />
          </Box>

          <SimpleGrid columns={{ base: 2, '2xl': 2 }} spacing={6} mt={6}>
            {doraMetrics.map((type: string) => (
              <MetricsCard
                key={type}
                metricType={type}
                selected={selected}
                selectedTeam={selectedTeam.value}
                queryKey={QUERY_KEYS.doraPage}
              />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <MetricDetails />
      )}
    </>
  );
};

export default DoraPage;
