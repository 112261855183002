import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getInvestmentReportDayGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  aggInterval: string,
  aggType: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInvestmentReport(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $aggInterval: String!
        $aggType: String!
        $teamId: String!
      ) {
        InvestmentReport(
          orgId: $orgId
          startDate: $startDate
          endDate: $endDate
          sprintId: $sprintId
          aggInterval: $aggInterval
          aggType: $aggType
          teamId: $teamId
        ) {
          itemMeta {
            label
            fillColor
          }
          columns {
            name
            rows {
              name
              value
              dist
            }
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      aggInterval,
      aggType,
      teamId,
    }
  );

export const getInvestmentReportWeekGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  aggInterval: string,
  aggType: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInvestmentReport(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $aggInterval: String!
        $aggType: String!
        $teamId: String!
      ) {
        InvestmentReport(
          orgId: $orgId
          startDate: $startDate
          endDate: $endDate
          sprintId: $sprintId
          aggInterval: $aggInterval
          aggType: $aggType
          teamId: $teamId
        ) {
          itemMeta {
            label
            fillColor
            teamId
          }
          columns {
            name
            rows {
              name
              value
              dist
            }
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      aggInterval,
      aggType,
      teamId,
    }
  );

export const getInvestmentReportOverallGraphql = async (
  startDate: string,
  endDate: string,
  aggInterval: string,
  aggType: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInvestmentReport(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $aggInterval: String!
        $aggType: String!
        $teamId: String!
      ) {
        InvestmentReport(
          orgId: $orgId
          startDate: $startDate
          endDate: $endDate
          aggInterval: $aggInterval
          aggType: $aggType
          teamId: $teamId
        ) {
          columns {
            name
            rows {
              name
              value
              dist
            }
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      aggInterval,
      aggType,
      teamId,
    }
  );
export const getDayTicketReportGraphql = async (
  endDate: string,
  sprintId: string,
  pageNumber: number,
  pageSize: number,
  category: string,
  aggType: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInvestmentReportDetail(
        $orgId: String!
        $endDate: String!
        $sprintId: String!
        $pageNumber: Int!
        $pageSize: Int!
        $category: String
        $aggType: String!
        $teamId: String!
      ) {
        InvestmentReportDetail(
          orgId: $orgId
          endDate: $endDate
          sprintId: $sprintId
          pageNumber: $pageNumber
          pageSize: $pageSize
          category: $category
          aggType: $aggType
          teamId: $teamId
        ) {
          totalPages
          currentPage
          data {
            summary
            assignee
            ticketType
            ticketUrl
            updatedDate
            key
            investment
            estimate
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      endDate,
      pageNumber: pageNumber - 1,
      pageSize,
      category,
      aggType,
      teamId,
      sprintId,
    }
  );
