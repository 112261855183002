import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getGitWorkLogFromGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  pageSize: number,
  pageNumber: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetWorkLog($filter: Filter!, $pageNumber: Int, $pageSize: Int) {
        GitWorklog(
          filter: $filter
          pageSize: $pageSize
          pageNumber: $pageNumber
        ) {
          itemMeta {
            label
            fillColor
          }
          rows {
            name

            breakdownList {
              name
              value
            }
            properties {
              name
              value
            }
            columns {
              date
              items {
                children {
                  count
                  label
                  fillColor
                }
              }
            }
          }
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate,
        endDate,
        teamId,
      },
      pageSize,
      pageNumber,
    }
  );

export const getIssuesWorkLogFromGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  pageSize: number,
  pageNumber: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetJiraWorkLog($filter: Filter!, $pageSize: Int, $pageNumber: Int) {
        JiraWorklog(
          filter: $filter
          pageSize: $pageSize
          pageNumber: $pageNumber
        ) {
          itemMeta {
            label
            fillColor
          }
          rows {
            name
            properties {
              name
              value
            }
            columns {
              date
              items {
                children {
                  count
                  label
                  fillColor
                }
              }
            }
          }
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate,
        endDate,
        teamId,
      },
      pageSize,
      pageNumber,
    }
  );

export const getAuthorWorkLogFromGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  authors: string[],
  pageSize: number,
  pageNumber: number,
  granularity = 'DAY',
  view = 'activity'
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAuthorWorkLog(
        $filter: Filter!
        $granularity: String!
        $pageSize: Int
        $pageNumber: Int
      ) {
        AuthorWorklog(
          filter: $filter
          granularity: $granularity
          pageSize: $pageSize
          pageNumber: $pageNumber
        ) {
          itemMeta {
            label
            fillColor
          }
          rows {
            name
            properties {
              name
              value
            }
            columns {
              date
              items {
                children {
                  count
                  label
                  fillColor
                }
              }
            }
            activeDays {
              days
              isBurnOut
              insight
            }
          }
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        authors: authors,
        teamId: authors.length === 0 ? teamId : '',
        view,
      },
      pageSize,
      pageNumber,
      granularity,
    }
  );

export const getWorkLogDetails = async (
  type: string,
  date: string,
  name: string,
  dataType: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetWorkLogDetails(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $name: String!
        $dataType: String!
        $type: String!
        $teamId: String!
        $timeZone: String
      ) {
        WorklogDetails(
          orgId: $orgId
          startDate: $startDate
          endDate: $endDate
          name: $name
          dataType: $dataType
          type: $type
          teamId: $teamId
          timeZone: $timeZone
        ) {
          id
          assignee
          description
          link
          activityDate
          project
          branch
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      type,
      startDate: date,
      endDate: date,
      name,
      dataType,
      teamId,
    }
  );
