import { FC } from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';

interface ShowFieldsProps {
  clickedGoal: any;
}

const ShowFields: FC<ShowFieldsProps> = ({ clickedGoal }) => {
  return (
    <Flex flexWrap="wrap" gap={6}>
      {clickedGoal?.fields?.map((item: any, index: number) => (
        <Stack spacing={0} key={index}>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
          >
            {item.field}
          </Text>
          {Object.entries(clickedGoal?.fieldsValue)?.map(
            ([key, val]: any, index: number) => (
              <Text
                key={index}
                fontFamily="heading"
                fontSize="sm"
                fontWeight="normal"
                color="text.primary"
              >
                {key === item.key && val}
              </Text>
            )
          )}
        </Stack>
      ))}
    </Flex>
  );
};

export default ShowFields;
