export function DashboardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.7938 6.94531H9.57952C9.28859 6.94531 9.05273 7.18116 9.05273 7.4721V13.7935C9.05273 14.0844 9.28859 14.3203 9.57952 14.3203H13.7938C14.0847 14.3203 14.3206 14.0844 14.3206 13.7935V7.4721C14.3206 7.18116 14.0847 6.94531 13.7938 6.94531Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.7938 1.67773H9.57952C9.28859 1.67773 9.05273 1.91359 9.05273 2.20452V4.3222C9.05273 4.61313 9.28859 4.84898 9.57952 4.84898H13.7938C14.0847 4.84898 14.3206 4.61313 14.3206 4.3222V2.20452C14.3206 1.91359 14.0847 1.67773 13.7938 1.67773Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.41881 1.67773H2.20452C1.91359 1.67773 1.67773 1.91359 1.67773 2.20452V8.52595C1.67773 8.81688 1.91359 9.05273 2.20452 9.05273H6.41881C6.70974 9.05273 6.94559 8.81688 6.94559 8.52595V2.20452C6.94559 1.91359 6.70974 1.67773 6.41881 1.67773Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.41881 11.1494H2.20452C1.91359 11.1494 1.67773 11.3852 1.67773 11.6762V13.7939C1.67773 14.0848 1.91359 14.3207 2.20452 14.3207H6.41881C6.70974 14.3207 6.94559 14.0848 6.94559 13.7939V11.6762C6.94559 11.3852 6.70974 11.1494 6.41881 11.1494Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
