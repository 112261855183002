import { Box, Button, Flex, Icon, Switch, Tooltip } from '@chakra-ui/react';
import Loader from '../../appLoader';
import { FC, useMemo, useState } from 'react';
import { QUERY_KEYS, useAddUpdateProjectsMutation, useTableView } from '../api';
import { createColumnHelper } from '@tanstack/react-table';
import { FaPlus, FaSync } from 'react-icons/fa';
import { goToLink } from '../helpers/integrationDetails.utils';
import { DataGrid } from '../../GridTable/DataGrid';
import { useToastHook } from '../../appToast';
import { Setting } from '../../icons';
import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import IntegrationModal from '../IntegrationModal';
import ConfirmModal from '../confirmModal/confirmModal';

interface TableViewProps {
  intDetailsData: any;
  openIntegrationModal: boolean;
  handleIntegrateModalOpen: () => void;
  handleIntegrateModalClose: () => void;
  openConfirmModal: boolean;
  handleConfirmModalOpen: () => void;
  headerText: string;
  bodyText: string;
}

export const TableView: FC<TableViewProps> = ({
  intDetailsData,
  openIntegrationModal,
  handleIntegrateModalOpen,
  handleIntegrateModalClose,
  openConfirmModal,
  handleConfirmModalOpen,
  headerText,
  bodyText,
}) => {
  const integrationId = intDetailsData?.dto?.name;
  const { data, isLoading } = useTableView(integrationId);
  const [Loading, setIsLoading] = useState(false);
  const [newToast] = useToastHook();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: handleRepoState, isLoading: setRepoStateLoading } =
    useAddUpdateProjectsMutation();

  const markRepoState = (repoName: any, flag: boolean) => {
    handleRepoState(
      {
        integrationId: integrationId,
        data: {
          id: repoName.key.id,
          active: flag,
        },
      },
      {
        onSuccess: () => {
          newToast({
            message:
              'Repo status updated. Changes will reflect in upcoming data.',
            status: 'success',
          });
          queryClient.invalidateQueries([QUERY_KEYS.tableView]);
        },
        onError: (err: Error | undefined | unknown) => {
          newToast({
            message: (err as Error).message || 'Something went wrong!',
            status: 'error',
          });
        },
      }
    );
  };

  const rows: any[] = useMemo(() => data?.dto || [], [data?.dto]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Repository Name',
      }),
      columnHelper.accessor('active', {
        cell: (info) => {
          return (
            <>
              <Tooltip
                label={info.getValue() ? 'Disable tracking' : 'Enable Tracking'}
                hasArrow
                placement="bottom-end"
              >
                <Box p={2} width={'fit-content'}>
                  <Switch
                    id="bot"
                    isChecked={info.getValue()}
                    onChange={() => {
                      markRepoState(info?.row?.original, !info.getValue());
                    }}
                    isDisabled={setRepoStateLoading}
                  />
                </Box>
              </Tooltip>
            </>
          );
        },
        header: 'Active',
      }),
      columnHelper.accessor('', {
        cell: (info) => (
          <Setting
            color="gray"
            cursor={'pointer'}
            onClick={() =>
              navigate(
                `/settings/integrations/${intDetailsData?.dto?.displayName}/${info?.row?.original?.name}/${info?.row?.original?.key?.id}`
              )
            }
          />
        ),
        header: 'Settings',
      }),
    ],
    [data?.dto]
  );

  return (
    <>
      {openIntegrationModal && (
        <IntegrationModal
          isOpen={openIntegrationModal}
          handleIntegrateModalClose={handleIntegrateModalClose}
          intDetailsData={intDetailsData}
          handleConfirmModalOpen={handleConfirmModalOpen}
        />
      )}
      {openIntegrationModal === false && openConfirmModal && (
        <ConfirmModal
          isOpen={openConfirmModal}
          headerText={headerText}
          bodyText={bodyText}
        />
      )}

      <Box mt={6}>
        <Flex justifyContent="flex-end">
          {intDetailsData?.dto?.name === 'GitLab' && (
            <Button
              variant="primary"
              size="sm"
              mr="10px"
              onClick={handleIntegrateModalOpen}
            >
              <Icon as={FaPlus} mr={2} />
              Add new account
            </Button>
          )}
          <Button
            variant="primary"
            size="sm"
            mr="10px"
            onClick={() => {
              if (
                intDetailsData?.dto?.links?.resync_url.startsWith(
                  'https://api.devdynamics.ai'
                )
              ) {
                try {
                  setIsLoading(true);
                  fetch(
                    intDetailsData?.dto?.links?.resync_url
                      .replace('{integrationKey}', integrationId)
                      .replace('{orgId}', `${localStorage.getItem('orgId')}`),
                    {
                      method: 'GET',
                      headers: {
                        Authorization: `bearer ${localStorage.getItem(
                          'token'
                        )}`,
                      },
                    }
                  )
                    .then((res) => {
                      setIsLoading(false);
                      newToast({
                        message:
                          'Request submitted successfully. Please check after some time.',
                        status: 'success',
                      });
                    })
                    .catch((err) => {
                      setIsLoading(false);
                      newToast({
                        message: 'There was a problem contact support.',
                        status: 'error',
                      });
                    });
                } catch (error) {
                  setIsLoading(false);
                }
              } else {
                goToLink(
                  intDetailsData?.dto?.links?.resync_url
                    .replace('{integrationKey}', integrationId)
                    .replace('{orgId}', `${localStorage.getItem('orgId')}`)
                );
              }
            }}
            justifyContent="flex-end"
          >
            <Icon as={FaSync} mr={2} />
            Sync Repositories
          </Button>
        </Flex>
        {data && (
          <DataGrid
            showLoader={isLoading}
            columns={columns}
            data={rows}
            maxH="30rem"
            sticky="none"
          />
        )}

        {(isLoading || Loading) && <Loader />}
      </Box>
    </>
  );
};

export default TableView;
