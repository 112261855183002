export function InsightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10.2512L1.77858 12L10.2622 3.65901L8.4834 1.91022L0 10.2512ZM8.48335 4.86985L7.252 3.65894L8.48335 2.44821L9.7149 3.65894L8.48335 4.86985Z"
        fill="black"
      />
      <path
        d="M3.70604 3.36404L4.644 2.6886L5.58206 3.34793L5.2237 2.2741L6.16156 1.59246H5.00234L4.64398 0.508331L4.28571 1.59246H3.12619L4.06445 2.27122L3.70604 3.36404Z"
        fill="black"
      />
      <path
        d="M9.65539 7.4905L9.48154 6.96474L9.3076 7.4905H8.74525L9.20018 7.76286L9.02643 8.25231L9.48155 7.92179L9.93647 8.24821L9.76262 7.76355L10.2178 7.4905H9.65539Z"
        fill="black"
      />
      <path
        d="M12 0.831488H11.1108L10.836 0L10.5613 0.831488H9.67198L10.3916 1.31779L10.1167 2.13776L10.836 1.61534L11.5553 2.12414L11.2804 1.31981L12 0.831488Z"
        fill="black"
      />
    </svg>
  );
}

export default InsightIcon;
