import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import {
  PageHeader,
  PageToolbar,
  TeamMenu,
  durationData,
  useQueryState,
  MetaTags,
  MetricInfoItems,
  MetricDashboard,
  useAiAssistantStore,
  Loader,
  removeEmptyQueryParams,
  DateFilter,
} from '@devd-client/devd/components';
import { Select } from 'chakra-react-select';

import { FC, useEffect, useState } from 'react';
import { Chatbox } from '../components/Chatbox';
import { useFetchRepo } from '../api';
import { QUERY_KEYS, useGitCards } from '../graphql';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';

const filterOptions = [
  { value: 'team', label: 'Team' },
  { value: 'repo', label: 'Repo' },
];

const orgId = `${localStorage.getItem('orgId')}`;

export const Git: FC = () => {
  const { isOpen } = useAiAssistantStore();
  const [isLargerThan1820] = useMediaQuery('(min-width: 1820px)');
  const [isLargeThan2120] = useMediaQuery('(min-width: 2120px)');
  const { data: repoList, isLoading: repoLoading } = useFetchRepo('git');
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [ticket, setTicket] = useQueryState('ticket');
  const [metricType, setMetricType] = useQueryState('tab');
  const [filter, setFilter] = useQueryState('filter');
  const [repo, setRepo] = useQueryState('repo');
  const [appState, dispatch] = useAuth();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  const [type, setType] = useState<string>();
  const [repos, setRepos] = useState<any>([]);

  const [filterBy, setFilterBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'team', label: 'Team' });

  const { data, isFetching } = useGitCards(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    filterBy.value === 'repo' ? '' : selectedTeam.value,
    filterBy.value === 'team' ? [] : repos?.map((el: any) => el.value)
  );

  const [clickedTab, setClickedTab] = useState<string>();

  useEffect(() => {
    if (!data) return;
    if (!clickedTab && !type) {
      setClickedTab(data[0]?.chartKey);
      setType(data[0]?.chartKey);
    }
  }, [data]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }

    if (type) {
      setMetricType(type);
    }

    if (repos && repos?.length < 1) {
      removeEmptyQueryParams({ repo: '' });
    } else {
      setRepo(repos?.map((repo: any) => repo.label).join(', '));
    }

    if (filterBy.value) {
      setFilter(filterBy.value);
    }
  }, [selected, selectedTeam.value, type, repos, filterBy.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    filter &&
      setFilterBy(
        filter === 'team'
          ? { value: 'team', label: 'Team' }
          : filter === 'repo'
          ? { value: 'repo', label: 'Repo' }
          : { value: '', label: '' }
      );

    metricType && setType(metricType);
    metricType && setClickedTab(metricType);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  useEffect(() => {
    if (!repoList?.dto) return;
    repo &&
      setRepos(
        repoList?.dto
          ?.filter((el: any) => repo?.split(', ')?.includes(el.name))
          ?.map((item: any) => ({ value: item.key, label: item.name }))
      );
  }, [repoList?.dto, repo]);

  return (
    <>
      <MetaTags title="Git | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader
            headingText={'Git'}
            mb={'10px'}
            // helpDocLink={helpDocLink}
          />
          <PageToolbar
            leftComp={
              <>
                <Box mr={3}>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    mb={2}
                  >
                    Filter By
                  </Text>
                  <Select
                    placeholder="Select Ticket Type"
                    useBasicStyles
                    onChange={(e: any) => {
                      setFilterBy(e);
                    }}
                    size="sm"
                    value={filterBy}
                    options={filterOptions}
                  />
                </Box>
                {filterBy.value === 'team' && (
                  <TeamMenu
                    selectedTeam={selectedTeam}
                    handleSelectedTeam={setSelectedTeam}
                    AuthActionTypes={AuthActionTypes}
                    getUser={getUser}
                    appState={appState}
                    dispatch={dispatch}
                  />
                )}

                {filterBy.value === 'repo' && (
                  <Box mr={3} maxWidth="800px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Repository
                    </Text>
                    <Select
                      placeholder="Select Repo"
                      isMulti={true}
                      useBasicStyles
                      onChange={(e: any) => {
                        setRepos(e);
                      }}
                      size="sm"
                      value={repos}
                      options={repoList?.dto?.map((repo: any) => ({
                        value: repo.key,
                        label: repo.name,
                      }))}
                    />
                  </Box>
                )}
              </>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
        </Box>

        <Grid
          my={4}
          templateColumns={
            isLargeThan2120
              ? 'repeat(6, 1fr)'
              : isLargerThan1820
              ? 'repeat(5, 1fr)'
              : 'repeat(4, 1fr)'
          }
          gap={4}
        >
          {isFetching
            ? Array(isLargeThan2120 ? 6 : isLargerThan1820 ? 5 : 4)
                .fill(null)
                .map((_, index) => (
                  <Box position={'relative'} minWidth="305px" height="109px">
                    <Skeleton minWidth="305px" height="109px" key={index} />
                    <Spinner position={'absolute'} top={'40%'} left={'43%'} />
                  </Box>
                ))
            : data?.map((item: any, idx: number) => (
                <GridItem key={idx}>
                  <MetricInfoItems
                    item={item}
                    type={type}
                    setType={setType}
                    clickedTab={clickedTab}
                    setClickedTab={setClickedTab}
                  />
                </GridItem>
              ))}
        </Grid>

        <MetricDashboard
          selected={selected}
          selectedTeam={filterBy.value === 'repo' ? '' : selectedTeam.value}
          setType={setType}
          type={type}
          repos={
            filterBy.value === 'team' ? [] : repos?.map((el: any) => el.value)
          }
          queryKey={QUERY_KEYS.allGitThreshold}
        />
      </Box>
      {isOpen && <Chatbox />}
      {repoLoading && <Loader />}
    </>
  );
};

export default Git;
