import { Flex, Skeleton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import RightSection from './RightSection';
import { ISelect, Loader, PeriodType } from '@devd-client/devd/components';
import { useLocation } from 'react-router';
import { useTeamDetailsStore } from '../../../../store/teamDetail';

interface AgreementCardProps {
  agreement: any;
  isLoading: boolean;
  selected: PeriodType;
}

const AgreementCard: React.FC<AgreementCardProps> = ({
  agreement,
  isLoading,
  selected,
}) => {
  const { pathname } = useLocation();
  const [selectedTeam, setSelectedTeam] = useState<ISelect>({
    value: '',
    label: '',
  });

  useEffect(() => {
    setSelectedTeam({
      value: decodeURIComponent(pathname.split('/')[2]),
      label: decodeURIComponent(pathname.split('/')[2]),
    });
  }, [pathname]);

  return (
    <>
      <Flex
        border="0.5px solid rgba(0, 0, 0, 0.1)"
        direction="column"
        w="100%"
        minH="470px"
        borderRadius="4px"
        padding={4}
      >
        <RightSection
          agreement={agreement}
          selectedTeam={selectedTeam}
          selected={selected}
        />
      </Flex>
      {isLoading && <Loader />}
    </>
  );
};

export default AgreementCard;
