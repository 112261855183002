import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  durationData,
  useQueryState,
  removeEmptyQueryParams,
  PageToolbar,
  TeamMenu,
  Loader,
  AppTooltip,
  DateFilter,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import { useCicdPipelineGql } from '../graphql';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useDashboardStore } from '@devd-client/devd/components';
import CicdList from '../components/CicdList';
import { Select } from 'chakra-react-select';
import { useFetchRepo } from '../api';

const filterOptions = [
  { value: 'pipeline', label: 'Pipeline' },
  { value: 'repo', label: 'Repo' },
];

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};

export const Cicd: FC = () => {
  const navigate = useNavigate();
  const [appState, dispatch] = useAuth();
  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [filter, setFilter] = useQueryState('filter');
  const [repo, setRepo] = useQueryState('repo');
  const [pipeline, setPipeline] = useQueryState('pipeline');

  const [currentPage, setCurrentPage] = useState(0);

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [isHovered, setIsHovered] = useState(false);
  const [repos, setRepos] = useState([]);
  const [pipelines, setPipelines] = useState([]);

  const [filterBy, setFilterBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'pipeline', label: 'Pipeline' });

  const { data: repoList, isLoading: repoLoading } = useFetchRepo('git');
  const { data: pipelineList, isLoading: pipelineLoading } =
    useFetchRepo('cicd');

  const { data: cicdData, isFetching } = useCicdPipelineGql(
    selectedTeam.value,
    selected.startDate,
    selected.endDate,
    selected.sprintId,
    currentPage,
    20,
    filterBy.value === 'pipeline'
      ? pipelines?.map((el: any) => el.label)
      : repos?.map((el: any) => el.label),
    filterBy.value
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected.duration,
  ]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }

    if (repos && repos?.length < 1) {
      removeEmptyQueryParams({ repo: '' });
    } else {
      setRepo(repos?.map((repo: any) => repo.label).join(', '));
    }

    if (pipelines && pipelines?.length < 1) {
      removeEmptyQueryParams({ pipeline: '' });
    } else {
      setPipeline(pipelines?.map((repo: any) => repo.value).join(', '));
    }

    if (filterBy.value) {
      setFilter(filterBy.value);
    }
  }, [selected, selectedTeam.value, filterBy.value, repos, pipelines]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    filter &&
      setFilterBy(
        filter === 'pipeline'
          ? { value: 'pipeline', label: 'Pipeline' }
          : filter === 'repo'
          ? { value: 'repo', label: 'Repo' }
          : { value: '', label: '' }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  useEffect(() => {
    if (!repoList?.dto) return;
    repo &&
      setRepos(
        repoList?.dto
          ?.filter((el: any) => repo?.split(', ')?.includes(el.name))
          ?.map((item: any) => ({ value: item.key, label: item.name }))
      );
  }, [repoList?.dto, repo]);

  useEffect(() => {
    if (!pipelineList?.dto) return;
    pipeline &&
      setPipelines(
        pipelineList?.dto
          ?.filter((el: any) => pipeline?.split(', ')?.includes(el.key))
          ?.map((item: any) => ({ value: item.key, label: item.name }))
      );
  }, [pipelineList?.dto, pipeline]);

  return (
    <>
      <MetaTags title="CI/CD | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {cicdData?.chartDataState === 'NO_INTEGRATION' ? (
          <Box>
            <PageHeader headingText={'Pipeline'} mb={'10px'} />
            <Flex mb={2} width="100%" justify="flex-end">
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Link to={`${cicdData?.chartError?.link}`}>
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      ml={2}
                    >
                      {cicdData?.chartError?.message}
                    </Text>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            </Flex>

            <Box
              w="full"
              height="400px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={cicdData?.zs || ''}
                style={{ position: 'relative' }}
              />

              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {cicdData?.chartError.message && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {cicdData?.chartError.message === 'ISSUE TRACKING'
                        ? 'Jira'
                        : cicdData?.chartError.message === 'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <PageHeader headingText={'CI/CD'} mb={'10px'} />
            <PageToolbar
              leftComp={
                <>
                  <Flex align="flex-end">
                    <TeamMenu
                      selectedTeam={selectedTeam}
                      handleSelectedTeam={setSelectedTeam}
                      AuthActionTypes={AuthActionTypes}
                      getUser={getUser}
                      appState={appState}
                      dispatch={dispatch}
                    />
                  </Flex>

                  <Box mx={3}>
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Filter By
                    </Text>
                    <Select
                      chakraStyles={customStyles}
                      placeholder="Select"
                      useBasicStyles
                      onChange={(e: any) => {
                        setFilterBy(e);
                      }}
                      size="sm"
                      value={filterBy}
                      options={filterOptions}
                    />
                  </Box>

                  {filterBy.value === 'repo' && (
                    <Box mr={3} maxWidth="800px">
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                        mb={2}
                      >
                        Repository
                      </Text>
                      <Select
                        chakraStyles={customStyles}
                        placeholder="Select Repo"
                        isMulti={true}
                        useBasicStyles
                        onChange={(e: any) => {
                          setRepos(e);
                        }}
                        size="sm"
                        value={repos}
                        options={repoList?.dto?.map((repo: any) => ({
                          value: repo.key,
                          label: repo.name,
                        }))}
                      />
                    </Box>
                  )}

                  {filterBy.value === 'pipeline' && (
                    <Box mr={3} maxWidth="800px">
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                        mb={2}
                      >
                        Pipelines
                      </Text>
                      <Select
                        chakraStyles={customStyles}
                        placeholder="Select Pipeline"
                        isMulti={true}
                        useBasicStyles
                        onChange={(e: any) => {
                          setPipelines(e);
                        }}
                        size="sm"
                        value={pipelines}
                        options={pipelineList?.dto?.map((repo: any) => ({
                          value: repo.key,
                          label: repo.name,
                        }))}
                      />
                    </Box>
                  )}
                </>
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              }
            />

            <CicdList
              data={cicdData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loading={isFetching}
            />
          </Box>
        )}
      </Box>
      {isFetching && <Loader />}
    </>
  );
};

export default Cicd;
