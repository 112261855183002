import { apiClient } from '@devd-client/api';

export const getInitiativeUpdates = (initiative: string): Promise<any> =>
  apiClient(`/v2/initiative/update/${initiative}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const postInvestmentUpdates = (params: any): Promise<any> =>
  apiClient(`/v2/initiative/update/${params.initiative}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const getInitiativesPage = (): Promise<any> =>
  apiClient(
    `/v2/account/org/${localStorage.getItem('orgId')}/page/Initiative`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const addUpdateInitiative = (params: any): Promise<any> => {
  return apiClient(
    `/v2/initiative/org/${localStorage.getItem('orgId')}/update`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const initiativeArchive = (params: any): Promise<any> => {
  return apiClient(`/v2/initiative/org/${localStorage.getItem('userId')}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      orgId: `${localStorage.getItem('orgId')}`,
      keys: params?.data,
    }),
  });
};

export const getAllocationDetail = (id: string): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${id}/allocation`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const addUpdateAllocation = (params: any): Promise<any> => {
  return apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${
      params?.allocationId
    }/allocation/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params?.formattedData),
    }
  );
};

export const getInitiativeDetails = (initiative: string): Promise<any> =>
  apiClient(
    `/v2/initiative/${initiative}/org/${localStorage.getItem('orgId')}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const postInitiative = (params: any): Promise<any> => {
  return apiClient(`/v2/initiative/org/${localStorage.getItem('orgId')}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });
};

export const editInitiative = (params: any): Promise<any> => {
  return apiClient(
    `/v2/initiative/org/${localStorage.getItem('orgId')}/${params.id}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const deleteInitiative = (params: any): Promise<any> => {
  return apiClient(
    `/v2/initiative/org/${localStorage.getItem('orgId')}/${params.id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};
