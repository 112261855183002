import styled, { css } from 'styled-components';

type DetailsSectionContainerProps = {
  insideModal?: boolean;
};

export const DetailsSectionContainer = styled.div<DetailsSectionContainerProps>`
  min-height: fit-content;
  width: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ insideModal }) =>
    insideModal === true &&
    css`
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
      padding: 12px;
      border-radius: 4px;
    `};
`;

export const TagLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.success};
  letter-spacing: 0.5px;
  padding: 4px 6px;
  background-color: #f0fff4;
  margin-right: 5px;
`;

export const TagLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const DetailsHeading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['xl']};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const DetailsParagraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const ToggleText = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  /* margin-bottom: -18px; */
`;

export const SettingBtnContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CardText = styled.h3`
  margin-top: 6px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 15px;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  width: 80px;
`;

type DotType = {
  color?: string;
};

export const Dot = styled.div<DotType>`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 15px;
  margin-left: 4px;
`;
