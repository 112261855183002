import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { MdDeleteOutline } from 'react-icons/md';
import { useState } from 'react';

interface ConfirmButtonProps {
  onSuccessAction: () => void;
  headerText: string;
  bodyText: string;
  buttonText: string;
  isDanger?: boolean;
  variant?: string;
  showIcon?: boolean;
  showLabel?: boolean;
}

export const ConfirmButton = ({
  showLabel = false,
  onSuccessAction,
  buttonText,
  headerText,
  bodyText,
  showIcon,
}: ConfirmButtonProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleButtonClick = () => {
    setIsTooltipOpen(false);
    onOpen();
  };

  const handleModalClose = () => {
    onClose();
    setIsTooltipOpen(false);
  };
  const onSubmit = () => {
    onSuccessAction();
    onClose();
  };

  return (
    <>
      {showIcon ? (
        <Tooltip
          label="Delete"
          isOpen={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
          hasArrow
        >
          {showLabel ? (
            <Button
              bg="transparent"
              size="sm"
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
              onClick={handleButtonClick}
              color="error"
              aria-label="delete"
              leftIcon={<MdDeleteOutline />}
            >
              Remove
            </Button>
          ) : (
            <IconButton
              bg="transparent"
              size="sm"
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
              onClick={handleButtonClick}
              color="error"
              position={'static'}
              aria-label="delete"
              icon={<MdDeleteOutline size={16} />}
            />
          )}
        </Tooltip>
      ) : (
        <Button onClick={onOpen} size="sm" variant={'error'}>
          {buttonText}
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        isCentered
        size="sm"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius={'2px'}
          boxShadow="0px 0px 14px rgba(0, 0, 0, 0.1)"
        >
          <ModalHeader
            fontFamily="heading"
            fontWeight="bold"
            lineHeight="22px"
            color="text.primary"
            pb={2}
            px={4}
          >
            {headerText}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody px={4}>
            <Stack>
              <Text
                fontFamily="heading"
                fontWeight="normal"
                fontSize="xs"
                color="text.secondary"
                lineHeight="17px"
              >
                {bodyText}
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter px={4}>
            <Button
              variant="secondary"
              size="sm"
              onClick={handleModalClose}
              mr={3}
            >
              Cancel
            </Button>
            <Button variant="error-filled" size="sm" onClick={onSubmit}>
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
