import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  ComponentBanner,
  DateFilter,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import { OptionBase, Select } from 'chakra-react-select';
import {
  customFilterSelect,
  filterStyles,
  repoauthorFilterStyles,
} from './FilterStyles';
import { RxReset } from 'react-icons/rx';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';
import useMetricTableHelpers from '../hooks/useMetricTableHelpers';
import {
  FilterByType,
  GetOptionsType,
  NavigationState,
} from '../../../utils/metricTableTypes';
import { useEffect, useState } from 'react';
import useOptions from '../hooks/useOptions';
import useHandleOptionChange from '../hooks/useHandleOptionChange';
import useHandleStatusChange from '../hooks/useHandleStatusChange';
import SubHeading from './SubHeading';
import { handleSelectPlaceHolder } from '../../../utils/metricTable.utils';
interface Option extends OptionBase {
  label: string;
  value: any;
}
interface IssueFilterType {
  isFetched: boolean;
  startDate: any;
  endDate: any;
  chartKey: string;
  metricKey: string;
  heading: string;
  totalDBrows: number;
  firstOption: string;
  secondOption: string;
  teamId: string;
  status: string;
  setTeamId: (value: string) => void;
  setStatus: (value: string) => void;
  setFirstOption: (value: string) => void;
  setSecondOption: (value: string) => void;
  setChartKey: (value: string) => void;
  setMetric: (value: string) => void;
  setMetricKey: (value: string) => void;
  setHeading: (value: string) => void;
  setTicketType: (value: string) => void;
}
// This component consists all filters used in PR table.
export default function IssueFilters({
  isFetched,
  startDate,
  endDate,
  heading,
  chartKey,
  metricKey,
  totalDBrows,
  firstOption,
  secondOption,
  teamId,
  status,
  setTeamId,
  setStatus,
  setSecondOption,
  setFirstOption,
  setChartKey,
  setMetric,
  setHeading,
  setMetricKey,
  setTicketType,
}: IssueFilterType) {
  const location = useLocation();
  const state = location.state as NavigationState;

  const [clearSearchBar, setClearSearchBar] = useState<null | boolean>(null);
  const metricFilterBy = useMetricsTableStore();
  const { handleMetricFilterBy, handleScrollToTop } = useMetricTableHelpers();
  const { getOptions, contributorData, issueOptions, repoList, teamData } =
    useOptions({
      startDate,
      endDate,
    });

  const {
    showOption,
    teamValue,
    defaultValue,
    secondaryDefaultValue,
    handleTeamOptionChange,
    handleShowOptionChange,
    handleDefaultOptionChange,
    handleSecondaryDefaultOptionChange,
  } = useHandleOptionChange();

  const { handleIssueStatusChange, tabIndex, setTabIndex } =
    useHandleStatusChange({
      setStatus,
    });
  useEffect(() => {
    handleDefaultOptionChange(
      getOptions('issueTable').filter(
        (option: Option) => option.value === (firstOption ?? '')
      )?.[0]?.label,
      firstOption ?? ''
    );
    handleSecondaryDefaultOptionChange(
      getOptions(firstOption as GetOptionsType)?.filter(
        (option: Option) => option.value === (secondOption ?? '')
      )?.[0]?.label,
      secondOption ?? ''
    );
    handleTeamOptionChange(teamId ?? '');
    handleShowOptionChange((firstOption as GetOptionsType) ?? '');
    setTabIndex(
      status === 'all'
        ? 0
        : status === 'todo'
        ? 1
        : status === 'inprogress'
        ? 2
        : 3
    );
  }, [contributorData, issueOptions, repoList, teamData]);

  return (
    <Grid
      templateColumns={'repeat(2, 1fr)'}
      className="fitler-container"
      w={'full'}
    >
      <SubHeading
        heading={heading}
        chartKey={chartKey}
        getOptions={getOptions}
        componentOrigin={metricFilterBy.componentOrigin}
      />
      {state?.headingText && (
        <ComponentBanner signalHeadingText={state?.headingText} />
      )}

      <GridItem
        colStart={1}
        colEnd={3}
        mb={4}
        mt={5}
        display={'flex'}
        justifyContent={'space-between'}
        w={'full'}
        zIndex={10}
      >
        <Flex gap={10}>
          <SearchBar clearSearchBar={clearSearchBar} />
          <Flex
            alignSelf={'end'}
            gap={
              ['', 'backtrack_issues'].includes(showOption as string) ? 1 : 3
            }
          >
            <Box pr={1}>
              <Text fontSize={'xs'} color={'grey'}>
                Team
              </Text>
              <Select
                options={getOptions('teams')}
                chakraStyles={filterStyles}
                placeholder="Select Team"
                value={teamValue}
                onChange={(input) => {
                  const modifiedValue = input?.value
                    ?.split(' ')
                    .includes('subTeam')
                    ? input?.value?.split(' ').slice(1).join(' ')
                    : input?.value?.split(' ').slice(0).join(' ');
                  handleScrollToTop();
                  handleMetricFilterBy('teams', '', modifiedValue, null);
                  setTeamId(modifiedValue);
                  setClearSearchBar(!clearSearchBar);
                  handleTeamOptionChange(modifiedValue);
                }}
              />
            </Box>
            <Box>
              <Text fontSize={'xs'} color={'grey'}>
                Filter By
              </Text>
              <Select
                options={getOptions('issueTable')}
                chakraStyles={filterStyles}
                placeholder="Filter"
                components={customFilterSelect}
                value={defaultValue}
                onChange={(input) => {
                  handleDefaultOptionChange(
                    input?.label as string,
                    input?.value
                  );
                  setFirstOption(input?.value);
                  if (
                    [
                      '',
                      'backtrackIssues',
                      'isLongCycleTime',
                      'isDelayed',
                    ].includes(input?.value)
                  ) {
                    handleScrollToTop();
                    setClearSearchBar(!clearSearchBar);
                    setSecondOption('');
                    handleShowOptionChange('');
                    handleMetricFilterBy(
                      'issueFilters',
                      input?.value,
                      input?.value,
                      null
                    );
                  } else {
                    handleShowOptionChange(input?.value);
                    handleSecondaryDefaultOptionChange('', '');
                  }
                }}
              />
            </Box>
            <Box alignSelf={'end'}>
              {![
                '',
                'backtrackIssues',
                'isLongCycleTime',
                'isDelayed',
              ].includes(showOption) && (
                <Select
                  // components={customRepoOption}
                  options={getOptions(showOption)}
                  chakraStyles={repoauthorFilterStyles}
                  placeholder={handleSelectPlaceHolder(showOption)}
                  value={
                    secondaryDefaultValue?.value ? secondaryDefaultValue : null
                  }
                  onChange={(input) => {
                    setSecondOption(input?.value);
                    handleScrollToTop();
                    setClearSearchBar(!clearSearchBar);
                    if (showOption === 'reopenedIssues') {
                      setTicketType(input?.value);
                    }
                    handleMetricFilterBy(
                      'issueFilters',
                      showOption,
                      input?.value,
                      null
                    );
                    handleSecondaryDefaultOptionChange(
                      input?.label as string,
                      input?.value
                    );
                  }}
                />
              )}
            </Box>
            {!['contributor-profile-pr', 'contributor-profile-issue'].includes(
              metricFilterBy.componentOrigin ?? ''
            ) && (
              <Tooltip hasArrow label="Reset filters">
                <IconButton
                  icon={<RxReset />}
                  aria-label="Reset filters"
                  justifySelf={'self-end'}
                  alignSelf={'self-end'}
                  borderRadius={'4px'}
                  size={'md'}
                  variant={'outline'}
                  border={'1px'}
                  borderColor={'#E5E5E5'}
                  onClick={() => {
                    setHeading('Issue Details Table');
                    setMetric('ISSUE_UPDATED');
                    setChartKey('ISSUE_UPDATED');
                    setMetricKey('');
                    setFirstOption('');
                    setSecondOption('');
                    setTeamId('');
                    setStatus('');
                    handleShowOptionChange('');
                    setTicketType('all');
                    setClearSearchBar(!clearSearchBar);
                    handleDefaultOptionChange('All', '');
                    handleTeamOptionChange('');
                    setTabIndex(0);
                    metricFilterBy.resetFilters();
                  }}
                  cursor={'pointer'}
                />
              </Tooltip>
            )}
          </Flex>
        </Flex>
        {metricFilterBy.componentOrigin !== 'contributor-profile-issue' && (
          <Box alignSelf={'end'}>
            <DateFilter isDetailsPage />
          </Box>
        )}
      </GridItem>

      <GridItem colStart={1} colEnd={3} mb={4}>
        <Tabs
          variant="soft-rounded"
          colorScheme="twitter"
          index={tabIndex}
          onChange={handleIssueStatusChange}
        >
          <TabList>
            <Tab>
              All {tabIndex === 0 && isFetched && `(${totalDBrows ?? 0})`}
            </Tab>
            <Tab>
              Todo {tabIndex === 1 && isFetched && `(${totalDBrows ?? 0})`}
            </Tab>
            <Tab>
              In Progress
              {tabIndex === 2 && isFetched && `(${totalDBrows ?? 0})`}
            </Tab>
            <Tab>
              Done {tabIndex === 3 && isFetched && `(${totalDBrows ?? 0})`}
            </Tab>
          </TabList>
        </Tabs>
      </GridItem>
    </Grid>
  );
}
