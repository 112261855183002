import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  source: Yup.string().required('This field is required.'),
  unit: Yup.string().required('This field is required.'),
  field: Yup.string().required('This field is required.'),
  metricFunction: Yup.string().required('This field is required.'),
  chartType: Yup.string().required('This field is required.'),
});
