import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseMutationResult,
  MutationKey,
  MutationFunction,
} from '@tanstack/react-query';

export function useCustomMutation<TData, TError, TVariables, TContext>(
  mutationKey: MutationKey,
  mutationFn: MutationFunction<TData, TVariables>,
  options: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  > = {}
): UseMutationResult<TData, TError, TVariables, TContext> {
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>(
    mutationKey,
    mutationFn,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['teamList']);

        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
    }
  );
}
