import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Bar,
} from 'recharts';
import CustomTooltip from './CustomTooltip';

type Props = {
  barChartData: any;
  keys: any;
};

const StackBarChart = ({ barChartData, keys }: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barGap={3}
        data={barChartData}
        margin={{
          top: 0,
          right: 5,
          left: -14,
          bottom: 24,
        }}
      >
        <XAxis
          dataKey="name"
          name="name"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 9, fontWeight: 500 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />

        {keys?.map((item: any) => (
          <Bar
            key={item.label}
            dataKey={item.label}
            stackId="a"
            barSize={16}
            cursor={'pointer'}
            fill={item.fillColor}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackBarChart;
