import {
  Avatar,
  Box,
  Divider,
  Flex,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useIssueBySprintGql } from '../../../../graphql/index';
import { SlideBar } from '@devd-client/devd/components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatLabel } from '../../../../helpers/team-details.utils';
import { useInView } from 'react-intersection-observer';

interface ShowSprintVelocitySideDrawerProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  heading: string;
  subHeading: string;
  startDate: string;
  endDate: string;
  sprintId: string;
  teamId: string;
  type: string;
}

const ShowSprintVelocitySideDrawer: React.FC<
  ShowSprintVelocitySideDrawerProps
> = ({
  openDrawer,
  handleDrawerClose,
  heading,
  subHeading,
  teamId,
  startDate,
  endDate,
  sprintId,
  type,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [allItems, setAllItems] = useState<any[]>([]);
  const { ref, inView } = useInView();

  const { data: sprintVelocityData, isLoading: isLoadingSprintVelocityData } =
    useIssueBySprintGql(
      startDate,
      endDate,
      sprintId,
      teamId,
      '',
      type,
      currentPage,
      20
    );

  useEffect(() => {
    if (sprintVelocityData?.data) {
      setAllItems((prev) => [...prev, ...sprintVelocityData.data]);
    }
  }, [sprintVelocityData?.data]);

  useEffect(() => {
    if (
      inView &&
      !isLoadingSprintVelocityData &&
      sprintVelocityData?.data?.length === 20
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [inView, isLoadingSprintVelocityData, sprintVelocityData]);

  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      SideDrawerBodyHeight={false}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Stack spacing={1}>
          {isLoadingSprintVelocityData &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {allItems?.map((item: any, idx: number, arr: any) => (
            <Fragment key={item?.id}>
              <Box p={2}>
                <Stack spacing={1}>
                  <Box>
                    <Box>
                      {item?.createdAt && (
                        <Text
                          fontFamily="heading"
                          fontSize="xs"
                          color="text.secondary"
                          fontWeight="semibold"
                        >
                          {moment(item?.createdAt).format('lll')}
                        </Text>
                      )}
                      <Link to={item?.ticketUrl} target="_blank">
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          textDecoration="underline"
                          color="gray.700"
                          fontWeight="medium"
                        >
                          {item?.summary}
                        </Text>
                      </Link>
                      {item?.author && (
                        <Flex align="center">
                          <Avatar
                            size="xs"
                            bg="text.secondary"
                            opacity={0.8}
                            name={item?.author?.toUpperCase()?.charAt(0)}
                          />
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            color="text.secondary"
                            fontWeight="medium"
                            ml={1}
                          >
                            {item?.author}
                          </Text>
                        </Flex>
                      )}
                    </Box>

                    <Flex align="center" flexWrap="wrap">
                      {Object.entries(item).map(([key, value]: any) => {
                        if (
                          key !== 'summary' &&
                          key !== 'ticketUrl' &&
                          key !== 'author' &&
                          key !== 'createdAt' &&
                          key !== 'estimate'
                        ) {
                          if (key === 'updatedAt') {
                            value = moment(value).format('lll');
                          }
                          return (
                            <Flex mr={3} key={key}>
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="semibold"
                                color="gray.600"
                                whiteSpace="nowrap"
                                mr={1}
                              >
                                {formatLabel(key)}
                              </Text>
                              <Text mt={'-3px'}>:</Text>
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="medium"
                                color="text.secondary"
                                ml={1}
                              >
                                {value}
                              </Text>
                            </Flex>
                          );
                        }
                        return null;
                      })}
                    </Flex>
                  </Box>
                </Stack>
              </Box>
              {idx !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}

          <Box ref={ref} visibility={'hidden'}>
            {' '}
            Hidden{' '}
          </Box>
          <Box h="20px" mb="4px">
            {isLoadingSprintVelocityData ? (
              <Flex height="20px" justify="center" align="center">
                <Spinner />{' '}
                <Text
                  ml={2}
                  fontFamily="heading"
                  color="text.secondary3"
                  fontSize="sm"
                >
                  Loading more data
                </Text>
              </Flex>
            ) : (
              sprintVelocityData?.data?.length > 8 &&
              sprintVelocityData?.data?.length < 20 && (
                <Flex height="20px" justify="center" align="center">
                  <Text
                    fontFamily="heading"
                    color="text.secondary3"
                    fontSize="sm"
                  >
                    No more data.
                  </Text>
                </Flex>
              )
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default ShowSprintVelocitySideDrawer;
