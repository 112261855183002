import {
  GridItem,
  Flex,
  Box,
  useTheme,
  IconButton,
  Text,
} from '@chakra-ui/react';

import { useState } from 'react';
import { extractPercentageAndRemaining } from '../shared/utils';
import { CrossIcon } from '../icons';

type Props = {
  signalHeadingText: string;
};

export const ComponentBanner = ({ signalHeadingText }: Props) => {
  const { colors } = useTheme();
  const [hideHeading, setHideHeading] = useState<boolean>(false);

  const { percentage, remaining } = extractPercentageAndRemaining(
    signalHeadingText || ''
  );

  return !hideHeading ? (
    <GridItem colSpan={2}>
      <Flex
        p={2}
        mt={4}
        width="fit-content"
        align="center"
        borderRadius={'4px'}
        bg="rgba(255, 173, 0, 0.10)"
        border="1px solid"
        borderColor="warning"
      >
        <Text color="text.primary" fontFamily="heading">
          <Text as="span" fontWeight="bold" color="text.primary" mr={1}>
            {percentage}
          </Text>
          <Text as="span" fontWeight={'normal'}>
            {remaining}
          </Text>
        </Text>

        <Box ml={6}>
          <IconButton
            aria-label="cross"
            size="xs"
            bg="transparent"
            onClick={() => {
              setHideHeading(true);
            }}
            icon={<CrossIcon color={colors.text.primary} />}
          />
        </Box>
      </Flex>
    </GridItem>
  ) : (
    <></>
  );
};
