import * as React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Flex } from '@chakra-ui/react';
import './../shared/css/styles.css';

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';

import Loader from '../appLoader';

type DataGridProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  maxH?: string;
  showLoader: boolean;
};

export function HeatMapTable<Data extends object>({
  data,
  columns,
  showLoader,
  maxH,
}: DataGridProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row: any) => row.subTeams,
    state: {
      sorting,
      rowSelection,
      expanded,
    },
  });

  function getGradientColor(value: any) {
    if (value >= 0 && value <= 49) {
      return '#FF5630';
    } else if (value >= 50 && value <= 66) {
      return '#FFC53A';
    } else if (value >= 67 && value <= 74) {
      return '#86E8AB';
    } else if (value >= 75 && value <= 100) {
      return '#36B37E';
    } else return '#F6F7F9';
  }

  return (
    <Box maxH={maxH} mr={20} pb={4}>
      <Table className="sticky-table-column1">
        <Thead bg="#fff" top={0}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta: any = header.column.columnDef.meta;

                return (
                  <Th
                    px={0}
                    pb={1}
                    height="140px"
                    textTransform="none"
                    isNumeric={meta?.isNumeric}
                    fontSize={header.id === 'teamName' ? 'md' : 'xs'}
                    fontFamily="heading"
                    fontWeight="semibold"
                    color={
                      header.id === 'teamName'
                        ? 'text.primary'
                        : 'text.secondary'
                    }
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                    }}
                  >
                    <Flex
                      height="100%"
                      align="flex-end"
                      whiteSpace="nowrap"
                      transform={
                        header.id !== 'teamName' ? 'rotate(-25deg)' : 'none'
                      }
                      {...{
                        style: {
                          width: header.getSize(),
                        },
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr bg={'#fff'} key={row.id} _hover={{ bg: '#F6F7F9' }}>
              {row.getVisibleCells().map((cell: any) => {
                const meta: any = cell.column.columnDef.meta;

                return (
                  <Td
                    bg={
                      cell.column.id.includes('teamName')
                        ? ''
                        : cell.column.id.includes('totalResponses')
                        ? '#F6F7F9'
                        : cell.getValue().score === null
                        ? '#F6F7F9'
                        : getGradientColor(cell.getValue()?.score)
                    }
                    fontFamily="heading"
                    color="text.primary"
                    fontSize="sm"
                    px={5}
                    py={3}
                    borderBottom={0}
                    fontWeight="medium"
                    isNumeric={meta?.isNumeric}
                    boxShadow="inset 0 0 0 1px rgba(255, 255, 255, 1)"
                    {...{
                      key: cell.id,
                      style: {
                        width: cell.column.getSize(),
                      },
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {showLoader && <Loader />}
    </Box>
  );
}
