import { apiClient } from '@devd-client/api';

export const getSprintList = (): Promise<any> =>
  apiClient(`/v1/org/${localStorage.getItem('orgId')}/sprints`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const postFavSprint = (params: any) => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/sprint`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const deleteFavSprint = (params: any) => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/sprint/${
      params.sprint
    }`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};
