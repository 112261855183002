export function HourGlassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 1C2.33579 1 2 1.33579 2 1.75C2 2.16421 2.33579 2.5 2.75 2.5H3.5V3.75C3.5 5.2451 4.20392 6.65294 5.4 7.55L5.73333 7.8C5.86667 7.9 5.86667 8.1 5.73333 8.2L5.4 8.45C4.20392 9.34706 3.5 10.7549 3.5 12.25V13.5H2.75C2.33579 13.5 2 13.8358 2 14.25C2 14.6642 2.33579 15 2.75 15H13.25C13.6642 15 14 14.6642 14 14.25C14 13.8358 13.6642 13.5 13.25 13.5H12.5V12.25C12.5 10.7549 11.7961 9.34706 10.6 8.45L10.2667 8.2C10.1333 8.1 10.1333 7.9 10.2667 7.8L10.6 7.55C11.7961 6.65294 12.5 5.2451 12.5 3.75V2.5H13.25C13.6642 2.5 14 2.16421 14 1.75C14 1.33579 13.6642 1 13.25 1H2.75ZM11 2.5H5V3.75C5 4.77296 5.48163 5.73622 6.3 6.35L6.63333 6.6C7.56667 7.3 7.56667 8.7 6.63333 9.4L6.3 9.65C5.48163 10.2638 5 11.227 5 12.25V13.5H11V12.25C11 11.227 10.5184 10.2638 9.7 9.65L9.36667 9.4C8.43333 8.7 8.43333 7.3 9.36667 6.6L9.7 6.35C10.5184 5.73622 11 4.77296 11 3.75V2.5Z"
        fill={props.color || '#2A2A2F'}
      />
    </svg>
  );
}
