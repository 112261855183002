import styled from 'styled-components';

type PaginationItemType = {
  disabled?: boolean;
  selected?: boolean;
  dots?: boolean;
};

export const PaginationContainer = styled.ul`
  display: flex;
  gap: 2px;
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

export const PaginationItem = styled.li<PaginationItemType>`
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  margin: 0 2px;
  color: ${({ disabled, selected }) =>
    disabled ? '#ccc' : selected ? '#FFF' : '#626266'};
  background-color: ${({ selected, theme }) =>
    selected ? `${theme.colors.primary}` : 'transparent'};
  border-radius: 8px;
  border: ${({ selected }) => (selected ? '' : '1px solid #E0E0E0')};
  font-size: 13px;
  min-width: 32px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ disabled, selected }) =>
      !disabled && !selected ? 'rgba(0, 149, 230, 0.1)' : ''};
  }

  & span {
    border-bottom: ${({ selected, theme }) =>
      selected && `2px solid ${theme.colors.primary}`};
    pointer-events: none; // Disable events on span to make the whole item clickable
  }
`;

export const PrevLabel = styled.div`
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

export const NextLabel = styled.div`
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;
