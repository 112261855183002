import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDayTicketReportGraphql } from '../../../graphql';
import LeftSideColumn from './LeftSideColumn';
import RightSideTable from './RightSideTable';
import { InvestmentsContext } from '../../../context/investmentContext';
import { Loader, PeriodType } from '@devd-client/devd/components';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../../graphql/index';
import { getDayTicketReportGraphql } from '../../../graphql/query';

const BoxViewContainer = styled.div`
  display: flex;
`;

const Divider = styled.div`
  width: 0.5px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 8px;
  margin-left: 8px;
`;

interface BoxViewProps {
  viewSwitch: string;
  selectedTeam: string;
  selected: PeriodType;
}

const BoxView: FC<BoxViewProps> = ({ viewSwitch, selectedTeam, selected }) => {
  const {
    state: { weeklyData, selectedChartData, ticketData },
    dispatch,
  } = useContext(InvestmentsContext);
  const queryClient = useQueryClient();

  const [selectedData, setSelectedData] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemOffset, setItemOffset] = useState<number>(20);

  useEffect(() => {
    setSelectedData(weeklyData[0]?.rows[0]?.name);
  }, [weeklyData]);

  useEffect(() => {
    if (currentPage >= ticketData?.totalPages) return;

    const nextPage = currentPage + 1;
    queryClient.prefetchQuery([QUERY_KEYS.investmentTicket, nextPage], () =>
      getDayTicketReportGraphql(
        selectedChartData.endDate,
        selected?.sprintId,
        nextPage,
        itemOffset,
        selectedData,
        viewSwitch,
        selectedTeam
      )
    );
  }, [currentPage, queryClient]);

  const { isFetching } = useDayTicketReportGraphql(
    selectedChartData.endDate,
    selected?.sprintId,
    currentPage,
    itemOffset,
    selectedData,
    viewSwitch,
    selectedTeam,
    dispatch
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page + 1);
    },
    [currentPage]
  );

  return (
    <>
      <BoxViewContainer>
        <LeftSideColumn
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />

        <Divider />

        <RightSideTable
          isFetching={isFetching}
          handlePageClick={handlePageClick}
        />
      </BoxViewContainer>

      {isFetching && <Loader />}
    </>
  );
};

export default BoxView;
