import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { ThickArrow } from '@devd-client/devd/components';

interface CycleTimeItemProps {
  title: string;
  time: string;
}

const CycleTimeItem: React.FC<CycleTimeItemProps> = ({ title, time }) => {
  const { colors } = useTheme();
  return (
    <Flex direction="column">
      <Text
        ml={1}
        fontSize="xxs"
        fontFamily="heading"
        fontWeight="semibold"
        color="text.primary"
        mb={'-10px'}
        letterSpacing={'0.5px'}
      >
        {title}
      </Text>
      <ThickArrow
        height="55px"
        width="55px"
        color={time === '0h' ? colors.text.secondary : colors.success}
      />
      <Box ml={2}>
        <Text
          fontSize="xxs"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          mt={'-10px'}
        >
          {time}
        </Text>
      </Box>
    </Flex>
  );
};

export default CycleTimeItem;
