import { Box, Text, Portal, Flex, Center, Spinner } from '@chakra-ui/react';
import {
  eliminateHtmlTags,
  SearchBox,
  SearchIconContainer,
  SearchInput,
  StyledSearchIcon,
  useDebounce,
} from '../index';
import { useState, useRef, useEffect } from 'react';
import { useFetchContributors } from './api';
import { useTheme } from '@chakra-ui/react';

interface AddManuallyProps {
  showHeading?: boolean;
  setSelectedAccountsFromSearch: React.Dispatch<React.SetStateAction<any[]>>;
  selectedEmails?: string[];
  isAccountAlreadyPresent?: (email: string) => boolean;
}

export const AddManually = ({
  showHeading = true,
  setSelectedAccountsFromSearch,
  selectedEmails,
  isAccountAlreadyPresent,
}: AddManuallyProps) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const { isFetching, data } = useFetchContributors(debouncedSearch, 0);
  const { colors } = useTheme();

  const handleSelectAccount = (account: any) => {
    if (
      selectedEmails?.includes(account.email) ||
      (isAccountAlreadyPresent && isAccountAlreadyPresent(account.email))
    ) {
      return;
    }
    setSelectedAccountsFromSearch((prev) => [...prev, account]);
    setSearch('');
    setSearchResults([]);
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (search && !isFetching && data?.dto) {
      setSearchResults(
        data.dto.map((item: any) => ({
          contributorAccountList: item.contributorAccountList,
          email: item.email,
          name: item.name,
          teams: item.teams,
          id: item.id,
        }))
      );
    } else {
      setSearchResults([]);
    }
  }, [data, search, isFetching]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Box mt={4} pb={search ? 32 : 8}>
      {showHeading && (
        <Text
          color={'text.secondary2'}
          fontSize={'md'}
          fontWeight={'medium'}
          mb={2}
        >
          Merge accounts manually
        </Text>
      )}
      <Box position="relative" ref={searchBoxRef} ml={showHeading ? -2.5 : 0}>
        <SearchBox>
          <SearchIconContainer>
            <StyledSearchIcon />
          </SearchIconContainer>
          <SearchInput
            width={'250px'}
            ref={inputRef}
            placeholder="Search Accounts"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSearch(eliminateHtmlTags(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </SearchBox>
        {(searchResults?.length > 0 || (search && isFetching)) && (
          <Portal containerRef={searchBoxRef}>
            <Box
              position="absolute"
              top="100%"
              left={3}
              right={0}
              mt={1}
              bg="white"
              boxShadow="md"
              borderRadius="md"
              zIndex={1000}
              w={'fit-content'}
              maxH="120px"
              overflowY="auto"
              border="1px solid #E0E0E0"
            >
              {isFetching ? (
                <Center p={4} minW={'280px'} minH={'100px'}>
                  <Spinner size="sm" color={colors.primary} />
                </Center>
              ) : (
                searchResults?.map((result, index) => (
                  <Box
                    key={index}
                    p={2}
                    _hover={{ bg: 'gray.100' }}
                    cursor="pointer"
                    onClick={() => handleSelectAccount(result)}
                  >
                    <Flex minW={'265px'}>
                      <Text fontWeight="normal" fontSize={'sm'}>
                        {result.id}
                      </Text>
                    </Flex>
                  </Box>
                ))
              )}
            </Box>
          </Portal>
        )}
      </Box>
    </Box>
  );
};

export default AddManually;
