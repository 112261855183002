export function Investment(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.1 17H10.85V15.75C11.6833 15.6 12.4 15.275 13 14.775C13.6 14.275 13.9 13.5333 13.9 12.55C13.9 11.85 13.7 11.2083 13.3 10.625C12.9 10.0417 12.1 9.53333 10.9 9.1C9.9 8.76667 9.20833 8.475 8.825 8.225C8.44167 7.975 8.25 7.63333 8.25 7.2C8.25 6.76667 8.40433 6.425 8.713 6.175C9.021 5.925 9.46667 5.8 10.05 5.8C10.5833 5.8 11 5.92933 11.3 6.188C11.6 6.446 11.8167 6.76667 11.95 7.15L13.55 6.5C13.3667 5.91667 13.0293 5.40833 12.538 4.975C12.046 4.54167 11.5 4.3 10.9 4.25V3H9.15V4.25C8.31667 4.43333 7.66667 4.8 7.2 5.35C6.73333 5.9 6.5 6.51667 6.5 7.2C6.5 7.98333 6.72933 8.61667 7.188 9.1C7.646 9.58333 8.36667 10 9.35 10.35C10.4 10.7333 11.1293 11.075 11.538 11.375C11.946 11.675 12.15 12.0667 12.15 12.55C12.15 13.1 11.954 13.504 11.562 13.762C11.1707 14.0207 10.7 14.15 10.15 14.15C9.6 14.15 9.11267 13.979 8.688 13.637C8.26267 13.2957 7.95 12.7833 7.75 12.1L6.1 12.75C6.33333 13.55 6.696 14.196 7.188 14.688C7.67933 15.1793 8.31667 15.5167 9.1 15.7V17ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
}

export default Investment;
