export const getGraphQLBaseURL = (region: string | null) => {
  switch (region) {
    case 'IN':
      return process.env['NX_GRAPHQL_HOST_IN'];
    default:
      return process.env['NX_GRAPHQL_HOST'];
  }
};

export const getAPIBaseURL = (region: string | null) => {
  switch (region) {
    case 'IN':
      return process.env['NX_API_HOST_IN'];
    default:
      return process.env['NX_API_HOST'];
  }
};
