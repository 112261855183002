import { TeamMenu, ISelect } from '@devd-client/devd/components';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';

const ActivityLogToolbarContainer = styled.div`
  display: flex;
  align-items: center;
`;
interface ActivityLogToolbarProps {
  selectedTeam: ISelect;
  handleSelectedTeam: (selectedTeam: ISelect) => void;
}

const ActivityLogToolbar: FC<ActivityLogToolbarProps> = ({
  selectedTeam,
  handleSelectedTeam,
}) => {
  const [appState, dispatch] = useAuth();
  return (
    <ActivityLogToolbarContainer>
      <TeamMenu
        selectedTeam={selectedTeam}
        handleSelectedTeam={handleSelectedTeam}
        AuthActionTypes={AuthActionTypes}
        getUser={getUser}
        appState={appState}
        dispatch={dispatch}
      />
    </ActivityLogToolbarContainer>
  );
};

export default ActivityLogToolbar;
