import React, { FC } from 'react';
import { Box, Flex, Stack, Text, useTheme } from '@chakra-ui/react';
import {
  ActiveDaysType,
  Property,
} from '../../../../helpers/ActivityLog.types';
import {
  Count,
  DetailBoxContainer,
  MetaData,
  MetaDataIcon,
  Name,
  Repo,
} from './DetailBox.styled';
import {
  AppTooltip,
  FireIcon,
  ThunderIcon,
} from '@devd-client/devd/components';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

interface DetailBoxProps {
  name: string;
  properties: Property[];
  activeDays: ActiveDaysType;
  workLogType: string;
}
const DetailBox: FC<DetailBoxProps> = ({
  name,
  properties,
  activeDays,
  workLogType,
}) => {
  const { colors } = useTheme();
  const navigate = useNavigate();

  return (
    <DetailBoxContainer>
      <Flex align="center">
        {workLogType === 'Contributors' ? (
          <Link to={`/contributors/${name}`}>
            <Name color={colors.text.primary}>{name}</Name>
          </Link>
        ) : (
          <Repo color={colors.text.primary}>{name}</Repo>
        )}

        {activeDays?.isBurnOut && (
          <AppTooltip
            placement="right"
            trigger="click"
            content={
              <Stack>
                {activeDays?.insight?.length > 0 ? (
                  activeDays?.insight?.map((item: string) => (
                    <Flex align="start">
                      <ThunderIcon height="18px" width="18px" />
                      <Text
                        ml={1}
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                        color="text.primary"
                      >
                        {item}
                      </Text>
                    </Flex>
                  ))
                ) : (
                  <Text
                    ml={1}
                    fontFamily="heading"
                    fontSize="sm"
                    fontWeight="normal"
                    color="text.primary"
                  >
                    No Insights.
                  </Text>
                )}
              </Stack>
            }
          >
            <Box>
              <FireIcon height="24px" width="24px" />
            </Box>
          </AppTooltip>
        )}
      </Flex>

      {properties?.map((property: Property, idx: number) => (
        <MetaDataLine
          key={`property-${idx}`}
          text={property.name}
          count={property.value}
          iconColor="blue"
          textColor="#777"
          countColor={colors.text.primary}
        />
      ))}
    </DetailBoxContainer>
  );
};

interface MetaDataLineProps {
  iconColor?: string;
  countColor?: string;
  textColor?: string;
  icon?: React.ReactNode;
  count: number | string;
  text: string;
}

const MetaDataLine: FC<MetaDataLineProps> = ({
  iconColor,
  countColor,
  textColor,
  icon,
  count,
  text,
}) => {
  return (
    <MetaData color={textColor}>
      {icon && <MetaDataIcon color={iconColor}>{icon}</MetaDataIcon>}
      <Count color={countColor}>{count}</Count>
      {text}
    </MetaData>
  );
};

export default React.memo(DetailBox);
