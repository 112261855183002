export function MetricsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_6899_306)">
        <path
          d="M10.2822 14.8477V7.47266H14.8477V14.8477"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.7168 14.8477V9.31641H10.2822V14.8477"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.15137 14.8474V10.8965H5.7168V14.8474"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.15137 7.27246L14.8478 2.49023"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.1367 1.15137L14.8482 2.47968L13.5316 5.20545"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6899_306">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
