export const absoluteSetInterval = (handler: any, timeout: any) => {
  let baseTime = Date.now();
  const callHandler = () => {
    if (Date.now() - baseTime > timeout) {
      baseTime = Date.now();
      handler();
    }
  };
  return window.setInterval(callHandler, 1000);
};

export const absoluteClearInterval = (handle: any) =>
  window.clearInterval(handle);
