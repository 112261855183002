import { createContext, useReducer } from 'react';
import {
  CycleTimeAction,
  cycletimeReducer,
  CycleTimeState,
} from '../reducers/cycletimeReducer';

const initialState = {
  dataPR: [],
  dataCoding: [],
  dataPickup: [],
  dataMerge: [],
};
export const CycleTimeContext = createContext<{
  state: CycleTimeState;
  dispatch: React.Dispatch<CycleTimeAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const CycleTimeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(cycletimeReducer, initialState);

  return (
    <CycleTimeContext.Provider value={{ state, dispatch }}>
      {children}
    </CycleTimeContext.Provider>
  );
};
