import { Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import CustomSelect from '../../customSelect';
import { AddBottomViewProps } from '../helpers/bottomView.types';

type Option = {
  name: string;
  value: string;
};

interface JiraFormProps {
  handleSaveClick: (
    params: AddBottomViewProps[],
    integrationId: string
  ) => void;
  data: any;
  integrationId: string;
}

const formatOptionLabel = ({ value, label }: any) => {
  if (value === '') {
    return <span>&nbsp;</span>;
  }
  return label;
};

const getSelectedValue = (fieldName: string, data: any) => {
  return (
    data?.dto
      ?.filter((elem: any) => elem?.name === fieldName && elem?.value)
      ?.map((item: any) => {
        return item?.options?.find((el: any) => el?.value === item?.value)
          ?.name;
      })[0] || ''
  );
};

const payloadObject = (fieldName: string, selectedValue: string, data: any) => {
  return {
    name: fieldName,
    value: data?.dto
      ?.find((elem: any) => elem?.name === fieldName)
      .options?.map(({ name, value }: Option) => ({
        value: value,
        label: name,
      }))
      .filter((item: any) => item.label === selectedValue)[0]?.value,
  };
};

const getOptions = (fieldName: string, data: any) => {
  return [{ value: '', label: '' }].concat(
    data?.dto
      ?.find((elem: any) => elem?.name === fieldName)
      .options?.map(({ name, value }: Option) => ({
        value: value,
        label: name,
      })) || []
  );
};

const JiraForm: FC<JiraFormProps> = ({
  handleSaveClick,
  data,
  integrationId,
}) => {
  const [selectedVelocity, setSelectedVelocity] = useState<string>('');
  const [selectedStoryPoint, setSelectedStoryPoint] = useState<string>('');
  const [selectedStartDate, setSelectedStartDate] = useState<string>('');
  const [selectedSeverity, setSelectedSeverity] = useState<string>('');
  const [selectedSprint, setSelectedSprint] = useState<string>('');

  useEffect(() => {
    setSelectedVelocity(getSelectedValue('Velocity', data));
    setSelectedStoryPoint(getSelectedValue('StoryPoint', data));
    setSelectedStartDate(getSelectedValue('StartDate', data));
    setSelectedSeverity(getSelectedValue('Severity', data));
    setSelectedSprint(getSelectedValue('Sprint', data));
  }, [data?.dto]);

  const onSubmit = () => {
    handleSaveClick(
      [
        payloadObject('Velocity', selectedVelocity, data),
        payloadObject('StoryPoint', selectedStoryPoint, data),
        payloadObject('StartDate', selectedStartDate, data),
        payloadObject('Severity', selectedSeverity, data),
        payloadObject('Sprint', selectedSprint, data),
      ],
      integrationId
    );
  };

  const onSelectChange = (
    selected: string,
    fieldName: string,
    setFunc: (setItem: string) => void
  ) => {
    setFunc(
      data?.dto
        ?.find((elem: any) => elem?.name === fieldName)
        ?.options?.map(({ name, value }: { name: string; value: string }) => ({
          value: value,
          label: name,
        }))
        ?.filter((item: any) => item.value === selected)[0]?.label
    );
  };

  return (
    <Stack spacing={4}>
      <HStack spacing={6}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          minW="100px"
        >
          Velocity:
        </Text>
        <CustomSelect
          placeholder={'select velocity'}
          onChange={(selected: string) =>
            onSelectChange(selected, 'Velocity', setSelectedVelocity)
          }
          defaultItem={{ value: selectedVelocity, label: selectedVelocity }}
          formatOptionLabel={formatOptionLabel}
          options={getOptions('Velocity', data)}
        />
      </HStack>

      <HStack spacing={6}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          minW="100px"
        >
          Story Point:
        </Text>
        <CustomSelect
          placeholder="Select Story point"
          onChange={(selected: string) =>
            onSelectChange(selected, 'StoryPoint', setSelectedStoryPoint)
          }
          defaultItem={{ value: selectedStoryPoint, label: selectedStoryPoint }}
          formatOptionLabel={formatOptionLabel}
          options={getOptions('StoryPoint', data)}
        />
      </HStack>

      <HStack spacing={6}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          minW="100px"
        >
          Start Date:
        </Text>
        <CustomSelect
          placeholder="Select Start date"
          onChange={(selected: string) =>
            onSelectChange(selected, 'StartDate', setSelectedStartDate)
          }
          defaultItem={{ value: selectedStartDate, label: selectedStartDate }}
          formatOptionLabel={formatOptionLabel}
          options={getOptions('StartDate', data)}
        />
      </HStack>

      <HStack spacing={6}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          minW="100px"
        >
          Severity:
        </Text>
        <CustomSelect
          placeholder="Select Severity"
          onChange={(selected: string) =>
            onSelectChange(selected, 'Severity', setSelectedSeverity)
          }
          defaultItem={{ value: selectedSeverity, label: selectedSeverity }}
          formatOptionLabel={formatOptionLabel}
          options={getOptions('Severity', data)}
        />
      </HStack>

      <HStack spacing={6}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          minW="100px"
        >
          Sprint:
        </Text>
        <CustomSelect
          placeholder="Select Sprint"
          onChange={(selected: string) =>
            onSelectChange(selected, 'Sprint', setSelectedSprint)
          }
          defaultItem={{ value: selectedSprint, label: selectedSprint }}
          formatOptionLabel={formatOptionLabel}
          options={getOptions('Sprint', data)}
        />
      </HStack>

      <Flex>
        <Button w="120px" variant="tertiary" onClick={onSubmit}>
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default JiraForm;
