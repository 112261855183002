import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useVelocityPage } from '../../api';
import VelocityPage from './devd-velocity';

export const Velocity = () => {
  const { data } = useVelocityPage();

  return (
    <>
      <MetaTags title="Velocity | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <VelocityPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Velocity;
