export function ActionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.99998 2.33337V1.33337M3.37375 3.37381L2.66665 2.66671M3.37375 8.66671L2.66665 9.37381M8.66665 3.37381L9.37375 2.66671M2.33331 6.00004H1.33331M10.5763 10.7931L8.91514 13.8781C8.72536 14.2305 8.63048 14.4067 8.51631 14.4513C8.41724 14.49 8.30566 14.479 8.21596 14.4219C8.11261 14.356 8.05369 14.1648 7.93585 13.7822L5.63011 6.29687C5.53385 5.98438 5.48572 5.82813 5.52444 5.7226C5.55817 5.63068 5.63062 5.55824 5.72254 5.52451C5.82807 5.48578 5.98432 5.53391 6.29681 5.63017L13.7821 7.93594C14.1647 8.05378 14.3559 8.1127 14.4218 8.21605C14.4789 8.30575 14.4899 8.41733 14.4512 8.5164C14.4066 8.63057 14.2304 8.72545 13.878 8.91522L10.793 10.5764C10.7407 10.6046 10.7145 10.6187 10.6915 10.6368C10.6712 10.6529 10.6528 10.6713 10.6367 10.6916C10.6186 10.7145 10.6045 10.7407 10.5763 10.7931Z"
        stroke={props.color || '#626266'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ActionIcon;
