import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Loader from '../appLoader';
import Breadcrumbs from '../appBreadcrumb';
import {
  QUERY_KEYS,
  useFetchIntegrationGitDetails,
  useGitExcludeBranchesMutation,
} from './api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../appToast';

export const IntegrationGitDetails = () => {
  const { name, id2 } = useParams();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const integrationName = decodeURIComponent(pathname.split('/')[3]);

  const { data: intDetailsData, isFetching } = useFetchIntegrationGitDetails(
    id2 as string,
    integrationName
  );

  const [branchValue, setBranchValue] = useState<string>('');
  const [prValue, setPrValue] = useState<string>('');
  const [label, setLabel] = useState<string>('');

  const handleBranchValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setBranchValue(event?.target?.value);
  };
  const handlePrValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrValue(event?.target?.value);
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel(event?.target?.value);
  };

  useEffect(() => {
    if (intDetailsData?.dto?.exclusion?.branches) {
      setBranchValue(intDetailsData?.dto?.exclusion?.branches);
    } else {
      setBranchValue('');
    }
    if (intDetailsData?.dto?.exclusion?.prs) {
      setPrValue(intDetailsData?.dto?.exclusion?.prs);
    } else {
      setPrValue('');
    }
    if (intDetailsData?.dto?.exclusion?.label) {
      setLabel(intDetailsData?.dto?.exclusion?.label);
    } else {
      setLabel('');
    }

    if (intDetailsData?.dto?.exclusion?.ignoreAllPrs) {
      setIsChecked(intDetailsData?.dto?.exclusion?.ignoreAllPrs);
    } else {
      setIsChecked(false);
    }
  }, [intDetailsData]);

  const { mutate: handleExclusion, isLoading: hahdleExclusionLoading } =
    useGitExcludeBranchesMutation();

  const handleSaveClick = (
    branchValue: any,
    prValue: any,
    label: any,
    id2: string
  ) => {
    const formatInputValue = (str: string) =>
      str
        ?.replace(/,\n|,\s*|\n+/g, ',')
        ?.replace(/,+$/, '')
        ?.replace(/,+/g, ',')
        ?.trim();

    const formattedBranchValue = formatInputValue(branchValue);

    const formattedPrValue = formatInputValue(prValue);

    const formattedLabel = formatInputValue(label);

    handleExclusion(
      {
        data: {
          exclusion: {
            branches: formattedBranchValue,
            prs: formattedPrValue,
            label: formattedLabel,
            ignoreAllPrs: isChecked,
          },
        },
        id2,
        integrationName,
      },
      {
        onSuccess: () => {
          queryClient?.invalidateQueries([QUERY_KEYS.intDetails]);

          newToast({
            message: `Branches and PR titles updated`,
            status: 'success',
          });
          setTimeout(
            () =>
              newToast({
                message: `Filter data will reflect in the next 5 minutes.`,
                status: 'info',
              }),
            2000
          );
        },
        onError: (err: any) => {
          newToast({
            message: err?.message || `Failed to save changes`,
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <Box w="full">
      <Box mb={3}>
        <Breadcrumbs />
      </Box>

      <VStack align="stretch" spacing={2}>
        <Text fontFamily={'heading'} fontSize={'lg'} fontWeight={'semibold'}>
          Repository: {name}
        </Text>

        <Text fontSize={'md'} fontFamily={'heading'} fontWeight={'semibold'}>
          Exclude Branches
        </Text>
        <Text fontSize={'sm'} fontFamily={'heading'}>
          Add the names of branches ( comma seperated ) that DevDynamics should{' '}
          <strong>skip </strong>
          when scanning {name} repository.
        </Text>

        <Text fontSize={'sm'} fontFamily={'heading'} color="text.secondary">
          <Text fontWeight="semibold" as="span" color="text.primary">
            Note:
          </Text>{' '}
          If your team work with feature branches you should add branches that
          are not a target to a direct commit and used only as a baseline branch
          (e.g. master, main, stage,dev )
        </Text>

        <Textarea
          value={branchValue}
          onChange={handleBranchValueChange}
          placeholder="Enter Git Branches Name"
          isDisabled={isChecked}
          h="70px"
          maxW={'60%'}
        />

        <Text
          fontSize={'md'}
          pt={2}
          fontFamily={'heading'}
          fontWeight={'semibold'}
        >
          Exclude by PR title's
        </Text>
        <Text fontSize={'sm'} fontFamily={'heading'}>
          Add words ( comma separated ) contains in Pull request title that
          DevDynamics should <strong>skip </strong>
          when scanning {name} repository.
        </Text>

        <Textarea
          value={prValue}
          onChange={handlePrValueChange}
          placeholder="Enter PR Title's"
          h="70px"
          maxW={'60%'}
          isDisabled={isChecked}
        />

        <Text
          fontSize={'md'}
          pt={2}
          fontFamily={'heading'}
          fontWeight={'semibold'}
        >
          Exclude by Label's
        </Text>
        <Text fontSize={'sm'} fontFamily={'heading'}>
          Add Labels (comma separated) that DevDynamics should{' '}
          <strong>skip </strong>
          when scanning {name} repository.
        </Text>

        <Textarea
          value={label}
          onChange={handleLabelChange}
          placeholder="Enter Label's"
          h="70px"
          maxW={'60%'}
          isDisabled={isChecked}
        />

        <Flex align="center">
          <Checkbox
            mr={2}
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.currentTarget.checked)}
          >
            {' '}
            <Text
              as="span"
              fontFamily="heading"
              color="text.secondary"
              fontSize="md"
              fontWeight="medium"
            >
              Ignore all PR's
            </Text>
          </Checkbox>
        </Flex>

        <Flex>
          <Button
            variant="primary"
            onClick={() =>
              handleSaveClick(branchValue, prValue, label, id2 as string)
            }
            isDisabled={hahdleExclusionLoading}
            w={'100px'}
            mt={3}
          >
            Save
          </Button>
        </Flex>
      </VStack>

      {(isFetching || hahdleExclusionLoading) && <Loader />}
    </Box>
  );
};

export default IntegrationGitDetails;
