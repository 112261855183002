import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ContentCard from '../components/ContentCard';
import { useInitiativesGql } from '../graphql';
import { InitiativeContext } from '../contexts/initiativesContext';
import {
  DetailViewIcon,
  Initiatives,
  ListViewIcon,
  Loader,
  PageHeader,
  removeEmptyQueryParams,
  useQueryState,
} from '@devd-client/devd/components';
import { Button, Text, useTheme } from '@chakra-ui/react';
import { useNavigate } from 'react-router';

const InitiaTivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const viewSwitchObj = [
  {
    tab: 'List view',
    tooltipText: 'List view',
    TabIcon: ListViewIcon,
  },
  {
    tab: 'Detail view',
    tooltipText: 'Detail view',
    TabIcon: DetailViewIcon,
  },
];

export const InitiativesPage = (data: any) => {
  const navigate = useNavigate();

  const [status, setStatus] = useQueryState('status');
  const [search, setSearch] = useQueryState('search');

  const { dispatch } = useContext(InitiativeContext);
  const [searchInput, setSearchInput] = useState<string>('');
  const [realStatus, setRealStatus] = useState<{
    value: string;
    label: string;
  }>({ value: '', label: 'All' });

  const [currentPage, setCurrentPage] = useState<any>(0);

  const { isFetching } = useInitiativesGql(
    realStatus.value,
    currentPage,
    20,
    searchInput,
    dispatch
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [searchInput]);

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  useEffect(() => {
    if (typeof realStatus.value !== 'undefined') {
      realStatus.value === ''
        ? removeEmptyQueryParams({ status: '' })
        : setStatus(realStatus.value);
    }

    if (typeof searchInput !== 'undefined') {
      searchInput === ''
        ? removeEmptyQueryParams({ search: '' })
        : setSearch(searchInput);
    }
  }, [realStatus.value, searchInput]);

  useEffect(() => {
    status && setRealStatus({ value: status, label: status });

    search && setSearchInput(search);
  }, []);

  return (
    <>
      <InitiaTivesContainer>
        <PageHeader
          headingText="Initiatives"
          component={
            <ButtonContainer>
              <Button
                variant="primary"
                size="sm"
                onClick={() => navigate('create')}
              >
                Create Initiative
              </Button>
            </ButtonContainer>
          }
        />
        <Text
          fontFamily="heading"
          fontSize="sm"
          color="text.secondary"
          mb={'20px'}
        >
          {data?.data?.dto?.title}
        </Text>

        <ContentCard
          handlePageClick={handlePageClick}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          handleRealStatus={setRealStatus}
          realStatus={realStatus}
          showLoader={isFetching}
        />
      </InitiaTivesContainer>
      {isFetching && <Loader />}
    </>
  );
};
