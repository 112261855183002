export const beforeStyles = {
  content: '""',
  position: 'absolute',
  display: 'block',
  width: '100%',
  height: '1.5px',
  bottom: '0',
  left: '0',
  backgroundColor: 'text.secondary',
  transform: 'scaleX(0)',
  transformOrigin: 'top left',
  transition: 'transform 0.3s ease',
};

export const hoverBeforeStyles = {
  transform: 'scaleX(1)',
};
