import Badge from './Badge';
import { ItemMetaType } from '../../../helpers/ActivityLog.types';
import styled from 'styled-components';
import { useTheme } from '@chakra-ui/react';

const LegendBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

type LegendBarProps = {
  activityLogData: any;
};

export const LegendBar = ({ activityLogData }: LegendBarProps) => {
  const {
    colors: { text },
  } = useTheme();

  return (
    <LegendBarContainer>
      {activityLogData?.itemMeta?.map((item: ItemMetaType) => (
        <Badge
          key={item.label}
          color={text.secondary}
          text={item.label}
          dotColor={item.fillColor}
        />
      ))}
    </LegendBarContainer>
  );
};

export default LegendBar;
