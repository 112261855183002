export const ProductivityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.002 512.002"
      {...props}
    >
      <path
        fill="#D7DEED"
        d="M124.503,441.651c-13.433-5.373-22.705-17.881-23.757-32.31
   c-2.008-27.554-7.939-74.167-25.455-109.198c-19.386-38.773-35.311-85.103-35.311-132.416c0-32.368,11.402-72.829,44.139-105.933
   C137.341,7.975,183.064,0,243.019,0c97.105,0,134.826,39.006,151.727,61.794c16.368,22.069,26.142,70.141,33.656,86.622
   c5.702,12.506,8.276,23.725,1.655,38.07c-6.621,14.345-3.859,20.067,1.288,30.161c4.781,9.379,26.549,40.794,33.84,47.082
   c7.357,6.345,11.495,23.449-2.023,30.345c-9.524,4.86-21.15,4.598-22.254,8.643c-1.103,4.046-2.558,10.182,2.241,17.781
   c2.843,4.501,3.839,12.102-6.722,18.01c-1.553,0.868-1.792,2.971-0.643,4.329c2.267,2.681,4.854,7.017,2.916,11.376
   c-2.943,6.621-10.667,6.621-11.402,17.931c-0.501,7.7,5.517,17.655,3.862,30.621c-1.655,12.966-15.449,30.345-38.897,29.794
   c-23.449-0.552-48.075-4.064-59.496,7.357c-6.033,6.033-8.531,32.76-9.567,53.87c-0.646,13.152-14.077,21.714-26.302,16.823
   L124.503,441.651z"
      />
      <path
        fill="#707487"
        d="M310.961,208.633l-1.353-0.781c0.289-1.566,0.487-3.166,0.487-4.814s-0.198-3.249-0.487-4.814
   l1.353-0.781c4.22-2.44,5.669-7.837,3.233-12.061c-2.444-4.215-7.837-5.681-12.061-3.233l-1.431,0.826
   c-2.428-2.071-5.2-3.702-8.263-4.795v-1.624c0-4.88-3.953-8.828-8.828-8.828s-8.828,3.948-8.828,8.828v1.627
   c-3.063,1.092-5.835,2.723-8.263,4.795l-1.431-0.826c-4.233-2.449-9.626-0.983-12.061,3.233c-2.435,4.224-0.988,9.621,3.233,12.061
   l1.353,0.781c-0.289,1.566-0.487,3.166-0.487,4.814c0,1.649,0.198,3.249,0.487,4.814l-1.353,0.781
   c-4.22,2.44-5.668,7.837-3.233,12.061c1.638,2.828,4.604,4.414,7.656,4.414c1.495,0,3.012-0.38,4.405-1.181l1.431-0.826
   c2.428,2.071,5.2,3.702,8.263,4.795v1.624c0,4.88,3.953,8.828,8.828,8.828s8.828-3.948,8.828-8.828v-1.627
   c3.063-1.092,5.835-2.723,8.263-4.795l1.431,0.826c1.393,0.802,2.91,1.181,4.405,1.181c3.052,0,6.017-1.587,7.656-4.414
   C316.629,216.469,315.181,211.072,310.961,208.633z M274.783,203.038c0-4.871,3.961-8.828,8.828-8.828s8.828,3.957,8.828,8.828
   c0,4.871-3.961,8.828-8.828,8.828S274.783,207.909,274.783,203.038z"
      />
      <path
        fill="#959CB3"
        d="M243.019,158.899h-9.533c-0.706-4.902-1.959-9.622-3.748-14.085l8.234-4.754
   c4.222-2.438,5.669-7.837,3.231-12.059c-2.438-4.223-7.837-5.67-12.059-3.231l-8.259,4.769c-3.019-3.827-6.473-7.281-10.3-10.3
   l4.769-8.259c2.438-4.222,0.991-9.621-3.231-12.059c-4.222-2.438-9.621-0.991-12.059,3.231l-4.754,8.234
   c-4.461-1.79-9.182-3.043-14.085-3.748v-9.534c0-4.875-3.953-8.828-8.828-8.828s-8.828,3.953-8.828,8.828v9.533
   c-4.902,0.706-9.622,1.959-14.085,3.748l-4.754-8.234c-2.438-4.222-7.837-5.669-12.059-3.231c-4.222,2.438-5.669,7.837-3.231,12.059
   l4.769,8.259c-3.827,3.019-7.281,6.473-10.3,10.3l-8.259-4.769c-4.222-2.438-9.621-0.991-12.059,3.231
   c-2.438,4.222-0.991,9.621,3.231,12.059l8.234,4.754c-1.79,4.461-3.043,9.182-3.748,14.085h-9.533c-4.875,0-8.828,3.953-8.828,8.828
   c0,4.875,3.953,8.828,8.828,8.828h9.533c0.706,4.902,1.959,9.621,3.748,14.084l-8.234,4.755c-4.222,2.438-5.669,7.837-3.231,12.059
   c2.438,4.222,7.837,5.669,12.059,3.231l8.261-4.769c3.019,3.826,6.473,7.28,10.299,10.299l-4.769,8.261
   c-2.438,4.223-0.991,9.621,3.231,12.059c4.222,2.438,9.621,0.991,12.059-3.231l4.754-8.234c4.461,1.79,9.182,3.042,14.085,3.748
   v9.535c0,4.875,3.953,8.828,8.828,8.828s8.828-3.953,8.828-8.828v-9.533c4.902-0.706,9.622-1.959,14.085-3.748l4.754,8.234
   c2.438,4.223,7.837,5.67,12.059,3.231c4.222-2.438,5.669-7.837,3.231-12.059l-4.769-8.261c3.826-3.02,7.28-6.474,10.299-10.299
   l8.261,4.769c4.222,2.438,9.621,0.991,12.059-3.231c2.438-4.223,0.991-9.621-3.231-12.059l-8.234-4.755
   c1.79-4.462,3.042-9.182,3.748-14.084h9.533c4.875,0,8.828-3.953,8.828-8.828C251.847,162.852,247.894,158.899,243.019,158.899z
    M216.536,167.727c0,24.337-19.802,44.139-44.139,44.139s-44.139-19.802-44.139-44.139s19.802-44.139,44.139-44.139
   S216.536,143.39,216.536,167.727z"
      />
      <path
        fill="#AFB9D2"
        d="M172.397,123.588c-24.377,0-44.139,19.762-44.139,44.139s19.762,44.139,44.139,44.139
   s44.139-19.762,44.139-44.139S196.774,123.588,172.397,123.588z M172.397,194.21c-14.626,0-26.483-11.857-26.483-26.483
   s11.857-26.483,26.483-26.483s26.483,11.857,26.483,26.483S187.023,194.21,172.397,194.21z"
      />
      <g>
        <circle fill="#707487" cx="172.395" cy="123.588" r="8.828" />
        <circle fill="#707487" cx="172.395" cy="211.866" r="8.828" />
        <circle fill="#707487" cx="216.534" cy="167.727" r="8.828" />
        <circle fill="#707487" cx="128.256" cy="167.727" r="8.828" />
      </g>
      <path
        fill="#858AA0"
        d="M348.952,105.933h-0.893c-1.146-5.624-3.393-10.844-6.461-15.473l0.668-0.668
   c3.448-3.448,3.448-9.036,0-12.485c-3.447-3.447-9.036-3.447-12.484,0l-0.668,0.668c-4.629-3.069-9.85-5.314-15.473-6.461v-0.893
   c0-4.875-3.953-8.828-8.828-8.828s-8.828,3.953-8.828,8.828v0.893c-5.624,1.147-10.844,3.392-15.473,6.461l-0.668-0.668
   c-3.447-3.447-9.036-3.447-12.484,0c-3.448,3.448-3.448,9.036,0,12.485l0.668,0.668c-3.069,4.629-5.314,9.848-6.461,15.473h-0.893
   c-4.875,0-8.828,3.953-8.828,8.828s3.953,8.828,8.828,8.828h0.893c1.147,5.624,3.393,10.844,6.461,15.473l-0.668,0.668
   c-3.448,3.447-3.448,9.036,0,12.484c3.447,3.448,9.036,3.448,12.484,0l0.668-0.668c4.629,3.069,9.848,5.314,15.473,6.461v0.894
   c0,4.875,3.953,8.828,8.828,8.828s8.828-3.953,8.828-8.828v-0.893c5.624-1.147,10.844-3.393,15.473-6.461l0.668,0.668
   c3.447,3.448,9.036,3.448,12.484,0c3.448-3.447,3.448-9.036,0-12.484l-0.668-0.668c3.069-4.629,5.314-9.848,6.461-15.473h0.893
   c4.875,0,8.828-3.953,8.828-8.828C357.78,109.885,353.828,105.933,348.952,105.933z M304.813,141.244
   c-14.603,0-26.483-11.88-26.483-26.483s11.88-26.483,26.483-26.483c14.603,0,26.483,11.88,26.483,26.483
   S319.416,141.244,304.813,141.244z"
      />
      <path
        fill="#707487"
        d="M304.813,88.277c-14.626,0-26.483,11.857-26.483,26.483s11.857,26.483,26.483,26.483
   s26.483-11.857,26.483-26.483S319.44,88.277,304.813,88.277z M304.813,123.588c-4.875,0-8.828-3.953-8.828-8.828
   c0-4.875,3.953-8.828,8.828-8.828s8.828,3.953,8.828,8.828C313.641,119.636,309.689,123.588,304.813,123.588z"
      />
    </svg>
  );
};
