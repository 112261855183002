import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '../../hooks/useCustomMutation';

export const QUERY_KEYS = {
  favourite: 'favourite',
  favTeam: 'favTeam',
  threshold: 'threshold',
  allProjects: 'allProjects',
};

export const useAddFavouriteMutation = () => {
  return useCustomMutation([QUERY_KEYS.favourite], ENDPOINTS.addFavourite);
};

export const useDeleteFavouriteMutation = () => {
  return useCustomMutation([QUERY_KEYS.favourite], ENDPOINTS.deleteFavourite);
};

export const useDeleteFavTeamMutation = () => {
  return useCustomMutation([QUERY_KEYS.favTeam], ENDPOINTS.deleteFavTeam);
};

export const useTeamFavMetricsMutation = () => {
  return useCustomMutation([QUERY_KEYS.favTeam], ENDPOINTS.postFavMetrics);
};

export const useFetchThreshold = (metric: string, teamId: string) => {
  return useQuery(
    [QUERY_KEYS.threshold, metric],
    () => ENDPOINTS.fetchThresholdData(metric, teamId),
    {
      enabled: !!metric,
    }
  );
};

export const useThresholdMutation = () => {
  return useCustomMutation([QUERY_KEYS.threshold], ENDPOINTS.postThresholdData);
};

export const useFetchRepo = () => {
  return useQuery([QUERY_KEYS.allProjects], ENDPOINTS.getAllRepos);
};
