import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useFlowPage } from '../../api';
import FlowPage from './devd-flow';

export const Flow = () => {
  const { data } = useFlowPage();

  return (
    <>
      <MetaTags title="Flow | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <FlowPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Flow;
