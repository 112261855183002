import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import React from 'react';
import { useGoalsPage } from '../apis';
import GoalsPage from './devd-goals';

export const Goals = () => {
  const { data } = useGoalsPage();
  return (
    <>
      <MetaTags title="Working Agreements | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <GoalsPage data={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Goals;
