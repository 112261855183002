import { FirstDayOfWeek, useMonth } from '@datepicker-react/hooks';
import Day from './Day';
import { IconButton } from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

interface MonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek | undefined;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
}

function Month({
  year,
  month,
  firstDayOfWeek,
  goToPreviousMonths,
  goToNextMonths,
}: MonthProps) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  return (
    <div>
      <div
        style={{
          marginBottom: '12px',
          marginLeft: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          aria-label="right icon"
          onClick={goToPreviousMonths}
          isRound
          bg="transparent"
          size="xs"
          icon={<AiOutlineLeft />}
        />
        <h3>{monthLabel}</h3>
        <IconButton
          aria-label="right icon"
          onClick={goToNextMonths}
          isRound
          bg="transparent"
          size="xs"
          icon={<AiOutlineRight />}
        />
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          justifyContent: 'center',
          marginBottom: '10px',
          fontSize: '10px',
        }}
      >
        {weekdayLabels.map((dayLabel) => (
          <div style={{ textAlign: 'center' }} key={dayLabel}>
            {dayLabel}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr)',
          justifyContent: 'center',
        }}
      >
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </div>
  );
}

export default Month;
