export function XHTMLIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 550.801 550.801"
      {...props}
    >
      <g>
        <path
          d="M488.426,197.014H475.2v-63.817c0-0.401-0.063-0.799-0.116-1.205c-0.021-2.531-0.833-5.023-2.562-6.993L366.319,3.694
           c-0.026-0.034-0.058-0.045-0.079-0.076c-0.633-0.707-1.371-1.298-2.151-1.804c-0.231-0.155-0.464-0.285-0.706-0.422
           c-0.676-0.366-1.393-0.675-2.131-0.896c-0.2-0.053-0.38-0.135-0.58-0.188C359.87,0.119,359.037,0,358.193,0H97.2
           c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.375c-17.046,0-30.871,13.818-30.871,30.873v160.545
           c0,17.038,13.824,30.87,30.871,30.87h13.226V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601
           V419.302h13.226c17.044,0,30.871-13.832,30.871-30.87V227.887C519.297,210.832,505.47,197.014,488.426,197.014z M97.2,21.601
           h250.193v110.51c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.601z M420.62,351.896h-19.923l-1.34-34.498
           c-0.4-10.832-0.791-23.947-0.791-37.041h-0.4c-2.816,11.496-6.55,24.332-10.03,34.899l-10.974,35.174h-15.899l-9.64-34.899
           c-2.933-10.578-6.012-23.403-8.153-35.174h-0.264c-0.538,12.171-0.934,26.072-1.608,37.314L340,351.896h-18.857l5.753-90.131
           h27.143l8.827,30.088c2.812,10.431,5.611,21.663,7.626,32.231h0.4c2.542-10.441,5.611-22.339,8.554-32.368l9.629-29.951h26.61
           L420.62,351.896z M243.588,278.881v-17.115h69.275v17.115h-24.611v73.016h-20.461v-73.016H243.588z M178.751,314.182v37.715
           h-20.456v-90.131h20.456v34.634h33.56v-34.634h20.332v90.131h-20.332v-37.715H178.751z M92.913,351.896H69.649l26.077-45.604
           l-25.144-44.526h23.398l7.889,16.451c2.674,5.489,4.683,9.903,6.816,14.977h0.269c2.133-5.748,3.878-9.767,6.146-14.977
           l7.625-16.451h23.269l-25.408,43.988l26.739,46.143h-23.533l-8.158-16.315c-3.343-6.286-5.487-10.969-8.026-16.189h-0.264
           c-1.875,5.221-4.147,9.903-6.956,16.189L92.913,351.896z M453.601,523.347H97.2V419.302h356.4V523.347z M492.545,351.896h-56.289
           v-90.131h20.456v73.014h35.833V351.896z"
        />
      </g>
    </svg>
  );
}

export default XHTMLIcon;
