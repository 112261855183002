import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { useState, useEffect } from 'react';
import { useAddUpdateAllocationMutation } from '../../../../apis/index';
import { QUERY_KEYS } from '../../../../apis/index';
import { useTeammenu, useToastHook } from '@devd-client/devd/components';
import { apiClient } from '@devd-client/api';
import { useQueryClient } from '@tanstack/react-query';

interface UpdateButtonProps {
  allocationId: string;
  isOpen: boolean;
  onClose: () => void;
  initialAllocationData: any;
}

const AllocationModal = ({
  allocationId,
  isOpen,
  onClose,
  initialAllocationData,
}: UpdateButtonProps) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const { data: teamData, isLoading: teamLoading } = useTeammenu();

  const [editedAllocationData, setEditedAllocationData] = useState(
    initialAllocationData?.dto.map((item: any) => ({
      ...item,
      allocation: item?.allocation?.toString(),
    })) || []
  );

  const fetchMemberOptions = async (team: string) => {
    try {
      const response = await apiClient(
        `/v1/team/members/org/${localStorage.getItem('orgId')}/team/${team}`,
        {
          method: 'GET',
          headers: {
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return response?.dto?.map((member: any) => ({
        name: member.name,
        email: member.email,
      }));
    } catch (error) {
      console.error('Error fetching member options:', error);
      return [];
    }
  };

  useEffect(() => {
    // Fetch member options for the initial allocationData
    const fetchInitialMemberOptions = async () => {
      const promises = editedAllocationData?.map((item: any) =>
        fetchMemberOptions(item?.team)
      );
      const memberOptions = await Promise.all(promises);
      const updatedAllocationData = [...editedAllocationData];
      memberOptions.forEach((options: string[], index: number) => {
        updatedAllocationData[index].memberOptions = options;
      });
      setEditedAllocationData(updatedAllocationData);
    };

    fetchInitialMemberOptions();
  }, []);

  const fetchMemberOptionsOnChange = async (index: number) => {
    const team = editedAllocationData[index].team;
    const memberOptions = await fetchMemberOptions(team);
    const updatedAllocationData = [...editedAllocationData];
    updatedAllocationData[index].memberOptions = memberOptions;
    setEditedAllocationData(updatedAllocationData);
  };

  const handleTeamChange = (index: number, value: string) => {
    const updatedAllocationData = [...editedAllocationData];
    updatedAllocationData[index].team = value;
    setEditedAllocationData(updatedAllocationData);
    fetchMemberOptionsOnChange(index);
  };

  const handleAllocationChange = (index: number, value: string) => {
    const newValue = value.replace(/[^0-9]/g, '');
    const parsedValue = Math.min(parseFloat(newValue), 100);

    const updatedAllocationData = [...editedAllocationData];
    updatedAllocationData[index].allocation =
      isNaN(parsedValue) || newValue.trim() === ''
        ? ''
        : parsedValue.toString();
    setEditedAllocationData(updatedAllocationData);
  };

  const handleMemberChange = (index: number, value: string) => {
    const updatedAllocationData = [...editedAllocationData];
    updatedAllocationData[index].member = value;
    setEditedAllocationData(updatedAllocationData);
  };

  const handleDeleteRow = (index: any) => {
    setEditedAllocationData((prevData: any) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const addRow = async () => {
    const newRow = {
      orgId: localStorage.getItem('orgId'),
      initiative: allocationId,
      team: teamData?.dto[0]?.name || '',
      member: '',
      allocation: '',
      memberOptions: [],
    };
    const memberOptions = await fetchMemberOptions(newRow.team);
    newRow.memberOptions = memberOptions;
    setEditedAllocationData([...editedAllocationData, newRow]);
  };

  const { mutate: updateAllocation, isLoading: updateInitiativeLoading } =
    useAddUpdateAllocationMutation();

  const UpdateAllocationDetail = () => {
    const formattedData = editedAllocationData?.map((item: any) => ({
      orgId: localStorage.getItem('orgId'),
      initiative: item.initiative,
      team: item.team,
      member: item.member,
      allocation: parseFloat(item.allocation),
    }));

    updateAllocation(
      { formattedData, allocationId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.allocation]);
          newToast({
            message: `Initiative Allocation detail updated successfully!`,
            status: 'success',
          });
          setTimeout(() => {
            onClose();
          }, 200);
        },
        onError: (err: any) => {
          newToast({
            message:
              err?.message ??
              'Some error in changing initiative allocation details. Contact DevDynamics support',
            status: 'error',
          });
          setTimeout(() => {
            onClose();
          }, 200);
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={'4xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily="Inter">
            Update Allocation for initiative {allocationId}
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="flex-end" marginBottom="2">
              <Button
                onClick={addRow}
                variant="primary"
                size="sm"
                rightIcon={<AiOutlinePlus />}
              >
                Add Member
              </Button>
            </Flex>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>Team</Th>
                  <Th>Member</Th>
                  <Th>Allocation (%)</Th>
                  <Th>Delete</Th>
                </Tr>
              </Thead>
              <Tbody>
                {editedAllocationData?.map((item: any, index: number) => (
                  <Tr key={index}>
                    <Td>
                      <Select
                        value={item.team}
                        onChange={(e) =>
                          handleTeamChange(index, e.target.value)
                        }
                      >
                        {teamData?.dto.map((team: any) => (
                          <option key={team.name} value={team.name}>
                            {team.name}
                          </option>
                        ))}
                      </Select>
                    </Td>
                    <Td>
                      <Select
                        value={item.member}
                        onChange={(e) =>
                          handleMemberChange(index, e.target.value)
                        }
                      >
                        {item?.memberOptions?.map((member: any) => (
                          <option key={member.email} value={member.email}>
                            {member.name === ' ' ? member.email : member.name}
                          </option>
                        ))}
                      </Select>
                    </Td>
                    <Td>
                      <Box border="5px" borderColor="blackAlpha.200">
                        <Input
                          type="text"
                          value={item.allocation}
                          onChange={(e) =>
                            handleAllocationChange(index, e.target.value)
                          }
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Button
                        onClick={() => handleDeleteRow(index)}
                        variant="primary"
                        size="sm"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-label="Delete"
                        isDisabled={
                          !item.memberOptions || item.memberOptions.length === 0
                        }
                      >
                        <AiOutlineDelete />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button variant="secondary" size="sm" onClick={onClose} mr={3}>
              Close
            </Button>
            <Button variant="filled" size="sm" onClick={UpdateAllocationDetail}>
              Update Allocation
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AllocationModal;
