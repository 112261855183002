export function StopWatchIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.38853 7.80081L9.71967 6.46967C10.0126 6.17678 10.4874 6.17678 10.7803 6.46967C11.0732 6.76256 11.0732 7.23744 10.7803 7.53033L9.44919 8.86147C9.48233 8.98539 9.5 9.11563 9.5 9.25C9.5 10.0784 8.82843 10.75 8 10.75C7.17157 10.75 6.5 10.0784 6.5 9.25C6.5 8.42157 7.17157 7.75 8 7.75C8.13437 7.75 8.26461 7.76767 8.38853 7.80081Z"
        fill={props.color || '#2A2A2F'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.75 0.75C5.75 0.335786 6.08579 0 6.5 0H9.5C9.91421 0 10.25 0.335786 10.25 0.75C10.25 1.16421 9.91421 1.5 9.5 1.5H8.75V2.5C8.75 2.51378 8.74963 2.52748 8.74889 2.54107C10.0512 2.68482 11.242 3.19908 12.2133 3.97611L12.2197 3.96967L12.9697 3.21967C13.2626 2.92678 13.7374 2.92678 14.0303 3.21967C14.3232 3.51256 14.3232 3.98744 14.0303 4.28033L13.2803 5.03033L13.2739 5.03669C14.1976 6.19145 14.75 7.65622 14.75 9.25C14.75 12.9779 11.7279 16 8 16C4.27208 16 1.25 12.9779 1.25 9.25C1.25 7.65622 1.80237 6.19145 2.72611 5.03669L2.71967 5.03033L1.96967 4.28033C1.67678 3.98744 1.67678 3.51256 1.96967 3.21967C2.26256 2.92678 2.73744 2.92678 3.03033 3.21967L3.78033 3.96967L3.78669 3.97611C4.75805 3.19908 5.94877 2.68482 7.25111 2.54107C7.25037 2.52748 7.25 2.51378 7.25 2.5V1.5H6.5C6.08579 1.5 5.75 1.16421 5.75 0.75ZM8 14.5C10.8995 14.5 13.25 12.1495 13.25 9.25C13.25 6.3505 10.8995 4 8 4C5.10051 4 2.75 6.3505 2.75 9.25C2.75 12.1495 5.10051 14.5 8 14.5Z"
        fill={props.color || '#2A2A2F'}
      />
    </svg>
  );
}
