export function StarIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_7754_15681)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.99992 0.238281C5.82984 0.238281 5.66331 0.28694 5.51998 0.378512C5.37849 0.468915 5.26544 0.597416 5.1938 0.749178L3.83302 3.4964C3.83035 3.5018 3.82779 3.50725 3.82535 3.51276C3.82474 3.51412 3.82378 3.51531 3.82257 3.5162C3.82136 3.51708 3.81994 3.51764 3.81846 3.5178C3.81324 3.51837 3.80803 3.51905 3.80283 3.51982L0.80662 3.9637C0.641018 3.97981 0.483097 4.04195 0.350827 4.14327C0.213965 4.2481 0.110439 4.39037 0.0527865 4.55284C-0.00486506 4.71531 -0.0141677 4.89102 0.0260058 5.05868C0.0660237 5.22567 0.153425 5.37755 0.277683 5.49605L2.47092 7.61218L2.47592 7.61692C2.47904 7.61985 2.4814 7.62351 2.48276 7.62759C2.48411 7.63165 2.48443 7.63599 2.48367 7.64021L2.48319 7.64301L1.96056 10.7016L1.96043 10.7024C1.93172 10.8682 1.95002 11.0387 2.01329 11.1945C2.07664 11.3507 2.18255 11.4858 2.31895 11.5847C2.45535 11.6836 2.61677 11.7422 2.78484 11.7539C2.95257 11.7655 3.12021 11.7299 3.26876 11.6513L3.26966 11.6507L5.96921 10.224C5.97889 10.2198 5.98935 10.2176 5.99994 10.2176C6.01052 10.2176 6.02098 10.2198 6.03067 10.224L8.7302 11.6507C8.87883 11.7297 9.04709 11.7656 9.215 11.7539C9.38309 11.7422 9.54449 11.6836 9.68086 11.5847C9.81732 11.4858 9.92318 11.3507 9.98652 11.1945C10.0498 11.0387 10.0681 10.8683 10.0394 10.7026L10.0393 10.7016L9.51663 7.64301L9.5162 7.64021C9.51543 7.63599 9.51578 7.63165 9.51706 7.62759C9.51843 7.62352 9.52083 7.61985 9.52392 7.61692L9.52889 7.61218L11.7222 5.49603C11.8464 5.37753 11.9339 5.22567 11.9738 5.05868C12.014 4.89102 12.0047 4.71531 11.9471 4.55284C11.8894 4.39037 11.7859 4.2481 11.649 4.14327C11.5168 4.04195 11.3588 3.97981 11.1932 3.9637L8.19702 3.51982C8.19182 3.51905 8.18661 3.51837 8.18139 3.5178C8.1799 3.51764 8.17848 3.51708 8.17727 3.5162C8.17606 3.51531 8.17511 3.51412 8.1745 3.51276C8.17206 3.50725 8.1695 3.5018 8.16682 3.4964L6.80605 0.749166C6.73441 0.59741 6.62136 0.468913 6.47987 0.378512C6.33654 0.28694 6.17 0.238281 5.99992 0.238281Z"
          fill={props.color || '#1DA44A'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7754_15681">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StarIcon;
