import { apiClient } from '@devd-client/api';

export const getMetricsList = (): Promise<any> =>
  apiClient(
    `/v1/config/metric/org/${localStorage.getItem('orgId')}?type=metric`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getTeamMetricsList = (teamName: any): Promise<any> =>
  apiClient(
    `/v1/config/metric/org/${localStorage.getItem(
      'orgId'
    )}?type=metric&team=${teamName}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const updateCustomDashboard = (params: any): Promise<any> =>
  apiClient(`/v1/custom-dashboard/${localStorage.getItem('orgId')}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });
