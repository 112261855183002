type LLMResponse = {
  content: string;
  error: string;
  responseCode: number;
};

export function parseLLMResponseData(data: string): LLMResponse {
  const pattern =
    /\{"content": "(.*?)", "error": "(.*?)", "responseCode": (\d+)\}/s;
  const matches = data.match(pattern);
  if (!matches || matches.length < 4) {
    throw new Error('Unable to parse response data');
  }
  return {
    content: matches[1],
    error: matches[2],
    responseCode: parseInt(matches[3]),
  };
}
