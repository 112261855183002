import { Flex, IconButton, Text } from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Container, Divider } from './lineChartCard.styled';

interface LineChartCardProps {
  chart: React.ReactNode;
  heading: string;
  subHeading?: string;
}

export const LineChartCard = ({
  chart,
  heading,
  subHeading,
}: LineChartCardProps) => {
  return (
    <Container>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        h={'37px'}
        backgroundColor={'#fff'}
        px={'12px'}
      >
        <Flex align="center">
          <Text mr="2" as="h4" fontSize="md" fontWeight="normal">
            {heading}
          </Text>
          <Text as="p" fontSize="xs" color="gray.600" mr="2" mt="px">
            {subHeading}
          </Text>
        </Flex>

        <Flex alignItems={'center'}>
          {/* <IconButton
            isRound
            size="sm"
            bg="none"
            aria-label="three dot icon"
            icon={<MdAccessTime />}
          /> */}

          {/* <IconButton
            isRound
            size="sm"
            bg="none"
            aria-label="circle info"
            icon={<AiOutlineInfoCircle size={18} />}
          />

          <IconButton
            isRound
            size="sm"
            bg="none"
            aria-label="three dot icon"
            icon={<BsThreeDotsVertical size={17} />}
          /> */}
        </Flex>
      </Flex>

      <Divider />

      <Flex h={'210px'} mx={'16px'} mt={'24px'} mb={'12px'}>
        {chart}
      </Flex>
    </Container>
  );
};
