import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the context value
interface ChatDataContextType {
  dataFromSummaryItem: any;
  setData: (data: any) => void;
  toggleChatbox: () => void;
  isOpen: any;
  setIsOpen: any;
}

// Create the context
const ChatDataContext = createContext<ChatDataContextType | undefined>(
  undefined
);

// Create a hook to use the context
export const useChatData = () => {
  const context = useContext(ChatDataContext);
  if (!context) {
    throw new Error('useData must be used within a ChatDataProvider');
  }
  return context;
};

interface ChatDataProviderProps {
  children: ReactNode;
}

// Create the provider component
export const ChatDataProvider: React.FC<ChatDataProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [dataFromSummaryItem, setDataFromSummaryItem] = useState<any>(null);

  const setData = (data: any) => {
    setDataFromSummaryItem(data);
  };
  const toggleChatbox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ChatDataContext.Provider
      value={{ dataFromSummaryItem, setData, isOpen, setIsOpen, toggleChatbox }}
    >
      {children}
    </ChatDataContext.Provider>
  );
};
