import { ISelect, PeriodType } from '@devd-client/devd/components';
import { FC } from 'react';
import styled from 'styled-components';
import ChartSection from './ChartSection';
import DetailsSection from './DetailsSection';
import { Divider } from '@chakra-ui/react';

const RightSectionContainer = styled.div`
  flex: 0.8;
  min-width: 300px;
  flex-direction: column;
`;

interface RightSectionProps {
  agreement: any;
  selectedTeam: ISelect;
  selected: PeriodType;
}

const RightSection: FC<RightSectionProps> = ({
  agreement,
  selectedTeam,
  selected,
}) => {
  return (
    <RightSectionContainer>
      <DetailsSection selectedTeam={selectedTeam} agreement={agreement} />
      <Divider my={3} />
      <ChartSection
        chartName={agreement?.name}
        selectedTeam={selectedTeam}
        selected={selected}
      />
    </RightSectionContainer>
  );
};

export default RightSection;
