import { useMetricsTableStore } from '@devd-client/devd/components';
import { useEffect, useState } from 'react';
import { GetOptionsType } from '../../../utils/metricTableTypes';

// below function is what passes values to zustand. if any alteration of data is required we can do it
// in this function before passing to zustand. see teams switch case.

export enum GoalsActionTypes {
  default = '[Goals] Get Goals Data',
  authors = '[Goals] Set Clicked Goal',
}

export function useMetricTableHelpers() {
  const metricFilterBy = useMetricsTableStore();
  const [scrollToTop, setScrollToTop] = useState(false);

  const handleScrollToTop = () => {
    setScrollToTop(!scrollToTop);
  };

  const handleMetricFilterBy = (
    caseName: string,
    origin: string,
    value: string | null,
    ticketType: null | string,
    chartKey = '',
    metricKey = '',
    metric = '',
    heading = ''
  ) => {
    switch (caseName) {
      // PR related
      case 'prFilters': {
        metricFilterBy.setPRValues(
          origin,
          value,
          chartKey,
          metricKey,
          metric,
          heading
        );
        break;
      }

      case 'status': {
        metricFilterBy.setStatus(value ?? 'all');
        break;
      }

      // Issue Related
      case 'issueFilters': {
        metricFilterBy.setIssueValues(
          origin,
          value,
          ticketType,
          chartKey,
          metric,
          heading
        );
        break;
      }

      case 'issueStatusType': {
        metricFilterBy.setIssueStatusType(value ?? 'all');
        break;
      }

      // Common filters
      case 'teams': {
        metricFilterBy.setTeam(value ?? '');
        break;
      }
      default:
        metricFilterBy.reset();
    }
  };

  return {
    handleMetricFilterBy,
    handleScrollToTop,
    scrollToTop,
  };
}

export default useMetricTableHelpers;
