import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import DistributionProgress from './DistributionProgress';
import {
  AppTooltip,
  InsightIcon,
  PeriodType,
} from '@devd-client/devd/components';
import DistributionBarChart from './DistributionBarChart';
import { useInsightMetricGql } from '../../../graphql';
import ReactHtmlParser from 'html-react-parser';

import { useState } from 'react';
import { useNavigate } from 'react-router';
import SquareChart from './SquareChart';
import { BsArrowRight } from 'react-icons/bs';
import { Link as NavLink } from 'react-router-dom';
import { beforeStyles, hoverBeforeStyles } from '../InsightCards.utils';

interface DistributionCardProps {
  chart: string;
  team: string;
  selected: PeriodType;
}

const DistributionCard: React.FC<DistributionCardProps> = ({
  chart,
  team,
  selected,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const { data, isFetching } = useInsightMetricGql(
    chart,
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const navigationLink =
    data?.chartMetadata?.chartTitle === 'Lead Time for Change'
      ? `/dashboard/dora?tab=LEAD_TIME_FOR_CHANGE&${
          window.location.href.split('?')[1]
        }`
      : data?.chartMetadata?.chartTitle === 'PR Size Distribution'
      ? `/metrics/quality?&type=PR_CHANGES&${
          window.location.href.split('?')[1]
        }`
      : '';

  return (
    <>
      {isFetching ? (
        <Box position={'relative'} minWidth="100%" height="248px">
          <Skeleton h="248px" w="100%" />
          <Spinner position={'absolute'} top={'40%'} left={'43%'} />
        </Box>
      ) : data?.chartDataState === 'NO_INTEGRATION' ? (
        <Box
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          position="relative"
          minH="248px"
          border="1px solid #E3E6EA"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            height="100%"
            objectFit="contain"
            src={data?.chartMetadata?.zsImage || ''}
            style={{ position: 'relative' }}
          />
          {isHovered && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius="4px"
              transition="background-color 0.3s" // Add a transition for smooth effect
            >
              {data?.chartMetadata?.source && (
                <Button
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  onClick={() => navigate('/settings/integrations')}
                >
                  Connect{' '}
                  {data?.chartMetadata?.source === 'ISSUE TRACKING'
                    ? 'Jira'
                    : data?.chartMetadata?.source === 'REPOSITORY'
                    ? 'Github'
                    : ''}
                </Button>
              )}
            </Box>
          )}
        </Box>
      ) : data?.chartDataState === 'INTEGRATED' ? (
        <Stack
          bg="#fff"
          padding={3}
          minHeight="248px"
          border="1px solid #E3E6EA"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
          justify="space-between"
        >
          <Flex direction="column" mb={4}>
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {data?.chartMetadata?.chartTitle}
              </Text>

              {(data?.chartMetadata?.chartTitle === 'Lead Time for Change' ||
                data?.chartMetadata?.chartTitle === 'PR Size Distribution') && (
                <Flex
                  as={NavLink}
                  to={navigationLink}
                  align="center"
                  justify="space-between"
                  color="text.secondary"
                  cursor="pointer"
                  position="relative"
                  fontSize="sm"
                  _hover={{ _before: hoverBeforeStyles }}
                  _before={beforeStyles}
                >
                  <Text
                    fontSize="xs"
                    fontWeight="semibold"
                    transition="all 300ms linear"
                    mr={1}
                  >
                    View Details
                  </Text>
                  <BsArrowRight />
                </Flex>
              )}
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {data?.chartMetadata?.info}
            </Text>
          </Flex>

          <Flex direction="column" height={'100%'} justify={'space-between'}>
            {data?.chartMetadata?.chartType === 'stacked_bar_chart' ? (
              <DistributionProgress
                data={data}
                showNumber={
                  data?.chartMetadata?.chartKey === 'WORK_IN_PROGRESS' ||
                  data?.chartMetadata?.chartKey === 'PR_REVIEWER_DISTRIBUTION'
                }
              />
            ) : data?.chartMetadata?.chartType === 'bar_chart' ? (
              <DistributionBarChart data={data} />
            ) : (
              // <SquareChart data={squareData} title={'7 items in progress'} />
              <Box />
            )}

            <Divider />
          </Flex>

          <Flex direction="column">
            <Flex align="center">
              <InsightIcon width="12px" height="12px" />
              <Text
                ml={1}
                fontFamily="heading"
                fontSize="md"
                color="text.primary"
                fontWeight="medium"
              >
                Insight
              </Text>
            </Flex>
            <Flex direction="column">
              {data?.insights?.map((insight: string, index: number) => (
                <Box
                  key={index}
                  fontSize="sm"
                  fontFamily="heading"
                  color="text.secondary"
                >
                  {ReactHtmlParser(insight)}
                </Box>
              ))}
            </Flex>
          </Flex>
        </Stack>
      ) : (
        <Stack
          bg="#fff"
          padding={3}
          minHeight="248px"
          border="1px solid #E3E6EA"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
          justify="space-between"
        >
          <Flex direction="column">
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {data?.chartMetadata?.chartTitle}
              </Text>
              {(data?.chartDataState === 'ERROR' ||
                data?.chartDataState === 'NOT_CONFIGURED' ||
                data?.chartDataState === 'NO_DATA') && (
                <AppTooltip
                  trigger="hover"
                  content={
                    <Flex>
                      <Text
                        color="text.primary"
                        fontFamily="heading"
                        fontSize="sm"
                        mr={1}
                      >
                        {data?.chartError?.message}
                      </Text>
                      <NavLink to={`${data?.chartError?.link}`} color="primary">
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </NavLink>
                    </Flex>
                  }
                >
                  <Box>
                    <Button
                      size={'xs'}
                      color={'error'}
                      border={'1px'}
                      borderColor={'error'}
                      backgroundColor={'white'}
                      fontFamily="heading"
                    >
                      {'why no data?'}
                    </Button>
                  </Box>
                </AppTooltip>
              )}
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {data?.chartMetadata?.info}
            </Text>
          </Flex>

          <Flex direction="column" height={'100%'} justify={'space-between'}>
            {data?.chartMetadata?.chartType === 'stacked_bar_chart' ? (
              <DistributionProgress data={data} />
            ) : data?.chartMetadata?.chartType === 'bar_chart' ? (
              <DistributionBarChart data={data} />
            ) : null}

            <Divider />
          </Flex>

          <Flex direction="column">
            <Flex align="center">
              <InsightIcon width="12px" height="12px" />
              <Text
                ml={1}
                fontFamily="heading"
                fontSize="md"
                color="text.primary"
                fontWeight="medium"
              >
                Insight
              </Text>
            </Flex>
            <Flex direction="column">
              {data?.insights?.map((insight: string, index: number) => (
                <Box
                  key={index}
                  fontSize="sm"
                  fontFamily="heading"
                  color="text.secondary"
                >
                  {ReactHtmlParser(insight)}
                </Box>
              ))}
            </Flex>
          </Flex>
        </Stack>
      )}
    </>
  );
};

export default DistributionCard;
