import { FC, useState } from 'react';
import { useFavTeam, useRemoveFavTeam, useTeammenu } from './apis';
import CustomSelect, { ISelect } from '../customSelect';
import { eliminateHtmlTags } from '../shared/utils';
import { ChakraStylesConfig, components } from 'chakra-react-select';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import {
  AiFillHeart,
  AiOutlineFileSearch,
  AiOutlineHeart,
} from 'react-icons/ai';
import { IoSettingsOutline } from 'react-icons/io5';
import { useToastHook } from '../appToast';
import Loader from '../appLoader';

interface TeamMenuProps {
  selectedTeam: ISelect;
  handleSelectedTeam: (selectedTeam: ISelect) => void;
  mb?: number;
  noDefault?: boolean;
  hideShowDetails?: boolean;
  AuthActionTypes?: any;
  getUser?: any;
  dispatch?: any;
  appState?: any;
  actionHandler?: any;
  prefixContent?: React.ReactNode;
  prefixIcon?: any;
  teams?: string[];
}
export const TeamMenu: FC<TeamMenuProps> = ({
  selectedTeam,
  handleSelectedTeam,
  noDefault,
  mb,
  hideShowDetails,
  appState,
  dispatch,
  getUser,
  AuthActionTypes,
  actionHandler,
  prefixContent,
  prefixIcon,
  teams,
}) => {
  const navigate = useNavigate();
  const { colors } = useTheme();

  const [newToast] = useToastHook();
  const { data: teamData, isLoading } = useTeammenu();
  const [getUserLoading, setGetUserLoading] = useState(false);
  const { mutate: handleFavTeam, isLoading: handleFavTeamLoading } =
    useFavTeam();
  const { mutate: removeFavTeam, isLoading: removeFavTeamLoading } =
    useRemoveFavTeam();

  const teamsOptions: any = [];

  teamData?.dto?.forEach((team: any) => {
    teamsOptions.push({
      value: team.name,
      label: team.name,
      id: team.name,
    });

    if (team.subTeams.length >= 1) {
      teamsOptions.push({
        value: team.name,
        label: team.name,
        options: team.subTeams.map((item: any) => ({
          id: team.name,
          value: item.name,
          label: item.name,
        })),
      });
    }
  });

  const options = noDefault
    ? teamsOptions
    : [{ value: '', label: 'Org', id: 'all' }].concat(teamsOptions || []);

  const targetSubTeam = options?.filter(
    (team: any) =>
      team.options &&
      team.options?.some(
        (subTeam: any) => subTeam.value === appState?.user?.dto?.team
      )
  );

  const targetTeam = options?.filter(
    (el: any) =>
      el.value === appState?.user?.dto?.team ||
      el.value === targetSubTeam[0]?.value
  );

  const newOptions = targetTeam?.concat(
    options?.filter((el: any) => el.value !== targetTeam[0]?.value)
  );

  const onChange = actionHandler
    ? actionHandler
    : (selected: string) =>
        handleSelectedTeam({
          value: eliminateHtmlTags(selected),
          label:
            eliminateHtmlTags(selected) === ''
              ? 'Org'
              : eliminateHtmlTags(selected),
        });

  const chakraStyles: ChakraStylesConfig = {
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 9999,
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        paddingLeft: state.options.some(
          (item: any) => item.label === state?.label
        )
          ? ''
          : '28px',
      };
    },
  };

  const flattenTeams = (teams: any[]) => {
    return teams?.flatMap((team) => {
      return [team, ...team.subTeams];
    });
  };
  const flattenedArray = flattenTeams(teamData?.dto);

  const filteredOptions = flattenedArray
    ?.filter((item: any) => teams?.includes(item.name))
    ?.map((el: any) => ({ value: el.name, label: el.name, id: el.name }));

  return (
    <Flex align="flex-end" mb={mb}>
      <CustomSelect
        size={prefixContent ? 'md' : 'sm'}
        placeholder="Org"
        label={prefixContent ? '' : actionHandler ?? 'Team'}
        chakraStyles={chakraStyles}
        defaultItem={selectedTeam}
        components={
          prefixContent
            ? {
                GroupHeading: () => <div></div>,
                SingleValue: ({ children, ...props }: any) => {
                  return (
                    <components.SingleValue {...props}>
                      <Flex gap={2}>
                        {prefixIcon}
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          color="text.primary"
                        >
                          {`${prefixContent}: `}
                        </Text>
                        <Text
                          fontWeight="semibold"
                          fontFamily="heading"
                          fontSize="sm"
                          color="text.primary"
                        >
                          {children}
                        </Text>
                      </Flex>
                    </components.SingleValue>
                  );
                },
              }
            : { GroupHeading: () => <div></div> }
        }
        onChange={onChange}
        options={
          teams
            ? [{ label: 'Org', value: '', id: 'all' }].concat(filteredOptions)
            : newOptions
        }
        getOptionValue={(option: any) => `${option.id}`}
      />

      {selectedTeam.value && !hideShowDetails && (
        <HStack ml={3} spacing={3}>
          <Box height="32px" width="1.5px" bg="gray.300" />

          <Tooltip hasArrow label="More Details">
            <IconButton
              aria-label="more details"
              size="sm"
              bg="transparent"
              border="1px solid"
              borderColor={'#CBD5E0'}
              onClick={() => navigate(`/teams/${selectedTeam.value}`)}
              icon={
                <AiOutlineFileSearch size={24} color={colors.text.secondary} />
              }
            />
          </Tooltip>

          <Tooltip hasArrow label="Edit Team">
            <IconButton
              bg="transparent"
              border="1px solid"
              borderColor="#CBD5E0"
              size="sm"
              onClick={() =>
                navigate(
                  `/settings/teams/${decodeURIComponent(selectedTeam.value)}`
                )
              }
              aria-label="Search database"
              icon={
                <IoSettingsOutline size={24} color={colors.text.secondary} />
              }
            />
          </Tooltip>

          <Tooltip
            hasArrow
            label={
              decodeURIComponent(selectedTeam.value) ===
              appState?.user?.dto?.team
                ? 'Remove from Favourite Team'
                : 'Mark as Favourite Team'
            }
          >
            <IconButton
              bg="transparent"
              size="sm"
              border="1px solid"
              borderColor="#CBD5E0"
              aria-label="Favorite"
              icon={
                decodeURIComponent(selectedTeam.value) ===
                appState?.user?.dto?.team ? (
                  <AiFillHeart size={24} color={colors.text.secondary} />
                ) : (
                  <AiOutlineHeart size={24} color={colors.text.secondary} />
                )
              }
              onClick={() => {
                decodeURIComponent(selectedTeam.value) ===
                appState?.user?.dto?.team
                  ? removeFavTeam(
                      {
                        name: decodeURIComponent(selectedTeam.value),
                      },
                      {
                        onSuccess: () => {
                          setGetUserLoading(true);
                          getUser()
                            .then((res: any) => {
                              setGetUserLoading(false);
                              dispatch({
                                type: AuthActionTypes.AUTH_READY,
                                payload: { ...appState.user, ...res },
                              });
                            })
                            .catch((err: any) => {
                              newToast({
                                message: 'Something went wrong',
                                status: 'error',
                              });
                              setGetUserLoading(false);
                            });
                          localStorage.removeItem('team');
                        },
                      }
                    )
                  : appState?.user?.dto?.team
                  ? newToast({
                      message: `${appState?.user?.dto?.team} is marked as Favourite. Remove it from favourite and try again.`,
                      status: 'error',
                    })
                  : handleFavTeam(
                      {
                        name: decodeURIComponent(selectedTeam.value),
                      },
                      {
                        onSuccess: () => {
                          setGetUserLoading(true);
                          getUser()
                            .then((res: any) => {
                              setGetUserLoading(false);
                              dispatch({
                                type: AuthActionTypes.AUTH_READY,
                                payload: { ...appState.user, ...res },
                              });
                            })
                            .catch((err: any) => {
                              newToast({
                                message: 'Something went wrong',
                                status: 'error',
                              });
                              setGetUserLoading(false);
                            });
                          localStorage.setItem(
                            'team',
                            decodeURIComponent(selectedTeam.value)
                          );
                        },
                      }
                    );
              }}
            />
          </Tooltip>
        </HStack>
      )}

      {(handleFavTeamLoading || removeFavTeamLoading || getUserLoading) && (
        <Loader />
      )}
    </Flex>
  );
};
