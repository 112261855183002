import { Box, Flex } from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  durationData,
  useQueryState,
  removeEmptyQueryParams,
  PageToolbar,
  TeamMenu,
  DateFilter,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';
import { AcceptanceByLanguage } from '../components/Copilot/AcceptanceByLanguage';
import { CopilotActiveUsers } from '../components/Copilot/CopilotActiveUsers';
import { CopilotLinesAccepted } from '../components/Copilot/CopilotLinesAccepted';

export const Copilot: FC = () => {
  const [appState, dispatch] = useAuth();
  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <>
      <MetaTags title="Copilot | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader headingText={'Copilot'} mb={'10px'} />
          <PageToolbar
            leftComp={
              <Flex align="flex-end">
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              </Flex>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
          <AcceptanceByLanguage />
          <CopilotActiveUsers />
          <CopilotLinesAccepted />
        </Box>
      </Box>
    </>
  );
};

export default Copilot;
