import { FC, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useNavigate } from 'react-router';
import CustomTooltip from './components/customTooltip/CustomTooltip';

interface SprintData {
  sprint: string;
  planned: number;
  added: number;
  removed: number;
}

interface ScopeChangeChartProps {
  data: any;
  showLabel?: boolean;
}

const ScopeChangeChart: FC<ScopeChangeChartProps> = ({ data, showLabel }) => {
  const slant = true;
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const teamId = params.get('team');

  const parsedData: SprintData[] = data?.map((item: any) => ({
    sprint: item?.xLabelName,
    planned: parseInt(item?.plannedTasks, 10),
    added: parseInt(item?.addedTasks, 10),
    removed: parseInt(item?.removedTasks, 10),
  }));

  const maxPlanned = Math.max(...parsedData.map((item) => item.planned));
  const minRemoved = Math.min(...parsedData.map((item) => item.removed));

  const handleClick = (label: any) => {
    if (data) {
      const labelId = data?.find(
        (item: any) => item?.xLabelName === label?.value
      ).xLabelId;

      if (labelId) {
        navigate(`/sprint/${labelId}${teamId && `?team=${teamId}`}`);
      }
    }
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={'#0095E6'}
          fontSize={7.6}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  return (
    <Box>
      <Flex justifyContent="center">
        <ResponsiveContainer width="100%" height={showLabel ? 360 : 220}>
          <BarChart
            data={parsedData}
            margin={{
              top: 20,
              right: 30,
              left: 80,
              bottom: 50,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="sprint"
              axisLine={false}
              tickLine={false}
              style={{
                fontSize: 10,
                fontWeight: 500,
                cursor: 'pointer',
              }}
              angle={slant ? -20 : undefined}
              textAnchor={slant ? 'end' : undefined}
              interval={slant ? 0 : undefined}
              tick={slant ? renderCustomTick : undefined}
              onClick={handleClick}
            />
            <YAxis
              yAxisId="planned"
              domain={[0, maxPlanned]}
              label={{
                value: 'Planned',
                angle: -90,
                position: 'insideLeft',
                fontSize: 11,
                fontWeight: 600,
                dx: -20,
              }}
              axisLine={false}
              tickLine={false}
              style={{ fontSize: 11, fontWeight: 400 }}
              tickFormatter={(value) => `${value} tasks`}
            />
            <YAxis
              yAxisId="added_removed"
              domain={[minRemoved, maxPlanned + minRemoved]}
              orientation="right"
              label={{
                value: 'Added / Removed',
                angle: -90,
                position: 'insideLeft',
                fontSize: 11,
                fontWeight: 600,
                dy: 15,
                dx: 55,
              }}
              axisLine={false}
              tickLine={false}
              style={{ fontSize: 11, fontWeight: 400 }}
              tickFormatter={(value) =>
                `${value >= 0 ? '+' : ''}${value} tasks`
              }
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<CustomTooltip hideBtn />}
              wrapperStyle={{ outline: 'none' }}
              position={{ y: -30 }}
            />
            <Legend
              verticalAlign="bottom"
              iconType="circle"
              iconSize={11}
              height={36}
              align="left"
              wrapperStyle={{ bottom: -20, left: 35, fontSize: 11 }}
            />
            <Bar
              dataKey="planned"
              fill="#8884d8"
              name="Planned"
              yAxisId="planned"
              barSize={12}
            />
            <Bar
              dataKey="added"
              fill="#82ca9d"
              name="Added"
              yAxisId="added_removed"
              barSize={12}
            />
            <Bar
              dataKey="removed"
              fill="#ff7f50"
              name="Removed"
              yAxisId="added_removed"
              barSize={12}
            />
          </BarChart>
        </ResponsiveContainer>
      </Flex>
    </Box>
  );
};

export default ScopeChangeChart;
