import React, { Fragment } from 'react';
import { pack, hierarchy } from 'd3-hierarchy';
import { Tooltip } from '@chakra-ui/react';
import * as d3 from 'd3';

const WIDTH = 70;
const HEIGHT = 85;

interface BubbleProps {
  handleDrawerOpen: () => void;
  setBubbleData: (data: any) => void;
  [key: string]: any;
}

const radiusScale = (value: number) => {
  // range decides the min/max radius
  //domain array confirms the value coming in between 1 to 150
  const fx = d3.scaleSqrt().range([3, 20]).domain([1, 150]);

  return value === 0 ? 0 : fx(value);
};

const BubbleChart = ({
  chartData,
  row,
  setRowName,
  handleDrawerOpen,
  setBubbleData,
  data,
}: BubbleProps) => {
  const nodes = hierarchy(chartData).sum((d: any) => Number(d.count));

  const packLayout = pack()
    .size([WIDTH, HEIGHT])
    .radius((r) => radiusScale(Number(r.value)))
    .padding(6);

  const root = packLayout(nodes);

  return (
    <svg width={WIDTH} height={HEIGHT}>
      <Bubbles
        data={data}
        row={row}
        setRowName={setRowName}
        nodes={root.children}
        handleDrawerOpen={handleDrawerOpen}
        setBubbleData={setBubbleData}
      />
    </svg>
  );
};

const Bubbles = ({
  nodes,
  handleDrawerOpen,
  setBubbleData,
  row,
  setRowName,
  data,
}: BubbleProps) =>
  nodes.map((node: any, i: any) => (
    <Fragment key={i}>
      <Bubble
        key={i}
        data={data}
        leaf={node}
        setRowName={setRowName}
        row={row}
        handleDrawerOpen={handleDrawerOpen}
        setBubbleData={setBubbleData}
      />
    </Fragment>
  ));

const Bubble = ({
  leaf,
  handleDrawerOpen,
  setBubbleData,
  row,
  setRowName,
  data,
}: BubbleProps) => {
  const emojiSize = Math.max(10, !isNaN(leaf.r) && leaf.r);

  return (
    <Tooltip hasArrow label={`${leaf.data.count} ${leaf.data.label}`}>
      <g
        transform={`translate(${isNaN(leaf.x) ? 0 : leaf.x},${
          isNaN(leaf.y) ? 0 : leaf.y
        })`}
        style={{ cursor: 'pointer' }}
      >
        <circle
          r={isNaN(leaf.r) ? 0 : leaf.r}
          fill={leaf.data.fillColor}
          onClick={() => {
            handleDrawerOpen();
            setBubbleData({ clickedData: leaf.data, date: data.date });
            setRowName(row.name);
          }}
          stroke={leaf.data.fillColor}
          strokeWidth="3"
        ></circle>

        <text
          y={emojiSize / 3.5}
          textAnchor="middle"
          style={{ fontSize: `${!isNaN(leaf.r) && leaf.r - 2.5}px` }}
          fill="#fff"
        ></text>
      </g>
    </Tooltip>
  );
};

export default React.memo(BubbleChart);
