import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import {
  ConfirmButton,
  DataGrid,
  Loader,
  Paginate,
  useToastHook,
} from '@devd-client/devd/components';
import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate } from 'react-router';
import {
  QUERY_KEYS,
  useCustomMetricsDeleteMutation,
  useCustomfield,
  useFetchCustomMetrics,
  useMeasure,
} from '../../api';
import { useQueryClient } from '@tanstack/react-query';

import moment from 'moment';

const CustomMetricsTable = () => {
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { data: measureData, isLoading: measureDataLoading } = useMeasure();
  const [currentPage, setCurrentPage] = useState(0);
  const { data: customFieldData, isLoading: customFieldLoading } =
    useCustomfield();

  const { data, isFetching: customMetricLoading } = useFetchCustomMetrics();

  const { mutate: deleteCustomMetric, isLoading: deleteCustomMetricLoading } =
    useCustomMetricsDeleteMutation();

  const rows: any[] = useMemo(() => data?.dto || [], [data?.dto]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Name',
      }),
      columnHelper.accessor('description', {
        cell: (info) => info.getValue(),
        header: 'Description',
        size: 200,
      }),

      columnHelper.accessor('measure', {
        cell: (info) => (
          <>
            {
              measureData?.dto?.find(
                (item: any) => item.metricId === parseInt(info.getValue())
              )?.displayName
            }
          </>
        ),

        header: 'Measure',
      }),

      columnHelper.accessor('updatedAt', {
        cell: (info) => moment(info.getValue()).format('ll'),

        header: 'Updated At',
        size: 120,
      }),

      columnHelper.accessor('updatedBy', {
        cell: (info) => info.getValue(),

        header: 'Updated By',
        minSize: 140,
      }),

      columnHelper.accessor('actions', {
        cell: (info) => (
          <Flex gap={2}>
            <Tooltip label="Edit" hasArrow>
              <IconButton
                aria-label="edit"
                bg="transparent"
                size="sm"
                color="text.secondary"
                icon={<MdOutlineEdit size={16} />}
                position={'static'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`edit?metric=${info.row.original?.name}`);
                }}
              />
            </Tooltip>

            <ConfirmButton
              buttonText="Delete"
              showIcon
              bodyText={`Do you really want to delete this Metric - ${info.row.original?.name}?`}
              headerText="Are you sure?"
              onSuccessAction={() => {
                deleteCustomMetric(
                  { name: info.row.original?.name },
                  {
                    onSuccess() {
                      queryClient.invalidateQueries([QUERY_KEYS.customMetrics]);
                      newToast({
                        message: `${info.row.original?.name} Deleted Successfully!`,
                        status: 'success',
                      });
                    },
                    onError(e: any) {
                      newToast({
                        message: e?.message,
                        status: 'error',
                      });
                    },
                  }
                );
              }}
            />
          </Flex>
        ),
        header: 'Actions',
      }),
    ],
    [data?.dto]
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );
  return (
    <Box>
      <DataGrid
        data={rows}
        columns={columns}
        showLoader={customMetricLoading}
        maxH="80vh"
        sticky="firstCol"
      />

      <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={data?.currentPage + 1}
          totalPageCount={data?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
      {(customFieldLoading ||
        deleteCustomMetricLoading ||
        measureDataLoading) && <Loader />}
    </Box>
  );
};

export default CustomMetricsTable;
