import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface TeamComparisonState {
  isChecked: boolean;
  toggleSwitch: () => void;

  selectedTeams: any;
  setSelectedTeams: (selectedTeams: any) => void;

  selectedMetrics: any;
  setSelectedMetrics: (selectedMetrics: any) => void;
}

export const useTeamComparisonStore = create<TeamComparisonState>()(
  persist(
    (set) => ({
      isChecked: true,
      toggleSwitch: () => set((state) => ({ isChecked: !state.isChecked })),

      selectedTeams: [{ value: 'all', label: 'All Teams' }],
      setSelectedTeams: (selectedTeams) => set({ selectedTeams }),

      selectedMetrics: [],
      setSelectedMetrics: (selectedMetrics) => set({ selectedMetrics }),
    }),
    {
      name: 'team-comparison-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
