import { ButtonGroup, Button, Box } from '@chakra-ui/react';

const DAYS = [
  {
    key: 'sunday',
    label: 'Su',
  },
  {
    key: 'monday',
    label: 'Mo',
  },
  {
    key: 'tuesday',
    label: 'Tu',
  },
  {
    key: 'wednesday',
    label: 'We',
  },
  {
    key: 'thursday',
    label: 'Th',
  },
  {
    key: 'friday',
    label: 'Fr',
  },
  {
    key: 'saturday',
    label: 'Sa',
  },
];

type Props = {
  days: boolean[];
  setDays: (days: boolean[]) => void;
};
export const ToggleDays = ({ days, setDays }: Props) => {
  const handleToggle = (index: number) => {
    const updatedDays = days.map((selected, i) =>
      i === index ? !selected : selected
    );
    setDays(updatedDays);
  };

  return (
    <Box>
      <ButtonGroup
        isAttached={false}
        size="sm"
        display="flex"
        justifyContent="flex-start"
      >
        {DAYS.map((day, index) => (
          <Button
            key={day.key}
            onClick={() => handleToggle(index)}
            bg={days[index] ? 'primary' : 'white'}
            color={days[index] ? 'white' : 'text.secondary'}
            variant={days[index] ? 'solid' : 'outline'}
            border="1px solid #DCE5F2"
            fontSize="small"
            borderRadius="full"
            rounded="full"
            w={8}
            h={8}
            _hover={{ bg: days[index] ? 'primary' : 'primaryBg' }}
          >
            {day.label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default ToggleDays;
