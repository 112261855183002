import { GraphQLClient } from 'graphql-request';
import { getGraphQLBaseURL } from '../helpers/getBaseURL';

export const graphQLClient = new GraphQLClient(
  process.env['NX_GRAPHQL_HOST'] as string
);

export const updateClientGraphQLWithRegion = (region: string | null) => {
  const graphQLBaseURL = getGraphQLBaseURL(region);

  if (graphQLBaseURL) {
    graphQLClient.setEndpoint(graphQLBaseURL);
  } else {
    graphQLClient.setEndpoint(process.env['NX_GRAPHQL_HOST'] as string);
  }
};

export default graphQLClient;
