import styled from 'styled-components';

export const DetailsSectionContainer = styled.div`
  min-height: fit-content;
  width: 100%;
  background-color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-radius: 2px;
  margin-top: 32px;
  position: relative;
`;

export const TagLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.success};
  letter-spacing: 0.5px;
  padding: 4px 6px;
  background-color: #f0fff4;
  margin-right: 5px;
`;

export const TagLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const DetailsHeading = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const DetailsParagraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: 12px;
`;

export const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const ToggleButtonContainer = styled.div`
  padding-top: 12px;
`;

export const ToggleText = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: -18px;
`;

export const SettingBtnContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 10px;
`;
