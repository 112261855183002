import React, { FC } from 'react';
import {
  LeftSection,
  PageToolbarContainer,
  RightSection,
} from './PageToolbar.styled';

interface PageToolbarProps {
  leftComp?: React.ReactNode;
  rightComp?: React.ReactNode;
  mt?: string;
  mb?: string;
}

export const PageToolbar: FC<PageToolbarProps> = ({
  leftComp,
  rightComp,
  mt,
  mb,
}) => (
  <PageToolbarContainer mt={mt} mb={mb}>
    <LeftSection>{leftComp && leftComp}</LeftSection>
    <RightSection>{rightComp && rightComp}</RightSection>
  </PageToolbarContainer>
);

export default PageToolbar;
