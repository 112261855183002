export function InitiativeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M6.41922 13.0054C7.29203 13.0054 7.99958 12.2979 7.99958 11.4251C7.99958 10.5523 7.29203 9.84473 6.41922 9.84473C5.54641 9.84473 4.83887 10.5523 4.83887 11.4251C4.83887 12.2979 5.54641 13.0054 6.41922 13.0054Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.15137 11.4248H4.83887"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11.4248H14.8482"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.4775 9.05469L14.8481 11.4252L12.4775 13.7958"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.41797 2.20605V7.21052L10.8956 4.70829L6.41797 2.20605Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.41797 9.84487V7.21094"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
