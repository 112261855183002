export const QualityIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.24577 10.3068L4.66667 14.6668L7.7256 12.8315C7.82537 12.7716 7.87525 12.7417 7.9285 12.73C7.97561 12.7196 8.02439 12.7196 8.0715 12.73C8.12475 12.7417 8.17463 12.7716 8.2744 12.8315L11.3333 14.6668L10.7546 10.3049M10.9506 2.83275C11.0536 3.08176 11.2512 3.2797 11.5 3.38303L12.3726 3.74448C12.6216 3.84764 12.8195 4.04549 12.9226 4.29453C13.0258 4.54356 13.0258 4.82337 12.9226 5.0724L12.5615 5.9444C12.4583 6.19354 12.4581 6.47363 12.5618 6.72266L12.9223 7.59439C12.9735 7.71774 12.9998 7.84995 12.9998 7.98347C12.9998 8.11699 12.9736 8.24921 12.9225 8.37257C12.8714 8.49593 12.7965 8.60801 12.702 8.70241C12.6076 8.79681 12.4955 8.87168 12.3721 8.92273L11.5002 9.28393C11.2512 9.38689 11.0532 9.58453 10.9499 9.83338L10.5885 10.706C10.4853 10.955 10.2875 11.1529 10.0385 11.256C9.78943 11.3592 9.50963 11.3592 9.26061 11.256L8.38864 10.8949C8.13961 10.792 7.85991 10.7922 7.61104 10.8954L6.73845 11.2564C6.48956 11.3593 6.21001 11.3592 5.96118 11.2561C5.71236 11.1531 5.51463 10.9554 5.41141 10.7067L5.04986 9.83379C4.9469 9.58478 4.74927 9.38685 4.50042 9.28351L3.62783 8.92206C3.37892 8.81895 3.18113 8.62122 3.07794 8.37233C2.97475 8.12344 2.97461 7.84376 3.07755 7.59477L3.43873 6.72277C3.54163 6.47373 3.54142 6.19402 3.43815 5.94514L3.07749 5.07189C3.02635 4.94855 3.00002 4.81634 3 4.68281C2.99998 4.54929 3.02627 4.41707 3.07736 4.29371C3.12846 4.17035 3.20336 4.05827 3.29778 3.96387C3.39221 3.86947 3.50431 3.79461 3.62768 3.74355L4.49965 3.38235C4.74844 3.27948 4.94624 3.0821 5.04965 2.83352L5.41109 1.9609C5.51424 1.71187 5.71209 1.51401 5.96111 1.41086C6.21013 1.30771 6.48993 1.30771 6.73896 1.41086L7.61092 1.77205C7.85995 1.87495 8.13965 1.87474 8.38853 1.77147L9.26149 1.41142C9.51048 1.30833 9.79022 1.30835 10.0392 1.41148C10.2882 1.51461 10.486 1.71241 10.5892 1.96138L10.9507 2.83426L10.9506 2.83275Z"
        stroke={props.color || '#626266'}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
