export const BottleNeckIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="#000000"
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
    >
      <g>
        <g>
          <g>
            <path
              d="M460,0H245c-5.522,0-10,4.478-10,10v45.768c-31.111,4.824-55,31.791-55,64.232s23.889,59.408,55,64.232V235h-60
               c-5.522,0-10,4.478-10,10c0,24.813-20.187,45-45,45s-45-20.187-45-45c0-5.522-4.478-10-10-10H10c-5.522,0-10,4.478-10,10v215
               c0,16.542,13.458,30,30,30h215h215c16.542,0,30-13.458,30-30V245V30C490,13.458,476.542,0,460,0z M235,470H30
               c-5.514,0-10-4.486-10-10V255h35.768c4.824,31.111,31.791,55,64.232,55s59.408-23.889,64.232-55H235v45.768
               c-31.111,4.824-55,31.791-55,64.232s23.889,59.408,55,64.232V470z M470,460c0,5.514-4.486,10-10,10H255v-50
               c0-5.522-4.478-10-10-10c-24.813,0-45-20.187-45-45s20.187-45,45-45c5.522,0,10-4.478,10-10v-55h50c5.522,0,10-4.478,10-10
               c0-24.813,20.187-45,45-45s45,20.187,45,45c0,5.522,4.478,10,10,10h55V460z M470,235h-45.768c-4.824-31.111-31.791-55-64.232-55
               s-59.408,23.889-64.232,55H255v-60c0-5.522-4.478-10-10-10c-24.813,0-45-20.187-45-45s20.187-45,45-45c5.522,0,10-4.478,10-10V20
               h205c5.514,0,10,4.486,10,10V235z"
            />
            <path d="M445,35h-30v20h20v15h20V45C455,39.478,450.522,35,445,35z" />
            <path d="M415,455h30c5.522,0,10-4.478,10-10v-25h-20v15h-20V455z" />
            <path d="M55,420H35v25c0,5.522,4.478,10,10,10h30v-20H55V420z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
