import { apiClient } from '@devd-client/api';

export const getInvestmentPage = (): Promise<any> =>
  apiClient(
    `/v2/account/org/${localStorage.getItem('orgId')}/page/Investment`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
