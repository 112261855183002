import { FC, useContext, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { ItemMetaType } from '../../helpers/Investments.types';
import { InvestmentsContext } from '../../context/investmentContext';
import { InvestmentsActionTypes } from '../../reducer/investmentReducer';
import CustomTooltip from './CustomTooltip';
import moment from 'moment';

const StackBarChart: FC = () => {
  const {
    dispatch,
    state: { barChartData, chartMetaData },
  } = useContext(InvestmentsContext);

  useEffect(() => {
    if (barChartData.length === 0) return;
    dispatch({
      type: InvestmentsActionTypes.SELECTED_CHART_DATA,
      payload: {
        endDate: barChartData[barChartData.length - 1]?.date,
        startDate: moment(barChartData[barChartData.length - 1]?.date)
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
        name: barChartData[barChartData.length - 1]?.name,
      },
    });
  }, [barChartData]);

  const renderColorfulLegendText = (value: string) => {
    return <span style={{ color: '#718096' }}>{value}</span>;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barGap={3}
        data={barChartData}
        margin={{
          top: 0,
          right: 5,
          left: -14,
          bottom: 24,
        }}
      >
        <XAxis
          dataKey="name"
          name="name"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 9, fontWeight: 500 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Legend
          verticalAlign="bottom"
          iconType="circle"
          iconSize={10}
          align="left"
          wrapperStyle={{
            left: 10,
            bottom: 20,
            fontSize: 10,
            fontFamily: 'Inter',
            fontWeight: 400,
          }}
          formatter={renderColorfulLegendText}
        />
        {chartMetaData.map((item: ItemMetaType) => (
          <Bar
            key={item.label}
            dataKey={item.label}
            stackId="a"
            barSize={16}
            cursor={'pointer'}
            fill={item.fillColor}
            onClick={(data: any) => {
              dispatch({
                type: InvestmentsActionTypes.SELECTED_CHART_DATA,
                payload: {
                  endDate: data?.payload?.date,
                  startDate: moment(data?.payload?.date)
                    .subtract(6, 'days')
                    .format('YYYY-MM-DD'),
                  name: data?.name,
                },
              });
            }}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackBarChart;
