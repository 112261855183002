import styled from 'styled-components';

type CustomBadgeProps = {
  dotColor?: string;
  color?: string;
};

export const BadgeContainer = styled.span<CustomBadgeProps>`
  font-size: 10px;
  font-weight: 400;
  font-family: 'Inter';
  margin-right: 10px;
  line-height: 1.5;
  color: ${(props) => props.color};

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    display: inline-block;
    background-color: ${(props) => props.dotColor || '#ccc'};
    vertical-align: center;
  }
`;
