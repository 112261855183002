export const GrowthIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <circle fill="#273B7A" cx="256" cy="256" r="256" />
      <circle fill="#324A5E" cx="256" cy="256" r="256" />
      <path
        fill="#2B3B4E"
        d="M511.652,269.405l-161.228-161.23l-158.844,86.85l-31.928,36.123l78.867,78.867l-38.833,2.874
       l-93.131,106.927l83.627,83.627C211.185,509.014,233.243,512,256,512C392.888,512,504.68,404.559,511.652,269.405z"
      />
      <path
        fill="#F4E3C3"
        d="M401.606,290.287c-3.303-4.591-9.307-6.397-14.601-4.41c-0.076,0.029-0.152,0.055-0.228,0.081
       l-89.619,29.836c0.019,0.721,0.005,1.445-0.043,2.174c-0.748,11.445-9.599,20.639-20.971,21.792l-54.01,5.61
       c-3.417,0.357-6.472-2.126-6.827-5.541c-0.357-3.417,2.126-6.551,5.541-6.827l54.01-5.61c5.33-0.581,9.495-4.86,9.847-10.235
       c0.191-2.934-0.772-5.766-2.712-7.975c-1.939-2.21-4.624-3.532-7.558-3.724l-71.711-4.675c-8.054-0.522-16.05,1.255-23.121,5.148
       L85.028,358.01l25.612,51.645l23.757-20.725c10.09-8.801,23.455-12.593,36.667-10.397l79.41,13.197
       c14.551,1.934,29.139-1.695,41.088-10.218l108.118-74.461C404.513,302.83,405.371,295.517,401.606,290.287z"
      />
      <g>
        <path
          fill="#FED8B2"
          d="M274.856,327.394c5.33-0.581,9.495-4.86,9.847-10.235c0.191-2.934-0.772-5.766-2.712-7.975
           c-1.939-2.21-4.624-3.532-7.558-3.724l-15.948-1.04v24.673L274.856,327.394z"
        />
        <path
          fill="#FED8B2"
          d="M401.606,290.287c-3.303-4.591-9.307-6.397-14.601-4.41c-0.076,0.029-0.152,0.055-0.228,0.081
           l-89.619,29.836c0.019,0.721,0.005,1.445-0.043,2.174c-0.748,11.445-9.599,20.639-20.971,21.792l-17.653,1.834v50.641
           c11.812-0.072,23.323-3.765,33.073-10.721l108.118-74.459C404.513,302.83,405.371,295.517,401.606,290.287z"
        />
      </g>
      <polygon
        fill="#D35933"
        points="66.079,355.147 98.619,332.903 139.099,397.572 106.556,419.816 "
      />
      <polygon
        fill="#B54324"
        points="139.099,397.572 118.574,364.784 86.176,387.256 106.556,419.816 "
      />
      <path
        fill="#F9B54C"
        d="M339.49,104.008H172.51c-9.111,0-16.496,7.385-16.496,16.496v100.323
       c0,9.111,7.385,16.496,16.496,16.496h59.93L256,284.444l23.561-47.12h59.93c9.111,0,16.496-7.385,16.496-16.496V120.506
       C355.987,111.395,348.601,104.008,339.49,104.008z"
      />
      <path
        fill="#F4A200"
        d="M339.49,104.008h-83.634v180.15l0.143,0.286l23.561-47.12h59.93c9.111,0,16.496-7.385,16.496-16.496
       V120.506C355.987,111.395,348.601,104.008,339.49,104.008z"
      />
      <path
        fill="#D83E20"
        d="M319.622,137.837c-0.3-4.274-4.037-7.477-8.28-7.197l-28.375,1.982
       c-4.274,0.3-7.497,4.006-7.199,8.28s3.994,7.465,8.28,7.199l10.311-0.721l-39.114,40.653l-22.852-22.852
       c-3.027-3.031-7.942-3.031-10.969,0l-28.444,28.444c-3.031,3.029-3.031,7.94,0,10.969c3.027,3.031,7.942,3.031,10.969,0
       l22.959-22.957l22.959,22.957c1.455,1.457,3.427,2.272,5.485,2.272c0.026,0,0.05,0,0.074,0c2.084-0.021,4.072-0.877,5.516-2.379
       l44.549-46.302l0.636,9.107c0.286,4.089,3.694,7.216,7.73,7.216c0.181,0,0.365-0.007,0.55-0.019c4.274-0.3,7.496-4.006,7.197-8.282
       L319.622,137.837z"
      />
      <path
        fill="#C1321F"
        d="M319.622,137.837c-0.3-4.274-4.037-7.477-8.28-7.197l-28.375,1.982
       c-4.274,0.3-7.497,4.006-7.199,8.28s3.994,7.465,8.28,7.199l10.311-0.721l-38.502,40.019v19.423
       c1.924-0.126,3.744-0.934,5.087-2.332l44.549-46.302l0.636,9.107c0.286,4.089,3.694,7.216,7.73,7.216
       c0.181,0,0.365-0.007,0.55-0.019c4.274-0.3,7.496-4.006,7.197-8.282L319.622,137.837z"
      />
    </svg>
  );
};
