import { Link } from 'react-router-dom';
import styled from 'styled-components';

export type BreakItemProps = {
  mb: number;
  mt: number;
};

export const BreakItem = styled.div<BreakItemProps>`
  height: 1px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
`;

export const BreakItemContent = styled.span`
  background-color: white;
  padding: 3px;
`;

export const FormLink = styled(Link)`
  color: ${({ theme }) => theme?.colors?.primary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
