export const HygieneIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        id="security--hygiene_2_"
        d="M16,27.36c-4.61,0-8.36-3.751-8.36-8.36V9.777L16,5.598l8.36,4.18V19
     C24.36,23.609,20.609,27.36,16,27.36z M8.36,10.223V19c0,4.213,3.427,7.64,7.64,7.64s7.64-3.427,7.64-7.64v-8.777L16,6.402
     L8.36,10.223z M4,9.36C3.801,9.36,3.64,9.199,3.64,9V7c0-0.199,0.161-0.36,0.36-0.36S4.36,6.801,4.36,7v2
     C4.36,9.199,4.199,9.36,4,9.36z M7,6.36H5C4.801,6.36,4.64,6.199,4.64,6S4.801,5.64,5,5.64h2c0.199,0,0.36,0.161,0.36,0.36
     S7.199,6.36,7,6.36z M3,6.36H1C0.801,6.36,0.64,6.199,0.64,6S0.801,5.64,1,5.64h2c0.199,0,0.36,0.161,0.36,0.36S3.199,6.36,3,6.36z
      M4,5.36C3.801,5.36,3.64,5.199,3.64,5V3c0-0.199,0.161-0.36,0.36-0.36S4.36,2.801,4.36,3v2C4.36,5.199,4.199,5.36,4,5.36z M31,5.36
     c-0.092,0-0.185-0.035-0.255-0.105l-1-1c-0.141-0.141-0.141-0.368,0-0.509s0.369-0.141,0.51,0l1,1c0.141,0.141,0.141,0.368,0,0.509
     C31.185,5.325,31.092,5.36,31,5.36z M27,5.36c-0.092,0-0.185-0.035-0.255-0.105c-0.141-0.141-0.141-0.368,0-0.509l1-1
     c0.141-0.141,0.369-0.141,0.51,0s0.141,0.368,0,0.509l-1,1C27.185,5.325,27.092,5.36,27,5.36z M30,2.36
     c-0.092,0-0.185-0.035-0.255-0.105c-0.141-0.141-0.141-0.368,0-0.509l1-1c0.141-0.141,0.369-0.141,0.51,0s0.141,0.368,0,0.509l-1,1
     C30.185,2.325,30.092,2.36,30,2.36z M28,2.36c-0.092,0-0.185-0.035-0.255-0.105l-1-1c-0.141-0.141-0.141-0.368,0-0.509
     s0.369-0.141,0.51,0l1,1c0.141,0.141,0.141,0.368,0,0.509C28.185,2.325,28.092,2.36,28,2.36z M27,31.36
     c-0.199,0-0.36-0.161-0.36-0.36v-2c0-0.199,0.161-0.36,0.36-0.36s0.36,0.161,0.36,0.36v2C27.36,31.199,27.199,31.36,27,31.36z
      M30,28.36h-2c-0.199,0-0.36-0.161-0.36-0.36s0.161-0.36,0.36-0.36h2c0.199,0,0.36,0.161,0.36,0.36S30.199,28.36,30,28.36z
      M26,28.36h-2c-0.199,0-0.36-0.161-0.36-0.36s0.161-0.36,0.36-0.36h2c0.199,0,0.36,0.161,0.36,0.36S26.199,28.36,26,28.36z
      M27,27.36c-0.199,0-0.36-0.161-0.36-0.36v-2c0-0.199,0.161-0.36,0.36-0.36s0.36,0.161,0.36,0.36v2
     C27.36,27.199,27.199,27.36,27,27.36z"
      />
      <rect id="_Transparent_Rectangle" fill="none" width="32" height="32" />
    </svg>
  );
};
