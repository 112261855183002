import React from 'react';
import TeamsTable from './TeamsTable';

interface TeamsProps {
  selectedProjectId: string;
}

const Teams: React.FC<TeamsProps> = ({ selectedProjectId }) => {
  return <TeamsTable selectedProjectId={selectedProjectId} />;
};

export default Teams;
