import { GetOptionsType } from './metricTableTypes';

export const handleSelectPlaceHolder = (type: GetOptionsType) => {
  switch (type) {
    case 'repos':
      return 'Select Repo';
    case 'authors':
      return 'Select Author';
    case 'reviewers':
      return 'Select Reviewer';
    case 'pr_size':
      return 'Select PR Size';
    case 'prReviewResponsivenessType':
      return 'Select response';
    case 'review_feedback':
      return 'Select feedback';
    case 'prFeedbackResponsivenessType':
      return 'Select response';
    case 'pr_age':
      return 'Select PR age';
    case 'prIterations':
      return 'Select Iteration Level';
    case 'issueType':
      return 'Select Issue Type';
    case 'issuePriority':
      return 'Select Priority';
    case 'issueDueDate':
      return 'Select Due Date';
    case 'inactiveIssues':
      return 'Select Inactive Issue';
    case 'reopenedIssues':
      return 'Select Reopened Issues';
    case 'issueStatus':
      return 'Select Issue Status';
    case 'inflow_outflow':
      return 'Select Throughput';
    case 'cycleTimeSpread':
      return 'Select Cycle Time Spread';
    case 'issueAge':
      return 'Select Open Issue Age';
    case 'changingRequirements':
      return 'Select Requirements';
    case 'stage':
      return 'Select Issue Stage';
    case 'assignees':
      return 'Select Assignees';
    case 'currentAssignee':
      return 'Select Current Assignee';
    default:
      return '';
  }
};
