import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import NotFoundImg from '../../../assets/status/404.svg';
import { useNavigate } from 'react-router';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Stack h="100vh" w="full" align="center" justify="center">
      <Image src={NotFoundImg} height="300px" width="300px" />
      <Text
        fontFamily="heading"
        fontSize="2xl"
        fontWeight="bold"
        color="text.primary"
      >
        The page you were looking for doesn't exist.
      </Text>

      <Box>
        <Button onClick={() => navigate('/')} variant="primary" size="sm">
          Go to Homepage
        </Button>
      </Box>
    </Stack>
  );
};

export default NotFound;
