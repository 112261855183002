export function SandTimerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="52" fill="#0095E6" fill-opacity="0.2" />
      <circle cx="52" cy="52" r="27" fill="#0095E6" />
      <circle cx="52" cy="52" r="39" fill="#0095E6" fill-opacity="0.4" />
      <path
        d="M45.825 61.425V58.5C45.825 55.8402 47.3928 53.4053 49.8193 52.2957L50.4654 52L49.8193 51.7042C47.3928 50.5947 45.825 48.1598 45.825 45.5V42.575H59.475V45.5C59.475 48.1598 57.9072 50.5947 55.4808 51.7042L54.8347 52L55.4808 52.2957C57.9072 53.4053 59.475 55.8402 59.475 58.5V61.425H45.825Z"
        fill="#0095E6"
      />
      <path
        d="M59.15 42.9V45.5C59.15 48.0331 57.6569 50.3522 55.3456 51.4091L54.0527 52L55.3456 52.5908C57.6569 53.6477 59.15 55.9669 59.15 58.5V61.1H46.15V58.5C46.15 55.9669 47.6431 53.6477 49.9545 52.5908L51.2473 52L49.9545 51.4091C47.6431 50.3522 46.15 48.0331 46.15 45.5V42.9H59.15ZM59.8 42.25H45.5V45.5C45.5 48.3892 47.2173 50.8722 49.6841 52C47.2173 53.1277 45.5 55.6107 45.5 58.5V61.75H59.8V58.5C59.8 55.6107 58.0827 53.1277 55.616 52C58.0827 50.8722 59.8 48.3892 59.8 45.5V42.25Z"
        fill="white"
      />
      <path
        d="M45.825 43.225V41.925H43.875V39.975H61.425V41.925H59.475V43.225H45.825Z"
        fill="white"
      />
      <path
        d="M61.1 40.3V41.6H59.8H59.15V42.25V42.9H46.15V42.25V41.6H45.5H44.2V40.3H61.1ZM61.75 39.65H43.55V42.25H45.5V43.55H59.8V42.25H61.75V39.65Z"
        fill="white"
      />
      <path
        d="M46.8 61.1H58.5C58.5 55.2624 52.65 53.3 52.65 53.3C52.65 53.3 46.8 55.3027 46.8 61.1Z"
        fill="white"
      />
      <path
        d="M43.875 64.025V62.075H45.825V60.775H59.475V62.075H61.425V64.025H43.875Z"
        fill="white"
      />
      <path
        d="M59.15 61.1V61.75V62.4H59.8H61.1V63.7H44.2V62.4H45.5H46.15V61.75V61.1H59.15ZM59.8 60.45H45.5V61.75H43.55V64.35H61.75V61.75H59.8V60.45Z"
        fill="white"
      />
    </svg>
  );
}

export default SandTimerIcon;
