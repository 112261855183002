import { Box, Flex, Stack, Text, useTheme } from '@chakra-ui/react';
import {
  AlertTriangle,
  EliteIcon,
  StarIcon,
  Thumsup,
  convertHexToRGBA,
} from '@devd-client/devd/components';

interface DoraProgressItemProps {
  type: 'Elite' | 'High' | 'Medium' | 'Low';
  title: string;
  keys: any;
}

const elite = (color: string) => (
  <Flex
    w={'100%'}
    h="24px"
    bg={`linear-gradient(270deg, ${color} 0%, #EEFDD7 100%)`}
  ></Flex>
);

const high = (color: string) => (
  <>
    <Flex
      w={'75%'}
      h="24px"
      bg={`linear-gradient(270deg, ${color} 0%, #D6E4FF 100%)`}
    ></Flex>
    <Box w="25%" h="24px" bg="#E3E6EA" />
  </>
);

const medium = (color: string) => (
  <>
    <Flex
      w={'50%'}
      h="24px"
      bg={`linear-gradient(270deg, ${color} 0%, #FFF6D6 100%)`}
    ></Flex>
    <Box w="50%" h="24px" bg="#E3E6EA" />
  </>
);

const low = (color: string) => (
  <>
    <Flex
      w={'25%'}
      h="24px"
      bg={`linear-gradient(270deg, ${color} 0%, #FFE9D5 100%)`}
    ></Flex>
    <Box w="75%" h="24px" bg="#E3E6EA" />
  </>
);

const empty = () => <Box w="100%" h="24px" bg="#E3E6EA" />;

const DoraProgressItem: React.FC<DoraProgressItemProps> = ({
  type,
  title,
  keys,
}) => {
  const { colors } = useTheme();

  return type ? (
    <Stack mb={8}>
      <Flex align="center">
        <Text
          color="text.priamry"
          fontSize="xs"
          fontFamily="heading"
          fontWeight="medium"
          mr={1}
        >
          {title}
        </Text>
        {/* <Box>
          <AiOutlineInfoCircle size={12} color={colors.text.secondary} />
        </Box> */}
      </Flex>

      <Flex position="relative">
        {type === 'Elite' &&
          elite(keys.find((key: any) => key.name === 'Elite')?.color)}
        {type === 'High' &&
          high(keys.find((key: any) => key.name === 'High')?.color)}
        {type === 'Medium' &&
          medium(keys.find((key: any) => key.name === 'Medium')?.color)}
        {type === 'Low' &&
          low(keys.find((key: any) => key.name === 'Low')?.color)}

        <Flex
          position="absolute"
          height="100%"
          width="100%"
          zIndex={1}
          top={0}
          left={0}
        >
          <Box flex={1} borderRight={'2px solid white'} position="relative">
            {type === 'Low' && (
              <Flex
                p={'4px 6px'}
                bg={convertHexToRGBA(
                  keys.find((key: any) => key.name === 'Low')?.color,
                  0.1
                )}
                position="absolute"
                bottom={-8}
                right={-7}
                align="center"
              >
                <Box>
                  <AlertTriangle
                    height="12px"
                    width="12px"
                    color={keys.find((key: any) => key.name === 'Low')?.color}
                  />
                </Box>
                <Text
                  ml={1}
                  color={keys.find((key: any) => key.name === 'Low')?.color}
                  fontSize="xs"
                  fontWeight="medium"
                  fontFamily="heading"
                >
                  Low
                </Text>
              </Flex>
            )}
          </Box>
          <Box flex={1} borderRight={'2px solid white'} position="relative">
            {type === 'Medium' && (
              <Flex
                p={'4px 6px'}
                bg={convertHexToRGBA(
                  keys.find((key: any) => key.name === 'Medium')?.color,
                  0.1
                )}
                position="absolute"
                bottom={-8}
                right={-8}
                align="center"
              >
                <Box>
                  <Thumsup
                    height="12px"
                    width="12px"
                    color={
                      keys.find((key: any) => key.name === 'Medium')?.color
                    }
                  />
                </Box>
                <Text
                  ml={1}
                  color={keys.find((key: any) => key.name === 'Medium')?.color}
                  fontSize="xs"
                  fontWeight="medium"
                  fontFamily="heading"
                >
                  Medium
                </Text>
              </Flex>
            )}
          </Box>
          <Box flex={1} borderRight={'2px solid white'} position="relative">
            {type === 'High' && (
              <Flex
                p={'4px 6px'}
                bg={convertHexToRGBA(
                  keys.find((key: any) => key.name === 'High')?.color,
                  0.1
                )}
                position="absolute"
                bottom={-8}
                right={-7}
                align="center"
              >
                <Box>
                  <StarIcon
                    height="12px"
                    width="12px"
                    color={keys.find((key: any) => key.name === 'High')?.color}
                  />
                </Box>
                <Text
                  ml={1}
                  color={keys.find((key: any) => key.name === 'High')?.color}
                  fontSize="xs"
                  fontWeight="medium"
                  fontFamily="heading"
                >
                  High
                </Text>
              </Flex>
            )}
          </Box>
          <Box flex={1} position="relative">
            {type === 'Elite' && (
              <Flex
                p={'4px 6px'}
                bg={convertHexToRGBA(
                  keys.find((key: any) => key.name === 'Elite')?.color,
                  0.1
                )}
                position="absolute"
                bottom={-8}
                right={0}
                align="center"
              >
                <Box>
                  <EliteIcon
                    height="16px"
                    width="16px"
                    color={keys.find((key: any) => key.name === 'Elite')?.color}
                  />
                </Box>
                <Text
                  ml={1}
                  color={keys.find((key: any) => key.name === 'Elite')?.color}
                  fontSize="xs"
                  fontWeight="medium"
                  fontFamily="heading"
                >
                  Elite
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Flex>
    </Stack>
  ) : (
    <Stack mb={7}>
      <Flex align="center">
        <Text
          color="text.priamry"
          fontSize="xs"
          fontFamily="heading"
          fontWeight="medium"
          mr={1}
        >
          {title}
        </Text>
      </Flex>
      {empty()}
    </Stack>
  );
};

export default DoraProgressItem;
