import { CustomTooltip } from '@devd-client/devd/components';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

interface BarChartRechartsProps {
  data: any;
  keys?: any;
  chartMetadata?: any;
}

export const BarChartGoals = ({
  data,
  keys,
  chartMetadata,
}: BarChartRechartsProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey={chartMetadata?.xAxis}
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <YAxis
          label={{
            value: chartMetadata?.ylabel,
            angle: -90,
            position: 'insideLeft',
            fontSize: 11,
            fontWeight: 400,
          }}
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip hideBtn />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Legend
          verticalAlign="bottom"
          iconType="circle"
          iconSize={11}
          height={36}
          align="left"
          wrapperStyle={{ left: 35, fontSize: 11 }}
        />

        {keys?.map((el: any) => (
          <Bar
            key={el.key}
            stackId="a"
            barSize={16}
            cursor={'pointer'}
            dataKey={el.key}
            name={el.name}
            fill={el.color}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
