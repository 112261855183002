import { PeriodType } from '@devd-client/devd/components';
import { useInvestmentReportWeekGql } from '@devd-client/devd/investments';
import { FC } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import CustomTooltip from './CustomTooltip';

interface StackBarChartProps {
  selected: PeriodType;
  selectedTeam: string;
}

const StackBarChart: FC<StackBarChartProps> = ({ selected, selectedTeam }) => {
  const dispatch = () => mockFn;
  const mockFn = () => null;

  const renderColorfulLegendText = (value: string) => {
    return <span style={{ color: '#718096' }}>{value}</span>;
  };

  const { data, isFetching: chartFetching } = useInvestmentReportWeekGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    'week',
    'Ticket count',
    selectedTeam,
    dispatch
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barGap={3}
        data={data?.barChartData}
        margin={{
          top: 0,
          right: 5,
          left: -14,
          bottom: 24,
        }}
      >
        <XAxis
          dataKey="name"
          name="name"
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 9, fontWeight: 500 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          style={{ fontSize: 11, fontWeight: 400 }}
          tickFormatter={(tick) => {
            return `${tick}%`;
          }}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Legend
          verticalAlign="bottom"
          iconType="circle"
          iconSize={10}
          height={20}
          align="left"
          wrapperStyle={{
            left: 10,
            bottom: 20,
            fontSize: 10,
            fontFamily: 'Inter',
            fontWeight: 400,
          }}
          formatter={renderColorfulLegendText}
        />
        {data?.chartMetaData?.map((item: any) => (
          <Bar
            key={item.label}
            dataKey={item.label}
            stackId="a"
            barSize={16}
            fill={item.fillColor}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackBarChart;
