export const colorPallete = [
  '#0B698B',
  '#FF8D70',
  '#ADEEE2',
  '#F1B813',
  '#490B3E',
  '#C2528B',
  '#5F50A9',
  '#0396A6',
  '#FAD1D7',
  '#0095E6',
];

export const categoryTypeOptions = [
  { value: 'label', text: 'Label' },
  { value: 'project', text: 'Project' },
  { value: 'component', text: 'Component' },
  { value: 'type', text: 'Ticket Type' },
  { value: 'custom', text: 'Custom Field' },
];

export const formatOptionLabel = ({ value, label }: any) => {
  if (value === '') {
    return <span>&nbsp;</span>;
  }
  return label;
};
