import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { useContext } from 'react';
import styled from 'styled-components';
import { InitiativeContext } from '../../../../contexts/initiativesContext';
import { useInitiativesDetailGql } from '../../../../graphql/index';
import { Loader } from '@devd-client/devd/components';

const currentDate = new Date();
const tasks: Task[] = [
  {
    start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
    name: 'No Task Available',
    id: 'Task 0',
    progress: 0,
    type: 'task',
  },
];

const NoDetials = styled.p`
  font-size: '14px';
  display: flex;
  justify-content: center;
  font-family: 'Inter';
  font-weight: 400;
`;

interface GanttChartProps {
  selectedProjectId: string;
}

const GanttChart: React.FC<GanttChartProps> = ({ selectedProjectId }) => {
  const {
    state: { initiativeDetails },
    dispatch,
  } = useContext(InitiativeContext);

  const { isFetching: overViewLoading } = useInitiativesDetailGql(
    selectedProjectId,
    0,
    20,
    dispatch
  );

  return initiativeDetails?.epicDetails?.length === 0 ? (
    <NoDetials>No Details Available.</NoDetials>
  ) : (
    <>
      <Gantt
        tasks={initiativeDetails?.epicDetails || tasks}
        viewMode={ViewMode.Week}
        listCellWidth=""
        columnWidth={70}
        onClick={(task) => console.log(task)}
      />
      {overViewLoading && <Loader />}
    </>
  );
};

export default GanttChart;
