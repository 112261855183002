import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import Month from './month';

interface CustomeDateProps {
  setFormatedRange: (range: string) => void;
  setSelectedRange: (range: [Date, Date] | null) => void;
  selectedRange: [Date, Date] | null;
}

export const CustomDate: React.FC<CustomeDateProps> = ({
  setFormatedRange,
  setSelectedRange,
  selectedRange,
}) => {
  const [currentMonth, setCurrentMonth] = useState<number>(0);
  const [currentYear, setCurrentYear] = useState<number>(0);

  useEffect(() => {
    const today = new Date();
    setCurrentMonth(today.getMonth() + 1);
    setCurrentYear(today.getFullYear());

    return () => {
      setSelectedRange(null);
    };
  }, [setSelectedRange]);

  const formatToYYYYMMDD = (date: Date) => {
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return localDate.toISOString().split('T')[0];
  };

  const handleDateSelect = (newRange: [Date, Date]) => {
    const formattedStartDate = formatToYYYYMMDD(newRange[0]);
    const formattedEndDate = formatToYYYYMMDD(newRange[1]);
    setFormatedRange(`${formattedStartDate} ${formattedEndDate}`);
    setSelectedRange(newRange);
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear((prevYear) => prevYear - 1);
    } else {
      setCurrentMonth((prevMonth) => prevMonth - 1);
    }
  };

  const goToNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear((prevYear) => prevYear + 1);
    } else {
      setCurrentMonth((prevMonth) => prevMonth + 1);
    }
  };

  return (
    <Box display={'flex'} gap={'12px'}>
      <Box padding={'16px'} borderRight={'1px solid #E0E0E0'}>
        <Month
          year={currentYear}
          month={currentMonth - 2}
          firstDayOfWeek={undefined}
          goToNextMonths={goToNextMonth}
          goToPreviousMonths={goToPreviousMonth}
          selectedRange={selectedRange}
          onSelectRange={handleDateSelect}
        />
      </Box>
      <Box padding={'16px'}>
        <Month
          year={currentYear}
          month={currentMonth - 1}
          firstDayOfWeek={undefined}
          goToNextMonths={goToNextMonth}
          goToPreviousMonths={goToPreviousMonth}
          selectedRange={selectedRange}
          onSelectRange={handleDateSelect}
        />
      </Box>
    </Box>
  );
};
