import { useState } from 'react';
import { default as ReactSelect, components } from 'react-select';

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    zIndex: 9999,
    fontFamily: 'Inter',
    fontSize: '14px',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: 'black',
    padding: '2px 4px',
    fontFamily: 'Inter',
    fontSize: '14px',
  }),
  valueContainer: (base: any) => ({
    ...base,
    maxHeight: '84px',
    overflow: 'auto',
    zIndex: 99999,
  }),
  option: (base: any, state: any) => ({
    ...base,
    color: '#1e2022',
    backgroundColor: state.isSelected ? '#F6F7F9' : 'white',
    padding: '.5rem 3rem .5rem .5rem',
    cursor: 'pointer',
    transition: 'all 0.5s',
    fontFamily: 'Inter',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#E3E6EA',
    },
  }),

  menu: (base: any) => ({ ...base, zIndex: 9999, marginTop: '1px' }),
};
const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }: any) => {
  const input = children.find(
    (child: any) =>
      child && typeof child === 'object' && child.type === components.Input
  );
  const values = children.filter((child: any) => child !== input);

  return (
    <components.ValueContainer {...props}>
      {input}
      {values}
    </components.ValueContainer>
  );
};

export function CustomMultiSelect2(props: any) {
  const [inputValue, setInputValue] = useState('');

  return (
    <div>
      <ReactSelect
        {...props}
        options={props.options}
        isMulti
        backspaceRemovesValue={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          DropdownIndicator: () => null,
          ValueContainer: CustomValueContainer,
        }}
        styles={customStyles}
        onChange={props.onChange}
        value={props.value}
        menuIsOpen
        getOptionValue={(option: any) => option.id}
        onInputChange={(value, action) => {
          if (action.action === 'input-change') setInputValue(value);
        }}
        inputValue={inputValue}
      />
    </div>
  );
}
