import { Loader } from '@devd-client/devd/components';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from '@devd-client/api';
import { ConfirmModal } from '@devd-client/devd/components';

export function Gmeet() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [headerText, setHeaderText] = useState<string>('');
  const [bodyText, setBodyText] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    apiClient(
      `/v1/integration/GoogleMeet/org/${localStorage.getItem('orgId')}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          name: 'gmeet',
          type: 'meet',
          active: true,
          properties: [
            {
              name: 'state',
              value: state,
            },
            {
              name: 'code',
              value: code,
            },
          ],
        }),
      }
    )
      .then((res: any) => {
        setLoading(false);
        setHeaderText('Integration successful and active');
        setBodyText(`Note: It might take 15 mins to 1 hour to pull data and update
        metrics.`);
      })
      .catch((err) => {
        setLoading(false);
        setHeaderText(
          err.message
            ? `${err.message}`
            : 'Integration Failed. Contact DevDynamics Support'
        );
      });
    setOpenConfirmModal(true);
  }, [state, code]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {openConfirmModal && (
            <ConfirmModal
              isOpen={openConfirmModal}
              headerText={headerText}
              bodyText={bodyText}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default Gmeet;
