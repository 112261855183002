import { FC } from 'react';
import styled from 'styled-components';
import UpdatesTable from './UpdatesTable';
import UpdatesToolbar from './UpdatesToolbar';

const UpdatesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface UpdatesProps {
  selectedProjectId: string;
}

const Updates: FC<UpdatesProps> = ({ selectedProjectId }) => {
  return (
    <UpdatesContainer>
      <UpdatesToolbar />
      <UpdatesTable selectedProjectId={selectedProjectId} />
    </UpdatesContainer>
  );
};

export default Updates;
