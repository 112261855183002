import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import { goalsGenericGraphql } from './query';

export const QUERY_KEYS = {
  goalsMetric: 'goalsMetric',
};

export const useGoalsChartGql = (
  metric: string,
  startDate: string,
  endDate: string,
  selectedTeam: string,
  sprintId: string
) => {
  return useQuery(
    [
      QUERY_KEYS.goalsMetric,
      metric,
      startDate,
      endDate,
      selectedTeam,
      sprintId,
    ],
    () =>
      goalsGenericGraphql(metric, startDate, endDate, selectedTeam, sprintId),
    {
      select: (data) => ({
        ...data.GoalMetricsByType,
        data: JSON.parse(data.GoalMetricsByType.data),
      }),
      enabled: !!metric && !!startDate && !!endDate,
    }
  );
};
