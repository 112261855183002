import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { apiClient } from '@devd-client/api';
import {
  Breadcrumbs,
  eliminateHtmlTags,
  Loader,
  useToastHook,
} from '@devd-client/devd/components';
import React, { Fragment, useEffect, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';

export const AskReports = () => {
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [newToast] = useToastHook();

  const [chat, setChat] = useState<any>([]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      fetch(
        `https://api.devdynamics.ai/app/gpt/${localStorage.getItem('orgId')}`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            question: question,
          }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          setIsLoading(false);
          setChat([
            ...chat,
            {
              question: question,
              answer: res.data.message,
              type: res.data.type,
              columns: res.data.columns,
              data: res.data.data,
            },
          ]);
          setQuestion('');
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [showAccessButton, setShowAccessButton] = useState<any>(null);

  const [showInput, setShowInput] = useState<any>(null);

  useEffect(() => {
    try {
      setIsLoading(true);
      apiClient(`/v2/account/access?feature=gpt`, {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res: any) => {
          setShowInput(res?.dto?.access);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setShowAccessButton(err?.message === 'Not Found');
        });
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  const onAccess = () => {
    try {
      setIsLoading(true);
      fetch(`https://api.devdynamics.ai/app/api/v2/account/access/request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          feature: 'gpt',
        }),
      })
        .then((response) => response.json())
        .then((res: any) => {
          setIsLoading(false);
          setShowAccessButton(false);
          setShowInput(false);
          newToast({
            message: 'We are reviewing your request.',
            status: 'success',
          });
        })
        .catch((err) => {
          newToast({
            message:
              err?.message ??
              'Some error occurred whle sending the request. Please try again.',
            status: 'error',
          });
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Stack w="full" position="relative">
      <Box mb={2}>
        <Breadcrumbs />
      </Box>
      {showAccessButton && (
        <Button
          variant="tertiary"
          w={'400px'}
          alignSelf={'center'}
          onClick={onAccess}
          disabled={isLoading}
        >
          {isLoading ? 'Requesting Access...' : 'Request Access'}
        </Button>
      )}

      {showInput && (
        <Box>
          <Flex justify="center" mb={6}>
            <form style={{ width: '70%' }} onSubmit={onSubmit}>
              <InputGroup size="md">
                <Input
                  type="text"
                  value={question}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQuestion(eliminateHtmlTags(e.currentTarget.value))
                  }
                />
                <InputRightAddon
                  children={
                    <button type="submit">
                      <AiOutlineSend />
                    </button>
                  }
                />
              </InputGroup>

              <Button type="submit" hidden>
                click
              </Button>
            </form>
          </Flex>

          <Flex align="center" direction="column">
            {chat
              .slice(0)
              .reverse()
              .map((item: any, idx: number) => (
                <Fragment key={idx}>
                  <Flex w="70%" minH="30px" p={1}>
                    <Text
                      fontFamily="heading"
                      fontWeight="semibold"
                      fontSize="md"
                      color="text.primary"
                    >
                      {item.question}
                    </Text>
                  </Flex>
                  <Flex
                    bg="#F6F7F9"
                    w="70%"
                    minH="30px"
                    p={1}
                    border="1px solid #ccc"
                    borderRadius="4px"
                    mb={4}
                  >
                    {item.type === 'text' && (
                      <Text
                        fontFamily="heading"
                        fontSize="md"
                        color="text.primary"
                      >
                        {item.answer}
                      </Text>
                    )}

                    {item.type === 'table' && (
                      <TableContainer maxH="70vh" overflowY="auto">
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              {item?.columns?.map(
                                (column: string, idx: number) => (
                                  <Th key={idx}>{column}</Th>
                                )
                              )}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {item?.data?.map((row: any, idx: number) => (
                              <Tr key={idx}>
                                {row?.item?.map((el: any, idx: number) => (
                                  <Td key={idx}>{el.String}</Td>
                                ))}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    )}
                  </Flex>
                </Fragment>
              ))}
          </Flex>
        </Box>
      )}

      {!showAccessButton && !showInput && (
        <Box height="60vh">
          <Center
            height="100%"
            textAlign="center"
            display={'flex'}
            flexDir={'column'}
          >
            <Heading as="h2" ml={2} size="md">
              We are reviewing your request for access.
            </Heading>

            <Text mt={4} color="gray.500">
              We can't wait to give you access to <strong>DevD AI</strong>.
              We'll reach out once it's enabled for you.
            </Text>
          </Center>
        </Box>
      )}

      {isLoading && <Loader />}
    </Stack>
  );
};

export default AskReports;
