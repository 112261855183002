import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  ComponentBanner,
  DateFilter,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import { OptionBase, Select } from 'chakra-react-select';
import {
  customFilterSelect,
  filterStyles,
  repoauthorFilterStyles,
} from './FilterStyles';
import { RxReset } from 'react-icons/rx';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import useMetricTableHelpers from '../hooks/useMetricTableHelpers';
import SubHeading from './SubHeading';
import useHandleOptionChange from '../hooks/useHandleOptionChange';
import useOptions from '../hooks/useOptions';
import useHandleStatusChange from '../hooks/useHandleStatusChange';
import {
  FilterByType,
  GetOptionsType,
  NavigationState,
} from '../../../utils/metricTableTypes';
import { handleSelectPlaceHolder } from '../../../utils/metricTable.utils';
interface Option extends OptionBase {
  label: string;
  value: any;
}
interface PrFilterType {
  isFetched: boolean;
  startDate: any;
  endDate: any;
  chartKey: string;
  metricKey: string;
  heading: string;
  totalDBrows: number;
  firstOption: string;
  secondOption: string;
  teamId: string;
  status: string;
  setTeamId: (value: string) => void;
  setStatus: (value: string) => void;
  setFirstOption: (value: string) => void;
  setSecondOption: (value: string) => void;
  setChartKey: (value: string) => void;
  setMetric: (value: string) => void;
  setMetricKey: (value: string) => void;
  setHeading: (value: string) => void;
}
// This component consists all filters used in PR table.
export default function PrFilters({
  isFetched,
  startDate,
  endDate,
  heading,
  chartKey,
  metricKey,
  totalDBrows,
  firstOption,
  secondOption,
  teamId,
  status,
  setTeamId,
  setStatus,
  setSecondOption,
  setFirstOption,
  setChartKey,
  setMetric,
  setHeading,
  setMetricKey,
}: PrFilterType) {
  const location = useLocation();
  const state = location.state as NavigationState;

  const [clearSearchBar, setClearSearchBar] = useState<null | boolean>(null);
  const metricFilterBy = useMetricsTableStore();
  const { handleMetricFilterBy, handleScrollToTop } = useMetricTableHelpers();
  const { getOptions, contributorData, issueOptions, repoList, teamData } =
    useOptions({
      startDate,
      endDate,
    });

  const {
    showOption,
    teamValue,
    defaultValue,
    secondaryDefaultValue,
    handleTeamOptionChange,
    handleShowOptionChange,
    handleDefaultOptionChange,
    handleSecondaryDefaultOptionChange,
  } = useHandleOptionChange();
  const { handlePRStatusChange, tabIndex, setTabIndex } = useHandleStatusChange(
    {
      setStatus,
    }
  );
  useEffect(() => {
    handleDefaultOptionChange(
      getOptions('prTable').filter(
        (option: Option) => option.value === (firstOption ?? '')
      )?.[0]?.label,
      firstOption ?? ''
    );
    handleSecondaryDefaultOptionChange(
      getOptions(firstOption as GetOptionsType)?.filter(
        (option: Option) => option.value === (secondOption ?? '')
      )?.[0]?.label,
      secondOption ?? ''
    );
    handleTeamOptionChange(teamId ?? '');
    handleShowOptionChange((firstOption as GetOptionsType) ?? '');
    setTabIndex(
      status === 'all' ? 0 : status === 'open' ? 1 : status === 'review' ? 2 : 3
    );
  }, [contributorData, issueOptions, repoList, teamData]);

  return (
    <Grid
      templateColumns={'repeat(2, 1fr)'}
      className="fitler-container"
      w={'full'}
    >
      <SubHeading
        heading={heading}
        chartKey={chartKey}
        getOptions={getOptions}
        componentOrigin={metricFilterBy.componentOrigin}
      />
      {state?.headingText && (
        <ComponentBanner signalHeadingText={state?.headingText} />
      )}

      {metricFilterBy.showFilters ? (
        <>
          <GridItem
            colStart={1}
            colEnd={3}
            mb={4}
            mt={5}
            display={'flex'}
            justifyContent={'space-between'}
            w={'full'}
            zIndex={10}
          >
            <Flex gap={7}>
              <SearchBar clearSearchBar={clearSearchBar} />
              <Flex
                alignSelf={'end'}
                gap={
                  [
                    'teams',
                    'repos',
                    'authors',
                    'feedback_response',
                    'review_feedback',
                    'review_response',
                    'pr_age',
                    'reviewers',
                    'pr_size',
                    'prIterations',
                  ].includes(showOption as string)
                    ? 3
                    : 1
                }
              >
                <Box pr={1}>
                  <Text fontSize={'xs'} color={'grey'}>
                    Team
                  </Text>
                  <Select
                    options={getOptions('teams')}
                    chakraStyles={filterStyles}
                    placeholder="Select Team"
                    value={teamValue}
                    onChange={(input) => {
                      const modifiedValue = input?.value
                        ?.split(' ')
                        .includes('subTeam')
                        ? input?.value?.split(' ').slice(1).join(' ')
                        : input?.value?.split(' ').slice(0).join(' ');
                      handleScrollToTop();
                      handleMetricFilterBy('teams', '', modifiedValue, null);
                      setTeamId(modifiedValue);
                      setClearSearchBar(!clearSearchBar);
                      handleTeamOptionChange(modifiedValue);
                    }}
                  />
                </Box>

                <Box>
                  <Text fontSize={'xs'} color={'grey'}>
                    Filter By
                  </Text>
                  <Select
                    options={getOptions('prTable')}
                    chakraStyles={filterStyles}
                    placeholder="Filter"
                    components={customFilterSelect}
                    value={defaultValue}
                    onChange={(input) => {
                      handleDefaultOptionChange(
                        input?.label as string,
                        input?.value
                      );
                      if (
                        input?.value === 'merged_with_review' ||
                        input?.value === 'merged_wo_review'
                      ) {
                        metricFilterBy.setMetricKeys(
                          input?.value === 'merged_with_review'
                            ? 'merged_with_review'
                            : 'merged_without_review'
                        );
                      }
                      setFirstOption(input?.value);
                      if (
                        input?.value !== 'teams' &&
                        input?.value !== 'repos' &&
                        input?.value !== 'authors' &&
                        input?.value !== 'prFeedbackResponsivenessType' &&
                        input?.value !== 'review_feedback' &&
                        input?.value !== 'prReviewResponsivenessType' &&
                        input?.value !== 'pr_age' &&
                        input?.value !== 'reviewers' &&
                        input?.value !== 'prIterations' &&
                        input?.value !== 'pr_size'
                      ) {
                        handleScrollToTop();
                        setClearSearchBar(!clearSearchBar);
                        setSecondOption('');
                        handleShowOptionChange('');
                        handleMetricFilterBy(
                          'prFilters',
                          input?.value,
                          input?.value,
                          null
                        );
                      } else {
                        handleShowOptionChange(input?.value);
                        handleSecondaryDefaultOptionChange('', '');
                      }
                    }}
                  />
                </Box>
                <Box alignSelf={'end'}>
                  {[
                    'teams',
                    'repos',
                    'authors',
                    'prFeedbackResponsivenessType',
                    'review_feedback',
                    'prReviewResponsivenessType',
                    'pr_age',
                    'prIterations',
                    'reviewers',
                    'pr_size',
                  ].includes(showOption) && (
                    <Select
                      // components={customRepoOption}
                      options={getOptions(showOption)}
                      chakraStyles={
                        ['repos', 'authors', 'reviewers'].includes(
                          showOption ?? ''
                        )
                          ? repoauthorFilterStyles
                          : filterStyles
                      }
                      placeholder={handleSelectPlaceHolder(showOption)}
                      value={
                        secondaryDefaultValue?.value
                          ? secondaryDefaultValue
                          : null
                      }
                      isDisabled={
                        ([
                          'prReviewResponsivenessType',
                          'review_feedback',
                          'prFeedbackResponsivenessType',
                        ].includes(showOption) &&
                          ['PR_AGEING_DISTRIBUTION'].includes(chartKey)) ||
                        (['pr_age'].includes(showOption) &&
                          [
                            'TOP_REVIEWER_DISTRIBUTION',
                            'PR_REVIEW',
                            'PR_REVIEWED',
                            'PR_HEALTH_INDEXES',
                            'LOC_VS_PR_CYCLE_TIME',
                            'PR_CYCLE_TIME_BREAKDOWN',
                            // below is for working agreements
                            'COMMIT_WITHOUT_REVIEW',
                            'PR_REVIEW_TIME',
                            // below is for teams page
                            'PR_MERGE',
                            'PR_OPEN',
                            'MERGE_WITHOUT_REVIEW',
                            'PR_CYCLE_TIME',
                          ].includes(chartKey))
                      }
                      onChange={(input) => {
                        setSecondOption(input?.value);
                        handleScrollToTop();
                        setClearSearchBar(!clearSearchBar);
                        handleMetricFilterBy(
                          'prFilters',
                          showOption,
                          input?.value,
                          null
                        );
                        handleSecondaryDefaultOptionChange(
                          input?.label as string,
                          input?.value
                        );
                      }}
                    />
                  )}
                </Box>
                {![
                  'contributor-profile-pr',
                  'contributor-profile-issue',
                ].includes(metricFilterBy.componentOrigin ?? '') && (
                  <Tooltip hasArrow label="Reset filters">
                    <IconButton
                      icon={<RxReset />}
                      aria-label="Reset filters"
                      justifySelf={'self-end'}
                      alignSelf={'self-end'}
                      borderRadius={'4px'}
                      size={'md'}
                      variant={'outline'}
                      border={'1px'}
                      borderColor={'#E5E5E5'}
                      onClick={() => {
                        setHeading('PR Details Table');
                        setMetric('PR_UPDATED');
                        setChartKey('PR_UPDATED');
                        setMetricKey('');
                        setFirstOption('');
                        setSecondOption('');
                        setTeamId('');
                        setStatus('');
                        handleShowOptionChange('');
                        setClearSearchBar(!clearSearchBar);
                        handleDefaultOptionChange('All', '');
                        handleTeamOptionChange('');
                        setTabIndex(0);
                        metricFilterBy.resetFilters();
                      }}
                      cursor={'pointer'}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            {!['contributor-profile-pr', 'contributor-profile-issue'].includes(
              metricFilterBy.componentOrigin ?? ''
            ) && (
              <Box alignSelf={'end'}>
                <DateFilter isDetailsPage />
              </Box>
            )}
          </GridItem>

          <GridItem colStart={1} colEnd={3} mb={4}>
            <Tabs
              variant="soft-rounded"
              colorScheme="twitter"
              index={tabIndex}
              onChange={handlePRStatusChange}
            >
              <TabList>
                <Tab>
                  All {tabIndex === 0 && isFetched && `(${totalDBrows ?? 0})`}
                </Tab>
                <Tab>
                  WIP {tabIndex === 1 && isFetched && `(${totalDBrows ?? 0})`}
                </Tab>
                <Tab>
                  In Review{' '}
                  {tabIndex === 2 && isFetched && `(${totalDBrows ?? 0})`}
                </Tab>
                <Tab>
                  Merged{' '}
                  {tabIndex === 3 && isFetched && `(${totalDBrows ?? 0})`}
                </Tab>
              </TabList>
            </Tabs>
          </GridItem>
        </>
      ) : (
        <Box my={10}></Box>
      )}
    </Grid>
  );
}
