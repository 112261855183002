import {
  Box,
  Button,
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { ChakraStylesConfig, Select, components } from 'chakra-react-select';
import { useState } from 'react';
import { SearchIcon } from '../icons';
import './style.css';
import { BiTrash } from 'react-icons/bi';

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon height="16px" width="16px" />
    </components.DropdownIndicator>
  );
};

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
      fontFamily: 'heading',
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: 'none',
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      bg: 'white',
      border: 'none',
      minHeight: '300px',
      boxShadow: 'none',
    };
  },

  option: (provided, state) => ({
    ...provided,
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),

  placeholder: (provided) => ({
    ...provided,
    color: 'text.secondary2',
  }),
};

type Props = {
  children: React.ReactNode;
  options: any;
  selectedMetrics: any;
  setSelectedMetrics: (selectedMetrics: any) => void;
  replaceMetric?: string;
  isCustomMetric?: boolean;
  popupPlacement?: PlacementWithLogical;
};

export const CustomMetricFilter = ({
  children,
  options,
  selectedMetrics,
  setSelectedMetrics,
  replaceMetric,
  isCustomMetric,
  popupPlacement,
}: Props) => {
  const [state, setState] = useState({ optionSelected: null });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (selected: any) => {
    setState({
      optionSelected: selected,
    });

    let newSelection;
    if (selected.value === 'CUSTOM') {
      newSelection = options.find(
        (option: any) => option.title === selected.label
      );
    } else {
      newSelection = options.find(
        (option: any) => option.name === selected.value
      );
    }

    const metricIndex = selectedMetrics.findIndex(
      (metric: any) =>
        metric.name === replaceMetric || metric.title === replaceMetric
    );
    const updatedMetrics = [...selectedMetrics];
    if (metricIndex > -1) {
      updatedMetrics.splice(metricIndex, 1, newSelection);
    } else {
      updatedMetrics.push(newSelection);
    }
    setSelectedMetrics(updatedMetrics);
    onClose();
  };

  const handleRemoveColumn = () => {
    let updatedMetrics;
    if (isCustomMetric) {
      updatedMetrics = selectedMetrics.filter(
        (metric: any) => metric.title !== replaceMetric
      );
    } else {
      updatedMetrics = selectedMetrics.filter(
        (metric: any) => metric.name !== replaceMetric
      );
    }

    setSelectedMetrics(updatedMetrics);

    setState({ optionSelected: null });
    onClose();
  };

  return (
    <Popover placement={popupPlacement} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <button
          style={{ cursor: 'pointer', width: '100%', all: 'unset' }}
          onClick={onOpen}
        >
          {children}
        </button>
      </PopoverTrigger>

      <PopoverContent mt={2.5}>
        <PopoverArrow />

        <PopoverCloseButton />
        <PopoverBody height="400px" overflow={'hidden'}>
          <Select
            classNamePrefix={'an-simple-select'}
            options={options?.map((option: any) => ({
              value: option.name,
              label: option.title,
            }))}
            placeholder="Search Metrics"
            chakraStyles={chakraStyles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              DropdownIndicator: CustomDropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            menuIsOpen
            onChange={handleChange}
            value={state.optionSelected}
            controlShouldRenderValue={false}
            isClearable={false}
            backspaceRemovesValue={false}
          />

          {replaceMetric && (
            <>
              <Box mt={'310px'} height="1px" w="full" bg="#E0E0E0" />
              <Flex mt={1} gap={2}>
                <Button
                  fontSize="xs"
                  size="sm"
                  fontWeight="medium"
                  fontFamily="heading"
                  color="text.secondary2"
                  leftIcon={<BiTrash />}
                  onClick={() => {
                    handleRemoveColumn();
                    onClose();
                  }}
                >
                  Remove Column
                </Button>
              </Flex>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
