import { apiClient } from '@devd-client/api';
import { parseLLMResponseData } from '../helpers';

export const getMetricInfo = (chartKey: string): Promise<any> => {
  return apiClient(
    `/v1/tooltip/org/${localStorage.getItem('orgId')}/metric/${chartKey}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const getAIGeneratedDataForInfoTooltip = async (
  metricCount: string,
  metricName: string,
  metricTrend: string,
  metricValueDesc: string,
  metricGoal: string
): Promise<any> => {
  const currentParams = new URLSearchParams(window.location.search);
  const params = new URLSearchParams({
    start_date: currentParams.get('start') || '',
    end_date: currentParams.get('end') || '',
    team: currentParams.get('team') || '',
  });
  try {
    const res = await fetch(
      `https://api.devdynamics.ai/app/gpt/metric/tooltipdata?` + params,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          metricCount: metricCount,
          metricName: metricName,
          metricTrend: metricTrend,
          metricValueDesc: metricValueDesc,
          metricGoal: metricGoal,
        }),
      }
    );
    const data = await res.text();
    return parseLLMResponseData(data);
  } catch (error) {
    throw new Error('Error in fetching insights.');
  }
};
