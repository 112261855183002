export function AppIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="180"
      viewBox="0 0 510 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0H48.0395C74.571 0 96.079 21.4903 96.079 48C96.079 74.5097 74.571 96 48.0395 96H0V0Z"
        fill="#4D4DFF"
      />
      <path
        d="M49.0403 78H47.0387C47.0387 61.98 34.048 49 18.0148 49V47C34.048 47 47.0387 34.02 47.0387 18H49.0403C49.0403 34.02 62.031 47 78.0642 47V49C62.031 49 49.0403 61.98 49.0403 78Z"
        fill="white"
      />
      <path
        d="M132.339 33.65H124.282V62.42H132.759C142.637 62.42 146.56 57.1 146.56 47.65C146.56 37.78 142.427 33.65 132.339 33.65ZM116.296 69V27H133.39C147.892 27 154.547 34.35 154.547 47.65C154.547 60.95 146.56 69 133.39 69H116.296Z"
        fill="#1D201E"
      />
      <path
        d="M174.218 41.07C169.104 41.07 166.092 43.8 165.391 49.68H182.275C181.995 44.01 179.473 41.07 174.218 41.07ZM174.429 69.84C164.34 69.84 157.825 63.96 157.825 52.97C157.825 41.84 164.13 35.26 174.429 35.26C184.377 35.26 189.211 41.28 189.211 50.31C189.211 51.57 189.211 53.04 189.071 54.72H165.321C165.882 60.67 168.894 63.68 174.499 63.68C179.753 63.68 181.574 61.16 182.485 58.01L188.931 59.83C187.389 65.78 183.256 69.84 174.429 69.84Z"
        fill="#1D201E"
      />
      <path
        d="M212.457 69H202.509L190.319 36.1H198.515L202.579 48.84C204.05 53.53 205.591 58.08 206.852 62.56H208.253C209.514 58.08 211.056 53.39 212.527 48.84L216.59 36.1H224.577L212.457 69Z"
        fill="#1D201E"
      />
      <path
        d="M243.739 33.65H235.682V62.42H244.159C254.037 62.42 257.96 57.1 257.96 47.65C257.96 37.78 253.827 33.65 243.739 33.65ZM227.695 69V27H244.789C259.291 27 265.947 34.35 265.947 47.65C265.947 60.95 257.96 69 244.789 69H227.695Z"
        fill="#1D201E"
      />
      <path
        d="M271.289 81.25C269.117 81.25 267.646 81.04 266.105 80.83L267.296 74.04C268.697 74.32 270.098 74.53 271.499 74.53C275.212 74.53 277.174 73.62 279.346 68.65L266.105 36.1H274.372L278.225 48C279.556 52.13 281.378 57.59 282.779 62.35H283.9C285.371 57.45 286.982 52.2 288.243 48.14L291.816 36.1H299.873L286.282 69.42C282.919 77.61 278.855 81.25 271.289 81.25Z"
        fill="#1D201E"
      />
      <path
        d="M310.615 50.38V69H303.118V36.1H310.474V44.78H311.105C312.926 38.69 317.13 35.26 322.664 35.26C329.53 35.26 332.823 39.88 332.823 47.02V69H325.327V48.98C325.327 44.99 323.575 42.05 319.021 42.05C313.977 42.05 310.615 45.41 310.615 50.38Z"
        fill="#1D201E"
      />
      <path
        d="M350.635 63.82C355.889 63.82 359.392 60.74 359.392 56.61V55.28H350.845C347.062 55.28 344.89 56.68 344.89 59.41C344.89 61.79 346.361 63.82 350.635 63.82ZM359.742 69V61.16H358.972C357.29 66.48 353.507 69.7 347.692 69.7C341.808 69.7 337.674 66.9 337.674 60.46C337.674 54.58 341.247 50.31 349.584 50.31H359.392V48.07C359.392 43.8 357.641 41.35 353.017 41.35C349.164 41.35 346.922 43.31 346.081 47.16L338.935 45.55C340.196 39.32 344.89 35.33 353.297 35.33C362.825 35.33 366.888 39.81 366.888 48V69H359.742Z"
        fill="#1D201E"
      />
      <path
        d="M381.323 50.38V69H373.827V36.1H381.183V44.78H381.814C383.635 38.62 387.769 35.26 393.373 35.26C399.538 35.26 402.621 39.04 403.111 45.13H403.882C405.493 38.83 409.346 35.26 415.441 35.26C422.377 35.26 425.74 39.88 425.74 47.02V69H418.174V48.98C418.174 44.99 416.352 42.05 411.939 42.05C406.754 42.05 403.532 45.41 403.532 50.38V69H396.036V48.98C396.036 44.99 394.144 42.05 389.73 42.05C384.686 42.05 381.323 45.41 381.323 50.38Z"
        fill="#1D201E"
      />
      <path
        d="M440.917 30.5H432.72V24.41H440.917V30.5ZM440.566 69H433V36.1H440.566V69Z"
        fill="#1D201E"
      />
      <path
        d="M470.929 48.91C469.948 44.71 467.987 41.91 462.803 41.91C457.408 41.91 454.045 45.34 454.045 52.55C454.045 59.2 457.128 63.26 463.013 63.26C468.337 63.26 470.159 59.97 471.139 55.98L478.075 57.66C476.744 65.29 472.12 69.91 463.083 69.91C452.714 69.91 446.339 63.89 446.339 52.83C446.339 41.77 452.854 35.26 462.943 35.26C472.4 35.26 476.674 39.74 478.005 47.58L470.929 48.91Z"
        fill="#1D201E"
      />
      <path
        d="M496.479 69.91C485.83 69.91 482.327 65.57 481.136 59.27L487.932 57.87C488.983 61.86 491.295 64.38 496.619 64.38C500.612 64.38 502.714 62.98 502.714 60.18C502.714 57.59 500.893 56.33 494.377 55.14C485.62 53.39 482.537 50.45 482.537 44.99C482.537 39.04 486.881 35.26 495.638 35.26C504.676 35.26 507.758 38.97 509.089 44.78L502.294 46.25C501.383 42.19 499.421 40.65 495.498 40.65C491.645 40.65 489.683 42.12 489.683 44.57C489.683 46.88 491.084 48.49 497.32 49.61C507.058 51.43 510 54.72 510 59.9C510 65.99 505.937 69.91 496.479 69.91Z"
        fill="#1D201E"
      />
    </svg>
  );
}

export default AppIcon;
