import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface TeamInfoItemProps {
  label?: React.ReactNode | string;
  title: string;
  clickableTitle?: boolean;
  onTitleClick?: () => void;
  value?: string | number | null;
  icon: IconType;
}

const TeamInfoItem: React.FC<TeamInfoItemProps> = ({
  label,
  title,
  clickableTitle,
  onTitleClick,
  value,
  icon: Icon,
}) => {
  return (
    <Flex direction="column">
      {label ? (
        <Tooltip hasArrow label={label}>
          <Flex
            color="text.secondary"
            _hover={{ color: 'primary', cursor: 'pointer' }}
          >
            {clickableTitle ? (
              <Flex onClick={() => onTitleClick?.()}>
                {Icon && <Icon size={14} />}
                <Text
                  ml={1}
                  as="span"
                  cursor="pointer"
                  fontFamily={'heading'}
                  color="text.secondary"
                  fontSize="xs"
                  fontWeight="semibold"
                  _hover={{ color: 'primary', textDecoration: 'underline' }}
                >
                  {title}
                </Text>
              </Flex>
            ) : (
              <>
                {Icon && <Icon size={14} />}
                <Text
                  ml={1}
                  as="span"
                  fontFamily={'heading'}
                  fontSize="xs"
                  fontWeight="semibold"
                >
                  {title}
                </Text>
              </>
            )}
          </Flex>
        </Tooltip>
      ) : (
        <Flex color="text.secondary">
          {Icon && <Icon size={14} />}

          <Text
            ml={1}
            as="span"
            fontFamily={'heading'}
            fontSize="xs"
            fontWeight="semibold"
          >
            {title}
          </Text>
        </Flex>
      )}

      <Text
        as="span"
        fontFamily="heading"
        fontSize="xs"
        fontWeight="medium"
        color="text.primary"
        textAlign="center"
      >
        {value}
      </Text>
    </Flex>
  );
};

export default TeamInfoItem;
