import { Loader } from '@devd-client/devd/components';
import React, { Suspense } from 'react';

const IntercomInstallation = React.memo(
  React.lazy(() =>
    import('@devd-client/devd/ui-shared').then((module) => ({
      default: module.Intercom,
    }))
  )
);

export function AsyncAsyncIntercomInstallation() {
  return (
    <Suspense fallback={<Loader />}>
      <IntercomInstallation />
    </Suspense>
  );
}
