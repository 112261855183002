import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useWellbeingPage } from '../../api';
import WellbeingPage from './devd-wellbeing';

export const Wellbeing = () => {
  const { data } = useWellbeingPage();

  return (
    <>
      <MetaTags title="Well-being | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <WellbeingPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Wellbeing;
