import {
  Box,
  Button,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Loader, PageHeader } from '@devd-client/devd/components';
import { useLocation, useNavigate } from 'react-router';
import TeamMembers from './TeamMembers';
import { useFetchTeamData } from '../apis';

export const AddMember = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const team = pathname.split('/')[3];

  const { data: response, isFetching: teamDetailsLoading } =
    useFetchTeamData(team);

  const defaultData = response?.dto;

  return (
    <>
      <Stack w="full" spacing={2}>
        <Box>
          <Button variant="link" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>

        <PageHeader
          headingText={defaultData?.key?.name ? `${defaultData.key.name}` : ''}
        />

        {!defaultData && (
          <Text fontSize="sm" fontFamily="heading" fontWeight="semibold">
            {'New Team'}
          </Text>
        )}
        <Tabs>
          {defaultData && (
            <TabList>
              <Tab>
                <Text fontSize="sm" fontFamily="heading" fontWeight="semibold">
                  {'Members'}
                </Text>
              </Tab>
            </TabList>
          )}

          <TabPanels>
            <TabPanel px={0}>
              <TeamMembers team={defaultData?.key?.name} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>

      {teamDetailsLoading && <Loader />}
    </>
  );
};
