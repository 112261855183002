import { FC, useRef, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useTheme,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  EmailIcon,
  Loader,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { apiClient } from '@devd-client/api';
import ReCAPTCHA from 'react-google-recaptcha';

interface ForgetPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ForgetPasswordModal: FC<ForgetPasswordModalProps> = ({
  isOpen,
  onClose,
}) => {
  const captchaRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newToast] = useToastHook();
  const { colors } = useTheme();
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);

  const resetPassword = async (email: string, token: string) => {
    try {
      setIsLoading(true);
      apiClient(`/v1/user/passwordreset?email=${email}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          emailId: email,
          token,
        }),
      })
        .then((res: any) => {
          setIsLoading(false);
          newToast({
            message:
              'Password reset link sent to your email. Check your SPAM folder as well.',
            status: 'success',
          });
          setTimeout(() => {
            onClose();
          }, 4000);
        })
        .catch((err) => {
          setIsLoading(false);
          newToast({
            message: `${err.message}`,
            status: 'error',
          });
        });
    } catch (err) {
      newToast({
        message: `${err}`,
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reset your password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values) => {
              const token = captchaRef.current?.getValue();

              captchaRef.current.reset();
              setIsCaptchaSolved(false);
              resetPassword(values.email, token);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Email address is invalid')
                .required('Email address is required'),
            })}
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
                <Stack
                  as="form"
                  spacing={4}
                  mb={4}
                  onSubmit={handleSubmit as any}
                >
                  <TextInput
                    name="email"
                    placeholder="Email Address"
                    leftIcon={
                      <EmailIcon
                        height="16px"
                        width="16px"
                        color={colors?.text?.secondary}
                      />
                    }
                  />

                  <ReCAPTCHA
                    sitekey={'6LeIzv8pAAAAACW1OxWz4UDA5aKBoEYQwCyAewfI'}
                    ref={captchaRef}
                    onChange={(token) => setIsCaptchaSolved(!!token)}
                  />

                  <Box alignSelf={'flex-end'}>
                    <Button
                      variant="filled"
                      size="sm"
                      type="submit"
                      isDisabled={!isCaptchaSolved}
                    >
                      Send link
                    </Button>
                  </Box>
                </Stack>
              );
            }}
          </Formik>
          {isLoading && <Loader />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ForgetPasswordModal;
