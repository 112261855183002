import { Button, Flex, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useToastHook } from '../../appToast';
import { useQueryClient } from '@tanstack/react-query';
import Loader from '../../appLoader';
import {
  QUERY_KEYS,
  useStatusCategory,
  useStatusCategoryMutation,
} from '../api';

interface StatusCategoryProps {
  integrationId: string;
}

const initialValues = {
  statusCategory: {},
};

const options = [
  { value: 'Done', label: 'Done' },
  { value: 'To Do', label: 'To Do' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Exclude', label: 'Exclude' },
];

const StatusCategoryForm = ({ integrationId }: StatusCategoryProps) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const [initialIssueValues, setInitialIssueValues] =
    useState<any>(initialValues);

  const {
    mutate: handleStatusCategory,
    isLoading: handleStatusCategoryLoading,
  } = useStatusCategoryMutation();

  const { data: statusCategories, isFetching: statusCategoriesLoading } =
    useStatusCategory(integrationId);

  useEffect(() => {
    if (statusCategories?.dto) {
      setInitialIssueValues({ statusCategory: statusCategories.dto });
    }
  }, [statusCategories?.dto]);

  const onSubmit = (values: any) => {
    handleStatusCategory(
      { ...values.statusCategory, integrationId },
      {
        onSuccess: () => {
          newToast({
            message: 'Status category mapping done successfully!',
            status: 'success',
          });
          queryClient.invalidateQueries([QUERY_KEYS.statusCategory]);
        },
        onError: (err: any) => {
          newToast({
            message: err?.message ?? 'Something went wrong!',
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialIssueValues}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Stack spacing={4}>
              {statusCategoriesLoading
                ? Object.keys(values.statusCategory).map((key, index) => (
                    <HStack spacing={6} key={index}>
                      <Skeleton width="150px" height="40px" />{' '}
                      <Skeleton width="180px" height="40px" />
                    </HStack>
                  ))
                : Object.keys(values.statusCategory).map((key, index) => (
                    <HStack spacing={6} key={index}>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                        width="150px"
                      >
                        {key}:
                      </Text>
                      <Field
                        as={Select}
                        name={`statusCategory.${key}`}
                        placeholder="Select Status"
                        useBasicStyles
                        menuPlacement="auto"
                        onChange={(selectedOption: any) => {
                          setFieldValue(
                            `statusCategory.${key}`,
                            selectedOption.value
                          );
                        }}
                        options={options}
                        value={options.find(
                          (item) => item.value === values.statusCategory[key]
                        )}
                        chakraStyles={{
                          container: (provided: any) => ({
                            ...provided,
                            width: '180px',
                          }),
                        }}
                      />
                    </HStack>
                  ))}

              {Object.keys(values.statusCategory)?.length > 0 && (
                <Flex>
                  <Button w="120px" variant="tertiary" type="submit">
                    Save
                  </Button>
                </Flex>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
      {(statusCategoriesLoading || handleStatusCategoryLoading) && <Loader />}
    </>
  );
};

export default StatusCategoryForm;
