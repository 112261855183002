export const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.90604 16.3548C2.35604 16.3548 1.88537 16.1588 1.49404 15.7667C1.10204 15.374 0.906036 14.9022 0.906036 14.3512V2.32964C0.906036 1.77865 1.10204 1.30714 1.49404 0.915105C1.88537 0.522402 2.35604 0.32605 2.90604 0.32605H18.906C19.456 0.32605 19.927 0.522402 20.319 0.915105C20.7104 1.30714 20.906 1.77865 20.906 2.32964V14.3512C20.906 14.9022 20.7104 15.374 20.319 15.7667C19.927 16.1588 19.456 16.3548 18.906 16.3548H2.90604ZM10.906 9.34221L2.90604 4.33323V14.3512H18.906V4.33323L10.906 9.34221ZM10.906 7.33862L18.906 2.32964H2.90604L10.906 7.33862ZM2.90604 4.33323V2.32964V14.3512V4.33323Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};
