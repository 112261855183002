export const PerformanceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M987.043172 1000.472648H760.73245V95.229759c0-17.925602 14.564551-31.369803 31.369803-31.369803H954.553019c17.925602 0 31.369803 14.564551 31.369803 31.369803l1.12035 905.242889z"
        fill="#8DCAF5"
      />
      <path
        d="M1009.450174 1022.87965H738.325447V95.229759c0-14.564551 5.601751-28.008753 15.684902-38.091903 10.083151-10.083151 23.527352-15.684902 38.091904-15.684902H954.553019c30.249453 0 53.776805 24.647702 53.776805 53.776805v927.649891z m-226.310722-44.814004h181.496718V95.229759c0-5.601751-4.4814-8.962801-8.962801-8.962801H793.222603c-3.36105 0-5.601751 1.12035-6.722101 2.240701-1.12035 1.12035-2.2407 3.36105-2.2407 6.7221v882.835887z"
        fill="#131313"
      />
      <path
        d="M621.809036 1001.592998H396.618664v-683.413567c0-15.684902 12.323851-26.888403 28.008753-26.888403H596.040984c15.684902 0 28.008753 12.323851 28.008752 26.888403-2.2407 0-2.2407 683.413567-2.2407 683.413567z"
        fill="#FB7E7E"
      />
      <path
        d="M644.216038 1024H374.211662v-705.820569c0-28.008753 22.407002-49.295405 50.415755-49.295405H596.040984c28.008753 0 50.415755 22.407002 50.415754 49.295405v705.820569z m-225.190372-44.814004h181.496718v-661.006565c0-2.2407-2.2407-4.4814-5.601751-4.4814H423.507067c-2.2407 0-5.601751 2.2407-5.601751 4.4814v661.006565z"
        fill="#131313"
      />
      <path
        d="M262.176651 1000.472648H35.865929V534.407002c0-12.323851 10.083151-22.407002 22.407002-22.407002h181.496718c12.323851 0 22.407002 10.083151 22.407002 22.407002v466.065646z"
        fill="#87C1EA"
      />
      <path
        d="M284.583653 1022.87965H13.458927V534.407002c0-24.647702 20.166302-44.814004 44.814004-44.814004h181.496718c24.647702 0 44.814004 20.166302 44.814004 44.814004v488.472648zM58.272931 978.065646h181.496718V534.407002H58.272931v443.658644z"
        fill="#131313"
      />
      <path
        d="M173.668992 1022.87965c-12.323851 0-22.407002-10.083151-22.407002-22.407002V587.063457c0-12.323851 10.083151-22.407002 22.407002-22.407002s22.407002 10.083151 22.407002 22.407002v413.409191c0 13.444201-10.083151 22.407002-22.407002 22.407002zM532.181027 1022.87965c-12.323851 0-22.407002-10.083151-22.407002-22.407002V362.993435c0-12.323851 10.083151-22.407002 22.407002-22.407002s22.407002 10.083151 22.407002 22.407002v637.479213c0 13.444201-10.083151 22.407002-22.407002 22.407002z"
        fill="#131313"
      />
      <path
        d="M890.693062 1022.87965c-12.323851 0-22.407002-10.083151-22.407002-22.407002V140.043764c0-12.323851 10.083151-22.407002 22.407002-22.407003s22.407002 10.083151 22.407002 22.407003v860.428884c0 13.444201-10.083151 22.407002-22.407002 22.407002z"
        fill="#131313"
      />
      <path
        d="M309.231355 182.617068c-12.323851 0-22.407002-10.083151-22.407002-22.407002V44.814004H170.307942c-12.323851 0-22.407002-10.083151-22.407002-22.407002s10.083151-22.407002 22.407002-22.407002h160.210066v160.210066c1.12035 12.323851-8.962801 22.407002-21.286653 22.407002z"
        fill="#131313"
      />
      <path
        d="M35.865929 320.420131c-5.601751 0-11.203501-2.2407-15.684902-6.7221-8.962801-8.962801-8.962801-22.407002 0-31.369803L293.546454 7.842451c8.962801-8.962801 22.407002-8.962801 31.369803 0 8.962801 8.962801 8.962801 22.407002 0 31.369803L51.55083 313.698031c-4.4814 4.4814-10.083151 6.722101-15.684901 6.7221z"
        fill="#131313"
      />
    </svg>
  );
};
