import { useState, useEffect, useRef } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
} from '@tanstack/react-table';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Input,
  HStack,
  Text,
  Tag,
  Button,
  Flex,
  TableContainer,
  Image,
  useFocusEffect,
  useTheme,
} from '@chakra-ui/react';
import { Loader, Upload } from '@devd-client/devd/components';
import { useVirtualizer } from '@tanstack/react-virtual';

interface DataTableProps<TData, TValue> {
  onClose: () => void;
  setData: (old: any) => void;
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  selectedRows: any;
  setSelectedRows: any;
  initiallySelectedRows: any;
  label?: string;
  activeTag: any;
  handleTagClick: any;
  tags: string[];
  bulkUploadModal: any;

  loading: boolean;
  openDropdown: { row: number; isOpen: boolean };
}

export function DataTable<TData, TValue>({
  onClose,
  bulkUploadModal,
  openDropdown,
  columns,
  setData,
  data,
  setSelectedRows,
  initiallySelectedRows,
  label,
  activeTag,
  handleTagClick,
  tags,
  loading,
}: DataTableProps<TData, TValue>) {
  const { colors } = useTheme();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [filtering, setFiltering] = useState('');
  const [initialDataSet, setInitialDataSet] = useState(true);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
      rowSelection,
      globalFilter: filtering,
    },
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setData((old: any) =>
          old.map((row: any, index: any) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    onGlobalFilterChange: setFiltering,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
  });

  // set the data of table on first render and whenever we change tabs.
  useEffect(() => {
    if (data.length > 0 && initialDataSet) {
      setInitialDataSet(false);
      const mapData: any = {};
      if (activeTag === 1) {
        setRowSelection(mapData);
        return;
      }
      data?.map((item: any, index) => {
        initiallySelectedRows?.map((selectedItem: any) => {
          if (selectedItem?.email === item?.email) {
            mapData[index] = true;
          }
        });
      });
      setRowSelection(mapData);
    }
  }, [data]);

  useEffect(() => {
    setInitialDataSet(true);
  }, [activeTag]);

  useEffect(() => {
    const handleSelectionState = (selections: RowSelectionState) => {
      setSelectedRows((prev: any) =>
        Object.keys(selections)?.map((key) => {
          return (
            table.getSelectedRowModel().rowsById[key]?.original ||
            prev.find((row: any) => row.id === key)
          );
        })
      );
    };

    handleSelectionState(rowSelection);
  }, [rowSelection, data]);

  //The virtualizer needs to know the scrollable container element.
  // refer the react tan stack tables documentaion for more info. mainly the virtualiziation example.
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element: any) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <Box>
      <HStack spacing={4} py={4}>
        <Input
          placeholder={`Search ${label}`}
          value={filtering}
          onChange={(e) => setFiltering(e.target.value)}
          maxW="sm"
        />
        <Button
          variant="primary"
          fontSize={'sm'}
          fontWeight={'semibold'}
          w={140}
          display={'flex'}
          onClick={() => {
            bulkUploadModal.onOpen();
            onClose();
          }}
          gap={1}
        >
          <Upload color={colors.primary} />
          Import CSV
        </Button>
      </HStack>
      <Text
        mb={2}
        fontSize="sm"
        fontFamily="heading"
        color="text.secondary"
        fontWeight="medium"
      >
        {`Total ${data?.length || 0} ${label}`}
      </Text>
      <Flex justify="space-between" align="center">
        <Box mb={2}>
          {tags.map((tag: string, index: number) => (
            <Tag
              fontFamily={'inter'}
              size="sm"
              key={index}
              bg={activeTag === index ? 'primaryBg' : 'white'}
              color={activeTag === index ? 'primary' : 'text.primary'}
              border="1px solid"
              borderColor={activeTag === index ? 'primary' : 'text.secondary'}
              borderRadius="full"
              px={4}
              py={2}
              m={1}
              cursor="pointer"
              onClick={() => handleTagClick(index)}
            >
              {tag}
            </Tag>
          ))}
        </Box>
      </Flex>
      <Box
        height="350px"
        marginInline={'auto'}
        borderWidth="1px"
        borderRadius="md"
      >
        <TableContainer
          ref={tableContainerRef}
          height="350px"
          overflowY={'auto'}
          overflowX={'hidden'}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table>
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th
                        textTransform="none"
                        {...{
                          key: header.id,
                          // style: { maxWidth: 150 },
                          width: header.getSize(),
                        }}
                      >
                        <Box
                        // {...{
                        //   style: {
                        //     width: header.getSize(),
                        //   },
                        // }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Box>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody
                style={{
                  height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
                  position: 'relative', //needed for absolute positioning of rows
                }}
              >
                {table.getRowModel().rows?.length ? (
                  rowVirtualizer.getVirtualItems().map((virtualRow: any) => {
                    const row = rows[virtualRow.index];

                    return (
                      <Tr
                        data-index={virtualRow.index}
                        ref={(node) => rowVirtualizer.measureElement(node)}
                        key={row.id}
                        data-state={
                          row.getIsSelected() ? 'selected' : undefined
                        }
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                          width: '100%',
                          height: 120,
                        }}
                      >
                        {row.getVisibleCells().map((cell: any) => {
                          return (
                            <Td
                              fontFamily={'heading'}
                              {...{
                                key: cell.id,
                                style: {
                                  width: cell.column.getSize(),
                                  overflow:
                                    cell.id.includes('teamRole') &&
                                    openDropdown.isOpen
                                      ? 'visible'
                                      : 'hidden',
                                },
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td colSpan={columns.length} textAlign="center">
                      No results.
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Box>
      <HStack justifyContent="space-between" mt={4}>
        <Text fontFamily={'inter'} fontSize="sm" color="gray.500">
          {table.getFilteredSelectedRowModel()?.rows?.length} of{' '}
          {table.getFilteredRowModel()?.rows?.length} row(s) selected.
        </Text>
      </HStack>
    </Box>
  );
}
