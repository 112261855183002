import {
  Box,
  Flex,
  IconButton,
  Progress,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  camelCaseToCapitalize,
  getColor,
} from '../../../helpers/Initiatives.utils';
import {
  ConfirmButton,
  DataGrid,
  Paginate,
  ProgressMultiColor,
  useToastHook,
} from '@devd-client/devd/components';
import { InitiativeContext } from '../../../contexts/initiativesContext';
import styled from 'styled-components';
import { BiArchiveIn, BiLinkExternal } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { FiEdit } from 'react-icons/fi';
import InitiativeEditModal from './InitiativeEditModal';
import ArchiveModalEdit from './ArchiveModalEdit';
import AllocatioInitiativenModal from './AllocationModal';
import moment from 'moment';
import { MdOutlineEdit } from 'react-icons/md';
import { useRemoveInitiative } from '../../../apis';
import { QUERY_KEYS } from '../../../graphql';
import { useQueryClient } from '@tanstack/react-query';

type AnchorLinkType = {
  mr?: string;
  ml?: string;
};

const AnchorLink = styled.a<AnchorLinkType>`
  text-decoration: underline;
  margin-right: ${({ mr }) => mr && mr};
  margin-left: ${({ ml }) => ml && ml};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;

interface ListViewProps {
  handlePageClick: (page: number) => void;
  showLoader: boolean;
}

const ListView: FC<ListViewProps> = ({ handlePageClick, showLoader }) => {
  const {
    state: { initiativeData },
  } = useContext(InitiativeContext);
  const { colors } = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [newToast] = useToastHook();

  const {
    mutate: handleRemoveInitiative,
    isLoading: handleRemoveInitiativeLoading,
  } = useRemoveInitiative();

  const rows: any[] = useMemo(
    () => initiativeData?.initiativeList || [],
    [initiativeData?.initiativeList]
  );
  const [checkedItem, setCheckedItem] = useState<string[]>([]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Flex align="center">
            <StyledLink
              to={`/initiatives/${info.getValue()}?id=${info.row.original?.id}`}
            >
              {info.getValue()}
            </StyledLink>
            <AnchorLink
              ml={'5px'}
              href={info.row.original.link}
              target="_blank"
              rel="noreferrer"
            >
              {/* <BiLinkExternal /> */}
            </AnchorLink>
          </Flex>
        ),
        size: 140,
        header: 'Name',
      }),
      columnHelper.accessor('desc', {
        cell: (info) => info.getValue(),
        minSize: 200,
        header: 'Description',
      }),

      columnHelper.accessor('status', {
        cell: (info) => (
          <>
            <Text>{camelCaseToCapitalize(info.getValue())}</Text>
            <Tag
              size="sm"
              style={{
                backgroundColor: `${info.row.original?.legend?.color}90`,
              }}
              fontSize="xxs"
            >
              {camelCaseToCapitalize(info.row.original?.legend?.label)}
            </Tag>
          </>
        ),
        size: 80,
        header: 'Status',
      }),

      columnHelper.accessor('progress', {
        cell: (info) => (
          <Tooltip
            hasArrow
            label={
              <Flex direction="column">
                <Text as="span">
                  Completed Issues : {info.row.original?.completedTasks || 0}
                </Text>
                <Text as="span">
                  Total Task : {info.row.original?.totalTasks || 0}
                </Text>
                {info.getValue() && <Text>Progress: {info.getValue()}%</Text>}
              </Flex>
            }
          >
            <Flex align="flex-end" direction="column" w="full">
              <Text
                fontSize="xs"
                fontFamily="heading"
                color="text.secondary"
                ml={2}
              >
                {info.row.original?.completedTasks || 0} /{' '}
                {info.row.original?.totalTasks || 0}
              </Text>
              <Box width="full">
                <Progress
                  borderRadius="10px"
                  height="10px"
                  value={info.row?.original?.progress}
                  sx={{
                    '& > div': {
                      backgroundColor: colors.primary,
                    },
                  }}
                />
              </Box>
            </Flex>
          </Tooltip>
        ),
        size: 125,
        header: 'Progress',
      }),
      columnHelper.accessor('contributionTracking', {
        cell: (info) =>
          info.getValue() &&
          info.getValue()?.charAt(0)?.toUpperCase() + info.getValue()?.slice(1),
        header: 'Contribution Tracking',
        size: 100,
      }),

      columnHelper.accessor('startDate', {
        cell: (info) => info.getValue() && moment(info.getValue()).format('ll'),
        header: 'Start Date',
        size: 100,
      }),

      columnHelper.accessor('plannedDate', {
        cell: (info) => info.getValue() && moment(info.getValue()).format('ll'),
        size: 100,
        header: 'Planned Date',
      }),
      columnHelper.accessor('actions', {
        cell: (info) => (
          <Flex gap={2}>
            <Tooltip label="Edit" hasArrow>
              <IconButton
                aria-label="edit"
                bg="transparent"
                size="sm"
                color="text.secondary"
                icon={<MdOutlineEdit size={16} />}
                position={'static'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`edit`, {
                    state: {
                      ...info.row.original,
                      selectedCustomFieldName:
                        info.row.original?.issueDefinition?.customField?.name ||
                        null,
                      selectedCustomFieldValue:
                        info.row.original?.issueDefinition?.customField
                          ?.value || null,
                    },
                  });
                }}
              />
            </Tooltip>

            <ConfirmButton
              buttonText="Delete"
              showIcon
              bodyText={`Do you really want to delete this Initiative - ${info.row.original?.name}?`}
              headerText="Are you sure?"
              onSuccessAction={() => {
                handleRemoveInitiative(
                  { id: info.row.original?.id },
                  {
                    onSuccess() {
                      queryClient.invalidateQueries([QUERY_KEYS.initiativeGql]);
                      newToast({
                        message: `${info.row.original?.name} Deleted Successfully!`,
                        status: 'success',
                      });
                    },
                    onError(e: any) {
                      newToast({
                        message: e?.message,
                        status: 'error',
                      });
                    },
                  }
                );
              }}
            />
          </Flex>
        ),
        header: 'Actions',
      }),
    ],
    [initiativeData?.initiativeList]
  );

  return (
    <>
      <DataGrid
        columns={columns}
        data={rows}
        showLoader={showLoader || handleRemoveInitiativeLoading}
        maxH="28rem"
        checkedItem={checkedItem}
        setCheckedItem={setCheckedItem}
        sticky="firstCol"
      />
      <Box marginLeft="-20px">
        <Paginate
          currentPage={initiativeData?.currentPage + 1}
          totalPageCount={initiativeData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
    </>
  );
};

export default ListView;
