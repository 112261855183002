import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';

export const QUERY_KEYS = {
  teamListHeatmap: 'teamListHeatmap',
};

export const useFetchTeamList = () => {
  return useQuery([QUERY_KEYS.teamListHeatmap], ENDPOINTS.getteamList);
};
