import {
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';
import './chart.css';
import { Box } from '@chakra-ui/react';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import {
  ISSUE_AGE_CHART_KEYS,
  ISSUE_CHART_KEYS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  PR_AGEING_CHARTS,
} from '../shared/utils';
import { useCallback, useState } from 'react';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';

interface PieChartRechartsProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  ticketType?: string;
  selected: any;
  queryKey?: string;
  repos?: string[];
}

export const PieChartRecharts = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  ticketType,
  selected,
  queryKey,
  repos,
}: PieChartRechartsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.05;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const count = data[index].value;

    return (
      <text
        x={x}
        y={y}
        fill="#778899"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={12}
        fontWeight={500}
      >
        {chartMetadata.chartKey === 'ISSUE_PROGRESSION_RATE'
          ? `${count}%`
          : `${count} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  return (
    <>
      <Box
        position="relative"
        width="100%"
        height={showLabel ? '100%' : '240px'}
      >
        <ResponsiveContainer>
          <PieChart
            margin={{
              top: 10,
              right: 35,
              left: 108,
            }}
          >
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="20%"
              cy="50%"
              outerRadius={'85%'}
              label={renderCustomizedLabel}
              labelLine={false}
              onClick={(event: any, payload: any) => {
                handleModalOpen();
                setClickedData({
                  ...payload,
                  name: event?.name,
                });
                setCurrentChartMeta(chartMetadata);
              }}
            >
              {data?.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color}
                  style={{ outline: 'none', cursor: 'pointer' }}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip hideBtn={true} />} />
            <Legend
              verticalAlign="middle"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: '25%',
                transform: 'translateY(-50%)',
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>

      {[
        ...ISSUE_CHART_KEYS,
        ...ISSUE_AGE_CHART_KEYS,
        ...PR_AGEING_CHARTS,
        ...ISSUE_LIFECYCLE_DIST_CHARTS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={queryKey}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata?.chartTitle}
            subHeading={`${moment(selected?.startDate).format(
              'DD MMM'
            )} - ${moment(selected?.endDate).format('DD MMM')}, ${
              clickedData?.name
            } (${
              data.find((d: any) => d?.label === clickedData?.name)?.value
            } %)`}
            payload={{
              ...clickedData,

              payload: {
                startDate: selected?.startDate,
                endDate: selected?.endDate,
              },
            }}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              type: ticketType,
              age:
                chartMetadata.chartKey === 'ISSUE_PROGRESSION_RATE'
                  ? null
                  : clickedData?.name,

              projectsFilter: repos,
              sprintId: selected.sprintId,
              status:
                chartMetadata.chartKey === 'ISSUE_PROGRESSION_RATE'
                  ? clickedData?.name
                  : '',
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
