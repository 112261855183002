import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '../../hooks/useCustomMutation';

export const QUERY_KEYS = {
  intDetails: 'intDetails',
  excludeBranches: 'excludeBranches',
};

export const useFetchIntegrationGitDetails = (
  id: string,
  integrationName: string
) => {
  return useQuery(
    [QUERY_KEYS.intDetails, integrationName],
    () => ENDPOINTS.getIntegrationGitDetails(id, integrationName),
    {
      enabled: !!id,
      keepPreviousData: false,
    }
  );
};

export const useGitExcludeBranchesMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.excludeBranches],
    ENDPOINTS.excludeBranches
  );
};
