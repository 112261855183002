import styled from 'styled-components';

export const Container = styled.div`
  height: 90vh;
  width: 100%;
  position: relative;
`;

type ZeroStatePageContainerProps = {
  showBlur: boolean;
};

export const ZeroStatePageContainer = styled.div<ZeroStatePageContainerProps>`
  min-height: 80vh;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  opacity: ${({ showBlur }) => (showBlur ? '0.5' : '1')};
  pointer-events: ${({ showBlur }) => showBlur && 'none'};
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: fit-content;
  margin-bottom: 50px;
`;

export const LeftSection = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightSection = styled.div`
  width: 60%;
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['4xl']};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 35px;
  margin-bottom: 24px;
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 25px;
  margin-bottom: 50px;
`;

export const ButtonContainer = styled.div``;

export const BottomContainer = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
