export function ClojureIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 256 256"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <g>
        <path
          d="M127.999,0 C57.423,0 -1.42108547e-14,57.423 -1.42108547e-14,128.001 C-1.42108547e-14,198.585 57.423,256.005 127.999,256.005 C198.577,256.005 256,198.585 256,128.001 C256,57.423 198.577,0 127.999,0"
          fill="#FFFFFF"
        ></path>
        <g transform="translate(12.000000, 12.000000)">
          <path
            d="M111.318,118.303 C110.168,120.795 108.899,123.595 107.585,126.575 C102.94,137.099 97.796,149.905 95.917,158.109 C95.242,161.031 94.824,164.652 94.832,168.667 C94.832,170.255 94.917,171.924 95.052,173.624 C101.619,176.037 108.712,177.364 116.119,177.377 C122.862,177.364 129.34,176.25 135.403,174.234 C133.978,172.931 132.618,171.542 131.38,169.977 C123.16,159.495 118.574,144.133 111.318,118.303"
            fill="#91DC47"
          ></path>
          <path
            d="M80.97,66.225 C65.271,77.289 54.998,95.537 54.959,116.217 C54.998,136.588 64.962,154.6 80.266,165.71 C84.02,150.073 93.43,135.755 107.541,107.055 C106.703,104.753 105.748,102.233 104.679,99.586 C100.77,89.78 95.128,78.392 90.093,73.235 C87.526,70.541 84.411,68.213 80.97,66.225"
            fill="#91DC47"
          ></path>
          <path
            d="M169.394,186.367 C161.294,185.352 154.609,184.127 148.761,182.064 C138.925,186.948 127.848,189.707 116.119,189.707 C75.535,189.707 42.636,156.813 42.631,116.217 C42.631,94.19 52.335,74.444 67.687,60.977 C63.581,59.985 59.299,59.406 54.925,59.414 C33.363,59.617 10.602,71.55 1.126,103.777 C0.24,108.468 0.451,112.015 0.451,116.219 C0.451,180.104 52.242,231.895 116.122,231.895 C155.244,231.895 189.804,212.456 210.733,182.726 C199.413,185.547 188.527,186.896 179.205,186.925 C175.711,186.925 172.431,186.738 169.394,186.367"
            fill="#63B132"
          ></path>
          <path
            d="M147.658,163.953 C148.372,164.307 149.991,164.885 152.244,165.524 C167.401,154.397 177.251,136.474 177.29,116.217 L177.284,116.217 C177.227,82.446 149.898,55.121 116.119,55.054 C109.405,55.067 102.955,56.175 96.916,58.176 C109.335,72.332 115.307,92.562 121.084,114.691 C121.087,114.701 121.092,114.709 121.094,114.717 C121.105,114.735 122.942,120.862 126.096,128.991 C129.228,137.109 133.69,147.159 138.556,154.483 C141.751,159.391 145.265,162.918 147.658,163.953"
            fill="#90B4FE"
          ></path>
          <path
            d="M116.122,0.541 C77.378,0.541 43.106,19.614 22.114,48.859 C33.039,42.017 44.194,39.549 53.929,39.637 C67.375,39.676 77.946,43.845 83.018,46.697 C84.243,47.403 85.406,48.163 86.545,48.944 C95.595,44.958 105.595,42.729 116.119,42.729 C156.708,42.734 189.612,75.628 189.618,116.217 L189.612,116.217 C189.612,136.681 181.242,155.184 167.749,168.508 C171.061,168.879 174.593,169.11 178.2,169.092 C191.011,169.098 204.858,166.271 215.239,157.54 C222.008,151.838 227.679,143.489 230.824,130.971 C231.439,126.136 231.793,121.221 231.793,116.219 C231.793,52.337 180.007,0.541 116.122,0.541"
            fill="#5881D8"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default ClojureIcon;
