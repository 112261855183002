import { ISelect, PeriodType } from '@devd-client/devd/components';
import { FC } from 'react';
import styled from 'styled-components';
import LeftSection from '../LeftSection';
import RightSection from '../RightSection';

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface MainContentProps {
  selectedTeam: ISelect;
  setSelectedTeam: (selectedTeam: ISelect) => void;
  selectedAction: string;
  setSelectedAction: (goal: string) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  periodOptions: PeriodType[];
  setPeriodOptions: (periodOptions: PeriodType[]) => void;
}

const MainContent: FC<MainContentProps> = ({
  selectedTeam,
  setSelectedTeam,
  selectedAction,
  setSelectedAction,
  selected,
  setSelected,
  periodOptions,
  setPeriodOptions,
}) => {
  return (
    <MainContentContainer>
      <LeftSection
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
      />
      <RightSection
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        selected={selected}
        setSelected={setSelected}
        periodOptions={periodOptions}
        setPeriodOptions={setPeriodOptions}
      />
    </MainContentContainer>
  );
};

export default MainContent;
