import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Flex, Switch, Tooltip } from '@chakra-ui/react';
import SettingsModal from '../SettingsModal';
import {
  ISelect,
  Loader,
  isHTML,
  useToastHook,
} from '@devd-client/devd/components';
import {
  DetailsHeading,
  DetailsSectionContainer,
  DetailsParagraph,
  Divider,
  ToggleButtonContainer,
  ToggleText,
  SettingBtnContainer,
  TagLabel,
  TagLabels,
  Legend,
  Dot,
  Text,
} from './DetailSection.styled';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS, useGoalSettingsMutation } from '../../../../../../api';
import ShowFields from './ShowFeilds';
import ReactHtmlParser from 'html-react-parser';
import { useLocation } from 'react-router';
import { useTeamDetailsStore } from '../../../../../../store/teamDetail';

interface DetailsSectionProps {
  selectedTeam: ISelect;
  agreement: any;
  insideModal?: boolean;
}
const isMultiline = (text: string) => text && text.includes('\n');

const DetailsSection: FC<DetailsSectionProps> = ({
  selectedTeam,
  agreement,
  insideModal,
}) => {
  const [newToast] = useToastHook();
  const { pathname } = useLocation();

  const [expanded, setExpanded] = useState(false);
  const isHtmlContent = isHTML(agreement?.description);
  const isMultilineContent = isMultiline(agreement?.description);

  const { memberCount } = useTeamDetailsStore();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeToggle, setActiveToggle] = useState<boolean>(false);
  const [dataToSend, setDataToSend] = useState<any>({});

  const queryClient = useQueryClient();
  const { mutate: handleGoalUpdate, isLoading } = useGoalSettingsMutation();

  useEffect(() => {
    setActiveToggle(agreement?.enabled);
  }, [agreement]);

  useEffect(() => {
    setDataToSend({
      ...agreement,
      team: decodeURIComponent(pathname.split('/')[2]),
    });
  }, []);

  const handleModalOpen = () => {
    if (!selectedTeam.value) {
      newToast({ message: 'Select any team', status: 'error' });
      return;
    } else {
      setIsOpen(true);
    }
  };

  const handleModalClose = useCallback(() => setIsOpen(false), [isOpen]);

  return (
    <>
      <DetailsSectionContainer insideModal={insideModal}>
        <Box>
          {agreement?.tags?.length > 0 && (
            <TagLabels>
              {agreement?.tags?.map((item: string) => (
                <TagLabel key={item}>{item}</TagLabel>
              ))}
            </TagLabels>
          )}
          <DetailsHeading>{agreement?.title}</DetailsHeading>
          <Box>
            {isHtmlContent ? (
              <Flex direction="column">
                <Box
                  flex={1}
                  style={{
                    display: expanded ? 'block' : '-webkit-box',
                    WebkitLineClamp: expanded
                      ? 'unset'
                      : isMultilineContent
                      ? 1
                      : 'unset',
                    WebkitBoxOrient: 'vertical',
                    overflow: expanded ? 'visible' : 'hidden',
                  }}
                >
                  {ReactHtmlParser(agreement?.description)}
                </Box>
                {isMultilineContent && !expanded && (
                  <Box>
                    <Button
                      variant="link"
                      color="primary"
                      size="sm"
                      onClick={() => setExpanded(true)}
                    >
                      View More
                    </Button>
                  </Box>
                )}
                {isMultilineContent && expanded && (
                  <Box>
                    <Button
                      variant="link"
                      color="primary"
                      size="sm"
                      onClick={() => setExpanded(false)}
                    >
                      View Less
                    </Button>
                  </Box>
                )}
              </Flex>
            ) : (
              <DetailsParagraph>{agreement?.description}</DetailsParagraph>
            )}
          </Box>
        </Box>

        {agreement?.goalStatus ? (
          <Tooltip
            hasArrow
            label={'Percentage change'}
            placement="bottom-start"
          >
            <Legend>
              <Dot color={agreement?.goalStatus?.color} />
              <Text>
                {parseFloat(agreement?.goalStatus?.improvementPercent).toFixed(
                  2
                )}
                %
              </Text>
            </Legend>
          </Tooltip>
        ) : (
          <Legend></Legend>
        )}

        <Box>
          <Divider />

          <Flex align="center" justify="space-between">
            <ShowFields clickedGoal={agreement} />

            <ToggleButtonContainer>
              <ToggleText>Active</ToggleText>
              <Switch
                isChecked={activeToggle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (memberCount === 0 || memberCount === null) {
                    newToast({
                      message: 'Please add member in the team.',
                      status: 'error',
                    });
                    return;
                  }

                  if (
                    localStorage.getItem('userId') ===
                    'a867b5e7-ee22-4a9f-becd-b8f3aa43f753'
                  ) {
                    newToast({
                      message: "You don't have access.",
                      status: 'error',
                    });
                    return;
                  }

                  setActiveToggle(e.target.checked);

                  handleGoalUpdate(
                    { ...dataToSend, enabled: e.target.checked },
                    {
                      onSuccess: () => {
                        queryClient.invalidateQueries([QUERY_KEYS.goals]);
                        newToast({
                          message: `Working Agreement status changed successfully.`,
                          status: 'success',
                        });
                      },
                    }
                  );
                }}
              />
            </ToggleButtonContainer>
          </Flex>
        </Box>
        {!insideModal && (
          <SettingBtnContainer>
            <Button
              variant="link"
              color="primary"
              onClick={() => {
                if (
                  localStorage.getItem('userId') ===
                  'a867b5e7-ee22-4a9f-becd-b8f3aa43f753'
                ) {
                  newToast({
                    message: "You don't have access.",
                    status: 'error',
                  });
                  return;
                } else if (!activeToggle) {
                  newToast({
                    message: 'Settings disabled for inactive agreements.',
                    status: 'error',
                  });
                } else {
                  handleModalOpen();
                }
              }}
            >
              Settings
            </Button>
          </SettingBtnContainer>
        )}
      </DetailsSectionContainer>

      {isOpen && (
        <SettingsModal
          isOpen={isOpen}
          handleModalClose={handleModalClose}
          selectedTeam={selectedTeam.value}
          clickedGoal={agreement}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default DetailsSection;
