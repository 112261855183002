import { Box, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <Flex>
      {breadcrumbs.map(({ breadcrumb, match }: any, index: number) => (
        <Box key={match.pathname}>
          <Text
            fontSize="sm"
            fontWeight="medium"
            color="text.secondary"
            as={Link}
            to={match.pathname || ''}
            _hover={{ textDecoration: 'underline' }}
          >
            {decodeURIComponent(breadcrumb?.props?.children)}
          </Text>
          &nbsp;
          {index < breadcrumbs.length - 1 && '>'}
          &nbsp;
        </Box>
      ))}
    </Flex>
  );
};

export default Breadcrumbs;
