export const EyeOpenIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.906 12.4804C13.156 12.4804 14.2187 12.0423 15.094 11.1661C15.9687 10.2892 16.406 9.22461 16.406 7.97237C16.406 6.72012 15.9687 5.65555 15.094 4.77864C14.2187 3.90241 13.156 3.46429 11.906 3.46429C10.656 3.46429 9.59337 3.90241 8.71804 4.77864C7.84337 5.65555 7.40604 6.72012 7.40604 7.97237C7.40604 9.22461 7.84337 10.2892 8.71804 11.1661C9.59337 12.0423 10.656 12.4804 11.906 12.4804ZM11.906 10.6772C11.156 10.6772 10.5187 10.4141 9.99404 9.8878C9.4687 9.36219 9.20604 8.72371 9.20604 7.97237C9.20604 7.22102 9.4687 6.58221 9.99404 6.05593C10.5187 5.53032 11.156 5.26752 11.906 5.26752C12.656 5.26752 13.2937 5.53032 13.819 6.05593C14.3437 6.58221 14.606 7.22102 14.606 7.97237C14.606 8.72371 14.3437 9.36219 13.819 9.8878C13.2937 10.4141 12.656 10.6772 11.906 10.6772ZM11.906 15.4858C9.4727 15.4858 7.25604 14.8053 5.25604 13.4442C3.25604 12.0837 1.80604 10.2598 0.906036 7.97237C1.80604 5.68493 3.25604 3.86066 5.25604 2.49956C7.25604 1.13912 9.4727 0.4589 11.906 0.4589C14.3394 0.4589 16.556 1.13912 18.556 2.49956C20.556 3.86066 22.006 5.68493 22.906 7.97237C22.006 10.2598 20.556 12.0837 18.556 13.4442C16.556 14.8053 14.3394 15.4858 11.906 15.4858ZM11.906 13.4822C13.7894 13.4822 15.5187 12.9853 17.094 11.9916C18.6687 10.9985 19.8727 9.65872 20.706 7.97237C19.8727 6.28601 18.6687 4.94594 17.094 3.95216C15.5187 2.95905 13.7894 2.46249 11.906 2.46249C10.0227 2.46249 8.29337 2.95905 6.71804 3.95216C5.14337 4.94594 3.93937 6.28601 3.10604 7.97237C3.93937 9.65872 5.14337 10.9985 6.71804 11.9916C8.29337 12.9853 10.0227 13.4822 11.906 13.4822Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};
