import { useQuery } from '@tanstack/react-query';
import {
  throughPutMetricsGenericGraphql,
  cycleTimeMetricsGenericGraphql,
  doraGenericGraphql,
  commentPerPr,
  changeFailureGraphql,
  meetingMetricsGraphql,
  LeadTimeMetricsGraphql,
  wellbeingGraphql,
  qualityGraphql,
  sizeBreakdown,
  customMetricGraphql,
  valueStreamGraphql,
  cycleTimeDistGraphql,
  issueCycleTimeGraphql,
  issuesAgeingGraphql,
  requirementStabilityGraphql,
  reviewerDistributionGraphql,
  prAgeingDistGraphql,
  averageActiveDaysGraphql,
  prHealthIndexGraphql,
  mergeHealthIndexGraphql,
  prCycleTimeBreakdownGraphql,
  changeHealthGraphql,
  deploymentFrequencyDistributionGraphql,
  leadTimeBreakDownGraphql,
  meanTimeToRecoveryDistributionGraphql,
  backwardMomentumGraphql,
  dependencyVulnerabilitiesGraphql,
  locVsPrCycleTimeGraphql,
  issueTimelineDistributionGraphql,
  workBreakdownGraphql,
  issueLifeCycleDistributionGraphql,
  pipelineMetricsByTypeGraphql,
  sprintGraphql,
} from './query';

export const allMetricsList: {
  type: string;
  query: string;
  repoFilter?: boolean;
}[] = [
  { type: 'PR_MERGE', query: 'throughput', repoFilter: true },
  { type: 'BUG_CLOSE', query: 'throughput' },
  { type: 'PROD_BUG_OPEN', query: 'throughput' },
  { type: 'BUG_OPEN', query: 'throughput' },
  { type: 'BUG_REOPENED', query: 'throughput' },
  { type: 'PR_REVIEW', query: 'throughput', repoFilter: true },
  { type: 'PR_CHANGES', query: 'throughput', repoFilter: true },
  { type: 'TASK_CLOSE', query: 'throughput' },
  { type: 'TASK_OPEN', query: 'throughput' },
  { type: 'PR_OPEN', query: 'throughput', repoFilter: true },
  { type: 'STORY_POINT_COMPLETED', query: 'throughput' },
  { type: 'CONTRIBUTOR_COUNT', query: 'throughput', repoFilter: true },
  { type: 'NEW_LINE_OF_CODE', query: 'throughput' },

  { type: 'DEPLOYMENT_FREQUENCY', query: 'dora' },
  { type: 'RELEASE_FREQUENCY', query: 'dora' },
  { type: 'LEAD_TIME_FOR_CHANGE', query: 'dora' },

  { type: 'MEAN_TIME_TO_RECOVERY', query: 'changefailure' },
  { type: 'CHANGE_FAILURE_RATE', query: 'changefailure' },
  { type: 'CHANGE_FAILURE', query: 'changefailure' },

  { type: 'PR_CYCLE_TIME', query: 'cycleTime', repoFilter: true },
  { type: 'CODING_CYCLE_TIME', query: 'cycleTime', repoFilter: true },
  { type: 'PICKUP_CYCLE_TIME', query: 'cycleTime', repoFilter: true },
  { type: 'MERGE_CYCLE_TIME', query: 'cycleTime', repoFilter: true },

  { type: 'COMMENTS_PER_PR', query: 'comments', repoFilter: true },

  { type: 'MEETING_TIME', query: 'meeting' },
  { type: 'MEETING_COUNT', query: 'meeting' },

  { type: 'TASK_LEAD_TIME', query: 'leadtime' },
  { type: 'BUG_LEAD_TIME', query: 'leadtime' },

  { type: 'BURN_OUT', query: 'wellbeing', repoFilter: false },
  { type: 'NON_WORKING_DAY', query: 'wellbeing', repoFilter: false },
  { type: 'OUT_OF_HOUR', query: 'wellbeing', repoFilter: false },

  { type: 'QUALITY_BUGS', query: 'quality', repoFilter: false },
  { type: 'QUALITY_CODE_SMELLS', query: 'quality', repoFilter: false },
  { type: 'QUALITY_VULNERABILITIES', query: 'quality', repoFilter: false },
  {
    type: 'QUALITY_SECURITY_HOTSPOTS',
    query: 'quality',
    repoFilter: false,
  },
  { type: 'QUALITY_COVERAGE', query: 'quality', repoFilter: false },
  { type: 'QUALITY_DUPLICATED_LINES', query: 'quality', repoFilter: false },

  {
    type: 'DEPENDENCY_VULNERABILITIES',
    query: 'dependencyVulnerabilities',
    repoFilter: false,
  },

  { type: 'ISSUE_CYCLE_TIME_VS_COUNT', query: 'issueCycleTime' },

  { type: 'ISSUE_THROUGHPUT', query: 'issueThroughput' },

  { type: 'BUG_THROUGHPUT', query: 'issueThroughput' },

  { type: 'ISSUE_THROUGHPUT', query: 'issueThroughput' },
  { type: 'ISSUE_CYCLE_TIME_DIST', query: 'cycleTimeDist' },

  { type: 'CHANGE_AGING_DISTRIBUTION', query: 'isseusAgeing' },

  { type: 'BACKWARD_MOMENTUM', query: 'backwardMomentum' },

  { type: 'REQUIREMENT_STABILITY', query: 'requirementStability' },

  { type: 'TOP_REVIEWER_DISTRIBUTION', query: 'reviewerDistribution' },

  { type: 'PR_AGEING_DISTRIBUTION', query: 'prAgeingDist' },

  { type: 'ACTIVE_DAYS', query: 'activeDays' },

  { type: 'PR_HEALTH_INDEXES', query: 'prHealthIndex' },

  { type: 'LOC_VS_PR_CYCLE_TIME', query: 'locVsPrCycleTime' },

  { type: 'REVIEW_RESPONSIVENESS_INDEX', query: 'reviewResponsiveIndex' },

  {
    type: 'PR_CYCLE_TIME_BREAKDOWN',
    query: 'prCycleTimeBreakdown',
    repoFilter: true,
  },

  {
    type: 'CHANGE_HEALTH',
    query: 'changeHealth',
  },
  {
    type: 'LEAD_TIME_BREAKDOWN',
    query: 'leadTimeBreakdown',
  },
  {
    type: 'DEPLOYMENT_FREQUENCY_DISTRIBUTION',
    query: 'deploymentFrequencyDist',
  },
  {
    type: 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION',
    query: 'meanTimeToRecoveryDist',
  },

  {
    type: 'ISSUE_PROGRESSION_RATE',
    query: 'issueDistTimeline',
  },

  { type: 'WORK_BREAKDOWN', query: 'workBreakdown' },

  { type: 'ISSUE_LIFECYCLE_DIST', query: 'issueLifeCycleDist' },
  {
    type: 'PIPELINE_RUN_COUNT',
    query: 'pipeline',
  },
  {
    type: 'PIPELINE_RUN_TIME',
    query: 'pipeline',
  },
  {
    type: 'SPRINT_SCOPE_CHANGE',
    query: 'sprint',
  },
];

type DynamicQueryKey = string;

enum MetricsType {
  Throughput = 'throughput',
  CycleTime = 'cycleTime',
  Dora = 'dora',
  ChangeFailure = 'changefailure',
  Comments = 'comments',
  Meeting = 'meeting',
  Leadtime = 'leadtime',
  Wellbeing = 'wellbeing',
  Quality = 'quality',
  DependencyVulnerabilities = 'dependencyVulnerabilities',
  IssueCycleTime = 'issueCycleTime',
  IssueThroughput = 'issueThroughput',
  CycleTimeDist = 'cycleTimeDist',
  IssuesAgeing = 'isseusAgeing',
  RequirementStability = 'requirementStability',
  BackwardMomentum = 'backwardMomentum',
  ReviewerDistribution = 'reviewerDistribution',
  PrAgeingDist = 'prAgeingDist',
  ActiveDays = 'activeDays',
  ReviewCompletionRate = 'reviewCompletionRate',
  PrHealthIndex = 'prHealthIndex',
  LocVsPrCycleTime = 'locVsPrCycleTime',
  ReviewResponsiveIndex = 'reviewResponsiveIndex',
  PrCycleTimeBreakdown = 'prCycleTimeBreakdown',
  ChangeHealth = 'changeHealth',
  LeadTimeBreakdown = 'leadTimeBreakdown',
  DeploymentFrequencyDist = 'deploymentFrequencyDist',
  MeanTimeToRecoveryDist = 'meanTimeToRecoveryDist',
  IssueDistTimeline = 'issueDistTimeline',
  WorkBreakdown = 'workBreakdown',
  IssueLifeCycleDist = 'issueLifeCycleDist',
  Pipeline = 'pipeline',
  Sprint = 'sprint',
}

const QUERY_KEYS = {
  sizeBreakdown: 'sizeBreakdown',
};

export type QueryFilter = {
  view?: string;
};

export const useChartsGql = (
  startDate: string,
  endDate: string,
  metricType: string,
  breakdown: string,
  selectedTeam: string,
  sprintId: string,
  ticketType: string,
  granularity: string,
  filter?: QueryFilter | null
) => {
  const query = allMetricsList
    .filter((item: any) => item.type === metricType)
    .map((el: any) => el.query)[0];

  let queryFn = () =>
    throughPutMetricsGenericGraphql(
      startDate,
      endDate,
      metricType,
      breakdown,
      selectedTeam,
      sprintId,
      [],
      granularity
    );

  switch (query) {
    case MetricsType.Throughput:
      queryFn = () =>
        throughPutMetricsGenericGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.CycleTime:
      queryFn = () =>
        cycleTimeMetricsGenericGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.Dora:
      queryFn = () =>
        doraGenericGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.ChangeFailure:
      queryFn = () =>
        changeFailureGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.Meeting:
      queryFn = () =>
        meetingMetricsGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.Leadtime:
      queryFn = () =>
        LeadTimeMetricsGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.Wellbeing:
      queryFn = () =>
        wellbeingGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.Quality:
      queryFn = () =>
        qualityGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.DependencyVulnerabilities:
      queryFn = () =>
        dependencyVulnerabilitiesGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;
    case MetricsType.Comments:
      queryFn = () =>
        commentPerPr(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.Pipeline:
      queryFn = () =>
        pipelineMetricsByTypeGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          ticketType,
          granularity
        );
      break;

    case MetricsType.BackwardMomentum:
      queryFn = () =>
        backwardMomentumGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.RequirementStability:
      queryFn = () =>
        requirementStabilityGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.IssuesAgeing:
      queryFn = () =>
        issuesAgeingGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          []
        );
      break;

    case MetricsType.CycleTimeDist:
      queryFn = () =>
        cycleTimeDistGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          []
        );
      break;

    case MetricsType.IssueThroughput:
      queryFn = () =>
        valueStreamGraphql(
          metricType,
          startDate,
          endDate,
          ticketType || 'bug',
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.IssueCycleTime:
      queryFn = () =>
        issueCycleTimeGraphql(
          startDate,
          endDate,
          ticketType || 'all',
          selectedTeam,
          sprintId,
          [],
          granularity,
          filter
        );
      break;

    case MetricsType.ReviewerDistribution:
      queryFn = () =>
        reviewerDistributionGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          []
        );
      break;

    case MetricsType.PrAgeingDist:
      queryFn = () =>
        prAgeingDistGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          []
        );
      break;

    case MetricsType.ActiveDays:
      queryFn = () =>
        averageActiveDaysGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.PrHealthIndex:
      queryFn = () =>
        prHealthIndexGraphql(
          startDate,
          endDate,
          'PR_HEALTH_INDEXES',
          selectedTeam,
          sprintId,
          [],
          granularity,
          filter
        );
      break;

    case MetricsType.LocVsPrCycleTime:
      queryFn = () =>
        locVsPrCycleTimeGraphql(
          startDate,
          endDate,
          'LOC_VS_PR_CYCLE_TIME',
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.ReviewResponsiveIndex:
      queryFn = () =>
        mergeHealthIndexGraphql(
          startDate,
          endDate,
          'REVIEW_RESPONSIVENESS_INDEX',
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.PrCycleTimeBreakdown:
      queryFn = () =>
        prCycleTimeBreakdownGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          [],
          granularity
        );
      break;

    case MetricsType.ChangeHealth:
      queryFn = () =>
        changeHealthGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.LeadTimeBreakdown:
      queryFn = () =>
        leadTimeBreakDownGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.DeploymentFrequencyDist:
      queryFn = () =>
        deploymentFrequencyDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.MeanTimeToRecoveryDist:
      queryFn = () =>
        meanTimeToRecoveryDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.IssueDistTimeline:
      queryFn = () =>
        issueTimelineDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          ticketType,
          []
        );
      break;

    case MetricsType.WorkBreakdown:
      queryFn = () =>
        workBreakdownGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          ticketType,
          [],
          granularity
        );
      break;

    case MetricsType.IssueLifeCycleDist:
      queryFn = () =>
        issueLifeCycleDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          ticketType,
          []
        );
      break;

    case MetricsType.Sprint:
      queryFn = () =>
        sprintGraphql(metricType, selectedTeam, startDate, endDate, sprintId);
      break;

    default:
      queryFn = () =>
        customMetricGraphql(
          startDate,
          endDate,
          metricType,
          selectedTeam,
          sprintId,
          granularity
        );
      break;
  }

  return useQuery(
    [
      metricType as DynamicQueryKey,
      startDate,
      endDate,
      metricType,
      breakdown,
      selectedTeam,
      ticketType,
      sprintId,
      granularity,
    ],
    queryFn,
    {
      select: (data) => getPayload(query, data),
      enabled: !!metricType && !!startDate && !!endDate,
    }
  );
};

export const useDetailChartsGql = (
  startDate: string,
  endDate: string,
  metricType: string,
  breakdown: string,
  selectedTeam: string,
  sprintId: string,
  ticketType: string,
  repos: string[],
  granularity: string,
  filter: QueryFilter | null
) => {
  const query = allMetricsList
    .filter((item: any) => item.type === metricType)
    .map((el: any) => el.query)[0];

  let queryFn = () =>
    throughPutMetricsGenericGraphql(
      startDate,
      endDate,
      metricType,
      breakdown,
      selectedTeam,
      sprintId,
      repos,
      granularity
    );

  switch (query) {
    case MetricsType.Throughput:
      queryFn = () =>
        throughPutMetricsGenericGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.CycleTime:
      queryFn = () =>
        cycleTimeMetricsGenericGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.Dora:
      queryFn = () =>
        doraGenericGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.ChangeFailure:
      queryFn = () =>
        changeFailureGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.Meeting:
      queryFn = () =>
        meetingMetricsGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.Leadtime:
      queryFn = () =>
        LeadTimeMetricsGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.Wellbeing:
      queryFn = () =>
        wellbeingGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.Quality:
      queryFn = () =>
        qualityGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.DependencyVulnerabilities:
      queryFn = () =>
        dependencyVulnerabilitiesGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;
    case MetricsType.Comments:
      queryFn = () =>
        commentPerPr(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.IssueThroughput:
      queryFn = () =>
        valueStreamGraphql(
          metricType,
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.CycleTimeDist:
      queryFn = () =>
        cycleTimeDistGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos
        );
      break;

    case MetricsType.IssueCycleTime:
      queryFn = () =>
        issueCycleTimeGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos,
          granularity,
          filter
        );
      break;
    case MetricsType.IssuesAgeing:
      queryFn = () =>
        issuesAgeingGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos
        );
      break;

    case MetricsType.RequirementStability:
      queryFn = () =>
        requirementStabilityGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.ReviewerDistribution:
      queryFn = () =>
        reviewerDistributionGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos
        );
      break;

    case MetricsType.PrAgeingDist:
      queryFn = () =>
        prAgeingDistGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos
        );
      break;

    case MetricsType.BackwardMomentum:
      queryFn = () =>
        backwardMomentumGraphql(
          startDate,
          endDate,
          ticketType,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.ActiveDays:
      queryFn = () =>
        averageActiveDaysGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.PrHealthIndex:
      queryFn = () =>
        prHealthIndexGraphql(
          startDate,
          endDate,
          'PR_HEALTH_INDEXES',
          selectedTeam,
          sprintId,
          repos,
          granularity,
          filter
        );
      break;

    case MetricsType.LocVsPrCycleTime:
      queryFn = () =>
        locVsPrCycleTimeGraphql(
          startDate,
          endDate,
          'LOC_VS_PR_CYCLE_TIME',
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.ReviewResponsiveIndex:
      queryFn = () =>
        mergeHealthIndexGraphql(
          startDate,
          endDate,
          'REVIEW_RESPONSIVENESS_INDEX',
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.PrCycleTimeBreakdown:
      queryFn = () =>
        prCycleTimeBreakdownGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          repos,
          granularity
        );
      break;

    case MetricsType.ChangeHealth:
      queryFn = () =>
        changeHealthGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.DeploymentFrequencyDist:
      queryFn = () =>
        deploymentFrequencyDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.LeadTimeBreakdown:
      queryFn = () =>
        leadTimeBreakDownGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.MeanTimeToRecoveryDist:
      queryFn = () =>
        meanTimeToRecoveryDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          granularity
        );
      break;

    case MetricsType.IssueDistTimeline:
      queryFn = () =>
        issueTimelineDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          ticketType,
          repos
        );
      break;

    case MetricsType.WorkBreakdown:
      queryFn = () =>
        workBreakdownGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          ticketType,
          repos,
          granularity
        );
      break;

    case MetricsType.IssueLifeCycleDist:
      queryFn = () =>
        issueLifeCycleDistributionGraphql(
          startDate,
          endDate,
          selectedTeam,
          sprintId,
          ticketType,
          repos
        );
      break;

    case MetricsType.Pipeline:
      queryFn = () =>
        pipelineMetricsByTypeGraphql(
          startDate,
          endDate,
          metricType,
          breakdown,
          selectedTeam,
          sprintId,
          ticketType,
          granularity
        );
      break;
    case MetricsType.Sprint:
      queryFn = () =>
        sprintGraphql(metricType, selectedTeam, startDate, endDate, sprintId);
      break;

    default:
      queryFn = () =>
        customMetricGraphql(
          startDate,
          endDate,
          metricType,
          selectedTeam,
          sprintId,
          granularity
        );
      break;
  }

  return useQuery(
    [
      metricType as DynamicQueryKey,
      startDate,
      endDate,
      metricType,
      breakdown,
      selectedTeam,
      ticketType,
      repos,
      sprintId,
      granularity,
      filter,
    ],
    queryFn,
    {
      select: (data) => getPayload(query, data),
      enabled: !!metricType && !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

const getPayload = (query: string, data: any) => {
  switch (query) {
    case MetricsType.Wellbeing:
      return {
        ...data.WellBeingDataByType,
        data: JSON.parse(data.WellBeingDataByType.data),
      };
    case MetricsType.Throughput:
      return {
        ...data.ThroughPutMetricsByType,
        data: JSON.parse(data.ThroughPutMetricsByType.data),
      };
    case MetricsType.CycleTime:
      return {
        ...data.CycleTimeMetricsByType,
        data: JSON.parse(data.CycleTimeMetricsByType.data),
      };
    case MetricsType.Dora:
      return {
        ...data.DeploymentMetricsByType,
        data: JSON.parse(data.DeploymentMetricsByType.data),
      };
    case MetricsType.ChangeFailure:
      return {
        ...data.ChangeFailureQuery,
        data: JSON.parse(data.ChangeFailureQuery.data),
      };
    case MetricsType.Meeting:
      return {
        ...data.MeetingMetricsByType,
        data: JSON.parse(data.MeetingMetricsByType.data),
      };
    case MetricsType.Leadtime:
      return {
        ...data.LeadTimeMetricsByType,
        data: JSON.parse(data.LeadTimeMetricsByType.data),
      };
    case MetricsType.Comments:
      return {
        ...data.AverageCommentsForPr,
        data: JSON.parse(data.AverageCommentsForPr.data),
      };
    case MetricsType.Quality:
      return {
        ...data.QualityMetricsOnOverallAndNewCode,
        data: JSON.parse(data.QualityMetricsOnOverallAndNewCode.data),
      };
    case MetricsType.DependencyVulnerabilities:
      return {
        ...data.VulnerabilityAlerts,
        data: JSON.parse(data.VulnerabilityAlerts.data),
      };
    case MetricsType.IssueCycleTime:
      return {
        ...data.IssueCycleTimeVsmQuery,
        data: JSON.parse(data.IssueCycleTimeVsmQuery.data),
      };

    case MetricsType.CycleTimeDist:
      return {
        ...data.CycleTimeDistVsmQuery,
        data: JSON.parse(data.CycleTimeDistVsmQuery.data),
      };

    case MetricsType.IssueThroughput:
      return {
        ...data.ThroughputValueStreamByType,
        data: JSON.parse(data.ThroughputValueStreamByType.data),
      };

    case MetricsType.IssuesAgeing:
      return {
        ...data.IssuesAgeingReport,
        data: JSON.parse(data.IssuesAgeingReport.data),
      };

    case MetricsType.RequirementStability:
      return {
        ...data.RequirementStabilityVsmQuery,
        data: JSON.parse(data.RequirementStabilityVsmQuery.data),
      };

    case MetricsType.BackwardMomentum:
      return {
        ...data.BackwardMomentum,
        data: JSON.parse(data.BackwardMomentum.data),
      };

    case MetricsType.ReviewerDistribution:
      return {
        ...data.ReviewerDistribution,
        data: JSON.parse(data.ReviewerDistribution.data),
      };

    case MetricsType.PrAgeingDist:
      return {
        ...data.PrAgeingReport,
        data: JSON.parse(data.PrAgeingReport.data),
      };

    case MetricsType.ActiveDays:
      return {
        ...data.AverageActiveDays,
        data: JSON.parse(data.AverageActiveDays.data),
      };

    case MetricsType.PrHealthIndex:
      return {
        ...data.PrHealthIndexes,
        data: JSON.parse(data.PrHealthIndexes.data),
      };

    case MetricsType.LocVsPrCycleTime:
      return {
        ...data.LocVsPrCycleTime,
        data: JSON.parse(data.LocVsPrCycleTime.data),
      };

    case MetricsType.ReviewResponsiveIndex:
      return {
        ...data.ReviewResponsivenessIndex,
        data: JSON.parse(data.ReviewResponsivenessIndex.data),
      };

    case MetricsType.PrCycleTimeBreakdown:
      return {
        ...data.PrCycleTimeBreakdown,
        data: JSON.parse(data.PrCycleTimeBreakdown.data),
      };

    case MetricsType.ChangeHealth:
      return {
        ...data.ChangeHealth,
        data: JSON.parse(data.ChangeHealth.data),
      };

    case MetricsType.DeploymentFrequencyDist:
      return {
        ...data.DeploymentFrequencyDistribution,
        data: JSON.parse(data.DeploymentFrequencyDistribution.data),
      };

    case MetricsType.LeadTimeBreakdown:
      return {
        ...data.LeadTimeBreakDown,
        data: JSON.parse(data.LeadTimeBreakDown.data),
      };

    case MetricsType.MeanTimeToRecoveryDist:
      return {
        ...data.MeanTimeToRecoveryDistribution,
        data: JSON.parse(data.MeanTimeToRecoveryDistribution.data),
      };

    case MetricsType.IssueDistTimeline:
      return {
        ...data.IssueTimelineDistribution,
        data: JSON.parse(data.IssueTimelineDistribution.data),
      };

    case MetricsType.WorkBreakdown:
      return {
        ...data.WorkBreakdown,
        data: JSON.parse(data.WorkBreakdown.data),
      };

    case MetricsType.IssueLifeCycleDist:
      return {
        ...data.IssueLifeCycleDistribution,
        data: JSON.parse(data.IssueLifeCycleDistribution.data),
      };

    case MetricsType.Pipeline:
      return {
        ...data.PipelineMetricsByType,
        data: JSON.parse(data.PipelineMetricsByType.data),
      };

    case MetricsType.Sprint:
      return {
        ...data.SprintMetricsByType,
        data: JSON.parse(data.SprintMetricsByType.data),
      };

    default:
      return {
        ...data.CustomMetrics,
        data: JSON.parse(data.CustomMetrics.data),
      };
  }
};

export const useSizeBreakdownGql = (
  startDate: string,
  endDate: string,
  metric: string,
  teamId: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.sizeBreakdown, metric, startDate, endDate, teamId, sprintId],
    () => sizeBreakdown(startDate, endDate, metric, teamId, sprintId),
    {
      select: (data) => data.MetricsBreakdown,
      enabled: !!metric && !!startDate && !!endDate,
    }
  );
};
