import { Box, Flex, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { MetricsCard, PeriodType } from '@devd-client/devd/components';
import { FC } from 'react';
import { ReportMetrics } from '../../../../../helpers/report.types';
import { useReportsStore } from '../../../../../store/index';

interface ShowMetricsProps {
  showMetric: ReportMetrics[];
  selected: PeriodType;
  selectedTeam: string;
}

const ShowMetrics: FC<ShowMetricsProps> = ({
  showMetric,
  selected,
  selectedTeam,
}) => {
  const { zeroStates } = useReportsStore();

  return (
    <Flex w="full">
      {showMetric
        .filter((item: ReportMetrics) => item.metric !== 'INVESTMENT_DIST')
        .map(
          (elem: ReportMetrics, idx: number, metricsArr: ReportMetrics[]) => (
            <Box
              key={`metric-${idx}`}
              width={`${100 / metricsArr.length}%`}
              pr={metricsArr.length - 1 !== idx ? '6' : undefined}
            >
              <Stack spacing={1}>
                {elem.zeroState ? (
                  zeroStates
                    .filter(
                      (el: { type: string; url: string }) =>
                        el.type === elem.zeroState
                    )
                    .map((item: { type: string; url: string }) => (
                      <Image
                        loading="lazy"
                        boxSize="100%"
                        objectFit="contain"
                        src={item.url}
                        alt="Metric images"
                      />
                    ))
                ) : (
                  <MetricsCard
                    metricType={elem.metric}
                    selected={selected}
                    selectedTeam={selectedTeam}
                    status={elem.status}
                  />
                )}
              </Stack>
            </Box>
          )
        )}
    </Flex>
  );
};

export default ShowMetrics;
