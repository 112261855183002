import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  useTheme,
  Heading,
  VStack,
  Image,
} from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import { useToastHook } from '../../appToast';
import { useNavigate } from 'react-router';
import { NoDataFoundIcon } from '../../icons';
import NoTeam from '../assets/noTeam.png';

const NoData = ({
  addMemberModal,
  team,
  page,
  appState,
  teamLength,
  plan,
}: {
  addMemberModal?: any;
  team: string | undefined;
  page?: string;
  appState?: any;
  teamLength?: number;
  plan?: string;
}) => {
  const [newToast] = useToastHook();
  const navigate = useNavigate();
  const { colors } = useTheme();
  if (page === 'Members' || page === 'Managers') {
    return (
      <Flex
        marginInline={'auto'}
        mt={4}
        w={'fit-content'}
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text
          fontFamily={'heading'}
          fontWeight={'medium'}
          color={'text.secondary'}
        >
          No {page} found!
        </Text>
        <Button
          mt={1}
          variant={'filled'}
          size={'xs'}
          py={4}
          px={3}
          colorScheme="twitter"
          borderRadius={4}
          onClick={() => addMemberModal.onOpen()}
        >
          Add {page}
        </Button>
      </Flex>
    );
  }
  if (page === 'team') {
    return (
      <Flex
        marginInline={'auto'}
        mt={16}
        w={'fit-content'}
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Image src={NoTeam}></Image>
        <Heading as="h2" fontWeight={'semibold'}>
          Uh no! No teams found
        </Heading>
        <Button
          fontSize={'md'}
          mt={6}
          variant={'filled'}
          colorScheme="twitter"
          borderRadius={4}
          onClick={() => {
            if (
              appState?.user?.dto?.teamAllowed &&
              (plan === 'Free' || plan === 'Trial') &&
              teamLength! >= appState?.user?.dto?.teamAllowed
            ) {
              newToast({
                message:
                  'Upgrade your plan to add more teams, or contact Devdynamics support',
                status: 'error',
              });
            } else {
              window.analytics.track('team_created', {
                status: 'init',
              });
              navigate('/settings/teams/add-team', {
                state: { newTeam: true },
              });
            }
          }}
        >
          Create Team
        </Button>
      </Flex>
    );
  }
  return (
    <Flex w="100%" py={10} justify="center" direction={'column'}>
      <Flex justify="center">
        <Box>
          <NoDataFoundIcon
            height={'24px'}
            width={'24px'}
            color={colors.text.secondary}
          />
        </Box>

        <Text
          ml={2}
          fontFamily="heading"
          textAlign="center"
          fontWeight="medium"
          color="text.secondary"
        >
          No result found.
        </Text>
      </Flex>

      {team && (
        <Button
          maxW={'fit-content'}
          size="sm"
          variant="primary"
          marginInline={'auto'}
          mt={4}
          onClick={addMemberModal.onOpen}
        >
          +Add Members
        </Button>
      )}
    </Flex>
  );
};

export default NoData;
