export type FlowState = {
  data: any;
};

export type FlowAction = {
  type: string;
  payload?: any;
};

export enum FlowActionTypes {
  SET_CUMULATIVE_DATA = 'SET_CUMULATIVE_DATA',
  SET_SCATTER_DATA = 'SET_SCATTER_DATA',
}

export const flowReducer = (
  state: FlowState,
  action: FlowAction
): FlowState => {
  switch (action.type) {
    case FlowActionTypes.SET_CUMULATIVE_DATA:
      return {
        ...state,
        data: action?.payload,
      };
    case FlowActionTypes.SET_SCATTER_DATA:
      return {
        ...state,
        data: action?.payload,
      };

    default:
      return state;
  }
};
