import {
  Box,
  Divider,
  Tooltip as ChakraToolTip,
  Flex,
  Text,
  Icon,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation } from 'react-router';
import {
  ISSUE_CHART_KEYS,
  MetricSidebar,
  PeriodType,
} from '@devd-client/devd/components';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import moment from 'moment';
import { QUERY_KEYS } from '../../../graphql';

interface barChartProps {
  barChartStats: any;
  selected: PeriodType;
}

const Colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box bg="white" p={2} boxShadow="md" borderRadius="md">
        <Flex gap={0.5} alignItems={'center'}>
          <Box
            w={'14px'}
            h={'14px'}
            rounded={'full'}
            bg={payload[0]?.payload.fill}
          />
          <Text>
            {label} : {payload[0]?.value}
          </Text>
        </Flex>
      </Box>
    );
  }
  return null;
};

export const IncidentBarChart: React.FC<barChartProps> = ({
  barChartStats,
  selected,
}) => {
  const { colors } = useTheme();
  const teammodal = useDisclosure();

  const { pathname } = useLocation();

  const [metricsName, setMetricsName] = useState<string>('');
  const [metricType, setMetricType] = useState<string>('');
  const [heading, setHeading] = useState<string>('');
  const [count, setCount] = useState<string>('');

  const sortedbarChartStats = [...barChartStats];
  sortedbarChartStats.sort((a, b) => {
    return parseInt(b.currentData) - parseInt(a.currentData);
  });

  const data02 = sortedbarChartStats
    ?.filter((item: any) => parseInt(item.currentData) > 0)
    .map((item: any, index: number) => ({
      name: item?.chartMetadata?.chartTitle,
      value: parseInt(item?.currentData),
      fill: Colors[index % Colors.length],
    }));

  return (
    <Box
      w={'100%'}
      borderWidth={1}
      borderColor="gray.300"
      borderRadius="12px"
      py={'20px'}
      bg="#fff"
      minHeight="248px"
      border="1px solid #E3E6EA"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
      transition="all 0.2s"
      _hover={{
        boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
      }}
    >
      <Text
        fontWeight="semibold"
        fontFamily="heading"
        color="text.primary"
        lineHeight="24px"
        fontSize={'md'}
        ml={30}
        mb={4}
      >
        Issue Activity
      </Text>

      <Flex direction="row" justify={'space-between'} align="stretch" px={4}>
        <Box maxH="200px" w={'25%'} overflowY="auto" pl={2}>
          <Flex direction="column" gap={0.5} justifyContent={'space-around'}>
            {sortedbarChartStats?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="inter"
                    fontWeight="semibold"
                    color="gray.500"
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label="View details"
                        aria-label="View details"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setMetricType(data?.chartMetadata?.chartTitle);
                            setHeading(data?.chartMetadata?.chartTitle);
                            setCount(data?.currentData);
                            teammodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                ${
                                  data?.previousData?.improved > 0
                                    ? 'improved'
                                    : 'deteriorated'
                                } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
        </Box>

        {[...ISSUE_CHART_KEYS].includes(metricsName) && teammodal?.isOpen && (
          <MetricSidebar
            querykey={QUERY_KEYS.teamStats}
            openDrawer={teammodal?.isOpen}
            handleDrawerClose={teammodal?.onClose}
            heading={heading}
            subHeading={`${moment(selected.startDate).format(
              'DD MMM'
            )} - ${moment(selected.endDate).format(
              'DD MMM'
            )}, ${heading} (${count})
           `}
            payload={{
              payload: {
                startDate: selected.startDate,
                endDate: selected.endDate,
              },
            }}
            currentChartMeta={{ chartKey: metricsName }}
            filterBy={{
              teamId: decodeURIComponent(pathname?.split('/')[2]),
              type: metricType,
              sprintId: selected.sprintId,
            }}
            selectedTeam={''}
          />
        )}

        <Box w={'80%'} maxH="200px" overflowY="auto">
          <ResponsiveContainer width="100%" height={data02.length * 35 + 40}>
            <BarChart
              layout="vertical"
              data={data02}
              margin={{ top: 0, right: 5, left: 5, bottom: 5 }}
            >
              <XAxis
                type="number"
                tick={{ fill: colors.gray[600], fontSize: 12 }}
              />
              <YAxis
                dataKey="name"
                type="category"
                axisLine={false}
                tickLine={false}
                tick={{ fill: colors.gray[600], fontSize: 12 }}
                width={100}
              />
              <Tooltip cursor={{ fill: 'none' }} content={<CustomTooltip />} />
              <Bar dataKey="value" radius={[0, 4, 4, 0]} barSize={25} />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Flex>
    </Box>
  );
};

export default IncidentBarChart;
