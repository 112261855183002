import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  metricInfo: 'metricInfo',
  aiGeneratedDataForInfoTooltip: 'aiGeneratedDataForInfoTooltip',
};

export const useGetMetricInfo = (chartKey: string) => {
  return useQuery([QUERY_KEYS.metricInfo, chartKey], () =>
    ENDPOINTS.getMetricInfo(chartKey)
  );
};

export const useGetAIGeneratedDataForInfoTooltip = (
  metricCount: string,
  metricName: string,
  metricTrend: string,
  metricValueDesc: string,
  metricGoal: string
) => {
  return useQuery([QUERY_KEYS.aiGeneratedDataForInfoTooltip], () =>
    ENDPOINTS.getAIGeneratedDataForInfoTooltip(
      metricCount,
      metricName,
      metricTrend,
      metricValueDesc,
      metricGoal
    )
  );
};
