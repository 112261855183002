import {
  Button,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { apiClient } from '@devd-client/api';

import { useState } from 'react';
import { useToastHook } from '../appToast/index';
import { Loader } from '../appLoader/index';
import { CrossIcon, TickIcon } from '../icons';
import DemoCalendarModal from '../DemoCalendarModal';

// interface SubsPlanCardProps {
//   currentPlan: boolean;
//   planName: string;
//   planDesc: string;
//   showUpgradeNow: boolean;
// }

export const SubsPlanCard = ({ item, currentPlan, handleTalkToSales }: any) => {
  const [newToast] = useToastHook();
  const [loading, setLoading] = useState(false);

  const handleStripeCheckout = () => {
    setLoading(true);
    apiClient(`/v1/billing/session`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        orgId: localStorage.getItem('orgId'),
        planKey: item?.key,
      }),
    })
      .then((res: any) => {
        window.location.assign(res.dto);
        setLoading(true);
      })
      .catch((err: any) => {
        newToast({ status: 'error', message: err.message });
        setLoading(false);
      });
  };

  return (
    <Stack
      borderRadius="4px"
      border="0.5px solid rgba(0, 0, 0, 0.1)"
      padding={3}
      spacing={4}
      minH="200px"
    >
      <Stack>
        <Text
          as="h3"
          fontSize="sm"
          fontWeight="bold"
          fontFamily="heading"
          lineHeight="20px"
          color="text.primary"
        >
          {item?.title}
        </Text>
        {/* <Text
          as="p"
          fontSize="xs"
          fontWeight="normal"
          lineHeight="17px"
          fontFamily="heading"
          color="text.secondary"
        >
          {item?.description}
        </Text> */}

        <Flex>
          <Text
            as="span"
            fontWeight="bold"
            fontFamily="heading"
            lineHeight="22px"
            color="text.primary"
          >
            {`${item?.currency === 'USD' && '$'}${item?.price}`}
          </Text>

          <Text
            as="span"
            fontFamily="heading"
            fontWeight="normal"
            fontSize="xs"
            lineHeight="21px"
            color="text.secondary"
            ml={1}
          >{`/contributor/mo`}</Text>
        </Flex>
      </Stack>

      <>
        {item?.key === currentPlan ? (
          <Button
            size="sm"
            variant="tertiary-disabled"
            _hover={{ userSelect: 'none' }}
            isDisabled={true}
          >
            Current Plan
          </Button>
        ) : (
          <Button size="sm" variant="tertiary" onClick={handleStripeCheckout}>
            {`Get ${item?.title}`}
          </Button>
        )}

        <Button onClick={handleTalkToSales} size="sm" variant="secondary">
          Talk to Sales
        </Button>
      </>

      <Stack>
        <Divider />
      </Stack>
      <Stack>
        <Text
          fontSize="xs"
          fontFamily="heading"
          lineHeight="17px"
          fontWeight="semibold"
          color="text.primary"
        >
          {item?.title === 'Business'
            ? `Everything in Pro plan, plus`
            : "What's included"}
        </Text>

        {item?.features?.map((el: any, idx: number) => (
          <HStack key={`feature-${idx}`} spacing={1}>
            {el.enabled ? <TickIcon /> : <CrossIcon />}

            <Text
              fontSize="xs"
              fontFamily="heading"
              fontWeight="normal"
              lineHeight="17px"
              color="text.secondary"
            >
              {el.title}
            </Text>
          </HStack>
        ))}
      </Stack>
      {loading && <Loader />}
    </Stack>
  );
};

export default SubsPlanCard;
