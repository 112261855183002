import { Box, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';

interface CopilotLoadingComponentProps {
  title?: string;
  height?: boolean;
  extraCard?: boolean;
}

export const CopilotLoadingComponent = ({
  title,
  height,
  extraCard,
}: CopilotLoadingComponentProps) => {
  return (
    <Flex
      flexDirection={'column'}
      mt={4}
      p={4}
      bg="white"
      borderRadius="md"
      border="1px solid #E0E0E0"
      height={height ? '480px' : '425px'}
      width="100%"
    >
      <Text fontSize="lg" fontWeight="semibold" mb={4} color={'text.secondary'}>
        {title}
      </Text>
      <Flex height={'100%'}>
        <Flex
          direction={'column'}
          mr={8}
          w="250px"
          height={'100%'}
          justifyContent={'center'}
        >
          <Skeleton height="82px" width="100%" mb={4} />
          <Skeleton height="82px" width="100%" mb={4} />
          {extraCard && <Skeleton height="82px" width="100%" mb={4} />}
        </Flex>
        <Box w="80%" position="relative">
          <Spinner position="absolute" top="50%" left="50%" />
          <Skeleton height="100%" width="100%" />
        </Box>
      </Flex>
    </Flex>
  );
};
