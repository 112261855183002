import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FormLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
