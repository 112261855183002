import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import React from 'react';
import { useInitiativesPage } from '../apis';
import { InitiativesPage } from './devd-initiatives';

export const Initiatives = () => {
  const { data } = useInitiativesPage();
  return (
    <>
      <MetaTags title="Initiatives | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <InitiativesPage data={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Initiatives;
