import styled from 'styled-components';
import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import React, { useEffect } from 'react';
import { useGoalsPage, useGetWorkflows } from '../apis';
import { Button } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import GoalsPage from './devd-goals';

export function WorkflowAutomation() {
  return (
    <>
      <MetaTags title="Automation | Devdynamics" />
      <GoalsPage />
    </>
  );
}

export default WorkflowAutomation;
