export function ContributorsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.47266 8.13086C8.49093 8.13086 9.31641 7.30539 9.31641 6.28711C9.31641 5.26883 8.49093 4.44336 7.47266 4.44336C6.45438 4.44336 5.62891 5.26883 5.62891 6.28711C5.62891 7.30539 6.45438 8.13086 7.47266 8.13086Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.47194 13.2667C9.04958 13.2667 10.48 12.6363 11.5249 11.6136C10.5586 10.4511 9.10169 9.71094 7.4719 9.71094C5.84213 9.71094 4.38523 10.451 3.41895 11.6135C4.46387 12.6363 5.89427 13.2667 7.47194 13.2667Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5703 11.5693L14.3211 14.3201"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.47238 13.267C10.6727 13.267 13.267 10.6727 13.267 7.47238C13.267 4.27208 10.6727 1.67773 7.47238 1.67773C4.27208 1.67773 1.67773 4.27208 1.67773 7.47238C1.67773 10.6727 4.27208 13.267 7.47238 13.267Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ContributorsIcon;
