import { Flex, Spinner, Text } from '@chakra-ui/react';
import { StatusEnum } from './showStatusText.types';

export const ShowStatusText = ({
  status,
  message,
}: {
  status: string | null;
  message: string | null;
}) => {
  return status === StatusEnum.inProgress ? (
    <Flex align="center">
      <Spinner color="text.secondary" size="xs" mr={1} speed="1s" />
      <Text
        fontFamily="heading"
        fontSize="xxs"
        mt={1}
        fontWeight="normal"
        color="text.secondary"
      >
        {message}
      </Text>
    </Flex>
  ) : status === StatusEnum.dataPullFailed || status === StatusEnum.dataPullPartiallyFailed ? (
    <Text
      fontFamily="heading"
      fontSize="xxs"
      mt={1}
      fontWeight="normal"
      color="error"
    >
      {message}
    </Text>
  ) : status === StatusEnum.pending ? (
    <Text
      fontFamily="heading"
      fontSize="xxs"
      mt={1}
      fontWeight="normal"
      color="text.secondary"
    >
      {message}
    </Text>
  ) : (
    <></>
  );
};
