import { FC } from 'react';
import styled from 'styled-components';
import {
  IProjectTab,
  ProjectTabOptions,
} from '../../../../helpers/Initiatives.types';
import { projectTabList } from '../../../../helpers/Initiatives.utils';

type ProjectTabProps = {
  selected?: boolean;
};

const ProjectTabsContainer = styled.div`
  display: flex;
  /* margin-bottom: 8px; */
`;

const ProjectTab = styled.h2<ProjectTabProps>`
  font-family: 'Inter';
  user-select: none;
  font-size: 18px;
  font-weight: ${({ selected }) => (selected ? '600' : '400')};
  margin-right: 24px;
  cursor: pointer;
  border-bottom: ${({ selected }) =>
    selected ? '2.5px solid #0078D4' : '2.5px solid transparent'};
  transition: all 0.2s;
`;

interface ProjectTabsProps {
  selectedTab: ProjectTabOptions;
  setSelectedTab: (tab: ProjectTabOptions) => void;
}

const ProjectTabs: FC<ProjectTabsProps> = ({ selectedTab, setSelectedTab }) => {
  return (
    <ProjectTabsContainer>
      {projectTabList.map((tab: IProjectTab) => (
        <ProjectTab
          key={tab.id}
          selected={tab.id === selectedTab}
          onClick={() => setSelectedTab(tab.id as ProjectTabOptions)}
        >
          {tab.title}
        </ProjectTab>
      ))}
    </ProjectTabsContainer>
  );
};

export default ProjectTabs;
