import {
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Text,
  Radio,
  RadioGroup,
  Divider,
  Flex,
  useTheme,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  CustomSelect,
  eliminateHtmlTags,
  ListSelect,
  useToastHook,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import { AddCfrProps } from '../helpers/dora.types';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useFetchAllJiraProjects } from '../apis';

interface CfrFormProps {
  handleSaveClick: (params: AddCfrProps) => void;
  data: any;
  customFieldData: any;
}

const cfTeamTrackingIssueOptions = [
  {
    label: 'Issue Label',
    value: 'LABEL',
  },
  {
    label: 'Issue Project',
    value: 'PROJECT',
  },
  {
    label: 'Epic',
    value: 'EPIC',
  },
  {
    label: 'Custom Field',
    value: 'CUSTOM',
  },
];

const cfTeamTrackingIncidentOptions = [
  {
    label: 'Incident Label',
    value: 'LABEL',
  },
  {
    label: 'Incident Service',
    value: 'SERVICE',
  },
];

const cfTeamTrackingGitOptions = [
  {
    label: 'Git Repo',
    value: 'REPO',
  },
];

const CfrForm: FC<CfrFormProps> = ({
  handleSaveClick,
  data,
  customFieldData,
}) => {
  const { colors } = useTheme();
  const [newToast] = useToastHook();
  const [cfrType, setCfrType] = useState<string>(data?.dto?.type || '');

  const [selectedIssueType, setSelectedIssueType] = useState<string>(
    data?.dto?.issueDefinition?.issueType || ''
  );
  const [selectedLabels, setSelectedLabels] = useState<string>(
    data?.dto?.issueDefinition?.labels || ''
  );
  const [selectedPriorities, setSelectedPriorities] = useState<string>(
    data?.dto?.issueDefinition?.priorities || ''
  );
  const [selectedProjects, setSelectedProjects] = useState<string>(
    data?.dto?.issueDefinition?.projects?.trim() || ''
  );
  const [selectedSeverity, setSelectedSeverity] = useState<string>(
    data?.dto?.issueDefinition?.severity || ''
  );

  const [selectedStatus, setSelectedStatus] = useState<string>(
    data?.dto?.issueDefinition?.status || ''
  );

  const [selectedCustomFieldName, setSelectedCustomFieldName] =
    useState<string>(data?.dto?.issueDefinition?.customField?.name || '');
  const [selectedCustomFieldValue, setSelectedCustomFieldValue] =
    useState<string>(data?.dto?.issueDefinition?.customField?.value || '');

  const [teamTrackingTypeIssue, setTeamTrackingTypeIssue] = useState(
    (data?.dto?.type === 'ISSUE_UNIT' && data?.dto?.teamTrackingType) || ''
  );

  const [teamTrackingTypeIncident, setTeamTrackingTypeIncident] = useState(
    (data?.dto?.type === 'INCIDENT_UNIT' && data?.dto?.teamTrackingType) || ''
  );

  const [teamTrackingTypeGit, setTeamTrackingTypeGit] = useState(
    (data?.dto?.type === 'GIT_PR_UNIT' && data?.dto?.teamTrackingType) || ''
  );

  const [teamTrackingField, setTeamTrackingField] = useState('');

  const [gitLabel, setGitLabel] = useState('');

  useEffect(() => {
    setTeamTrackingField(data?.dto?.teamTrackingField);
    setGitLabel(data?.dto?.label);
  }, [data?.dto?.teamTrackingField, data?.dto?.label]);

  const onSubmit = () => {
    if (
      (cfrType === 'ISSUE_UNIT' && !teamTrackingTypeIssue) ||
      (cfrType === 'INCIDENT_UNIT' && !teamTrackingTypeIncident) ||
      (cfrType === 'GIT_PR_UNIT' && !teamTrackingTypeGit) ||
      !cfrType
    ) {
      return newToast({
        message: 'Please select a Team Tracking option',
        status: 'error',
      });
    }

    if (cfrType === 'GIT_PR_UNIT' && !gitLabel) {
      return newToast({
        message: 'Please enter a specific word.',
        status: 'error',
      });
    }

    if (teamTrackingTypeIssue === 'CUSTOM' && !teamTrackingField) {
      return newToast({
        message: 'Please enter a custom field name',
        status: 'error',
      });
    }

    let issueDefinition = null;

    if (cfrType === 'ISSUE_UNIT') {
      issueDefinition = {
        issueType: selectedIssueType,
        labels: selectedLabels,
        priorities: selectedPriorities,
        projects: selectedProjects,
        severity: selectedSeverity,
        status: selectedStatus,
        customField: {
          name: selectedCustomFieldName ?? '',
          value: selectedCustomFieldValue,
        },
      };
    }
    handleSaveClick({
      type: cfrType,
      teamTrackingType:
        cfrType === 'ISSUE_UNIT'
          ? teamTrackingTypeIssue
          : cfrType === 'INCIDENT_UNIT'
          ? teamTrackingTypeIncident
          : teamTrackingTypeGit,
      issueDefinition: issueDefinition,
      teamTrackingField:
        customFieldData?.dto?.find(
          (option: any) => option.value === teamTrackingField
        )?.value || '',
      label: gitLabel,
    });
  };

  const formatOptionLabel = ({ value, label }: any) => {
    if (value === '') {
      return <span>&nbsp;</span>;
    }
    return label;
  };

  useEffect(() => {
    setSelectedCustomFieldName(data?.dto?.issueDefinition?.customField?.name);
  }, [data?.dto]);

  const listSelectProjectModal = useDisclosure();

  const { data: issueProjects } = useFetchAllJiraProjects();
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  return (
    <Stack spacing={4}>
      <Text
        fontFamily="heading"
        fontSize="md"
        fontWeight="semibold"
        color="text.primary"
      >
        Change Failure Detection
      </Text>
      <RadioGroup
        borderRadius="4px"
        bg="gray.50"
        p={3}
        mb={6}
        value={cfrType}
        onChange={(value) => setCfrType(value)}
      >
        <Stack fontFamily="heading">
          <Radio value="ISSUE_UNIT">
            Detect change failure from issue management
          </Radio>

          {cfrType === 'ISSUE_UNIT' && (
            <>
              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                  >
                    Project Name:
                  </Text>
                </Box>
                <Box>
                  <Button
                    variant="primary"
                    size="sm"
                    w={'300px'}
                    onClick={listSelectProjectModal.onOpen}
                  >
                    Select Issue Project (
                    {selectedProjects
                      ? selectedProjects?.split(',')?.length
                      : 0}
                    )
                  </Button>

                  {listSelectProjectModal.isOpen && (
                    <ListSelect
                      isOpen={listSelectProjectModal.isOpen}
                      onClose={listSelectProjectModal.onClose}
                      loading={false}
                      initiallySelectedRows={selectedProjects
                        ?.split(',')
                        .map((item: string) => ({
                          label: item,
                          value: item,
                        }))}
                      onSelectedRowsChange={(e: any) =>
                        setSelectedProjects(
                          e?.map((el: any) => el.label)?.join(',')
                        )
                      }
                      data={issueProjects?.dto?.map((item: any) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                      label={'Issue Project'}
                      setTriggerSubmit={setTriggerSubmit}
                    />
                  )}
                </Box>
              </HStack>

              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                  >
                    Issue Type:
                  </Text>
                </Box>
                <Input
                  w={'300px'}
                  value={selectedIssueType}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setSelectedIssueType(
                      eliminateHtmlTags(e.currentTarget.value)
                    )
                  }
                  placeholder="Issue type"
                />
              </HStack>

              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                  >
                    Labels:
                  </Text>
                </Box>

                <Input
                  w={'300px'}
                  value={selectedLabels}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setSelectedLabels(eliminateHtmlTags(e.currentTarget.value))
                  }
                  placeholder="Issue labels"
                />
              </HStack>

              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                  >
                    Priority:
                  </Text>
                </Box>

                <Input
                  w={'300px'}
                  value={selectedPriorities}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setSelectedPriorities(
                      eliminateHtmlTags(e.currentTarget.value)
                    )
                  }
                  placeholder="Issue priority"
                />
              </HStack>

              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                  >
                    Severity:
                  </Text>
                </Box>

                <Input
                  w={'300px'}
                  value={selectedSeverity}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setSelectedSeverity(
                      eliminateHtmlTags(e.currentTarget.value)
                    )
                  }
                  placeholder="Issue severity"
                />
              </HStack>

              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                  >
                    Issue Done Status:
                  </Text>
                </Box>

                <Input
                  w={'300px'}
                  value={selectedStatus}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setSelectedStatus(eliminateHtmlTags(e.currentTarget.value))
                  }
                  placeholder="Issue Done Status"
                />
              </HStack>

              <HStack>
                <Box minW="130px">
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    mt={'25px'}
                  >
                    Custom Field:
                  </Text>
                </Box>

                <CustomSelect
                  defaultItem={{
                    label:
                      customFieldData?.dto?.find(
                        (option: any) =>
                          option.value === selectedCustomFieldName
                      )?.name || '',
                    value: selectedCustomFieldName,
                  }}
                  label={'Select Field Name'}
                  placeholder={'Select Field Name'}
                  onChange={(selected: string) => {
                    setSelectedCustomFieldName(eliminateHtmlTags(selected));
                  }}
                  formatOptionLabel={formatOptionLabel}
                  options={[{ value: '', label: '' }].concat(
                    customFieldData?.dto?.map((e: any) => {
                      return {
                        label: e.name,
                        value: e.value,
                      };
                    })
                  )}
                />

                <Box>
                  <Input
                    w={'300px'}
                    value={selectedCustomFieldValue}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setSelectedCustomFieldValue(
                        eliminateHtmlTags(e.currentTarget.value)
                      )
                    }
                    mt={'25px'}
                    placeholder="Type Field Value"
                  />
                </Box>
              </HStack>
            </>
          )}
          <Radio value="INCIDENT_UNIT">
            Detect change failure from incident management
          </Radio>

          <Radio value="GIT_PR_UNIT">
            Detect change failure by Pull Request Title with specific word
          </Radio>
          {cfrType === 'GIT_PR_UNIT' && (
            <Input
              w={'300px'}
              value={gitLabel}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setGitLabel(eliminateHtmlTags(e.currentTarget.value))
              }
              placeholder="Enter word (case sensitive)"
            />
          )}
        </Stack>
      </RadioGroup>

      <Divider />

      {cfrType === 'INCIDENT_UNIT' && (
        <>
          <Flex align="center">
            <Text
              mr={2}
              fontFamily="heading"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
            >
              Team Tracking
            </Text>
            <Tooltip
              hasArrow
              placement="right"
              label="Use to segregate for team data"
            >
              <Box>
                <AiOutlineInfoCircle color={colors.text.secondary} />
              </Box>
            </Tooltip>
          </Flex>
          <HStack>
            <CustomSelect
              defaultItem={{
                label:
                  cfTeamTrackingIncidentOptions?.find(
                    (option: any) => option.value === teamTrackingTypeIncident
                  )?.label || '',
                value: teamTrackingTypeIncident,
              }}
              // label={'Select Field Name'}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setTeamTrackingTypeIncident(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                cfTeamTrackingIncidentOptions
              )}
            />
          </HStack>
        </>
      )}

      {cfrType === 'ISSUE_UNIT' && (
        <>
          <Flex align="center">
            <Text
              mr={2}
              fontFamily="heading"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
            >
              Team Tracking
            </Text>
            <Tooltip
              hasArrow
              placement="right"
              label="Use to segregate team data"
            >
              <Box>
                <AiOutlineInfoCircle color={colors.text.secondary} />
              </Box>
            </Tooltip>
          </Flex>
          <HStack align="center">
            <CustomSelect
              defaultItem={{
                label:
                  cfTeamTrackingIssueOptions?.find(
                    (option: any) => option.value === teamTrackingTypeIssue
                  )?.label || '',
                value: teamTrackingTypeIssue,
              }}
              // label={'Select Field Name'}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setTeamTrackingTypeIssue(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                cfTeamTrackingIssueOptions
              )}
            />
          </HStack>

          {teamTrackingTypeIssue === 'CUSTOM' && (
            <HStack>
              <CustomSelect
                menuPlacement="top"
                defaultItem={{
                  label:
                    customFieldData?.dto?.find(
                      (option: any) => option.value === teamTrackingField
                    )?.name || '',
                  value: teamTrackingField,
                }}
                label={'Select Field Name'}
                placeholder={'Select Field Name'}
                onChange={(selected: string) => {
                  setTeamTrackingField(eliminateHtmlTags(selected));
                }}
                formatOptionLabel={formatOptionLabel}
                options={[{ value: '', label: '' }].concat(
                  customFieldData?.dto?.map((e: any) => {
                    return {
                      label: e.name,
                      value: e.value,
                    };
                  })
                )}
              />
            </HStack>
          )}
        </>
      )}

      {cfrType === 'GIT_PR_UNIT' && (
        <>
          <Flex align="center">
            <Text
              mr={2}
              fontFamily="heading"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
            >
              Team Tracking
            </Text>
            <Tooltip
              hasArrow
              placement="right"
              label="Use to segregate for team data"
            >
              <Box>
                <AiOutlineInfoCircle color={colors.text.secondary} />
              </Box>
            </Tooltip>
          </Flex>
          <HStack>
            <CustomSelect
              defaultItem={{
                label:
                  cfTeamTrackingGitOptions?.find(
                    (option: any) => option.value === teamTrackingTypeGit
                  )?.label || '',
                value: teamTrackingTypeGit,
              }}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setTeamTrackingTypeGit(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                cfTeamTrackingGitOptions
              )}
            />
          </HStack>
        </>
      )}

      <Box>
        <Button width="120px" variant="tertiary" size={'md'} onClick={onSubmit}>
          Save
        </Button>
      </Box>
    </Stack>
  );
};

export default CfrForm;
