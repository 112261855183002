import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Link,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { apiClient } from '@devd-client/api';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Loader,
  PasswordInput,
  ProfileIcon,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { useNavigate, useSearchParams } from 'react-router-dom';

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  password: Yup.string()
    .min(12, 'Password must be at least 12 characters.')
    .max(128, 'Password must be lower than 128 characters.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*?\b(?:password|admin|user)\b).*$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    )
    .required('Password is required.'),
});

export function Invitation() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const { colors } = useTheme();

  const invitationCode = searchParams.get('code') ?? '';
  const [data, setData] = useState<any>('');

  const [message, setMessage] = useState<string>('');

  function createEncodedInvitationCode() {
    // Encode the email
    return encodeURIComponent(invitationCode);
  }

  useEffect(() => {
    try {
      setLoading(true);
      apiClient(`/v2/account/invitation/${createEncodedInvitationCode()}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res: any) => {
          setLoading(false);
          if (!res || !res.dto) {
            newToast({
              message: 'Signup Failed. Please try again',
              status: 'error',
            });
            return;
          }
          setData(res);
        })
        .catch((err) => {
          setLoading(false);
          newToast({
            message:
              err?.message ?? 'Signup Failed. Please check the link again ',
            status: 'error',
          });
          setMessage(
            err.message ??
              'Error in getting email/orgId. please check the link again.'
          );
        });
    } catch (err) {
      setLoading(false);
    }
  }, [invitationCode]);

  const onSubmit = async ({
    firstName,
    lastName,
    password,
  }: {
    firstName: string;
    lastName: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      apiClient(`/v2/account/join?code=${createEncodedInvitationCode()}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invitationCode: invitationCode,
          firstName: firstName,
          lastName: lastName,
          password: password,
        }),
      })
        .then((res: any) => {
          setLoading(false);
          newToast({
            message: 'Signup successful.',
            status: 'success',
          });
          setTimeout(() => {
            navigate('/account/login');
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          newToast({ message: err?.message, status: 'error' });
          setMessage(
            err?.message ??
              'Error in getting email/orgId. please check the link again.'
          );
        });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={({ firstName, lastName, password }, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit({ firstName, lastName, password });
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { isSubmitting, handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit as any}>
              <Stack spacing={4} w="full">
                <Stack spacing={1}>
                  <Flex align="center" justify="center">
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                    >
                      Hi
                    </Text>
                    <Text
                      ml={2}
                      as="span"
                      color="text.primary"
                      fontWeight="semibold"
                      fontFamily="heading"
                    >
                      {data && data?.dto?.email}
                    </Text>
                  </Flex>

                  <Text
                    textAlign="center"
                    fontSize="md"
                    fontFamily="heading"
                    color="text.primary"
                  >
                    You have been invited to join DevDynamics.
                  </Text>
                </Stack>

                {message && (
                  <Alert status="error" mb={2}>
                    <AlertIcon />
                    <AlertDescription>{message}</AlertDescription>
                  </Alert>
                )}

                <Flex justify="center" gap={4}>
                  <TextInput
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    leftIcon={
                      <ProfileIcon
                        height="16px"
                        width="16px"
                        color={colors.text.secondary}
                      />
                    }
                  />
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    leftIcon={
                      <ProfileIcon
                        height="16px"
                        width="16px"
                        color={colors.text.secondary}
                      />
                    }
                  />
                </Flex>

                <PasswordInput
                  name="password"
                  label="Password"
                  placeholder="Password"
                />

                <Button
                  isDisabled={isSubmitting || loading}
                  variant="tertiary"
                  w="full"
                  fontSize="sm"
                  type="submit"
                >
                  Sign Up
                </Button>

                <Text
                  align={'center'}
                  fontSize={'xs'}
                  fontFamily="heading"
                  color="text.secondary"
                >
                  By continuing, you agree to our{' '}
                  <Link
                    href={'https://devdynamics.ai/terms'}
                    target="_blank"
                    color="primary"
                  >
                    Terms{' '}
                  </Link>{' '}
                  of Service and{' '}
                  <Link
                    href={'https://devdynamics.ai/privacy'}
                    target="_blank"
                    color="primary"
                  >
                    Privacy{' '}
                  </Link>{' '}
                  Policy.
                </Text>
              </Stack>
            </form>
          );
        }}
      </Formik>
      {loading && <Loader />}
    </>
  );
}

export default Invitation;
