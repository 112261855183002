import {
  useDatepicker,
  START_DATE,
  FocusedInput,
} from '@datepicker-react/hooks';
import Month from './Month';
import DatepickerContext from './datePickerContext';
import { useEffect, useRef } from 'react';

type DateRangeType = {
  startDate: Date;
  endDate: Date;
  focusedInput: FocusedInput;
};

interface DatePickerProps {
  dateRange: DateRangeType;
  setDateRange: (dateRange: DateRangeType) => void;
  handleCloseDateRange: () => void;
}

export function Datepicker({
  dateRange,
  setDateRange,
  handleCloseDateRange,
}: DatePickerProps) {
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    focusedInput: dateRange.focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths: 1,
    isDateBlocked: (date: Date) => date > new Date(),
  });

  function handleDateChange(data: any) {
    if (!data.focusedInput) {
      setDateRange({ ...data, focusedInput: START_DATE });
    } else {
      setDateRange(data);
    }
  }

  const dateRangeRef = useRef(null);

  useOutsideClick(dateRangeRef);

  function useOutsideClick(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseDateRange();
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <DatepickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
      }}
    >
      {/* <NavButton onClick={goToPreviousMonths}>Previous</NavButton>
      <NavButton onClick={goToNextMonths}>Next</NavButton> */}

      <div
        ref={dateRangeRef}
        style={{
          display: 'grid',
          margin: '5px',
          gridTemplateColumns: `repeat(${activeMonths.length}, 300px)`,
          gridGap: '0 64px',
          background: '#fff',
          boxShadow: '0 2px 8px 2px rgba(0, 0, 0, 0.1)',
          padding: '16px',
          borderRadius: '4px',
        }}
      >
        {activeMonths.map((month) => (
          <Month
            key={`${month.year}-${month.month}`}
            goToPreviousMonths={goToPreviousMonths}
            goToNextMonths={goToNextMonths}
            year={month.year}
            month={month.month}
            firstDayOfWeek={firstDayOfWeek}
          />
        ))}
      </div>
    </DatepickerContext.Provider>
  );
}

export default Datepicker;
