import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

interface ScatterChartProps {
  data: any;
  xLabel: string;
  yLabel: string;
}

const ToolTipContainer = styled.div`
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 8px;
`;

const ToolTipTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const ToolTipLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <ToolTipContainer>
        <ToolTipTitle>{payload[0].payload.label}</ToolTipTitle>
        <ToolTipLabel>Unit Id: {payload[0].payload.unitId}</ToolTipLabel>
        <ToolTipLabel>Days : {payload[0].payload.value}</ToolTipLabel>
      </ToolTipContainer>
    );
  }

  return null;
};

const CustomXTick = ({ x, y, payload, prevLabel }: any) => {
  if (payload.value === prevLabel) {
    return null;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} dx={100} textAnchor="center" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};
export const AgingDistributionScatterChart = ({
  data,
  xLabel,
  yLabel,
}: ScatterChartProps) => {
  const mappedData = data.map((item: any, index: any) => ({
    label: item.label,
    value: item.value,
    unitId: item.unitId,
    index,
  }));

  const labels = Array.from(new Set(mappedData.map((item: any) => item.label)));
  const dataWithUniqueLabels = labels.map((label, index) => ({
    index,
    label,
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        data={data}
        margin={{
          top: 10,
          right: 20,
          bottom: 20,
          left: 0,
        }}
      >
        <CartesianGrid />
        <XAxis
          label={{
            fontSize: 12,
            value: xLabel,
            position: 'bottom',
            offset: 0,
          }}
          tickLine={false}
          axisLine={false}
          padding={{ left: 5, right: 5 }}
          style={{ fontSize: 11, fontWeight: 400 }}
          domain={[0, labels.length - 1]}
          tick={<CustomXTick />}
          tickCount={labels.length}
          ticks={dataWithUniqueLabels.map((item: any) => item.label)}
          dataKey="label"
        />
        <YAxis
          label={{
            fontSize: 12,
            value: yLabel,
            angle: -90,
            offset: 0,
          }}
          tickLine={false}
          axisLine={false}
          style={{ fontSize: 11, fontWeight: 400 }}
          dataKey="value"
        />
        <Tooltip
          cursor={{ strokeDasharray: '3 3' }}
          content={<CustomTooltip />}
        />
        <Scatter name="" data={mappedData} fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
