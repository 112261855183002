export const ThickArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 19H15C15.65 19 16.26 18.68 16.63 18.16L21 12L16.63 5.84C16.26 5.31 15.65 5 15 5H3L7.5 12L3 19Z"
        fill={props.color || '#000000'}
      />
    </svg>
  );
};
