export function SortIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 7.5L6 10L8.5 7.5M3.5 4.5L6 2L8.5 4.5"
        stroke={props.color || '#2A2A2F'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
