import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  Chart,
  SlideBar,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { Field, FieldArray, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { validationSchema } from './validationSchema';
import { Select } from 'chakra-react-select';
import {
  QUERY_KEYS,
  useDimension,
  useGetAllCustomDashboards,
  useGroupBy,
  useMeasure,
  useUpdateCustomDashboard,
} from '../../../api';
import {
  useAddCustomMetricsMutation,
  useUpdateCustomMetricsMutation,
} from '@devd-client/devd/custom-metrics';
import { useCustomMetrics } from '../../../graphql';
import {
  chartTypeOptions1,
  chartTypeOptions2,
  operatorOptions,
  typeOptions,
  valueOptions,
} from './utils';
import { useQueryClient } from '@tanstack/react-query';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { FormValues } from './types';
import { useSearchParams } from 'react-router-dom';
import { MdOutlineClear } from 'react-icons/md';

interface AddMetricsSlidebarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  selectedTeam: string;
  selected: any;
}

const initialValues: FormValues = {
  name: '',
  dimensions: [],
  measure: null,
  groupBy: null,
  chartType: null,
};

export const AddMetricsSlidebar: FC<AddMetricsSlidebarProps> = ({
  openDrawer,
  handleDrawerClose,
  selected,
  selectedTeam,
}) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const [searchParam] = useSearchParams();
  const { mutate: saveCustomMetrics } = useAddCustomMetricsMutation();
  const { mutate: updateCustomMetrics } = useUpdateCustomMetricsMutation();
  const { data: allDashboard } = useGetAllCustomDashboards();
  const { mutate: handleUpdate } = useUpdateCustomDashboard();
  const [entity, setEntity] = useState('');
  const [showChartForGroup, setShowChartForGroup] = useState(false);
  const [showPreviewBox, setShowPreviewBox] = useState(false);
  const [savedMetric, setSavedMetric] = useState('');

  const [metricSaved, setMetricSaved] = useState(false);

  const { data: measureData } = useMeasure();
  const { data: dimensionData } = useDimension(entity);
  const { data: groupByData } = useGroupBy(entity);

  const { data, isLoading, refetch } = useCustomMetrics(
    selected.startDate,
    selected.endDate,
    savedMetric,
    '',
    selectedTeam,
    selected.sprintId,
    'WEEK'
  );

  const onSubmit = (values: any, { resetForm }: any) => {
    if (metricSaved) {
      updateCustomMetrics(
        {
          dimensions: values.dimensions.map((item: any) => ({
            dimensionId: item.dimensionId,
            value: item.value,
            operator: item.condition,
            customField: '', // TODO
          })),
          name: values.name,
          measure: values.measure.value,
          chartType: values.chartType.value,
          group_by: values?.groupBy?.value || null,
        },
        {
          onSuccess: (data) => {
            setSavedMetric(data?.dto?.name);

            newToast({
              message: 'Metrics updated successfully!',
              status: 'success',
            });

            // if (
            //   allDashboard?.dto?.some(
            //     (item: any) => item.name === searchParam.get('id')
            //   )
            // ) {
            //   const prevData = allDashboard?.dto?.find(
            //     (item: any) => item.name === searchParam.get('id')
            //   );

            // handleUpdate(
            //   {
            //     ...prevData,
            //     layout: {
            //       ...prevData?.layout,
            //       metrics: [
            //         ...prevData.layout.metrics,
            //         { metric: values.name },
            //       ],
            //     },
            //   },
            //   {
            //     onSuccess: () => {

            //     },
            //   }
            // );
            // }
            refetch();
            Promise.all([
              queryClient.invalidateQueries([QUERY_KEYS.customDashboards]),
              queryClient.invalidateQueries([
                QUERY_KEYS.customDashboardsDetails,
              ]),
            ]);
          },
          onError: (err: any) => {
            newToast({
              message: err.message || 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    } else {
      saveCustomMetrics(
        {
          dimensions: values.dimensions.map((item: any) => ({
            dimensionId: item.dimensionId,
            value: item.value,
            operator: item.condition,
            customField: '', //Todo
          })),
          name: values.name,
          measure: values.measure.value,
          chartType: values.chartType.value,
          group_by: values?.groupBy?.label || null,
        },
        {
          onSuccess: (data) => {
            setSavedMetric(data?.dto?.name);
            setShowPreviewBox(true);
            newToast({
              message: 'Metrics saved successfully!',
              status: 'success',
            });
            setMetricSaved(true);
            if (
              allDashboard?.dto?.some(
                (item: any) => item.name === searchParam.get('id')
              )
            ) {
              const prevData = allDashboard?.dto?.find(
                (item: any) => item.name === searchParam.get('id')
              );
              handleUpdate(
                {
                  ...prevData,
                  layout: {
                    ...prevData?.layout,
                    metrics: [
                      ...prevData.layout.metrics,
                      { metric: values.name },
                    ],
                  },
                },
                {
                  onSuccess: () => {
                    Promise.all([
                      queryClient.invalidateQueries([
                        QUERY_KEYS.customDashboards,
                      ]),
                      queryClient.invalidateQueries([
                        QUERY_KEYS.customDashboardsDetails,
                      ]),
                    ]);
                  },
                }
              );
            }
          },
          onError: (err: any) => {
            newToast({
              message: err.message || 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    }
  };

  return (
    <SlideBar
      width={'100%'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={'Create Metric'}
      hideDownload={true}
      sideBarSubHeading={<></>}
      content={
        <Stack spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(props) => {
              const { handleSubmit, setFieldValue, errors, touched, values } =
                props;
              return (
                <form onSubmit={handleSubmit as any}>
                  <Flex direction="column" gap={4} bg="gray.100" padding={6}>
                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Metric Name
                      </Text>
                      <Box width="300px">
                        <TextInput
                          name="name"
                          placeholder="Enter Metric Name"
                        />
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Measures
                      </Text>
                      <Box minW="300px">
                        <Field name="measure">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              options={measureData?.dto?.map((item: any) => ({
                                label: item.displayName,
                                value: String(item.metricId),
                                entity: item.entity,
                              }))}
                              useBasicStyles
                              placeholder="Select measure"
                              onChange={(selected: any) => {
                                setEntity(selected.entity);
                                setFieldValue('dimensions', []);
                                setFieldValue('groupBy', null);
                                setFieldValue('chartType', null);
                                setShowChartForGroup(false);
                                setFieldValue('measure', selected);
                              }}
                              value={values.measure}
                            />
                          )}
                        </Field>
                        {touched.measure && errors.measure ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.measure}
                          </Text>
                        ) : null}
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="flex-start" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                        mt={2}
                      >
                        Dimensions
                      </Text>
                      <FieldArray name="dimensions">
                        {({ push, remove }) => (
                          <Box>
                            {values.dimensions?.map((dimension, index) => (
                              <>
                                <Flex key={index} align="center" gap={4} mb={4}>
                                  <Box minWidth="200px">
                                    <Text
                                      fontFamily={'heading'}
                                      fontSize="sm"
                                      fontWeight="semibold"
                                      color="text.secondary"
                                    >
                                      Attribute
                                    </Text>
                                    <Field
                                      name={`dimensions.${index}.dimensionId`}
                                    >
                                      {({ field }: any) => (
                                        <Select
                                          {...field}
                                          options={dimensionData?.dto
                                            ?.map((item: any) => ({
                                              value: String(item.metricId),
                                              label: item.displayName,
                                            }))
                                            .filter(
                                              (option: any) =>
                                                !values.dimensions
                                                  .map(
                                                    (dimension: any) =>
                                                      dimension.dimensionId
                                                  )
                                                  .includes(option.value)
                                            )}
                                          placeholder="Select dimension"
                                          onChange={(selected: any) => {
                                            setFieldValue(
                                              `dimensions.${index}.dimensionId`,
                                              selected.value
                                            );
                                          }}
                                          useBasicStyles
                                          value={dimensionData?.dto?.find(
                                            (option: any) =>
                                              option.value ===
                                                values.dimensions[index]
                                                  .dimensionId &&
                                              values.dimensions[index]
                                                .dimensionId
                                          )}
                                        />
                                      )}
                                    </Field>

                                    {touched.dimensions?.[index] &&
                                    typeof errors.dimensions?.[index] ===
                                      'object' &&
                                    (errors.dimensions[index] as any)
                                      .dimensionId ? (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color="red.500"
                                      >
                                        {
                                          (errors.dimensions[index] as any)
                                            .dimensionId
                                        }
                                      </Text>
                                    ) : null}
                                  </Box>

                                  <Box minWidth="150px">
                                    <Text
                                      fontFamily={'heading'}
                                      fontSize="sm"
                                      fontWeight="semibold"
                                      color="text.secondary"
                                    >
                                      Value
                                    </Text>

                                    <Box minW="">
                                      <Field name={`dimensions.${index}.value`}>
                                        {({ field }: any) => (
                                          <Input
                                            {...field}
                                            placeholder="Enter value"
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                              setFieldValue(
                                                `dimensions.${index}.value`,
                                                e.target.value
                                              );
                                            }}
                                            value={
                                              values.dimensions[index].value
                                            }
                                          />
                                        )}
                                      </Field>
                                    </Box>
                                    {touched.dimensions?.[index] &&
                                    typeof errors.dimensions?.[index] ===
                                      'object' &&
                                    (errors.dimensions[index] as any).value ? (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color="red.500"
                                      >
                                        {
                                          (errors.dimensions[index] as any)
                                            .value
                                        }
                                      </Text>
                                    ) : null}
                                  </Box>

                                  {/* <Box minWidth="150px">
                                    <Text
                                      fontFamily={'heading'}
                                      fontSize="sm"
                                      fontWeight="semibold"
                                      color="text.secondary"
                                    >
                                      Condition
                                    </Text>
                                    <Field
                                      name={`dimensions.${index}.condition`}
                                    >
                                      {({ field }: any) => (
                                        <Select
                                          {...field}
                                          useBasicStyles
                                          options={operatorOptions}
                                          placeholder="Select condition"
                                          onChange={(selected: any) => {
                                            setFieldValue(
                                              `dimensions.${index}.condition`,
                                              selected.value
                                            );
                                          }}
                                          value={operatorOptions.find(
                                            (option: any) =>
                                              option.value ===
                                              values.dimensions[index].condition
                                          )}
                                        />
                                      )}
                                    </Field>
                                    {touched.dimensions?.[index] &&
                                    typeof errors.dimensions?.[index] ===
                                      'object' &&
                                    (errors.dimensions[index] as any)
                                      .condition ? (
                                      <Text
                                        as="span"
                                        fontSize="sm"
                                        color="red.500"
                                      >
                                        {
                                          (errors.dimensions[index] as any)
                                            .condition
                                        }
                                      </Text>
                                    ) : null}
                                  </Box> */}

                                  <Flex gap={2} mt={5}>
                                    <Tooltip hasArrow label="Delete">
                                      <IconButton
                                        aria-label="Delete"
                                        size="sm"
                                        icon={<DeleteIcon />}
                                        onClick={() => remove(index)}
                                        border="1px solid #777"
                                      />
                                    </Tooltip>
                                  </Flex>
                                </Flex>
                              </>
                            ))}

                            <Flex align="center" gap={4}>
                              {dimensionData?.dto?.length !==
                                values?.dimensions?.length && (
                                <Tooltip hasArrow label="Add Dimension">
                                  <IconButton
                                    aria-label="Add Dimension"
                                    icon={<AddIcon />}
                                    size="sm"
                                    onClick={() => {
                                      if (!values.measure) {
                                        newToast({
                                          message:
                                            'Please select Measure to add dimensions',
                                          status: 'error',
                                        });
                                      } else {
                                        push({
                                          dimensionId: '',
                                          value: '',
                                          condition: 'EQUAL',
                                        });
                                      }
                                    }}
                                    border="1px dotted #777"
                                  />
                                </Tooltip>
                              )}

                              {touched.dimensions &&
                              typeof errors.dimensions === 'string' ? (
                                <Text as="span" fontSize="sm" color="red.500">
                                  {errors.dimensions}
                                </Text>
                              ) : null}
                            </Flex>
                          </Box>
                        )}
                      </FieldArray>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Group By
                      </Text>

                      <Box minW="300px">
                        <Field name="groupBy">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              useBasicStyles
                              options={groupByData?.dto?.map((item: any) => ({
                                label: item.displayName,
                                value: item.field,
                              }))}
                              placeholder="Group By"
                              onChange={(selected: any) => {
                                setShowChartForGroup(true);
                                setFieldValue('groupBy', selected);
                              }}
                              value={values.groupBy}
                            />
                          )}
                        </Field>
                        {touched.groupBy && errors.groupBy ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.groupBy}
                          </Text>
                        ) : null}
                      </Box>
                      {values.groupBy && (
                        <Tooltip hasArrow label="Clear">
                          <IconButton
                            aria-label="clear"
                            icon={<MdOutlineClear size="18" />}
                            size="sm"
                            onClick={() => {
                              setFieldValue('groupBy', null);
                              setFieldValue('chartType', null);
                              setShowChartForGroup(false);
                            }}
                            border="1px dotted #777"
                          />
                        </Tooltip>
                      )}
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Flex align="center" gap={4}>
                      <Text
                        fontFamily={'heading'}
                        fontSize="sm"
                        fontWeight="semibold"
                        color="text.secondary"
                        mb={1}
                        width="100px"
                      >
                        Chart Type
                      </Text>

                      <Box minW="300px">
                        <Field name="chartType">
                          {({ field, form }: any) => (
                            <Select
                              {...field}
                              useBasicStyles
                              options={
                                showChartForGroup
                                  ? chartTypeOptions2
                                  : chartTypeOptions1
                              }
                              placeholder="Select chart type"
                              onChange={(selected: any) => {
                                setFieldValue('chartType', selected);
                              }}
                              value={values.chartType}
                            />
                          )}
                        </Field>
                        {touched.chartType && errors.chartType ? (
                          <Text as="span" fontSize="sm" color="red.500">
                            {errors.chartType}
                          </Text>
                        ) : null}
                      </Box>
                    </Flex>

                    <Box w="full" h="1px" bg="#e0e0e0" />

                    <Box>
                      <Button
                        variant="tertiary"
                        fontSize="sm"
                        type="submit"
                        loadingText={'Saving'}
                      >
                        {metricSaved ? 'Update' : 'Save'}
                      </Button>
                    </Box>
                  </Flex>
                </form>
              );
            }}
          </Formik>
          {showPreviewBox && (
            <Box
              border="1px solid"
              borderColor="#e0e0e0"
              height="400px"
              padding={3}
              width="100%"
            >
              {!isLoading && !data ? (
                <Text color="error">No Data</Text>
              ) : (
                <Chart
                  //   chartref={chartref}
                  data={data?.data}
                  keys={data?.keys}
                  chartMetadata={data?.chartMetadata}
                  showLabel={true}
                  selectedTeam={selectedTeam}
                  hideBtn
                  //   ticketType={ticketType}
                  selected={selected}
                  //   queryKey={queryKey}
                />
              )}
            </Box>
          )}
        </Stack>
      }
    />
  );
};

export default AddMetricsSlidebar;
