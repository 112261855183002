import { Box } from '@chakra-ui/react';
import { useContext } from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import styled from 'styled-components';
import { InvestmentsContext } from '../../context/investmentContext';

const ColorDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
  background-color: ${({ color }) => color};
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope';
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const CustomTooltip = ({ payload }: any) => {
  return (
    <TooltipContainer>
      <ColorDot color={payload[0]?.payload?.fill} />
      <Box fontWeight="600" fontSize="12px" color="#999999">
        {payload[0]?.name}:
      </Box>
      <Box fontWeight="600" fontSize="12px" ml="5px">
        {payload[0]?.value} Tickets
      </Box>
    </TooltipContainer>
  );
};

const PieChartData = () => {
  const {
    state: { pieChartData },
  } = useContext(InvestmentsContext);

  const renderCustomLabel = (item: any) => (
    <text
      fill={'#000'}
      fontWeight={400}
      fontFamily="Inter"
      fontSize={12}
      x={item.x}
      y={item.y}
      stroke="none"
      textAnchor="end"
    >
      <tspan x={item.x} textAnchor={item.textAnchor} dy="0em">
        {`${item.dist}%`}
      </tspan>
    </text>
  );

  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart>
        <Pie
          data={pieChartData}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          label={(item) => renderCustomLabel(item)}
          labelLine={false}
        ></Pie>
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default PieChartData;
