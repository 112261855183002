import { apiClient } from '@devd-client/api';

export const getAllSubsPlans = (params: any): Promise<any> =>
  apiClient(
    `/v2/subscription/plans?duration=${
      params.duration === 'Monthly' ? 'month' : 'year'
    }`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllNotifications = (): Promise<any> =>
  apiClient(`/v1/alarms/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const markAsRead = (params: any): Promise<any> =>
  apiClient(`/v1/alarms/${params.id}/mark-as-read`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({}),
  });

export const shownTrialEndModal = (params: any): Promise<any> =>
  apiClient(`/v1/user/${params.id}/preference/displayUpgrade`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      displayed: params.displayed,
    }),
  });

export const getOrdinalSteps = (): Promise<any> =>
  apiClient(`/v2/account/org/${localStorage.getItem('orgId')}/setup`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
