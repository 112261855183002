import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  customField: 'customField',
  jiraProjects: 'jiraProjects',
};

export const useCustomfield = () => {
  return useQuery([QUERY_KEYS.customField], ENDPOINTS.getCustomField);
};

export const useFetchAllJiraProjects = () =>
  useQuery([QUERY_KEYS.jiraProjects], ENDPOINTS.getAllJiraProjects);
