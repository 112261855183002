export function Epic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z"
        fill="#904EE2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.923 7.75651L10.921 7.75251C10.967 7.67751 11 7.59451 11 7.49951C11 7.22351 10.776 6.99951 10.5 6.99951H8V4.49951C8 4.22351 7.776 3.99951 7.5 3.99951C7.328 3.99951 7.185 4.09151 7.095 4.22251C7.067 4.26351 7.044 4.30551 7.029 4.35251L5.081 8.23651L5.083 8.23951C5.035 8.31651 5 8.40251 5 8.49951C5 8.77651 5.224 8.99951 5.5 8.99951H8V11.4995C8 11.7765 8.224 11.9995 8.5 11.9995C8.679 11.9995 8.829 11.9005 8.918 11.7585L8.921 11.7595L8.934 11.7335C8.945 11.7125 8.957 11.6935 8.965 11.6715L10.923 7.75651Z"
        fill="white"
      />
    </svg>
  );
}

export default Epic;
