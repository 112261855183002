import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SuggestedAction } from '../../../../helpers/report.types';
import { FiExternalLink } from 'react-icons/fi';

interface SuggestionItemProps {
  action: SuggestedAction;
}

const SuggestionItem: FC<SuggestionItemProps> = ({ action }) => {
  return (
    <Flex
      w="70%"
      p={2}
      border="0.5px solid"
      borderColor="primary"
      borderRadius="4px"
    >
      {!action.cta ? (
        <Text fontSize="sm" fontWeight="medium" color="text.primary">
          {action.text}
        </Text>
      ) : (
        <Link to={action.cta}>
          <Flex
            align="center"
            _hover={{ color: 'primary', textDecoration: 'underline' }}
          >
            <Text
              fontSize="sm"
              mr={1}
              textDecoration="underline"
              fontWeight="medium"
              _hover={{ color: 'primary', textDecoration: 'underline' }}
              color="text.primary"
            >
              {action.text}
            </Text>
            <Box>
              <FiExternalLink />
            </Box>
          </Flex>
        </Link>
      )}
    </Flex>
  );
};

export default SuggestionItem;
