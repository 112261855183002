import { Stack, Text } from '@chakra-ui/react';
import { ISelect, PeriodType } from '@devd-client/devd/components';
import { FC } from 'react';
import styled from 'styled-components';
import { Report, SuggestedAction } from '../../../helpers/report.types';
import ChartContainer from './ChartContainer';
import SuggestionItem from './SuggestionItem';

interface ReportItemProps {
  report: Report;
  showDivider: boolean;
  selected: PeriodType;
  selectedTeam: ISelect;
}

const Divider = styled.hr`
  border-top: ${({ theme }) => `1.6px dashed #ccc`};
  color: transparent;
`;

const ReportItem: FC<ReportItemProps> = ({
  report,
  showDivider,
  selected,
  selectedTeam,
}) => {
  return (
    <>
      <Stack spacing={4} w="full">
        <Text
          fontSize="md"
          fontFamily="heading"
          fontWeight="medium"
          color="text.primary"
          width="85%"
        >
          {report?.headline}
        </Text>

        <ChartContainer
          showMetric={report?.reportMetrics}
          selected={selected}
          selectedTeam={selectedTeam}
        />
        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
          >
            SUGGESTED ACTIONS
          </Text>

          {report?.suggestedActions.map(
            (action: SuggestedAction, idx: number) => (
              <SuggestionItem key={`Suggestion-${idx}`} action={action} />
            )
          )}
        </Stack>
        {showDivider && <Divider />}
      </Stack>
    </>
  );
};

export default ReportItem;
