import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  TeamFilter,
  AuthorIdFilter,
  PR_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_HEALTH_INDEX_CHARTS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  ISSUE_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  REQUIREMENT_STABILITY_CHARTS,
} from '../shared/utils';

// This zustand store consists and persists all data related to PR table

interface MetricTableTypes {
  isQueryReady: boolean;
  tableType: string;
  heading: string;
  metricKey: string;
  chartKey: string;
  metric: string;
  componentOrigin: string | null;
  showFilters: boolean;
  searchQuery: null | string;
  projectsFilter?: string[];
  sprintId?: string | null;
  teamId: string;
  name?: string;
  orderBy?: string;
  orderColumn?: string;

  // PR RELATED
  status: string;
  prSize: string | null;
  reviewer: string | null;
  filterAge: string | null;
  age: string | null;
  prFeedbackResponsivenessType: string | null;
  prReviewResponsivenessType: string | null;
  prIds: null | string[];
  author: string | null;
  type: string | null;
  lowerBoundIncl: number | null;
  upperBound: number | null;
  isPrLinked: boolean | null;
  isPrWithComment: boolean | null;
  isPrReviewedWithComment: boolean | null;
  isLongPrCycleTime: boolean | null;
  isMergedWithReview: boolean | null;
  prIterations: string | null;

  // ISSUE RELATED
  issueType: string | null;
  issueStatusType: string;
  issueStatus: string | null;
  filterStatus: string | null;
  issuePriority: string | null;
  issueDueDate: string | null;
  isDueDatePresent: null | boolean;
  isPriority: null | boolean;
  isLongCycleTime: null | boolean;
  inactiveIssues: string | null;
  backtrackIssues: null | boolean;
  reopenedIssues: null | boolean;
  changingRequirements: null | boolean;
  cycleTimeSpread: string | null;
  issueAge: string | null;
  stage: string | null;
  currentAssignee: null | string;
  assignees: null | string[];
  issueIds: null | string[];
  isDelayed: null | boolean;
}

interface Actions {
  reset: () => void;
  resetFilters: () => void;

  setSorting: (orderBy: string, orderColumn: string) => void;
  setComponentOrigin: (value: string | null) => void;
  setIsQueryReady: (value: boolean) => void;
  setShowFilters: (value: boolean) => void;
  setStatus: (value: string) => void;
  setSearchQuery: (value: string) => void;
  setIssueIds: (value: string) => void;
  setTeam: (value: string) => void;
  setPrIds: (value: string[] | null) => void;
  setMetricKeys: (value: string) => void;

  // PR RELATED
  setPRValues: (
    origin: string,
    value: string | null,
    chartKey: string,
    metricKey: string,
    metric: string,
    heading: string
  ) => void;

  // ISSUE RELATED
  setIssueValues: (
    origin: string,
    value: string | null,
    // double check if ticketType needs to be not necessary to pass.
    ticketType: null | string,
    chartKey: string,
    metric: string,
    heading: string
  ) => void;
  setIssueStatusType: (value: string) => void;
}

const initialState: MetricTableTypes = {
  isQueryReady: false,
  tableType: '',
  heading: '',
  metricKey: '',
  chartKey: '',
  metric: '',
  componentOrigin: null,
  showFilters: true,
  searchQuery: null,
  projectsFilter: [],
  sprintId: '',
  teamId: '',
  name: '',
  orderBy: '',
  orderColumn: '',

  // PR RELATED
  status: 'all',
  prSize: null,
  reviewer: null,
  filterAge: null,
  age: null,
  prReviewResponsivenessType: null,
  prFeedbackResponsivenessType: null,
  prIds: null,
  author: null,
  type: null,
  lowerBoundIncl: null,
  upperBound: null,
  isPrLinked: null,
  isPrWithComment: null,
  isPrReviewedWithComment: null,
  isLongPrCycleTime: null,
  isMergedWithReview: null,
  prIterations: null,

  // ISSUE RELATED
  issueType: null,
  issueStatusType: 'all',
  issueStatus: null,
  filterStatus: null,
  issuePriority: null,
  isLongCycleTime: null,
  isPriority: null,
  issueDueDate: null,
  isDueDatePresent: null,
  inactiveIssues: null,
  backtrackIssues: null,
  reopenedIssues: null,
  changingRequirements: null,
  cycleTimeSpread: null,
  issueAge: null,
  stage: null,
  currentAssignee: null,
  assignees: null,
  issueIds: null,
  isDelayed: null,
};

export const useMetricsTableStore = create<MetricTableTypes & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,
      // PR RELATED
      setPRValues: (
        origin,
        value,
        chartKey = '',
        metricKey = '',
        metric = '',
        heading = ''
      ) => {
        return set((state) => ({
          // STORE VARIABLES
          heading: heading !== '' ? heading : state.heading,
          tableType: 'PR',
          chartKey: chartKey !== '' ? chartKey : state.chartKey,
          metric: metric !== '' ? metric : state.metric,
          isQueryReady: true,

          // QUERY RELATED
          author:
            origin === 'authors'
              ? value
              : state.componentOrigin === 'contributor-profile-pr' &&
                origin !== 'reviewers'
              ? state.author
              : null,
          reviewer:
            origin === 'reviewers'
              ? value
              : [
                  'TOP_REVIEWER_DISTRIBUTION',
                  'PR_REVIEW',
                  'PR_REVIEWED',
                ].includes(chartKey !== '' ? chartKey : state.chartKey) ||
                (state.componentOrigin === 'contributor-profile-pr' &&
                  origin !== 'authors')
              ? state.reviewer
              : null,
          sprintId: '',
          projectsFilter: origin === 'repos' ? [value as string] : [],
          type: null,
          orderBy: '',
          orderColumn: '',

          // PR VIEW FILTERS (PVF)
          prSize:
            origin === 'pr_size'
              ? value
              : ['LOC_VS_PR_CYCLE_TIME'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.prSize
              : null,
          filterAge:
            origin === 'pr_age'
              ? value
              : ['PR_AGEING_DISTRIBUTION'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.filterAge
              : null,

          searchQuery: null,
          prReviewResponsivenessType:
            origin === 'prReviewResponsivenessType'
              ? value
              : ['REVIEW_RESPONSIVENESS_INDEX'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                ) &&
                !['review_feedback', 'prFeedbackResponsivenessType'].includes(
                  origin
                )
              ? state.prReviewResponsivenessType
              : null,
          prFeedbackResponsivenessType:
            origin === 'prFeedbackResponsivenessType'
              ? value
              : ['REVIEW_RESPONSIVENESS_INDEX'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                ) &&
                !['review_feedback', 'prReviewResponsivenessType'].includes(
                  origin
                )
              ? state.prFeedbackResponsivenessType
              : null,
          isPrReviewedWithComment:
            origin === 'review_feedback'
              ? value === 'review_with_comment'
                ? true
                : false
              : ['REVIEW_RESPONSIVENESS_INDEX'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                ) &&
                ![
                  'prReviewResponsivenessType',
                  'prFeedbackResponsivenessType',
                ].includes(origin)
              ? state.isPrReviewedWithComment
              : null,
          isPrLinked:
            origin === 'prs_linked'
              ? true
              : origin === 'prs_unlinked'
              ? false
              : null,
          isPrWithComment: origin === 'prs_wo_comments' ? false : null,
          isLongPrCycleTime: origin === 'long_pr_cycle' ? true : null,
          isMergedWithReview:
            origin === 'merged_with_review'
              ? true
              : origin === 'merged_wo_review'
              ? false
              : null,
          prIterations: origin === 'prIterations' ? value : null,

          // METRIC SPECIFIC FILTERS
          age:
            origin === 'pr_age'
              ? value === 'MORE_THAN_1D'
                ? 'More than 1 Day'
                : value === 'MORE_THAN_3D'
                ? 'More than 3 Days'
                : value === 'MORE_THAN_7D'
                ? 'More than 7 Days'
                : value === 'MORE_THAN_14D'
                ? 'More than 14 Days'
                : value === 'MORE_THAN_1M'
                ? 'More than 1 Month'
                : null
              : ['PR_AGEING_DISTRIBUTION'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.filterAge === 'MORE_THAN_1D'
                ? 'More than 1 Day'
                : state.filterAge === 'MORE_THAN_3D'
                ? 'More than 3 Days'
                : state.filterAge === 'MORE_THAN_7D'
                ? 'More than 7 Days'
                : state.filterAge === 'MORE_THAN_14D'
                ? 'More than 14 Days'
                : state.filterAge === 'MORE_THAN_1M'
                ? 'More than 1 Month'
                : null
              : null,
          // refer to setReviewResponse reviewfeedback and setFeedbackresponse
          name:
            origin === 'prReviewResponsivenessType'
              ? 'Review Responsiveness Index'
              : origin === 'prFeedbackResponsivenessType'
              ? 'Feedback Responsiveness Index'
              : origin === 'review_feedback'
              ? 'Review Completion'
              : ['REVIEW_RESPONSIVENESS_INDEX'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.name
              : '',
          metricKey: metricKey !== '' ? metricKey : state.metricKey,
          lowerBoundIncl:
            origin === 'pr_size'
              ? value === '0-200'
                ? 0
                : value === '200-400'
                ? 200
                : value === '400-600'
                ? 400
                : value === '600-800'
                ? 600
                : value === '800'
                ? 800
                : null
              : ['LOC_VS_PR_CYCLE_TIME'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.prSize === '0-200'
                ? 0
                : state.prSize === '200-400'
                ? 200
                : state.prSize === '400-600'
                ? 400
                : state.prSize === '600-800'
                ? 600
                : state.prSize === '800'
                ? 800
                : null
              : null,
          upperBound:
            origin === 'pr_size'
              ? value === '0-200'
                ? 200
                : value === '200-400'
                ? 400
                : value === '400-600'
                ? 600
                : value === '600-800'
                ? 800
                : value === '800'
                ? null
                : null
              : ['LOC_VS_PR_CYCLE_TIME'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.prSize === '0-200'
                ? 200
                : state.prSize === '200-400'
                ? 400
                : state.prSize === '400-600'
                ? 600
                : state.prSize === '600-800'
                ? 800
                : state.prSize === '800'
                ? null
                : null
              : null,
        }));
      },
      setStatus: (value: string) => {
        return set(() => ({
          status: value,
        }));
      },
      setPrIds: (value: string[] | null) =>
        set((state) => ({
          prIds: value,
        })),

      // ISSUE RELATED
      setIssueValues: (
        origin,
        value,
        ticketType,
        chartKey = '',
        metric = '',
        heading = ''
      ) => {
        return set((state) => ({
          // STORE VARIABLES
          heading: heading !== '' ? heading : state.heading,
          tableType: 'ISSUE',
          chartKey: chartKey !== '' ? chartKey : state.chartKey,
          metric: metric !== '' ? metric : state.metric,
          isQueryReady: true,
          // QUERY RELATED
          sprintId: '',
          type:
            origin === 'reopenedIssues'
              ? value
              : ticketType !== null
              ? ticketType
              : ['all', 'bug', 'task'].includes(state.type ?? '')
              ? state.type
              : null,
          projectsFilter: origin === 'repos' ? [value as string] : [],

          // ISSUE VIEW FILTERS (IVF)
          issueType: origin === 'issueType' ? value : null,
          issueStatus:
            origin === 'issueStatus' &&
            !['ISSUE_PROGRESSION_RATE'].includes(
              chartKey !== '' ? chartKey : state.chartKey
            )
              ? value
              : null,
          filterStatus:
            origin === 'inflow_outflow'
              ? value
              : origin === 'issueStatus' &&
                ['ISSUE_PROGRESSION_RATE'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? value
              : ['ISSUE_THROUGHPUT', 'ISSUE_PROGRESSION_RATE'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.filterStatus
              : null,
          issuePriority:
            origin === 'issuePriority'
              ? value !== 'false'
                ? value
                : null
              : null,
          isPriority:
            origin === 'issuePriority'
              ? value === 'false'
                ? false
                : null
              : null,
          issueDueDate:
            origin === 'issueDueDate'
              ? value !== 'false'
                ? value
                : null
              : null,
          isDueDatePresent:
            origin === 'issueDueDate'
              ? value === 'false'
                ? false
                : null
              : null,
          isLongCycleTime: origin === 'isLongCycleTime' ? true : null,
          isDelayed: origin === 'isDelayed' ? true : null,
          backtrackIssues: origin === 'backtrackIssues' ? true : null,
          reopenedIssues: origin === 'reopenedIssues' ? true : null,
          changingRequirements:
            origin === 'changingRequirements'
              ? value === 'true'
                ? true
                : false
              : null,

          inactiveIssues: origin === 'inactiveIssues' ? value : null,
          cycleTimeSpread:
            origin === 'cycleTimeSpread'
              ? value
              : ['ISSUE_CYCLE_TIME_DIST'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.cycleTimeSpread
              : null,
          issueAge:
            origin === 'issueAge'
              ? value
              : ['CHANGE_AGING_DISTRIBUTION'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.issueAge
              : null,
          stage:
            origin === 'stage'
              ? value
              : ['ISSUE_LIFECYCLE_DIST'].includes(
                  chartKey !== '' ? chartKey : state.chartKey
                )
              ? state.stage
              : null,
          currentAssignee:
            origin === 'currentAssignee'
              ? value
              : state.componentOrigin === 'contributor-profile-issue' &&
                origin !== 'assignees'
              ? state.currentAssignee
              : null,
          assignees:
            origin === 'assignees'
              ? [value as string]
              : state.componentOrigin === 'contributor-profile-issue' &&
                origin !== 'currentAssignee'
              ? state.assignees
              : null,
          issueIds: null,
        }));
      },
      setIssueStatusType: (value: string) =>
        set((state) => ({
          issueStatusType: value,
        })),

      setIssueIds: (value: string) =>
        set((state) => ({
          issueIds: value === '' ? null : [value],
        })),

      //  COMMON FILTERS

      setTeam: (value: string) =>
        set(() => ({
          teamId: value,
        })),
      setComponentOrigin: (value: string | null) =>
        set(() => ({
          componentOrigin: value,
        })),
      setIsQueryReady: (value: boolean) =>
        set(() => ({
          isQueryReady: value,
        })),

      setShowFilters: (value: boolean) =>
        set(() => ({
          showFilters: value,
        })),

      setSearchQuery: (value: string) =>
        set((state) => ({ searchQuery: value === '' ? null : value })),

      setSorting: (orderBy: string, orderColumn: string) =>
        set(() => ({
          orderBy,
          orderColumn,
        })),

      reset: () => set(() => initialState),
      resetFilters: () =>
        set((state) => ({
          ...initialState,
          isQueryReady: true,
          tableType: state.tableType,
          heading:
            state.tableType === 'PR'
              ? 'PR Details Table'
              : 'Issue Details Table',
          chartKey: state.tableType === 'PR' ? 'PR_UPDATED' : 'ISSUE_UPDATED',
          metric: state.tableType === 'PR' ? 'PR_UPDATED' : 'ISSUE_UPDATED',
          componentOrigin: state.componentOrigin,
          type: state.tableType === 'ISSUE' ? 'all' : null,
          status: 'all',
          issueStatusType: 'all',
        })),
      setMetricKeys: (value) => set(() => ({ metricKey: value })),
    }),
    {
      name: 'MetricFilters', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useMetricsTableStore;
