import { useCustomMutation } from '@devd-client/devd/components';
import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  customMetrics: 'customMetrics',
  customField: 'customField',
  measure: 'measure',
  customMetric: 'customMetric',
};

export const useFetchCustomMetrics = () =>
  useQuery([QUERY_KEYS.customMetrics], ENDPOINTS.getCustomMetrics);

export const useAddCustomMetricsMutation = () =>
  useCustomMutation([QUERY_KEYS.customMetrics], ENDPOINTS.addCustomMetrics);

export const useUpdateCustomMetricsMutation = () =>
  useCustomMutation([QUERY_KEYS.customMetrics], ENDPOINTS.updateCustomMetrics);

export const useCustomMetricsDeleteMutation = () =>
  useCustomMutation([QUERY_KEYS.customMetrics], ENDPOINTS.deleteCustomMetrics);

export const useCustomfield = () => {
  return useQuery([QUERY_KEYS.customField], ENDPOINTS.getCustomField);
};

export const useMeasure = () => {
  return useQuery([QUERY_KEYS.measure], ENDPOINTS.getMeasure);
};

export const useFetchCustomMetricsByName = (metric: string) =>
  useQuery([QUERY_KEYS.customMetrics, metric], () =>
    ENDPOINTS.getCustomMetricsByName(metric)
  );
