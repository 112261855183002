import { Box, Text, useTheme } from '@chakra-ui/react';
import { splitTime } from '@devd-client/devd/components';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

type Props = {
  data: any;
  showLabel: boolean;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        bg="white"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="md"
        p={3}
        boxShadow="sm"
      >
        <Text
          fontWeight="semibold"
          color="text.secondary"
          mb={1}
        >{`${label} : ${splitTime(payload[0]?.value / 3600)}`}</Text>
      </Box>
    );
  }

  return null;
};

const TableBarChart = ({ data, showLabel }: Props) => {
  const { colors } = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{ bottom: showLabel ? 80 : 0, left: 0 }}>
        <XAxis
          dataKey="name"
          angle={-25}
          interval={0}
          textAnchor="end"
          dy={4}
          style={{ fontSize: '12px' }}
          tick={showLabel}
        />
        <YAxis tickFormatter={(e) => splitTime(e / 3600)} />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
          cursor={{ fill: 'transparent' }}
        />
        <Bar dataKey="value" barSize={showLabel ? 22 : 16}>
          {data?.map((_: any, index: number) => (
            <Cell
              cursor="pointer"
              fill={colors.primary}
              key={`cell-${index}`}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TableBarChart;
