export type GoalsState = {
  goalsList: any;
  clickedGoal: any;
};

export enum GoalsActionTypes {
  GET_GOALS_LIST = '[Goals] Get Goals Data',
  SET_CLICKED_GOAL = '[Goals] Set Clicked Goal',
}

export type GoalsAction = {
  type: GoalsActionTypes;
  payload?: any;
};

export const goalsReducer = (
  state: GoalsState,
  action: GoalsAction
): GoalsState => {
  switch (action.type) {
    case GoalsActionTypes.GET_GOALS_LIST:
      return { ...state, goalsList: action?.payload };

    case GoalsActionTypes.SET_CLICKED_GOAL:
      return { ...state, clickedGoal: action?.payload };

    default:
      return state;
  }
};
