import { Box } from '@chakra-ui/react';
import React, { Suspense } from 'react';

const Onboarding = React.lazy(() =>
  import('@devd-client/devd/onboarding').then((module) => ({
    default: module.DevdOnboarding,
  }))
);
export function AsyncOnboarding() {
  return (
    <Suspense>
      <Onboarding />
    </Suspense>
  );
}

export default AsyncOnboarding;
