import { splitTime } from '@devd-client/devd/components';

export const tabs: Record<string, number> = {
  teamStats: 0,
  teamMetrics: 1,
  contributors: 2,
  repositories: 3,
  sprint: 4,
  team: 5,
  workingAgreement: 6,
  inProgress: 7,
};

export const employeeTabs: Record<string, number> = {
  teamStats: 0,
  teamMetrics: 1,
  repositories: 2,
  sprint: 3,
  team: 4,
  workingAgreement: 5,
  inProgress: 6,
};

export const tabList: string[] = [
  'Team Statistics',
  'Team Metrics',
  'Contributors Velocity',
  'Repositories Velocity',
  'Sprint Velocity',
  'Team Collaboration',
  'Working Agreement',
  'InProgress Work Item',
];

export const employeeTabList: string[] = [
  'Team Statistics',
  'Team Metrics',
  'Repositories Velocity',
  'Sprint Velocity',
  'Team Collaboration',
  'Working Agreement',
  'InProgress Work Item',
];

export const convertToTimeFormat = (time: number) =>
  time === 0 || time === null ? '0h' : splitTime(time);

export const getTabList = (role: string): string[] => {
  if (role === 'EMPLOYEE') {
    return employeeTabList;
  } else {
    return tabList;
  }
};

export const getTabs = (role: string): Record<string, number> => {
  if (role === 'EMPLOYEE') {
    return employeeTabs;
  } else {
    return tabs;
  }
};

export const defaultMetrics = ['PR_CYCLE_TIME', 'TASK_CLOSE', 'PR_MERGE'];

export const formatLabel = (label: any) => {
  return label
    ?.replace(/([A-Z])/g, ' $1')
    ?.replace(/^./, (str: any) => str.toUpperCase())
    ?.replace('Pr', 'PR');
};
