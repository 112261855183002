export const AiStarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_8316_18187)">
        <path
          d="M13.5128 8.42227C13.9843 8.42227 14.279 7.91183 14.0433 7.50352C13.9338 7.31402 13.7316 7.19727 13.5128 7.19727C9.72757 7.11152 8.00032 5.35977 8.00032 1.68477C8.00032 1.34652 7.72607 1.07227 7.38782 1.07227C7.04957 1.07227 6.77532 1.34652 6.77532 1.68477C6.77532 5.39039 5.0052 7.14214 1.26282 7.19727C0.791324 7.19727 0.496637 7.7077 0.732387 8.11602C0.841824 8.30552 1.04401 8.42227 1.26282 8.42227C4.97457 8.42227 6.7202 10.174 6.77532 13.9348C6.77532 14.2731 7.04951 14.5473 7.38782 14.5473C7.72607 14.5473 8.00032 14.273 8.00032 13.9348C8.1902 10.1556 9.9297 8.42227 13.4761 8.42227H13.5128Z"
          fill="url(#paint0_linear_8316_18187)"
        />
        <path
          d="M11.0753 2.98329H11.8409V3.74891C11.8409 4.10254 12.2237 4.32354 12.53 4.14672C12.6721 4.06466 12.7597 3.91304 12.7597 3.74891V2.98329H13.5253C13.8789 2.98329 14.0999 2.60047 13.9231 2.29422C13.841 2.1521 13.6894 2.06454 13.5253 2.06454H12.7597V1.29891C12.7597 0.945287 12.3768 0.724287 12.0706 0.901099C11.9285 0.983162 11.8409 1.13479 11.8409 1.29891V2.06454H11.0753C10.7217 2.06454 10.5007 2.44735 10.6775 2.7536C10.7595 2.89572 10.9112 2.98329 11.0753 2.98329Z"
          fill="url(#paint1_linear_8316_18187)"
        />
        <path
          d="M14.8905 13.0157H14.1249V12.2501C14.1249 11.8965 13.7421 11.6755 13.4358 11.8523C13.2937 11.9343 13.2061 12.086 13.2061 12.2501V13.0157H12.4405C12.0869 13.0157 11.8659 13.3985 12.0427 13.7048C12.1248 13.8469 12.2764 13.9345 12.4405 13.9345H13.2061V14.7001C13.2061 15.0537 13.589 15.2747 13.8952 15.0979C14.0373 15.0158 14.1249 14.8642 14.1249 14.7001V13.9345H14.8905C15.2441 13.9345 15.4651 13.5516 15.2883 13.2454C15.2063 13.1033 15.0546 13.0157 14.8905 13.0157Z"
          fill="url(#paint2_linear_8316_18187)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8316_18187"
          x1="7.38782"
          y1="1.07227"
          x2="7.38782"
          y2="14.5473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={props.color || '#FF6FD8'} />
          <stop offset="1" stop-color={props.color || '#4D4DFF'} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8316_18187"
          x1="12.3003"
          y1="0.838867"
          x2="12.3003"
          y2="4.20896"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={props.color || '#FF6FD8'} />
          <stop offset="1" stop-color={props.color || '#4D4DFF'} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8316_18187"
          x1="13.6655"
          y1="11.79"
          x2="13.6655"
          y2="15.1601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={props.color || '#FF6FD8'} />
          <stop offset="1" stop-color={props.color || '#4D4DFF'} />
        </linearGradient>
        <clipPath id="clip0_8316_18187">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiStarIcon;
