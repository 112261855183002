import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useDashboardStore } from '../../hooks/useDashboardStore';

interface YearProps {
  year: number;
  goToPreviousYear: () => void;
  goToNextYear: () => void;
  onSelectQuarter: (quarter: number, year: number) => void;
}

const quarters = [
  'Q1 Jan to Mar',
  'Q2 Apr to Jun',
  'Q3 Jul to Sept',
  'Q4 Oct to Dec',
];

const Quarter: React.FC<YearProps> = ({
  year,
  goToPreviousYear,
  goToNextYear,
  onSelectQuarter,
}) => {
  const [selectedQuarter, setSelectedQuarter] = useState<number | null>(null);
  const { filterTitle } = useDashboardStore();
  const currentYear = new Date().getFullYear();
  const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

  const isFutureQuarter = (quarterIndex: number, year: number) => {
    if (year > currentYear) return true;
    if (year === currentYear && quarterIndex + 1 > currentQuarter) return true;
    return false;
  };

  const handleQuarterClick = (index: number) => {
    if (isFutureQuarter(index, year)) return;
    setSelectedQuarter(index);
    onSelectQuarter(index + 1, year);
  };

  useEffect(() => {
    onSelectQuarter(selectedQuarter ? selectedQuarter + 1 : 1, year);
  }, [year, onSelectQuarter, selectedQuarter]);

  return (
    <Box textAlign="center" padding={'16px'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <IconButton
          aria-label="previous year"
          onClick={goToPreviousYear}
          icon={<AiOutlineLeft />}
          bg="transparent"
        />
        <Box as="h3">{year}</Box>
        <IconButton
          aria-label="next year"
          onClick={goToNextYear}
          icon={<AiOutlineRight />}
          bg="transparent"
          isDisabled={year >= currentYear}
        />
      </Box>
      <Grid
        templateColumns="1fr"
        gap={1}
        paddingLeft={'52px'}
        paddingRight={'52px'}
      >
        {quarters.map((quarter, index) => (
          <Box
            key={index}
            onClick={() => handleQuarterClick(index)}
            padding={'6px'}
            bg={
              selectedQuarter === index ||
              (selectedQuarter === null && filterTitle === quarter)
                ? 'gray.300'
                : 'transparent'
            }
            borderRadius={'md'}
            _hover={{ bg: isFutureQuarter(index, year) ? '' : 'gray.200' }}
            marginBottom={'8px'}
            color={isFutureQuarter(index, year) ? 'gray.400' : ''}
            cursor={isFutureQuarter(index, year) ? 'default' : 'pointer'}
          >
            {quarter}
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default Quarter;
