export function SecurityIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 7.66642L5.83333 8.99975L8.83333 5.99975M11.8333 7.99975C11.8333 11.272 8.26402 13.652 6.96532 14.4097C6.81773 14.4958 6.74393 14.5388 6.63978 14.5612C6.55896 14.5785 6.44104 14.5785 6.36021 14.5612C6.25606 14.5388 6.18227 14.4958 6.03467 14.4097C4.73597 13.652 1.16666 11.272 1.16666 7.99975V4.81149C1.16666 4.27848 1.16666 4.01197 1.25384 3.78289C1.33085 3.58051 1.45599 3.39994 1.61844 3.25677C1.80233 3.09471 2.05186 3.00113 2.55093 2.81398L6.12547 1.47353C6.26406 1.42156 6.33336 1.39557 6.40465 1.38527C6.46789 1.37613 6.53211 1.37613 6.59534 1.38527C6.66663 1.39557 6.73593 1.42156 6.87453 1.47353L10.4491 2.81398C10.9481 3.00113 11.1977 3.09471 11.3816 3.25677C11.544 3.39994 11.6691 3.58051 11.7462 3.78289C11.8333 4.01197 11.8333 4.27848 11.8333 4.81149V7.99975Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
