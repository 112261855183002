export const getColor = (delay: number) =>
  delay > 20
    ? '#D83B01'
    : delay === 0
    ? '#16A416'
    : delay <= 20
    ? '#718096'
    : '';

export const projectTabList = [
  {
    id: 'timeline',
    title: 'Epic Timeline',
  },
  {
    id: 'issues',
    title: 'Issues',
  },
  {
    id: 'teams',
    title: 'Teams',
  },
  {
    id: 'contributors',
    title: 'Contributors',
  },
  {
    id: 'sprint',
    title: 'Sprint',
  },
  {
    id: 'changeLog',
    title: 'Change Log',
  },
  {
    id: 'updates',
    title: 'Updates',
  },
];

export const updatesModalStatusOptions = [
  { value: 'On Track', text: 'On Track' },
  { value: 'At Risk', text: 'At Risk' },
  { value: 'Off Track', text: 'Off Track' },
];

export const propsConfig = {
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      _hover: {
        background: 'primary',
      },
    },
    isInRangeBtnProps: {
      color: 'primary',
    },
    selectedBtnProps: {
      background: 'primary',
      color: 'text.primary',
    },
    todayBtnProps: {
      background: 'primary',
    },
  },
  inputProps: {
    size: 'md',
    width: '100%',
  },

  popoverCompProps: {
    popoverContentProps: {
      background: 'white',
    },
  },
};

export const getFormattedDate = (date: Date) => {
  const formattedDate =
    date.toLocaleDateString('en-US', { year: 'numeric' }) +
    '-' +
    date.toLocaleDateString('en-US', { month: '2-digit' }) +
    '-' +
    date.toLocaleDateString('en-US', { day: '2-digit' });
  return formattedDate;
};

export const camelCaseToCapitalize = (str: string) =>
  str
    ?.replace(/([a-z])([A-Z])/g, '$1 $2')
    ?.replace(/^./, (match) => match.toUpperCase());

export const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Not Started', value: 'NotStarted' },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'In Progress',
    value: 'InProgress',
  },
];

export const colors = [
  '#0095E6',
  '#49CC93',
  '#EC4899',
  '#5F50A9',
  '#FFC532',
  '#FF7C65',
];
