import { apiClient } from '@devd-client/api';

export const addFavourite = (params: any): Promise<any> =>
  apiClient(`/v1/user/${localStorage.getItem('userId')}/preference/metrics`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params.data),
  });

export const deleteFavourite = (params: any): Promise<any> => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/metrics/${
      params.data.name
    }`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const deleteFavTeam = (params: any): Promise<any> => {
  return apiClient(
    `/v1/team/metric/org/${localStorage.getItem('orgId')}/team/${
      params.team
    }/metric/${params.metric}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const postFavMetrics = (params: any) => {
  return apiClient(
    `/v1/team/metric/org/${localStorage.getItem('orgId')}/team/${params.team}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.metricsList),
    }
  );
};

export const fetchThresholdData = (metric: string, teamId: string) => {
  return apiClient(
    `/v1/threshold/org/${localStorage.getItem('orgId')}/metric/${metric}${
      teamId !== '' ? `?team=${teamId}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const postThresholdData = (params: any) => {
  return apiClient(
    `/v1/threshold/org/${localStorage.getItem('orgId')}${
      params.teamId !== 'Org' ? `?team=${params.teamId}` : ''
    }`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.body),
    }
  );
};

export const resetThresholdData = (metric: string) => {
  return apiClient(`/v1/threshold/metric/${metric}/default`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
};

export const getAllRepos = (): Promise<any> =>
  apiClient(
    `/v1/integration/git/org/${localStorage.getItem(
      'orgId'
    )}/projects/lite?active=true`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
