import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import PieChartData from './PieChartData';
import StackBarChart from './StackBarChart';

const ChartCardContainer = styled.div`
  display: flex;
  height: 284px;
  width: 100%;
  background-color: #fff;
  padding: 12px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ChartHeading = styled.h2`
  font-family: 'Inter';
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const ChartCard = () => {
  return (
    <ChartCardContainer>
      <Box w={'70%'}>
        <ChartHeading>Investment distribution</ChartHeading>
        <StackBarChart />
      </Box>

      <Box w={'30%'} h={'100%'}>
        <PieChartData />
      </Box>
    </ChartCardContainer>
  );
};

export default ChartCard;
