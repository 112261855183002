import { apiClient } from '@devd-client/api';

export const getContributors = (
  searchInput: string,
  currentPage: number
): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem(
      'orgId'
    )}/contributors${`?pageNo=${currentPage}&pageSize=${20}`} ${
      searchInput ? `&searchTerm=${searchInput}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
