import { apiClient } from '@devd-client/api';

export const getIntegrationGitDetails = (
  id: string,
  integrationName: string
): Promise<any> =>
  apiClient(
    `/v1/repo/${integrationName}/${id}/org/${localStorage.getItem('orgId')}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const excludeBranches = (params: any): Promise<any> => {
  return apiClient(
    `/v1/repo/${params?.integrationName}/${
      params?.id2
    }/org/${localStorage.getItem('orgId')}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params?.data),
    }
  );
};
