import {
  HiOutlineArrowTrendingDown,
  HiOutlineArrowTrendingUp,
} from 'react-icons/hi2';

import { Flex, Text, Tooltip, useDisclosure, useTheme } from '@chakra-ui/react';

import {
  CFR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  ISSUE_CHART_KEYS,
  NumberStatus,
  PR_CHART_KEYS,
  checkNumber,
} from '../shared/utils';
import { Element, Wrapper } from './StatsTable.styled';
import { useState } from 'react';
import MetricSidebar from '../charts/components/MetricSidebar';
import moment from 'moment';

type StatsDataType = {
  metric: string;
  currentData: string;
  chartMetadata: { chartTitle: string };
  previousData: {
    prevAvgValue: number | null;
    percentChange: number | null;
    improved: boolean | null;
  };
};

interface StatsTableProps {
  statsData: StatsDataType[];
  selected: any;
  teamId: string;
  emailId: string;
}

export const StatsTable: React.FC<StatsTableProps> = ({
  statsData,
  selected,
  teamId,
  emailId,
}) => {
  const { colors } = useTheme();

  const teammodal = useDisclosure();
  const emailmodal = useDisclosure();

  const [metricsName, setMetricsName] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  return (
    <Wrapper>
      {statsData?.map((stats: StatsDataType) => (
        <Element key={stats?.metric}>
          <Flex height="full" align="center" justify="space-between">
            <Text
              fontFamily="heading"
              fontSize="md"
              color="text.primary"
              fontWeight="medium"
            >
              {stats?.chartMetadata?.chartTitle}
            </Text>

            <Flex direction="column" align="flex-end">
              <Tooltip label="View details" aria-label="View details">
                <Text
                  cursor="pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setMetricsName(stats?.metric);
                    setHeading(stats?.chartMetadata?.chartTitle);
                    if (teamId) {
                      teammodal.onOpen();
                    } else {
                      emailmodal.onOpen();
                    }
                  }}
                  ml={2}
                  fontFamily="heading"
                  fontSize="md"
                  color="text.primary"
                  fontWeight="bold"
                  textDecoration="none"
                  transition="text-decoration 0.2s ease-in-out"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  _focus={{
                    outline: 'none',
                    boxShadow: 'outline',
                  }}
                >
                  {stats?.currentData}
                </Text>
              </Tooltip>
              {typeof stats?.previousData?.percentChange !== null &&
                stats?.previousData?.percentChange !== 0 && (
                  <>
                    {checkNumber(stats?.previousData?.percentChange) ===
                    NumberStatus.positive ? (
                      <Flex gap={1}>
                        <Text
                          fontSize="xs"
                          fontFamily="heading"
                          color={
                            stats?.previousData?.improved ? 'success' : 'error'
                          }
                        >
                          {stats?.previousData?.percentChange}%
                        </Text>

                        <HiOutlineArrowTrendingUp
                          size={16}
                          color={
                            stats?.previousData?.improved
                              ? colors.success
                              : colors.error
                          }
                        />
                      </Flex>
                    ) : checkNumber(stats?.previousData?.percentChange) ===
                      NumberStatus.negative ? (
                      <Flex gap={1}>
                        <Text
                          fontSize="xs"
                          fontFamily="heading"
                          color={
                            stats?.previousData?.improved ? 'success' : 'error'
                          }
                        >
                          {stats?.previousData?.percentChange}%
                        </Text>

                        <HiOutlineArrowTrendingDown
                          size={16}
                          color={
                            stats?.previousData?.improved
                              ? colors.success
                              : colors.error
                          }
                        />
                      </Flex>
                    ) : (
                      <></>
                    )}
                  </>
                )}
            </Flex>
          </Flex>
        </Element>
      ))}

      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
      ].includes(metricsName) &&
        emailmodal.isOpen && (
          <MetricSidebar
            openDrawer={emailmodal.isOpen}
            handleDrawerClose={emailmodal.onClose}
            heading={heading}
            subHeading={`${moment(selected.startDate).format(
              'DD MMM'
            )} - ${moment(selected.endDate).format('DD MMM')}
           `}
            payload={{
              payload: {
                startDate: selected.startDate,
                endDate: selected.endDate,
              },
            }}
            currentChartMeta={{ chartKey: metricsName }}
            filterBy={{
              author: emailId,
            }}
            selectedTeam={''}
          />
        )}
    </Wrapper>
  );
};
