import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useCycletimePage } from '../../api';
import CycleTimePage from './devd-cycle-time';

export const CycleTime = () => {
  const { data } = useCycletimePage();

  return (
    <>
      <MetaTags title="Cycle Time | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <CycleTimePage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default CycleTime;
