import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';

const QUERY_KEYS = {
  verifyToken: 'verifyToken',
  resetPassword: 'resetPassword',
  ordinals: 'ordinals',
};

export const useVerifyToken = (code: string) => {
  return useQuery([QUERY_KEYS.verifyToken, code], () =>
    ENDPOINTS.verifyResetPasswordToken(code)
  );
};

export const useResetPassword = () => {
  return useCustomMutation([QUERY_KEYS.resetPassword], ENDPOINTS.resetPassword);
};

export const useFetchOrdinals = () => {
  return useQuery([QUERY_KEYS.ordinals], ENDPOINTS.getOrdinalSteps);
};
