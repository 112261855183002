import { FC } from 'react';
import { useTheme } from '@chakra-ui/react';
import {
  CustomSelect,
  LeftElements,
  RightElements,
  SearchBox,
  SearchIconContainer,
  SearchInput,
  StyledSearchIcon,
  ToolbarContainer,
} from '@devd-client/devd/components';

const UpdatesToolbar: FC = () => {
  const theme = useTheme();
  return (
    <ToolbarContainer>
      <RightElements>
        {/* <CustomSelect
          size="sm"
          label="Team"
          defaultItem={{ value: '', label: 'All' }}
        /> */}
      </RightElements>

      <LeftElements>
        {/* <SearchBox>
          <SearchInput placeholder="Search" />
          <SearchIconContainer>
            <StyledSearchIcon />
          </SearchIconContainer>
        </SearchBox> */}
      </LeftElements>
    </ToolbarContainer>
  );
};

export default UpdatesToolbar;
