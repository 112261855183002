export function FortranIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 32 32"
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="g3768">
        <rect
          id="rect881"
          x="2.34"
          y="2.34"
          width="27.33"
          height="27.33"
          rx="5.08"
          style={{ fill: '#734f96' }}
        />

        <path
          id="path1412"
          d="M6.06,25.5c0-1.09,0-1.19.1-1.19l1.25-.06c1,0,1.18-.06,1.42-.19a.74.74,0,0,0,.39-.37c.12-.23.12-.29.12-7.72,0-7,0-7.5-.1-7.67a1.06,1.06,0,0,0-.63-.5,10.23,10.23,0,0,0-1.38-.1l-1.17,0V5.32H25.94v8.82H24.89a8.56,8.56,0,0,1-1.2-.05c-.14,0-.15-.05-.22-.95a12.73,12.73,0,0,0-.35-2.43,3.22,3.22,0,0,0-2.63-2.81c-.57-.1-3.1-.2-5.19-.2H13.87v6.63l.75,0A6.33,6.33,0,0,0,16.49,14a1.88,1.88,0,0,0,.89-.91,5.86,5.86,0,0,0,.39-1.75c0-.33.08-.66.1-.73s0-.13,1.18-.13H20.2V21.06H17.85l0-.61a7.85,7.85,0,0,0-.54-2.66c-.36-.68-1.05-1-2.45-1.08l-.76-.07-.2,0,0,3.34c0,3.51,0,3.65.3,3.94s.43.32,3.22.42c.05,0,.07.28.07,1.19v1.18H6.06Z"
          style={{ fill: '#fff' }}
        />
      </g>
    </svg>
  );
}

export default FortranIcon;
