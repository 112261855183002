import { FC, useContext } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { GoalsContext } from '../../../context/goals.context';
import { useGoalsChartGql } from '../../../graphql';
import {
  CalendarContainer,
  ChartContainer,
  ChartHeading,
  ChartSectionContainer,
  ChartSubheading,
  Name,
  NameContainer,
} from './ChartSection.styled';
import {
  Loader,
  ISelect,
  useQueryState,
  PeriodType,
  DateFilter,
} from '@devd-client/devd/components';
import GoalChart from './GoalChart';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';

interface ChartSelectionProps {
  selectedTeam: ISelect;
  setSelectedTeam: (selectedTeam: ISelect) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  periodOptions: PeriodType[];
  setPeriodOptions: (periodOptions: PeriodType[]) => void;
}

const ChartSection: FC<ChartSelectionProps> = ({
  selectedTeam,
  setSelectedTeam,
  selected,
  setSelected,
  periodOptions,
  setPeriodOptions,
}) => {
  const {
    state: { clickedGoal },
  } = useContext(GoalsContext);

  const [appState, dispatch] = useAuth();

  const { data, isLoading } = useGoalsChartGql(
    clickedGoal?.name,
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected?.sprintId
  );

  return (
    <>
      <ChartSectionContainer>
        <ChartHeading>
          <NameContainer>
            <Name>{data?.chartMetadata?.chartTitle}</Name>
            {/* <AiOutlineInfoCircle fontSize="16px" color="#718096" /> */}
          </NameContainer>

          <CalendarContainer>
            <DateFilter
              getUser={getUser}
              appState={appState}
              dispatch={dispatch}
              AuthActionTypes={AuthActionTypes}
            />
          </CalendarContainer>
        </ChartHeading>

        <ChartSubheading>
          {data?.chartMetadata?.chartTitle &&
            `About the ${data?.chartMetadata?.chartTitle}`}
        </ChartSubheading>
        <ChartContainer>
          <GoalChart
            data={data}
            selectedTeam={selectedTeam.value}
            showLabel
            selected={selected}
          />
        </ChartContainer>
      </ChartSectionContainer>
      {isLoading && <Loader />}
    </>
  );
};

export default ChartSection;
