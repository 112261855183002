import {
  Box,
  Flex,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useTheme,
  Button,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import InsightCardItem from './InsightCardItem';
import { BsArrowRight, BsQuestionCircle } from 'react-icons/bs';
import { beforeStyles, hoverBeforeStyles } from '../InsightCards.utils';
import { Link as NavLink } from 'react-router-dom';
import { AppTooltip } from '@devd-client/devd/components';

type InsightDataType = {
  title: string;
  subTitle: string;
  link: string;
  data: {
    count: number;
    title: string;
    subTitle: string;
    link: string;
    category: string;
  }[];
  chartDataState: any;
  chartError: {
    message: string;
    link: string;
  };
};

interface InsightCardProps {
  data: InsightDataType;
  loading: boolean;
  team?: string;
}

const InsightCard: React.FC<InsightCardProps> = ({ data, loading, team }) => {
  const { colors } = useTheme();

  return (
    <>
      {loading ? (
        <Box position={'relative'} minWidth="100%" height="248px">
          <Skeleton h="248px" w="100%" borderRadius={'4px'} />
          <Spinner position={'absolute'} top={'40%'} left={'43%'} />
        </Box>
      ) : (
        <Stack
          spacing={4}
          p={3}
          w="full"
          minW="230px"
          minH="248px"
          borderRadius="4px"
          border="1px solid #dde0e4"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
        >
          <Flex align="center" justify="space-between" w="full">
            <Flex align="center">
              <Text
                fontFamily="heading"
                fontWeight="semibold"
                color="text.primary"
              >
                {data?.title}
              </Text>

              <Tooltip
                aria-label="tooltip"
                hasArrow
                placement="top"
                label={data?.subTitle}
              >
                <Box ml={1}>
                  <BsQuestionCircle size={12} color={colors.text.primary} />
                </Box>
              </Tooltip>
            </Flex>
            {data?.chartDataState !== 'ERROR' &&
              data?.chartDataState !== 'NOT_CONFIGURED' && (
                <Flex
                  as={NavLink}
                  to={
                    data?.link.includes('activity-log')
                      ? `${data?.link}`
                      : `${data?.link}${
                          data?.link && data.link.includes('?')
                            ? `&${window.location.href.split('?')[1]}`
                            : `?${window.location.href.split('?')[1]}`
                        }`
                  }
                  align="center"
                  justify="space-between"
                  color="text.secondary"
                  cursor="pointer"
                  position="relative"
                  fontSize="sm"
                  _hover={{ _before: hoverBeforeStyles }}
                  _before={beforeStyles}
                >
                  <Text
                    fontSize="xs"
                    fontWeight="semibold"
                    transition="all 300ms linear"
                    mr={1}
                  >
                    View Details
                  </Text>
                  <BsArrowRight />
                </Flex>
              )}

            {(data?.chartDataState === 'ERROR' ||
              data?.chartDataState === 'NOT_CONFIGURED') && (
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Text
                      color="text.primary"
                      fontFamily="heading"
                      fontSize="sm"
                      mr={1}
                    >
                      {data?.chartError?.message}
                    </Text>
                    <NavLink to={`${data?.chartError?.link}`}>
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </NavLink>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'xs'}
                    color={'error'}
                    border={'1px'}
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            )}
          </Flex>

          <Stack spacing={2} w="full">
            {data?.data?.map((item: any, idx: number) => (
              <InsightCardItem key={idx} insightItem={item} />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default InsightCard;
