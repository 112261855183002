import styled, { css } from 'styled-components';

type GoalCardItemType = {
  selected?: boolean;
};

type DotType = {
  color?: string;
};

export const GoalCardItemContainer = styled.div<GoalCardItemType>`
  min-height: 57px;
  width: 100%;
  border-radius: 2px;
  background-color: #fff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  padding-left: 16px;
  margin-bottom: 12px;
  margin-top: 4px;
  position: relative;
  user-select: none;

  overflow: hidden;
  cursor: pointer;
  filter: ${({ selected }) =>
    selected && 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15))'};
  transition: all 0.2s;

  &:hover {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
  }

  ${({ selected }) =>
    selected === true &&
    css`
      &::before {
        content: '';
        height: 100%;
        width: 2%;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 0;
        left: 0;
      }
    `};
`;

export const TagLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 8px;
  letter-spacing: 0.5px;
  line-height: 10px;
  padding: 2px 4px;
  color: ${({ theme }) => theme.colors.text.secondary};
  background-color: #f6f7f9;
  margin-right: 5px;
`;

export const TagLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardText = styled.h3`
  margin-top: 6px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 15px;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 80px;
`;

export const Dot = styled.div<DotType>`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 15px;
  margin-left: 4px;
`;
