export function DocsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="book-open-01">
        <path
          id="Icon"
          d="M7.99967 14L7.93297 13.8999C7.46988 13.2053 7.23833 12.858 6.93241 12.6065C6.66158 12.3839 6.34952 12.2169 6.01408 12.1151C5.63517 12 5.21775 12 4.38289 12H3.46634C2.7196 12 2.34624 12 2.06102 11.8547C1.81014 11.7268 1.60616 11.5229 1.47833 11.272C1.33301 10.9868 1.33301 10.6134 1.33301 9.86667V4.13333C1.33301 3.3866 1.33301 3.01323 1.47833 2.72801C1.60616 2.47713 1.81014 2.27316 2.06102 2.14532C2.34624 2 2.7196 2 3.46634 2H3.73301C5.22648 2 5.97322 2 6.54365 2.29065C7.04541 2.54631 7.45336 2.95426 7.70903 3.45603C7.99967 4.02646 7.99967 4.77319 7.99967 6.26667M7.99967 14V6.26667M7.99967 14L8.06638 13.8999C8.52947 13.2053 8.76102 12.858 9.06694 12.6065C9.33777 12.3839 9.64983 12.2169 9.98527 12.1151C10.3642 12 10.7816 12 11.6165 12H12.533C13.2797 12 13.6531 12 13.9383 11.8547C14.1892 11.7268 14.3932 11.5229 14.521 11.272C14.6663 10.9868 14.6663 10.6134 14.6663 9.86667V4.13333C14.6663 3.3866 14.6663 3.01323 14.521 2.72801C14.3932 2.47713 14.1892 2.27316 13.9383 2.14532C13.6531 2 13.2797 2 12.533 2H12.2663C10.7729 2 10.0261 2 9.4557 2.29065C8.95393 2.54631 8.54599 2.95426 8.29032 3.45603C7.99967 4.02646 7.99967 4.77319 7.99967 6.26667"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}
