import moment from 'moment';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  issueFilters: Yup.array()
    .of(
      Yup.object().shape({
        criteriaFields: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string().required('Name is required'),
              value: Yup.string().test(
                'value-required',
                'Value is required',
                function (value) {
                  const { name } = this.parent;

                  return (
                    name === 'Date Range' || name === 'Custom Field' || !!value
                  );
                }
              ),
              dateRange: Yup.object()
                .shape({
                  startDate: Yup.date()
                    .nullable()
                    .max(
                      Yup.ref('endDate'),
                      `Start date can't be after end date`
                    )
                    .notRequired()
                    .test(
                      'within-3-months',
                      'Must be within 3 months of end date',
                      function (startDate) {
                        const { endDate } = this.parent;
                        if (!startDate || !endDate) return true;
                        return (
                          moment(endDate).diff(moment(startDate), 'months') <= 3
                        );
                      }
                    ),
                  endDate: Yup.date()
                    .nullable()
                    .min(
                      Yup.ref('startDate'),
                      `End date can't be before start date`
                    )
                    .notRequired()
                    .test(
                      'within-3-months',
                      'Must be within 3 months of start date',
                      function (endDate) {
                        const { startDate } = this.parent;
                        if (!endDate || !startDate) return true;
                        return (
                          moment(endDate).diff(moment(startDate), 'months') <= 3
                        );
                      }
                    ),
                })
                .nullable()
                .notRequired(),

              customField: Yup.object()
                .shape({
                  name: Yup.string().nullable(),
                  value: Yup.string()
                    .nullable()
                    .test(
                      'custom-field-value-required',
                      'Custom field value is required',
                      function (value) {
                        const { name } = this.parent;
                        if (!name) return true;
                        return !!value;
                      }
                    ),
                })
                .nullable()
                .notRequired(),
            })
          )
          .required('At least one criteria field is required'),
      })
    )
    .required('At least one issue filter is required'),
});
