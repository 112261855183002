export function Focus({ color }: { color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM16.5 9h-3M4.5 9h-3M9 4.5v-3M9 16.5v-3"
      ></path>
    </svg>
  );
}

export default Focus;
