import { Box, Divider, Flex, Text, useTheme } from '@chakra-ui/react';
import { CustomMetricFilter } from '@devd-client/devd/components';
import { Fragment } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

type Props = {
  data: any;
  options: any;
  setSelectedMetrics: any;
  selectedMetrics: any;
};

const SelectMetricColumn = ({
  data,
  options,
  setSelectedMetrics,
  selectedMetrics,
}: Props) => {
  const { colors } = useTheme();
  return (
    <Flex direction="column" minW="318px" flex={1}>
      <Flex
        position={'sticky'}
        top={0}
        minH="52px"
        borderLeft="1px solid #E0E0E0"
        width="100%"
        align="center"
        bg="#FBFBFB"
        zIndex={50}
        px={4}
      >
        <CustomMetricFilter
          options={options}
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
          popupPlacement={'bottom-end'}
        >
          <Flex
            w="100%"
            cursor="pointer"
            align="center"
            justify="space-between"
          >
            <Text
              fontFamily={'heading'}
              fontSize="xs"
              fontWeight="medium"
              color="text.secondary2"
            >
              {'Select Metrics'}
            </Text>
            <MdOutlineKeyboardArrowDown
              size={18}
              color={colors.text.secondary2}
            />
          </Flex>
        </CustomMetricFilter>
      </Flex>

      <Divider />
      {data?.map((row: any, idx: number) => (
        <Fragment key={`col-${idx}`}>
          <Flex h="52px" align="center" w="full">
            <Box borderLeft="1px solid #E0E0E0" w="full" h="52px" p={4}>
              _
            </Box>
          </Flex>
          <Divider />
        </Fragment>
      ))}
    </Flex>
  );
};

export default SelectMetricColumn;
