import { Box, BoxProps, Flex, SkeletonCircle } from '@chakra-ui/react';

interface MilestoneItemProps extends BoxProps {
  icon?: any;
  boxProps?: BoxProps;
  skipTrail?: boolean;
}
const MilestoneItemSkeleton: React.FC<MilestoneItemProps> = ({
  icon,
  boxProps = {},
  skipTrail = false,
  children,
  ...props
}) => {
  return (
    <Flex minH={24} {...props}>
      <Flex flexDir="column" alignItems="center" mr={4} pos="relative">
        <SkeletonCircle size="10" />
        {!skipTrail && (
          <Box w="1px" minH={'80px'} flex={1} bg={'gray.400'} my={1} />
        )}
      </Flex>
      <Box pt={{ base: 1, sm: 3 }} {...boxProps}>
        {children}
      </Box>
    </Flex>
  );
};
export default MilestoneItemSkeleton;
