import React, { FC } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import {
  DeleteClickType,
  InvestmentCategory,
} from '../helpers/investment-categories.types';
import { ConfirmButton } from '@devd-client/devd/components';
import { useStrings } from '../helpers/investment-categories.strings';

interface InvestmentCategoriesItemProps {
  item: InvestmentCategory;
  handleEditButtonOpen: () => void;
  handleDeleteClick: (item: DeleteClickType) => void;
  setSelectedForEdit: (selectedItem: InvestmentCategory) => void;
}

const InvestmentCategoriesItem: FC<InvestmentCategoriesItemProps> = ({
  item,
  setSelectedForEdit,
  handleEditButtonOpen,
  handleDeleteClick,
}) => {
  const strings = useStrings();
  return (
    <Box
      as="div"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      p={4}
    >
      <Box
        as="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          as="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
          borderRadius="full"
          bg={item.color}
          w={2}
          h={2}
        />
        <Text ml={4} fontSize={'16px'} fontWeight={'bold'}>
          {item.category}
        </Text>
      </Box>
      <Box>
        <Button
          variant={'primary'}
          mr={4}
          size="sm"
          onClick={() => {
            setSelectedForEdit(item);
            handleEditButtonOpen();
          }}
        >
          {strings.Edit}
        </Button>
        <ConfirmButton
          onSuccessAction={() =>
            handleDeleteClick({
              integration: item.integration,
              category: item.category,
              type: item.type,
            })
          }
          isDanger
          buttonText="Delete"
          headerText="Delete category?"
          bodyText="Are you sure you want to delete this investment category?"
        />
      </Box>
    </Box>
  );
};

export default React.memo(InvestmentCategoriesItem);
