import { useTheme } from '@chakra-ui/react';
import React, { FC, Fragment, useContext } from 'react';
import styled from 'styled-components';
import { InvestmentDataType } from '../../../../helpers/Investments.types';
import { InvestmentsContext } from '../../../../context/investmentContext';

type ItemNameProps = {
  color?: string;
  selected?: boolean;
};

const LeftSideColumnContainer = styled.div`
  width: 200px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ItemName = styled.p<ItemNameProps>`
  font-size: 12px;
  font-weight: 700;
  font-family: 'Manrope';
  color: ${({ color }) => color || '#333'};
  padding: 7.5px;
  background-color: ${({ selected }) => (selected ? '#f5f5f5' : 'none')};
  border-radius: 4px;
  cursor: pointer;
`;

interface LeftSideColumnProps {
  selectedData: string;
  setSelectedData: (selected: string) => void;
}

const LeftSideColumn: FC<LeftSideColumnProps> = ({
  selectedData,
  setSelectedData,
}) => {
  const { colors } = useTheme();

  const {
    state: { weeklyData },
  } = useContext(InvestmentsContext);

  return (
    <LeftSideColumnContainer>
      {weeklyData[0]?.rows?.map((data: InvestmentDataType, idx: number) => (
        <Fragment key={idx}>
          <ItemName
            color={colors.primary}
            selected={selectedData === data.name}
            onClick={() => setSelectedData(data.name)}
          >
            {data.name}
          </ItemName>
          <Divider />
        </Fragment>
      ))}
    </LeftSideColumnContainer>
  );
};

export default React.memo(LeftSideColumn);
