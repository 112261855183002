export const viewSwitchObj = [
  {
    tab: 'Day',
    tooltipText: 'Day',
    TabIcon: '',
  },
  {
    tab: 'Week',
    tooltipText: 'Week',
    TabIcon: '',
  },
  {
    tab: 'Month',
    tooltipText: 'Month',
    TabIcon: '',
  },
];

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.substring(1);

export const filterOptions = [
  { value: '', label: '-' },
  { value: 'PROJECT', label: 'Project' },
  { value: 'PRIORITY', label: 'Priority' },
];

export function convertToSingleDecimal(number: number): number {
  const parsedNumber: number = parseFloat(number.toString());

  if (isNaN(parsedNumber)) {
    return NaN;
  }

  const roundedNumber: string = parsedNumber.toFixed(1);

  return parseFloat(roundedNumber);
}

export const getViewForFilter = (
  metrics: string | null,
  dashboard: string
): { view: string } | null => {
  return metrics === 'PR_HEALTH_INDEXES'
    ? dashboard === 'dashboard'
      ? { view: 'git' }
      : { view: 'quality' }
    : metrics === 'ISSUE_CYCLE_TIME_VS_COUNT'
    ? dashboard === 'dashboard'
      ? { view: 'ticket' }
      : { view: 'velocity' }
    : null;
};
