export function SourceBranch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.17677 3.07322L9.57322 0.676753C9.73071 0.51926 9.99999 0.630802 9.99999 0.853529V5.64642C9.99999 5.86915 9.73071 5.98069 9.57322 5.8232L7.17677 3.42677C7.07914 3.32914 7.07914 3.17085 7.17677 3.07322ZM3.25 2.5C2.83579 2.5 2.5 2.83579 2.5 3.25C2.5 3.66421 2.83579 4 3.25 4C3.66421 4 4 3.66421 4 3.25C4 2.83579 3.66421 2.5 3.25 2.5ZM1 3.25C1 2.00736 2.00736 1 3.25 1C4.49264 1 5.5 2.00736 5.5 3.25C5.5 4.22966 4.87389 5.06309 4 5.37197V10.628C4.87389 10.9369 5.5 11.7703 5.5 12.75C5.5 13.9926 4.49264 15 3.25 15C2.00736 15 1 13.9926 1 12.75C1 11.7703 1.62611 10.9369 2.5 10.628V5.37197C1.62611 5.06309 1 4.22966 1 3.25ZM11 2.5H10V4H11C11.5523 4 12 4.44772 12 5V10.628C11.1261 10.9369 10.5 11.7703 10.5 12.75C10.5 13.9926 11.5074 15 12.75 15C13.9926 15 15 13.9926 15 12.75C15 11.7703 14.3739 10.9369 13.5 10.628V5C13.5 3.61929 12.3807 2.5 11 2.5ZM2.5 12.75C2.5 12.3358 2.83579 12 3.25 12C3.66421 12 4 12.3358 4 12.75C4 13.1642 3.66421 13.5 3.25 13.5C2.83579 13.5 2.5 13.1642 2.5 12.75ZM12 12.75C12 12.3358 12.3358 12 12.75 12C13.1642 12 13.5 12.3358 13.5 12.75C13.5 13.1642 13.1642 13.5 12.75 13.5C12.3358 13.5 12 13.1642 12 12.75Z"
        fill="#2A2A2F"
      />
    </svg>
  );
}

export default SourceBranch;
