import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';

import { FC, useState } from 'react';
import { useFetchAllPlans } from '../api';

import { Loader } from '../../../appLoader';
import { SubsPlanCard } from '../../../subsPlanCard/index';
import ViewSwitcher from '../../../viewSwitcher';
import DemoCalendarModal from '../../../DemoCalendarModal';

interface SubsPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const viewSwitchObj = [
  {
    tab: 'Monthly',
    tooltipText: 'Monthly',
    TabIcon: '',
  },
  {
    tab: 'Annual',
    tooltipText: 'Annual',
    TabIcon: '',
  },
];

const SubsPlanModal: FC<SubsPlanModalProps> = ({ isOpen, onClose }) => {
  const talkToSalesModal = useDisclosure();
  const [viewToggle, setViewToggle] = useState('Monthly');
  const { data: subsPlanData, isFetching } = useFetchAllPlans({
    duration: viewToggle,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent pb={4} rounded="sm" minH="50vh" minW={'760px'}>
        <ModalHeader fontFamily="Inter">{'Choose Plan'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Box mb={2} width="fit-content">
              <ViewSwitcher
                tabList={viewSwitchObj}
                currentTab={viewToggle}
                handleSwitch={setViewToggle}
              />
            </Box>
            <SimpleGrid
              columns={(subsPlanData?.dto && subsPlanData?.dto?.length) || 2}
              gap={4}
            >
              {subsPlanData?.dto &&
                subsPlanData?.dto?.map((item: any, idx: number) => (
                  <SubsPlanCard
                    key={`card-${idx}`}
                    item={item}
                    handleTalkToSales={() => {
                      talkToSalesModal.onOpen();
                    }}
                  />
                ))}
            </SimpleGrid>
          </Stack>
        </ModalBody>
        {isFetching && <Loader />}
        {talkToSalesModal.isOpen && (
          <DemoCalendarModal
            isOpen={talkToSalesModal.isOpen}
            onClose={() => {
              talkToSalesModal.onClose();
              onClose();
            }}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default SubsPlanModal;
