export const PasswordIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 23 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.90604 12.4158V10.4122H21.906V12.4158H1.90604ZM3.05604 6.35495L1.75604 5.60361L2.60604 4.10091H0.906036V2.59822H2.60604L1.75604 1.14562L3.05604 0.394272L3.90604 1.84687L4.75604 0.394272L6.05604 1.14562L5.20604 2.59822H6.90604V4.10091H5.20604L6.05604 5.60361L4.75604 6.35495L3.90604 4.85226L3.05604 6.35495ZM11.056 6.35495L9.75604 5.60361L10.606 4.10091H8.90604V2.59822H10.606L9.75604 1.14562L11.056 0.394272L11.906 1.84687L12.756 0.394272L14.056 1.14562L13.206 2.59822H14.906V4.10091H13.206L14.056 5.60361L12.756 6.35495L11.906 4.85226L11.056 6.35495ZM19.056 6.35495L17.756 5.60361L18.606 4.10091H16.906V2.59822H18.606L17.756 1.14562L19.056 0.394272L19.906 1.84687L20.756 0.394272L22.056 1.14562L21.206 2.59822H22.906V4.10091H21.206L22.056 5.60361L20.756 6.35495L19.906 4.85226L19.056 6.35495Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};
