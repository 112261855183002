import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { WorkflowAutomationContext } from '../../context/workflowAutomation.context';
import { FieldType } from '../../helpers/goals.types';
import { GoalsActionTypes } from '../../reducer/goals.reducer';
import GoalCards from './GoalCards';

const LeftSectionContainer = styled.div`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;

interface LeftSectionProps {
  selectedAction: string;
  setSelectedAction: (action: string) => void;
}

const LeftSection: FC<LeftSectionProps> = ({
  selectedAction,
  setSelectedAction,
}) => {
  const {
    state: { goalsList },
    dispatch,
  } = useContext(WorkflowAutomationContext);

  const [searchParams] = useSearchParams();
  const goal = searchParams.get('action');

  useEffect(() => {
    if (goalsList && goalsList.length === 0) return;
    if (!goal) setSelectedAction(goalsList[0]?.event);
  }, [goalsList?.[0]?.event, goal]);

  useEffect(() => {
    dispatch({
      type: GoalsActionTypes.SET_CLICKED_GOAL,
      payload: goalsList
        ?.filter((goal: any) => goal.event === selectedAction)
        .map((el: any) => el)[0],
    });
  }, [selectedAction, goalsList, dispatch]);

  const activeGoals = useMemo(
    () => goalsList?.filter((goal: any) => goal.enabled === true),
    [goalsList]
  );

  const otherGoals = useMemo(
    () => goalsList?.filter((goal: any) => goal.enabled === false),
    [goalsList]
  );

  return (
    <LeftSectionContainer>
      {activeGoals?.length > 0 && (
        <GoalCards
          goalHeader={'Active Workflows'}
          workflowsList={activeGoals}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
        />
      )}
      <GoalCards
        goalHeader={
          otherGoals?.length > 0
            ? 'Inactive Workflows'
            : 'No Inactive Workflows'
        }
        workflowsList={otherGoals}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
      />
    </LeftSectionContainer>
  );
};

export default LeftSection;
