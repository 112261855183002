import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ApiProvider } from '@devd-client/api';
import { IntlProvider } from 'react-intl';
import { AuthProvider } from '@devd-client/devd/auth';
import { ChakraProvider } from '@chakra-ui/react';
import React, { StrictMode } from 'react';

import { ThemeProvider } from 'styled-components';
import App from './app/app';

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
  Sentry.init({
    dsn: 'https://f6af0b91bc1740d68a59367297b57f15@o4505205026586624.ingest.sentry.io/4505205294104576',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.05,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
  });
}

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'fr':
      return import('./translations/fr.json');
    default:
      return import('./translations/en.json');
  }
}

function loadTheme(theme = 'devd') {
  switch (theme) {
    case 'devd':
    default:
      return import('./themes/app-default');
  }
}

async function bootstrapper(locale = 'en') {
  const messages = await loadLocaleData(locale);
  const theme = await loadTheme();
  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container!);
  root.render(
    <StrictMode>
      <ChakraProvider theme={theme.default}>
        <ThemeProvider theme={theme.default}>
          <IntlProvider
            locale={locale}
            defaultLocale="en"
            messages={messages.default}
          >
            <AuthProvider>
              <ApiProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ApiProvider>
            </AuthProvider>
          </IntlProvider>
        </ThemeProvider>
      </ChakraProvider>
    </StrictMode>
  );
}

bootstrapper();
