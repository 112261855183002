export const FireIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 64 64"
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* <style type="text/css">
	.st0{fill:#B4E6DD;}
	.st1{fill:#80D4C4;}
	.st2{fill:#D2F0EA;}
	.st3{fill:#FFFFFF;}
	.st4{fill:#FBD872;}
	.st5{fill:#DB7767;}
	.st6{fill:#F38E7A;}
	.st7{fill:#F6AF62;}
	.st8{fill:#32A48E;}
	.st9{fill:#A38FD8;}
	.st10{fill:#7C64BD;}
	.st11{fill:#EAA157;}
	.st12{fill:#9681CF;}
	.st13{fill:#F9C46A;}
	.st14{fill:#CE6B61;}
</style> */}

      <g>
        <path
          fill="#CE6B61"
          d="M21.04,20.63c0,0-1.79,8.99-0.96,11.47c0,0,1.1-13.6,11.67-24.1c0,0-0.34,8.79,5.15,13.25s6.73,11.4,6.73,11.4   s0.62-4.74-1.24-12.33c0,0,11.67,12.12,6.32,25.65c0,0-6.45,14.9-24.92,8.38S17.74,22.97,21.04,20.63z"
        />

        <path
          fill="#F9C46A"
          d="M40.75,32.12c0.17,3.81-1.55,4.81-2.94,5.01c-0.89,0.13-1.79-0.1-2.57-0.53c-7.65-4.15-3.72-14.29-3.72-14.29   c-5.01,4.79-2.4,14.74-2.4,14.74s1.55,5.64-1.62,6.13c-1.59,0.25-2.33-0.39-2.67-1.08c-0.31-0.64-0.31-1.39-0.04-2.05   c2.15-5.34,0.31-8.36,0.31-8.36c-1.03,3.06-2.12,5.08-2.91,6.29c-1.12,1.72-1.83,3.68-1.93,5.73C19.9,51.35,26.3,54.4,26.3,54.4   c10.51,5.36,15.23-4.44,15.23-4.44C46.96,39.52,40.75,32.12,40.75,32.12z"
        />
      </g>
    </svg>
  );
};

export default FireIcon;
