import { Flex } from '@chakra-ui/react';
import {
  CalendarViewIcon,
  PeriodType,
  TaskViewIcon,
} from '@devd-client/devd/components';
import { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { InvestmentsContext } from '../../context/investmentContext';
import { useInvestmentReportDayGql } from '../../graphql';
import BoxView from './BoxView';
import HeaderBar from './HeaderBar';
import InfoTopBar from './InfoTopbar';
import ListView from './ListView';

const InfoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: #fff;
  padding: 12px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
`;

interface InfoCardProps {
  viewSwitch: string;
  toggleTaskCalendar: string;
  setToggleTaskCalendar: (toggleTaskCalendar: string) => void;
  selectedTeam: string;
  selected: PeriodType;
}

export const viewSwitchObj = [
  {
    tab: 'Calendar view',
    tooltipText: 'Calendar view',
    TabIcon: CalendarViewIcon,
  },
  {
    tab: 'Task view',
    tooltipText: 'Task view',
    TabIcon: TaskViewIcon,
  },
];

const InfoCard: FC<InfoCardProps> = ({
  viewSwitch,
  toggleTaskCalendar,
  setToggleTaskCalendar,
  selectedTeam,
  selected,
}) => {
  const {
    state: { selectedChartData },
    dispatch,
  } = useContext(InvestmentsContext);

  useEffect(() => {
    setToggleTaskCalendar(viewSwitchObj[0].tab);
  }, []);

  const { isFetching } = useInvestmentReportDayGql(
    selectedChartData.startDate,
    selectedChartData.endDate,
    selected?.sprintId,
    'day',
    viewSwitch,
    selectedTeam,
    dispatch
  );

  return (
    <InfoCardContainer>
      <Flex justify={'space-between'} mb={6}>
        <HeaderBar />
        <InfoTopBar
          setViewToggle={setToggleTaskCalendar}
          viewSwitchObj={viewSwitchObj}
          viewToggle={toggleTaskCalendar}
          selectedTeam={selectedTeam}
        />
      </Flex>

      {toggleTaskCalendar === 'Calendar view' && (
        <ListView isFetching={isFetching} />
      )}

      {toggleTaskCalendar === 'Task view' && (
        <BoxView
          viewSwitch={viewSwitch}
          selectedTeam={selectedTeam}
          selected={selected}
        />
      )}
    </InfoCardContainer>
  );
};

export default InfoCard;
