import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Badge,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type Props = {
  tabs: { id: number; label: string }[];
  setCurrentTab: (tab: string) => void;
  currentTab: string;
  signalData: any;
};

const CircularTabs = ({
  tabs,
  setCurrentTab,
  currentTab,
  signalData,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setCurrentTab(tabs[selectedTab]?.label);
  }, [selectedTab]);

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.label === currentTab);
    if (index !== -1 && index !== selectedTab) {
      setSelectedTab(index);
    }
  }, [currentTab, tabs]);

  return (
    <Box textAlign="center">
      <Tabs
        mt={2}
        variant="unstyled"
        index={tabs.find((item: any) => item.label === currentTab)?.id || 0}
        onChange={(index) => setSelectedTab(index)}
      >
        <TabList justifyContent="center" gap="4">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              border="1px solid"
              borderColor="#E0E0E0"
              borderRadius="20px"
              w="fit-content"
              p={'8px 16px'}
              color="text.secondary3"
              fontWeight="medium"
              textAlign="center"
              _selected={{
                borderColor: 'primary',
                bg: 'primary2Bg',
                color: 'primary',
              }}
              transition={'0.3s all'}
              _hover={{ bg: 'primary2Bg' }}
            >
              {tab.label}{' '}
              <Badge
                ml={1}
                borderRadius="10px"
                color={currentTab === tab.label ? 'primary' : 'text.secondary3'}
                bg={currentTab === tab.label ? '#DBDBFF' : 'gray.100'}
              >
                {signalData?.filter((item: any) => item?.category === tab.label)
                  ?.length || signalData?.length}
              </Badge>
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  );
};

export default CircularTabs;
