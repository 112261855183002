export function SurveyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32 8C33.8599 8 34.7899 8 35.5529 8.20444C37.6235 8.75925 39.2408 10.3765 39.7956 12.4471C40 13.2101 40 14.1401 40 16V34.4C40 37.7603 40 39.4405 39.346 40.7239C38.7708 41.8529 37.8529 42.7708 36.7239 43.346C35.4405 44 33.7603 44 30.4 44H17.6C14.2397 44 12.5595 44 11.2761 43.346C10.1471 42.7708 9.2292 41.8529 8.65396 40.7239C8 39.4405 8 37.7603 8 34.4V16C8 14.1401 8 13.2101 8.20445 12.4471C8.75925 10.3765 10.3765 8.75925 12.4471 8.20444C13.2101 8 14.1401 8 16 8M18 30L22 34L31 25M19.2 12H28.8C29.9201 12 30.4802 12 30.908 11.782C31.2843 11.5903 31.5903 11.2843 31.782 10.908C32 10.4802 32 9.92011 32 8.8V7.2C32 6.0799 32 5.51984 31.782 5.09202C31.5903 4.71569 31.2843 4.40973 30.908 4.21799C30.4802 4 29.9201 4 28.8 4H19.2C18.0799 4 17.5198 4 17.092 4.21799C16.7157 4.40973 16.4097 4.71569 16.218 5.09202C16 5.51984 16 6.0799 16 7.2V8.8C16 9.92011 16 10.4802 16.218 10.908C16.4097 11.2843 16.7157 11.5903 17.092 11.782C17.5198 12 18.0799 12 19.2 12Z"
        stroke={props.color || 'black'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
