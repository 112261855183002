export function GitlabIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1605_351)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 19.375L14.8678 7.8374H7.13599L11.0038 19.375H11Z"
          fill="#E24329"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 19.375L7.13217 7.8374H1.71417L11 19.375Z"
          fill="#FC6D26"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.71411 7.84131L0.538113 11.3465C0.431204 11.6651 0.549567 12.0149 0.828295 12.2131L10.9999 19.375L1.71411 7.84131Z"
          fill="#FCA326"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.71417 7.84134H7.13217L4.80308 0.897041C4.68472 0.539528 4.16163 0.539528 4.04326 0.897041L1.71417 7.84134Z"
          fill="#E24329"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11 19.375L14.8678 7.8374H20.2858L11 19.375Z"
          fill="#FC6D26"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.2858 7.84131L21.4618 11.3465C21.5687 11.6651 21.4504 12.0149 21.1716 12.2131L11 19.3789L20.2858 7.84131Z"
          fill="#FCA326"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.2858 7.84132H14.8678L17.1969 0.893135C17.3153 0.535622 17.8383 0.535622 17.9567 0.893135L20.2858 7.84132Z"
          fill="#E24329"
        />
      </g>
      <defs>
        <clipPath id="clip0_1605_351">
          <rect
            width="21"
            height="18.75"
            fill="white"
            transform="translate(0.5 0.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GitlabIcon;
