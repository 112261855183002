import { MetaTags } from '@devd-client/devd/components';
import ReportPage from './devd-report';

export function Report() {
  return (
    <>
      <MetaTags title="Reports | Devdynamics" />
      <ReportPage />
    </>
  );
}

export default Report;
