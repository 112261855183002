import React, { FC } from 'react';
import styled from 'styled-components';

import ChangeLogToolbar from './ChangeLogToolbar';
import ChangeLogTable from './ChangeLogTable';

const ChangeLogContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ChangeLogProps {
  selectedProjectId: string;
}
const ChangeLog: FC<ChangeLogProps> = ({ selectedProjectId }) => {
  return (
    <ChangeLogContainer>
      <ChangeLogToolbar />
      <ChangeLogTable selectedProjectId={selectedProjectId} />
    </ChangeLogContainer>
  );
};

export default ChangeLog;
