export function DangerIcon() {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21.53">
      <g id="Layer_1-2" data-name="Layer_1">
        <g>
          <path
            className="cls-1"
            style={{ fill: '#ffad00' }}
            d="M3.95,21.53c-1.15,0-1.97,0-2.74-.56-.67-.49-1.11-1.25-1.19-2.07-.1-.95.32-1.66.89-2.66L8.95,2.34c.57-.99.99-1.7,1.86-2.09.38-.17.78-.25,1.19-.25s.82.09,1.19.25c.87.39,1.28,1.1,1.85,2.08l8.05,13.91c.58.99.99,1.71.89,2.66-.09.82-.52,1.58-1.19,2.07-.77.56-1.6.56-2.75.56H3.95Z"
          />
          <path
            className="cls-2"
            style={{
              fill: 'none',
              stroke: '#000',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.96px',
            }}
            d="M12,8.32v3.91M12,16.15h0M10.65,3.32L2.6,17.22c-.45.77-.67,1.16-.64,1.47.03.28.17.53.4.69.26.19.7.19,1.59.19h16.1c.89,0,1.34,0,1.59-.19.22-.16.37-.41.4-.69.03-.32-.19-.7-.64-1.47L13.35,3.32c-.44-.77-.67-1.15-.96-1.28-.25-.11-.54-.11-.8,0-.29.13-.51.51-.96,1.28Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default DangerIcon;
