import { useTheme } from '@chakra-ui/react';
import {
  CategoryData,
  CategoryItem,
  Data,
  Date,
  DateBox,
  Divider,
  HeadingBox,
  InfoDetailsCategoryData,
  InfoDetailsCategoryName,
  InfoDetailsContainer,
  InfoDetailsHeaderHeading,
  InfoDetailsItem,
  Name,
} from './InfoDetails.styled';
import { FC, Fragment, useContext } from 'react';
import {
  InvestmentDataType,
  RowType,
} from '../../../../helpers/Investments.types';
import { InvestmentsContext } from '../../../../context/investmentContext';
import moment from 'moment';

const InfoDetails: FC = () => {
  const { colors } = useTheme();

  const {
    state: { weeklyData },
  } = useContext(InvestmentsContext);

  return (
    <InfoDetailsContainer>
      <InfoDetailsCategoryName>
        <HeadingBox>
          <InfoDetailsHeaderHeading color={colors.text.secondary}>
            Name
          </InfoDetailsHeaderHeading>
        </HeadingBox>
        <Divider />
        {weeklyData[0]?.rows?.map((row: RowType) => (
          <Fragment key={row.name}>
            <CategoryItem>
              <Name color={colors.primary}>{row.name}</Name>
            </CategoryItem>
            <Divider />
          </Fragment>
        ))}
      </InfoDetailsCategoryName>

      {weeklyData?.map((data: InvestmentDataType, idx: number) => (
        <InfoDetailsCategoryData key={`date-${idx}`}>
          <DateBox>
            <Date color={colors.text.secondary}>
              {moment(data.name).format('DD ddd')}
            </Date>
          </DateBox>
          <Divider />
          {data?.rows?.map((row: RowType, idx: number) => (
            <Fragment key={`col-${idx}`}>
              <CategoryData>
                <InfoDetailsItem bgColorPercentage={`${row.value}%`}>
                  <Data color={colors.text.primary}>{`${row.value}%`}</Data>
                </InfoDetailsItem>
              </CategoryData>
              <Divider />
            </Fragment>
          ))}
        </InfoDetailsCategoryData>
      ))}
    </InfoDetailsContainer>
  );
};

export default InfoDetails;
