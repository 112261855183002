import { ISelect, PeriodType } from '@devd-client/devd/components';
import React, { FC } from 'react';
import styled from 'styled-components';
import ChartSection from './ChartSection';
import DetailsSection from './DetailsSection';

const RightSectionContainer = styled.div`
  flex: 0.8;
  min-width: 300px;
  flex-direction: column;
  padding: 0 24px;
`;

interface RightSectionProps {
  selectedTeam: ISelect;
  setSelectedTeam: (selectedTeam: ISelect) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  periodOptions: PeriodType[];
  setPeriodOptions: (periodOptions: PeriodType[]) => void;
}

const RightSection: FC<RightSectionProps> = ({
  selectedTeam,
  setSelectedTeam,
  selected,
  setSelected,
  periodOptions,
  setPeriodOptions,
}) => {
  return (
    <RightSectionContainer>
      <DetailsSection selectedTeam={selectedTeam} />
      <ChartSection
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        selected={selected}
        setSelected={setSelected}
        periodOptions={periodOptions}
        setPeriodOptions={setPeriodOptions}
      />
    </RightSectionContainer>
  );
};

export default RightSection;
