export type InvestmentsState = {
  weeklyData: null | any;
  chartMetaData: null | any;
  barChartData: null | any;
  pieChartData: null | any;
  selectedChartData: null | any;
  ticketData: null | any;
};

export enum InvestmentsActionTypes {
  CHART_META = '[Investments] Get chart meta data',
  WEEKLY_DATA = '[Investments] Get weekly data',
  BAR_CHART_DATA = '[Investments] Get bar chart data',
  PIE_CHART_DATA = '[Investments] Get pie chart data',
  TICKET_DATA = '[Investments] Get ticket chart data',
  SELECTED_CHART_DATA = '[Investments] Update selected chart data',
}

export type InvestmentsAction = {
  type: InvestmentsActionTypes;
  payload?: any;
};

export const investmentsReducer = (
  state: InvestmentsState,
  action: InvestmentsAction
): InvestmentsState => {
  switch (action.type) {
    case InvestmentsActionTypes.CHART_META:
      return { ...state, chartMetaData: action?.payload };

    case InvestmentsActionTypes.WEEKLY_DATA:
      return { ...state, weeklyData: action?.payload };

    case InvestmentsActionTypes.BAR_CHART_DATA:
      return { ...state, barChartData: action?.payload };

    case InvestmentsActionTypes.PIE_CHART_DATA:
      return { ...state, pieChartData: action?.payload };

    case InvestmentsActionTypes.SELECTED_CHART_DATA:
      return { ...state, selectedChartData: action?.payload };
    case InvestmentsActionTypes.TICKET_DATA:
      return { ...state, ticketData: action?.payload };

    default:
      return state;
  }
};
