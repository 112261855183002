import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const goalsGenericGraphql = async (
  templateId: any,
  metric: string,
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string
): Promise<any> => {
  return await graphQLClient.request(
    gql`
      query WorkflowAuditMetricsByType($templateId: String!, $filter: Filter!) {
        WorkflowAuditMetricsByType(templateId: $templateId, filter: $filter) {
          data
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
            dataType
            detailEntity
          }
          keys {
            key
            name
            color
            chartType
          }
          chartDataState
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      templateId: templateId,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        project: '',
        searchTerm: '',
        reviewer: '',
        author: '',
        sprintId: '',
        sprintIds: [],
        authors: [],
        type: '',
        status: '',
        label: '',
        projectsFilter: [],
      },
    }
  );
};
