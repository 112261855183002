export const WinIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <circle fill="#71E2EF" cx="256" cy="256" r="256" />
      <g>
        <path
          fill="#38C6D9"
          d="M468.661,398.551C496.028,357.807,512,308.77,512,256c0-10.895-0.686-21.63-2.007-32.168
		l-56.303-56.303l-65.35,38.109l-98.399-98.399l-2.946,37.138l-30.858-10.637l-30.408,21.882L335.988,265.88l-9.481,8.964
		L219.193,167.531l-44.346,39.498l-21.654-21.657l-86.971,37.441l273.382,273.382l-2.763,2.763
		c50.029-16.639,93.203-48.287,124.168-89.617l-65.939-65.939l9.223-9.223L468.661,398.551z"
        />
        <path
          fill="#38C6D9"
          d="M282.51,510.64c9.799-1.008,19.428-2.582,28.869-4.665L115.984,310.581l-10.252,23.283
		L282.51,510.64z"
        />
      </g>
      <path
        fill="#4076A3"
        d="M254.69,511.983l-134.87-196.98c-9.707-14.177-30.368-14.905-41.048-1.445l-49.181,61.988
	C72.337,456.335,157.043,511.493,254.69,511.983z"
      />
      <path
        fill="#386895"
        d="M119.82,315.002c-4.361-6.37-10.936-10-17.822-10.873v156.338
	c42.53,32.087,95.373,51.228,152.691,51.515L119.82,315.002z"
      />
      <rect
        x="225.728"
        y="111.949"
        fill="#C92F00"
        width="60.733"
        height="43.672"
      />
      <path
        fill="#930000"
        d="M286.456,249.689c-2.381,0-4.31-1.929-4.31-4.31V109.756c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31v135.623C290.766,247.76,288.837,249.689,286.456,249.689z"
      />
      <path
        fill="#273B7A"
        d="M309.45,221.337c-9.707-14.177-30.368-14.905-41.048-1.445L87.123,448.381
	C132.194,487.979,191.288,512,256,512c76.805,0,145.701-33.832,192.624-87.397L309.45,221.337z"
      />
      <path
        fill="#121149"
        d="M448.624,424.603L309.45,221.336c-5.267-7.692-13.759-11.39-22.132-11.045V510.08
	C351.404,502.258,408.18,470.773,448.624,424.603z"
      />
      <path
        fill="#386895"
        d="M256,512c112.188,0,207.51-72.171,242.098-172.616l-43.842-64.033
	c-9.707-14.177-30.368-14.905-41.048-1.445L225.73,510.211C235.66,511.379,245.757,512,256,512z"
      />
      <path
        fill="#273B7A"
        d="M498.098,339.384l-43.842-64.033c-6.177-9.023-16.787-12.574-26.441-10.511v180.915
	C459.455,417.087,483.899,380.621,498.098,339.384z"
      />
      <path
        fill="#FFFFFF"
        d="M187.906,233.024c22.216,0,40.224-18.01,40.224-40.224s-18.008-40.226-40.224-40.226
	c-0.412,0-0.819,0.019-1.227,0.031c-6.809-19.422-25.295-33.354-47.042-33.354c-25.943,0-47.249,19.82-49.621,45.144
	c-18.666,0.34-33.702,15.551-33.702,34.299c0,18.96,15.37,34.33,34.33,34.33H187.906z"
      />
      <path
        fill="#D0D1D3"
        d="M187.906,152.574c-0.412,0-0.819,0.019-1.227,0.031c-6.689-19.077-24.648-32.823-45.892-33.308
	v113.726h47.12c22.216,0,40.224-18.01,40.224-40.224S210.122,152.574,187.906,152.574z"
      />
      <path
        fill="#FFFFFF"
        d="M431.871,214.049c15.672,0,28.377-12.705,28.377-28.377s-12.705-28.377-28.377-28.377
	c-0.291,0-0.578,0.014-0.865,0.022c-4.805-13.702-17.844-23.531-33.187-23.531c-18.303,0-33.333,13.983-35.006,31.847
	c-13.169,0.24-23.776,10.971-23.776,24.197c0,13.376,10.843,24.219,24.219,24.219L431.871,214.049L431.871,214.049z"
      />
      <path
        fill="#D0D1D3"
        d="M431.871,157.293c-0.291,0-0.578,0.014-0.865,0.022c-4.73-13.493-17.455-23.211-32.494-23.504v80.237
	h33.361c15.672,0,28.377-12.705,28.377-28.377C460.248,169.998,447.543,157.293,431.871,157.293z"
      />
    </svg>
  );
};
