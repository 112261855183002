import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useDashboardStore } from '../../hooks/useDashboardStore';

interface MonthProps {
  year: number;
  goToPreviousYear: () => void;
  goToNextYear: () => void;
  onSelectMonth: (month: number, year: number) => void;
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const Month: React.FC<MonthProps> = ({
  year,
  goToPreviousYear,
  goToNextYear,
  onSelectMonth,
}) => {
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const { filterTitle } = useDashboardStore();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const isFutureMonth = (monthIndex: number, year: number) => {
    if (year > currentYear) return true;
    if (year === currentYear && monthIndex > currentMonth) return true;
    return false;
  };

  const handleMonthClick = (index: number) => {
    if (isFutureMonth(index, year)) return;
    setSelectedMonth(index);
    onSelectMonth(index, year);
  };

  useEffect(() => {
    onSelectMonth(selectedMonth || 0, year);
  }, [year, onSelectMonth, selectedMonth]);

  return (
    <Box textAlign="center" padding={'16px'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <IconButton
          aria-label="previous year"
          onClick={goToPreviousYear}
          icon={<AiOutlineLeft />}
          bg="transparent"
        />
        <Box as="h3">{year}</Box>
        <IconButton
          aria-label="next year"
          onClick={goToNextYear}
          icon={<AiOutlineRight />}
          bg="transparent"
          isDisabled={year >= currentYear}
        />
      </Box>
      <Grid templateColumns="repeat(3, 1fr)" gap={3}>
        {months.map((month, index) => (
          <Box
            key={index}
            as="button"
            onClick={() => handleMonthClick(index)}
            marginX={'20px'}
            marginBottom={'6px'}
            padding={'4px'}
            borderRadius={'md'}
            bg={
              selectedMonth === index ||
              (selectedMonth === null && filterTitle?.startsWith(month))
                ? 'gray.300'
                : 'transparent'
            }
            _hover={{ bg: isFutureMonth(index, year) ? '' : 'gray.200' }}
            color={isFutureMonth(index, year) ? 'gray.400' : ''}
            cursor={isFutureMonth(index, year) ? 'default' : 'pointer'}
          >
            {month}
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default Month;
