import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { TickIcon2 } from '../../icons';

interface FeatureItemProps {
  title: string;
  subtitle?: string;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ title, subtitle }) => {
  return (
    <Flex align="center">
      <TickIcon2 height={12} width={12} />
      <Flex align="center">
        <Text
          ml={3}
          fontSize="md"
          fontFamily="heading"
          color="text.secondary"
          fontWeight="bold"
        >
          {title}
        </Text>

        <Text fontSize="md" ml={1} fontWeight="normal" color="text.secondary">
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FeatureItem;
