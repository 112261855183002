export function Task(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z"
        fill="#4BADE8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6247 4.71917C12.056 5.06418 12.1259 5.69347 11.7809 6.12473L7.78091 11.1247C7.4359 11.556 6.8066 11.6259 6.37534 11.2809C5.94408 10.9359 5.87416 10.3066 6.21917 9.87534L10.2192 4.87534C10.5642 4.44408 11.1935 4.37416 11.6247 4.71917Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.29289 7.79289C4.68342 7.40237 5.31658 7.40237 5.70711 7.79289L7.70711 9.79289C8.09763 10.1834 8.09763 10.8166 7.70711 11.2071C7.31658 11.5976 6.68342 11.5976 6.29289 11.2071L4.29289 9.20711C3.90237 8.81658 3.90237 8.18342 4.29289 7.79289Z"
        fill="white"
      />
    </svg>
  );
}

export default Task;
