export function MarkdownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="markdown"
      {...props}
    >
      <path d="M1.731 19.5H22.27c.955 0 1.731-.788 1.731-1.757V6.257C24 5.288 23.224 4.5 22.269 4.5H1.731C.776 4.5 0 5.288 0 6.257v11.485c0 .97.776 1.758 1.731 1.758zM16.614 12V8.015h2.308V12h2.307l-3.462 4.102L14.306 12h2.308zM3.461 8.015h2.308l2.308 2.93 2.308-2.93h2.308v7.969h-2.308v-4.57l-2.308 2.93-2.308-2.93v4.57H3.461V8.015z"></path>
    </svg>
  );
}

export default MarkdownIcon;
