import { Navigate } from 'react-router';
import { useSignUp } from '@devd-client/api';
import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Flex, Link, Stack, Text, useTheme } from '@chakra-ui/react';
import {
  EmailIcon,
  Loader,
  OrganizationIcon,
  PasswordInput,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { FormLink } from './signup.styles';
import { ProfileIcon } from '@devd-client/devd/components';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../helpers/auth';
import { useAuth } from '../../context/devd-auth';
import { FIREBASE_ERRORS } from '../../helpers/firebaseErrors';

const initialValues = {
  firstName: '',
  lastName: '',
  organizationName: '',
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  organizationName: Yup.string().required('Organization name is required'),
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
  password: Yup.string()
    .min(12, 'Password must be at least 12 characters.')
    .max(128, 'Password must be lower than 128 characters.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*?\b(?:password|admin|user)\b).*$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    )
    .required('Password is required.'),
});

export function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [newToast] = useToastHook();
  const { colors } = useTheme();

  const { mutate: signup } = useSignUp();

  const [appState] = useAuth();

  const handleLogin = (email: string, password: string) => {
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoading(false);
        setRedirect(true);
      })
      .catch((error) => {
        setIsLoading(false);
        newToast({
          message:
            FIREBASE_ERRORS[error?.message as keyof typeof FIREBASE_ERRORS] ||
            error?.message,
          status: 'error',
        });
      });
  };

  const OnSignUp = (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    organizationName: string
  ) => {
    setIsLoading(true);
    signup(
      {
        firstName,
        lastName,
        email,
        password,
        organizationName,
        type: 'EMAIL',
      },
      {
        onSuccess(data) {
          setIsLoading(false);
          newToast({
            message: 'Sign Up Successessful.',
            status: 'success',
          });
          handleLogin(email, password);
        },
        onError(error: any) {
          setIsLoading(false);
          newToast({
            message: `${error.message}`,
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <>
      {appState.user && <Navigate to="/" />}
      {redirect === true && !appState.user && <Loader />}

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          OnSignUp(
            values.firstName,
            values.lastName,
            values.email,
            values.password,
            values.organizationName
          );
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { isSubmitting, handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit as any}>
              <Stack spacing={4}>
                <Flex justify="center" gap={4}>
                  <TextInput
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    leftIcon={
                      <ProfileIcon
                        height="16px"
                        width="16px"
                        color={colors.text.secondary}
                      />
                    }
                  />
                  <TextInput
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    leftIcon={
                      <ProfileIcon
                        height="16px"
                        width="16px"
                        color={colors.text.secondary}
                      />
                    }
                  />
                </Flex>

                <TextInput
                  name="organizationName"
                  label="Organization Name"
                  placeholder="Organization Name"
                  leftIcon={
                    <OrganizationIcon
                      height="16px"
                      width="16px"
                      color={colors.text.secondary}
                    />
                  }
                />
                <TextInput
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  leftIcon={
                    <EmailIcon
                      height="16px"
                      width="16px"
                      color={colors.text.secondary}
                    />
                  }
                />

                <PasswordInput
                  name="password"
                  label="Password"
                  placeholder="Password"
                />

                <Button
                  isDisabled={isSubmitting}
                  variant="tertiary"
                  w="full"
                  fontSize="sm"
                  type="submit"
                >
                  Sign Up
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>

      <Text
        fontFamily="heading"
        fontSize="sm"
        fontWeight="normal"
        textAlign={'center'}
        color="text.primary"
        mt={4}
      >
        Already have an account?
        <FormLink to={'/account/login'}> Login</FormLink>
      </Text>

      <Text align={'center'} fontSize={'sm'} mt={'10px'}>
        By continuing, you agree to our{' '}
        <Link
          href={'https://devdynamics.ai/terms'}
          target="_blank"
          color="primary"
        >
          Terms{' '}
        </Link>{' '}
        of Service and{' '}
        <Link
          href={'https://devdynamics.ai/privacy'}
          target="_blank"
          color="primary"
        >
          Privacy{' '}
        </Link>{' '}
        Policy.
      </Text>

      {isLoading && <Loader />}
    </>
  );
}

export default Signup;
