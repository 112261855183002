import { Box, Flex, Stack } from '@chakra-ui/react';
import {
  Breadcrumbs,
  MetricDetails,
  PageHeader,
} from '@devd-client/devd/components';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DetailView from '../components/DetailView';
import ListView from '../components/ListView';

const ReportPage = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const metricType = searchParams?.get('type');

  const [selectedItem, setSelectedItem] = useState<any>(null);

  return (
    <>
      {!metricType ? (
        <Flex display={'flex'} flexDirection={'column'} width={'100%'}>
          {!id ? (
            <PageHeader headingText="Reports" />
          ) : (
            <Box mb={2}>
              <Breadcrumbs />
            </Box>
          )}

          <Stack spacing={3} w="full">
            {id ? (
              <DetailView selectedItem={selectedItem} />
            ) : (
              <ListView setSelectedItem={setSelectedItem} />
            )}
          </Stack>
        </Flex>
      ) : (
        <MetricDetails />
      )}
    </>
  );
};

export default ReportPage;
