import React, { useMemo, useState } from 'react';
import {
  QUERY_KEYS,
  useAddMembers,
  useDeleteMembers,
  useTeamMembers,
} from '../../apis';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ConfirmButton,
  DataGrid,
  GitHubIcon,
  JiraIcon,
  Loader,
  useToastHook,
} from '@devd-client/devd/components';
import AddMemberModal from '../AddMemberModal';
import { useQueryClient } from '@tanstack/react-query';

interface TeamMembersProps {
  team: string;
}

const TeamMembers: React.FC<TeamMembersProps> = ({ team }) => {
  const addMemberModal = useDisclosure();
  const queryClient = useQueryClient();
  const [newToast] = useToastHook();

  const [roleValue, setRoleValue] = useState('');
  const [selectedMember, setSelectedMember] = useState<string[]>([]);

  const { data, isLoading } = useTeamMembers(team);

  const { mutate: handleDeleteMember, isLoading: deleteMemberLoading } =
    useDeleteMembers();

  const { mutate: handleAddMember, isLoading: addMemberLoading } =
    useAddMembers();

  const rows: any[] = useMemo(() => data?.dto || [], [data?.dto]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Name',
      }),

      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: 'Email',
      }),

      columnHelper.accessor('accounts', {
        cell: (info) => (
          <Flex gap={4} flexWrap="wrap">
            {info?.getValue()?.map((item: any, index: any) => (
              <Flex align="center">
                <Box height={'14px'} width={'14px'}>
                  {item?.type === 'github' ? (
                    <GitHubIcon height="14px" width="14px" />
                  ) : item?.type === 'jira' ? (
                    <JiraIcon height="14px" width="14px" />
                  ) : undefined}
                </Box>
                <Text
                  ml={1}
                  fontSize="xs"
                  color="text.secondary"
                  fontWeight="semibold"
                >
                  {item?.accountId}
                </Text>
              </Flex>
            ))}
          </Flex>
        ),
        header: 'Contributor Accounts',
      }),

      columnHelper.accessor('role', {
        cell: (info) => info.getValue(),
        header: 'Role',
      }),

      columnHelper.accessor('Action', {
        cell: (info) => (
          <ConfirmButton
            showIcon
            buttonText="Delete"
            headerText="Are you sure?"
            bodyText="Do you really want to delete this member?"
            onSuccessAction={() =>
              handleDeleteMember(
                {
                  team: team,
                  email: info.row.original.email,
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries([QUERY_KEYS.teamMembers]);
                    newToast({
                      message: 'Member removed successfully!',
                      status: 'success',
                    });
                  },
                }
              )
            }
          />
        ),
        header: 'Action',
      }),
    ],
    [data?.dto]
  );

  const handleFormSubmit = () => {
    handleAddMember(
      {
        role: roleValue,
        teamName: team,
        members: selectedMember.map((member: any) => {
          return {
            employeeId: member.value.id,
            email: member.value.email,
          };
        }),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.teamMembers]);
          newToast({
            message: `${
              selectedMember.length > 1 ? 'Members' : 'Member'
            } added successfully!`,
            status: 'success',
          });
          addMemberModal.onClose();
        },
      }
    );
  };

  return (
    <>
      <Flex direction="column" w="full">
        <Box textAlign="right">
          <Button variant="primary" size="sm" onClick={addMemberModal.onOpen}>
            +Add Member
          </Button>
        </Box>

        <DataGrid
          showLoader={isLoading}
          columns={columns}
          data={rows}
          maxH="40rem"
          sticky="firstCol"
        />
      </Flex>
      {addMemberModal.isOpen && (
        <AddMemberModal
          isOpen={addMemberModal.isOpen}
          onClose={addMemberModal.onClose}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
          handleFormSubmit={handleFormSubmit}
          addMemberLoading={addMemberLoading}
          team={team}
          roleValue={roleValue}
          setRoleValue={setRoleValue}
        />
      )}
      {deleteMemberLoading && <Loader />}
    </>
  );
};

export default TeamMembers;
