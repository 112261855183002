export const LeftQuoteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.36 -2.86102e-06H7.14L8.46 3.6C5.28 4.5 4.56 6.54 4.56 9.18V9.78H7.44V17.88H4.47035e-08V9.18C4.47035e-08 4.98 1.86 1.44 6.36 -2.86102e-06ZM10.86 17.88V9.18C10.86 4.98 12.72 1.44 17.22 -2.86102e-06H18L19.32 3.6C16.14 4.5 15.42 6.54 15.42 9.18V9.78H18.3V17.88H10.86Z"
        fill={props.color || '#8F8F91'}
      />
    </svg>
  );
};
