import styled from 'styled-components';

type HeadingProps = {
  color?: string;
};

type NameProps = {
  color?: string;
};

type DataColor = {
  color?: string;
};

type InfoDetailsItemProps = {
  bgColorPercentage?: string;
};

export const InfoDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const InfoDetailsHeaderHeading = styled.p<HeadingProps>`
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 400;
  color: ${({ color }) => color || '#333'};
`;

export const Date = styled.p<HeadingProps>`
  font-family: 'Inter';
  font-size: 10px;
  font-weight: 400;
  color: ${({ color }) => color || '#333'};
`;

export const InfoDetailsItem = styled.div<InfoDetailsItemProps>`
  width: 100%;
  margin-right: 8px;

  padding: 10px;
  background-color: ${({ bgColorPercentage }) =>
    `rgba(77, 77, 255, ${bgColorPercentage})`};
`;

export const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const Name = styled.h4<NameProps>`
  font-size: 12px;
  font-family: 'Manrope';
  font-weight: 700;
  color: ${({ color }) => color || '#333'};
`;

export const Data = styled.p<DataColor>`
  font-size: 10px;
  font-weight: 600;
  font-family: 'Manrope';
  color: ${({ color }) => color || '#333'};
`;

export const InfoDetailsCategoryName = styled.div`
  width: 240px;
  display: flex;
  flex-direction: column;
`;

export const HeadingBox = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 34px;
`;

export const CategoryItem = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: 34px;
`;

export const InfoDetailsCategoryData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const DateBox = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
`;

export const CategoryData = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
`;
