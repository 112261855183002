import { HStack, Stack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface ShowFieldsProps {
  clickedGoal: any;
}

const ShowFields: FC<ShowFieldsProps> = ({ clickedGoal }) => {
  return (
    <HStack spacing={6}>
      {clickedGoal?.fields?.map((item: any) => (
        <Stack spacing={0}>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
          >
            {item.field}
          </Text>
          {Object.entries(clickedGoal?.fieldsValue)?.map(([key, val]: any) => (
            <Text
              fontFamily="heading"
              fontSize="sm"
              fontWeight="normal"
              color="text.primary"
            >
              {key === item.key && val}
            </Text>
          ))}
        </Stack>
      ))}
    </HStack>
  );
};

export default ShowFields;
