import { MetricDetails } from '@devd-client/devd/metric-details';
import { FC } from 'react';

interface IssuesProps {
  selectedProjectId: string;
}

const metricTableProps = {
  tableType: 'ISSUE',
  chartKey: 'ISSUE_UPDATED',
  metricKey: '',
  metric: 'ISSUE_UPDATED',
  heading: '',
  firstOption: '',
  secondOption: '',
  teamId: '',
  status: 'all',
  ticketType: 'all',
  isSeparateComponent: true,
  componentOrigin: 'initiatives-issue',
};

const Issues: FC<IssuesProps> = ({ selectedProjectId }) => {
  return <MetricDetails {...metricTableProps} />;
};

export default Issues;
