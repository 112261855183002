import React, { createContext, useReducer, FC } from 'react';
import {
  GoalsAction,
  goalsReducer,
  GoalsState,
} from '../reducer/goals.reducer';

const initialState = {
  goalsList: [],
  clickedGoal: {},
};

export const WorkflowAutomationContext = createContext<{
  state: GoalsState;
  dispatch: React.Dispatch<GoalsAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const WorkflowAutomationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(goalsReducer, initialState);

  return (
    <WorkflowAutomationContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkflowAutomationContext.Provider>
  );
};

export default WorkflowAutomationProvider;
