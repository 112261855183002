const CountIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="6"
      viewBox="0 0 16 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 6V1.5H0V0H3V6H1.5ZM5 6V3.5C5 3.21667 5.096 2.979 5.288 2.787C5.47933 2.59567 5.71667 2.5 6 2.5H8V1.5H5V0H8.5C8.78333 0 9.021 0.0956664 9.213 0.287C9.40433 0.479 9.5 0.716667 9.5 1V2.5C9.5 2.78333 9.40433 3.02067 9.213 3.212C9.021 3.404 8.78333 3.5 8.5 3.5H6.5V4.5H9.5V6H5ZM11 6V4.5H14V3.5H12V2.5H14V1.5H11V0H14.5C14.7833 0 15.0207 0.0956664 15.212 0.287C15.404 0.479 15.5 0.716667 15.5 1V5C15.5 5.28333 15.404 5.52067 15.212 5.712C15.0207 5.904 14.7833 6 14.5 6H11Z"
        fill={props.color || '#777'}
      />
    </svg>
  );
};

export default CountIcon;
