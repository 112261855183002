import { Box, Tooltip } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { SettingsIcon } from '../../../icons';

export const SettingsButton = () => {
  const { pathname } = useLocation();
  const isActive = new RegExp(`(^|/)settings(/|$)`).test(pathname);

  return (
    <Box>
      <Tooltip closeOnClick hasArrow label="Settings">
        <Box
          as={Link}
          to="/settings"
          aria-label="Settings"
          bg={isActive ? 'gray.100' : 'transparent'}
          p={1}
          borderRadius="md"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="36px"
          height="36px"
          _hover={{ bg: 'gray.100', color: 'primary' }}
          color={isActive ? 'primary' : 'text.primary'}
        >
          <SettingsIcon color="currentColor" />
        </Box>
      </Tooltip>
    </Box>
  );
};
