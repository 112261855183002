export const NoDataFoundIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 19C3.61667 19 2.43767 18.5127 1.463 17.538C0.487667 16.5627 0 15.3833 0 14C0 12.6167 0.487667 11.4373 1.463 10.462C2.43767 9.48733 3.61667 9 5 9C6.38333 9 7.56267 9.48733 8.538 10.462C9.51267 11.4373 10 12.6167 10 14C10 15.3833 9.51267 16.5627 8.538 17.538C7.56267 18.5127 6.38333 19 5 19ZM18.6 18L12.2 11.6C12 11.3833 11.7873 11.1627 11.562 10.938C11.3373 10.7127 11.1167 10.5 10.9 10.3C11.5333 9.9 12.0417 9.36667 12.425 8.7C12.8083 8.03333 13 7.3 13 6.5C13 5.25 12.5627 4.18733 11.688 3.312C10.8127 2.43733 9.75 2 8.5 2C7.25 2 6.18733 2.43733 5.312 3.312C4.43733 4.18733 4 5.25 4 6.5C4 6.6 4.00433 6.69567 4.013 6.787C4.021 6.879 4.03333 6.975 4.05 7.075C3.75 7.10833 3.42067 7.175 3.062 7.275C2.704 7.375 2.38333 7.49167 2.1 7.625C2.06667 7.44167 2.04167 7.25833 2.025 7.075C2.00833 6.89167 2 6.7 2 6.5C2 4.68333 2.62933 3.14567 3.888 1.887C5.146 0.629 6.68333 0 8.5 0C10.3167 0 11.8543 0.629 13.113 1.887C14.371 3.14567 15 4.68333 15 6.5C15 7.21667 14.8877 7.89567 14.663 8.537C14.4377 9.179 14.125 9.76667 13.725 10.3L20 16.6L18.6 18ZM3.225 16.475L5 14.7L6.75 16.475L7.475 15.775L5.7 14L7.475 12.225L6.775 11.525L5 13.3L3.225 11.525L2.525 12.225L4.3 14L2.525 15.775L3.225 16.475Z"
        fill={props.color || '#777'}
      />
    </svg>
  );
};
