import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useInvestmentPage } from './../api';
import { InvestmentsPage } from './devd-investments';

export const Investments = () => {
  const { data } = useInvestmentPage();

  return (
    <>
      <MetaTags title="Investments | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <InvestmentsPage data={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Investments;
