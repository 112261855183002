import { FC, useMemo } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { DataGrid } from '@devd-client/devd/components';
import { useTeamAllocationDetailGql } from '../../../graphql';
import { createColumnHelper } from '@tanstack/react-table';

interface AllocationInitiativeModalProps {
  isOpen: boolean;
  onClose: () => void;
  allocationId: string;
  allocationTeam?: string;
}

const AllocatioInitiativenModal: FC<AllocationInitiativeModalProps> = ({
  isOpen,
  onClose,
  allocationId,
  allocationTeam,
}) => {
  const { data: teamAllocationData, isFetching: teamAllocationLoading } =
    useTeamAllocationDetailGql(allocationId, allocationTeam ?? '');

  const rows: any[] = useMemo(
    () => teamAllocationData?.TeamAllocationDetails || [],
    [teamAllocationData?.TeamAllocationDetails]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('team', {
        cell: (info) => info.getValue(),
        header: 'Team',
      }),
      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        size: 100,
        header: 'Member',
      }),
      columnHelper.accessor('allocation', {
        cell: (info) => info.getValue(),
        size: 50,
        header: 'Allocation',
      }),
    ],
    [teamAllocationData?.TeamAllocationDetails]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      motionPreset="slideInBottom"
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent rounded="sm" minW="50vw">
        <ModalHeader fontFamily="Inter">
          Allocation Details for initiative {allocationId}
        </ModalHeader>
        <ModalCloseButton color="text.secondary" fontSize="xs" />

        <ModalBody>
          <DataGrid
            showLoader={teamAllocationLoading}
            columns={columns}
            data={rows}
            maxH="30rem"
            sticky="firstCol"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AllocatioInitiativenModal;
