import qs from 'query-string';

export const setQueryStringWithoutPageReload = (qsValue: any) => {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    qsValue;
  window.history.replaceState({ path: newurl }, '', newurl);
};

export const getQueryStringValue = (
  key: any,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const setQueryStringValue = (
  key: any,
  value: any,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({
    ...values,
    [key]: value,
  });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export function removeEmptyQueryParams(queryParams: {
  [key: string]: any;
}): void {
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value === null || value === undefined || value === '') {
      searchParams.delete(key);
    }
  });
  const newUrl = window.location.pathname + '?' + searchParams.toString();
  window.history.replaceState(null, '', newUrl);
}
