import styled from 'styled-components';

export const MultiColorBar = styled.div`
  width: 100%;
`;

export const ValueWrapper = styled.div`
  float: left;
  text-align: center;
`;

export const GraduationWrapper = styled.div`
  float: left;
  text-align: center;
`;

export const BarWrapper = styled.div`
  float: left;
  height: 8.5px;
`;

export const Legends = styled.div`
  text-align: center;
`;

export const Legend = styled.div`
  display: inline-block;
  margin: 0 5px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Dot = styled.span`
  font-size: 20px;
  vertical-align: middle;
`;

export const Label = styled.span`
  margin-left: 2px;
  vertical-align: middle;
`;

export const Scale = styled.div``;

export const Values = styled.div``;

export const Bars = styled.div`
  & div:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  & div:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
