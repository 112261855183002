export type ThroughputState = {
  dataOpenPR: any;
  dataMergedPR: any;
  dataPRChange: any;
  dataPRReview: any;
  dataTasks: any;
  dataBugs: any;
};

export type ThroughputAction = {
  type: string;
  payload?: any;
};

export enum ThroughputActionTypes {
  SET_THROUGHPUT_DATA = 'SET_THROUGHPUT_DATA',
}

export const throughputReducer = (
  state: ThroughputState,
  action: ThroughputAction
): ThroughputState => {
  switch (action.type) {
    case ThroughputActionTypes.SET_THROUGHPUT_DATA:
      return {
        ...state,
        dataOpenPR: action?.payload.openPR,
        dataMergedPR: action?.payload.mergedPR,
        dataPRChange: action?.payload.prChanges,
        dataPRReview: action?.payload.prReview,
        dataTasks: action?.payload.tasks,
        dataBugs: action?.payload.bugs,
      };

    default:
      return state;
  }
};
