export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 18.5C1.95 18.5 1.479 18.3043 1.087 17.913C0.695667 17.521 0.5 17.05 0.5 16.5V2.5C0.5 1.95 0.695667 1.479 1.087 1.087C1.479 0.695667 1.95 0.5 2.5 0.5H9.5V2.5H2.5V16.5H9.5V18.5H2.5ZM13.5 14.5L12.125 13.05L14.675 10.5H6.5V8.5H14.675L12.125 5.95L13.5 4.5L18.5 9.5L13.5 14.5Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};

export default LogoutIcon;
