export const EyeCloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1 11.3L13.65 9.85C13.8 9.06667 13.575 8.33333 12.975 7.65C12.375 6.96667 11.6 6.7 10.65 6.85L9.2 5.4C9.48333 5.26667 9.77067 5.16667 10.062 5.1C10.354 5.03333 10.6667 5 11 5C12.25 5 13.3127 5.43733 14.188 6.312C15.0627 7.18733 15.5 8.25 15.5 9.5C15.5 9.83333 15.4667 10.146 15.4 10.438C15.3333 10.7293 15.2333 11.0167 15.1 11.3ZM18.3 14.45L16.85 13.05C17.4833 12.5667 18.046 12.0373 18.538 11.462C19.0293 10.8873 19.45 10.2333 19.8 9.5C18.9667 7.81667 17.7707 6.479 16.212 5.487C14.654 4.49567 12.9167 4 11 4C10.5167 4 10.0417 4.03333 9.575 4.1C9.10833 4.16667 8.65 4.26667 8.2 4.4L6.65 2.85C7.33333 2.56667 8.03333 2.354 8.75 2.212C9.46667 2.07067 10.2167 2 11 2C13.5167 2 15.7583 2.69567 17.725 4.087C19.6917 5.479 21.1167 7.28333 22 9.5C21.6167 10.4833 21.1127 11.396 20.488 12.238C19.8627 13.0793 19.1333 13.8167 18.3 14.45ZM18.8 20.6L14.6 16.45C14.0167 16.6333 13.4293 16.771 12.838 16.863C12.246 16.9543 11.6333 17 11 17C8.48333 17 6.24167 16.3043 4.275 14.913C2.30833 13.521 0.883333 11.7167 0 9.5C0.35 8.61667 0.791667 7.79567 1.325 7.037C1.85833 6.279 2.46667 5.6 3.15 5L0.4 2.2L1.8 0.799999L20.2 19.2L18.8 20.6ZM4.55 6.4C4.06667 6.83333 3.625 7.30833 3.225 7.825C2.825 8.34167 2.48333 8.9 2.2 9.5C3.03333 11.1833 4.229 12.5207 5.787 13.512C7.34567 14.504 9.08333 15 11 15C11.3333 15 11.6583 14.9793 11.975 14.938C12.2917 14.896 12.6167 14.85 12.95 14.8L12.05 13.85C11.8667 13.9 11.6917 13.9373 11.525 13.962C11.3583 13.9873 11.1833 14 11 14C9.75 14 8.68733 13.5627 7.812 12.688C6.93733 11.8127 6.5 10.75 6.5 9.5C6.5 9.31667 6.51233 9.14167 6.537 8.975C6.56233 8.80833 6.6 8.63333 6.65 8.45L4.55 6.4Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};
