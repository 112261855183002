import {
  Box,
  VStack,
  Checkbox,
  Text,
  Stack,
  Input,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useFetchSprint } from '../../DayTabs/api';
import { useDashboardStore } from '../../hooks/useDashboardStore';
import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { BiSearch } from 'react-icons/bi';
import { useDebounce } from '../../hooks/useDebounce';

interface Sprint {
  sprintId: string;
  sprintName: string;
  startDate: string;
  endDate: string;
  status: string;
}

interface SprintsProps {
  selectedSprintId: string | null;
  setFormatedRange: (range: string) => void;
  setSelectedSprintId: (id: string) => void;
  setSelectedSprintName: (name: string) => void;
  appState?: any;
  sprintIds?: any;
  setSprintIds?: (sprintIds: any) => void;
  setStartDate: (startDate: any) => void;
  setEndDate: (endDate: any) => void;
  setSprintId: (sprintId: any) => void;
  setSprintName: (sprintName: any) => void;
  setDuration: (duration: any) => void;
  setActive: (active: any) => void;
  setIsOpen: (isOpen: any) => void;
}

export const Sprints: React.FC<SprintsProps> = ({
  setFormatedRange,
  setSelectedSprintId,
  selectedSprintId,
  setSelectedSprintName,
  appState,
  sprintIds,
  setSprintIds,
  setStartDate,
  setEndDate,
  setSprintId,
  setSprintName,
  setDuration,
  setActive,
  setIsOpen,
}) => {
  const { data, isLoading } = useFetchSprint();
  const [selectedSprintIds, setSelectedSprintIds] = useState<string[]>([]);
  const { selected, setSelected, setFilterTitle } = useDashboardStore();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  useEffect(() => {
    if (debouncedSearchTerm === '') {
      setCurrentPage(1);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (appState?.user?.dto?.sprint && selectedSprintId === null) {
      setSelectedSprintId(appState?.user?.dto?.sprint);
    }
  }, [appState?.user?.dto?.sprint, selectedSprintId, setSelectedSprintId]);

  useEffect(() => {
    if (setSprintIds) {
      setSprintIds(selectedSprintIds);
    }
  }, [selectedSprintIds, setSprintIds]);

  const filteredSprints = useMemo(() => {
    if (isLoading || !Array.isArray(data)) return [];

    const uniqueSprintMap = new Map<string, Sprint>();

    for (const sprint of data) {
      const normalizedSprintName = sprint.sprintName.toLowerCase();
      if (!uniqueSprintMap.has(normalizedSprintName)) {
        uniqueSprintMap.set(normalizedSprintName, sprint);
      }
    }

    const uniqueSprints = Array.from(uniqueSprintMap.values());

    const normalizedSearchTerm = debouncedSearchTerm.trim().toLowerCase();

    const matchingSprints = normalizedSearchTerm
      ? uniqueSprints.filter((sprint) =>
          sprint.sprintName.toLowerCase().includes(normalizedSearchTerm)
        )
      : uniqueSprints;

    if (sprintIds && sprintIds.length > 0) {
      const selectedSprintSet = new Set(sprintIds);

      const [prioritizedSprints, remainingSprints] = matchingSprints.reduce(
        ([prioritized, remaining], sprint) => {
          if (selectedSprintSet.has(sprint.sprintId)) {
            prioritized.push(sprint);
          } else {
            remaining.push(sprint);
          }
          return [prioritized, remaining];
        },
        [[], []] as [Sprint[], Sprint[]]
      );

      return [...prioritizedSprints, ...remainingSprints];
    } else if (selected.sprintId) {
      const selectedSprintIndex = matchingSprints.findIndex(
        (sprint) => sprint.sprintId === selected.sprintId
      );

      if (selectedSprintIndex > -1) {
        const [selectedSprint] = matchingSprints.splice(selectedSprintIndex, 1);
        return [selectedSprint, ...matchingSprints];
      }
    }

    return matchingSprints;
  }, [data, debouncedSearchTerm, isLoading, sprintIds, selected.sprintId]);

  const paginatedSprints = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredSprints.slice(start, end);
  }, [filteredSprints, currentPage]);

  const totalPages = Math.ceil(filteredSprints.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (isLoading)
    return (
      <Box
        w={'200px'}
        h={'200px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Spinner />
      </Box>
    );
  if (!Array.isArray(data)) return <div>No sprints available.</div>;

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSprintSelection = (sprint: Sprint) => {
    const isSelected = selectedSprintIds.includes(sprint.sprintId);
    const newSelectedSprintIds = isSelected
      ? selectedSprintIds.filter((id) => id !== sprint.sprintId)
      : [...selectedSprintIds, sprint.sprintId];

    setSelectedSprintIds(newSelectedSprintIds);

    if (location.pathname === '/sprint') {
      setSelected({
        ...selected,
        duration: 'Sprint',
      });
      setFilterTitle('Sprint');
    }

    if (!setSprintIds) {
      setSelectedSprintId(sprint.sprintId);
      setSelectedSprintName(sprint.sprintName);

      const formattedStartDate = sprint.startDate.split(' ')[0];
      const formattedEndDate = sprint.endDate.split(' ')[0];
      setFormatedRange(`${formattedStartDate} ${formattedEndDate}`);

      const startDate = formattedStartDate;
      const endDate = formattedEndDate;

      setStartDate(startDate);
      setEndDate(endDate);
      setSprintId(sprint.sprintId);
      setSprintName(sprint.sprintName);
      setDuration('Sprint');

      setSelected({
        ...selected,
        startDate: startDate!,
        endDate: endDate!,
        sprintId: sprint.sprintId!,
        sprintName: sprint.sprintName!,
        duration: 'Sprint',
      });
      setFilterTitle(sprint.sprintName!);
      setActive('Sprint');
    }
    if (location.pathname !== '/sprint') {
      setIsOpen(false);
    }
  };

  return (
    <Box width="100%" padding="16px" paddingBottom={0}>
      <Box position="relative">
        <Input
          placeholder="Search Sprint"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          marginBottom="12px"
        />
        <Box
          position="absolute"
          top="40%"
          right="8px"
          transform="translateY(-50%)"
          backgroundColor={'white'}
          padding={1}
        >
          <BiSearch size={22} color="#A0AEC0" />
        </Box>
      </Box>
      <Box maxH="200px" overflowY="auto">
        {paginatedSprints.length === 0 ? (
          <Text
            fontSize="14px"
            color={'#718096'}
            fontFamily={'inter'}
            textAlign={'center'}
          >
            No sprints found.
          </Text>
        ) : (
          <VStack align="start" spacing={3}>
            {paginatedSprints.map((sprint: Sprint) => (
              <Box key={sprint.sprintId} width="100%">
                <Checkbox
                  colorScheme="blue"
                  size="sm"
                  isChecked={
                    (selectedSprintIds.includes(sprint.sprintId) &&
                      setSprintIds !== undefined) ||
                    sprint.sprintId === selectedSprintId ||
                    ((sprintIds?.length === 0 || sprintIds === undefined) &&
                      selected.sprintId === sprint.sprintId &&
                      selectedSprintId === null)
                  }
                  onChange={() => handleSprintSelection(sprint)}
                >
                  <Text fontSize="14px" color={'#2A2A2F'} fontFamily={'inter'}>
                    {sprint.sprintName}
                  </Text>
                </Checkbox>
                <Stack direction="row" spacing={2}>
                  <Text fontSize="10px" color="#626266" marginLeft="24px">
                    {formatDate(sprint.startDate)} -{' '}
                    {formatDate(sprint.endDate)}
                  </Text>
                </Stack>
              </Box>
            ))}
            {totalPages > 1 && (
              <Box display={'flex'} w={'full'} justifyContent={'space-between'}>
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  size={'sm'}
                  bg={'transparent'}
                  padding={0}
                  _hover={{ bg: 'transparent', color: 'black' }}
                  color={'#2A2A2F'}
                  fontWeight={'medium'}
                  isDisabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  size={'sm'}
                  bg={'transparent'}
                  padding={0}
                  _hover={{ bg: 'transparent', color: 'black' }}
                  color={'#2A2A2F'}
                  fontWeight={'medium'}
                  marginRight={1}
                  isDisabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </Box>
            )}
          </VStack>
        )}
      </Box>
    </Box>
  );
};
