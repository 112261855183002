import { ChatDataProvider } from '../context/homeChatContext';
import HomePage from './devd-home';

export const Home = () => {
  return (
    <ChatDataProvider>
      <HomePage />
    </ChatDataProvider>
  );
};

export default Home;
