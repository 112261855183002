import { FC } from 'react';
import ChartCard from './ChartCard';
import { Flex } from '@chakra-ui/react';
import { PeriodType } from '@devd-client/devd/components';

interface ContPerformanceTableProps {
  selected: PeriodType;
  showMetric: any;
  selectedTeam: string;
}

const ContPerformanceTable: FC<ContPerformanceTableProps> = ({
  selected,
  showMetric,
  selectedTeam,
}) => {
  return (
    <Flex w="full">
      <ChartCard
        selected={selected}
        item={showMetric}
        selectedTeam={selectedTeam}
      />
    </Flex>
  );
};

export default ContPerformanceTable;
