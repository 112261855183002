import styled from 'styled-components';

export const IntegrationsDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const LeftSection = styled.div`
  display: flex;
`;

export const RightSection = styled.div``;

export const IconContainer = styled.div`
  height: 64px;
  width: 60px;
  margin-right: 24px;
`;

export const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ItemName = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 29px;
`;

export const ItemSubText = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 20px;
`;

export const Divider = styled.div`
  height: 0.5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 20px;
`;

export const Status = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.success};
  line-height: 15px;
`;
