import { FC, useEffect, useRef } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import styled from 'styled-components';

interface SlideDrawerProps {
  sideBarHeading: string;
  sideBarSubHeading: React.ReactNode;
  openDrawer: boolean;
  content?: React.ReactNode;
  handleDrawerClose: () => void;
  width?: string;
}

type SideDrawerProps = {
  show: boolean;
  width?: string;
};

const SideDrawerContainer = styled.div<SideDrawerProps>`
  position: fixed;
  height: 100%;
  background: #fff;
  padding: 24px;
  top: 0;
  right: 0;
  width: ${({ width }) => width || '350px'};
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: ${({ show }) =>
    show ? 'transform: translateX(0%)' : 'translateX(120%)'};
  transition: transform 0.3s ease-out;
  z-index: 999;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 5px;
  margin-top: 5px;
`;

const Heading = styled.h1`
  font-size: 18px;
  font-family: 'Inter';
  color: '#000';
  font-weight: 700;
`;

const SideDrawerBody = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export const InfoSlideBar: FC<SlideDrawerProps> = ({
  openDrawer,
  content,
  handleDrawerClose,
  width,
  sideBarHeading,
  sideBarSubHeading,
}) => {
  const myRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      handleDrawerClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <SideDrawerContainer ref={myRef} show={openDrawer} width={width}>
      <CloseButtonContainer>
        <Flex flexDir={'column'}>
          <Text fontWeight={'600'} color="#2A2A2F" fontSize={'20px'}>
            {sideBarHeading}
          </Text>
          {sideBarSubHeading && sideBarSubHeading}
        </Flex>
        <IconButton
          isRound
          bg="transparent"
          onClick={handleDrawerClose}
          aria-label="close"
          size="sm"
          icon={<AiOutlineClose fontSize="18" />}
        />
      </CloseButtonContainer>
      <SideDrawerBody>{content}</SideDrawerBody>
    </SideDrawerContainer>
  );
};
