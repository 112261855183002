import React from 'react';

interface DayProps {
  dayLabel: string;
  date: Date;
  isSelected: boolean | null;
  isFirstSelected: boolean | null;
  isLastSelected: boolean | null;
  onClick: () => void;
  isFuture: boolean;
}

const Day: React.FC<DayProps> = ({
  dayLabel,
  date,
  isSelected,
  onClick,
  isFirstSelected,
  isLastSelected,
  isFuture,
}) => {
  let backgroundColor = 'white';
  let color = 'black';

  if (isFuture) {
    backgroundColor = '#F0F0F0';
    color = '#A0A0A0';
  } else {
    if (isFirstSelected || isLastSelected) backgroundColor = '#4D4DFF';
    else if (isSelected) backgroundColor = '#DBDBFF';

    if (isFirstSelected || isLastSelected) color = 'white';
  }

  return (
    <button
      onClick={onClick}
      style={{
        padding: '8px',
        border: 0,
        cursor: 'pointer',
        color: color,
        background: backgroundColor,
        borderTopLeftRadius: isFirstSelected ? '2px' : '',
        borderBottomRightRadius: isLastSelected ? '2px' : '',
      }}
    >
      {dayLabel}
    </button>
  );
};

export default Day;
