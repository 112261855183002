import { Dispatch } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ActivityLogActionTypes } from '../reducers/activityLogReducer';
import { getGitWorkLogFromGraphql, getWorkLogDetails } from './query';
import { getIssuesWorkLogFromGraphql } from './query';
import { getAuthorWorkLogFromGraphql } from './query';

const QUERY_KEYS = {
  activityLogGql: 'activityLogGql',
  workLogDetails: 'workLogDetails',
};

export const useActivityLogGql = (
  workLogType: string,
  startDate: string,
  endDate: string,
  teamId: string,
  authors: string[],
  pageSize: number,
  pageNumber: number
) => {
  let queryFn = (pageParam: any) =>
    getGitWorkLogFromGraphql(startDate, endDate, teamId, pageSize, pageParam);
  switch (workLogType) {
    case 'Git':
      queryFn = (pageParam: any) =>
        getGitWorkLogFromGraphql(
          startDate,
          endDate,
          teamId,
          pageSize,
          pageParam
        );
      break;
    case 'Issues':
      queryFn = (pageParam: any) =>
        getIssuesWorkLogFromGraphql(
          startDate,
          endDate,
          teamId,
          pageSize,
          pageParam
        );
      break;
    case 'Contributors':
      queryFn = (pageParam: any) =>
        getAuthorWorkLogFromGraphql(
          startDate,
          endDate,
          teamId,
          authors,
          pageSize,
          pageParam
        );
      break;
    default:
      queryFn = (pageParam: any) =>
        getGitWorkLogFromGraphql(
          startDate,
          endDate,
          teamId,
          pageSize,
          pageParam
        );
      break;
  }

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    isLoading,
    isError,
  } = useInfiniteQuery(
    [
      QUERY_KEYS.activityLogGql,
      startDate,
      endDate,
      teamId,
      authors,
      workLogType,
    ],
    ({ pageParam = 0 }) => queryFn(pageParam),

    {
      getNextPageParam: (lastPage, allPages) => {
        const lastPageObj: any = Object.values(lastPage);

        if (lastPageObj[0]?.rows?.length < pageSize) {
          return undefined;
        } else {
          return allPages.length;
        }
      },
    }
  );

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    refetch,
    isLoading,
    isError,
  };
};

export const useWorkLogDetailsGql = (
  type: string,
  date: string,
  name: string,
  dataType: string,
  teamId: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.workLogDetails, type, date, name, dataType, teamId],
    () => getWorkLogDetails(type, date, name, dataType, teamId),
    {
      onSuccess: (data) => {
        dispatch({
          type: ActivityLogActionTypes.WORK_LOG_DETAILS,
          payload: data?.WorklogDetails,
        });
      },
      enabled: !!name,
    }
  );
};
