import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '../../hooks/useCustomMutation';

export const QUERY_KEYS = {
  teammenu: 'teammenu',
  favTeam: 'favTeam',
};

export const useTeammenu = () => {
  return useQuery([QUERY_KEYS.teammenu], ENDPOINTS.getTeammenu);
};

export const useFavTeam = () => {
  return useCustomMutation([QUERY_KEYS.favTeam], ENDPOINTS.postFavTeam);
};

export const useRemoveFavTeam = () => {
  return useCustomMutation([QUERY_KEYS.favTeam], ENDPOINTS.deleteFavTeam);
};
