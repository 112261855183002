import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEYS = {
  activityLogPage: 'activityLogPage',
};

export const useActivityLogPage = () => {
  return useQuery([QUERY_KEYS.activityLogPage], ENDPOINTS.getActivityLogPage);
};
