import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  CustomSelect,
  Loader,
  PageHeader,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { useLocation, useNavigate } from 'react-router';

import { Field, Formik } from 'formik';
import { useUpdateCustomMetricsMutation, useCustomfield } from '../../api';
import { validationSchema } from '../../helpers/custom-metric.validation';
import { InitialValues } from '../../helpers/custom-metrics.types';
import { useEffect, useState } from 'react';
import {
  chartTypeOptions,
  functionOptions,
  sourceOptions,
  unitOptions,
} from '../../helpers/custom-metrics.utils';

export const EditMetrics = () => {
  const navigate = useNavigate();
  const location = useLocation() as any;
  const state = location?.state;

  const initialValues: InitialValues = {
    name: state.name || '',
    description: state.description || '',
    source: state.source || '',
    field: state.fieldId || '',
    unit: state.unit || '',
    metricFunction: state.function || '',
    chartType: state.chartType || '',
    applyAtTeam: Boolean(state.isTeamMetric) || false,
    applyAtContributor: Boolean(state.isContributorMetric) || false,
  };

  const [initialRecord, setInitialRecord] = useState(initialValues);
  const [newToast] = useToastHook();

  const { data: customFieldData, isLoading: customFieldLoading } =
    useCustomfield();

  const { mutate: handleAddCustomMetric, isLoading } =
    useUpdateCustomMetricsMutation();

  useEffect(() => {
    setInitialRecord({
      name: state.name,
      description: state.description,
      source: state.source,
      field: state.fieldId,
      unit: state.unit,
      metricFunction: state.function,
      chartType: state.chartType,
      applyAtTeam: state.isTeamMetric,
      applyAtContributor: state.isContributorMetric,
    });
  }, []);

  const onSubmit = ({
    name,
    description,
    source,
    field,
    unit,
    metricFunction,
    chartType,
    applyAtTeam,
    applyAtContributor,
  }: InitialValues) => {
    handleAddCustomMetric(
      {
        name,
        description,
        fieldId: field,
        chartType,
        function: metricFunction,
        isTeamMetric: applyAtTeam,
        isContributorMetric: applyAtContributor,
        source,
        unit,
      },
      {
        onSuccess: () => {
          newToast({
            message: 'Custom Metric updated successfully!',
            status: 'success',
          });
          setTimeout(() => {
            navigate('/settings/custom-metrics');
          }, 2000);
        },
        onError: (e: any) => {
          newToast({
            message: e?.message,
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box mb={5}>
        <PageHeader headingText="Edit Custom Metric" />
        <Button onClick={() => navigate(-1)} size="sm" variant="link">
          Back
        </Button>
      </Box>

      <Formik
        enableReinitialize
        initialValues={initialRecord}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { handleSubmit, setFieldValue, errors, touched, values } =
            props;
          return (
            <form onSubmit={handleSubmit as any}>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} w="full">
                <GridItem colSpan={1}>
                  <TextInput
                    isDisabled
                    name="name"
                    label="Metric Name"
                    placeholder="Metric Name"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <TextInput
                    name="description"
                    label="Metric Description"
                    placeholder="Metric Description"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <Field
                    id="source"
                    name="source"
                    label={'Metric Source'}
                    defaultValue={sourceOptions.find(
                      (item: any) => item.value === values.source
                    )}
                    as={CustomSelect}
                    options={sourceOptions}
                    placeholder="Select source"
                    onChange={(selected: any) =>
                      setFieldValue('source', selected)
                    }
                  />

                  {touched.source && errors.source ? (
                    <Text as="span" fontSize="sm" color="red.500">
                      {errors.source}
                    </Text>
                  ) : null}
                </GridItem>

                <GridItem colSpan={1}>
                  <Field
                    name="field"
                    label={'Metric Field'}
                    defaultValue={state.defaultField}
                    as={CustomSelect}
                    options={
                      customFieldData?.dto?.map((item: any) => ({
                        value: item.value,
                        label: item.name,
                      })) || []
                    }
                    placeholder="Select field"
                    onChange={(selected: any) =>
                      setFieldValue('field', selected)
                    }
                  />

                  {touched.field && errors.field ? (
                    <Text as="span" fontSize="sm" color="red.500">
                      {errors.field}
                    </Text>
                  ) : null}
                </GridItem>

                <GridItem colSpan={1}>
                  <Field
                    name="unit"
                    label={'Metric Unit'}
                    defaultValue={unitOptions.find(
                      (item: any) => item.value === values.unit
                    )}
                    as={CustomSelect}
                    options={unitOptions}
                    placeholder="Select unit"
                    onChange={(selected: any) =>
                      setFieldValue('unit', selected)
                    }
                  />

                  {touched.unit && errors.unit ? (
                    <Text as="span" fontSize="sm" color="red.500">
                      {errors.unit}
                    </Text>
                  ) : null}
                </GridItem>

                <GridItem colSpan={1}>
                  <Field
                    name="metricFunction"
                    label={'Metric Function'}
                    as={CustomSelect}
                    defaultValue={functionOptions.find(
                      (el: any) => el.value === values.metricFunction
                    )}
                    options={functionOptions}
                    placeholder="Select Metric Function"
                    onChange={(selected: any) =>
                      setFieldValue('metricFunction', selected)
                    }
                  />

                  {touched.metricFunction && errors.metricFunction ? (
                    <Text as="span" fontSize="sm" color="red.500">
                      {errors.metricFunction}
                    </Text>
                  ) : null}
                </GridItem>

                <GridItem colSpan={1}>
                  <Field
                    name="chartType"
                    label={'Metric Chart Type'}
                    as={CustomSelect}
                    defaultValue={chartTypeOptions.find(
                      (el: any) => el.value === values.chartType
                    )}
                    options={chartTypeOptions}
                    placeholder="Select chart type"
                    onChange={(selected: any) =>
                      setFieldValue('chartType', selected)
                    }
                  />

                  {touched.chartType && errors.chartType ? (
                    <Text as="span" fontSize="sm" color="red.500">
                      {errors.chartType}
                    </Text>
                  ) : null}
                </GridItem>

                <GridItem colSpan={2}>
                  <Field name="applyAtTeam">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.applyAtTeam && form.touched.applyAtTeam
                        }
                      >
                        <Checkbox
                          {...field}
                          id="applyAtTeam"
                          isChecked={field.value}
                        >
                          Apply at team
                        </Checkbox>
                        <FormErrorMessage>
                          {form.errors.applyAtTeam}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </GridItem>

                <GridItem colSpan={2}>
                  <Field name="applyAtContributor">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.applyAtContributor &&
                          form.touched.applyAtContributor
                        }
                      >
                        <Checkbox
                          {...field}
                          id="applyAtContributor"
                          isChecked={field.value}
                        >
                          Apply at Contributor
                        </Checkbox>
                        <FormErrorMessage>
                          {form.errors.applyAtContributor}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </GridItem>

                <GridItem colSpan={2}>
                  <Button
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    variant="tertiary"
                    fontSize="sm"
                    type="submit"
                    loadingText={'Saving'}
                  >
                    Edit
                  </Button>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Formik>
      {(isLoading || customFieldLoading) && <Loader />}
    </Box>
  );
};

export default EditMetrics;
