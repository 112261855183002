export const FIREBASE_ERRORS = {
  'Firebase: Error (auth/email-already-in-use).':
    'A user with that email already exists.',

  'Firebase: Error (auth/user-not-found).':
    'Check your email inbox to verify your email.',

  'Firebase: Error (auth/wrong-password).':
    'Invalid Email Address or Password.',

  'Firebase: Error (auth/network-request-failed).':
    'Please check your Internet connection',

  'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
    'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
};
