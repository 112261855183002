import { FC, Fragment } from 'react';
import { Box, Divider, Skeleton, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { SlideBar } from '../../../appSlideBar';
import { Paginate } from '../../../appPaginate';

interface WellbeingMemberSideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  wellbeingMemberData: any;
  heading: string;
  subHeading: string;
  isFetching: boolean;
  handlePageClick: any;
}

const WellbeingMemberSideBar: FC<WellbeingMemberSideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  wellbeingMemberData,
  heading,
  subHeading,
  isFetching,
  handlePageClick,
}) => {
  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Stack spacing={1}>
          {isFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {wellbeingMemberData?.data?.map(
            (item: any, idx: number, arr: any) => (
              <Fragment key={item?.id}>
                <Box p={2}>
                  <Stack spacing={1}>
                    <Box>
                      <Link to={`/contributors/${item.email}`}>
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          textDecoration="underline"
                          color="primary"
                          fontWeight="medium"
                        >
                          {item?.email}
                        </Text>
                      </Link>
                      {item?.name && (
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          color="gray.700"
                          fontWeight="medium"
                        >
                          {item?.name}
                        </Text>
                      )}
                    </Box>

                    {item?.role && (
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        color="gray.700"
                        fontWeight="medium"
                      >
                        Role :{' '}
                        <Text as="span" color="text.secondary">
                          {item?.role?.charAt(0)?.toUpperCase() +
                            item?.role?.slice(1).toLowerCase()}
                        </Text>
                      </Text>
                    )}
                  </Stack>
                </Box>
                {idx !== arr.length - 1 && <Divider />}
              </Fragment>
            )
          )}

          <Box marginLeft="-20px" my={'16px'}>
            {wellbeingMemberData?.data?.length > 1 && (
              <Paginate
                currentPage={wellbeingMemberData?.currentPage + 1}
                totalPageCount={wellbeingMemberData?.totalPages}
                pageSize={20}
                onPageChange={(page) => handlePageClick(page as number)}
              />
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default WellbeingMemberSideBar;
