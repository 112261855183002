import { Box, Button, Image, Text } from '@chakra-ui/react';
import {
  ButtonContainer,
  IconContainer,
  IntegrationCardContainer,
  IntegrationName,
  Status,
  TopSection,
} from './helpers/integration-card.styled';
import { IntegrationData } from './helpers/integration-card.types';
import { useStrings } from './helpers/integrations-card.strings';
import { useNavigate } from 'react-router';
import { ShowStatusText } from '../showStatusText';
import { Setting } from '../icons';
import { useToastHook } from '../appToast';

export type IntegrationCardProps = IntegrationData & {
  setClickSubmit: (clickSubmit: any) => void;
  isIntegrationDisabled?: boolean;
};

export const IntegrationCard = ({
  isIntegrationDisabled,
  ...props
}: IntegrationCardProps) => {
  const strings = useStrings();
  const navigate = useNavigate();
  const [newToast] = useToastHook();

  return (
    <IntegrationCardContainer
      onClick={() => {
        if (props.active) {
          navigate(`/settings/integrations/${props?.displayValue}`);
        } else if (!isIntegrationDisabled) {
          navigate(`/settings/integrations/${props?.displayValue}`);
        } else if (isIntegrationDisabled) {
          newToast({
            message:
              'Upgrade your plan for doing more Integrations or contact Devdynamics support.',
            status: 'error',
          });
        }
      }}
    >
      <TopSection>
        <Box>
          <IconContainer>
            <Image
              src={
                props['links']?.icon ||
                'https://icon-library.com/images/sugar-cube-icon/sugar-cube-icon-10.jpg'
              }
            />
          </IconContainer>
          <IntegrationName>{props?.displayName}</IntegrationName>
        </Box>

        {(props?.showFieldMap || props?.reSyncProjects) && (
          <Setting color="gray" />
        )}
      </TopSection>

      <>
        {props?.active ? (
          <Status>Active</Status>
        ) : (
          <ButtonContainer>
            <Button
              mt={5}
              type="submit"
              variant={'link'}
              padding={0}
              size="sm"
              color={'primary'}
              onClick={() => {
                if (isIntegrationDisabled) {
                  newToast({
                    message: 'Upgrade your plan for doing more Integrations',
                    status: 'error',
                  });
                } else
                  navigate(`/settings/integrations/${props?.displayValue}`);
              }}
            >
              {strings.Integration}
            </Button>
          </ButtonContainer>
        )}
      </>

      <ShowStatusText status={props?.status} message={props?.message} />
    </IntegrationCardContainer>
  );
};

export default IntegrationCard;
