import { LoginContainer } from './login.styles';
import React, { Suspense } from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { AppIcon } from '@devd-client/devd/components';

const Login = React.memo(
  React.lazy(() =>
    import('@devd-client/devd/auth').then((module) => ({
      default: module.Login,
    }))
  )
);
export function AsyncLogin() {
  return (
    <LoginContainer>
      <Flex justify={'center'}>
        <AppIcon height="28px" />
      </Flex>

      <Stack my={4}>
        <Text
          fontFamily="heading"
          fontSize="sm"
          color="text.secondary"
          fontWeight="normal"
          lineHeight="22px"
          textAlign="center"
        >
          A single platform for data-driven engineering - Metrics, Insights,
          Investments, Feedback and more.
        </Text>
      </Stack>

      <Suspense>
        <Login />
      </Suspense>
    </LoginContainer>
  );
}

export default AsyncLogin;
