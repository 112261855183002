import { createContext, useReducer } from 'react';
import { FlowAction, flowReducer, FlowState } from '../reducers/flowReducer';

const initialState = {
  data: [],
};
export const FlowContext = createContext<{
  state: FlowState;
  dispatch: React.Dispatch<FlowAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const FlowProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(flowReducer, initialState);

  return (
    <FlowContext.Provider value={{ state, dispatch }}>
      {children}
    </FlowContext.Provider>
  );
};
