import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '../../hooks/useCustomMutation';

export const QUERY_KEYS = {
  intDetails: 'intDetails',
  issueType: 'issueType',
  issueTypeUpdate: 'issueTypeUpdate',
  bottomView: 'bottomView',
  tableView: 'tableView',
  projects: 'projects',
  issueStatuses: 'issueStatuses',
  issueStatus: 'issueStatus',
  qualityConfig: 'qualityConfig',
  statusCategory: 'statusCategory',
  prodBugs: 'prodBugs',
};

export const useFetchIntegrationDetails = (id: string) => {
  return useQuery(
    [QUERY_KEYS.intDetails],
    () => ENDPOINTS.getIntegrationDetails(id),
    {
      enabled: !!id,
      keepPreviousData: false,
    }
  );
};

export const useFetchIssueTypeMap = (integrationId: string) => {
  return useQuery([QUERY_KEYS.issueType, integrationId], () =>
    ENDPOINTS.getIssueTypeMap(integrationId)
  );
};

export const useIssueTpyeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.issueTypeUpdate],
    ENDPOINTS.updateIssueTypeMap
  );
};

export const useIntegrationMutation = () => {
  return useCustomMutation([QUERY_KEYS.intDetails], ENDPOINTS.integrateRequest);
};

export const useBottomView = (integrationId: string) => {
  return useQuery([QUERY_KEYS.bottomView, integrationId], () =>
    ENDPOINTS.getBottomView(integrationId)
  );
};

export const useAddUpdateBottomViewMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.bottomView],
    ENDPOINTS.addUpdateBottomView
  );
};

export const useTableView = (integrationId: string) => {
  return useQuery([QUERY_KEYS.tableView, integrationId], () =>
    ENDPOINTS.getTableView(integrationId)
  );
};

export const useFetchProjectDetails = (integrationId: string) => {
  return useQuery([QUERY_KEYS.projects], () =>
    ENDPOINTS.getProjects(integrationId)
  );
};

export const useAddUpdateProjectsMutation = () => {
  return useCustomMutation([QUERY_KEYS.projects], ENDPOINTS.addUpdateProjects);
};

export const useIssueStatuses = (integrationId: string) => {
  return useQuery([QUERY_KEYS.issueStatuses, integrationId], () =>
    ENDPOINTS.getIssueStatuses(integrationId)
  );
};

export const useConfiguredIssueStatus = (integrationId: string) => {
  return useQuery([QUERY_KEYS.issueStatus], () =>
    ENDPOINTS.getConfiguredIssueStatus(integrationId)
  );
};

export const useConfiguredIssueStatusMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.issueStatus],
    ENDPOINTS.postConfiguredIssueStatus
  );
};

export const useQualityConfig = (integrationId: string) => {
  return useQuery([QUERY_KEYS.qualityConfig], () =>
    ENDPOINTS.getQualityConfig(integrationId)
  );
};

export const useQualityConfigMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.qualityConfig],
    ENDPOINTS.postQualityConfig
  );
};

export const useStatusCategory = (integrationId: string) => {
  return useQuery([QUERY_KEYS.statusCategory, integrationId], () =>
    ENDPOINTS.getStatusCategory(integrationId)
  );
};

export const useStatusCategoryMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.statusCategory],
    ENDPOINTS.postStatusCategory
  );
};

export const useProdBugs = (integrationId: string) => {
  return useQuery([QUERY_KEYS.prodBugs, integrationId], () =>
    ENDPOINTS.getProdBugs(integrationId)
  );
};

export const useProdBugsMutation = () => {
  return useCustomMutation([QUERY_KEYS.prodBugs], ENDPOINTS.postProdBugs);
};
