import {
  CFR_CHART_KEYS,
  CustomTooltip,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  MetricSidebar,
  PeriodType,
  PR_CHART_KEYS,
} from '@devd-client/devd/components';
import moment from 'moment';
import { useCallback, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { QUERY_KEYS } from '../../../graphql';

interface LineChartGoalsProps {
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  selected: PeriodType;
}

export const LineChartGoals = ({
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  selected,
}: LineChartGoalsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: showLabel ? 0 : -20,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={chartMetadata?.xAxis}
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 9, fontWeight: 500 }}
          />

          <YAxis
            label={
              !showLabel
                ? {}
                : {
                    value: chartMetadata?.ylabel,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 11,
                    fontWeight: 400,
                  }
            }
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 400 }}
          />

          <Tooltip
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />

          {showLabel && (
            <Legend
              verticalAlign="bottom"
              iconType="circle"
              iconSize={11}
              height={36}
              align="left"
              wrapperStyle={{ left: 35, fontSize: 11 }}
            />
          )}

          {keys?.map((el: any) => (
            <Line
              key={el.key}
              dataKey={el.key}
              name={el.name}
              stroke={el.color}
              strokeWidth={2}
              activeDot={{
                r: 6,
                strokeWidth: 2,
                stroke: '#9DB2BF',
                cursor: 'pointer',
                onClick: (event: any, payload: any) => {
                  handleModalOpen();
                  setClickedData({ ...payload, name: el.name });
                  setCurrentChartMeta(chartMetadata);
                },
                className: 'active-dot',
              }}
              dot={{
                fill: el.color,
                stroke: el.color,
                strokeWidth: 3,
                r: 2,
              }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...GOAL_METRIC_GIT_KEYS,
        ...GOAL_METRIC_JIRA_KEYS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={QUERY_KEYS.goalsMetric}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={`${
              Array.isArray(clickedData)
                ? moment(clickedData[0]?.payload?.startDate).format('DD MMM')
                : moment(clickedData?.payload?.startDate).format('DD MMM')
            } - ${
              Array.isArray(clickedData)
                ? moment(clickedData[0]?.payload?.endDate).format('DD MMM')
                : moment(clickedData?.payload?.endDate).format('DD MMM')
            }, ${
              clickedData
                ? (clickedData[0]?.payload?.meet
                    ? `Met (${clickedData[0].payload.meet})`
                    : '') +
                  (clickedData[0]?.payload?.meet &&
                  clickedData[0]?.payload?.notMeet
                    ? ', '
                    : '') +
                  (clickedData[0]?.payload?.notMeet
                    ? `Not Met (${clickedData[0].payload.notMeet})`
                    : '')
                : ''
            }`}
            payload={clickedData}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              sprintId: selected?.sprintId,
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
