import {
  OptionBase,
  ChakraStylesConfig,
  GroupBase,
  SelectComponentsConfig,
  chakraComponents,
} from 'chakra-react-select';

import { Box, Flex } from '@chakra-ui/react';
import { Filter } from '@devd-client/devd/components';
import { Children, useRef } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

interface Option extends OptionBase {
  label: string;
  value: any;
}

export const filterStyles: ChakraStylesConfig<
  Option,
  false,
  GroupBase<Option>
> = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: 'transparent',
    outline: 'none',
    border: 'none',
    padding: 0,
    mr: 2,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // pl: 2,
    mr: 5,
    w: 'full',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'black',
  }),
  container: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
      minW: 170,
      w: 'fit-content',
      zIndex: 9999,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      _hover: { background: state.isSelected ? '' : 'blue.100' },
      paddingLeft: state.data.value.includes('subTeam') ? 7 : 'auto',
      overflowX: 'hidden',
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      scrollbarWidth: 'none',
    };
  },
};

export const repoauthorFilterStyles: ChakraStylesConfig<
  Option,
  false,
  GroupBase<Option>
> = {
  ...filterStyles,
  container: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
      minW: 250,
      w: 'fit-content',
      zIndex: 9999,
      // ml: 4,
    };
  },
};

export const customFilterSelect: SelectComponentsConfig<
  Option,
  false,
  GroupBase<Option>
> = {
  Placeholder: ({ children, ...props }) => (
    <chakraComponents.Placeholder {...props}>
      <Flex
        justifyContent={'space-evenly'}
        alignItems={'center'}
        gap={2}
        mr={3}
      >
        <Filter height={16} width={16} />
        {children}
      </Flex>
    </chakraComponents.Placeholder>
  ),
};

// // Below is the component that virtualises the repo options since some clients have repos > 1500. refer tanstack virtual docs.
// const customRepoOption: SelectComponentsConfig<
//   Option,
//   false,
//   GroupBase<Option>
// > = {
//   MenuList: ({ children, ...props }) => {
//     const parentRef = useRef<HTMLDivElement>(null);
//     const childrenArray = Children.toArray(children);

//     const rowVirtualizer = useVirtualizer({
//       count: childrenArray.length,
//       getScrollElement: () => parentRef.current,
//       estimateSize: () => 35,
//       overscan: 5,
//     });

//     return (
//       <chakraComponents.MenuList {...props}>
//         <Box
//           className="test"
//           ref={parentRef}
//           style={{
//             height: `${
//               childrenArray.length > 100 ? 2000 : childrenArray.length * 50
//             }px`,
//             width: '250px',
//             overflowY: 'auto',
//           }}
//         >
//           <Box
//             style={{
//               height: `${rowVirtualizer.getTotalSize()}px`,
//               position: 'relative',
//               width: '100%',
//             }}
//           >
//             {rowVirtualizer.getVirtualItems().map((virtualRow: any) => {
//               const child = childrenArray[virtualRow.index];
//               return (
//                 <Box
//                   key={virtualRow.index}
//                   style={{
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: `${virtualRow.size}px`,
//                     transform: `translateY(${virtualRow.start}px)`,
//                   }}
//                 >
//                   {child}
//                 </Box>
//               );
//             })}
//           </Box>
//         </Box>
//       </chakraComponents.MenuList>
//     );
//   },
// };
