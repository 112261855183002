export function ConfluenceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          id="a"
          x1="28.607"
          y1="-60.825"
          x2="11.085"
          y2="-50.756"
          gradientTransform="matrix(1, 0, 0, -1, 0, -29.66)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stop-color="#0052cc" />
          <stop offset="1" stop-color="#2684ff" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="621.442"
          y1="1817.567"
          x2="603.915"
          y2="1827.64"
          gradientTransform="matrix(-1, 0, 0, 1, 624.83, -1816.71)"
          xlinkHref="#a"
        />
      </defs>
      <title>file_type_confluence</title>
      <path
        d="M3.015,23.087c-.289.472-.614,1.02-.891,1.456a.892.892,0,0,0,.3,1.212l5.792,3.564a.89.89,0,0,0,1.226-.29l.008-.013c.231-.387.53-.891.855-1.43,2.294-3.787,4.6-3.323,8.763-1.336l5.743,2.731A.892.892,0,0,0,26,28.559l.011-.024L28.766,22.3a.891.891,0,0,0-.445-1.167c-1.212-.57-3.622-1.707-5.792-2.754C14.724,14.586,8.09,14.831,3.015,23.087Z"
        style={{ fill: 'url(#a)' }}
      />
      <path
        d="M28.985,8.932c.289-.472.614-1.02.891-1.456a.892.892,0,0,0-.3-1.212L23.785,2.7a.89.89,0,0,0-1.236.241.584.584,0,0,0-.033.053c-.232.387-.53.891-.856,1.43-2.294,3.787-4.6,3.323-8.763,1.336L7.172,3.043a.89.89,0,0,0-1.187.421l-.011.024L3.216,9.726a.891.891,0,0,0,.445,1.167c1.212.57,3.622,1.706,5.792,2.753C17.276,17.433,23.91,17.179,28.985,8.932Z"
        style={{ fill: 'url(#b)' }}
      />
    </svg>
  );
}

export default ConfluenceIcon;
