export function ErlangIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="#000000"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 512 512"
      enable-background="new 0 0 512 512"
      {...props}
    >
      <g id="5151e0c8492e5103c096af88a51f65ac">
        <path
          display="inline"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M81.899,417.288c-25.125,0-50.248,0-75.369,0
           c-1.006,0-2.013,0-3.015,0H0.5V94.712h4.521c0.503,0,1.006,0,1.509,0c21.104,0,42.205,0,63.31,0
           c-4.396,7.535-8.916,15.478-15.075,25.625c-16.268,26.81-30.619,64.577-33.16,105.519C16.473,308.46,45.624,377.708,81.899,417.288
           z M445.176,94.712c23.966,36.337,41.839,86.771,34.67,149.229c-97.979,0-195.959,0-293.938,0
           c1.576,62.386,12.966,111.232,48.234,137.169c74.06,54.477,156.644-17,183.898-66.32c24.236,13.702,50.381,26.054,73.864,39.19
           c-9.985,15.827-38.816,57.657-42.209,63.309c20.601,0,41.202,0,61.804,0c0-107.522,0-215.054,0-322.576
           C489.394,94.712,467.282,94.712,445.176,94.712z M324.587,168.575c8.263-89.932-140.23-83.146-134.159,0
           C235.149,168.575,279.866,168.575,324.587,168.575z"
        ></path>
      </g>
    </svg>
  );
}

export default ErlangIcon;
