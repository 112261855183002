import { FC } from 'react';
import styled from 'styled-components';
import InfoDetails from './InfoDetails';
import { Loader } from '@devd-client/devd/components';

const ListViewContainer = styled.div`
  width: 100%;
`;

interface ListViewProps {
  isFetching: boolean;
}

const ListView: FC<ListViewProps> = ({ isFetching }) => {
  return (
    <>
      <ListViewContainer>
        <InfoDetails />
      </ListViewContainer>
      {isFetching && <Loader />}
    </>
  );
};

export default ListView;
