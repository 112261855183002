import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import MetricDetails from '.';
import { usePrDetailsPage } from '../apis';

export const PrDetailsPage = () => {
  const { data } = usePrDetailsPage();
  return (
    <>
      <MetaTags title="PR Details | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <MetricDetails />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};
