import { FC, Fragment } from 'react';
import { Box, Divider, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { SlideBar } from '../../../appSlideBar';
import { Paginate } from '../../../appPaginate';
import moment from 'moment';

interface QualitySideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  qualityData: any;
  heading: string;
  subHeading: string;
  isFetching: boolean;
  handlePageClick: any;
}

const QualitySideBar: FC<QualitySideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  qualityData,
  heading,
  subHeading,
  isFetching,
  handlePageClick,
}) => {
  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Stack spacing={1}>
          {isFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {qualityData?.data?.map((item: any, idx: number, arr: any) => (
            <Fragment key={item?.id}>
              <Box p={2}>
                <Stack spacing={1}>
                  <Box>
                    {item?.date && (
                      <Text
                        fontFamily="heading"
                        fontSize="xs"
                        color="text.secondary"
                        fontWeight="semibold"
                      >
                        {moment(item?.date).format('lll')}
                      </Text>
                    )}
                    <Flex gap={2} alignContent={'center'} mb={1}>
                      {item?.project && (
                        <Text
                          fontSize="lg"
                          fontFamily="heading"
                          color="gray.700"
                          fontWeight="medium"
                        >
                          Project: {item?.project}
                        </Text>
                      )}
                    </Flex>

                    <Flex mr={3} key={`att-${idx}`} align="flex-start">
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="semibold"
                        color="gray.600"
                        whiteSpace={'nowrap'}
                        mr={1}
                      >
                        Branch
                      </Text>{' '}
                      <Text mt={'-3px'}>:</Text>
                      <Text
                        ml={1}
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="medium"
                        color="text.secondary"
                      >
                        {item?.branch}
                      </Text>
                    </Flex>

                    <Flex gap={2}>
                      <Flex mr={3} key={`att-${idx}`} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Expected Value
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item?.expectedValue}
                        </Text>
                      </Flex>

                      <Flex mr={3} key={`att-${idx}`} align="flex-start">
                        <Text
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="semibold"
                          color="gray.600"
                          whiteSpace={'nowrap'}
                          mr={1}
                        >
                          Value
                        </Text>{' '}
                        <Text mt={'-3px'}>:</Text>
                        <Text
                          ml={1}
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.secondary"
                        >
                          {item?.value}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex mr={3} key={`att-${idx}`} align="flex-start">
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="semibold"
                        color="gray.600"
                        whiteSpace={'nowrap'}
                        mr={1}
                      >
                        Analysis Id
                      </Text>{' '}
                      <Text mt={'-3px'}>:</Text>
                      <Text
                        ml={1}
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="medium"
                        color="text.secondary"
                      >
                        {item?.analysisId}
                      </Text>
                    </Flex>
                  </Box>
                </Stack>
              </Box>
              {idx !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}

          <Box marginLeft="-20px" my={'16px'}>
            {qualityData?.data?.length > 1 && (
              <Paginate
                currentPage={qualityData?.currentPage + 1}
                totalPageCount={qualityData?.totalPages}
                pageSize={20}
                onPageChange={(page) => handlePageClick(page as number)}
              />
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default QualitySideBar;
