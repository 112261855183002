import { useTheme } from '@chakra-ui/react';
import React, { FC } from 'react';
import styled from 'styled-components';
import DataTable from './DataTable';

type TableHeadingProps = {
  color?: string;
};

const RightSideTableContainer = styled.div`
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
`;

const TableHeading = styled.h1<TableHeadingProps>`
  font-size: 18px;
  font-family: 'Manrope';
  font-weight: 700;
  color: ${({ color }) => color || '#333'};
  margin-bottom: 10px;
`;

interface RightSideTableProps {
  handlePageClick: (event: any) => void;
  isFetching: boolean;
}

const RightSideTable: FC<RightSideTableProps> = ({
  handlePageClick,
  isFetching,
}) => {
  const {
    colors: { text },
  } = useTheme();
  return (
    <RightSideTableContainer>
      <TableHeading color={text.primary}>JIRA tickets</TableHeading>
      <DataTable isFetching={isFetching} handlePageClick={handlePageClick} />
    </RightSideTableContainer>
  );
};

export default React.memo(RightSideTable);
