import { FC } from 'react';
import { Tooltip } from '@chakra-ui/react';
import {
  CardText,
  Dot,
  GoalCardItemContainer,
  Legend,
  TagLabel,
  TagLabels,
  Text,
} from './GoalCardItem.styled';

interface GoalCardItemProps {
  goal: any;
  selectedGoal: string;
  setSelectedGoal: (selectedGoal: string) => void;
}

const GoalCardItem: FC<GoalCardItemProps> = ({
  goal,
  selectedGoal,
  setSelectedGoal,
}) => {
  return (
    <GoalCardItemContainer
      selected={goal.name === selectedGoal}
      onClick={() => setSelectedGoal(goal.name)}
    >
      {goal?.tags?.length > 0 && (
        <TagLabels>
          {goal?.tags?.map((item: string) => (
            <TagLabel key={item}>{item}</TagLabel>
          ))}
        </TagLabels>
      )}

      <CardText>{goal.title}</CardText>

      {goal?.goalStatus && (
        <Tooltip hasArrow label={'Percentage change'} placement="bottom-start">
          <Legend>
            <Dot color={goal?.goalStatus?.color} />
            <Text>
              {parseFloat(goal?.goalStatus?.improvementPercent).toFixed(2)}%
            </Text>
          </Legend>
        </Tooltip>
      )}
    </GoalCardItemContainer>
  );
};

export default GoalCardItem;
