import styled from 'styled-components';

/* eslint-disable-next-line */
export interface DevdComponentsProps {}

const StyledDevdComponents = styled.div`
  color: pink;
`;

export function DevdComponents(props: DevdComponentsProps) {
  return (
    <StyledDevdComponents>
      <h1>Welcome to DevdComponents!</h1>
    </StyledDevdComponents>
  );
}

export default DevdComponents;
