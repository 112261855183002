import React, { FC, useContext } from 'react';
import ActivityLogTable from './ActivityLogTable';
import LegendBar from './LegendBar';
import Toolbar from './Toolbar';
import styled from 'styled-components';
import { ISelect } from '@devd-client/devd/components';
import { ActivityLogContext } from '../../contexts/activityLogContext';
import { Text } from '@chakra-ui/react';

const ActivityLogContentContainer = styled.div`
  min-width: fit-content;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;
const LegendBarContainer = styled.div`
  position: sticky;
  top: 0;
  padding-top: 10px;
  background-color: inherit;
`;
interface ActivityLogContentProps {
  weekArray: string[];
  handleOptionSelect: (value: ISelect) => void;
  workLogType: ISelect;
  teamId: string;
  contributorData: any;
  contributorIds: string[];
  setContributorIds: (value: string[]) => void;
  activityLogData: any;
}

const ActivityLogContent: FC<ActivityLogContentProps> = ({
  weekArray,
  handleOptionSelect,
  workLogType,
  teamId,
  contributorData,
  contributorIds,
  setContributorIds,
  activityLogData,
}) => {
  return (
    <ActivityLogContentContainer>
      <Toolbar
        handleOptionSelect={handleOptionSelect}
        workLogType={workLogType}
        contributorData={contributorData}
        contributorIds={contributorIds}
        setContributorIds={setContributorIds}
      />
      <LegendBarContainer>
        <LegendBar activityLogData={activityLogData} />
      </LegendBarContainer>

      <Text fontSize="sm" fontWeight="normal" color="text.primary">
        Total rows: {activityLogData?.rows?.length}
      </Text>
      {
        <ActivityLogTable
          weekArray={weekArray}
          workLogType={workLogType.value}
          teamId={teamId}
          activityLogData={activityLogData}
        />
      }
    </ActivityLogContentContainer>
  );
};

export default ActivityLogContent;
