import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';

export const ConfirmModal = ({ isOpen, headerText, bodyText }: any) => {
  const { onClose } = useDisclosure();
  const navigate = useNavigate();

  const onSubmit = () => {
    navigate('/settings/integrations');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={'2px'}
        boxShadow="0px 0px 14px rgba(0, 0, 0, 0.1)"
      >
        <ModalHeader
          fontFamily="heading"
          fontWeight="semibold"
          lineHeight="22px"
          color="text.primary"
          textAlign={'center'}
        >
          {headerText}{' '}
        </ModalHeader>

        <ModalBody>
          <Text
            fontFamily="heading"
            fontWeight="normal"
            fontSize="xs"
            color="text.secondary"
            lineHeight="17px"
            textAlign={'center'}
          >
            {bodyText
              ? bodyText
              : headerText === ''
              ? `Note: It might take 15 mins to 1 hour to pull data and update
            metrics.`
              : ''}
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button w="120px" variant="primary" size={'md'} onClick={onSubmit}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
