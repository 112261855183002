import React, { useEffect, useState } from 'react';
import { QUERY_KEYS, useProdBugs, useProdBugsMutation } from '../api';
import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import IssueFilterModal from '../../IssueFilterModal';
import Loader from '../../appLoader';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../../appToast';

interface ProdBugsProps {
  integrationId: string;
}

const ProdBugs = ({ integrationId }: ProdBugsProps) => {
  const issueFilterModal = useDisclosure();
  const queryClient = useQueryClient();
  const [newToast] = useToastHook();
  const { data, isLoading } = useProdBugs(integrationId);
  const {
    mutate: handleProdBugsMutation,
    isLoading: handleProdBugsMutationLoader,
  } = useProdBugsMutation();
  const [prodBugConditions, setProdBugConditions] = useState<any>();

  useEffect(() => {
    setProdBugConditions(data?.dto);
  }, [data]);

  return (
    <>
      <Box>
        <Text
          fontFamily="heading"
          fontSize="lg"
          fontWeight="semibold"
          color="text.primary"
          mb={2}
        >
          Production Bugs Configuration
        </Text>

        <Text
          fontSize="md"
          fontFamily="heading"
          fontWeight="medium"
          color="text.primary"
          mb={2}
        >
          {data?.dto?.conditions?.length || 0}{' '}
          {`Issue Filter Conditio${
            data?.dto?.conditions?.length > 1 ? `ns` : 'n'
          } Linked`}
        </Text>
        <Button variant="primary" size="sm" onClick={issueFilterModal.onOpen}>
          {`Configure >`}
        </Button>
      </Box>
      {issueFilterModal.isOpen && (
        <IssueFilterModal
          isOpen={issueFilterModal.isOpen}
          onClose={issueFilterModal.onClose}
          loading={handleProdBugsMutationLoader}
          issueFilterConditions={prodBugConditions}
          onSubmitAction={(payload) => {
            handleProdBugsMutation(
              { ...payload, integrationId },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([QUERY_KEYS.prodBugs]);
                  newToast({
                    message: 'Prod Bugs configuration updated successfully!',
                    status: 'success',
                  });
                  issueFilterModal.onClose();
                },
              }
            );
          }}
        />
      )}

      {isLoading && <Loader />}
    </>
  );
};

export default ProdBugs;
