import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  NumberInput,
  Flex,
  NumberInputField,
  Divider,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { TeamMenu } from '../../../../teammenu/index';
import { ISelect } from '../../../../customSelect';
import { useFetchThreshold, useThresholdMutation } from '../../../apis';
import { Loader } from '../../../../appLoader/index';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { useToastHook } from '../../../../appToast/index';
import { resetThresholdData } from '../../../apis/endpoints';

type CustomBadgeProps = {
  dotColor?: string;
  color?: string;
};

const BadgeContainer = styled.span<CustomBadgeProps>`
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
  font-family: ${(props) => props.theme.fonts.heading};
  line-height: 1.5;
  color: ${(props) => props.theme.colors.text.primary};

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 4px;
    display: inline-block;
    background-color: ${(props) => props.dotColor || '#ccc'};
    vertical-align: center;
  }
`;

interface SetGoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  metricTitle: string;
  queryKey: string;
}

const SetGoalModal: React.FC<SetGoalModalProps> = ({
  isOpen,
  onClose,
  metricTitle,
  queryKey,
}) => {
  const {
    mutate: handleThresholdSubmit,
    isLoading: handleThresholdSubmitLoading,
  } = useThresholdMutation();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const params = new URLSearchParams(window.location.search);
  const metric = params.get('tab');
  const team = params.get('team');
  const [selectedTeam, setSelectedTeam] = useState<ISelect>({
    value: team as string,
    label: team as string,
  });
  const [resetLoading, setResetLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const { data, isLoading } = useFetchThreshold(metric!, selectedTeam.value);

  const [dataToSend, setDataToSend] = useState<any>([]);

  useEffect(() => {
    setDataToSend(data?.dto?.fields);
  }, [data?.dto?.fields]);

  useEffect(() => {
    if (!dataToSend) return;

    if (
      Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) <
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        ) &&
      dataToSend[0]?.high === null
    ) {
      setErrMessage('On Track value should be greater than At Risk value');
    } else if (
      Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) <
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        ) &&
      dataToSend[0]?.low === null
    ) {
      setErrMessage('At Risk value should be greater than On Track value');
    } else if (
      dataToSend[0]?.unit === '%' &&
      (Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) > 100 ||
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        ) > 100)
    ) {
      setErrMessage('Value should not exceed 100%');
    } else if (
      Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) < 0 ||
      Number(
        dataToSend[0]?.high !== null
          ? dataToSend[0]?.high
          : dataToSend[2]?.high !== null
          ? dataToSend[2]?.high
          : 0
      ) < 0
    ) {
      setErrMessage('Negative value not allowed');
    } else if (
      isNaN(
        Number(
          dataToSend[0]?.low !== null
            ? dataToSend[0]?.low
            : dataToSend[2]?.low !== null
            ? dataToSend[2]?.low
            : 0
        )
      ) ||
      isNaN(
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        )
      )
    ) {
      setErrMessage('Please enter a value');
    } else {
      setErrMessage('');
    }
  }, [dataToSend]);

  const handleSubmit = () => {
    if (
      Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) <
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        ) &&
      dataToSend[0]?.high === null
    ) {
      newToast({
        message: `On Track value should be greater than At Risk value`,
        status: 'error',
      });
    } else if (
      Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) <
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        ) &&
      dataToSend[0]?.low === null
    ) {
      newToast({
        message: `At Risk value should be greater than On Track value`,
        status: 'error',
      });
    } else if (
      dataToSend[0]?.unit === '%' &&
      (Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) > 100 ||
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        ) > 100)
    ) {
      newToast({
        message: `Value should not exceed 100%`,
        status: 'error',
      });
    } else if (
      Number(
        dataToSend[0]?.low !== null
          ? dataToSend[0]?.low
          : dataToSend[2]?.low !== null
          ? dataToSend[2]?.low
          : 0
      ) < 0 ||
      Number(
        dataToSend[0]?.high !== null
          ? dataToSend[0]?.high
          : dataToSend[2]?.high !== null
          ? dataToSend[2]?.high
          : 0
      ) < 0
    ) {
      newToast({
        message: `Negative value not allowed`,
        status: 'error',
      });
    } else if (
      isNaN(
        Number(
          dataToSend[0]?.low !== null
            ? dataToSend[0]?.low
            : dataToSend[2]?.low !== null
            ? dataToSend[2]?.low
            : 0
        )
      ) ||
      isNaN(
        Number(
          dataToSend[0]?.high !== null
            ? dataToSend[0]?.high
            : dataToSend[2]?.high !== null
            ? dataToSend[2]?.high
            : 0
        )
      )
    ) {
      newToast({
        message: `Please enter a value`,
        status: 'error',
      });
    } else {
      handleThresholdSubmit(
        {
          metric: metric!,
          teamId: selectedTeam.value,
          body: {
            ...data?.dto,
            fields: dataToSend?.map((el: any) => {
              return el.key === 'medium'
                ? {
                    ...el,
                    high:
                      dataToSend[0].low !== null
                        ? dataToSend[0].low
                        : dataToSend[2].low !== null
                        ? dataToSend[2].low
                        : '',
                    low:
                      dataToSend[0].high !== null
                        ? dataToSend[0].high
                        : dataToSend[2].high !== null
                        ? dataToSend[2].high
                        : '',
                  }
                : el;
            }),
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
            queryClient.invalidateQueries(['threshold']);
            queryClient.invalidateQueries([metric]);
            onClose();
          },
        }
      );
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text
              color="text.primary"
              fontFamily="heading"
              fontSize="md"
              lineHeight="20px"
              fontWeight="bold"
            >
              {`Set Goal (${metricTitle})`}
            </Text>
          </ModalHeader>
          <ModalCloseButton color="text.secondary" fontSize="xs" />
          <ModalBody>
            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              hideShowDetails
              mb={4}
            />
            <Divider />

            {dataToSend
              ?.filter((item: any) => item.key !== 'medium')
              ?.map((field: any, index: number) => (
                <Flex
                  key={`field-${index}`}
                  width="80%"
                  justify="space-between"
                  align="center"
                  py={2}
                  px={2}
                >
                  {field.key === 'good' ? (
                    <BadgeContainer color="text.primary" dotColor="#1DA44A">
                      On Track
                    </BadgeContainer>
                  ) : field.key === 'bad' ? (
                    <BadgeContainer color="text.primary" dotColor="#E65100">
                      At Risk
                    </BadgeContainer>
                  ) : (
                    ''
                  )}

                  {field?.key === 'medium' ? (
                    <></>
                  ) : (
                    <Flex direction="column">
                      <Text
                        fontSize="xs"
                        fontWeight="semibold"
                        fontFamily="heading"
                        color="text.secondary"
                      >
                        {field?.high !== null
                          ? 'Less Than'
                          : field?.low !== null
                          ? 'Greater Than Equal'
                          : ''}
                      </Text>
                      <InputGroup size="sm">
                        <NumberInput
                          max={dataToSend[0]?.unit === '%' ? 100 : 100000}
                          width="140px"
                          size={'sm'}
                          value={
                            field?.high !== null
                              ? field?.high
                              : field?.low !== null
                              ? field?.low
                              : ''
                          }
                        >
                          <NumberInputField
                            onChange={(e) => {
                              setDataToSend((prevData: any) => {
                                const updatedData = prevData?.map(
                                  (item: any) => {
                                    if (item.key === field.key) {
                                      return {
                                        ...item,
                                        high:
                                          field?.high !== null
                                            ? e.target.value
                                            : null,
                                        low:
                                          field?.low !== null
                                            ? e.target.value
                                            : null,
                                      };
                                    }

                                    return item;
                                  }
                                );

                                return updatedData;
                              });
                            }}
                          />
                        </NumberInput>
                        <InputRightAddon bg="white">
                          {field?.unit}
                        </InputRightAddon>
                      </InputGroup>
                    </Flex>
                  )}
                </Flex>
              ))}

            <Text
              fontFamily="heading"
              fontSize="sm"
              fontWeight="normal"
              color="text.secondary"
            >
              If progress falls between 'On Track' and 'At Risk', it will be
              considered{' '}
              <BadgeContainer color="text.primary" dotColor="#FFAD00">
                Behind.
              </BadgeContainer>
            </Text>
            <Flex>
              {errMessage && (
                <Text color="red.500" fontSize="sm" bg="red.50" px={1}>
                  {errMessage}
                </Text>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              size="sm"
              variant="secondary"
              mr={3}
              onClick={() => {
                setResetLoading(true);

                resetThresholdData(metric!).then((res: any) => {
                  if (res) {
                    setDataToSend(res?.dto?.fields);
                    setResetLoading(false);
                  }
                });
              }}
            >
              Set Default
            </Button>
            <Button size="sm" variant="filled" onClick={handleSubmit}>
              Save
            </Button>
          </ModalFooter>
          {(isLoading || handleThresholdSubmitLoading || resetLoading) && (
            <Loader />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default React.memo(SetGoalModal);
