import { SignUpProps } from './api';
import apiClient from './apiClient';
import { gql } from 'graphql-request';
import graphQLClient from './graphqlClient';

// @TODO we need type definations from the be documention tool, not blocking anything though
type Todo = {
  id: string;
  title: string;
  completed: boolean;
};

export const getTodos = (): Promise<Todo[]> => apiClient('/todos');

export const getTodosFromGraphql = (): Promise<{
  todos: {
    data: Todo[];
  };
}> =>
  graphQLClient.request(gql`
    query {
      todos {
        data {
          title
          id
          completed
        }
      }
    }
  `);

export const handleSignup = (params: SignUpProps) => {
  const { firstName, lastName, email, password, organizationName, type } =
    params;
  return apiClient('/v2/account/signup', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      password,
      organizationName,
      type,
      title: 'test',
    }),
  });
};
