import { useTheme } from '@chakra-ui/react';
import moment from 'moment';
import styled from 'styled-components';

const CustomTooltipContainer = styled.div`
  width: 180px;
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope';
  font-size: '10px';
`;

type CustomTooltipLabelProps = {
  fontSize?: string;
  color?: string;
};

const CustomTooltipLabel = styled.p<CustomTooltipLabelProps>`
  font-size: ${({ fontSize }) => fontSize || '10px'};
  font-weight: 700;
  color: ${({ color }) => color || '#777'};
`;

export interface CustomTooltipProps {
  active: boolean;
  payload: PayloadType;
  label: string;
}

type PayloadType = {
  chartType: undefined | string;
  color: string;
  datakey: string;
  fill: string;
  formatter?: string | undefined;
  name: string;
  payload: any;
  type: undefined | string;
  unit: undefined | string;
  value: string;
};

const CustomTooltip = ({ active, payload, label }: any) => {
  const { colors } = useTheme();
  if (active && payload && payload.length) {
    const { date, name, ...newLabelData } = payload[0] && payload[0].payload;

    return (
      <CustomTooltipContainer>
        {Array.from(Object.keys(newLabelData), (k) => (
          <CustomTooltipLabel
            key={k}
            color={colors.text.secondary}
            fontSize="12px"
          >{`${newLabelData[k]}%  ${k}`}</CustomTooltipLabel>
        ))}
      </CustomTooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
