import { Text } from '@chakra-ui/react';
import {
  ChangeEvent,
  DragEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

type FileUploadLabelType = {
  dragActive: boolean;
};

type FileType = {
  width: boolean | string;
};

const FileUploadForm = styled.form<FileType>`
  height: 16rem;
  width: ${({ width }) => (width ? '100%' : '28rem')};
  max-width: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const FileUploadLabel = styled.label<FileUploadLabelType>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  cursor: pointer;
  background-color: ${({ dragActive }) => (dragActive ? '#fff' : '#f8fafc')};
`;

const FileUploadLine = styled.p``;

const FileUploadButton = styled.button`
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  cursor: pointer;
  background-color: transparent;
  &:hover {
    text-decoration-line: underline;
  }
`;

const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  cursor: pointer;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

interface DragDropFileProps {
  file: any;
  width?: string;
  setFile: (file: any) => void;
}

export const DragDropFile: FC<DragDropFileProps> = ({
  file,
  setFile,
  width = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState<boolean>(false);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const onButtonClick = () => {
    if (inputRef.current === null) return;
    inputRef.current.click();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files === null) return;
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <FileUploadForm
      width={width}
      onDragEnter={handleDrag}
      onSubmit={(e: SyntheticEvent) => e.preventDefault()}
    >
      <FileUploadInput
        id="input-file-upload"
        type="file"
        accept=".xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xml, .xlam, .xla, .xlw, .xlr, .csv "
        multiple={false}
        ref={inputRef}
        onChange={handleChange}
      />
      <FileUploadLabel htmlFor="input-file-upload" dragActive={dragActive}>
        <>
          <FileUploadLine>Drag and drop your file here or</FileUploadLine>
          <FileUploadButton onClick={onButtonClick}>
            Upload a file
          </FileUploadButton>
        </>
      </FileUploadLabel>
      <Text fontSize={'14px'} mt={4}>
        {file?.name}
      </Text>
      {dragActive && (
        <DragFileElement
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></DragFileElement>
      )}
    </FileUploadForm>
  );
};

export default DragDropFile;
