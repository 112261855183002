export interface BottomViewResponse {
  currentPage: number;
  dto: BottomViewType;
  error: any;
  totalItems: number;
  totalPages: number;
}

export type AddBottomViewProps = {
  name: string;
  value?: string;
  type?: string;
};

export type BottomViewType = {
  name: string;
  value?: string;
  type?: string;
  options: Array<string>;
};

export const jiraTabs: Record<string, number> = {
  jiraFieldMap: 0,
  statusCategoryMap: 1,
  stageMap: 2,
  issueFieldMap: 3,
  projects: 4,
  prodBugs: 5,
};

export const tabsWithoutFieldMap: Record<string, number> = {
  statusCategoryMap: 0,
  stageMap: 1,
  issueFieldMap: 2,
  projects: 3,
  prodBugs: 4,
};

export const clickupTabs: Record<string, number> = {
  clickupFieldMap: 0,
  statusCategoryMap: 1,
  stageMap: 2,
  issueFieldMap: 3,
  projects: 4,
  prodBugs: 5,
};

export const azureBoardTabs: Record<string, number> = {
  azureBoardsMap: 0,
  statusCategoryMap: 1,
  stageMap: 2,
  issueFieldMap: 3,
  projects: 4,
  prodBugs: 5,
};
