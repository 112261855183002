import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '../../hooks/useCustomMutation';

const QUERY_KEYS = {
  sprint: 'sprint',
  favSprint: 'favSprint',
};

export const useFetchSprint = () => {
  return useQuery([QUERY_KEYS.sprint], ENDPOINTS.getSprintList, {
    keepPreviousData: true,
  });
};

export const useFavSprint = () => {
  return useCustomMutation([QUERY_KEYS.favSprint], ENDPOINTS.postFavSprint);
};

export const useRemoveFavSprint = () => {
  return useCustomMutation([QUERY_KEYS.favSprint], ENDPOINTS.deleteFavSprint);
};
