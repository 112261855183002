import { DataProps, ItemMetaType } from '../helpers/ActivityLog.types';
export type ActivityLogState = {
  workLogData: null | DataProps[];
  workLogMeta: null | ItemMetaType[];
  workLogDetails: null | any;
};

export enum ActivityLogActionTypes {
  WORK_LOG_DATA = '[ActivityLog] Get Work Log Data',
  WORK_LOG_META = '[ActivityLog] Get Work Log Meta',
  WORK_LOG_DETAILS = '[ActivityLog] Get Work Log Details',
}

export type ActivityLogAction = {
  type: ActivityLogActionTypes;
  payload?: any;
};

export const activityLogReducer = (
  state: ActivityLogState,
  action: ActivityLogAction
): ActivityLogState => {
  switch (action.type) {
    case ActivityLogActionTypes.WORK_LOG_DATA:
      return { ...state, workLogData: action?.payload };

    case ActivityLogActionTypes.WORK_LOG_META:
      return { ...state, workLogMeta: action?.payload };

    case ActivityLogActionTypes.WORK_LOG_DETAILS:
      return { ...state, workLogDetails: action?.payload };

    default:
      return state;
  }
};
