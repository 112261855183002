import { FC, useContext, useMemo } from 'react';
import { DataGrid } from '@devd-client/devd/components';
import { InitiativeContext } from '../../../../contexts/initiativesContext';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { useInitiativeUpdates } from '../../../../apis';

interface UpdatesTableProps {
  selectedProjectId: string;
}

const UpdatesTable: FC<UpdatesTableProps> = ({ selectedProjectId }) => {
  const {
    state: { updatesData },
    dispatch,
  } = useContext(InitiativeContext);

  const { isFetching: updatesLoading } = useInitiativeUpdates(
    selectedProjectId,
    dispatch
  );

  const rows: any[] = useMemo(() => updatesData?.dto || [], [updatesData?.dto]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('summary', {
        cell: (info) => info.getValue(),
        header: 'Summary',
        size: 300,
      }),

      columnHelper.accessor('status', {
        cell: (info) => info.getValue().toUpperCase(),
        header: 'Status',
      }),

      columnHelper.accessor('createdAt', {
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('YYYY-MM-DD'),
        header: 'Created At',
        size: 100,
      }),

      columnHelper.accessor('updatedBy', {
        cell: (info) => info.getValue(),
        header: 'Updated By',
      }),
    ],
    [updatesData?.dto]
  );

  return (
    <DataGrid
      showLoader={updatesLoading}
      columns={columns}
      data={rows}
      maxH="26rem"
      sticky="firstCol"
    />
  );
};

export default UpdatesTable;
