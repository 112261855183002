export const FeedbackIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 21H15.02M24 21H24.02M33 21H33.02M14 36V40.671C14 41.7367 14 42.2696 14.2185 42.5432C14.4084 42.7812 14.6965 42.9197 15.0011 42.9194C15.3513 42.919 15.7673 42.5861 16.5995 41.9204L21.3704 38.1037C22.345 37.324 22.8323 36.9341 23.375 36.6569C23.8564 36.411 24.3689 36.2312 24.8984 36.1225C25.4953 36 26.1194 36 27.3675 36H32.4C35.7603 36 37.4405 36 38.7239 35.346C39.8529 34.7708 40.7708 33.8529 41.346 32.7239C42 31.4405 42 29.7603 42 26.4V15.6C42 12.2397 42 10.5595 41.346 9.27606C40.7708 8.14708 39.8529 7.2292 38.7239 6.65396C37.4405 6 35.7603 6 32.4 6H15.6C12.2397 6 10.5595 6 9.27606 6.65396C8.14708 7.2292 7.2292 8.14708 6.65396 9.27606C6 10.5595 6 12.2397 6 15.6V28C6 29.8599 6 30.7899 6.20445 31.5529C6.75925 33.6235 8.37653 35.2408 10.4471 35.7956C11.2101 36 12.1401 36 14 36ZM16 21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20C15.5523 20 16 20.4477 16 21ZM25 21C25 21.5523 24.5523 22 24 22C23.4477 22 23 21.5523 23 21C23 20.4477 23.4477 20 24 20C24.5523 20 25 20.4477 25 21ZM34 21C34 21.5523 33.5523 22 33 22C32.4477 22 32 21.5523 32 21C32 20.4477 32.4477 20 33 20C33.5523 20 34 20.4477 34 21Z"
        stroke={props.color || 'black'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
