export function SettingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7901 38.7422L19.959 41.3711C20.3065 42.1537 20.8735 42.8186 21.5914 43.2852C22.3093 43.7518 23.1472 44.0001 24.0034 44C24.8596 44.0001 25.6975 43.7518 26.4154 43.2852C27.1333 42.8186 27.7004 42.1537 28.0479 41.3711L29.2167 38.7422C29.6328 37.8094 30.3328 37.0317 31.2168 36.52C32.1063 36.0069 33.1354 35.7883 34.1568 35.8956L37.0168 36.2C37.8681 36.2901 38.7273 36.1312 39.4901 35.7427C40.253 35.3541 40.8867 34.7526 41.3145 34.0111C41.7429 33.27 41.947 32.4206 41.902 31.5658C41.857 30.711 41.5649 29.8876 41.0612 29.1956L39.3679 26.8689C38.7649 26.0343 38.4427 25.0296 38.4479 24C38.4477 22.9732 38.7729 21.9727 39.3768 21.1422L41.0701 18.8156C41.5738 18.1235 41.8659 17.3001 41.9109 16.4453C41.9558 15.5906 41.7518 14.7411 41.3234 14C40.8956 13.2585 40.2619 12.657 39.499 12.2685C38.7362 11.8799 37.877 11.7211 37.0256 11.8111L34.1656 12.1156C33.1443 12.2228 32.1152 12.0042 31.2256 11.4911C30.3399 10.9765 29.6398 10.1947 29.2256 9.25778L28.0479 6.62889C27.7004 5.84634 27.1333 5.18143 26.4154 4.7148C25.6975 4.24816 24.8596 3.99986 24.0034 4C23.1472 3.99986 22.3093 4.24816 21.5914 4.7148C20.8735 5.18143 20.3065 5.84634 19.959 6.62889L18.7901 9.25778C18.3759 10.1947 17.6758 10.9765 16.7901 11.4911C15.9005 12.0042 14.8714 12.2228 13.8501 12.1156L10.9812 11.8111C10.1299 11.7211 9.27067 11.8799 8.50783 12.2685C7.74498 12.657 7.11123 13.2585 6.68342 14C6.25506 14.7411 6.05098 15.5906 6.09595 16.4453C6.14092 17.3001 6.433 18.1235 6.93675 18.8156L8.63008 21.1422C9.23397 21.9727 9.55916 22.9732 9.55897 24C9.55916 25.0268 9.23397 26.0273 8.63008 26.8578L6.93675 29.1844C6.433 29.8765 6.14092 30.6999 6.09595 31.5547C6.05098 32.4094 6.25506 33.2589 6.68342 34C7.11165 34.7411 7.74547 35.3423 8.50821 35.7308C9.27095 36.1193 10.1299 36.2784 10.9812 36.1889L13.8412 35.8844C14.8625 35.7772 15.8916 35.9958 16.7812 36.5089C17.6703 37.022 18.3736 37.804 18.7901 38.7422Z"
        stroke={props.color || 'black'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9998 30C27.3136 30 29.9998 27.3137 29.9998 24C29.9998 20.6863 27.3136 18 23.9998 18C20.6861 18 17.9998 20.6863 17.9998 24C17.9998 27.3137 20.6861 30 23.9998 30Z"
        stroke={props.color || 'black'}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
