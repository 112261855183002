import { Box, Flex } from '@chakra-ui/react';
import { AppIcon } from '@devd-client/devd/components';
import React, { Suspense } from 'react';

const Signup = React.lazy(() =>
  import('@devd-client/devd/auth').then((module) => ({
    default: module.Signup,
  }))
);
export function AsyncSignup() {
  return (
    <Box
      maxW="580px"
      m={'10vh auto'}
      p={'30px'}
      backgroundColor={'#f6f7f9'}
      boxShadow={'0 1px 10px rgba(0, 0, 0, 0.1)'}
    >
      <Flex justify={'center'}>
        <AppIcon height="28px" />
      </Flex>

      <Suspense>
        <Signup />
      </Suspense>
    </Box>
  );
}

export default AsyncSignup;
