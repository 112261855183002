import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';

export const SampleBarChart = ({ data }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart barGap={3} data={data}>
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          padding={{ left: 5, right: 5 }}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <YAxis
          label={{
            value: 'Number',
            angle: -90,
            position: 'insideLeft',
            fontSize: 11,
            fontWeight: 400,
          }}
          tickLine={false}
          axisLine={false}
          type="number"
          style={{ fontSize: 11, fontWeight: 400 }}
        />

        <CartesianGrid strokeDasharray="3 3" vertical={false} />

        <defs>
          <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0095E6" stopOpacity={0.8} />
            <stop
              offset="95%"
              stopColor="rgba(0, 149, 230, 0)"
              stopOpacity={0.8}
            />
          </linearGradient>
        </defs>

        <Legend
          verticalAlign="bottom"
          iconType="circle"
          iconSize={11}
          height={36}
          align="left"
          wrapperStyle={{ left: 35, fontSize: 11 }}
        />
        <Bar barSize={16} dataKey="closed" stackId="a" fill="#4C51BF" />
        <Bar barSize={16} dataKey="open" stackId="a" fill="#319795" />
        <Bar barSize={16} dataKey="inProgress" stackId="a" fill="#E53E3E" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SampleBarChart;
