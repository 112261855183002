import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';

interface CumulativeFlowDiagramAreaChartProps {
  data: any;
}

export const CumulativeFlowAreaChart = ({
  data,
}: CumulativeFlowDiagramAreaChartProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 5,
          left: 0,
          bottom: 10,
        }}
      >
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          padding={{ left: 5, right: 5 }}
          style={{ fontSize: 11, fontWeight: 400 }}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
        <YAxis
          label={{
            value: 'Number',
            angle: -90,
            position: 'insideLeft',
            fontSize: 11,
            fontWeight: 400,
          }}
          tickLine={false}
          axisLine={false}
          type="number"
          style={{ fontSize: 11, fontWeight: 400 }}
        />

        <CartesianGrid strokeDasharray="3 3" vertical={false} />

        <defs>
          <linearGradient id="colorInProgress" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorOpen" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorClosed" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>

        <Legend
          verticalAlign="bottom"
          iconType="circle"
          iconSize={11}
          height={36}
          align="left"
          wrapperStyle={{ left: 35, fontSize: 11 }}
        />

        <Area
          type="monotone"
          dataKey="closed"
          stroke="#82ca9d"
          strokeWidth="1.5"
          fillOpacity={1}
          fill="url(#colorClosed)"
        />

        <Area
          type="monotone"
          dataKey="inProgress"
          stroke="#ffc658"
          strokeWidth="1.5"
          fillOpacity={1}
          fill="url(#colorInProgress)"
        />

        <Area
          type="monotone"
          dataKey="open"
          stroke="#8884d8"
          strokeWidth="1.5"
          fillOpacity={1}
          fill="url(#colorOpen)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
