import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  contributors: 'contributors',
};

export const useFetchContributors = (
  searchInput: string,
  currentPage: number
) => {
  return useQuery(
    [QUERY_KEYS.contributors, searchInput, currentPage],
    () => ENDPOINTS.getContributors(searchInput, currentPage),
    {
      keepPreviousData: true,
    }
  );
};
