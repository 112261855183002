import { Box, SimpleGrid } from '@chakra-ui/react';
import {
  Loader,
  PageHeader,
  PageToolbar,
  TeamMenu,
  ISelect,
  PeriodType,
  durationData,
  useQueryState,
  removeEmptyQueryParams,
  DateFilter,
} from '@devd-client/devd/components';
import {
  AgingDistributionScatterChart,
  CumulativeFlowAreaChart,
  FlowScatterChart,
  LineChartCard,
  SampleBarChart,
} from '@devd-client/devd/ui-shared';

import { FC, useContext, useEffect, useState } from 'react';
import { FlowContext } from '../../contexts/flowContext';
import {
  useAgingDistributionGql,
  useFlowGql,
  useIssueCycleTimeGql,
} from '../../graphql';
import { useSearchParams } from 'react-router-dom';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';

interface FlowPageProps {
  metricsData: any;
}

export const FlowPage: FC<FlowPageProps> = ({ metricsData }) => {
  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');
  const helpDocLink = metricsData?.dto?.helpDoc;

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [appState, ,] = useAuth();
  const { selected, selectedTeam, setSelected, setSelectedTeam } =
    useDashboardStore();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const { dispatch } = useContext(FlowContext);

  const { isFetching, data: AreaChartData } = useFlowGql(
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected.sprintId,
    dispatch
  );

  const { isFetching: isFetchingCycleTime, data: ScatterChartData } =
    useIssueCycleTimeGql(
      selected.startDate,
      selected.endDate,
      selectedTeam.value,
      selected?.sprintId,
      dispatch
    );
  const {
    isFetching: isFetchingAgingDistribution,
    data: AgingDistributionScatterChartData,
  } = useAgingDistributionGql(
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected?.sprintId,
    dispatch
  );

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box>
        <PageHeader headingText="Flow" mb={'10px'} helpDocLink={helpDocLink} />
        <PageToolbar
          leftComp={
            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              AuthActionTypes={AuthActionTypes}
              getUser={getUser}
              appState={appState}
              dispatch={dispatch}
            />
          }
          rightComp={
            <DateFilter
              getUser={getUser}
              appState={appState}
              dispatch={dispatch}
              AuthActionTypes={AuthActionTypes}
            />
          }
        />
      </Box>

      <SimpleGrid mt={6} columns={{ lg: 2, md: 1, sm: 1 }} spacing={6}>
        {AreaChartData && (
          <LineChartCard
            chart={<CumulativeFlowAreaChart data={AreaChartData?.data} />}
            heading="Issues Distribution By Status"
          />
        )}
        {ScatterChartData && (
          <LineChartCard
            chart={
              <FlowScatterChart
                data={ScatterChartData?.units}
                xLabel={ScatterChartData?.chartMetadata.xlabel}
                yLabel={ScatterChartData?.chartMetadata.ylabel}
              />
            }
            heading="Issues Cycle Time"
          />
        )}
        {/* {AgingDistributionScatterChartData && (
          <LineChartCard
            chart={
              <AgingDistributionScatterChart
                data={AgingDistributionScatterChartData?.units}
                xLabel={AgingDistributionScatterChartData?.chartMetadata.xlabel}
                yLabel={AgingDistributionScatterChartData?.chartMetadata.ylabel}
              />
            }
            heading="Issues Status Aging Distribution"
          />
        )} */}
      </SimpleGrid>

      {(isFetching || isFetchingCycleTime || isFetchingAgingDistribution) && (
        <Loader />
      )}
    </Box>
  );
};

export default FlowPage;
