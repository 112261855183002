import { Box, Flex, Text, Tooltip, useTheme } from '@chakra-ui/react';
import {
  AppTooltip,
  CustomSelect,
  eliminateHtmlTags,
  ISelect,
} from '@devd-client/devd/components';
import { FC, useState, useEffect } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import styled from 'styled-components';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { useAuth } from '@devd-client/devd/auth';

const chakraStyles: ChakraStylesConfig = {
  menu: (provided) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

const filterOptions = [
  { value: 'Contributors', label: 'Contributors' },
  { value: 'Git', label: 'Git' },
  { value: 'Issues', label: 'Issues' },
];
const filterOptionsWithoutContributors = [
  { value: 'Git', label: 'Git' },
  { value: 'Issues', label: 'Issues' },
];

function getLabel(selected: string) {
  if (selected === '') {
    return 'All';
  } else {
    return selected;
  }
}

const ToolbarContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;

interface ToolbarDropDownProps {
  workLogType: ISelect;
  handleOptionSelect: (value: ISelect) => void;
  contributorData: any;
  contributorIds: string[];
  setContributorIds: (value: string[]) => void;
}

const Toolbar: FC<ToolbarDropDownProps> = ({
  handleOptionSelect,
  workLogType,
  contributorData,
  contributorIds,
  setContributorIds,
}) => {
  const { colors } = useTheme();
  const [appState] = useAuth();

  const contributorOptions =
    contributorData?.data?.map((contributor: any) => ({
      value: contributor.email,
      label: contributor.name,
    })) || [];

  const initialSelectedContributors = contributorOptions.filter((option: any) =>
    contributorIds.includes(option.value)
  );

  const [selectedContributors, setSelectedContributors] = useState<any>(
    initialSelectedContributors
  );

  const [selectedView, setSelectedView] = useState<string>(
    workLogType.value || 'Contributors'
  );

  useEffect(() => {
    const updatedSelectedContributors = contributorOptions.filter(
      (option: any) => contributorIds.includes(option.value)
    );
    setSelectedContributors(updatedSelectedContributors);
  }, [contributorIds]);

  const handleViewChange = (selected: string) => {
    const selectedValue = eliminateHtmlTags(selected);
    setSelectedView(selectedValue);
    handleOptionSelect({
      value: selectedValue,
      label: getLabel(selectedValue),
    });
  };

  return (
    <ToolbarContainer>
      <Flex>
        <CustomSelect
          label="View"
          options={
            appState?.user?.dto?.modules?.contributorSearch !== false
              ? filterOptions
              : filterOptionsWithoutContributors
          }
          defaultItem={workLogType}
          onChange={handleViewChange}
          size="sm"
        />

        {selectedView === 'Contributors' &&
          appState?.user?.dto?.modules?.contributorSearch !== false && (
            <Box minW={'183px'} ml={2}>
              <Text
                fontWeight="semibold"
                fontSize="sm"
                lineHeight="17px"
                color="text.secondary"
                fontFamily="Inter"
                mb={2}
              >
                {'Select Contributors'}
              </Text>
              <Box height={'10px'}>
                <Select
                  size="sm"
                  chakraStyles={chakraStyles}
                  useBasicStyles
                  options={contributorOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder={'Select Contributors'}
                  value={selectedContributors}
                  defaultValue={initialSelectedContributors}
                  onChange={(selectedOptions: any) => {
                    if (selectedOptions?.length === 0) {
                      setSelectedContributors([]);
                      if (setContributorIds) setContributorIds([]);
                    } else {
                      setSelectedContributors(selectedOptions);
                      if (setContributorIds)
                        setContributorIds(
                          selectedOptions?.map((item: any) => item.value)
                        )!;
                    }
                  }}
                />
              </Box>
            </Box>
          )}
      </Flex>

      <Tooltip
        placement="left"
        hasArrow
        label="Hover or click on bubbles to see details."
      >
        <Box>
          <BiInfoCircle size={20} color={colors.text.secondary} />
        </Box>
      </Tooltip>
    </ToolbarContainer>
  );
};

export default Toolbar;
