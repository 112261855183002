export function AlertTriangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_7754_15716)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.88935 1.48163C5.38548 0.631122 6.61436 0.631126 7.1105 1.48163L11.6182 9.20911C12.1181 10.0662 11.4999 11.1426 10.5076 11.1426H1.49224C0.499938 11.1426 -0.118325 10.0662 0.381667 9.20911L4.88935 1.48163ZM5.99993 3.53551C6.35497 3.53551 6.64279 3.82333 6.64279 4.17836V6.53551C6.64279 6.89054 6.35497 7.17836 5.99993 7.17836C5.64489 7.17836 5.35707 6.89054 5.35707 6.53551V4.17836C5.35707 3.82333 5.64489 3.53551 5.99993 3.53551ZM6.85707 8.89265C6.85707 9.36605 6.47331 9.74979 5.99993 9.74979C5.52655 9.74979 5.14279 9.36605 5.14279 8.89265C5.14279 8.41927 5.52655 8.03551 5.99993 8.03551C6.47331 8.03551 6.85707 8.41927 6.85707 8.89265Z"
          fill={props.color || '#E65100'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7754_15716">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlertTriangle;
