import { FC, useContext, useMemo } from 'react';
import { InitiativeContext } from '../../../../contexts/initiativesContext';
import moment from 'moment';
import { DataGrid } from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useChangeLogGql } from '../../../../graphql/index';

interface ChangeLogTableProps {
  selectedProjectId: string;
}

const ChangeLogTable: FC<ChangeLogTableProps> = ({ selectedProjectId }) => {
  const {
    state: { changeLog },
    dispatch,
  } = useContext(InitiativeContext);

  const { isFetching: changeLogLoading } = useChangeLogGql(
    selectedProjectId,
    dispatch
  );

  const rows: any[] = useMemo(() => changeLog || [], [changeLog]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('assignee', {
        cell: (info) => info.getValue(),
        header: 'Assignee',
      }),

      columnHelper.accessor('status', {
        cell: (info) => info.getValue()?.toUpperCase(),
        header: 'Status',
      }),

      columnHelper.accessor('expectedDoneDate', {
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('YYYY-MM-DD'),
        header: 'Done Date',
        size: 80,
      }),

      columnHelper.accessor('timeSpent', {
        cell: (info) => info.getValue(),
        header: 'Type',
      }),

      columnHelper.accessor('updatedAt', {
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('YYYY-MM-DD'),
        header: 'Updated At',
        size: 80,
      }),
    ],
    [changeLog]
  );

  return (
    <DataGrid
      showLoader={changeLogLoading}
      columns={columns}
      data={rows}
      maxH="26rem"
      sticky="firstCol"
    />
  );
};

export default ChangeLogTable;
