export type AuthState = {
  user: any;
  authIsReady: boolean;
};

export enum AuthActionTypes {
  AUTH_READY = '[Auth] Auth Ready',
  LOGIN = '[Auth] Login',
  LOGOUT = '[Auth] Logout',
}

export type AuthAction = {
  type: AuthActionTypes;
  payload?: any;
};

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { ...state, user: action?.payload };
    case AuthActionTypes.LOGOUT:
      return { ...state, user: null };
    case AuthActionTypes.AUTH_READY:
      return { ...state, user: action?.payload, authIsReady: true };
    default:
      return state;
  }
};
