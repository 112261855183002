export function Bug(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z"
        fill="#E5493A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 8C11.5 9.93317 9.93317 11.5 8 11.5C6.06683 11.5 4.5 9.93317 4.5 8C4.5 6.06683 6.06683 4.5 8 4.5C9.93317 4.5 11.5 6.06683 11.5 8Z"
        fill="white"
      />
    </svg>
  );
}

export default Bug;
