import { Box, Tooltip, useTheme } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { InvestmentsContext } from '../../../context/investmentContext';
import { AiOutlineInfoCircle } from 'react-icons/ai';

type InfoCardHeaderProps = {
  color?: string;
};

const HeaderBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoCardHeader = styled.h2<InfoCardHeaderProps>`
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 18px;
  color: ${({ color }) => color || '#333'};
`;

const HeaderBar: FC = () => {
  const { colors } = useTheme();

  const {
    state: { selectedChartData },
  } = useContext(InvestmentsContext);

  return (
    <HeaderBarContainer>
      <InfoCardHeader color={colors.text.primary}>
        {`Selected week's data (${selectedChartData.name})`}
      </InfoCardHeader>

      <Tooltip
        hasArrow
        label={
          'Please select a bar chart from the options above to update the Calendar view.'
        }
        aria-label="A tooltip"
        placement="right"
      >
        <Box>
          <Box
            as={AiOutlineInfoCircle}
            _hover={{ cursor: 'pointer', color: 'primary' }}
            transition="color 0.3s"
            color={colors.text.secondary}
            fontSize={14}
            ml={1}
          />
        </Box>
      </Tooltip>
    </HeaderBarContainer>
  );
};

export default HeaderBar;
