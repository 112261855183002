import { Box, Stack, Text } from '@chakra-ui/react';
import { ISelect, TeamMenu, ViewSwitcher } from '@devd-client/devd/components';
import { FC } from 'react';
import { viewSwitchObj } from '../../lib/devd-investments';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';

interface InvestmentsToolbarProps {
  selectedTeam: ISelect;
  handleSelectedTeam: (selectedTeam: ISelect) => void;
  viewSwitch: string;
  setViewSwitch: (value: string) => void;
}

const InvestmentsToolbar: FC<InvestmentsToolbarProps> = ({
  selectedTeam,
  handleSelectedTeam,
  viewSwitch,
  setViewSwitch,
}) => {
  const [appState, dispatch] = useAuth();

  return (
    <Stack>
      <Box>
        <TeamMenu
          selectedTeam={selectedTeam}
          handleSelectedTeam={handleSelectedTeam}
          AuthActionTypes={AuthActionTypes}
          getUser={getUser}
          appState={appState}
          dispatch={dispatch}
        />
      </Box>

      <Stack>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
        >
          Calculation type
        </Text>
        <ViewSwitcher
          tabList={viewSwitchObj}
          currentTab={viewSwitch}
          handleSwitch={setViewSwitch}
        />
      </Stack>
    </Stack>
  );
};

export default InvestmentsToolbar;
