import React, { FC } from 'react';
import styled from 'styled-components';
import GoalCardItem from './GoalCardItem';

const GoalCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  margin-bottom: 16px;
`;

const Heading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 17px;
  margin-bottom: 12px;
  padding-left: 5px;
`;

const GoalCardsWrapper = styled.div`
  overflow-y: auto;
  padding-right: 5px;
  padding-left: 5px;
`;

interface GoalCardsProps {
  goalHeader: string;
  workflowsList: any;
  selectedAction: string;
  setSelectedAction: (selectedAction: string) => void;
}

const GoalCards: FC<GoalCardsProps> = ({
  goalHeader,
  workflowsList,
  selectedAction,
  setSelectedAction,
}) => {
  return (
    <GoalCardsContainer>
      <Heading>{goalHeader}</Heading>
      <GoalCardsWrapper>
        {workflowsList?.map((workFlow: any) => (
          <GoalCardItem
            key={workFlow.name}
            workFlow={workFlow}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
          />
        ))}
      </GoalCardsWrapper>
    </GoalCardsContainer>
  );
};

export default GoalCards;
