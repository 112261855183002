import { createContext, useReducer } from 'react';
import {
  InvestmentsAction,
  investmentsReducer,
  InvestmentsState,
} from '../reducer/investmentReducer';

const initialState = {
  weeklyData: [],
  chartMetaData: [],
  barChartData: [],
  pieChartData: [],
  selectedChartData: {},
  ticketData: {},
};

export const InvestmentsContext = createContext<{
  state: InvestmentsState;
  dispatch: React.Dispatch<InvestmentsAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const InvestmentsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(investmentsReducer, initialState);

  return (
    <InvestmentsContext.Provider value={{ state, dispatch }}>
      {children}
    </InvestmentsContext.Provider>
  );
};
