import { useState, useRef, useEffect } from 'react';
import {
  useInstantSearch,
  useSearchBox,
  UseSearchBoxProps,
} from 'react-instantsearch';
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

function CustomSearchBox({
  onQueryChange,
  ...props
}: UseSearchBoxProps & { onQueryChange: (query: string) => void }) {
  const { query, refine } = useSearchBox(props);
  const { status } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);

  const isSearchStalled = status === 'stalled';

  useEffect(() => {
    onQueryChange(inputValue);
  }, [inputValue, onQueryChange]);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  return (
    <Box>
      <form
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray.400" />
          </InputLeftElement>
          <Input
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Search Metrics"
            spellCheck={false}
            maxLength={512}
            type="search"
            value={inputValue}
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
            autoFocus
            sx={{
              _focus: {
                border: 'none',
                boxShadow: 'none',
              },
            }}
            bg="white"
            outline={0}
            border={0}
            pr="1.2rem"
          />
        </InputGroup>
        <Button type="submit" display="none">
          Submit
        </Button>
      </form>
    </Box>
  );
}

export default CustomSearchBox;
