import { FC, useCallback, useEffect, useMemo, useState, memo } from 'react';
import { Select } from 'chakra-react-select';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Box,
  MenuItem,
  Text,
  Flex,
  Checkbox,
  Avatar,
  position,
  MenuList,
} from '@chakra-ui/react';
import {
  GitHubIcon,
  JiraIcon,
  Loader,
  UserCheckIcon,
  UserXIcon,
  UserIcon,
} from '@devd-client/devd/components';
import { Formik } from 'formik';
import { useTeamMembers, useTeamMembersToSelect } from '../../apis';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from './DataTable';
import { MdHeight } from 'react-icons/md';
import { ChakraStylesConfig, chakraComponents } from 'chakra-react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface AddMemberModalProps {
  bulkUploadModal?: any;
  isOpen: boolean;
  onClose: () => void;
  roleValue?: string;
  setRoleValue?: (role: string) => void;
  selectedMember: string[];
  setSelectedMember: (selectedMember: string[]) => void;
  handleFormSubmit: () => void;
  addMemberLoading: boolean;
  team: string;
}

const AddMemberModal: FC<AddMemberModalProps> = ({
  isOpen,
  onClose,
  bulkUploadModal,
  selectedMember,
  setSelectedMember,
  handleFormSubmit,
  addMemberLoading,
  team,
}) => {
  const { data, isFetching: nonTeamMemberLoading } =
    useTeamMembersToSelect(team);

  const { data: teamMembers, isFetching } = useTeamMembers(team);

  const columnHelper = createColumnHelper<any>();

  // below state is to set the height of dropdown in table.
  const [openDropDown, setOpenDropDown] = useState({
    row: 0,
    isOpen: false,
  });

  const columns: any = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: ({ row }) => (
          <Checkbox
            isChecked={row.getIsSelected()}
            onChange={(e) => {
              row.toggleSelected(e.target.checked);
            }}
            aria-label="Select row"
          />
        ),
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            onChange={(e) => table.toggleAllRowsSelected(e.target.checked)}
            aria-label="Select all rows"
          />
        ),
        size: 10,
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <Flex align="center">
            <Flex align="center" flex={'1'} overflow={'hidden'}>
              <Avatar size="sm" mr={2} src={info.row.original?.avatar} />
              {info.getValue()}
            </Flex>
            <Box mx={1}>
              {info.row.original?.assigned ? <UserCheckIcon /> : <UserXIcon />}
            </Box>
          </Flex>
        ),
        header: 'Name',
        size: 400,
      }),

      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        size: 400,
        header: 'Email',
      }),

      columnHelper.accessor('accounts', {
        cell: (info) => (
          <Flex
            gap={4}
            maxH="105px"
            direction={'column'}
            overflowY={'hidden'}
            _hover={{ overflowY: 'auto' }}
            overflowX={'hidden'}
          >
            {info?.getValue()?.map((item: any, index: any) => (
              <Flex align="center">
                <Box height={'14px'} width={'14px'}>
                  {item?.type === 'github' ? (
                    <GitHubIcon height="14px" width="14px" />
                  ) : item?.type === 'jira' ? (
                    <JiraIcon height="14px" width="14px" />
                  ) : (
                    <UserIcon height="15px" width="15px" />
                  )}
                </Box>
                <Text
                  ml={1}
                  fontSize="xs"
                  color="text.secondary"
                  fontWeight="semibold"
                >
                  {item?.accountId}
                </Text>
              </Flex>
            ))}
          </Flex>
        ),
        header: 'Contributor Accounts',
        size: 350,
      }),

      columnHelper.accessor('teamRole', {
        cell: ({ cell, row, column, table }) => {
          return (
            <DropdownCell row={row} column={column} cell={cell} table={table} />
          );
        },
        size: 250,
        header: 'Team role',
      }),
    ],
    [data?.dto]
  );
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
      },
      width: '140px',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      padding: '0 8px',
    }),

    dropdownIndicator: (provided: any) => ({
      ...provided,
      background: 'none',
      padding: 0,
      marginLeft: '-55px',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '140px',
    }),
    menuList: (provided: any) => ({
      ...provided,
      width: '140px',
    }),
  };

  const DropdownCell = memo(
    ({
      cell,
      table,
      row,
      column,
    }: {
      cell: any;
      row: any;
      column: any;
      table: any;
    }) => {
      const handleChange = useCallback((event: any) => {
        // dynamically update the values of table. i.e role
        table.options.meta?.updateData(row.index, column.id, event.value);
      }, []);

      const CustomDropdownIndicator = (props: any) => {
        const { selectProps } = props;
        const rotation = selectProps.menuIsOpen ? '180deg' : '0deg';
        return (
          <chakraComponents.DropdownIndicator {...props}>
            <ChevronDownIcon
              color="gray.500"
              transform={`rotate(${rotation})`}
            />
          </chakraComponents.DropdownIndicator>
        );
      };

      return (
        <Select
          variant={'unstyled'}
          defaultValue={{
            value: data?.dto[cell.row.index].teamRole,
            label:
              data?.dto[cell.row.index].teamRole === 'MANAGER'
                ? 'Manager'
                : 'Employee',
          }}
          chakraStyles={customStyles}
          options={[
            { value: 'MANAGER', label: 'Manager' },
            { value: 'EMPLOYEE', label: 'Employee' },
          ]}
          onMenuOpen={() =>
            setOpenDropDown({
              row: cell.row.index,
              isOpen: true,
            })
          }
          onMenuClose={() =>
            setOpenDropDown({
              row: -1,
              isOpen: false,
            })
          }
          onChange={(event) => handleChange(event)}
          components={{
            // to add the dropdown arrow
            DropdownIndicator: CustomDropdownIndicator,
          }}
        />
      );
    }
  );

  const [filteredData, setFilteredData] = useState([]);

  const [activeTag, setActiveTag] = useState<any>(0);

  const tags = ['All', 'Unassigned'];

  const handleTagClick = (index: string) => {
    setActiveTag(index);
  };

  useEffect(() => {
    if (activeTag === 0) {
      setFilteredData(data?.dto);
    } else if (activeTag === 1) {
      const team = teamMembers?.dto.map((member: any) => member.email);
      setFilteredData(
        data?.dto?.filter((item: any) => !team?.includes(item.email))
      );
    }
  }, [data?.dto, teamMembers?.dto, activeTag]);

  return (
    <Modal
      // size="6xl"
      scrollBehavior="inside"
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxW="1250px" maxH={'max-content'}>
        <ModalHeader fontFamily={'heading'}>
          Add Members to
          <Text as="span" color="primary" ml={1}>
            {decodeURIComponent(team)}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {(props) => {
              const { isSubmitting, handleSubmit } = props;
              return (
                <Stack as="form" onSubmit={handleSubmit as any}>
                  <DataTable
                    onClose={onClose}
                    bulkUploadModal={bulkUploadModal}
                    data={filteredData || []}
                    setData={setFilteredData}
                    openDropdown={openDropDown}
                    columns={columns}
                    label="Members"
                    selectedRows={selectedMember}
                    setSelectedRows={setSelectedMember}
                    initiallySelectedRows={teamMembers?.dto}
                    tags={tags}
                    activeTag={activeTag}
                    handleTagClick={handleTagClick}
                    loading={isFetching || nonTeamMemberLoading}
                  />

                  <Box alignSelf={'flex-end'}>
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      mb={4}
                      isDisabled={selectedMember?.length === 0}
                      onClick={handleFormSubmit}
                    >
                      Submit
                    </Button>
                  </Box>
                </Stack>
              );
            }}
          </Formik>
        </ModalBody>

        {(addMemberLoading || isFetching || nonTeamMemberLoading) && <Loader />}
      </ModalContent>
    </Modal>
  );
};

export default AddMemberModal;
