import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import CustomSelect from '../../customSelect';
import { AddBottomViewProps } from '../helpers/bottomView.types';
import { eliminateHtmlTags } from '../../shared/utils';

interface LinearFormProps {
  handleSaveClick: (
    params: AddBottomViewProps[],
    integrationId: string
  ) => void;
  data: any;
  integrationId: string;
}

const LinearForm: FC<LinearFormProps> = ({
  handleSaveClick,
  data,
  integrationId,
}) => {
  const [selectedBugDefinition, setSelectedBugDefinition] = useState<string>(
    data?.dto
      ?.filter((elem: any) => elem?.name === 'bugDefinition')
      ?.map((item: any) => item?.type ?? '')[0]
  );
  const [bugFieldValue, setBugFieldValue] = useState<string>(
    data?.dto
      ?.filter((elem: any) => elem?.name === 'bugDefinition')
      ?.map((item: any) => item?.value ?? '')[0]
  );

  const onSubmit = () => {
    handleSaveClick(
      [
        {
          name: 'bugDefinition',
          value: bugFieldValue,
          type: selectedBugDefinition,
        },
      ],
      integrationId
    );
  };

  return (
    <Stack spacing={4}>
      <HStack spacing={6}>
        <Box minW="100px">
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Bug Classification:
          </Text>
        </Box>
        <CustomSelect
          placeholder="Select Bug type"
          onChange={(selected: any) => {
            setSelectedBugDefinition(eliminateHtmlTags(selected));
          }}
          defaultItem={{
            value: selectedBugDefinition,
            label: selectedBugDefinition,
          }}
          options={data?.dto
            ?.find((elem: any) => elem?.name === 'bugDefinition')
            ?.options?.map((item: any) => ({
              value: item.value,
              label: item.name,
            }))}
        />
        <Input
          w={'300px'}
          ml={2}
          value={bugFieldValue}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setBugFieldValue(eliminateHtmlTags(e.currentTarget.value))
          }
          variant="outline"
          placeholder="Type Field Value"
          colorScheme={'primary'}
        />
      </HStack>

      <Flex>
        <Button w="120px" variant="tertiary" onClick={onSubmit}>
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default LinearForm;
