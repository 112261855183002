import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  HStack,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import {
  Breadcrumbs,
  Loader,
  PageToolbar,
  useQueryState,
  useToastHook,
} from '@devd-client/devd/components';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import ProjectTabs from '../components/ContentCard/BoxView/ProjectTabs';
import Contributors from '../components/ContentCard/BoxView/Contributors';
import Sprint from '../components/ContentCard/BoxView/Sprint';
import Teams from '../components/ContentCard/BoxView/Teams';
import ChangeLog from '../components/ContentCard/BoxView/ChangeLog';
import Updates from '../components/ContentCard/BoxView/Updates';
import { ProjectTabOptions } from '../helpers/Initiatives.types';
import { InitiativeContext } from '../contexts/initiativesContext';
import {
  QUERY_KEYS,
  useAllocationDetail,
  useInitiativeDetail,
  usePostInitiativeMutation,
} from '../apis';
import Overview from '../components/ContentCard/BoxView/Overview';
import { useLocation } from 'react-router';
import { AiOutlinePlus } from 'react-icons/ai';
import AllocationModal from '../components/ContentCard/BoxView/AllocationModal';
import UpdateModal from '../components/ContentCard/BoxView/UpdateModal';
import { camelCaseToCapitalize, colors } from '../helpers/Initiatives.utils';
import moment from 'moment';
import { useInitiativeProgressGql, useInitiativeTaskDistGql } from '../graphql';
import { CumulativeFlowAreaChart } from '@devd-client/devd/ui-shared';
import { BiBullseye } from 'react-icons/bi';
import { GiFlyingFlag } from 'react-icons/gi';

import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartTooltip,
  Legend,
} from 'recharts';
import Issues from '../components/ContentCard/BoxView/Issues';

export const DetailsInitiatives = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [searchId] = useQueryState('id');

  const [newToast] = useToastHook();
  const [show, setShow] = useQueryState('show');
  const [selectedTab, setSelectedTab] = useState<ProjectTabOptions>('timeline');

  const { data: initiativeProgress } = useInitiativeProgressGql(
    decodeURIComponent(searchId)
  );

  const { data: initiativeTaskDist } = useInitiativeTaskDistGql(
    decodeURIComponent(searchId)
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: allocationData, isLoading: allocationLoading } =
    useAllocationDetail(decodeURIComponent(searchId));

  const queryClient = useQueryClient();

  const selectedProjectId = decodeURIComponent(searchId) || '';

  const { data } = useInitiativeDetail(decodeURIComponent(searchId));

  const currentItem = data?.dto;

  const [selected, setSelected] = useState<string>('');
  const [summary, setSummary] = useState<string>('');

  const {
    mutate: postInitiativeUpdate,
    isLoading: postInitiativeUpdateLoading,
  } = usePostInitiativeMutation();

  useEffect(() => {
    if (typeof selectedTab !== 'undefined') {
      setShow(selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    show && setSelectedTab(show as ProjectTabOptions);
  }, []);

  const handlePostUpdateClick = () => {
    postInitiativeUpdate(
      {
        initiative: decodeURIComponent(pathname.split('/')[2]),
        summary,
        status: selected,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.initiativeUpdates]);
          newToast({
            message: `Update posted successfully.`,
            status: 'success',
          });
        },
      }
    );

    setSummary('');
    setSelected('');
  };

  return (
    <Flex direction="column" width="100%" minH="100%">
      <Breadcrumbs />

      <Flex direction="column">
        <Box mb={3}>
          <Text fontFamily="heading" fontSize="lg" fontWeight="semibold">
            {decodeURIComponent(pathname.split('/')[2])}
          </Text>

          <Text fontFamily="heading" fontWeight="medium" color="text.secondary">
            {data?.dto?.desc}
          </Text>
        </Box>

        <HStack spacing={6}>
          {data?.dto?.startDate && (
            <Tooltip hasArrow label="Start Date">
              <Flex align="center" fontSize="sm" gap={1}>
                <GiFlyingFlag />
                <Text fontFamily="heading" fontWeight="medium">
                  {moment(data?.dto?.startDate, 'DD/MM/YYYY').format('ll')}
                </Text>
              </Flex>
            </Tooltip>
          )}

          {data?.dto?.plannedDate && (
            <Tooltip hasArrow label="Planned Date">
              <Flex align="center" fontSize="sm" gap={1}>
                <BiBullseye />
                <Text fontWeight="medium">
                  {moment(data?.dto?.plannedDate, 'DD/MM/YYYY').format('ll')}
                </Text>
              </Flex>
            </Tooltip>
          )}

          {data?.dto?.status && (
            <Tooltip hasArrow label="Status">
              <Flex align="center" gap={1}>
                <CircularProgress
                  size={'24px'}
                  thickness="18px"
                  value={data?.dto?.progress}
                  color="primary"
                />
                <Text fontSize="sm" fontWeight="medium">
                  {data?.dto?.progress || '0'}%
                </Text>
                <Text fontSize="sm" fontWeight="medium">
                  {camelCaseToCapitalize(data?.dto?.status || '')}
                </Text>
              </Flex>
            </Tooltip>
          )}

          <Tag
            size="sm"
            style={{
              backgroundColor: `${data?.dto?.legend?.color}90`,
            }}
            fontSize="xxs"
          >
            {camelCaseToCapitalize(data?.dto?.legend?.label || '')}
          </Tag>
        </HStack>
      </Flex>

      <Flex mt={6} mb={4} w="full" gap={6}>
        <Box
          border="0.5px solid rgba(0, 0, 0, 0.1)"
          padding={3}
          height="260px"
          width="50%"
          boxShadow={'md'}
          borderRadius={'4px'}
        >
          <Text fontFamily="heading" fontSize="sm" fontWeight="semibold">
            Progress
          </Text>

          <Box my={1} height="1px" width="full" bg="gray.200" />

          <CumulativeFlowAreaChart data={initiativeProgress?.data} />
        </Box>

        <Box
          border="0.5px solid rgba(0, 0, 0, 0.1)"
          padding={3}
          height="260px"
          width="50%"
          boxShadow={'md'}
          borderRadius={'4px'}
        >
          <Text fontFamily="heading" fontSize="sm" fontWeight="semibold">
            Task Status Distribution
          </Text>

          <Box my={1} height="1px" width="full" bg="gray.200" />

          <Box
            width="100%"
            height={200}
            overflowY={'auto'}
            overflowX={'hidden'}
          >
            <ResponsiveContainer height="100%" width="100%">
              <PieChart width={200} height={200}>
                <Pie
                  data={initiativeTaskDist?.data?.map((item: any) => ({
                    ...item,
                    value: Number(item.value),
                  }))}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={60}
                  fill="#82ca9d"
                  label
                  labelLine={false}
                >
                  {initiativeTaskDist?.data?.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={colors[index % 6]} />
                  ))}
                </Pie>
                <RechartTooltip />
                <Legend
                  verticalAlign="middle"
                  layout="vertical"
                  iconType="circle"
                  iconSize={11}
                  align="right"
                  wrapperStyle={{
                    right: -8,
                    fontSize: 11.5,
                    top: initiativeTaskDist?.data?.length > 15 ? '0%' : '50%',
                    transform:
                      initiativeTaskDist?.data?.length > 15
                        ? 'none'
                        : 'translateY(-50%)',
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Flex>

      <PageToolbar
        rightComp={
          <HStack spacing={2}>
            <Button
              onClick={onOpen}
              variant={'primary'}
              size="sm"
              leftIcon={<AiOutlinePlus />}
            >
              Update Allocation
            </Button>

            <UpdateModal
              headerText="Update Initiative"
              buttonText="Post Update"
              selected={selected}
              setSelected={setSelected}
              summary={summary}
              setSummary={setSummary}
              onSuccessAction={handlePostUpdateClick}
            />
          </HStack>
        }
      />

      <Box>
        <ProjectTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />

        {/* {selectedTab === 'epics' && (
          <Epics selectedProjectId={selectedProjectId} />
        )} */}
        {selectedTab === 'contributors' && (
          <Contributors selectedProjectId={selectedProjectId} />
        )}

        {selectedTab === 'sprint' && (
          <Sprint selectedProjectId={selectedProjectId} />
        )}
        {selectedTab === 'teams' && (
          <Teams selectedProjectId={selectedProjectId} />
        )}
        {selectedTab === 'timeline' && (
          <Overview selectedProjectId={selectedProjectId} />
        )}
        {selectedTab === 'issues' && (
          <Issues selectedProjectId={selectedProjectId} />
        )}
        {selectedTab === 'changeLog' && (
          <ChangeLog selectedProjectId={selectedProjectId} />
        )}
        {selectedTab === 'updates' && (
          <Updates selectedProjectId={selectedProjectId} />
        )}
      </Box>
      {postInitiativeUpdateLoading && <Loader />}

      {isOpen && (
        <AllocationModal
          isOpen={isOpen}
          onClose={onClose}
          allocationId={decodeURIComponent(pathname.split('/')[2])}
          initialAllocationData={allocationData}
        />
      )}
    </Flex>
  );
};

export default DetailsInitiatives;
