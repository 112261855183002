export type CycleTimeState = {
  dataPR: any;
  dataCoding: any;
  dataPickup: any;
  dataMerge: any;
};

export type CycleTimeAction = {
  type: string;
  payload?: any;
};

export enum CycleTimeActionTypes {
  SET_CYCLETIME_DATA = 'SET_DATA',
}

export const cycletimeReducer = (
  state: CycleTimeState,
  action: CycleTimeAction
): CycleTimeState => {
  switch (action.type) {
    case CycleTimeActionTypes.SET_CYCLETIME_DATA:
      return {
        ...state,
        dataPR: action?.payload.pr,
        dataCoding: action.payload.coding,
        dataPickup: action?.payload.pickup,
        dataMerge: action?.payload.merge,
      };
    default:
      return state;
  }
};
