import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useTheme,
  Tooltip,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import {
  InfoCircle,
  VelocityIcon,
  QualityIcon,
  ProductivityIcon,
  RightIcon,
  IntegrationIcon,
  useDetailsPageStore,
  InvestmentIcon,
  extractPercentageAndRemaining,
  OnboadingIcon,
  AppTooltip,
} from '@devd-client/devd/components';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { useState } from 'react';
import CircularTabs from './CircularTabs';
import ReactHtmlParser from 'html-react-parser';

interface SignalsProps {
  signalData: any;
  signalDataLoading: boolean;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  selected: any;
}

type Tab = { id: number; label: string };

const Signals = ({
  signalData,
  signalDataLoading,
  currentTab,
  setCurrentTab,
  selected,
}: SignalsProps) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { setSelected, setActive } = useDetailsPageStore();
  const [showMore, setShowMore] = useState(false);

  const uniqueCategories = Array.from(
    new Set(signalData?.map((item: any) => item.category))
  ) as string[];

  const tabs: Tab[] = [
    { id: 0, label: 'All' },
    ...uniqueCategories.map((category, index) => ({
      id: index + 1,
      label: category,
    })),
  ];

  const filteredData =
    currentTab === 'All'
      ? signalData
      : signalData?.filter((item: any) => item.category === currentTab);

  return (
    <Flex mt={6} w="full" direction="column">
      <Flex align="center">
        <Text mr={1} fontFamily="heading" fontSize="xl" fontWeight="semibold">
          Signals
        </Text>
        <Tooltip
          hasArrow
          aria-label="signals"
          label="Signals alerts you about workflow issues that slow down your team. Spot bottlenecks early, keep development flowing smoothly."
        >
          <Box>
            <InfoCircle />
          </Box>
        </Tooltip>
      </Flex>
      <Flex w="full" align="flex-end" justify="space-between">
        <Flex>
          <CircularTabs
            tabs={tabs}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            signalData={signalData}
          />
        </Flex>
        {filteredData?.length > 4 && (
          <Flex
            cursor="pointer"
            align="center"
            onClick={() => {
              setShowMore(!showMore);
            }}
            userSelect={'none'}
          >
            <Text color="primary2" fontFamily="heading" fontWeight="medium">
              Show {showMore ? 'Less' : 'More'}
            </Text>
            {showMore ? (
              <MdKeyboardArrowUp color={colors.primary2} />
            ) : (
              <MdKeyboardArrowDown color={colors.primary2} />
            )}
          </Flex>
        )}
      </Flex>
      {filteredData?.length === 0 && (
        <Text
          fontFamily="heading"
          fontSize="md"
          fontWeight="medium"
          color="text.secondary3"
          mt={4}
        >
          No Signals Found.
        </Text>
      )}
      <SimpleGrid mt={4} w="full" columns={4} spacing={4}>
        {signalDataLoading
          ? new Array(4).fill(0).map((_: any, index: number) => (
              <Box position={'relative'}>
                <Skeleton key={index} height="140px" minWidth="200px" />
                <Spinner position={'absolute'} top={'42%'} left={'42%'} />
              </Box>
            ))
          : filteredData
              ?.slice(0, showMore ? signalData?.length : 4)
              ?.map((item: any, index: number) => {
                const { percentage, remaining } = extractPercentageAndRemaining(
                  item?.message
                );

                return (
                  <Flex
                    key={index}
                    padding={4}
                    border="1px solid #E0E0E0"
                    borderRadius={'8px'}
                    minWidth="220px"
                    minHeight="140px"
                    direction="column"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    justify="space-between"
                    _hover={{
                      boxShadow: 'lg',
                      transition: '0.2s all',
                    }}
                  >
                    <Flex direction="column">
                      <Flex align="center" justify="space-between">
                        <Flex align="center">
                          {item?.category === 'Quality' ? (
                            <QualityIcon />
                          ) : item?.category === 'Velocity' ? (
                            <VelocityIcon />
                          ) : item?.category === 'Productivity' ? (
                            <ProductivityIcon />
                          ) : item?.category === 'Integration' ? (
                            <IntegrationIcon />
                          ) : item?.category === 'Onboarding' ? (
                            <OnboadingIcon />
                          ) : item?.category === 'Investment Distribution' ? (
                            <InvestmentIcon color="gray" />
                          ) : (
                            ''
                          )}

                          <Text
                            ml={1}
                            color="text.secondary2"
                            fontWeight="semibold"
                            fontFamily="heading"
                            fontSize="sm"
                          >
                            {item?.category}
                          </Text>
                        </Flex>
                        {item?.tooltip ? (
                          <AppTooltip
                            trigger="hover"
                            placement={
                              (index + 1) % 4 === 0
                                ? 'top-start'
                                : 'bottom-start'
                            }
                            content={
                              <Box
                                maxW="40vw"
                                maxH="40vh"
                                overflowY="auto"
                                pr={2}
                                sx={{
                                  '&::-webkit-scrollbar': {
                                    width: '4px',
                                  },
                                  '&::-webkit-scrollbar-track': {
                                    width: '2px',
                                    background: 'rgba(0, 0, 0, 0.05)',
                                  },
                                  '&::-webkit-scrollbar-thumb': {
                                    background: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '10px',
                                    '&:hover': {
                                      background: 'rgba(0, 0, 0, 0.2)',
                                    },
                                  },
                                  h1: {
                                    marginTop: '4px',
                                    marginBottom: '2px',
                                    fontWeight: 'bold',
                                  },
                                  ul: {
                                    marginLeft: '20px',
                                    listStyleType: 'disc',
                                  },
                                  li: {
                                    padding: '3px 0 3px 0',
                                  },
                                }}
                              >
                                {ReactHtmlParser(item?.tooltip || '')}
                              </Box>
                            }
                          >
                            <Box>
                              <InfoCircle />
                            </Box>
                          </AppTooltip>
                        ) : (
                          <Box>
                            <InfoCircle />
                          </Box>
                        )}
                      </Flex>

                      <Text
                        mt={5}
                        color="text.primary"
                        fontFamily="heading"
                        fontWeight="medium"
                      >
                        <Text
                          as="span"
                          fontWeight="semibold"
                          color={item?.color}
                          mr={1}
                        >
                          {percentage}
                        </Text>
                        <Text as="span">{remaining}</Text>
                      </Text>
                    </Flex>
                    <Box>
                      <Button
                        variant="unstyled"
                        color="text.secondary2"
                        fontWeight="normal"
                        size="sm"
                        _hover={{
                          textDecoration: 'underline',
                        }}
                        onClick={() => {
                          navigate(item?.link, {
                            state: {
                              headingText: item?.message,
                            },
                          });
                          setSelected({
                            id: 6,
                            startDate: selected.startDate as string,
                            endDate: selected.endDate as string,
                            duration: 'Custom',
                            sprintId: '',
                          });
                          setActive('Custom');
                        }}
                        rightIcon={
                          <RightIcon
                            height="11px"
                            width="11px"
                            color={colors.text.secondary2}
                          />
                        }
                      >
                        {item?.category === 'Integration'
                          ? 'Integrate'
                          : 'Investigate'}
                      </Button>
                    </Box>
                  </Flex>
                );
              })}
      </SimpleGrid>
    </Flex>
  );
};

export default Signals;
