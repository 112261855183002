export const EliteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      {...props}
    >
      <path
        d="M6.3511 0.469912C6.2709 0.355346 6.13985 0.287109 6 0.287109C5.86015 0.287109 5.7291 0.355346 5.6489 0.469912L2.94184 4.33714L0.731617 2.12692C0.609046 2.00435 0.42471 1.96768 0.264564 2.03402C0.104418 2.10035 0 2.25663 0 2.42997V8.0014C0 8.45602 0.180612 8.89205 0.502102 9.21357C0.823593 9.53508 1.25963 9.71568 1.71429 9.71568H10.2857C10.7403 9.71568 11.1764 9.53508 11.4979 9.21357C11.8194 8.89205 12 8.45602 12 8.0014V2.42997C12 2.25663 11.8956 2.10035 11.7354 2.03402C11.5753 1.96768 11.3909 2.00435 11.2683 2.12692L9.0582 4.33714L6.3511 0.469912Z"
        fill={props.color || '#4D4DFF'}
      />
    </svg>
  );
};

export default EliteIcon;
