export enum IconType {
  rocket = 'rocket',
  productivity = 'productivity',
  burnout = 'burnout',
  win = 'win',
  bottleneck = 'bottleneck',
  hygiene = 'hygiene',
  performance = 'performance',
  growth = 'growth',
}

export type Report = {
  headline: string;
  reportMetrics: ReportMetrics[];
  suggestedActions: { text: string; cta: string }[];
};

export type SuggestedAction = {
  text: string;
  cta: string;
};

export type ReportMetrics = {
  metric: string;
  suggestedActions: SuggestedAction[];
  status: string;
  zeroState: null | string;
};
