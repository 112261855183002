import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface AiAssistantState {
  data: any;
  setData: (data: any) => void;
  toggleChatbox: () => void;
  isOpen: any;
  setIsOpen: any;
}

export const useAiAssistantStore = create<AiAssistantState>()(
  persist(
    (set) => ({
      data: null,
      setData: (data: any) => {
        set({ data });
      },
      toggleChatbox: () => {
        set((state) => ({ isOpen: !state.isOpen }));
      },
      isOpen: false,
      setIsOpen: (isOpen: any) => {
        set({ isOpen });
      },
    }),
    {
      name: 'ai-assistant',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
