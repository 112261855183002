import { apiClient } from '@devd-client/api';

export const getAllGoals = (team: string): Promise<any> =>
  apiClient(
    `/v1/goal/org/${localStorage.getItem('orgId')}${team && `?team=${team}`}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const updateGoalSettings = (params: any): Promise<any> => {
  const { team, teams, ...data } = params;
  return apiClient(
    `/v1/goal/org/${localStorage.getItem('orgId')}/team/${params.team}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }
  );
};

export const getGoalsPage = (): Promise<any> =>
  apiClient(`/v2/account/org/${localStorage.getItem('orgId')}/page/Goal`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
