import { apiClient } from '@devd-client/api';

export const getTimeline = (id: any, apiParam: string): Promise<any> =>
  apiClient(`/v1/timeline/${localStorage.getItem('orgId')}/${apiParam}/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
