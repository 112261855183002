import { apiClient } from '@devd-client/api';

export const getTeamDetails = (team: string): Promise<any> =>
  apiClient(`/v1/team/${team}/org/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getAllGoals = (team: string): Promise<any> =>
  apiClient(
    `/v1/goal/org/${localStorage.getItem('orgId')}${team && `?team=${team}`}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const updateGoalSettings = (params: any): Promise<any> => {
  const { team, teams, ...data } = params;
  return apiClient(
    `/v1/goal/org/${localStorage.getItem('orgId')}/team/${params.team}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }
  );
};

export const getAllTeamMetrics = (team: string): Promise<any> =>
  apiClient(
    `/v1/team/metric/org/${localStorage.getItem('orgId')}/team/${team}/fav`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const postFavMetrics = (params: any) => {
  return apiClient(
    `/v1/team/metric/org/${localStorage.getItem('orgId')}/team/${params.team}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.metricsList),
    }
  );
};

export const getTeamsPage = (): Promise<any> =>
  apiClient(`/v2/account/org/${localStorage.getItem('orgId')}/page/Teams`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getTeamPage = (): Promise<any> =>
  apiClient(`/v2/account/org/${localStorage.getItem('orgId')}/page/Team`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getMetricsList = (): Promise<any> =>
  apiClient(`/v1/teamComparison/metrics/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
