import { useContext } from 'react';
import { PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import styled from 'styled-components';
// import { InvestmentsContext } from '../../context/investmentContext';

type TooltipTextType = {
  fSize?: string;
  fWeight?: string;
  color?: string;
  mr?: string;
  ml?: string;
};

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope';
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const TooltipText = styled.div<TooltipTextType>`
  display: flex;
  margin-right: ${({ mr }) => mr};
  margin-left: ${({ ml }) => ml};
  color: ${({ color }) => color};
  font-weight: ${({ fWeight }) => fWeight};
  font-size: ${({ fSize }) => fSize};
`;
const CustomTooltip = ({ payload }: any) => {
  return (
    <TooltipContainer>
      <TooltipText fWeight="600" fSize="12px" color="#999999">
        <pre>{`${payload[0]?.name}: `}</pre>
      </TooltipText>
      <TooltipText mr="5px" fWeight="600" fSize="12px">
        {payload[0]?.value}
      </TooltipText>
    </TooltipContainer>
  );
};

const PieChartData = ({ properties }: any) => {
  //   const {
  //     state: { pieChartData },
  //   } = useContext(InvestmentsContext);
  const fillColor = ['#FAD1D7', '#FF8D70', '#0396A6', '#C2528B'];

  const renderCustomLabel = (item: any) => (
    <text
      fill={'#000'}
      fontWeight={400}
      fontFamily="Inter"
      fontSize={12}
      x={item.x}
      y={item.y}
      stroke="none"
      textAnchor="end"
    >
      <tspan x={item.x} textAnchor={item.textAnchor} dy="0em">
        {`${item.value}`}
      </tspan>
    </text>
  );

  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart>
        <Pie
          data={properties.map((item: any) => {
            return {
              ...item,
              value: Number(item.value),
              fill: fillColor.pop(),
            };
          })}
          innerRadius={10}
          outerRadius={20}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          label={(item) => renderCustomLabel(item)}
          labelLine={false}
        ></Pie>
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
export default PieChartData;
