import { Loader } from '@devd-client/devd/components';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from '@devd-client/api';
import { ConfirmModal } from '@devd-client/devd/components';

export function GitInstallation() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const installationId = searchParams.get('installation_id');
  const settup = searchParams.get('setup_action');

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [headerText, setHeaderText] = useState<string>('');
  const [bodyText, setBodyText] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    apiClient(`/v1/integration/GitHub/org/${localStorage.getItem('orgId')}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        name: 'git',
        type: 'cloud',
        active: false,
        properties: [
          {
            name: 'installationId',
            value: installationId,
          },
          {
            name: 'setup_action',
            value: settup,
          },
        ],
      }),
    })
      .then((res: any) => {
        setLoading(false);
        switch (settup) {
          case 'install':
            setHeaderText('Integration successful and active');
            setBodyText(`Note: It might take 15 mins to 1 hour to pull data and update
            metrics.`);
            break;
          case 'update':
            setHeaderText('Integration successful and active');
            setBodyText(`Note: It might take 15 mins to 1 hour to pull data and update
            metrics.`);
            break;
          case 'pending':
            setHeaderText('Integration Pending');
            setBodyText(
              `Integration was initiated but not complete. Please contact git admin to approve integration request.`
            );
            break;
          default:
            setHeaderText('Git Integration installation failed');
            setBodyText(`Please try again or contact DevDynamics Support`);
            break;
        }
      })
      .catch((err) => {
        setLoading(false);
        setHeaderText(
          err.message
            ? `${err.message}`
            : 'Integration Failed. Contact DevDynamics Support'
        );
      });
    setOpenConfirmModal(true);
  }, [settup, installationId]);

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {openConfirmModal && (
            <ConfirmModal
              isOpen={openConfirmModal}
              headerText={headerText}
              bodyText={bodyText}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default GitInstallation;
