import { FC } from 'react';
import { Loader } from '@devd-client/devd/components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useReportQuestionsGql } from '../../graphql';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { getQuestionIcon } from '../../helpers/report.utils';

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  cursor: pointer;
  color: #000;
  &:hover {
    color: ${(props: any) => props.theme.colors.primary};
  }
`;

interface ListViewProps {
  setSelectedItem: (item: any) => void;
}

const ListView: FC<ListViewProps> = ({ setSelectedItem }) => {
  const { data: reportList, isLoading } = useReportQuestionsGql(0, 50);

  return (
    <>
      <Stack>
        {reportList
          ?.sort((a: any, b: any) => a.id - b.id)
          ?.map((item: any, idx: number) => (
            <Flex
              key={`report-${idx}`}
              px={1}
              _hover={{ bg: '#F6F7F9' }}
              py={6}
              direction="column"
            >
              <StyledLink
                to={{
                  pathname: '',
                  search: `?id=${item.id}`,
                }}
                onClick={() => setSelectedItem(item)}
              >
                <Flex align="center" _hover={{ textDecoration: 'underline' }}>
                  {getQuestionIcon(item.icon)}

                  <Text
                    fontFamily="heading"
                    fontSize="md"
                    ml={2}
                    color="text.primary"
                    fontWeight="semibold"
                  >
                    {item.question}
                  </Text>
                </Flex>
              </StyledLink>
              <Text
                mt={1}
                fontFamily="heading"
                fontSize="sm"
                fontWeight="medium"
                color="text.secondary"
              >
                {item.description}
              </Text>
            </Flex>
          ))}
      </Stack>
      {isLoading && <Loader />}
    </>
  );
};

export default ListView;
