import { FC } from 'react';
import styled from 'styled-components';
import ListView from './ListView';
import Toolbar from './Toolbar';

const ContentCardContainer = styled.div`
  /* padding: 26px; */
  /* border-radius: 4px; */
  /* border: 0.5px solid rgba(0, 0, 0, 0.1); */
  width: 100%;
  background-color: #fff;
`;

interface ContentCardProps {
  handlePageClick: (page: number) => void;
  setSearchInput: (searchInput: string) => void;
  searchInput: string;
  handleRealStatus: (status: { value: string; label: string }) => void;
  realStatus: { value: string; label: string };
  showLoader: boolean;
}

const ContentCard: FC<ContentCardProps> = ({
  handlePageClick,
  setSearchInput,
  searchInput,
  handleRealStatus,
  realStatus,
  showLoader,
}) => {
  return (
    <ContentCardContainer>
      <Toolbar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleRealStatus={handleRealStatus}
        realStatus={realStatus}
      />

      <ListView handlePageClick={handlePageClick} showLoader={showLoader} />
    </ContentCardContainer>
  );
};

export default ContentCard;
