export const CallIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.86644 4.00016C10.5176 4.12721 11.116 4.44567 11.5852 4.91479C12.0543 5.38391 12.3727 5.98234 12.4998 6.6335M9.86644 1.3335C11.2193 1.48379 12.4808 2.08961 13.4439 3.0515C14.407 4.01339 15.0144 5.27417 15.1664 6.62683M7.31776 9.24221C6.51671 8.44116 5.88419 7.5354 5.42019 6.56898C5.38028 6.48586 5.36032 6.44429 5.34499 6.3917C5.29051 6.2048 5.32964 5.97529 5.44298 5.817C5.47488 5.77246 5.51298 5.73436 5.58919 5.65815C5.82226 5.42508 5.93879 5.30854 6.01498 5.19136C6.30231 4.74943 6.30231 4.17971 6.01498 3.73778C5.93879 3.6206 5.82226 3.50406 5.58919 3.27099L5.45927 3.14108C5.10498 2.78678 4.92783 2.60964 4.73758 2.51341C4.3592 2.32203 3.91236 2.32203 3.53399 2.51341C3.34373 2.60964 3.16659 2.78678 2.81229 3.14108L2.7072 3.24617C2.35412 3.59925 2.17758 3.77579 2.04275 4.01581C1.89313 4.28215 1.78556 4.69581 1.78647 5.00129C1.78729 5.27659 1.84069 5.46474 1.94749 5.84104C2.52147 7.8633 3.60446 9.77154 5.19644 11.3635C6.78843 12.9555 8.69667 14.0385 10.7189 14.6125C11.0952 14.7193 11.2834 14.7727 11.5587 14.7735C11.8642 14.7744 12.2778 14.6668 12.5442 14.5172C12.7842 14.3824 12.9607 14.2059 13.3138 13.8528L13.4189 13.7477C13.7732 13.3934 13.9503 13.2162 14.0466 13.026C14.2379 12.6476 14.2379 12.2008 14.0466 11.8224C13.9503 11.6321 13.7732 11.455 13.4189 11.1007L13.289 10.9708C13.0559 10.7377 12.9394 10.6212 12.8222 10.545C12.3803 10.2577 11.8105 10.2577 11.3686 10.545C11.2514 10.6212 11.1349 10.7377 10.9018 10.9708C10.8256 11.047 10.7875 11.0851 10.743 11.117C10.5847 11.2303 10.3552 11.2695 10.1683 11.215C10.1157 11.1997 10.0741 11.1797 9.99099 11.1398C9.02458 10.6758 8.11882 10.0433 7.31776 9.24221Z"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
