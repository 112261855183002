import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEYS = {
  investmentPage: 'investmentPage',
};

export const useInvestmentPage = () => {
  return useQuery([QUERY_KEYS.investmentPage], ENDPOINTS.getInvestmentPage);
};
