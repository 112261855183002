import styled from 'styled-components';

export const LoginContainer = styled.div`
  max-width: 580px;
  margin: 10vh auto;
  padding: 30px;
  /* background-color: rgb(248, 248, 248); */
  background-color: #f6f7f9;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 116px;
  text-align: center;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: none;
  order: 1;
  flex-grow: 0;
`;
