import { Box, SimpleGrid } from '@chakra-ui/react';
import { useLocation } from 'react-router';
import { QUERY_KEYS, useTeamFavMetrics } from '../../../api';
import {
  AddMetrics,
  MetricsCard,
  PeriodType,
} from '@devd-client/devd/components';
import { useTeamDetailsStore } from '../../../store/teamDetail';

interface TeamMetricsProps {
  selected: PeriodType;
}

const TeamMetrics = ({ selected }: TeamMetricsProps) => {
  const { pathname } = useLocation();
  const { data, isLoading } = useTeamFavMetrics(
    decodeURIComponent(pathname.split('/')[2])
  );

  const teamFavMetrics = data?.dto?.map((metric: any) =>
    metric.name === 'CUSTOM' ? metric.title : metric.name
  );

  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
      {teamFavMetrics?.map((type: string) => (
        <MetricsCard
          key={type}
          metricType={type}
          selected={selected}
          selectedTeam={decodeURIComponent(pathname.split('/')[2])}
          teamMetrics
          queryKey={QUERY_KEYS.teamFavMetrics}
        />
      ))}
      <AddMetrics
        title="Add Team Metrics"
        modalHeading="Add Team Metrics"
        buttonText="Add to Team Metrics"
        queryKeys={QUERY_KEYS}
        name="Teams"
        forTeam
      />
    </SimpleGrid>
  );
};

export default TeamMetrics;
