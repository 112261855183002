export const OrganizationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.906036 18.2866V0.254242H10.906V4.26142H20.906V18.2866H0.906036ZM2.90604 16.283H8.90604V14.2794H2.90604V16.283ZM2.90604 12.2758H8.90604V10.2722H2.90604V12.2758ZM2.90604 8.2686H8.90604V6.26501H2.90604V8.2686ZM2.90604 4.26142H8.90604V2.25783H2.90604V4.26142ZM10.906 16.283H18.906V6.26501H10.906V16.283ZM12.906 10.2722V8.2686H16.906V10.2722H12.906ZM12.906 14.2794V12.2758H16.906V14.2794H12.906Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};
