import React, { useState } from 'react';
import Month from './month';

interface MonthsProps {
  setselectedMonthAndYear: (month: string) => void;
  setMonthTitle: (title: string) => void;
}

const Months: React.FC<MonthsProps> = ({
  setselectedMonthAndYear,
  setMonthTitle,
}) => {
  const [year, setYear] = useState(new Date().getFullYear());

  const goToPreviousYear = () => setYear(year - 1);
  const goToNextYear = () => setYear(year + 1);

  const handleMonthSelect = (month: number, year: number) => {
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    setMonthTitle(monthNames[month]);
    setselectedMonthAndYear(`${month}-${year}`);
  };

  return (
    <Month
      year={year}
      goToPreviousYear={goToPreviousYear}
      goToNextYear={goToNextYear}
      onSelectMonth={handleMonthSelect}
    />
  );
};

export default Months;
