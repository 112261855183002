import { FC, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Box,
  useTheme,
} from '@chakra-ui/react';
import { apiClient } from '@devd-client/api';
import { Formik } from 'formik';
import * as Yup from 'yup';

import TextInput from '../../../TextInput';
import CustomSelect from '../../../customSelect';
import Loader from '../../../appLoader';
import { useToastHook } from '../../../appToast';
import { eliminateHtmlTags } from '../../../shared/utils';
import { EmailIcon } from '../../../icons';

interface InviteMemberProps {
  isOpen: boolean;
  onClose: () => void;
}

const roleData =
  localStorage.getItem('role') === 'MANAGER'
    ? [
        {
          label: 'Manager',
          value: 'MANAGER',
        },
      ]
    : [
        {
          label: 'Admin',
          value: 'EMPLOYER_ADMIN',
        },
        {
          label: 'Manager',
          value: 'MANAGER',
        },
        {
          label: 'Employee',
          value: 'EMPLOYEE',
        },
      ];

const TeamInviteModal: FC<InviteMemberProps> = ({ isOpen, onClose }) => {
  const { colors } = useTheme();
  const [newToast] = useToastHook();
  const [loading, setLoading] = useState(false);

  const [roleValue, setRoleValue] = useState('');

  const InviteTeamMember = (email: string) => {
    try {
      setLoading(true);
      apiClient(`/v2/account/invitation/${localStorage.getItem('orgId')}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          email: email,
          role: roleValue,
        }),
      })
        .then((res: any) => {
          setLoading(false);
          newToast({
            message: 'Invitation sent successfully on invitee email.',
            status: 'success',
          });
          setTimeout(() => {
            onClose();
          }, 200);
        })
        .catch((err) => {
          setLoading(false);
          newToast({
            message:
              err?.message ??
              'Some error in sending invite. Contact DevDynamics support',
            status: 'error',
          });
        });
    } catch (err: any) {
      setLoading(false);
      newToast({
        message:
          err?.message ??
          'Some error in sending invite. Contact DevDynamics support',
        status: 'error',
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite your team member</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              InviteTeamMember(values.email);
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Email address is invalid')
                .required('Email address is required'),
            })}
          >
            {(props) => {
              const { isSubmitting, handleSubmit } = props;
              return (
                <Stack as="form" onSubmit={handleSubmit as any}>
                  <TextInput
                    name="email"
                    label="Email*"
                    placeholder="Email Address"
                    leftIcon={
                      <EmailIcon
                        height="16px"
                        width="16px"
                        color={colors.text.secondary}
                      />
                    }
                  />

                  <CustomSelect
                    label={'Select Role*'}
                    placeholder={'Select Role'}
                    onChange={(selected: string) => {
                      setRoleValue(eliminateHtmlTags(selected));
                    }}
                    options={roleData?.map((e: any) => {
                      return {
                        label: e.label,
                        value: e.value,
                      };
                    })}
                  />

                  <Box alignSelf={'flex-end'}>
                    <Button
                      variant="primary"
                      size="md"
                      type="submit"
                      isDisabled={isSubmitting || roleValue === ''}
                    >
                      Invite Team Member
                    </Button>
                  </Box>
                </Stack>
              );
            }}
          </Formik>
        </ModalBody>

        {loading && <Loader />}
      </ModalContent>
    </Modal>
  );
};

export default TeamInviteModal;
