export const TaskViewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 10C0.716667 10 0.479333 9.904 0.288 9.712C0.0960001 9.52067 0 9.28333 0 9C0 8.71667 0.0960001 8.479 0.288 8.287C0.479333 8.09567 0.716667 8 1 8C1.28333 8 1.521 8.09567 1.713 8.287C1.90433 8.479 2 8.71667 2 9C2 9.28333 1.90433 9.52067 1.713 9.712C1.521 9.904 1.28333 10 1 10ZM1 6C0.716667 6 0.479333 5.904 0.288 5.712C0.0960001 5.52067 0 5.28333 0 5C0 4.71667 0.0960001 4.479 0.288 4.287C0.479333 4.09567 0.716667 4 1 4C1.28333 4 1.521 4.09567 1.713 4.287C1.90433 4.479 2 4.71667 2 5C2 5.28333 1.90433 5.52067 1.713 5.712C1.521 5.904 1.28333 6 1 6ZM1 2C0.716667 2 0.479333 1.904 0.288 1.712C0.0960001 1.52067 0 1.28333 0 1C0 0.716667 0.0960001 0.479 0.288 0.287C0.479333 0.0956668 0.716667 0 1 0C1.28333 0 1.521 0.0956668 1.713 0.287C1.90433 0.479 2 0.716667 2 1C2 1.28333 1.90433 1.52067 1.713 1.712C1.521 1.904 1.28333 2 1 2ZM4 10V8H18V10H4ZM4 6V4H18V6H4ZM4 2V0H18V2H4Z"
        fill={props.color || '#777'}
      />
    </svg>
  );
};
