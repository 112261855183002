import { useCustomMutation } from '../../../hooks/useCustomMutation';
import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  allPlans: 'allPlans',
  notifications: 'notifications',
  markAsRead: 'markAsRead',
  shownTrialEndModal: 'shownTrialEndModal',
  ordinals: 'ordinals',
};

export const useFetchAllPlans = ({ duration }: any) =>
  useQuery(
    [QUERY_KEYS.allPlans, duration],
    () => ENDPOINTS.getAllSubsPlans({ duration }),
    {
      keepPreviousData: true,
    }
  );

export const useNotifications = () =>
  useQuery([QUERY_KEYS.notifications], ENDPOINTS.getAllNotifications, {
    keepPreviousData: true,
  });

export const useMarkAsRead = () =>
  useCustomMutation([QUERY_KEYS.markAsRead], ENDPOINTS.markAsRead);

export const useShownTrialEndModal = () =>
  useCustomMutation(
    [QUERY_KEYS.shownTrialEndModal],
    ENDPOINTS.shownTrialEndModal
  );

export const useFetchOrdinals = () => {
  return useQuery([QUERY_KEYS.ordinals], ENDPOINTS.getOrdinalSteps);
};
