import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';
import { QueryFilter } from '.';

export const throughPutMetricsGenericGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetThroughPutMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        ThroughPutMetricsByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const cycleTimeMetricsGenericGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCycleTimeMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        CycleTimeMetricsByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            helpDoc
            showBreakdown
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const doraGenericGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeploymentMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        DeploymentMetricsByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          reason
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const changeFailureGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetChangeFailureQuery(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        ChangeFailureQuery(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          reason
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const meetingMetricsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetMeetingMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        MeetingMetricsByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
          }
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
          tags
          highlight
          relatedMetrics
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const LeadTimeMetricsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetLeadTimeMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        LeadTimeMetricsByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
          }
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
          tags
          highlight
          relatedMetrics
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const commentPerPr = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAverageCommentsForPr(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        AverageCommentsForPr(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const wellbeingGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetWellBeingDataByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String!
        $teamId: String!
        $sprintId: String!
        $projectsFilter: [String]
        $granularity: String!
      ) {
        WellBeingDataByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          performance
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      metric,
      breakdown,
      teamId,
      sprintId,
      projectsFilter,
      granularity,
    }
  );

export const qualityGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query QualityMetricsOnOverallAndNewCode(
        $metric: String!
        $granularity: String!
        $breakdown: String
        $filter: Filter!
      ) {
        QualityMetricsOnOverallAndNewCode(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      metric: metric,
      granularity: granularity,
      breakdown: breakdown,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        project: '',
        teamId: teamId,
        reviewer: '',
        author: '',
        sprintId: sprintId,
        projectsFilter,
      },
    }
  );

export const dependencyVulnerabilitiesGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query VulnerabilityAlerts(
        $metric: String!
        $granularity: String!
        $breakdown: String
        $filter: Filter!
      ) {
        VulnerabilityAlerts(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          attributes {
            key
            value
          }
        }
      }
    `,
    {
      metric: metric,
      granularity: granularity,
      breakdown: breakdown,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        project: '',
        teamId: teamId,
        reviewer: '',
        author: '',
        sprintId: sprintId,
        projectsFilter,
      },
    }
  );

export const sizeBreakdown = async (
  startDate: string,
  endDate: string,
  metric: string,
  teamId: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetMetricsBreakdown(
        $metric: String!
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
      ) {
        MetricsBreakdown(
          metric: $metric
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            project: ""
          }
        ) {
          title
          subTitle
          unit
          breakdowns {
            title
            icon
            subTitle
            value
          }
        }
      }
    `,
    {
      metric: metric,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      project: '',
      teamId: teamId,
      sprintId: sprintId,
    }
  );

export const customMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  teamId: string,
  sprintId: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCustomMetrics(
        $customFieldName: String!
        $granularity: String!
        $filter: Filter!
      ) {
        CustomMetrics(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
            dataType
            detailEntity
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      customFieldName: customFieldName,
      granularity: granularity,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        sprintId: sprintId,
      },
    }
  );

export const valueStreamGraphql = async (
  metricStr: string,
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetThroughputValueStreamByType(
        $metricStr: String!
        $granularity: String!
        $filter: Filter!
      ) {
        ThroughputValueStreamByType(
          metricStr: $metricStr
          granularity: $granularity
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      metricStr,
      granularity: granularity,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        sprintId: sprintId,
        type: type,
        projectsFilter: projectsFilter,
      },
    }
  );

export const cycleTimeDistGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCycleTimeDistVsmQuery($filter: Filter!) {
        CycleTimeDistVsmQuery(filter: $filter) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        sprintId: sprintId,
        type: type,
        projectsFilter: projectsFilter,
      },
    }
  );

export const issueCycleTimeGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string,
  filter?: QueryFilter | null
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueCycleTimeVsmQuery($granularity: String!, $filter: Filter!) {
        IssueCycleTimeVsmQuery(granularity: $granularity, filter: $filter) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      granularity: granularity,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        type: type,
        sprintId: sprintId,
        projectsFilter: projectsFilter,
        ...filter,
      },
    }
  );

export const issuesAgeingGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssuesAgeingReport($filter: Filter!) {
        IssuesAgeingReport(filter: $filter) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        type: type,
        sprintId: sprintId,
        projectsFilter: projectsFilter,
      },
    }
  );

export const reviewerDistributionGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetReviewerDistribution($filter: Filter!) {
        ReviewerDistribution(filter: $filter) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        sprintId: sprintId,
        projectsFilter: projectsFilter,
      },
    }
  );

export const prAgeingDistGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrAgeingReport($filter: Filter!) {
        PrAgeingReport(filter: $filter) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        sprintId: sprintId,
        type: type,
        projectsFilter: projectsFilter,
      },
    }
  );

export const requirementStabilityGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetRequirementStabilityVsmQuery(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $type: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
        $projectsFilter: [String]
      ) {
        RequirementStabilityVsmQuery(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            type: $type
            projectsFilter: $projectsFilter
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      type: type,
      projectsFilter,
    }
  );

export const averageActiveDaysGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAverageActiveDays(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
        $projectsFilter: [String]!
      ) {
        AverageActiveDays(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      projectsFilter: projectsFilter,
    }
  );

export const backwardMomentumGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetBackwardMomentum($filter: Filter!, $granularity: String!) {
        BackwardMomentum(filter: $filter, granularity: $granularity) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
            zsImage
            source
          }
          average
          averageLabel
          data
          chartDataState
          chartError {
            message
            link
          }
          threshold {
            title
            value
            color
            desc
            target
          }
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      granularity: granularity,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        type: type,
        sprintId: sprintId,
        projectsFilter: projectsFilter,
      },
    }
  );

export const prHealthIndexGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string,
  filter?: QueryFilter | null
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrHealthIndexes(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $metric: String!
        $granularity: String!
        $projectsFilter: [String]!
        $view: String
      ) {
        PrHealthIndexes(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
            view: $view
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      metric: metric,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      projectsFilter: projectsFilter,
      ...filter,
    }
  );

export const locVsPrCycleTimeGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetLocVsPrCycleTime(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
        $projectsFilter: [String]!
      ) {
        LocVsPrCycleTime(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      metric: metric,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      projectsFilter: projectsFilter,
    }
  );
export const mergeHealthIndexGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetReviewResponsivenessIndex(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $metric: String!
        $granularity: String!
        $projectsFilter: [String]!
      ) {
        ReviewResponsivenessIndex(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      metric: metric,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      projectsFilter: projectsFilter,
    }
  );

export const prCycleTimeBreakdownGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrCycleTimeBreakdown(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
        $projectsFilter: [String]!
      ) {
        PrCycleTimeBreakdown(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      projectsFilter: projectsFilter,
    }
  );

export const changeHealthGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetChangeHealth(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
      ) {
        ChangeHealth(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
    }
  );

export const leadTimeBreakDownGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetLeadTimeBreakDown(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
      ) {
        LeadTimeBreakDown(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
    }
  );

export const deploymentFrequencyDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeploymentFrequencyDistribution(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
      ) {
        DeploymentFrequencyDistribution(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
    }
  );

export const meanTimeToRecoveryDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetMeanTimeToRecoveryDistribution(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $granularity: String!
      ) {
        MeanTimeToRecoveryDistribution(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      granularity: granularity,
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
    }
  );

export const issueTimelineDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  type: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueTimelineDistributionGraphql(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $type: String!
        $projectsFilter: [String]
      ) {
        IssueTimelineDistribution(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            type: $type
            projectsFilter: $projectsFilter
          }
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          keys {
            key
            name
            color
            chartType
          }
          chartDataState

          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      type: type,
      projectsFilter,
    }
  );

export const workBreakdownGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  type: string,
  projectsFilter: string[],
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetWorkBreakdown(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $type: String!
        $granularity: String!
        $projectsFilter: [String]
      ) {
        WorkBreakdown(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            type: $type
            projectsFilter: $projectsFilter
          }
          granularity: $granularity
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          reason
          chartDataState
          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          attributes {
            key
            value
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      type: type,
      projectsFilter,
      granularity,
    }
  );

export const issueLifeCycleDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  type: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueLifeCycleDistribution(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String!
        $type: String!
        $projectsFilter: [String]
      ) {
        IssueLifeCycleDistribution(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            type: $type
            projectsFilter: $projectsFilter
          }
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          average
          averageLabel
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      startDate: startDate,
      endDate: endDate,
      teamId: teamId,
      sprintId: sprintId,
      type: type,
      projectsFilter,
    }
  );

export const pipelineMetricsByTypeGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  breakdown: string,
  teamId: string,
  sprintId: string,
  project: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPipelineMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $metric: String!
        $breakdown: String
        $teamId: String!
        $sprintId: String!
        $granularity: String!
        $project: String!
        $searchTerm: String
        $reviewer: String
        $author: String
        $sprintIds: [String]
        $authors: [String]
        $type: String
        $status: String
        $label: String
        $timeZone: String
        $branches: String
      ) {
        PipelineMetricsByType(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
            project: $project
            searchTerm: $searchTerm
            reviewer: $reviewer
            author: $author
            sprintId: $sprintId
            sprintIds: $sprintIds
            authors: $authors
            type: $type
            status: $status
            label: $label
            timeZone: $timeZone
            branches: $branches
          }
        ) {
          chartMetadata {
            chartKey
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            zAxis
            zlabel
            info
            zsImage
            source
          }
          data
          average
          averageLabel
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          chartDataState
          chartError {
            message
            link
          }
          configLink
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
        }
      }
    `,
    {
      metric,
      breakdown,
      granularity,

      orgId: `${localStorage.getItem('orgId')}`,
      startDate,
      endDate,
      teamId,
      sprintId,
      project,
      searchTerm: '',
      reviewer: '',
      author: '',
      sprintIds: [],
      authors: [],
      type: '',
      status: '',
      label: '',
      timeZone: '',
      branches: '',
    }
  );

export const sprintGraphql = async (
  metric: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintMetricsByType(
        $metric: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $orgId: String!
      ) {
        SprintMetricsByType(
          metric: $metric
          granularity: ""
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
          }
          data
          keys {
            key
            name
            color
          }
          tags
          highlight
          relatedMetrics
          average
          averageLabel
          chartDataState
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      metric,
      teamId,
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
    }
  );
