export const roles = [
  {
    label: 'Manager',
    value: 'MANAGER',
  },
  {
    label: 'Employee',
    value: 'EMPLOYEE',
  },
];
