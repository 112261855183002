import {
  Box,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Loader from '../appLoader';

interface DemoCalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  onBackAction?: () => void;
  showBackBtn?: boolean;
}

export const DemoCalendarModal: React.FC<DemoCalendarModalProps> = ({
  isOpen,
  onClose,
  onBackAction,
  showBackBtn,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line
  function dummyOnClose() {}

  const handleOnLoad = () => setIsLoading(false);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={dummyOnClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent position="relative" minW={{ md: '760px', lg: '1065px' }}>
          {showBackBtn && (
            <Flex
              bg="transparent"
              align="center"
              position="absolute"
              top={4}
              left={4}
            >
              <Box
                size="sm"
                as={IconButton}
                bg="transparent"
                onClick={onBackAction}
              >
                <IoIosArrowRoundBack size={28} />
              </Box>
            </Flex>
          )}

          <ModalCloseButton onClick={onClose} />
          <ModalBody w="full" px={8} pb={8}>
            {isLoading && <Loader />}
            <div style={{ width: '100%' }}>
              <iframe
                src="https://calendly.com/devdynamics/30min?hide_gdpr_banner=1%22&month=2023-04"
                width="100%"
                height="660px"
                onLoad={handleOnLoad}
              ></iframe>
              <script
                type="text/javascript"
                src="https://assets.calendly.com/assets/external/widget.js"
                async
              ></script>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DemoCalendarModal;
