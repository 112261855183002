import { Box, Button, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { AppTooltip, Chart, PeriodType } from '@devd-client/devd/components';
import React from 'react';
import { useSprintMetricByTypeGql } from '../../../../../graphql/index';
import { ResponsiveContainer } from 'recharts';
import ScopeChangeChart from '../scopeBarChart';
import { Link } from 'react-router-dom';

interface SprintMetricProps {
  item: any;
  selected: PeriodType;
  team: string;
}

const SprintMetric: React.FC<SprintMetricProps> = ({
  item,
  selected,
  team,
}) => {
  const { data, isLoading } = useSprintMetricByTypeGql(
    item.metric,
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isLoading ? (
        <Skeleton width="100%" height="350px" mb={2} />
      ) : (
        <Stack
          borderRadius="4px"
          border="0.5px solid rgba(0,0,0,0.1)"
          p={3}
          mb={2}
          h="500px"
          w="100%"
        >
          <Flex w="100%" justify="space-between" align="center">
            <Text fontFamily="heading" fontSize="md" fontWeight="semibold">
              {data?.chartMetadata?.chartTitle}
            </Text>

            {(data?.chartDataState === 'ERROR' ||
              data?.chartDataState === 'NOT_CONFIGURED') && (
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      mr={1}
                    >
                      {data?.chartError?.message}
                    </Text>
                    <Link to={`${data?.chartError?.link}`}>
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'xs'}
                    color={'error'}
                    border={'1px'}
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            )}
          </Flex>
          {data?.chartDataState === 'INTEGRATED' && (
            <>
              {data && item?.metric !== 'SPRINT_SCOPE_CHANGE' && (
                <Chart
                  data={data?.data}
                  keys={data?.keys}
                  chartMetadata={data?.chartMetadata}
                  selected={selected}
                  hideBtn
                  slant
                />
              )}
              {item?.metric === 'SPRINT_SCOPE_CHANGE' && (
                <ResponsiveContainer width="100%" height="100%">
                  <ScopeChangeChart selected={selected} team={team} />
                </ResponsiveContainer>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default SprintMetric;
