export type InitiativesState = {
  initiativeData: any;
  initiativeDetails: any;
  taskList: any;
  changeLog: any;
  updatesData: any;
  contributors: any;
  sprint: any;
  teams: any;
};

export enum InitiativesActionTypes {
  INITIATIVE_DATA = '[Initiatives] Get Initiative Data',
  INITIATIVE_DETAILS = '[Initiatives] Get Initiatives Details',
  INITIATIVE_TASK_LIST = '[Initiatives] Get Initiatives task list',
  INITIATIVE_CHANGE_LOG = '[Initiatives] Get Initiatives Change Log',
  INITIATIVE_UPDATES = '[Initiatives] Get Initiatives Updates',
  INITIATIVE_CONTRIBUTORS = '[Initiatives] Get Initiatives Contributors',
  INITIATIVE_SPRINT = '[INITIATIVE_SPRINT] Get Initiatives Sprint',
  INITIATIVE_TEAMS = '[INITIATIVE_TEAMS] Get Initiatives Teams',
}

export type InitiativesAction = {
  type: InitiativesActionTypes;
  payload?: any;
};

export const InitiativesReducer = (
  state: InitiativesState,
  action: InitiativesAction
): InitiativesState => {
  switch (action.type) {
    case InitiativesActionTypes.INITIATIVE_DATA:
      return { ...state, initiativeData: action?.payload };

    case InitiativesActionTypes.INITIATIVE_DETAILS:
      return { ...state, initiativeDetails: action?.payload };

    case InitiativesActionTypes.INITIATIVE_TASK_LIST:
      return { ...state, taskList: action?.payload };

    case InitiativesActionTypes.INITIATIVE_CHANGE_LOG:
      return { ...state, changeLog: action?.payload };

    case InitiativesActionTypes.INITIATIVE_UPDATES:
      return { ...state, updatesData: action?.payload };

    case InitiativesActionTypes.INITIATIVE_CONTRIBUTORS:
      return { ...state, contributors: action?.payload };

    case InitiativesActionTypes.INITIATIVE_SPRINT:
      return { ...state, sprint: action?.payload };

    case InitiativesActionTypes.INITIATIVE_TEAMS:
      return { ...state, teams: action?.payload };
    default:
      return state;
  }
};
