import {
  useChartTableGql,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import { FilterByType } from '../../../utils/metricTableTypes';
import { orderColumns } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

export function usePrTableQuery(
  startDate: string,
  endDate: string,
  metric: string
) {
  const metricFilterBy = useMetricsTableStore();
  const metricData = useChartTableGql(
    startDate,
    endDate,
    metricFilterBy.componentOrigin === 'contributor-profile-pr' &&
      metricFilterBy.status === 'merged'
      ? 'PR_MERGE'
      : metric,
    {
      teamId: metricFilterBy.teamId,
      author: metricFilterBy.author,
      reviewer: metricFilterBy.reviewer,
      sprintId: metricFilterBy.sprintId ?? '',
      project: '',
      projectsFilter: metricFilterBy.projectsFilter ?? [],
      type: metricFilterBy.type,
      status: metricFilterBy.status === 'all' ? null : metricFilterBy.status,
      prViewFilters: {
        prSize: metricFilterBy.prSize,
        age: metricFilterBy.filterAge ?? null,
        prIds: metricFilterBy.prIds,
        searchQuery: metricFilterBy.searchQuery,
        prReviewResponsivenessType: metricFilterBy.prReviewResponsivenessType,
        prFeedbackResponsivenessType:
          metricFilterBy.prFeedbackResponsivenessType,
        isPrReviewedWithComment: metricFilterBy.isPrReviewedWithComment,
        isPrLinked: metricFilterBy.isPrLinked,
        isPrWithComment: metricFilterBy.isPrWithComment,
        isLongPrCycleTime: metricFilterBy.isLongPrCycleTime,
        isMergedWithReview: metricFilterBy.isMergedWithReview,
        prIterations: metricFilterBy.prIterations,
      },
      metricSpecificFilter: {
        age: metricFilterBy.age,
        name: metricFilterBy.name ?? null,
        metricKey: metricFilterBy.metricKey,
        lowerBoundIncl: metricFilterBy.lowerBoundIncl,
        upperBound: metricFilterBy.upperBound,
      },
      orderBy: metricFilterBy.orderBy as string,
      orderColumn: metricFilterBy.orderColumn as string,
    },
    0,
    20,
    '',
    true,
    metricFilterBy.isQueryReady
  );
  return { ...metricData };
}

export default usePrTableQuery;
