import styled from 'styled-components';

export const IntegrationCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 234px;
  min-height: 145px;
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  padding: 24px 20px;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export const IntegrationName = styled.h2`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 17px;
`;

export const Status = styled.h1`
  margin-top: 20px;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.success};
  line-height: 15px;
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div``;
