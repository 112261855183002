export const TickIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.66667 0.5L3.70833 6.5L1 3.77273"
        stroke="#107C10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickIcon;
