import moment from 'moment';
import { DataProps, ItemMetaType } from './ActivityLog.types';

export const getCurrentWeek = (currentDate = moment()) => {
  const weekStart = currentDate.clone().startOf('isoWeek');
  const weekEnd = currentDate.clone().endOf('isoWeek');

  const days = [];

  for (let i = 0; i <= 13; i++) {
    days.push(moment(weekStart).add(i, 'days').format('YYYY-MM-DD'));
  }
  return days;
};

export const itemMeta: ItemMetaType[] = [
  {
    label: 'PR_OPEN',
    fillColor: '#EF6B6B',
  },
  {
    label: 'PR_MERGED',
    fillColor: '#61CDBB',
  },
  {
    label: 'COMMIT',
    fillColor: '#FAC76E',
  },
];

export const data: DataProps[] = [];

export const convertHeading = (heading: string) => {
  switch (heading) {
    case 'Commits':
      return 'Commits';
    case 'PR Reviewed':
      return 'PR reviewed';
    case 'PR Comment':
      return 'PR commented';
    case 'PR Open':
      return 'PR Opened';
    case 'PR Merged':
      return 'Merged PRs';
    case 'Issues Done':
      return 'Issues Done';
    case 'Bugs Open':
      return 'Bugs Opened';
    case 'Bugs Closed':
      return 'Bugs Closed';
    case 'Issues Open':
      return 'Issues Opened';
    default:
      return heading;
  }
};
