import { useRef, useState } from 'react';
import { InstantSearch, Hits, Highlight, useHits } from 'react-instantsearch';

import algoliasearch from 'algoliasearch/lite';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  UnorderedList,
  useDisclosure,
  Flex,
  IconButton,
  Divider,
  Tooltip,
  Icon,
  Tag,
  Text,
} from '@chakra-ui/react';

import CustomSearchBox from './CustomSearchBox';
import { MdOutlineSubdirectoryArrowLeft } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';

const appId = process.env.NX_ALGOLIA_APP_ID!;
const apiKey = process.env.NX_ALGOLIA_API_KEY!;
const searchClient = algoliasearch(appId, apiKey);

const Hit = ({ hit, onClose }: any) => {
  const url = new URL(hit?.url);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link to={url?.pathname + url?.search}>
      <Flex
        onClick={onClose}
        borderWidth="1px"
        justify="space-between"
        borderRadius="lg"
        p={3}
        mb={3}
        cursor="pointer"
        _hover={{ bg: 'primary', color: 'white' }}
        bg={isHovered ? 'primary' : 'white'}
        color={isHovered ? 'white' : 'gray.600'}
        transition="all 0.2s ease"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Text
          sx={{
            '.ais-Highlight-highlighted': {
              color: isHovered ? 'white' : '#4D4DFF',
              backgroundColor: 'transparent',
            },
          }}
          fontFamily="heading"
          fontWeight="normal"
        >
          <Highlight
            classNames={{
              highlighted: '#fffff',
            }}
            attribute="title"
            hit={hit}
          />
        </Text>

        <MdOutlineSubdirectoryArrowLeft size="18" color="#fff" />
      </Flex>
    </Link>
  );
};

const SearchComponent = () => {
  const [query, setQuery] = useState('');
  const finalRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
  };

  const CustomHits = () => {
    const { results } = useHits();
    return (
      <Box>
        {results && results.hits.length > 0 ? (
          results &&
          results.hits.map((hit: any) => (
            <Hit key={hit.objectID} hit={hit} onClose={onClose} />
          ))
        ) : (
          <Text
            fontFamily={'heading'}
            textAlign={'center'}
            my={3}
            opacity={0.75}
          >
            No results found for "{results && results.query}"
          </Text>
        )}
      </Box>
    );
  };

  return (
    <>
      <Button
        variant="primary"
        ref={finalRef}
        color="text.primary"
        height="32px"
        _hover={{ color: 'primary', bg: 'gray.100' }}
        w="220px"
        aria-label="search"
        onClick={onOpen}
        mr={2}
        display={'flex'}
        justifyContent={'start'}
        alignItems={'center'}
        pl={1.5}
      >
        <Icon
          color="primary"
          height={4}
          width={4}
          as={SearchIcon}
          mb="2px"
        ></Icon>
        <Tag
          color="primary"
          background={'transparent'}
          fontSize={'xs'}
          fontFamily={'heading'}
        >
          Search
        </Tag>
      </Button>
      <Modal
        finalFocusRef={finalRef}
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={2}>
          <ModalBody padding={0}>
            <InstantSearch
              indexName="prod_metrics_index"
              searchClient={searchClient}
            >
              <CustomSearchBox onQueryChange={handleQueryChange} />
              <Box maxH="440px" overflowY={'auto'}>
                {query.length > 0 && (
                  <>
                    <Divider mb={4} mt={3} w={'95%'} mx="auto" />
                    <Box
                      sx={{
                        '.ais-Hits-list': {
                          listStyleType: 'none',
                          padding: 0,
                          margin: 0,
                        },
                      }}
                      px={4}
                    >
                      <CustomHits />
                    </Box>
                  </>
                )}
              </Box>
            </InstantSearch>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchComponent;
