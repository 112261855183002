import React from 'react';
import { FirstDayOfWeek, useMonth } from '@datepicker-react/hooks';
import Day from './day';
import { IconButton, Box, Grid, Text } from '@chakra-ui/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

interface MonthProps {
  year: number;
  month: number;
  firstDayOfWeek: FirstDayOfWeek | undefined;
  goToPreviousMonths: () => void;
  goToNextMonths: () => void;
  selectedRange: [Date, Date] | null;
  onSelectRange: (newRange: [Date, Date]) => void;
}

const Month: React.FC<MonthProps> = ({
  year,
  month,
  firstDayOfWeek = 0,
  goToPreviousMonths,
  goToNextMonths,
  selectedRange,
  onSelectRange,
}) => {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const handleDayClick = (date: Date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (date > today) {
      return;
    }

    if (selectedRange) {
      const [start, end] = selectedRange;
      if (!end || date < start) {
        onSelectRange([date, start]);
      } else {
        onSelectRange([start, date]);
      }
    } else {
      onSelectRange([date, date]);
    }
  };

  return (
    <Box>
      <Box
        mb="12px"
        ml="12px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          aria-label="previous month"
          onClick={goToPreviousMonths}
          isRound
          bg="transparent"
          size="xs"
          icon={<AiOutlineLeft />}
        />
        <Text as="h3" size="sm" fontFamily={'inter'}>
          {monthLabel}
        </Text>
        <IconButton
          aria-label="next month"
          onClick={goToNextMonths}
          isRound
          bg="transparent"
          size="xs"
          icon={<AiOutlineRight />}
          isDisabled={year === currentYear && month >= currentMonth}
        />
      </Box>
      <Grid
        templateColumns="repeat(7, 1fr)"
        justifyContent="center"
        mb="10px"
        fontSize="10px"
      >
        {weekdayLabels.map((dayLabel) => (
          <Text textAlign="center" key={dayLabel}>
            {dayLabel.charAt(0)}
          </Text>
        ))}
      </Grid>
      <Grid templateColumns="repeat(7, 1fr)" justifyContent="center">
        {days.map((day, index) => {
          if (typeof day === 'object') {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const isFuture = day.date > today;

            const isSelected =
              selectedRange &&
              day.date >= selectedRange[0] &&
              day.date <= selectedRange[1];
            const isFirstSelected =
              selectedRange &&
              day.date.getTime() === selectedRange[0].getTime();
            const isLastSelected =
              selectedRange &&
              day.date.getTime() === selectedRange[1].getTime();

            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
                isSelected={isSelected}
                isFirstSelected={isFirstSelected}
                isLastSelected={isLastSelected}
                onClick={() => handleDayClick(day.date)}
                isFuture={isFuture}
              />
            );
          }

          return <Box key={index} />;
        })}
      </Grid>
    </Box>
  );
};

export default Month;
