import { apiClient } from '@devd-client/api';

export const IgnorePrOrIssue = (params: any): Promise<any> =>
  apiClient(
    `/v1/${params.type}/org/${localStorage.getItem('orgId')}?id=${params.id}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({}),
    }
  );
