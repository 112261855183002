import {
  Badge,
  Box,
  Flex,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Datepicker from '../dateRange/index';
import { START_DATE } from '@datepicker-react/hooks';
import moment from 'moment';
import {
  DatePickerContainer,
  DayTabsContainer,
  Tab,
  Wrapper,
} from './DayTabs.styled';
import { CustomizedOption } from './SprintBar';
import { Sprint } from './DayTabs.types';
import { useFavSprint, useFetchSprint, useRemoveFavSprint } from './api';
import { PeriodType } from '../shared/typings';
import CustomSelect from '../customSelect';
import { durationData, eliminateHtmlTags } from '../shared/utils';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useToastHook } from '../appToast';
import Loader from '../appLoader';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { CustomMultiSelect2 } from '../CustomMultiSelect2';

interface DayTabsProps {
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  periodOptions: any;
  setPeriodOptions?: (periodOptions: any) => void;
  AuthActionTypes?: any;
  getUser?: any;
  dispatch?: any;
  appState?: any;
  hideFavIcon?: boolean;
  multiSelect?: boolean;
  sprintIds?: any;
  setSprintIds?: (sprintIds: any) => void;
}

const customStyles = {
  option: (base: any, state: any) => ({
    ...base,
    color: '#1e2022',
    backgroundColor: state.isSelected ? '#F6F7F9' : 'white',
    padding: '.5rem 3rem .5rem .5rem',
    cursor: 'pointer',
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: '#E3E6EA',
    },
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const DayTabs: FC<DayTabsProps> = ({
  selected,
  setSelected,
  periodOptions,
  setPeriodOptions,
  appState,
  dispatch,
  getUser,
  AuthActionTypes,
  hideFavIcon,
  multiSelect,
  sprintIds,
  setSprintIds,
}) => {
  const { colors } = useTheme();

  const [newToast] = useToastHook();
  const [getUserLoading, setGetUserLoading] = useState(false);
  const [showMultiDropdown, setShowMultiDropdown] = useState(false);

  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  const { data, isLoading } = useFetchSprint();

  const [selectedSprints, setSelectedSprints] = useState<any>([]);

  const startDate = searchParams.get('start');
  const endDate = searchParams.get('end');
  const duration = searchParams.get('duration');

  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [clickedCustom, setClickedCustom] = useState<boolean>(false);

  const multiDropdownRef = useRef(null);

  useOutsideClick(multiDropdownRef);

  const [dateRange, setDateRange] = useState<any>({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });

  const [selectedSprint, setSelectedSprint] = useState<Sprint | null>(null);

  const { mutate: handleFavSprint, isLoading: handleFavTeamLoading } =
    useFavSprint();
  const { mutate: removeFavSprint, isLoading: removeFavTeamLoading } =
    useRemoveFavSprint();

  useEffect(() => {
    if (dateRange.endDate !== null) {
      handleCloseDateRange();
    }
  }, [dateRange.endDate]);

  useEffect(() => {
    if (selected?.duration === 'Sprint') {
      setSelectedSprint({
        label: selected.sprintName!,
        value: selected.sprintName!,
        startDate: selected.startDate!,
        endDate: selected.endDate!,
        sprintId: selected.sprintId!,
      });
    }
  }, [selected?.duration]);

  useEffect(() => {
    if (clickedCustom && dateRange.endDate) {
      setPeriodOptions &&
        setPeriodOptions(
          periodOptions.map((item: any) =>
            item.duration === 'Custom'
              ? {
                  ...item,
                  startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                  endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                }
              : item
          )
        );

      setSelected(
        periodOptions
          .filter((item: any) => item.duration === 'Custom')
          .map((el: any) => ({
            ...el,
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
          }))[0]
      );
    }
  }, [clickedCustom, dateRange.endDate]);

  useEffect(() => {
    if (selectedSprint?.endDate) {
      setPeriodOptions &&
        setPeriodOptions(
          periodOptions.map((item: any) =>
            item.duration === 'Sprint'
              ? {
                  ...item,
                  startDate: moment(selectedSprint.startDate).format(
                    'YYYY-MM-DD'
                  ),
                  endDate: moment(selectedSprint.endDate).format('YYYY-MM-DD'),
                }
              : item
          )
        );

      setSelected(
        periodOptions
          .filter((item: any) => item.duration === 'Sprint')
          .map((el: any) => ({
            ...el,
            startDate: moment(selectedSprint.startDate).format('YYYY-MM-DD'),
            endDate: moment(selectedSprint.endDate).format('YYYY-MM-DD'),
            sprintName: selectedSprint.value,
            sprintId: selectedSprint.sprintId,
          }))[0]
      );
    }
  }, [selectedSprint?.endDate]);

  useEffect(() => {
    if (selected.duration !== 'Sprint') {
      setSelectedSprints([]);
    }
  }, [selected]);

  function useOutsideClick(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseSprintModal();
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const handleOpenDateRange = useCallback(() => {
    setShowDateRange(true);
  }, [showDateRange]);

  const handleCloseDateRange = useCallback(() => {
    setShowDateRange(false);
  }, [showDateRange]);

  const handleCloseSprintModal = useCallback(() => {
    setShowMultiDropdown(false);
  }, [showMultiDropdown]);

  return (
    <Wrapper>
      <DayTabsContainer>
        {periodOptions.map((period: PeriodType) => (
          <Tab
            key={period?.id}
            borderB={colors.primary}
            selected={period.id === selected?.id}
            onClick={() => {
              if (period?.duration === 'Custom') {
                handleOpenDateRange();
                setClickedCustom(true);
                setSelectedSprint(null);
              } else {
                setClickedCustom(false);
                handleCloseDateRange();
                setSelected(period);
                setSelectedSprint(null);
              }
            }}
          >
            {period?.duration === 'Custom' &&
            duration === 'Custom' &&
            startDate !== '' &&
            endDate !== ''
              ? `${moment(startDate).format('ll')}-${moment(endDate).format(
                  'll'
                )}`
              : period?.duration === 'Sprint'
              ? null
              : period?.duration}
          </Tab>
        ))}

        {multiSelect ? (
          <Flex
            position="relative"
            direction="column"
            align={'flex-end'}
            ref={multiDropdownRef}
          >
            <Flex
              align="center"
              padding="3px"
              border={
                selectedSprints?.length > 0
                  ? '1px solid #0095E6'
                  : '1px solid #CBD5E0'
              }
              borderRadius="4px"
              width="183px"
              justify={'space-between'}
              onClick={() =>
                showMultiDropdown
                  ? setShowMultiDropdown(false)
                  : setShowMultiDropdown(true)
              }
            >
              <Flex align="center">
                <Text userSelect={'none'} fontSize="sm" as="span" mr={1}>
                  Sprint
                </Text>
                {selectedSprints?.length > 0 && (
                  <Badge
                    userSelect="none"
                    rounded={'3xl'}
                    color="primary"
                    bg="blue.100"
                  >
                    {selectedSprints?.length}
                  </Badge>
                )}
              </Flex>

              {showMultiDropdown ? (
                <IconButton
                  aria-label="Search down"
                  size="xs"
                  isRound
                  bg="transparent"
                  onClick={() => setShowMultiDropdown(false)}
                  icon={<MdKeyboardArrowUp />}
                />
              ) : (
                <IconButton
                  aria-label="Search down"
                  size="xs"
                  isRound
                  bg="transparent"
                  onClick={() => setShowMultiDropdown(true)}
                  icon={<MdKeyboardArrowDown />}
                />
              )}
            </Flex>
            {showMultiDropdown && (
              <Box position="absolute" bg="white" top={12} width="440px">
                {data && data.length > 0 && (
                  <CustomMultiSelect2
                    placeholder={'Search Sprint'}
                    options={[
                      ...(data && Array.isArray(data)
                        ? data.map((item: any, index: number) => ({
                            label: item.sprintName,
                            value: item.sprintName,
                            startDate: item.startDate,
                            endDate: item.endDate,
                            sprintId: item.sprintId,
                            id: index,
                          }))
                        : []),
                    ]}
                    value={selectedSprints}
                    onChange={(selectedOptions: any) => {
                      if (selectedOptions?.length === 0) {
                        setSelected(durationData[3]);
                        setSelectedSprints([]);
                        if (setSprintIds) setSprintIds([]);
                      } else {
                        setSelectedSprints(selectedOptions);
                        setSelected({
                          ...periodOptions[6],
                          startDate,
                          endDate,
                        });
                        if (setSprintIds)
                          setSprintIds(
                            selectedOptions?.map((item: any) => item.sprintId)
                          )!;
                      }
                    }}
                  />
                )}
              </Box>
            )}
          </Flex>
        ) : (
          <CustomSelect
            isLoading={isLoading}
            size="sm"
            defaultItem={selectedSprint}
            placeholder={'Select Sprint'}
            onChange={(selected: string) => {
              if (selected === '') {
                setSelected(durationData[3]);
                setSelectedSprint(null);
              } else {
                setSelectedSprint(
                  data
                    ?.filter(
                      (item: any) =>
                        item?.sprintName === eliminateHtmlTags(selected)
                    )
                    ?.map((item: any) => ({
                      label: item.sprintName,
                      value: item.sprintName,
                      startDate: item.startDate,
                      endDate: item.endDate,
                      sprintId: item.sprintId,
                    }))[0]
                );
              }
            }}
            styles={customStyles}
            components={{
              Option: CustomizedOption,
            }}
            options={[
              { label: '', value: '' },
              ...(data && Array.isArray(data)
                ? data.map((item: any) => ({
                    label: item.sprintName,
                    value: item.sprintName,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    sprintId: item.sprintId,
                  }))
                : []),
            ]}
          />
        )}

        {!hideFavIcon && (
          <>
            <Box height="32px" width="1.5px" bg="gray.300" mx={2} />

            {getUserLoading || handleFavTeamLoading || removeFavTeamLoading ? (
              <Skeleton height="32px" width="32px" />
            ) : (
              <Tooltip
                hasArrow
                isDisabled={selected?.duration !== 'Sprint'}
                label={
                  selectedSprint?.sprintId === appState?.user?.dto?.sprint
                    ? 'Remove from Favourite Sprint'
                    : 'Mark as Favourite Sprint'
                }
                placement="bottom-end"
              >
                <IconButton
                  bg="transparent"
                  size="sm"
                  isDisabled={selected?.duration !== 'Sprint'}
                  border="1px solid"
                  borderColor="#CBD5E0"
                  aria-label="Favorite"
                  icon={
                    selected?.duration === 'Sprint' &&
                    selectedSprint?.sprintId === appState?.user?.dto?.sprint ? (
                      <AiFillHeart size={24} color={colors.text.secondary} />
                    ) : (
                      <AiOutlineHeart size={24} color={colors.text.secondary} />
                    )
                  }
                  onClick={() => {
                    selectedSprint?.sprintId === appState?.user?.dto?.sprint
                      ? removeFavSprint(
                          {
                            sprint: selectedSprint?.sprintId,
                          },
                          {
                            onSuccess: () => {
                              setGetUserLoading(true);
                              getUser()
                                .then((res: any) => {
                                  setGetUserLoading(false);
                                  dispatch({
                                    type: AuthActionTypes.AUTH_READY,
                                    payload: { ...appState.user, ...res },
                                  });
                                })
                                .catch((err: any) => {
                                  newToast({
                                    message:
                                      err?.message ?? 'Something went wrong',
                                    status: 'error',
                                  });
                                  setGetUserLoading(false);
                                });
                            },
                          }
                        )
                      : appState?.user?.dto?.sprint
                      ? newToast({
                          message: `${
                            data?.find(
                              (el: any) =>
                                el.sprintId === appState?.user.dto?.sprint
                            )?.sprintName
                          } is marked as Favourite. Remove it from favourite and try again.`,
                          status: 'error',
                        })
                      : handleFavSprint(
                          {
                            sprint: selectedSprint?.sprintId,
                          },
                          {
                            onSuccess: () => {
                              setGetUserLoading(true);
                              getUser()
                                .then((res: any) => {
                                  setGetUserLoading(false);

                                  dispatch({
                                    type: AuthActionTypes.AUTH_READY,
                                    payload: {
                                      ...appState.user,
                                      ...res,
                                    },
                                  });
                                })
                                .catch((err: any) => {
                                  newToast({
                                    message:
                                      err?.message ?? 'Something went wrong',
                                    status: 'error',
                                  });
                                  setGetUserLoading(false);
                                });
                            },
                          }
                        );
                  }}
                />
              </Tooltip>
            )}
          </>
        )}
      </DayTabsContainer>

      {showDateRange && (
        <DatePickerContainer>
          <Datepicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleCloseDateRange={handleCloseDateRange}
          />
        </DatePickerContainer>
      )}
    </Wrapper>
  );
};
