import { Button, Center, Image, Stack, Text } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import Loader from '../appLoader';
import {
  BottomContainer,
  ButtonContainer,
  Container,
  Heading,
  LeftSection,
  Paragraph,
  RightSection,
  TopContainer,
  ZeroStatePageContainer,
} from './zeroStatePage.styled';
import { LockIcon } from '../icons';
import { AddMetrics } from '../addMetrics';

// Todo:: Add data type to data interface
type DataType = {
  description: string;
  enabled: boolean;
  images: [{ [key: string]: string }];
  message: string;
  title: string;
};

interface ZeroStatePageProps {
  data: any;
  featureAlert?: boolean;
  queryKeys?: any;
}

const LockPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99;
`;

const Card = styled.div`
  height: 240px;
  width: 400px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: grid;
  place-items: center;

  & h1 {
    font-weight: 600;
    font-family: 'Inter';
    font-size: 20px;
  }
`;

const LockPage = () => {
  const navigate = useNavigate();
  return (
    <LockPageContainer>
      <Card>
        <Stack spacing={4}>
          <Center>
            <LockIcon height={'80px'} width="300px" />
          </Center>

          <Text
            fontFamily="heading"
            fontSize="xl"
            fontWeight="medium"
            color="text.secondary"
            alignSelf="center"
          >
            Upgrade to access feature
          </Text>

          <Button
            variant="tertiary"
            width="80%"
            alignSelf="center"
            onClick={() => navigate('/settings/subscription-billing')}
          >
            Upgrade
          </Button>
        </Stack>
      </Card>
    </LockPageContainer>
  );
};

export const ZeroStatePage: FC<ZeroStatePageProps> = ({
  data,
  featureAlert,
  queryKeys,
}) => {
  const navigate = useNavigate();
  const [openAddMetric, setOpenAddMetric] = useState<boolean>(false);
  return (
    <Container>
      {featureAlert && <LockPage />}
      {data ? (
        <ZeroStatePageContainer showBlur={featureAlert ? true : false}>
          <TopContainer>
            <LeftSection>
              {data?.images?.left && (
                <Image
                  loading="lazy"
                  boxSize="100%"
                  objectFit="contain"
                  src={data.images.left}
                  alt="Metric images"
                />
              )}
            </LeftSection>
            <RightSection>
              <Heading>{data?.title}</Heading>

              <Paragraph>{data?.description}</Paragraph>

              <ButtonContainer>
                <Button
                  width="240px"
                  variant="tertiary"
                  onClick={() => {
                    if (`${data?.actionText}` === 'Mark Favourite') {
                      setOpenAddMetric(true);
                    } else {
                      navigate(`${data?.actionNav}`);
                    }
                  }}
                >
                  {`${data?.actionText}`}
                </Button>
              </ButtonContainer>
            </RightSection>
          </TopContainer>
          <BottomContainer>
            {data?.images?.bottom && (
              <Image
                loading="lazy"
                boxSize="100%"
                objectFit="contain"
                src={data?.images?.bottom}
                alt="Metric images"
              />
            )}
          </BottomContainer>

          {openAddMetric && (
            <AddMetrics
              title="Add Metrics"
              modalHeading={`Select metrics to add in Favourite.`}
              name={'Favourite'}
              buttonText={`Add to Favourite`}
              queryKeys={queryKeys}
              openAddMetric
              setOpenAddMetric={setOpenAddMetric}
            />
          )}
        </ZeroStatePageContainer>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default ZeroStatePage;
