export function InsightIcon2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.99998 14.6667V11.3334M2.99998 4.66671V1.33337M1.33331 3.00004H4.66665M1.33331 13H4.66665M8.66665 2.00004L7.51053 5.00595C7.32252 5.49477 7.22852 5.73918 7.08233 5.94476C6.95277 6.12697 6.79358 6.28617 6.61137 6.41573C6.40578 6.56191 6.16137 6.65591 5.67255 6.84392L2.66665 8.00004L5.67256 9.15616C6.16137 9.34417 6.40578 9.43817 6.61137 9.58435C6.79358 9.71391 6.95277 9.87311 7.08233 10.0553C7.22852 10.2609 7.32252 10.5053 7.51053 10.9941L8.66665 14L9.82277 10.9941C10.0108 10.5053 10.1048 10.2609 10.251 10.0553C10.3805 9.87311 10.5397 9.71391 10.7219 9.58435C10.9275 9.43817 11.1719 9.34417 11.6607 9.15616L14.6666 8.00004L11.6607 6.84392C11.1719 6.65591 10.9275 6.56191 10.7219 6.41573C10.5397 6.28617 10.3805 6.12697 10.251 5.94476C10.1048 5.73918 10.0108 5.49477 9.82276 5.00595L8.66665 2.00004Z"
        stroke={props.color || '#626266'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
