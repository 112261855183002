import { Box, Text } from '@chakra-ui/react';
import { formatNumber } from '../../helpers/home.utils';

interface CopilotDataCardProps {
  title: string;
  number: string;
}

export const CopilotDataCard = ({ title, number }: CopilotDataCardProps) => {
  return (
    <Box
      mb={4}
      p={4}
      border="1px solid #E0E0E0"
      borderRadius="md"
      textAlign="center"
    >
      <Text fontSize="lg" fontWeight="semibold" color={'text.primary'}>
        {formatNumber(number)}
      </Text>
      <Text fontSize="xs" color="text.secondary3">
        {title}
      </Text>
    </Box>
  );
};
