import { Box, Flex } from '@chakra-ui/react';
import {
  DataGrid,
  Paginate,
  PieChartShared,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { fillColors } from '../../../../../../helpers/report.utils';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-all;
  cursor: pointer;

  &::after {
    content: attr(data-break-chars);
    display: inline-block;
    word-break: normal;
  }
`;

const Cell = styled.div`
  word-break: break-all;
  &::after {
    content: attr(data-break-chars);
    display: inline-block;
    word-break: normal;
  }
`;

interface ListViewProps {
  data: any;
  loading: boolean;
}

const ListView = ({ data, loading }: ListViewProps) => {
  const [currentPage, setCurrentPage] = useState(0);

  const rows: any[] = useMemo(() => data || [], [data]);

  const columnHelper = createColumnHelper<any>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('contributor', {
        cell: (info) => (
          <StyledLink
            to={`/contributors/${info.getValue()}`}
            target="_blank"
            rel="noopener"
          >
            {info.getValue()}
          </StyledLink>
        ),
        header: 'Contributor',
        size: 120,
      }),

      columnHelper.accessor('teams', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: 'Team',
        size: 100,
      }),

      columnHelper.accessor('outcomePoints', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: 'Outcome Points',
        size: 100,
      }),

      columnHelper.accessor('achievedOutcomePoints', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: 'Achieved Outcome Points',
        size: 100,
      }),

      columnHelper.accessor('timelyDeliveryPercentage', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Timely Delivery(%)',
        size: 100,
      }),

      columnHelper.accessor('sub-parReviewer', {
        cell: (info) => (
          <Flex height="180px" width="180px">
            <PieChartShared
              data={
                (info.row?.original?.subParReviewerCountForCriticalIssues !=
                  null &&
                  info.row?.original?.subParReviewerCountForOtherIssues !=
                    null &&
                  Object.entries({
                    Critical:
                      info.row?.original?.subParReviewerCountForCriticalIssues,
                    Other:
                      info.row?.original?.subParReviewerCountForOtherIssues,
                  })?.map(([key, value], index: number) => {
                    return { name: key, value, fill: fillColors[index] };
                  })) ||
                []
              }
            />
          </Flex>
        ),
        header: 'Sub-Par Reviewer',
        size: 150,
      }),
      columnHelper.accessor('Bug Caused By', {
        cell: (info) => (
          <Flex height="180px" width="180px">
            <PieChartShared
              data={
                (info.row.original?.bugCausedByCountForCriticalIssues != null &&
                  info.row.original?.bugCausedByCountForOtherIssues != null &&
                  Object.entries({
                    Critical:
                      info.row.original?.bugCausedByCountForCriticalIssues,
                    Other: info.row.original?.bugCausedByCountForOtherIssues,
                  })?.map(([key, value], index: number) => {
                    return { name: key, value, fill: fillColors[index] };
                  })) ||
                []
              }
            />
          </Flex>
        ),
        header: 'Bug Caused By',
        size: 150,
      }),
    ],
    [data]
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <Box>
      <DataGrid
        data={rows}
        columns={columns}
        showLoader={loading}
        useMaxH
        sticky="firstCol"
      />

      {/* <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={data?.currentPage + 1}
          totalPageCount={data?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box> */}
      {/* {(customFieldLoading || deleteCustomMetricLoading) && <Loader />} */}
    </Box>
  );
};

export default ListView;
