import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import SettingsModal from '../../SettingsModal';
import { GoalsContext } from '../../../context/goals.context';
import {
  ISelect,
  Loader,
  Switch,
  isHTML,
  useToastHook,
} from '@devd-client/devd/components';
import {
  DetailsHeading,
  DetailsSectionContainer,
  DetailsParagraph,
  Divider,
  ToggleButtonContainer,
  ToggleText,
  SettingBtnContainer,
  TagLabel,
  TagLabels,
} from './DetailSection.styled';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS, useGoalSettingsMutation } from '../../../apis';
import ShowFields from './ShowFeilds';
import ReactHtmlParser from 'html-react-parser';

interface DetailsSectionProps {
  selectedTeam: ISelect;
}

const DetailsSection: FC<DetailsSectionProps> = ({ selectedTeam }) => {
  const {
    state: { clickedGoal },
  } = useContext(GoalsContext);

  const [newToast] = useToastHook();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeToggle, setActiveToggle] = useState<boolean>(false);
  const [dataToSend, setDataToSend] = useState<any>({});

  const queryClient = useQueryClient();
  const { mutate: handleGoalUpdate, isLoading } = useGoalSettingsMutation();

  useEffect(() => {
    setActiveToggle(clickedGoal?.enabled);
  }, [clickedGoal]);

  useEffect(() => {
    setDataToSend({ ...clickedGoal, team: selectedTeam.value });
  }, [clickedGoal, selectedTeam.value]);

  const handleModalOpen = () => {
    if (!selectedTeam.value) {
      newToast({ message: 'Select any team', status: 'error' });
      return;
    } else {
      setIsOpen(true);
    }
  };

  const handleModalClose = useCallback(() => setIsOpen(false), [isOpen]);

  return (
    <>
      <DetailsSectionContainer>
        {clickedGoal?.tag?.length > 0 && (
          <TagLabels>
            {clickedGoal?.tag?.map((item: string) => (
              <TagLabel key={item}>{item}</TagLabel>
            ))}
          </TagLabels>
        )}
        <DetailsHeading>{clickedGoal?.title}</DetailsHeading>
        {isHTML(clickedGoal?.description) ? (
          <Box mt={3}>{ReactHtmlParser(clickedGoal?.description)}</Box>
        ) : (
          <DetailsParagraph>{clickedGoal?.description}</DetailsParagraph>
        )}

        <Divider />

        <ShowFields clickedGoal={clickedGoal} />
        <ToggleButtonContainer>
          <ToggleText>Active</ToggleText>
          <Switch
            id="test-switch"
            toggled={activeToggle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!selectedTeam.value) {
                newToast({ message: 'Select any team', status: 'error' });
                return;
              }

              if (
                localStorage.getItem('userId') ===
                'a867b5e7-ee22-4a9f-becd-b8f3aa43f753'
              ) {
                newToast({
                  message: "You don't have access.",
                  status: 'error',
                });
                return;
              }

              setActiveToggle(e.target.checked);

              handleGoalUpdate(
                { ...dataToSend, enabled: e.target.checked },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries([QUERY_KEYS.goals]);
                    newToast({
                      message: `Agreement settings updated successfully.`,
                      status: 'success',
                    });
                    e.target.checked === true &&
                      setTimeout(() => {
                        newToast({
                          message:
                            'We will begin capturing exceptions going forward.',
                          status: 'info',
                        });
                      }, 2000);
                  },
                }
              );
            }}
          />
        </ToggleButtonContainer>
        <SettingBtnContainer>
          <Button
            variant="ghost"
            color="primary"
            onClick={() => {
              if (
                localStorage.getItem('userId') ===
                'a867b5e7-ee22-4a9f-becd-b8f3aa43f753'
              ) {
                newToast({
                  message: "You don't have access.",
                  status: 'error',
                });
                return;
              } else if (!activeToggle) {
                newToast({
                  message: 'Settings disabled for inactive agreements.',
                  status: 'error',
                });
              } else {
                handleModalOpen();
              }
            }}
          >
            Settings
          </Button>
        </SettingBtnContainer>
      </DetailsSectionContainer>

      {isOpen && (
        <SettingsModal
          isOpen={isOpen}
          handleModalClose={handleModalClose}
          selectedTeam={selectedTeam.value}
          clickedGoal={clickedGoal}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default DetailsSection;
