import {
  ActivityLogAction,
  ActivityLogState,
  activityLogReducer,
} from '../reducers/activityLogReducer';
import { createContext, useReducer } from 'react';

const initialState = {
  workLogData: [],
  workLogMeta: [],
  workLogDetails: [],
};

export const ActivityLogContext = createContext<{
  state: ActivityLogState;
  dispatch: React.Dispatch<ActivityLogAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ActivityLogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(activityLogReducer, initialState);

  return (
    <ActivityLogContext.Provider value={{ state, dispatch }}>
      {children}
    </ActivityLogContext.Provider>
  );
};

export default ActivityLogProvider;
