export const ThunderIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 10 10"
      fill={props.color || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.41667 0.833374L1.25 5.83337H5L4.58333 9.16671L8.75 4.16671H5L5.41667 0.833374Z"
        stroke={props.stroke || '#FF8D70'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThunderIcon;
