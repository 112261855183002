import { useAuth, AuthActionTypes, getUser } from '@devd-client/devd/auth';
import styled from 'styled-components';
import IntegrationOptions from '../components/IntegrationOptions';
import {
  DateFilter,
  MetaTags,
  TeamMenu,
  durationData,
  removeEmptyQueryParams,
  useAiAssistantStore,
  useQueryState,
} from '@devd-client/devd/components';
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import Insights from '../components/Insights';
import Summary from '../components/Summary';
import InsightCards from '../components/InsightCards';
import { useEffect, useState } from 'react';
import { Chatbox } from '../components/Chatbox';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';
import { IoCloseOutline } from 'react-icons/io5';

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 24px;
`;

const WelcomeName = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 29px;
  width: calc(100% - 380px);
`;

const Name = styled.span``;

export function HomePage() {
  const [AppState, dispatch] = useAuth();
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { isOpen } = useAiAssistantStore();

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  useEffect(() => {
    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }

    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }
  }, [selectedTeam, setTeam, selected]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : AppState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(AppState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(AppState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: AppState?.user?.dto?.sprint,
          sprintName: JSON.parse(AppState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <Flex direction="column" width="100%" minH="100%">
      <MetaTags
        title="Overview | Dashboard | Devdynamics"
        description="Welcome to Devdynamics"
        keywords="home, devdynamics"
      />

      <HomePageContainer>
        <Flex justify="space-between" align="flex-end">
          <TeamMenu
            selectedTeam={selectedTeam}
            handleSelectedTeam={setSelectedTeam}
            AuthActionTypes={AuthActionTypes}
            getUser={getUser}
            appState={AppState}
            dispatch={dispatch}
          />

          <DateFilter
            getUser={getUser}
            appState={AppState}
            dispatch={dispatch}
            AuthActionTypes={AuthActionTypes}
          />
        </Flex>

        <Box w="full">
          <Summary team={selectedTeam.value} selected={selected} />
        </Box>

        <Box mt={4} w="full">
          <InsightCards team={selectedTeam.value} selected={selected} />
        </Box>

        {isOpen && <Chatbox />}

        {/* <Box
            mt={12}
            bg="white"
            position="fixed"
            top={0}
            height="100%"
            right={2}
            width="380px"
            zIndex="1"
            borderLeft="0.5px solid rgba(0, 0, 0, 0.1)"
          >
            <Insights />
          </Box> */}
      </HomePageContainer>
    </Flex>
  );
}

export default HomePage;
