import { useMetricsTableStore } from '@devd-client/devd/components';
import { OptionBase } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import useOptions from './useOptions';
import { FilterByType, GetOptionsType } from '../../../utils/metricTableTypes';

interface Option extends OptionBase {
  label: string;
  value: any;
}
// This hook manages all changes in options, i.e selecting options.
export function useHandleOptionChange() {
  // below initalRenderTrigger is used to prevent reset of filters when team filter changes. need to incorprate this for date filte also.
  const [showOption, setShowOption] = useState<GetOptionsType>('');

  const [defaultValue, setDefaultValue] = useState<Option>({
    label: '',
    value: '',
  });
  const [teamValue, setTeamValue] = useState<Option>({
    label: 'Org',
    value: '',
  });
  const [secondaryDefaultValue, setsecondaryDefaultValue] = useState<Option>({
    label: '',
    value: '',
  });
  const handleTeamOptionChange = (team: string) => {
    setTeamValue({
      value: team,
      label: team ? team : 'Org',
    });
  };

  const handleDefaultOptionChange = (label: string, value: string) => {
    setDefaultValue({ label, value });
  };
  const handleSecondaryDefaultOptionChange = (label: string, value: string) => {
    setsecondaryDefaultValue({ label, value });
  };
  const handleShowOptionChange = (value: GetOptionsType) => {
    setShowOption(value);
  };

  return {
    showOption,
    defaultValue,
    teamValue,
    secondaryDefaultValue,
    handleTeamOptionChange,
    handleDefaultOptionChange,
    handleSecondaryDefaultOptionChange,
    handleShowOptionChange,
  };
}

export default useHandleOptionChange;
