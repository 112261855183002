export function folderCode(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66634 4.66667L7.92265 3.17928C7.70861 2.7512 7.60158 2.53715 7.44192 2.38078C7.30073 2.24249 7.13056 2.13732 6.94372 2.07287C6.73245 2 6.49315 2 6.01454 2H3.46634C2.7196 2 2.34624 2 2.06102 2.14532C1.81014 2.27316 1.60616 2.47713 1.47833 2.72801C1.33301 3.01323 1.33301 3.3866 1.33301 4.13333V4.66667M1.33301 4.66667H11.4663C12.5864 4.66667 13.1465 4.66667 13.5743 4.88465C13.9506 5.0764 14.2566 5.38236 14.4484 5.75869C14.6663 6.18651 14.6663 6.74656 14.6663 7.86667V10.8C14.6663 11.9201 14.6663 12.4802 14.4484 12.908C14.2566 13.2843 13.9506 13.5903 13.5743 13.782C13.1465 14 12.5864 14 11.4663 14H4.53301C3.4129 14 2.85285 14 2.42503 13.782C2.0487 13.5903 1.74274 13.2843 1.55099 12.908C1.33301 12.4802 1.33301 11.9201 1.33301 10.8V4.66667ZM9.33301 11L10.9997 9.33333L9.33301 7.66667M6.66634 7.66667L4.99967 9.33333L6.66634 11"
        stroke="#2A2A2F"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default folderCode;
