import { Box } from '@chakra-ui/react';
import { Loader } from '@devd-client/devd/components';
import React from 'react';
import { AddTeamTab } from './addTeamTab';
import TeamView from './TeamTable';

interface CardTableProps {
  team: string;
  setTeamLength: any;
  appState?: any;
  teamLength?: number;
  plan?: string;
}

export const CardTable: React.FC<CardTableProps> = ({
  team,
  setTeamLength,
  appState,
  teamLength,
  plan,
}) => {
  const [active, setActive] = React.useState('1');

  return (
    <Box>
      {active === '3' ? (
        <AddTeamTab />
      ) : (
        <Box boxShadow={'lg'} borderRadius={'lg'}>
          {active === '1' && (
            <TeamView
              appState={appState}
              team={team}
              teamLength={teamLength}
              plan={plan}
              setTeamLength={setTeamLength}
            />
          )}

          {active.length <= 0 && <Loader />}
        </Box>
      )}
    </Box>
  );
};

export default CardTable;
