import styled from 'styled-components';

export const Container = styled.div`
  min-width: 360px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  background-color: #fff;
  padding: 0 12px;
`;

export const Divider = styled.div`
  height: 2px;
  background-color: #e5e5e5;
`;
