import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useTheme,
} from '@chakra-ui/react';
import {
  Breadcrumbs,
  eliminateHtmlTags,
  Loader,
  PageHeader,
  useToastHook,
  eliminateSpecialCharacters,
} from '@devd-client/devd/components';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { BsAsterisk } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import {
  DigestTimeRangeOptions,
  timeRangeOptions,
} from '../helpers/time-range';
import { colorStyles, isValidEmail } from '../helpers/teams.utils';
import TeamMembers from './TeamMembers';
import {
  useFetchAllBoardNames,
  useFetchAllGitProjects,
  useFetchAllIncidentManagement,
  useFetchAllIssueEpic,
  useFetchAllJiraProjects,
  useFetchDoraSettings,
  useFetchParentTeams,
  useFetchQualityProjects,
  useFetchTeamData,
  usePostTeam,
} from '../apis';
import { FieldType } from '../helpers/teams.types';

export const AddTeamTab = () => {
  const { colors } = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [newToast] = useToastHook();

  const team = pathname.split('/')[3];

  const {
    mutate: handleAddOrUpdateTeam,
    isLoading: handleAddOrUpdateTeamLoading,
  } = usePostTeam();
  const { data: parentTeams, isLoading: parentTeamLoading } =
    useFetchParentTeams(team);

  const { data: gitRepo } = useFetchAllGitProjects();
  const { data: jiraRepo } = useFetchAllJiraProjects();
  const { data: issueEpic } = useFetchAllIssueEpic();
  const { data: qualityRepo } = useFetchQualityProjects();

  const { data: incidentManagement } = useFetchAllIncidentManagement();

  const { data: doraSettings } = useFetchDoraSettings();

  const { data: response, isFetching: teamDetailsLoading } =
    useFetchTeamData(team);
  const { data: boardNames } = useFetchAllBoardNames();

  const defaultData = response?.dto;

  const [days, setDays] = useState([
    false,
    true,
    true,
    true,
    true,
    true,
    false,
  ]);

  const [parentTeam, setParentTeam] = useState({ value: '', label: '' });

  const [teamName, setTeamName] = useState<string>('');
  const [issueDone, setIssueDone] = useState<boolean>(false);
  const [issueInProgress, setIssueInProgress] = useState<boolean>(false);
  const [prMerged, setPrMerged] = useState<boolean>(false);
  const [goalExceptions, setGoalExceptions] = useState<boolean>(false);

  const [teamDescription, setTeamDescription] = useState<string>('');
  const [slackChannel, setSlackChannel] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [digest, setDigest] = useState<boolean>(false);

  const [tz, setTz] = useState(
    defaultData?.workTiming?.timeZone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [startTime, setStartTime] = useState('09:00');
  const [endTime, setEndTime] = useState('18:00');
  const [notificationTime, setNotificationTime] = useState('');
  const [selectedBoardNames, setSelectedBoardNames] = useState<any>([]);

  const [cfTeams, setCfTeams] = useState<any>('');
  const [cfLabels, setCfLabels] = useState<any>('');
  const [cfProjects, setCfProjects] = useState<any>([]);
  const [cfEpics, setCfEpics] = useState<any>([]);
  const [cfServicesList, setCfServicesList] = useState<any>([]);
  const [cfCustomField, setCfCustomField] = useState<any>('');
  const [cfRepos, setCfRepos] = useState<any>([]);

  const [deploymentLabels, setDeploymentLabels] = useState<any>('');
  const [deploymentPattern, setDeploymentPattern] = useState<any>('');
  const [deploymentProjects, setDeploymentProjects] = useState<any>([]);
  const [deploymentRepos, setDeploymentRepos] = useState<any>([]);
  const [deploymentCustomField, setDeploymentCustomField] = useState<any>('');
  const [workspaceRepo, setWorkspaceRepo] = useState<any>([]);
  const [workspaceIssue, setWorkspaceIssue] = useState<any>([]);
  const [qualityProjects, setQualityProjects] = useState<any>([]);

  const [filterDataByRepo, setFilterDataByRepo] = useState<boolean>(false);
  const [filterDataByIssueTracking, setFilterDataByIssueTracking] =
    useState<boolean>(false);

  useEffect(() => {
    setCfLabels(defaultData?.toolsDefinition?.changeFailure?.labels);
    setCfCustomField(
      defaultData?.toolsDefinition?.changeFailure?.customField?.value
    );
    setDeploymentCustomField(
      defaultData?.toolsDefinition?.deployment?.customField?.value
    );
    setCfProjects(
      defaultData?.toolsDefinition?.changeFailure?.projects?.map(
        (repo: any) => ({
          value: repo.name,
          label: repo.name,
        })
      )
    );
    setCfEpics(
      defaultData?.toolsDefinition?.changeFailure?.terms?.map((repo: any) => ({
        value: repo,
        label: repo,
      }))
    );
    setCfRepos(
      defaultData?.toolsDefinition?.changeFailure?.repos?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setCfServicesList(
      defaultData?.toolsDefinition?.changeFailure?.servicesList?.map(
        (repo: any) => ({
          value: repo.name,
          label: repo.name,
        })
      )
    );

    setDeploymentLabels(defaultData?.toolsDefinition?.deployment?.labels);
    setDeploymentPattern(defaultData?.toolsDefinition?.deployment?.pattern);
    setDeploymentProjects(
      defaultData?.toolsDefinition?.deployment?.projects?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setDeploymentRepos(
      defaultData?.toolsDefinition?.deployment?.repos?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setSelectedBoardNames(
      defaultData?.workspaces?.issueBoards?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setWorkspaceRepo(
      defaultData?.workspaces?.repository?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setQualityProjects(
      defaultData?.workspaces?.qualityProjects?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );

    setWorkspaceIssue(
      defaultData?.workspaces?.issueTracking?.map((repo: any) => ({
        value: repo.name,
        label: repo.name,
      }))
    );
  }, [defaultData]);

  useEffect(() => {
    if (workspaceRepo?.length === 0) setFilterDataByRepo(false);

    if (workspaceIssue?.length === 0) setFilterDataByIssueTracking(false);
  }, [workspaceRepo, workspaceIssue]);

  useEffect(() => {
    if (!defaultData) return;

    setParentTeam({
      value: defaultData?.parentTeam,
      label: defaultData?.parentTeam,
    });

    setDays(
      defaultData?.workTiming?.days || [
        false,
        true,
        true,
        true,
        true,
        true,
        false,
      ]
    );
    setTeamName(defaultData?.key?.name || '');
    setTeamDescription(defaultData?.description || '');
    setIssueDone(defaultData?.notification?.digest?.issueDone || false);
    setIssueInProgress(
      defaultData?.notification?.digest?.issueInProgress || false
    );
    setPrMerged(defaultData?.notification?.digest?.prMerged || false);
    setGoalExceptions(
      defaultData?.notification?.digest?.goalExceptions || false
    );
    setEmail(defaultData?.notification?.email || '');
    setSlackChannel(defaultData?.notification?.slack || '');

    setDigest(defaultData?.notification?.digest?.enable || false);
    setTz(
      defaultData?.workTiming?.timeZone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    setStartTime(defaultData?.workTiming?.startTime || '09:00');
    setEndTime(defaultData?.workTiming?.endTime || '18:00');
    setNotificationTime(defaultData?.notification?.time || '');

    setFilterDataByRepo(defaultData?.workspaces?.filterDataByRepo || false);
    setFilterDataByIssueTracking(
      defaultData?.workspaces?.filterDataByIssueTracking || false
    );
  }, [defaultData]);

  const handleSubmit = () => {
    const payload = {
      key: {
        orgId: localStorage.getItem('orgId'),
        name: teamName.trim(),
      },
      parentTeam: parentTeam.value,
      contacts: {
        slack: slackChannel,
        email: email,
      },
      owners: null,
      notification: {
        slack: slackChannel,
        email: email,
        time: notificationTime,
        digest: {
          enable: digest,
          issueDone: issueDone,
          bugsOpen: null,
          bugsDone: null,
          prMerged: prMerged,
          issueInProgress: issueInProgress,
          issueInQa: null,
          goalExceptions: goalExceptions,
        },
      },
      toolsDefinition: {
        changeFailure: {
          teams: '',
          customField: {
            value: cfCustomField,
          },
          labels: cfLabels,
          projects: cfProjects
            ? jiraRepo?.dto?.filter((item: any) =>
                cfProjects.some((el: any) => el.value === item.name)
              )
            : [],
          terms: cfEpics
            ? issueEpic?.dto?.filter((item: any) =>
                cfEpics.some((el: any) => el.value === item)
              )
            : [],
          servicesList: cfServicesList
            ? incidentManagement?.dto?.filter((item: any) =>
                cfServicesList.some((el: any) => el.value === item.name)
              )
            : [],
          repos: cfRepos
            ? gitRepo?.dto?.filter((item: any) =>
                cfRepos.some((el: any) => el.value === item.name)
              )
            : [],
        },
        deployment: {
          labels: deploymentLabels,
          pattern: deploymentPattern,
          projects: deploymentProjects
            ? jiraRepo?.dto?.filter((item: any) =>
                deploymentProjects.some((el: any) => el.value === item.name)
              )
            : [],
          repos: deploymentRepos
            ? gitRepo?.dto?.filter((item: any) =>
                deploymentRepos.some((el: any) => el.value === item.name)
              )
            : [],
          customField: {
            value: deploymentCustomField,
          },
        },
      },

      workspaces: {
        issueBoards: selectedBoardNames
          ? boardNames?.dto
              ?.filter((item: any) =>
                selectedBoardNames.some((el: any) => el.value === item.name)
              )
              ?.map((board: any) => ({
                name: board.name,
                key: board.id,
                url: null,
                project: board.key,
              }))
          : [],

        repository: workspaceRepo
          ? gitRepo?.dto?.filter((item: any) =>
              workspaceRepo.some((el: any) => el.value === item.name)
            )
          : [],

        issueTracking: workspaceIssue
          ? jiraRepo?.dto?.filter((item: any) =>
              workspaceIssue.some((el: any) => el.value === item.name)
            )
          : [],

        qualityProjects: qualityProjects
          ? qualityRepo?.dto?.filter((item: any) =>
              qualityProjects.some((el: any) => el.value === item.name)
            )
          : [],

        filterDataByRepo,
        filterDataByIssueTracking,
      },
      workTiming: {
        startTime: startTime,
        endTime: endTime,
        timeZone: tz,
        days: days,
      },
      description: teamDescription,
      contributors: 0,
      digestEnabled: digest,
    };

    handleAddOrUpdateTeam(payload, {
      onSuccess: () => {
        newToast({
          message: defaultData
            ? 'Team has been updated successfully'
            : 'Team has been added successfully',
          status: 'success',
        });
        setTimeout(() => {
          navigate('/settings/teams');
        }, 1000);
      },

      onError: (err) => {
        newToast({ message: 'Something went wrong', status: 'error' });
      },
    });
  };

  const formatOptionLabel = ({ value, label }: any) => {
    if (value === '') {
      return <span>&nbsp;</span>;
    }
    return label;
  };

  const maxCharacterLimit = 40;

  const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = eliminateSpecialCharacters(e.target.value);
    if (newValue.length <= maxCharacterLimit) {
      setTeamName(eliminateHtmlTags(newValue));
    }
  };

  return (
    <>
      <Stack w="full" spacing={2}>
        <PageHeader
          headingText={
            defaultData?.key?.name
              ? `Update ${defaultData.key.name} Team`
              : 'Create Team'
          }
        />

        <Box>
          <Breadcrumbs />
        </Box>
        {!defaultData && (
          <Text fontSize="sm" fontFamily="heading" fontWeight="semibold">
            {'New Team'}
          </Text>
        )}
        <Tabs>
          {defaultData && (
            <TabList>
              <Tab>
                <Text fontSize="sm" fontFamily="heading" fontWeight="semibold">
                  {'Details'}
                </Text>
              </Tab>
              <Tab>
                <Text fontSize="sm" fontFamily="heading" fontWeight="semibold">
                  {'Members'}
                </Text>
              </Tab>
            </TabList>
          )}

          <TabPanels>
            <TabPanel p={0}>
              <Stack
                // border="0.5px solid rgba(0, 0, 0, 0.1)"
                // borderRadius="4px"
                bg="white"
                py={defaultData ? 4 : 0}
                spacing={4}
              >
                <Flex>
                  <Stack w={'33%'} pr={5} spacing={4}>
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      fontFamily="heading"
                      color="text.primary"
                      lineHeight="17px"
                    >
                      1. Team Details
                    </Text>

                    <Stack>
                      <Flex align="center">
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Team name
                        </Text>
                        <BsAsterisk color={colors.text.secondary} size="8px" />
                      </Flex>
                      <Input
                        disabled={defaultData ? true : false}
                        placeholder="Team Name"
                        value={teamName}
                        onChange={(e) => {
                          handleTeamNameChange(e);
                        }}
                        isRequired
                      />
                      <Text fontSize="sm" color="gray.500">
                        {teamName.length}/{maxCharacterLimit}
                      </Text>
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Description (optional)
                      </Text>

                      <Textarea
                        placeholder="Team Description"
                        size="sm"
                        height={'100px'}
                        resize={'vertical'}
                        value={teamDescription}
                        onChange={(e) => {
                          setTeamDescription(eliminateHtmlTags(e.target.value));
                        }}
                      />
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Choose Parent Team
                      </Text>
                      <Box w="full">
                        <Select
                          useBasicStyles
                          placeholder="Choose Parent Team"
                          value={!parentTeam.value ? undefined : parentTeam}
                          onChange={(e: any) => {
                            setParentTeam({ value: e.value, label: e.value });
                          }}
                          formatOptionLabel={formatOptionLabel}
                          options={[{ value: '', label: '' }].concat(
                            parentTeams?.dto?.map((item: any) => ({
                              value: item.name,
                              label: item.name,
                            }))
                          )}
                        />
                      </Box>
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Time
                      </Text>
                      <Flex gap={4}>
                        <Box w="full">
                          <Select
                            placeholder="Start Time"
                            onChange={(e: any) => {
                              setStartTime(e.value);
                            }}
                            useBasicStyles
                            value={{
                              value: startTime ? startTime : 'Select',
                              label: startTime ? startTime : 'Select',
                            }}
                            options={timeRangeOptions.map((time: any) => {
                              return {
                                value: time,
                                label: time,
                              };
                            })}
                          />
                        </Box>
                        <Box w={'full'}>
                          <Select
                            useBasicStyles
                            placeholder="End Time"
                            onChange={(e: any) => {
                              setEndTime(e.value);
                            }}
                            value={{
                              value: endTime ? endTime : 'Select',
                              label: endTime ? endTime : 'Select',
                            }}
                            options={timeRangeOptions.map((time: any) => {
                              return {
                                value: time,
                                label: time,
                              };
                            })}
                          />
                        </Box>
                      </Flex>
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Time Zone
                      </Text>

                      <TimezoneSelect
                        value={tz}
                        onChange={(e) => {
                          setTz(e.value as any);
                        }}
                        styles={colorStyles}
                        defaultValue={tz}
                        labelStyle="abbrev"
                        timezones={{
                          ...allTimezones,
                          'America/Lima': 'Pittsburgh',
                          'Europe/Berlin': 'Frankfurt',
                          'Europe/Paris': 'Paris',
                          'Asia/Tokyo': 'Tokyo',
                        }}
                      />
                    </Stack>
                  </Stack>

                  <Divider orientation="vertical" width="0.5px" />

                  <Stack w={'33%'} spacing={4} pl={5}>
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      fontFamily="heading"
                      color="text.primary"
                      lineHeight="17px"
                    >
                      2. Workspaces
                    </Text>
                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Repositories
                      </Text>

                      <Select
                        placeholder="Select Repository"
                        isMulti={true}
                        menuPlacement="bottom"
                        useBasicStyles
                        onChange={(e: any) => {
                          setWorkspaceRepo(e);
                        }}
                        value={workspaceRepo}
                        options={gitRepo?.dto?.map((repo: any) => ({
                          label: repo.name,
                          value: repo.name,
                        }))}
                      />
                    </Stack>

                    <Stack>
                      <Checkbox
                        isChecked={filterDataByRepo}
                        onChange={(e) => {
                          if (workspaceRepo?.length === 0) {
                            return newToast({
                              message: 'Selecting Repository is mandatory.',
                              status: 'error',
                            });
                          }
                          setFilterDataByRepo(e.target.checked);
                        }}
                      >
                        Filter team git activity data by repo along with members
                      </Checkbox>
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Issue Projects
                      </Text>

                      <Select
                        placeholder="Select Projects"
                        isMulti={true}
                        useBasicStyles
                        menuPlacement="bottom"
                        onChange={(e: any) => {
                          setWorkspaceIssue(e);
                        }}
                        value={workspaceIssue}
                        options={jiraRepo?.dto?.map((repo: any) => {
                          return {
                            value: repo.name,
                            label: repo.name,
                          };
                        })}
                      />
                    </Stack>

                    <Stack>
                      <Checkbox
                        isChecked={filterDataByIssueTracking}
                        onChange={(e) => {
                          if (workspaceIssue?.length === 0) {
                            return newToast({
                              message: 'Selecting Issue project is mandatory.',
                              status: 'error',
                            });
                          }

                          setFilterDataByIssueTracking(e.target.checked);
                        }}
                      >
                        Filter team issue activity data by project along with
                        members
                      </Checkbox>
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Quality Projects
                      </Text>

                      <Select
                        placeholder="Select Project"
                        isMulti={true}
                        menuPlacement="top"
                        useBasicStyles
                        onChange={(e: any) => {
                          setQualityProjects(e);
                        }}
                        value={qualityProjects}
                        options={qualityRepo?.dto?.map((repo: any) => ({
                          label: repo.name,
                          value: repo.name,
                        }))}
                      />
                    </Stack>
                  </Stack>

                  <Divider orientation="vertical" width="0.5px" />

                  <Stack w={'33%'} px={5} spacing={4}>
                    <Flex align={'center'}>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        fontFamily="heading"
                        color="text.primary"
                        lineHeight="17px"
                      >
                        3. Select DORA Tracking Field Value
                      </Text>
                    </Flex>

                    <Text
                      fontFamily="heading"
                      fontSize="md"
                      fontWeight="medium"
                      color="text.primary"
                    >
                      Change Failure Filter
                    </Text>
                    {doraSettings?.dto?.cf_team_tracking_type ===
                    FieldType.label ? (
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Change Failure Label
                        </Text>

                        <Input
                          width={'100%'}
                          placeholder="Add comma separated label"
                          value={cfLabels}
                          onChange={(e) => {
                            setCfLabels(eliminateHtmlTags(e.target.value));
                          }}
                        />
                      </Stack>
                    ) : doraSettings?.dto?.cf_team_tracking_type ===
                      FieldType.project ? (
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Issue Projects
                        </Text>

                        <Select
                          placeholder="Select Projects"
                          isMulti={true}
                          useBasicStyles
                          onChange={(e: any) => {
                            setCfProjects(e);
                          }}
                          value={cfProjects}
                          options={jiraRepo?.dto?.map((repo: any) => {
                            return {
                              value: repo.name,
                              label: repo.name,
                            };
                          })}
                        />
                      </Stack>
                    ) : doraSettings?.dto?.cf_team_tracking_type ===
                      FieldType.epic ? (
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Issue Epic's
                        </Text>

                        <Select
                          placeholder="Select Epic's"
                          isMulti={true}
                          useBasicStyles
                          onChange={(e: any) => {
                            setCfEpics(e);
                          }}
                          value={cfEpics}
                          options={issueEpic?.dto?.map((issue: any) => {
                            return {
                              value: issue,
                              label: issue,
                            };
                          })}
                        />
                      </Stack>
                    ) : doraSettings?.dto?.cf_team_tracking_type ===
                      FieldType.service ? (
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Incident Service
                        </Text>

                        <Select
                          placeholder="Select Incidents service"
                          isMulti={true}
                          useBasicStyles
                          onChange={(e: any) => {
                            setCfServicesList(e);
                          }}
                          value={cfServicesList}
                          options={incidentManagement?.dto?.map((repo: any) => {
                            return {
                              value: repo.name,
                              label: repo.name,
                            };
                          })}
                        />
                      </Stack>
                    ) : doraSettings?.dto?.cf_team_tracking_type ===
                      FieldType.repo ? (
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          GIT Repositories
                        </Text>

                        <Select
                          placeholder="Select Github Repo"
                          isMulti={true}
                          useBasicStyles
                          onChange={(e: any) => {
                            setCfRepos(e);
                          }}
                          value={cfRepos}
                          options={gitRepo?.dto?.map((repo: any) => ({
                            label: repo.name,
                            value: repo.name,
                          }))}
                        />
                      </Stack>
                    ) : doraSettings?.dto?.cf_team_tracking_type ===
                      FieldType.custom ? (
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          {doraSettings?.dto?.cf_team_tracking_field}
                        </Text>

                        <Input
                          width={'100%'}
                          placeholder={
                            doraSettings?.dto?.cf_team_tracking_field
                          }
                          value={cfCustomField}
                          onChange={(e) => {
                            setCfCustomField(eliminateHtmlTags(e.target.value));
                          }}
                        />
                      </Stack>
                    ) : (
                      <></>
                    )}

                    <Divider />
                    <>
                      <Text
                        fontFamily="heading"
                        fontSize="md"
                        fontWeight="medium"
                        color="text.primary"
                      >
                        Deployment Filter
                      </Text>
                      {doraSettings?.dto?.deployment_team_tracking_type ===
                      FieldType.label ? (
                        <Stack>
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            fontWeight="semibold"
                            color="text.secondary"
                            lineHeight="17px"
                          >
                            Deployment Labels
                          </Text>

                          <Input
                            width={'100%'}
                            placeholder="Add comma separated label"
                            value={deploymentLabels}
                            onChange={(e) => {
                              setDeploymentLabels(
                                eliminateHtmlTags(e.target.value)
                              );
                            }}
                          />
                        </Stack>
                      ) : doraSettings?.dto?.deployment_team_tracking_type ===
                        FieldType.project ? (
                        <Stack>
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            fontWeight="semibold"
                            color="text.secondary"
                            lineHeight="17px"
                          >
                            Issue Project
                          </Text>

                          <Select
                            placeholder="Select Projects"
                            isMulti={true}
                            useBasicStyles
                            onChange={(e: any) => {
                              setDeploymentProjects(e);
                            }}
                            value={deploymentProjects}
                            options={jiraRepo?.dto?.map((repo: any) => {
                              return {
                                value: repo.name,
                                label: repo.name,
                              };
                            })}
                          />
                        </Stack>
                      ) : doraSettings?.dto?.deployment_team_tracking_type ===
                        FieldType.repo ? (
                        <Stack>
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            fontWeight="semibold"
                            color="text.secondary"
                            lineHeight="17px"
                          >
                            GIT Repositories
                          </Text>

                          <Select
                            placeholder="Select Github Repo"
                            isMulti={true}
                            useBasicStyles
                            onChange={(e: any) => {
                              setDeploymentRepos(e);
                            }}
                            value={deploymentRepos}
                            options={gitRepo?.dto?.map((repo: any) => ({
                              label: repo.name,
                              value: repo.name,
                            }))}
                          />
                        </Stack>
                      ) : doraSettings?.dto?.deployment_team_tracking_type ===
                        FieldType.namePattern ? (
                        <Stack>
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            fontWeight="semibold"
                            color="text.secondary"
                            lineHeight="17px"
                          >
                            Job Name Pattern
                          </Text>

                          <Input
                            width={'100%'}
                            placeholder="Enter job names/patterns using '*' as a wildcard, separated by commas."
                            _placeholder={{
                              fontSize: 'xxs',
                            }}
                            value={deploymentPattern}
                            onChange={(e) => {
                              setDeploymentPattern(
                                eliminateHtmlTags(e.target.value)
                              );
                            }}
                          />
                        </Stack>
                      ) : doraSettings?.dto?.deployment_team_tracking_type ===
                        FieldType.custom ? (
                        <Stack>
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            fontWeight="semibold"
                            color="text.secondary"
                            lineHeight="17px"
                          >
                            {doraSettings?.dto?.deployment_team_tracking_field}
                          </Text>

                          <Input
                            width={'100%'}
                            placeholder={
                              doraSettings?.dto?.deployment_team_tracking_field
                            }
                            value={deploymentCustomField}
                            onChange={(e) => {
                              setDeploymentCustomField(
                                eliminateHtmlTags(e.target.value)
                              );
                            }}
                          />
                        </Stack>
                      ) : (
                        <></>
                      )}
                    </>

                    {/* <Divider orientation="horizontal" />

                    <Stack w={'100%'} spacing={4}>
                      <Flex align={'center'}>
                        <Text
                          fontSize="sm"
                          fontWeight="semibold"
                          fontFamily="heading"
                          color="text.primary"
                          lineHeight="17px"
                        >
                          4. Sprint Tracking
                        </Text>
                      </Flex>
                      <Text
                        fontFamily="heading"
                        fontSize="md"
                        fontWeight="medium"
                        color="text.primary"
                      >
                        Sprint Detection
                      </Text>
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Issue Management Board Names
                        </Text>

                        <Select
                          placeholder="Select Boards"
                          isMulti={true}
                          menuPlacement="top"
                          useBasicStyles
                          onChange={(e: any) => {
                            setSelectedBoardNames(e);
                          }}
                          value={selectedBoardNames}
                          options={boardNames?.dto?.map((repo: any) => {
                            return {
                              value: repo.name,
                              label: repo.name,
                            };
                          })}
                        />
                      </Stack>
                    </Stack> */}
                    <Divider />
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      fontFamily="heading"
                      color="text.primary"
                      lineHeight="17px"
                    >
                      4. Notifications
                    </Text>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Slack or MS Teams Channel Name
                      </Text>
                      <Flex align="center">
                        <Icon
                          as={BiInfoCircle}
                          color={colors.text.secondary}
                          ml={2}
                        />
                        <Text
                          fontSize="xs"
                          color="text.secondary"
                          fontFamily="heading"
                        >
                          Add DevDynamics App in this channel to get digest
                        </Text>
                      </Flex>
                      <Input
                        width={'100%'}
                        placeholder="Slack or MS Teams Channel"
                        value={slackChannel}
                        onChange={(e) => {
                          setSlackChannel(eliminateHtmlTags(e.target.value));
                        }}
                      />
                    </Stack>

                    <FormControl isInvalid={!isValidEmail(email)}>
                      <Stack>
                        <Text
                          fontSize="sm"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          lineHeight="17px"
                        >
                          Email Id
                        </Text>
                        <Input
                          placeholder="Email Id"
                          value={email}
                          onChange={(e) => {
                            const sanitizedEmail = eliminateHtmlTags(
                              e.target.value
                            );
                            setEmail(sanitizedEmail);
                          }}
                        />
                        <FormErrorMessage>
                          {isValidEmail(email) ? null : 'Invalid Email format'}
                        </FormErrorMessage>
                      </Stack>
                    </FormControl>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Daily Digest
                      </Text>
                      <Switch
                        onChange={(e) => {
                          setDigest(e?.target?.checked);

                          if (e?.target?.checked) {
                            setNotificationTime(
                              defaultData?.notification?.time || '09:00'
                            );
                            if (!defaultData?.notification?.digest?.enable) {
                              setIssueDone(true);
                              setPrMerged(true);
                              setIssueInProgress(true);
                              setGoalExceptions(true);
                            } else {
                              setIssueDone(
                                defaultData?.notification?.digest?.issueDone
                              );
                              setPrMerged(
                                defaultData?.notification?.digest?.prMerged
                              );
                              setIssueInProgress(
                                defaultData?.notification?.digest
                                  ?.issueInProgress
                              );
                              setGoalExceptions(
                                defaultData?.notification?.digest
                                  ?.goalExceptions
                              );
                            }
                          } else {
                            setNotificationTime('');
                            setIssueDone(false);
                            setPrMerged(false);
                            setIssueInProgress(false);
                            setGoalExceptions(false);
                          }
                        }}
                        isChecked={digest}
                        size="md"
                      />
                    </Stack>

                    <Stack>
                      <Text
                        fontFamily="heading"
                        color="text.primary"
                        fontWeight="semibold"
                        fontSize="xs"
                        lineHeight="17px"
                      >
                        Things to include in the daily digest
                      </Text>

                      <Stack>
                        <Checkbox
                          isChecked={issueDone}
                          onChange={(e) => {
                            setIssueDone(e.target.checked);
                          }}
                          value="issueDone"
                          isDisabled={!digest}
                        >
                          <Text
                            fontSize="xs"
                            fontFamily="heading"
                            fontWeight="normal"
                            lineHeight="15px"
                            color="text.secondary"
                          >
                            Issue Done
                          </Text>
                        </Checkbox>
                        <Checkbox
                          isChecked={prMerged}
                          onChange={(e) => {
                            setPrMerged(e.target.checked);
                          }}
                          value="prMerged"
                          isDisabled={!digest}
                        >
                          <Text
                            fontSize="xs"
                            fontFamily="heading"
                            fontWeight="normal"
                            lineHeight="15px"
                            color="text.secondary"
                          >
                            PR Merged
                          </Text>
                        </Checkbox>
                        <Checkbox
                          isChecked={issueInProgress}
                          onChange={(e) => {
                            setIssueInProgress(e.target.checked);
                          }}
                          value="issueInProgress"
                          isDisabled={!digest}
                        >
                          <Text
                            fontSize="xs"
                            fontFamily="heading"
                            fontWeight="normal"
                            lineHeight="15px"
                            color="text.secondary"
                          >
                            Issue in progress
                          </Text>
                        </Checkbox>
                        <Checkbox
                          isChecked={goalExceptions}
                          onChange={(e) => {
                            setGoalExceptions(e.target.checked);
                          }}
                          value="goalExceptions"
                          isDisabled={!digest}
                        >
                          <Text
                            fontSize="xs"
                            fontFamily="heading"
                            fontWeight="normal"
                            lineHeight="15px"
                            color="text.secondary"
                          >
                            Working Agreement Exceptions
                          </Text>
                        </Checkbox>
                      </Stack>
                    </Stack>

                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Time
                      </Text>
                      <Box w="50%">
                        <Select
                          placeholder="Start Time"
                          onChange={(e: any) => {
                            setNotificationTime(e.value);
                          }}
                          useBasicStyles
                          value={{
                            value: notificationTime
                              ? notificationTime
                              : 'Select',
                            label: notificationTime
                              ? notificationTime
                              : 'Select',
                          }}
                          formatOptionLabel={formatOptionLabel}
                          options={[{ value: '', label: '' }].concat(
                            DigestTimeRangeOptions.map((time: any) => ({
                              value: time,
                              label: time,
                            }))
                          )}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Flex>

                <Divider />

                <Flex alignSelf="flex-end">
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      navigate('/settings/teams');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    ml={4}
                    variant="tertiary"
                    isDisabled={teamName === '' || !isValidEmail(email)}
                    onClick={() => {
                      if (digest && email === '' && slackChannel === '') {
                        newToast({
                          message:
                            'Include at least one item: a Slack channel or an Email ID',
                          status: 'error',
                        });
                      } else {
                        handleSubmit();
                      }
                    }}
                  >
                    {defaultData ? 'Update team' : 'Create team'}
                  </Button>
                </Flex>
              </Stack>
            </TabPanel>
            <TabPanel px={0}>
              <TeamMembers team={defaultData?.key?.name} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>

      {(handleAddOrUpdateTeamLoading ||
        teamDetailsLoading ||
        parentTeamLoading) && <Loader />}
    </>
  );
};
