import { useRef, useEffect, HTMLProps } from 'react';
import { Box, useCheckbox } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [indeterminate, rest.checked]);

  const { getInputProps, getCheckboxProps } = useCheckbox({
    isIndeterminate: indeterminate,
    isChecked: rest.checked,
    isDisabled: rest.disabled,
  });

  return (
    <Box as="label" display="inline-flex" alignItems="center" cursor="pointer">
      <input
        type="checkbox"
        ref={ref}
        {...getInputProps({ ...rest })}
        style={{ display: 'none' }}
      />
      <Box
        as="span"
        {...getCheckboxProps()}
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        borderWidth="1px"
        borderRadius="sm"
        boxSize="20px"
        bg="white"
        borderColor="gray.300"
        _checked={{
          bg: 'primary',
          borderColor: 'primary',
          color: 'white',
        }}
        _indeterminate={{
          bg: 'primary',
          borderColor: 'primary',
        }}
        _disabled={{
          bg: 'gray.100',
          borderColor: 'gray.200',
        }}
      >
        {rest.checked && <CheckIcon boxSize={3} />}
        {ref.current?.indeterminate && (
          <Box
            as="span"
            width="10px"
            height="2px"
            bg="white"
            borderRadius="sm"
          />
        )}
      </Box>
    </Box>
  );
}

export default IndeterminateCheckbox;
