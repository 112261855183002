export const TickIcon2 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M10.7494 1.02432L3.58741 7.9314L1.68688 5.90226C1.33678 5.5724 0.786626 5.55241 0.386514 5.83229C-0.00359513 6.12217 -0.113626 6.63195 0.126441 7.04178L2.37707 10.7002C2.59713 11.0401 2.97724 11.25 3.40736 11.25C3.81747 11.25 4.20758 11.0401 4.42764 10.7002C4.78774 10.2304 11.6597 2.04389 11.6597 2.04389C12.5599 1.12428 11.4696 0.314621 10.7494 1.01433V1.02432Z"
        fill={props?.color || '#0095E6'}
      />
    </svg>
  );
};

export default TickIcon2;
