export const DetailViewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.5 13.5C9.05 13.5 9.521 13.304 9.913 12.912C10.3043 12.5207 10.5 12.05 10.5 11.5C10.5 10.95 10.3043 10.479 9.913 10.087C9.521 9.69567 9.05 9.5 8.5 9.5C7.95 9.5 7.47933 9.69567 7.088 10.087C6.696 10.479 6.5 10.95 6.5 11.5C6.5 12.05 6.696 12.5207 7.088 12.912C7.47933 13.304 7.95 13.5 8.5 13.5ZM14.1 18.5L10.525 14.925C10.2417 15.1083 9.92933 15.25 9.588 15.35C9.246 15.45 8.88333 15.5 8.5 15.5C7.4 15.5 6.45833 15.1083 5.675 14.325C4.89167 13.5417 4.5 12.6 4.5 11.5C4.5 10.4 4.89167 9.45833 5.675 8.675C6.45833 7.89167 7.4 7.5 8.5 7.5C9.6 7.5 10.5417 7.89167 11.325 8.675C12.1083 9.45833 12.5 10.4 12.5 11.5C12.5 11.8833 12.45 12.2417 12.35 12.575C12.25 12.9083 12.1083 13.2167 11.925 13.5L14.5 16.075V7.225L10.55 2.5H2.5V18.5H14.1ZM2.5 20.5C1.95 20.5 1.47933 20.3043 1.088 19.913C0.696 19.521 0.5 19.05 0.5 18.5V2.5C0.5 1.95 0.696 1.479 1.088 1.087C1.47933 0.695667 1.95 0.5 2.5 0.5H11.5L16.5 6.5V18.5C16.5 19.05 16.3043 19.521 15.913 19.913C15.521 20.3043 15.05 20.5 14.5 20.5H2.5Z"
        fill={props.color || '#777'}
      />
    </svg>
  );
};
