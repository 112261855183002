export function Story(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1Z"
        fill="#63BA3C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 4H6C5.448 4 5 4.448 5 5V11.5C5 11.776 5.224 12 5.5 12C5.675 12 5.821 11.905 5.91 11.769L5.914 11.77L7.84 9.54C7.92 9.434 8.08 9.434 8.16 9.54L10.086 11.77L10.09 11.769C10.179 11.905 10.325 12 10.5 12C10.776 12 11 11.776 11 11.5V5C11 4.448 10.552 4 10 4Z"
        fill="white"
      />
    </svg>
  );
}

export default Story;
