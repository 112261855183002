import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getInitiativesGraphql = async (
  status: string,
  pageNumber: number,
  pageSize: number,
  search: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInitiatives(
        $orgId: String!
        $status: String!
        $pageNumber: Int!
        $pageSize: Int!
        $search: String!
      ) {
        Initiatives(
          orgId: $orgId
          status: $status
          pageNumber: $pageNumber
          pageSize: $pageSize
          search: $search
        ) {
          initiativeList {
            name
            id
            desc
            link
            status
            legend {
              color
              label
            }
            plannedDate
            estimatedDate
            progress
            totalTasks
            completedTasks
            startDate
            contributionTracking
            issueFilterConditions {
              conditions {
                field
                operator
                value
              }
            }
            issueDefinition {
              issueTypes
              labels
              projects
              issues
              customField {
                name
                value
              }
              dateRange {
                startDate
                endDate
              }
            }
          }
          totalPages
          totalElements
          currentPage
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      status,
      pageNumber,
      pageSize,
      search,
    }
  );

export const getInitiativeDetailGraphql = async (
  initiative: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInitiativeDetails(
        $orgId: String!
        $initiative: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        InitiativeDetails(
          orgId: $orgId
          initiative: $initiative
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          totalTasks
          totalTasksOpen
          totalTasksInProgress
          totalTasksClosed
          totalBugs
          totalBugsFixed
          epicDetails {
            id
            progress
            ticketType
            description
            url
            startDate
            endDate
            dependencies
            totalTasks
            totalTasksOpen
            totalTasksInProgress
            totalTasksClosed
            totalBugs
            totalBugsFixed
            displayOrder
            project
            type
            completedTaskCost
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      initiative,
      pageNumber,
      pageSize,
    }
  );

export const getInitiativeTaskGraphql = async (
  initiative: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getTaskListDetails(
        $orgId: String!
        $initiative: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        AllTaskForInitiative(
          orgId: $orgId
          initiative: $initiative
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          totalPages
          currentPage
          data {
            summary
            assignee
            ticketType
            ticketUrl
            updatedDate
            status
            key
            investment
            project
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      initiative,
      pageNumber,
      pageSize,
    }
  );

export const getInitiativeChangeLogGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getTicketUpdateLog($initiative: String!) {
        TicketUpdateLog(initiative: $initiative) {
          status
          assignee
          expectedDoneDate
          timeSpent
          updatedAt
        }
      }
    `,
    {
      initiative,
    }
  );

export const getInitiativeContributorsGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorsForInitiative(
        $initiative: String!
        $orgId: String!
      ) {
        ContributorsForInitiative(initiative: $initiative, orgId: $orgId) {
          initiative
          contributorActivities {
            contributorAccount
            issueCount
            team
          }
        }
      }
    `,
    {
      initiative,
      orgId: localStorage.getItem('orgId'),
    }
  );

export const getInitiativeSprintGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintsForInitiative($initiative: String!, $orgId: String!) {
        SprintsForInitiative(initiative: $initiative, orgId: $orgId) {
          initiative
          sprints {
            sprintName
            sprintId
            project
            startDate
            endDate
            unplannedTasks
            commitedTasks
            completedTasks
            commitedSP
            completedSP
          }
        }
      }
    `,
    {
      initiative,
      orgId: localStorage.getItem('orgId'),
    }
  );

export const getInitiativeTeamsGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamsForInitiative($initiative: String!, $orgId: String!) {
        TeamsForInitiative(initiative: $initiative, orgId: $orgId) {
          initiative
          teams {
            name
            allocation
            contributors
          }
        }
      }
    `,
    {
      initiative,
      orgId: localStorage.getItem('orgId'),
    }
  );

export const teamAllocationGraphql = async (
  initiative: string,
  team: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamAllocationDetails(
        $initiative: String!
        $orgId: String!
        $team: String!
      ) {
        TeamAllocationDetails(
          initiative: $initiative
          orgId: $orgId
          team: $team
        ) {
          team
          email
          allocation
        }
      }
    `,
    {
      initiative,
      orgId: `${localStorage.getItem('orgId')}`,
      team,
    }
  );

export const initiativeProgressGraphql = async (
  initiativeId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInitiativeTaskProgress($orgId: String, $initiativeId: String) {
        InitiativeTaskProgress(orgId: $orgId, initiativeId: $initiativeId) {
          taskCycleTime
          membersInvolved
          teamsInvolved
          data {
            date
            open
            closed
            inProgress
          }
        }
      }
    `,
    {
      initiativeId,
      orgId: `${localStorage.getItem('orgId')}`,
    }
  );

export const initiativeTaskDistGraphql = async (
  initiativeId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GitInitiativeTaskStatusDistribution(
        $orgId: String
        $initiativeId: String
      ) {
        InitiativeTaskStatusDistribution(
          orgId: $orgId
          initiativeId: $initiativeId
        ) {
          data {
            name
            value
          }
        }
      }
    `,
    {
      initiativeId,
      orgId: `${localStorage.getItem('orgId')}`,
    }
  );
