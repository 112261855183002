import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Link,
  Skeleton,
  Text,
  VStack,
  useTheme,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';
import { useFetchTimeline } from './api';
import moment from 'moment';
import MilestoneItemSkeleton from './components/MilestoneItemSkeleton';
import MilestoneItem from './components/MilestoneItem';
import {
  convertToAllTitleCase,
  getProperIcon,
  getValueByKey,
} from './helpers/deploymentDetails.utils';
import { TbPointFilled } from 'react-icons/tb';
import { useEffect } from 'react';
import { useToastHook } from '../appToast';

export const DeploymentDetails = () => {
  const [newToast] = useToastHook();
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const apiParam = pathname.split('/')[2];

  const { data, isLoading, isError, error } = useFetchTimeline(
    pathname.split('/').pop() as string,
    apiParam
  );

  useEffect(() => {
    if (isError) {
      newToast({ message: (error as any)?.message, status: 'error' });
    }
  }, [isError]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      minHeight={'100%'}
    >
      <Flex align="center" justify="space-between">
        <Button
          onClick={() => {
            if (window.history.length > 2) {
              navigate(-1);
            } else {
              navigate('/dora');
            }
          }}
          size="sm"
          variant="link"
        >
          Back
        </Button>
      </Flex>
      <Heading fontSize="xl" fontWeight="600" my={5}>
        {apiParam === 'cfr'
          ? 'Change Failure'
          : apiParam === 'build'
          ? 'Deployment Details'
          : ''}
      </Heading>
      {data?.dto?.buildDetails && (
        <>
          <Box mb={4}>
            <Flex align="center" flexWrap="wrap" gap={3}>
              <Flex align="center">
                <Text
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="gray.600"
                  mr={1}
                >
                  Repository
                </Text>{' '}
                :{' '}
                <Text
                  ml={1}
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="medium"
                  color="text.secondary"
                >
                  {data?.dto?.buildDetails?.repo}
                </Text>
              </Flex>

              <Flex align="center">
                <Text
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="semibold"
                  color="gray.600"
                  mr={1}
                >
                  Created At
                </Text>{' '}
                :{' '}
                <Text
                  ml={1}
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="medium"
                  color="text.secondary"
                >
                  {moment(data?.dto?.buildDetails?.createdAt).format('ll')}
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Divider orientation="horizontal" />
        </>
      )}

      <VStack textAlign="start" align="start" mb={5}>
        <Box zIndex={5}>
          <Heading fontSize="xl" fontWeight="600" my={5}>
            TimeLine
          </Heading>
          <Box pb={6}>
            {isLoading &&
              Array.from(Array(6).keys()).map(
                (_: any, idx: number, arr: any) => (
                  <MilestoneItemSkeleton
                    key={idx}
                    skipTrail={idx === arr.length - 1}
                  >
                    <Skeleton height="98px" width="800px" />
                  </MilestoneItemSkeleton>
                )
              )}

            {data?.dto?.events?.length === 0 && (
              <Text fontFamily="heading" fontSize="md" color="text.primary">
                No Timeline Available.
              </Text>
            )}

            {data?.dto?.events.map((event: any, idx: number, arr: any) => (
              <MilestoneItem
                icon={getProperIcon(event?.type)}
                skipTrail={idx === arr.length - 1}
              >
                <Box
                  position="relative"
                  bg="gray.100"
                  width="800px"
                  p={2}
                  borderRadius={'4px'}
                >
                  <Box
                    position="absolute"
                    width="0"
                    height="0"
                    border="10px solid transparent"
                    borderRight="10px solid #EdF2F7"
                    top={1}
                    left={-4}
                  />
                  {event.url ? (
                    <Flex align="center" flexWrap="wrap" gap={3}>
                      <Text
                        fontSize="md"
                        fontFamily="heading"
                        fontWeight="medium"
                      >
                        {getValueByKey(event?.type)}
                      </Text>
                      <Link
                        href={event.url}
                        _hover={{ color: colors.primary }}
                        color={colors.primary}
                        isExternal
                        fontSize="sm"
                        fontWeight="medium"
                        textDecoration={'underline'}
                      >
                        {event.id}
                      </Link>
                    </Flex>
                  ) : (
                    <Flex direction="column">
                      <Flex align="center">
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          fontWeight="medium"
                          color="text.primary"
                          mr={4}
                        >
                          {getValueByKey(event?.type)}
                        </Text>
                        <Text
                          fontSize="sm"
                          color="text.primary"
                          fontWeight="medium"
                        >
                          {event.id}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                  <Text fontSize="11px" color="gray.600" fontFamily="heading">
                    {moment(event.time).format('lll')}
                  </Text>

                  <HStack align="flex-start" spacing={1} ml={-1}>
                    <Box>
                      <TbPointFilled
                        height="20px"
                        width="20px"
                        color={colors.text.tertiary}
                      />
                    </Box>

                    <Text
                      fontFamily="heading"
                      color="text.primary"
                      fontSize="sm"
                      fontWeight="normal"
                    >
                      {event.description}
                    </Text>
                  </HStack>

                  <Flex align="center" gap={2} flexWrap={'wrap'}>
                    {event?.metadata &&
                      Object.entries(event?.metadata).map(
                        ([key, value]: any, idx: number) =>
                          value && (
                            <Flex mr={3} key={`att-${idx}`} align="flex-start">
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="normal"
                                color="gray.600"
                                whiteSpace={'nowrap'}
                                mr={1}
                              >
                                {convertToAllTitleCase(key)}
                              </Text>{' '}
                              <Text mt={'-2px'}>:</Text>
                              <Text
                                ml={1}
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="normal"
                                color="text.secondary"
                                wordBreak={'break-all'}
                              >
                                {value}
                              </Text>
                            </Flex>
                          )
                      )}
                  </Flex>
                </Box>
              </MilestoneItem>
            ))}
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default DeploymentDetails;
