import React from 'react';
import ContributorsTable from './ContributorsTable';
import ContributorsToolbar from './ContributorsToolbar';

interface ContributorsProps {
  selectedProjectId: string;
}

const Contributors: React.FC<ContributorsProps> = ({ selectedProjectId }) => {
  return (
    <>
      <ContributorsToolbar />
      <ContributorsTable selectedProjectId={selectedProjectId} />
    </>
  );
};

export default Contributors;
