import { Flex, Text } from '@chakra-ui/react';

type Props = {
  pairKey: string;
  value?: string;
  mr?: string | number;
  content?: React.ReactNode;
};

/**
 * @prop {string} pairKey (key)
 * @prop {React.ReactNode} content (content is JSX element optional)
 * @prop {string | number} mr (margin right optional)
 * @prop {string} value (value optional)
 */

const KeyValueLine = ({ pairKey, value, mr, content }: Props) => {
  return (
    <Flex mr={mr || 3} align="flex-start">
      <Text
        fontFamily="heading"
        fontSize="sm"
        fontWeight="semibold"
        color="gray.600"
        whiteSpace={'nowrap'}
        mr={1}
      >
        {pairKey}
      </Text>{' '}
      <Text mt={'-3px'}>:</Text>
      {content ? (
        content
      ) : (
        <Text
          ml={1}
          fontFamily="heading"
          fontSize="sm"
          fontWeight="medium"
          color="text.secondary"
          wordBreak={'break-all'}
        >
          {value}
        </Text>
      )}
    </Flex>
  );
};

export default KeyValueLine;
