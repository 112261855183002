import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { PeriodType } from '../shared/typings';
import { ISelect } from '../customSelect';

interface DashboardState {
  selectedTeam: ISelect;
  setSelectedTeam: (selectedTeam: ISelect) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  filterTitle: string;
  setFilterTitle: (title: string) => void;
  active: string;
  setActive: (active: string) => void;
}

interface DetailsPageState {
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  filterTitle: string;
  setFilterTitle: (title: string) => void;
  active: string;
  setActive: (active: string) => void;
}

export const useDashboardStore = create<DashboardState>()(
  persist(
    (set) => ({
      selectedTeam: {
        value: localStorage.getItem('team') || '',
        label: localStorage.getItem('team') || 'Org',
      },
      selected: {
        id: 0,
        startDate: '',
        endDate: '',
        duration: '',
        sprintId: '',
      },
      filterTitle: '',
      setSelected: (selected: PeriodType) => set({ selected }),
      setSelectedTeam: (selectedTeam: ISelect) => set({ selectedTeam }),
      setFilterTitle: (filterTitle: string) => set({ filterTitle }),
      active: '',
      setActive: (active: string) => set({ active }),
    }),
    {
      name: 'dashboard-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useDetailsPageStore = create<DetailsPageState>()(
  persist(
    (set) => ({
      selected: {
        id: 0,
        startDate: '',
        endDate: '',
        duration: '',
        sprintId: '',
      },
      filterTitle: '',
      setSelected: (selected: PeriodType) => set({ selected }),
      setFilterTitle: (filterTitle: string) => set({ filterTitle }),
      active: '',
      setActive: (active: string) => set({ active }),
    }),
    {
      name: 'detailsPage-store',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
