import { useMetricsTableStore } from '@devd-client/devd/components';
import { useState } from 'react';
import useMetricTableHelpers from './useMetricTableHelpers';

// Hook responsible for all status related changes.
export function useHandleStatusChange({
  setStatus,
}: {
  setStatus: (value: string) => void;
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const { handleMetricFilterBy } = useMetricTableHelpers();

  const handlePRStatusChange = (index: number) => {
    setTabIndex(index);
    setStatus(
      index === 0
        ? 'all'
        : index === 1
        ? 'open'
        : index === 2
        ? 'review'
        : 'merged'
    );
    handleMetricFilterBy(
      'status',
      '',
      index === 0
        ? 'all'
        : index === 1
        ? 'open'
        : index === 2
        ? 'review'
        : 'merged',
      null
    );
  };

  const handleIssueStatusChange = (index: number) => {
    setTabIndex(index);
    setStatus(
      index === 0
        ? 'all'
        : index === 1
        ? 'todo'
        : index === 2
        ? 'inprogress'
        : 'done'
    );
    handleMetricFilterBy(
      'issueStatusType',
      '',
      index === 0
        ? 'all'
        : index === 1
        ? 'todo'
        : index === 2
        ? 'inprogress'
        : 'done',
      null
    );
  };

  return {
    handlePRStatusChange,
    handleIssueStatusChange,
    tabIndex,
    setTabIndex,
  };
}
export default useHandleStatusChange;
