import { useEffect } from 'react';
import { useLocation } from 'react-router';

export function usePageViews(): void {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    window.analytics.page(`${window.document.title}`);
  }, [window.document.title]);
}
