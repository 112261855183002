export function TeamCompareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M12.7402 2.20508L14.8474 4.31222L12.7402 6.41936"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.58008 4.31152H14.8479"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7402 9.58008L14.8474 11.6872L12.7402 13.7944"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.15137 4.31152H4.83887L9.05315 11.6865H14.8478"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.15137 11.6865H4.83887"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default TeamCompareIcon;
