export const Goals = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 17V0H9L9.4 2H15V12H8L7.6 10H2V17H0ZM9.65 10H13V4H7.75L7.35 2H2V8H9.25L9.65 10Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};

export default Goals;
