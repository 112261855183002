import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  teamDetails: 'teamDetails',
  goals: 'goals',
  teamFavMetrics: 'teamFavMetrics',
  favTeam: 'favTeam',
  teamsPage: 'teamsPage',
  teamPage: 'teamPage',
  metricListHeatmap: 'metricListHeatmap',
};

export const useTeamDetails = (
  team: string,
  setMemberCount: (member: number) => void
) => {
  return useQuery(
    [QUERY_KEYS.teamDetails, team],
    () => ENDPOINTS.getTeamDetails(team),
    {
      onSuccess: (data) => setMemberCount(data?.dto?.memberCount),
    }
  );
};

export const useGetGoals = (team: string) => {
  return useQuery([QUERY_KEYS.goals, team], () => ENDPOINTS.getAllGoals(team));
};

export const useGoalSettingsMutation = () => {
  return useCustomMutation([QUERY_KEYS.goals], ENDPOINTS.updateGoalSettings);
};

export const useTeamFavMetrics = (team: string) => {
  return useQuery(
    [QUERY_KEYS.teamFavMetrics, team],
    () => ENDPOINTS.getAllTeamMetrics(team),
    {
      enabled: !!team,
    }
  );
};

export const useFetchTeamsPage = () => {
  return useQuery([QUERY_KEYS.teamsPage], ENDPOINTS.getTeamsPage);
};

export const useFetchTeamPage = () => {
  return useQuery([QUERY_KEYS.teamPage], ENDPOINTS.getTeamPage);
};

export const useFetchMetricList = () => {
  return useQuery([QUERY_KEYS.metricListHeatmap], ENDPOINTS.getMetricsList);
};
