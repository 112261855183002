import { Fragment, useEffect } from 'react';
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import {
  CircleIcon,
  CustomMetricFilter,
  DownArrowIcon,
  InfoIcon,
  Loader,
  splitTime,
  UpArrowIcon,
  useDashboardStore,
} from '@devd-client/devd/components';
import { useTeamComparisonGql } from '../../../graphql';
import { IoRemoveCircleOutline } from 'react-icons/io5';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useTeamDetailsStore } from '../../../store/teamDetail';

type Props = {
  data: any;
  selectedTeams: string[];
  setSelectedMetrics: (selectedMetrics: any) => void;
  selectedMetrics: any;
  options: any;
};

const MetricColumn = ({
  data,
  selectedTeams,
  setSelectedMetrics,
  selectedMetrics,
  options,
}: Props) => {
  const { selected } = useDashboardStore();
  const { apiTrigger } = useTeamDetailsStore();
  const { colors } = useTheme();

  const {
    data: metricData,
    isLoading: metricDataLoading,
    refetch,
    isRefetching,
  } = useTeamComparisonGql(
    selected.startDate,
    selected.endDate,
    selected.sprintId,
    selectedTeams?.map((team: any) => team.value),
    data.name === 'CUSTOM' ? data.title : data.name
  );

  useEffect(() => {
    refetch();
  }, [apiTrigger]);

  return (
    <>
      <Flex direction="column" minW="200px">
        <Flex
          position={'sticky'}
          top={0}
          minH="52px"
          borderLeft="1px solid #E0E0E0"
          width="100%"
          align="center"
          bg="#FBFBFB"
          zIndex={50}
          overflow={'visible'}
        >
          <Flex align="center" w={'full'} ml={2} mr={2}>
            <Flex align="center">
              <Tooltip label={data.info} hasArrow>
                <Box>
                  <InfoIcon height="13" width="13" />
                </Box>
              </Tooltip>
            </Flex>

            <CustomMetricFilter
              options={options}
              selectedMetrics={selectedMetrics}
              setSelectedMetrics={setSelectedMetrics}
              replaceMetric={data.name === 'CUSTOM' ? data.title : data.name}
              isCustomMetric={data.name === 'CUSTOM'}
              popupPlacement="bottom-start"
            >
              <Flex
                w="100%"
                cursor="pointer"
                align="center"
                justify="space-between"
              >
                <Text
                  ml={1}
                  fontFamily={'heading'}
                  fontSize="xs"
                  fontWeight="medium"
                  color="text.secondary2"
                  textAlign={'start'}
                  w="154px"
                >
                  {data.title}
                </Text>
                <MdOutlineKeyboardArrowDown
                  size={18}
                  color={colors.text.secondary2}
                />
              </Flex>
            </CustomMetricFilter>
          </Flex>
        </Flex>

        <Divider />

        {metricDataLoading &&
          selectedTeams?.map((_: any, idx: number) => {
            return (
              <Skeleton
                key={idx}
                minHeight="51px"
                style={{ marginBottom: '1px' }}
                width="99%"
              />
            );
          })}

        {metricData?.rows?.map((row: any, idx: number) => (
          <Fragment key={`row-${idx}`}>
            <Flex h="52px" align="center" w="full">
              {row.count && row.count !== 'NaN' && row.count !== 'Infinity' ? (
                <Box
                  bg={row.color}
                  borderLeft="1px solid #E0E0E0"
                  w="full"
                  h="52px"
                  p={4}
                >
                  <Flex height="20px" align="center" justify="center">
                    <Flex w="full" justify="space-between" align="center">
                      {/\/member/gi.test(data.title) ? (
                        <Text fontFamily={'heading'} color="text.primary">
                          {row.count}{' '}
                          {row.totalCount && (
                            <Text as="span" fontSize="xs">
                              Total: {row.totalCount}
                            </Text>
                          )}
                        </Text>
                      ) : (
                        <Text fontFamily={'heading'} color="text.primary">
                          {row.unit === 'Minutes'
                            ? splitTime(row.count / 60)
                            : row.unit === 'Hours'
                            ? splitTime(row.count)
                            : row.unit === 'TIME'
                            ? splitTime(row.count / 60)
                            : row.count}{' '}
                          {row.unit === 'Percent' || row.unit === 'PERCENT' ? (
                            <Text as="span" fontSize="xs">
                              {'%'}
                            </Text>
                          ) : row.unit === 'contributor' ? (
                            <Text as="span" fontSize="xs">
                              {'Contributors'}
                            </Text>
                          ) : (
                            row.unit === 'Days' && (
                              <Text fontSize="xs" as="span">
                                {'Days'}
                              </Text>
                            )
                          )}
                        </Text>
                      )}

                      {row.improved !== null && row.percentage !== null && (
                        <Flex align="center">
                          {row.improved ? (
                            <UpArrowIcon color="#1F7A3D" />
                          ) : (
                            <DownArrowIcon color="#73310D" />
                          )}

                          <Text color={row.improved ? '#1F7A3D' : '#73310D'}>
                            {row.percentage}%
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Box>
              ) : (
                <Box bg={'white'} borderLeft="1px solid #E0E0E0" h="52px" p={4}>
                  <Tooltip hasArrow label="No data available">
                    <Box>
                      <CircleIcon />
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Flex>
            <Divider />
          </Fragment>
        ))}
      </Flex>
      {isRefetching && <Loader />}
    </>
  );
};

export default MetricColumn;
