const EstimateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 20C9.75 20 8.57933 19.7627 7.488 19.288C6.396 18.8127 5.446 18.1707 4.638 17.362C3.82933 16.554 3.18733 15.604 2.712 14.512C2.23733 13.4207 2 12.25 2 11C2 9.75001 2.23733 8.57901 2.712 7.48701C3.18733 6.39567 3.82933 5.44567 4.638 4.63701C5.446 3.82901 6.396 3.18767 7.488 2.71301C8.57933 2.23767 9.75 2.00001 11 2.00001C12.25 2.00001 13.421 2.23767 14.513 2.71301C15.6043 3.18767 16.5543 3.82901 17.363 4.63701C18.171 5.44567 18.8127 6.39567 19.288 7.48701C19.7627 8.57901 20 9.75001 20 11C20 12.25 19.7627 13.4207 19.288 14.512C18.8127 15.604 18.171 16.554 17.363 17.362C16.5543 18.1707 15.6043 18.8127 14.513 19.288C13.421 19.7627 12.25 20 11 20ZM13.8 15.2L15.2 13.8L12 10.6V6.00001H10V11.4L13.8 15.2ZM4.6 0.350006L6 1.75001L1.75 6.00001L0.349998 4.60001L4.6 0.350006ZM17.4 0.350006L21.65 4.60001L20.25 6.00001L16 1.75001L17.4 0.350006ZM11 18C12.95 18 14.604 17.3207 15.962 15.962C17.3207 14.604 18 12.95 18 11C18 9.05001 17.3207 7.39567 15.962 6.03701C14.604 4.67901 12.95 4.00001 11 4.00001C9.05 4.00001 7.396 4.67901 6.038 6.03701C4.67933 7.39567 4 9.05001 4 11C4 12.95 4.67933 14.604 6.038 15.962C7.396 17.3207 9.05 18 11 18Z"
        fill={props.color || '#777'}
      />
    </svg>
  );
};

export default EstimateIcon;
