import { useState } from 'react';
import {
  Box,
  Tooltip,
  IconButton,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Image,
  Button,
} from '@chakra-ui/react';
import {
  AiOutlineFileText,
  AiOutlineMail,
  AiOutlineMessage,
  AiOutlinePhone,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { DocsIcon } from '../../../icons';

declare global {
  interface Window {
    Intercom: any;
    analytics: any;
  }
}

const HelpIconButton = () => {
  const [showOptions, setShowOptions] = useState(false);

  const redirectToDocs = () => {
    window.open('https://docs.devdynamics.ai/', '_blank');
  };

  return (
    <Box>
      <Menu>
        {/* <Tooltip aria-label="Docs" label="Docs" hasArrow placement="bottom">
          <MenuButton _hover={{ color: 'primary', bg: 'gray.100' }}>
          <Button _hover={{ color: 'primary', bg: 'gray.100' }}>
              <IconButton
                aria-label="Docs"
                variant="ghost"
                color="text.primary"
                onClick={() => setShowOptions(!showOptions)}
                icon={<Image src={HelpDocsIcon} />}
              />
          </Button>
          </MenuButton>
        </Tooltip> */}
        <Tooltip closeOnClick hasArrow label="Docs">
          <Box
            aria-label="Docs"
            p={1}
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="36px"
            height="36px"
            _hover={{ bg: 'gray.100', color: 'primary' }}
            onClick={redirectToDocs}
            cursor="pointer"
          >
            <DocsIcon color="currentColor" />
          </Box>
        </Tooltip>
        {/* <MenuList mr={'20px'}>
          <MenuItem
            onClick={redirectToDocs}
            icon={<AiOutlineFileText size={20} />}
          >
            Product docs
          </MenuItem>
        </MenuList> */}
      </Menu>
    </Box>
  );
};

export default HelpIconButton;
