import { FC } from 'react';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { CheckboxOption } from './CustomOption';

interface DetailDropdownProps {
  options: any;
  optionSelected: any;
  setOptionSelected: (optionSelected: any) => void;
}

const customStyles = {
  option: (base: any, state: any) => ({
    ...base,
    color: '#1e2022',
    backgroundColor: state.isSelected ? '#F6F7F9' : 'white',
    padding: '.5rem 3rem .5rem .5rem',
    cursor: 'pointer',
    transition: 'all 0.5s',
    '&:hover': {
      backgroundColor: '#E3E6EA',
    },
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

export const DetailDropdown: FC<DetailDropdownProps> = ({
  options,
  optionSelected,
  setOptionSelected,
}) => {
  const handleChange = (selected: any) => {
    setOptionSelected(selected);
  };
  return (
    <Select
      useBasicStyles
      options={options}
      isMulti
      chakraStyles={chakraStyles}
      styles={customStyles}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      maxMenuHeight={400}
      value={optionSelected}
      components={{
        Option: CheckboxOption,
      }}
      onChange={handleChange}
    />
  );
};
