import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const goalsGenericGraphql = async (
  metric: string,
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetGoalMetricsByType(
        $orgId: String!
        $metric: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String
      ) {
        GoalMetricsByType(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            sprintId: $sprintId
          }
        ) {
          chartMetadata {
            chartType
            chartTitle
            info
            xlabel
            ylabel
            xAxis
            yAxis
            chartKey
          }
          data
          legend {
            color
            label
          }
          keys {
            key
            name
            color
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      metric,
      startDate,
      endDate,
      teamId,
      sprintId,
    }
  );
