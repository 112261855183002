export function ActivityLog(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 22V16L7.9 14L6.9 18.4L0 17L0.4 15L5.2 16L6.8 7.9L5 8.6V12H3V7.3L6.95 5.6C7.53333 5.35 7.96267 5.18733 8.238 5.112C8.51267 5.03733 8.76667 5 9 5C9.35 5 9.675 5.09167 9.975 5.275C10.275 5.45833 10.5167 5.7 10.7 6L11.7 7.6C12.1333 8.3 12.7207 8.875 13.462 9.325C14.204 9.775 15.05 10 16 10V12C14.9 12 13.871 11.7707 12.913 11.312C11.9543 10.854 11.15 10.25 10.5 9.5L9.9 12.5L12 14.5V22H10ZM10.5 4.5C9.95 4.5 9.47933 4.304 9.088 3.912C8.696 3.52067 8.5 3.05 8.5 2.5C8.5 1.95 8.696 1.479 9.088 1.087C9.47933 0.695667 9.95 0.5 10.5 0.5C11.05 0.5 11.521 0.695667 11.913 1.087C12.3043 1.479 12.5 1.95 12.5 2.5C12.5 3.05 12.3043 3.52067 11.913 3.912C11.521 4.304 11.05 4.5 10.5 4.5Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
}

export default ActivityLog;
