import { FC } from 'react';
import {
  Avatar,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import {
  Bar,
  BarChart,
  Tooltip as RechartsTooltip,
  ReferenceLine,
  XAxis,
} from 'recharts';
import { useBurnoutContributorDetail } from '../../../graphql';
import { Link } from 'react-router-dom';
import { PeriodType } from '@devd-client/devd/components';
import moment from 'moment';

interface DetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  team: any;
  selected: PeriodType;
}

const DetailModal: FC<DetailModalProps> = ({
  isOpen,
  onClose,
  team,
  selected,
}) => {
  const { data, isLoading } = useBurnoutContributorDetail(
    'AGGREGATED_ACTIVITY',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Details
          <Text
            as="h3"
            fontFamily="heading"
            fontSize="sm"
            fontWeight="medium"
            color="text.secondary"
          >
            Day wise Contributors git activities from{' '}
            <Text as="span" fontWeight="semibold">
              {' '}
              {moment(selected.startDate).format('ll')} -{' '}
              {moment(selected.endDate).format('ll')}{' '}
              {`${
                selected.duration === 'Custom'
                  ? ''
                  : selected.sprintName
                  ? `( ${selected.sprintName} )`
                  : `( ${selected.duration} )`
              }`}
            </Text>
          </Text>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody maxH={'80vh'} overflowY={'auto'} mb={4} pt={6}>
          {isLoading ? (
            <Skeleton h="200px" w="100%" />
          ) : (
            <Table
              borderWidth="1px"
              borderRadius="md"
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Thead>
                <Tr>
                  <Th textAlign={'center'}>ID</Th>
                  <Th px={16} justifyContent={'space-around'} display={'flex'}>
                    <Tooltip label="Sunday">
                      <Text>S</Text>
                    </Tooltip>
                    <Tooltip label="Monday">
                      <Text>M</Text>
                    </Tooltip>
                    <Tooltip label="Tuesday">
                      <Text>T</Text>
                    </Tooltip>
                    <Tooltip label="Wednesday">
                      <Text>W</Text>
                    </Tooltip>
                    <Tooltip label="Thursday">
                      <Text>T</Text>
                    </Tooltip>
                    <Tooltip label="Friday">
                      <Text>F</Text>
                    </Tooltip>
                    <Tooltip label="Saturday">
                      <Text>S</Text>
                    </Tooltip>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((item: any) => (
                  <Tr key={item.name}>
                    <Td>
                      <Flex align="center" width={140}>
                        <Avatar
                          size="xs"
                          name={item.name.charAt(0)}
                          bg="gray.500"
                          color="#fff"
                          fontWeight="semibold"
                          fontSize="xs"
                        />
                        <Link to={`/contributors/${item.name}`}>
                          <Text
                            ml={2}
                            textDecoration="underline"
                            _hover={{
                              color: 'primary',
                              cursor: 'pointer',
                            }}
                            color="text.secondary"
                            fontWeight="medium"
                            fontSize="xs"
                          >
                            {item.name}
                          </Text>
                        </Link>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex justifyContent="center" alignItems="center">
                        <BarChart
                          width={250}
                          height={50}
                          data={item.activityCounts}
                          stackOffset="sign"
                          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                        >
                          <XAxis dataKey="label" hide />
                          <RechartsTooltip
                            wrapperStyle={{
                              outline: 'none',
                            }}
                            position={{ y: -80 }}
                            content={({ payload, label, active }) => {
                              if (active && payload && payload.length) {
                                return (
                                  <Box
                                    backgroundColor="#f9f9f9"
                                    p={2}
                                    borderRadius={4}
                                    boxShadow="0 2px 6px rgba(0, 0, 0, 0.15)"
                                  >
                                    <Text
                                      fontSize="xs"
                                      fontFamily="heading"
                                      color="text.primary"
                                      fontWeight="semibold"
                                    >
                                      {payload[0].payload.day}
                                    </Text>
                                    <Text
                                      fontWeight="medium"
                                      fontSize="11px"
                                      fontFamily="heading"
                                      color="text.secondary"
                                    >
                                      Cumulative Office Hours Activities:{' '}
                                      <Text
                                        as="span"
                                        color="text.primary"
                                        fontWeight="semibold"
                                      >
                                        {payload[0].payload.whActivity}
                                      </Text>
                                    </Text>
                                    <Text
                                      fontWeight="medium"
                                      fontSize="11px"
                                      fontFamily="heading"
                                      color="text.secondary"
                                    >
                                      Cumulative Non-Office Hours Activities:{' '}
                                      <Text
                                        as="span"
                                        color="text.primary"
                                        fontWeight="semibold"
                                      >
                                        {Math.abs(
                                          payload[0].payload.nwhActivity
                                        )}
                                      </Text>
                                    </Text>
                                  </Box>
                                );
                              }
                              return null;
                            }}
                          />
                          <ReferenceLine y={0} stroke="#E3E6EA" isFront />
                          <Bar
                            dataKey="whActivity"
                            stackId="stack"
                            fill="#5F50A9"
                            barSize={10}
                            radius={[2, 2, 0, 0]}
                          />
                          <Bar
                            dataKey="nwhActivity"
                            stackId="stack"
                            fill="#FF8D70"
                            barSize={10}
                            radius={[2, 2, 0, 0]}
                          />
                        </BarChart>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DetailModal;
