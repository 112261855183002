import {
  Avatar,
  Box,
  Checkbox,
  Flex,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { components } from 'react-select';

export const CheckboxOption = (props: any) => {
  const onClick = (e: any) => {
    e.preventDefault();
  };

  return (
    <Box borderBottom={'0.5px solid rgba(0, 0, 0, 0.1)'}>
      <components.Option {...props}>
        <Flex onClick={onClick} align="center">
          <Checkbox isChecked={props.isSelected} onChange={() => null} />

          <Box mx={10}>
            <Avatar size="sm" src={props?.data?.avatar} />
          </Box>

          <Stack spacing={0}>
            <Text
              color="text.primary"
              fontSize="sm"
              fontWeight="bold"
              lineHeight="17px"
            >
              {props?.data?.name}
            </Text>
            <Text fontSize="xs" fontWeight="semibold" color="text.secondary">
              {props?.data?.email}
            </Text>
            <Flex gap={4} flexWrap="wrap">
              {props?.data?.contributorAccountList.map((el: any) => (
                <AccountName title={el?.key?.account} imgType={el?.key?.type} />
              ))}
            </Flex>
          </Stack>
        </Flex>
      </components.Option>
    </Box>
  );
};

const AccountName = ({
  title,
  imgType,
}: {
  title: string;
  imgType: string;
}) => (
  <Flex align="center">
    <Box height={'14px'} width={'14px'}>
      <Image
        src={
          imgType === 'github'
            ? 'https://cdn4.iconfinder.com/data/icons/iconsimple-logotypes/512/github-512.png'
            : imgType === 'jira'
            ? 'https://seeklogo.com/images/J/jira-logo-FD39F795A7-seeklogo.com.png'
            : undefined
        }
      />
    </Box>
    <Text ml={1} fontSize="xs" color="text.secondary" fontWeight="semibold">
      {title}
    </Text>
  </Flex>
);
