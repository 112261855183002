import React from 'react';
import SprintTable from './SprintTable';

interface SprintProps {
  selectedProjectId: string;
}

const Sprint: React.FC<SprintProps> = ({ selectedProjectId }) => {
  return <SprintTable selectedProjectId={selectedProjectId} />;
};

export default Sprint;
