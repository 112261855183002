import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useThroughputPage } from '../../api';
import ThroughputPage from './devd-through-put';

export const Throughput = () => {
  const { data } = useThroughputPage();
  return (
    <>
      <MetaTags title="Throughput | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <ThroughputPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Throughput;
