import { FC } from 'react';
import { Tooltip } from '@chakra-ui/react';
import {
  CardText,
  Dot,
  GoalCardItemContainer,
  Legend,
  TagLabel,
  TagLabels,
  Text,
} from './GoalCardItem.styled';

interface GoalCardItemProps {
  workFlow: any;
  selectedAction: string;
  setSelectedAction: (selectedAction: string) => void;
}

const GoalCardItem: FC<GoalCardItemProps> = ({
  workFlow,
  selectedAction,
  setSelectedAction,
}) => {
  return (
    <GoalCardItemContainer
      selected={workFlow?.event === selectedAction}
      onClick={() => setSelectedAction(workFlow?.event)}
    >
      <CardText>{workFlow?.name}</CardText>
    </GoalCardItemContainer>
  );
};

export default GoalCardItem;
