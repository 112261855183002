import { Box, Button, Divider, Stack } from '@chakra-ui/react';
import { Fragment, useCallback, useState } from 'react';
import InvestmentCategoriesForm from './components/inv-categories-form';
import {
  useDeleteInvestmentMutation,
  useInvestmentCategories,
  useAddUpdateInvestmentMutation,
  QUERY_KEYS,
} from './apis';
import { Loader, MetaTags, PageHeader } from '@devd-client/devd/components';
import {
  DeleteClickType,
  InvestmentCategory,
} from './helpers/investment-categories.types';
import InvestmentCategoriesItem from './components/inv-categories-item';
import InvestmentCategoriesUpdateForm from './components/inv-categories-update-form';
import { useQueryClient } from '@tanstack/react-query';
import { useStrings } from './helpers/investment-categories.strings';
import { useToastHook } from '@devd-client/devd/components';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';

const Container = styled.div`
  width: 100%;
`;

export const InvestmentCategories = () => {
  const { data, isLoading } = useInvestmentCategories();

  const [newToast] = useToastHook();

  const strings = useStrings();

  const queryClient = useQueryClient();
  const {
    mutate: addUpdateInvestmentCategories,
    isLoading: addMutationLoading,
  } = useAddUpdateInvestmentMutation();

  const {
    mutate: deleteInvestmentCategories,
    isLoading: deleteMutationLoading,
  } = useDeleteInvestmentMutation();

  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [selectedForEdit, setSelectedForEdit] =
    useState<InvestmentCategory | null>(null);

  const handleAddButtonToggle = useCallback(() => {
    setShowSelect((prev) => !prev);
  }, [showSelect]);

  const handleEditButtonOpen = useCallback(() => {
    setShowEditForm(true);
  }, [showEditForm]);

  const handleEditButtonClose = useCallback(() => {
    setShowEditForm(false);
  }, [showEditForm]);

  const handleSaveClick = ({ field, ...params }: InvestmentCategory) => {
    addUpdateInvestmentCategories(
      {
        ...params,
        field: field as string,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.investmentCategory]);
          newToast({
            message: `${params.category} created successfully.`,
            status: 'success',
          });
        },
      }
    );
    handleAddButtonToggle();
  };

  const handleDeleteClick = (params: DeleteClickType) => {
    deleteInvestmentCategories(params, {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEYS.investmentCategory]);
        newToast({
          message: `${params.category} deleted successfully.`,
          status: 'success',
        });
      },
    });
  };

  const handleUpdateClick = ({ field, ...params }: InvestmentCategory) => {
    addUpdateInvestmentCategories(
      {
        ...params,
        field: field as string,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.investmentCategory]);
          newToast({
            message: `${params.category} updated successfully.`,
            status: 'success',
          });
        },
      }
    );
  };

  return (
    <>
      <MetaTags title="Investment Categories | Settings | Devdynamics" />

      <Container>
        <PageHeader
          headingText="Investment Categories"
          mb={'20px'}
          component={
            <Box>
              <Button
                variant="primary"
                size="sm"
                leftIcon={<AiOutlinePlus />}
                onClick={handleAddButtonToggle}
              >
                {strings.AddCategory}
              </Button>
            </Box>
          }
        />
        <Box as="div" width={'full'}>
          <Stack
            p={6}
            pt={2}
            border="0.5px solid rgba(0,0,0,0.1)"
            borderRadius="4px"
            spacing={0}
          >
            {showSelect && (
              <InvestmentCategoriesForm
                handleAddButtonToggle={handleAddButtonToggle}
                handleSaveClick={handleSaveClick}
              />
            )}

            {data?.dto?.map((item: InvestmentCategory) => (
              <Fragment key={item.category}>
                {selectedForEdit?.category === item.category && showEditForm ? (
                  <InvestmentCategoriesUpdateForm
                    handleEditButtonClose={handleEditButtonClose}
                    selectedForEdit={selectedForEdit}
                    handleUpdateClick={handleUpdateClick}
                  />
                ) : (
                  <InvestmentCategoriesItem
                    item={item}
                    handleDeleteClick={handleDeleteClick}
                    handleEditButtonOpen={handleEditButtonOpen}
                    setSelectedForEdit={setSelectedForEdit}
                  />
                )}

                <Divider />
              </Fragment>
            ))}
          </Stack>
        </Box>
        {(isLoading || addMutationLoading || deleteMutationLoading) && (
          <Loader />
        )}
      </Container>
    </>
  );
};
export default InvestmentCategories;
