import React from 'react';
import { LineChartGoals } from '../LineChartGoals';
import { BarChartGoals } from '../BarChartGoals';
import { PeriodType } from '@devd-client/devd/components';

interface GoalChartProps {
  data: any;
  selectedTeam: string;
  showLabel: boolean;
  selected: PeriodType;
}

const GoalChart: React.FC<GoalChartProps> = ({
  data,
  selectedTeam,
  showLabel,
  selected,
}) => {
  switch (data?.chartMetadata?.chartType) {
    case 'line_chart':
      return (
        <LineChartGoals
          data={data?.data}
          keys={data?.keys}
          chartMetadata={data?.chartMetadata}
          selectedTeam={selectedTeam}
          showLabel
          selected={selected}
        />
      );
    case 'stacked_bar_chart':
      return (
        <BarChartGoals
          data={data?.data}
          keys={data?.keys}
          chartMetadata={data?.chartMetadata}
        />
      );

    default:
      return null;
  }
};

export default GoalChart;
