export function OpsgenieIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 256 305"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="16.6199552%"
          x2="50%"
          y2="119.282511%"
          id="linearGradient-1"
        >
          <stop stop-color="#2684FF" offset="0%"></stop>
          <stop stop-color="#0052CC" offset="82%"></stop>
        </linearGradient>
        <linearGradient
          x1="41.179673%"
          y1="31.1603357%"
          x2="67.7136955%"
          y2="78.6783874%"
          id="linearGradient-2"
        >
          <stop stop-color="#2684FF" offset="0%"></stop>
          <stop stop-color="#0052CC" offset="62%"></stop>
        </linearGradient>
      </defs>
      <g>
        <circle
          fill="url(#linearGradient-1)"
          cx="127.996078"
          cy="76.0579131"
          r="76.0579131"
        ></circle>
        <path
          d="M121.515807,302.952652 C71.8432945,271.266206 30.5322167,228.083335 1.07656696,177.056341 C-0.0620320498,175.01097 -0.309636381,172.587443 0.391817304,170.354079 C1.09327099,168.120715 2.6820805,166.273968 4.78566922,165.246901 L62.3833377,136.980984 C66.4123421,135.016304 71.2746417,136.506664 73.5106445,140.391652 C102.630871,189.198451 145.690139,228.174512 197.147387,252.304221 C178.169747,271.425488 157.153981,288.409659 134.476348,302.952652 C130.510908,305.428568 125.481248,305.428568 121.515807,302.952652 Z"
          fill="url(#linearGradient-2)"
        ></path>
        <path
          d="M134.476348,302.952652 C184.155284,271.274027 225.468036,228.089405 254.915589,177.056341 C256.057132,175.016287 256.31102,172.597018 255.617868,170.364422 C254.924715,168.131826 253.345322,166.281735 251.24912,165.246901 L193.608818,136.980984 C189.579813,135.016304 184.717514,136.506664 182.481511,140.391652 C153.367402,189.203464 110.306398,228.181097 58.8447689,252.304221 C77.8108513,271.438283 98.8280892,288.423643 121.515807,302.952652 C125.481248,305.428568 130.510908,305.428568 134.476348,302.952652 Z"
          fill="#2684FF"
        ></path>
      </g>
    </svg>
  );
}

export default OpsgenieIcon;
