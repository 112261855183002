export const getFormattedDate = (date: Date) => {
  const formattedDate =
    date.toLocaleDateString('en-US', { year: 'numeric' }) +
    '-' +
    date.toLocaleDateString('en-US', { month: '2-digit' }) +
    '-' +
    date.toLocaleDateString('en-US', { day: '2-digit' });
  return formattedDate;
};

export const propsConfig = {
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      _hover: {
        background: 'primary',
      },
    },
    isInRangeBtnProps: {
      color: 'primary',
    },
    selectedBtnProps: {
      background: 'primary',
      color: 'text.primary',
    },
    todayBtnProps: {
      background: 'primary',
    },
  },
  inputProps: {
    size: 'md',
    width: '100%',
  },

  popoverCompProps: {
    popoverContentProps: {
      background: 'white',
    },
  },
};

export const formatOptionLabel = ({ value, label }: any) => {
  if (value === '') {
    return <span>&nbsp;</span>;
  }
  return label;
};

export const definitionTypeOptions = [
  { value: 'labels', text: 'Labels' },
  { value: 'type', text: 'Issue Types' },
  { value: 'workspace', text: 'Project Names' },
  { value: 'id', text: 'Issue IDs' },
  { value: 'customField', text: 'Custom Field' },
];

export const definitionTypeOptionsForInitiatives = [
  { value: 'labels', text: 'Labels' },
  { value: 'type', text: 'Issue Types' },
  { value: 'workspace', text: 'Project Names' },
  { value: 'id', text: 'Issue IDs' },
  { value: 'customField', text: 'Custom Field' },
  { value: 'dateRange', text: 'Date Range' },
];

export const convertConditionsToIssueFilters = (conditions: any) => {
  return conditions.map((condition: any) => {
    return {
      criteriaFields: condition.map((field: any) => {
        const baseField = {
          name: definitionTypeOptionsForInitiatives.find(
            (el: any) => el.value === field.field
          )?.text,
          value: '',
          dateRange: {
            startDate: new Date(),
            endDate: new Date(),
          },
          customField: {
            name: '',
            value: '',
          },
        };

        if (field.field === 'dateRange') {
          const value = JSON.parse(field.value);
          baseField.dateRange.startDate = new Date(value.startDate);
          baseField.dateRange.endDate = new Date(value.endDate);
        } else if (field.field === 'customField') {
          baseField.customField.name =
            field.customField?.name || field.value?.name;
          baseField.customField.value =
            field.customField?.value || field.value?.value;
        } else {
          baseField.value = field.value;
        }

        return baseField;
      }),
    };
  });
};
