export const DeployTypes = {
  issue: 'Issue',
  incident: 'Incident',
  commit: 'Commit',
  pr: 'PR',
  comment: 'Comment',
  deployment: 'Deployment',
} as const;

export enum DeployEnum {
  issue = 'issue',
  incident = 'incident',
  commit = 'commit',
  pr = 'pr',
  comment = 'comment',
  deployment = 'deployment',
}
