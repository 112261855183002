import { Box, SimpleGrid } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  Loader,
  PageHeader,
  useToastHook,
  IntegrationCard,
  MetaTags,
} from '@devd-client/devd/components';
import { apiClient } from '@devd-client/api';
import styled from 'styled-components';
import { useAuth } from '@devd-client/devd/auth';

const Container = styled.div`
  width: 100%;
`;

export type IntegrationData = {
  name: string;
  type: string;
  active: boolean;
  projects: any;
  fieldMap: string;
  fieldMapVals: string;
  properties: any;
  status: string;
  message: null;
  displayValue: string;
};

export const IntegrationSettings = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [activeIntegrationData, setActiveIntegrationData] = useState<
    IntegrationData[]
  >([]);
  const [inactiveIntegrationData, setInactiveIntegrationData] = useState<
    IntegrationData[]
  >([]);
  const [appState] = useAuth();
  const plan = appState?.user?.dto?.plan;

  const [isIntegrationDisabled, setIntegrationDisabled] =
    useState<boolean>(false);

  const [newToast] = useToastHook();

  const [clickSubmit, setClickSubmit] = useState<boolean>(false);

  useEffect(() => {
    try {
      setLoading(true);
      apiClient(`/v1/integration/org/${localStorage.getItem('orgId')}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
      })
        .then((res: any) => {
          setLoading(false);

          setActiveIntegrationData(
            res?.dto?.filter((item: any) => item.active === true)
          );
          setInactiveIntegrationData(
            res?.dto?.filter((item: any) => item.active === false)
          );
        })
        .catch((err) => {
          newToast({
            message: `${err}`,
            status: 'error',
          });
        });
    } catch (err) {
      setLoading(false);
      newToast({
        message: `${err}`,
        status: 'error',
      });
    }
  }, [clickSubmit]);

  useEffect(() => {
    if (!appState?.user?.dto?.integrationAllowed) {
      setIntegrationDisabled(false);
    } else {
      setIntegrationDisabled(
        (plan === 'Free' || plan === 'Trial') &&
          activeIntegrationData?.length >=
            appState?.user?.dto?.integrationAllowed
      );
    }
  }, [activeIntegrationData, appState?.user?.dto?.integrationAllowed]);

  return (
    <>
      <MetaTags title="Integrations | Settings | Devdynamics" />
      <Container>
        <PageHeader headingText="Integrations" mb={'20px'} />
        <Box as="div" width={'full'}>
          {activeIntegrationData?.length > 0 && !loading ? (
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={3} mb={3}>
              {activeIntegrationData?.map(
                (integration: IntegrationData, index: number) => {
                  return integration?.name !== '' ? (
                    <IntegrationCard
                      key={index}
                      {...integration}
                      setClickSubmit={setClickSubmit}
                      isIntegrationDisabled={false}
                    />
                  ) : (
                    <></>
                  );
                }
              )}
            </SimpleGrid>
          ) : (
            <></>
          )}

          {inactiveIntegrationData?.length > 0 && !loading ? (
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={3}>
              {inactiveIntegrationData?.map(
                (integration: IntegrationData, index: number) => {
                  return integration?.name !== '' ? (
                    <IntegrationCard
                      key={index}
                      {...integration}
                      setClickSubmit={setClickSubmit}
                      isIntegrationDisabled={isIntegrationDisabled}
                    />
                  ) : (
                    <></>
                  );
                }
              )}
            </SimpleGrid>
          ) : (
            <></>
          )}
        </Box>

        {loading && <Loader />}
      </Container>
    </>
  );
};
export default IntegrationSettings;
