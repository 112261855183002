import React, { FC } from 'react';
import { BadgeContainer } from './Badge.styled';

interface BadgeProps {
  text: string;
  dotColor: string;
  color?: string;
}

export const Badge: FC<BadgeProps> = ({ dotColor, color, text }) => {
  return (
    <BadgeContainer dotColor={dotColor} color={color}>
      {text}
    </BadgeContainer>
  );
};

export default Badge;
