import { Box, Flex, Text, useTheme, Image, Button } from '@chakra-ui/react';
import {
  NoDataFoundIcon,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import Nodata from './no-data.png';
import NoGitIntegration from './git-not-integrated.png';
import NoIssueIntegration from './issue-not-integrated.png';
import { useNavigate } from 'react-router';

const NoIntegration = ({
  integration,
}: {
  integration: { type: string; active: boolean };
}) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  return (
    <Flex w="100%" py={50} justify="center">
      <Flex justify="center" direction={'column'} align={'center'}>
        <Box w={170}>
          <Image
            src={
              integration.type === 'GitHub'
                ? NoGitIntegration
                : NoIssueIntegration
            }
          />
        </Box>

        <Text
          fontFamily="heading"
          fontWeight="semibold"
          fontSize={'lg'}
          mt={5}
          mb={2}
        >
          {integration.type === 'GitHub'
            ? 'Git Not Integrated.'
            : 'Issue Tracker not integrated.'}
        </Text>
        {integration.type === 'GitHub' ? (
          <Text fontFamily="heading" fontSize={'xs'}>
            Connect any of your git tools.
          </Text>
        ) : (
          <Text fontFamily="heading" fontSize={'xs'}>
            Connect any of your issue tracker tools.
          </Text>
        )}
        {integration.type === 'GitHub' ? (
          <Button
            variant={'primary'}
            mt={3}
            onClick={() => navigate('/settings/integrations')}
          >
            Connect Git
          </Button>
        ) : (
          <Button
            variant={'primary'}
            mt={3}
            onClick={() => navigate('/settings/integrations')}
          >
            Connect Issue Tracker
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

export default NoIntegration;
