import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react';

type Status = 'success' | 'error' | 'warning';

interface CustomAlertProps {
  status: Status;
  content: React.ReactNode | string;
}

export const CustomAlert: React.FC<CustomAlertProps> = ({
  status,
  content,
}) => {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  return isVisible ? (
    <Alert
      display="flex"
      alignItems="center"
      width="fit-content"
      p={1}
      borderRadius="4px"
      status={status}
      my={1}
      minH="48px"
    >
      <AlertIcon boxSize={4} />
      <Box>
        <AlertDescription>{content}</AlertDescription>
      </Box>
      <CloseButton alignSelf="center" onClick={onClose} />
    </Alert>
  ) : (
    <></>
  );
};
