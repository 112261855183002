import { apiClient } from '@devd-client/api';
import { BottomViewResponse } from '../helpers/bottomView.types';

export const getIntegrationDetails = (id: string): Promise<any> =>
  apiClient(`/v1/integration/${id}/org/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getIssueTypeMap = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/issueType`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};
export const updateIssueTypeMap = (params: any): Promise<any> => {
  return apiClient(
    `/v1/integration/Jira/org/${localStorage.getItem('orgId')}/issueType`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const integrateRequest = (params: any): Promise<any> => {
  return apiClient(
    `/v1/integration/${params.name}/org/${localStorage.getItem('orgId')}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const getBottomView = (
  integrationId: string
): Promise<BottomViewResponse> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/fieldMap`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const addUpdateBottomView = (params: any): Promise<any> => {
  return apiClient(
    `/v1/integration/${params?.integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/fieldMap`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.data),
    }
  );
};

export const getTableView = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/projects`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const getProjects = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/projects`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const addUpdateProjects = (params: any): Promise<any> => {
  return apiClient(
    `/v1/integration/${params.integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/project`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.data),
    }
  );
};

export const getIssueStatuses = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/issueStatuses`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const getConfiguredIssueStatus = (
  integrationId: string
): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/issueStatus`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const postConfiguredIssueStatus = (params: any): Promise<any> => {
  const { integrationId, ...data } = params;
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/issueStatus`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }
  );
};

export const getQualityConfig = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/qualityConfig`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const postQualityConfig = (params: any): Promise<any> => {
  const { integrationId, ...data } = params;
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/qualityConfig`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }
  );
};

export const getStatusCategory = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/statusCategory`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const postStatusCategory = (params: any): Promise<any> => {
  const { integrationId, ...data } = params;
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/statusCategory`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }
  );
};

export const getProdBugs = (integrationId: string): Promise<any> => {
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/prodBugsConfiguration`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const postProdBugs = (params: any): Promise<any> => {
  const { integrationId, ...data } = params;
  return apiClient(
    `/v1/integration/${integrationId}/org/${localStorage.getItem(
      'orgId'
    )}/prodBugsConfiguration`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }
  );
};
