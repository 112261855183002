import { lazy } from 'react';
import SuspenseWrapper from '../../utils/suspenseWrapper';

export const IntegrationSettings = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/ui-shared').then((module) => ({
      default: module.IntegrationSettings,
    }))
  )
);

export const InvestmentCategories = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/ui-shared').then((module) => ({
      default: module.InvestmentCategories,
    }))
  )
);

export const Teams = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/teams').then((module) => ({
      default: module.DevdTeams,
    }))
  )
);

export const Members = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/members').then((module) => ({
      default: module.DevdMembers,
    }))
  )
);

export const Dora = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/ui-shared').then((module) => ({
      default: module.Dora,
    }))
  )
);

export const AddTeams = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/teams').then((module) => ({
      default: module.TeamConfiguration,
    }))
  )
);

export const SubsAndBilling = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/subs-and-billing').then((module) => ({
      default: module.SubsAndBilling,
    }))
  )
);

export const Organisation = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/organisation').then((module) => ({
      default: module.DevdOrganisation,
    }))
  )
);

export const Contributors = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/contributors').then((module) => ({
      default: module.Contributors,
    }))
  )
);

export const IntegrationDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/components').then((module) => ({
      default: module.IntegrationDetails,
    }))
  )
);

export const IntegrationGitDetails = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/components').then((module) => ({
      default: module.IntegrationGitDetails,
    }))
  )
);

export const Feedback = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/feedback').then((module) => ({
      default: module.FeedbackSettings,
    }))
  )
);

export const Notification = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/notification').then((module) => ({
      default: module.Notification,
    }))
  )
);

export const AddMember = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/teams').then((module) => ({
      default: module.AddMember,
    }))
  )
);

export const Survey = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/survey').then((module) => ({
      default: module.SurveySettings,
    }))
  )
);

export const CustomMetrics = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/custom-metrics').then((module) => ({
      default: module.CustomMetrics,
    }))
  )
);

export const AddCustomMetrics = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/custom-metrics').then((module) => ({
      default: module.AddMetrics,
    }))
  )
);

export const EditCustomMetrics = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/custom-metrics').then((module) => ({
      default: module.EditMetrics,
    }))
  )
);
