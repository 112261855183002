/**
 * Retrieves and flattens data from a specified key within paginated GraphQL data.
 *
 * This function accepts a GraphQL response object that contains multiple pages of data
 * and extracts data based on the provided key, flattening the results into a single array.
 * If the key or data is not found, it returns an empty array.
 *
 * @param {any} graphQLData - The GraphQL data object containing a paginated structure,
 * usually with a `pages` array where each page includes data for a specific key.
 * @param {string} key - The key is the GraphQL query key coming from backend.
 */
function getDataByKey(graphQLData: any, key: string) {
  return (
    graphQLData?.pages?.flatMap((page: any) => page[key]?.data ?? []) ?? []
  );
}

export { getDataByKey };
