import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface SidebarState {
  open: boolean;
  setOpen: (open: boolean) => void;
  hideBanner: boolean;
  setHideBanner: (showBanner: boolean) => void;
}

export const useSidebarStore = create<SidebarState>()(
  persist(
    (set) => ({
      open: true,
      setOpen: (open: boolean) => set({ open }),
      hideBanner: false,
      setHideBanner: (hideBanner: boolean) => set({ hideBanner }),
    }),
    {
      name: 'sidebar-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
