import moment from 'moment';
import { InvestmentDataType } from './Investments.types';

export const getOneWeek = (currentDate = moment()) => {
  const weekStart = currentDate.clone().startOf('isoWeek');
  const weekEnd = currentDate.clone().endOf('isoWeek');

  const days = [];

  for (let i = 0; i <= 6; i++) {
    days.push(moment(weekStart).add(i, 'days').format('MM-DD-YYYY'));
  }
  return days;
};

export const convertToStackChartData = (data: InvestmentDataType[]) => {
  const mergeArrayOfObjs = (arr: any) => Object.assign({}, ...arr);

  return data.map((item: InvestmentDataType, idx: number) => {
    return {
      date: item.name,
      name: `${moment(item.name).subtract(6, 'days').format('DD MMM')}-${moment(
        item.name
      ).format('DD MMM')}`,
      ...mergeArrayOfObjs(
        item.rows.map((row: any) => ({
          [row.name]: row.dist,
        }))
      ),
    };
  });
};
