import { useField } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useTheme,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { PasswordIcon } from '../icons/PasswordIcon';
import { EyeCloseIcon } from '../icons/EyeCloseIcon';
import { EyeOpenIcon } from '../icons/EyeOpenIcon';

export function PasswordInput({ label, ...props }: any) {
  const [field, meta] = useField(props);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { colors } = useTheme();

  return (
    <FormControl id={props.id} isInvalid={meta.touched && (meta.error as any)}>
      {label && (
        <FormLabel
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
        >
          {label}
        </FormLabel>
      )}

      <InputGroup size="md">
        <InputLeftElement
          color="text.secondary"
          children={
            <PasswordIcon
              height="16px"
              width="16px"
              color={colors?.text?.secondary}
            />
          }
        />

        <Input {...field} {...props} type={show ? 'text' : 'password'} />

        <InputRightElement
          children={
            <IconButton
              variant="link"
              aria-label={show ? 'Mask password' : 'Reveal password'}
              icon={
                show ? (
                  <EyeCloseIcon
                    height="16px"
                    width="16px"
                    color={colors?.text?.secondary}
                  />
                ) : (
                  <EyeOpenIcon
                    height="16px"
                    width="16px"
                    color={colors?.text?.secondary}
                  />
                )
              }
              onClick={handleClick}
            />
          }
        />
      </InputGroup>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
export default PasswordInput;
