import { Flex } from '@chakra-ui/react';
import { AppIcon, Loader } from '@devd-client/devd/components';
import React, { Suspense } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 580px;
  min-height: 400px;
  margin: 10vh auto;
  padding: 30px;
  /* background-color: rgb(248, 248, 248); */
  background-color: #f6f7f9;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
`;

const Invitation = React.memo(
  React.lazy(() =>
    import('@devd-client/devd/auth').then((module) => ({
      default: module.Invitation,
    }))
  )
);

export function AsyncInvitation() {
  return (
    <Container>
      <Flex justify={'center'}>
        <AppIcon height="28px" />
      </Flex>

      <Suspense>
        <Invitation />
      </Suspense>
    </Container>
  );
}
