export const BulbIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="#C7CFE2"
        d="M212.17,435.162v33.008c0,24.207,19.623,43.83,43.83,43.83l0,0c24.207,0,43.83-19.623,43.83-43.83
   v-33.008H212.17z"
      />
      <path
        opacity="0.1"
        enableBackground="new"
        d="M245.362,468.17v-33.008H212.17v33.008c0,24.207,19.623,43.83,43.83,43.83
   c5.875,0,11.475-1.164,16.596-3.262C256.621,502.196,245.362,486.502,245.362,468.17z"
      />
      <path
        fill="#FFD782"
        d="M257.892,86.994c-75.51-1.022-138.722,61.303-138.722,136.819c0,30.098,9.726,57.921,26.195,80.513
   c26.43,44.007,38.985,64.51,42.774,91.373c1.625,11.518,11.466,20.091,23.097,20.091h89.53c11.631,0,21.472-8.573,23.097-20.091
   c3.79-26.863,16.345-47.366,42.774-91.373c16.47-22.592,26.195-50.415,26.195-80.513C392.833,148.875,332.59,88.005,257.892,86.994z
   "
      />
      <path
        opacity="0.1"
        enableBackground="new"
        d="M232.394,395.699c-3.79-26.863-16.344-47.366-42.774-91.373
   c-16.47-22.592-26.195-50.415-26.195-80.513c0-67.413,50.377-124.309,114.905-135.017c-6.663-1.094-13.488-1.707-20.438-1.802
   c-75.51-1.022-138.722,61.303-138.722,136.819c0,30.098,9.726,57.921,26.195,80.513c26.431,44.008,38.984,64.51,42.774,91.373
   c1.625,11.518,11.465,20.09,23.097,20.09h44.256C243.86,415.79,234.018,407.216,232.394,395.699z"
      />
      <path
        fill="#B4BBCC"
        d="M317.798,435.162l-61.797-19.373l-61.797,19.373c-10.7,0-19.373,8.674-19.373,19.373
   c0,10.7,8.674,19.374,19.373,19.374h123.595c10.7,0,19.373-8.674,19.373-19.374C337.171,443.837,328.497,435.162,317.798,435.162z"
      />
      <path
        opacity="0.1"
        enableBackground="new"
        d="M219.085,454.536c0-10.7,8.674-19.373,19.374-19.373l39.67-12.436
   l-22.128-6.937l-61.797,19.373c-10.7,0-19.374,8.674-19.374,19.373c0,10.7,8.674,19.374,19.374,19.374h44.256
   C227.759,473.909,219.085,465.236,219.085,454.536z"
      />
      <path
        fill="#C7CFE2"
        d="M317.798,396.416H194.204c-10.7,0-19.373,8.674-19.373,19.373l0,0c0,10.7,8.674,19.373,19.373,19.373
   h123.595c10.7,0,19.373-8.674,19.373-19.373l0,0C337.171,405.09,328.497,396.416,317.798,396.416z"
      />
      <path
        opacity="0.1"
        enableBackground="new"
        d="M219.085,415.79c0-10.7,8.674-19.373,19.374-19.373h-44.256
   c-10.7,0-19.374,8.674-19.374,19.373c0,10.7,8.674,19.373,19.374,19.373h44.256C227.759,435.162,219.085,426.489,219.085,415.79z"
      />
      <g>
        <path
          fill="#FFD782"
          d="M256.001,61.698c-4.584,0-8.298-3.715-8.298-8.298V8.298c0-4.583,3.714-8.298,8.298-8.298
       s8.298,3.715,8.298,8.298V53.4C264.299,57.982,260.584,61.698,256.001,61.698z"
        />
        <path
          fill="#FFD782"
          d="M154.169,87.636c-2.941,0-5.789-1.567-7.294-4.33L125.3,43.698
       c-2.192-4.024-0.707-9.064,3.317-11.256c4.027-2.193,9.065-0.707,11.256,3.318l21.575,39.608c2.192,4.024,0.707,9.064-3.317,11.256
       C156.871,87.311,155.51,87.636,154.169,87.636z"
        />
        <path
          fill="#FFD782"
          d="M77.13,159.126c-1.542,0-3.101-0.429-4.493-1.328l-37.893-24.462
       c-3.85-2.486-4.957-7.622-2.471-11.472s7.621-4.957,11.471-2.471l37.893,24.462c3.85,2.486,4.957,7.622,2.471,11.472
       C82.522,157.786,79.854,159.126,77.13,159.126z"
        />
        <path
          fill="#FFD782"
          d="M357.832,87.636c-1.341,0-2.701-0.325-3.962-1.012c-4.024-2.192-5.509-7.232-3.317-11.256
       l21.575-39.608c2.191-4.025,7.228-5.512,11.256-3.318c4.024,2.192,5.509,7.232,3.317,11.256l-21.575,39.608
       C363.621,86.07,360.773,87.636,357.832,87.636z"
        />
        <path
          fill="#FFD782"
          d="M434.871,159.126c-2.724,0-5.391-1.34-6.979-3.798c-2.486-3.85-1.38-8.987,2.471-11.472
       l37.893-24.462c3.848-2.485,8.985-1.38,11.471,2.471s1.38,8.987-2.471,11.472l-37.893,24.462
       C437.973,158.697,436.413,159.126,434.871,159.126z"
        />
      </g>
    </svg>
  );
};
