import { apiClient } from '@devd-client/api';
import { InvestmentCategoryResponse } from '../helpers/investment-categories.types';

type AddInvestmentCategoryProps = {
  integration: string;
  category: string;
  type: string;
  sqlOperator: string;
  value: string;
  color: string;
  field: string;
};

type DeleteInvestmentCategoryProps = {
  orgId?: string;
  integration: string;
  category: string;
  type: string;
};

export const getInvestmentCategories =
  (): Promise<InvestmentCategoryResponse> =>
    apiClient(`/v2/org/${localStorage.getItem('orgId')}/investment`, {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });

export const addUpdateInvestmentCategories = (
  params: AddInvestmentCategoryProps
): Promise<any> =>
  apiClient(`/v2/org/${localStorage.getItem('orgId')}/investment`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const deleteInvestmentCategories = (
  params: DeleteInvestmentCategoryProps
): Promise<any> => {
  const orgId = localStorage.getItem('orgId');
  return apiClient(`/v2/org/${localStorage.getItem('orgId')}/investment`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ ...params, orgId }),
  });
};
