import {
  Box,
  Button,
  Center,
  Code,
  Divider,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useTheme,
  useDisclosure,
} from '@chakra-ui/react';
import {
  CustomSelect,
  eliminateHtmlTags,
  ListSelect,
  useToastHook,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useFetchAllJiraProjects } from '../apis';

interface CustomField {
  name: string;
  value: string;
}

interface IssueDefinition {
  issueType: string;
  labels: string;
  priorities: string;
  projects: string;
  severity: string;
  status: string;
  customField: CustomField;
}

interface ReleaseFormProps {
  handleReleaseSaveClick: (params: any) => void;
  data: any;
  customFieldData: any;
}

interface FormData {
  type: string;
  teamTrackingType: string;
  branchDefinition: string | null;
  issueDefinition: IssueDefinition | null;
  teamTrackingField: any;
}

const rfTeamTrackingIssueOptions = [
  {
    label: 'Issue Label',
    value: 'LABEL',
  },
  {
    label: 'Issue Project',
    value: 'PROJECT',
  },
  {
    label: 'Custom Field',
    value: 'CUSTOM',
  },
];

const rfTeamTrackingGitOptions = [
  {
    label: 'Git Repo',
    value: 'REPO',
  },
];

const rfTeamTrackingEventOptions = [
  {
    label: 'Git Repo',
    value: 'REPO',
  },
  {
    label: 'Job Name Pattern',
    value: 'NAME_PATTERN',
  },
];

const ReleaseForm: FC<ReleaseFormProps> = ({
  handleReleaseSaveClick,
  data,
  customFieldData,
}) => {
  const [newToast] = useToastHook();
  const { colors } = useTheme();
  const orgId = localStorage.getItem('orgId') || '';
  const [selectedIssueType, setSelectedIssueType] = useState<string>(
    data?.dto?.issueDefinition?.issueType || ''
  );
  const [selectedLabels, setSelectedLabels] = useState<string>(
    data?.dto?.issueDefinition?.labels || ''
  );
  const [selectedPriorities, setSelectedPriorities] = useState<string>(
    data?.dto?.issueDefinition?.priorities || ''
  );
  const [selectedProjects, setSelectedProjects] = useState<string>(
    data?.dto?.issueDefinition?.projects?.trim() || ''
  );
  const [selectedSeverity, setSelectedSeverity] = useState<string>(
    data?.dto?.issueDefinition?.severity || ''
  );

  const [selectedStatus, setSelectedStatus] = useState<string>(
    data?.dto?.issueDefinition?.status || ''
  );

  const [selectedCustomFieldName, setSelectedCustomFieldName] =
    useState<string>(data?.dto?.issueDefinition?.customField?.name || '');
  const [selectedCustomFieldValue, setSelectedCustomFieldValue] =
    useState<string>(data?.dto?.issueDefinition?.customField?.value || '');

  const [selectedBranchDefinition, setselectedBranchDefinition] =
    useState<string>(data?.dto?.branchDefinition || '');

  const [selectedOption, setSelectedOption] = useState<string>(
    data?.dto?.type || ''
  );

  const [teamTrackingTypeIssue, setTeamTrackingTypeIssue] = useState(
    (data?.dto?.type === 'ISSUE_UNIT' && data?.dto?.teamTrackingType) || ''
  );

  const [teamTrackingTypeGit, setTeamTrackingTypeGit] = useState(
    (data?.dto?.type === 'GIT_PR_UNIT' && data?.dto?.teamTrackingType) || ''
  );

  const [teamTrackingTypeWebhook, setTeamTrackingTypeWebhook] = useState(
    (data?.dto?.type === 'WEBHOOK_UNIT' && data?.dto?.teamTrackingType) || ''
  );

  const [teamTrackingField, setTeamTrackingField] = useState('');

  useEffect(() => {
    setTeamTrackingField(data?.dto?.teamTrackingField);
  }, [data?.dto?.teamTrackingField]);

  const onSubmit = () => {
    if (
      (selectedOption === 'ISSUE_UNIT' && !teamTrackingTypeIssue) ||
      (selectedOption === 'GIT_PR_UNIT' && !teamTrackingTypeGit) ||
      (selectedOption === 'WEBHOOK_UNIT' && !teamTrackingTypeWebhook) ||
      !selectedOption
    ) {
      return newToast({
        message: 'Please select a Team Tracking option',
        status: 'error',
      });
    }

    if (teamTrackingTypeIssue === 'CUSTOM' && !teamTrackingField) {
      return newToast({
        message: 'Please enter a custom field name',
        status: 'error',
      });
    }

    const formData: FormData = {
      type: selectedOption,
      teamTrackingType:
        selectedOption === 'ISSUE_UNIT'
          ? teamTrackingTypeIssue
          : selectedOption === 'GIT_PR_UNIT'
          ? teamTrackingTypeGit
          : selectedOption === 'WEBHOOK_UNIT'
          ? teamTrackingTypeWebhook
          : '',
      branchDefinition: null,
      issueDefinition: null,
      teamTrackingField:
        customFieldData?.dto?.find(
          (option: any) => option.value === teamTrackingField
        )?.value || '',
    };

    if (selectedOption === 'ISSUE_UNIT') {
      formData.issueDefinition = {
        issueType: selectedIssueType,
        labels: selectedLabels,
        priorities: selectedPriorities,
        projects: selectedProjects,
        severity: selectedSeverity,
        status: selectedStatus,
        customField: {
          name: selectedCustomFieldName ?? '',
          value: selectedCustomFieldValue,
        },
      };
    } else if (selectedOption === 'GIT_PR_UNIT') {
      formData.branchDefinition = selectedBranchDefinition;
    }

    handleReleaseSaveClick(formData);
  };

  const formatOptionLabel = ({ value, label }: any) => {
    if (value === '') {
      return <span>&nbsp;</span>;
    }
    return label;
  };

  useEffect(() => {
    setSelectedCustomFieldName(data?.dto?.issueDefinition?.customField?.name);
  }, [data?.dto]);

  const listSelectProjectModal = useDisclosure();

  const { data: issueProjects } = useFetchAllJiraProjects();
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  return (
    <Stack spacing={6}>
      <Text
        fontFamily="heading"
        fontSize="md"
        fontWeight="semibold"
        color="text.primary"
      >
        Deployment Detection
      </Text>
      <RadioGroup
        bg="gray.50"
        borderRadius="4px"
        p={3}
        value={selectedOption}
        onChange={setSelectedOption}
      >
        <Stack>
          <Stack>
            <Radio value="ISSUE_UNIT">
              <Text
                fontFamily="heading"
                fontSize="sm"
                color="text.primary"
                fontWeight="medium"
              >
                Detect my deployment from issue managment
              </Text>
            </Radio>
            {selectedOption === 'ISSUE_UNIT' && (
              <>
                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                    >
                      Project Name:
                    </Text>
                  </Box>
                  <Box>
                    <Button
                      variant="primary"
                      size="sm"
                      w={'300px'}
                      onClick={listSelectProjectModal.onOpen}
                    >
                      Select Issue Project (
                      {selectedProjects
                        ? selectedProjects?.split(',')?.length
                        : 0}
                      )
                    </Button>

                    {listSelectProjectModal.isOpen && (
                      <ListSelect
                        isOpen={listSelectProjectModal.isOpen}
                        onClose={listSelectProjectModal.onClose}
                        loading={false}
                        initiallySelectedRows={selectedProjects
                          ?.split(',')
                          .map((item: string) => ({
                            label: item,
                            value: item,
                          }))}
                        onSelectedRowsChange={(e: any) =>
                          setSelectedProjects(
                            e?.map((el: any) => el.label)?.join(',')
                          )
                        }
                        data={issueProjects?.dto?.map((item: any) => ({
                          value: item.name,
                          label: item.name,
                        }))}
                        label={'Issue Project'}
                        setTriggerSubmit={setTriggerSubmit}
                      />
                    )}
                  </Box>
                </HStack>

                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                    >
                      Issue Type:
                    </Text>
                  </Box>
                  <Input
                    w={'300px'}
                    value={selectedIssueType}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setSelectedIssueType(
                        eliminateHtmlTags(e.currentTarget.value)
                      )
                    }
                    placeholder="Issue type"
                  />
                </HStack>

                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                    >
                      Labels:
                    </Text>
                  </Box>

                  <Input
                    w={'300px'}
                    value={selectedLabels}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setSelectedLabels(
                        eliminateHtmlTags(e.currentTarget.value)
                      )
                    }
                    placeholder="Issue labels"
                  />
                </HStack>

                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                    >
                      Priority:
                    </Text>
                  </Box>

                  <Input
                    w={'300px'}
                    value={selectedPriorities}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setSelectedPriorities(
                        eliminateHtmlTags(e.currentTarget.value)
                      )
                    }
                    placeholder="Issue priority"
                  />
                </HStack>

                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                    >
                      Severity:
                    </Text>
                  </Box>

                  <Input
                    w={'300px'}
                    value={selectedSeverity}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setSelectedSeverity(
                        eliminateHtmlTags(e.currentTarget.value)
                      )
                    }
                    placeholder="Issue severity"
                  />
                </HStack>

                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                    >
                      Issue Done Status:
                    </Text>
                  </Box>

                  <Input
                    w={'300px'}
                    value={selectedStatus}
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      setSelectedStatus(
                        eliminateHtmlTags(e.currentTarget.value)
                      )
                    }
                    placeholder="Issue Done Status"
                  />
                </HStack>

                <HStack>
                  <Box minW="130px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mt={'25px'}
                    >
                      Custom Field:
                    </Text>
                  </Box>

                  <CustomSelect
                    defaultItem={{
                      label:
                        customFieldData?.dto?.find(
                          (option: any) =>
                            option.value === selectedCustomFieldName
                        )?.name || '',
                      value: selectedCustomFieldName,
                    }}
                    label={'Select Field Name'}
                    placeholder={'Select Field Name'}
                    onChange={(selected: string) => {
                      setSelectedCustomFieldName(eliminateHtmlTags(selected));
                    }}
                    formatOptionLabel={formatOptionLabel}
                    options={[{ value: '', label: '' }].concat(
                      customFieldData?.dto?.map((e: any) => {
                        return {
                          label: e.name,
                          value: e.value,
                        };
                      })
                    )}
                  />

                  <Box>
                    <Input
                      w={'300px'}
                      value={selectedCustomFieldValue}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        setSelectedCustomFieldValue(
                          eliminateHtmlTags(e.currentTarget.value)
                        )
                      }
                      mt={'25px'}
                      placeholder="Type Field Value"
                    />
                  </Box>
                </HStack>
              </>
            )}
          </Stack>

          <Stack>
            <Radio value="GIT_PR_UNIT">
              <Text
                fontFamily="heading"
                fontSize="sm"
                color="text.primary"
                fontWeight="medium"
              >
                Detect my deployment by Pull Requests to a dedicated branch
              </Text>
            </Radio>
            {selectedOption === 'GIT_PR_UNIT' && (
              <>
                <Text
                  fontSize="sm"
                  fontFamily="heading"
                  fontWeight="semibold"
                  color="text.secondary"
                  lineHeight="17px"
                >
                  Branch
                </Text>
                <Input
                  maxW="600px"
                  value={selectedBranchDefinition}
                  isDisabled={!(selectedOption === 'GIT_PR_UNIT')}
                  isInvalid={
                    selectedOption === 'GIT_PR_UNIT' &&
                    selectedBranchDefinition === ''
                  }
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setselectedBranchDefinition(
                      eliminateHtmlTags(e.currentTarget.value)
                    )
                  }
                  placeholder="Give one or multiple comma separated branch name such as 'release*,trunk'."
                />
              </>
            )}
          </Stack>

          <Stack>
            <Radio value="WEBHOOK_UNIT">
              <Text
                fontFamily="heading"
                fontSize="sm"
                color="text.primary"
                fontWeight="medium"
              >
                Git deployment, Jenkins or CI/CD event. Call below api to push
                events calling below API
              </Text>
            </Radio>

            {selectedOption === 'WEBHOOK_UNIT' && (
              <>
                <Flex w="full" borderRadius={4} ml={2}>
                  <Code
                    w="full"
                    h={'470px'}
                    pl={2}
                    py={2}
                    px={6}
                    fontSize={'13px'}
                    fontFamily={'heading'}
                    fontStyle={'normal'}
                    fontWeight={'medium'}
                    lineHeight={'24px'}
                    color={'text.primary'}
                  >
                    <pre>
                      {`curl --location -g --request  POST 'https://api.devdynamics.ai/api/v1/deployment/${orgId}/notify'
        --header 'X-api-key: $org_api_key'
        --header 'X-delivery: $unique_uuid' 
        --header 'X-event: cd' 
        --header 'Content-Type: application/json'
        --data-raw '{
                    "id": "02537f6d-9196-4b59-a022-248e1ea7834a",
                    "timeStamp": "2022-11-08 13:09:24.846+00",
                    "htmlUrl": "https://api.github.com/repos/devdynamics-ai/devd-client/deployments/696863512",
                    "source": "git",
                    "issueId": "JIRA-123",
                    "assignee": "hello@devdynamics.ai",
                    "status": "success",
                    "repository": "devd-client",
                    "environment": "Production",
                    "commit": "a6264fbf55601a45890b98b55ce016c567a20dc9",
                    "prId": "2342346",
                    "artifact": "frontend-v1.0"
                  }'`}
                    </pre>
                  </Code>
                </Flex>
              </>
            )}
          </Stack>
        </Stack>
      </RadioGroup>

      <Divider />

      {selectedOption === 'GIT_PR_UNIT' && (
        <>
          <Flex align="center">
            <Text
              mr={2}
              fontFamily="heading"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
            >
              Team Tracking
            </Text>
            <Tooltip
              hasArrow
              placement="right"
              label="Use to segregate team data"
            >
              <Box>
                <AiOutlineInfoCircle color={colors.text.secondary} />
              </Box>
            </Tooltip>
          </Flex>
          <HStack align="center">
            <CustomSelect
              defaultItem={{
                label:
                  rfTeamTrackingGitOptions?.find(
                    (option: any) => option.value === teamTrackingTypeGit
                  )?.label || '',
                value: teamTrackingTypeGit,
              }}
              // label={'Select Field Name'}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setTeamTrackingTypeGit(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                rfTeamTrackingGitOptions
              )}
            />
          </HStack>
        </>
      )}

      {selectedOption === 'ISSUE_UNIT' && (
        <>
          <Flex align="center">
            <Text
              mr={2}
              fontFamily="heading"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
            >
              Team Tracking
            </Text>
            <Tooltip
              hasArrow
              placement="right"
              label="Use to segregate team data"
            >
              <Box>
                <AiOutlineInfoCircle color={colors.text.secondary} />
              </Box>
            </Tooltip>
          </Flex>
          <HStack align="center">
            <CustomSelect
              defaultItem={{
                label:
                  rfTeamTrackingIssueOptions?.find(
                    (option: any) => option.value === teamTrackingTypeIssue
                  )?.label || '',
                value: teamTrackingTypeIssue,
              }}
              // label={'Select Field Name'}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setTeamTrackingTypeIssue(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                rfTeamTrackingIssueOptions
              )}
            />
          </HStack>

          {teamTrackingTypeIssue === 'CUSTOM' && (
            <HStack>
              <CustomSelect
                menuPlacement="top"
                defaultItem={{
                  label:
                    customFieldData?.dto?.find(
                      (option: any) => option.value === teamTrackingField
                    )?.name || '',
                  value: teamTrackingField,
                }}
                label={'Select Field Name'}
                placeholder={'Select Field Name'}
                onChange={(selected: string) => {
                  setTeamTrackingField(eliminateHtmlTags(selected));
                }}
                formatOptionLabel={formatOptionLabel}
                options={[{ value: '', label: '' }].concat(
                  customFieldData?.dto?.map((e: any) => {
                    return {
                      label: e.name,
                      value: e.value,
                    };
                  })
                )}
              />
            </HStack>
          )}
        </>
      )}
      {selectedOption === 'WEBHOOK_UNIT' && (
        <>
          <Flex align="center">
            <Text
              mr={2}
              fontFamily="heading"
              fontSize="md"
              fontWeight="semibold"
              color="text.primary"
            >
              Team Tracking
            </Text>
            <Tooltip
              hasArrow
              placement="right"
              label="Use to segregate team data"
            >
              <Box>
                <AiOutlineInfoCircle color={colors.text.secondary} />
              </Box>
            </Tooltip>
          </Flex>
          <HStack align="center">
            <CustomSelect
              defaultItem={{
                label:
                  rfTeamTrackingEventOptions?.find(
                    (option: any) => option.value === teamTrackingTypeWebhook
                  )?.label || '',
                value: teamTrackingTypeWebhook,
              }}
              // label={'Select Field Name'}
              placeholder={'Select Field Name'}
              onChange={(selected: string) => {
                setTeamTrackingTypeWebhook(eliminateHtmlTags(selected));
              }}
              formatOptionLabel={formatOptionLabel}
              options={[{ value: '', label: '' }].concat(
                rfTeamTrackingEventOptions
              )}
            />
          </HStack>
        </>
      )}

      <Box>
        <Button
          variant="tertiary"
          w="120px"
          size={'md'}
          onClick={onSubmit}
          isDisabled={
            selectedOption === 'GIT_PR_UNIT' && selectedBranchDefinition === ''
          }
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
};

export default ReleaseForm;
