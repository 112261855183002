import { FC, useContext, useMemo, useState } from 'react';
import { DataGrid } from '@devd-client/devd/components';
import { InitiativeContext } from '../../../../../contexts/initiativesContext';
import { createColumnHelper } from '@tanstack/react-table';
import { useTeamsGql } from '../../../../../graphql';
import AllocatioInitiativenModal from '../../../ListView/AllocationModal';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';

interface TeamsTableProps {
  selectedProjectId: string;
}

const TeamsTable: FC<TeamsTableProps> = ({ selectedProjectId }) => {
  const {
    state: { teams },
    dispatch,
  } = useContext(InitiativeContext);

  const { isFetching: teamsLoading } = useTeamsGql(selectedProjectId, dispatch);

  const allocationModal = useDisclosure();
  const [allocationTeam, setAllocationTeam] = useState<string>('');

  const rows: any[] = useMemo(() => teams?.teams || [], [teams?.teams]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Name',
      }),
      columnHelper.accessor('allocation', {
        cell: (info) =>
          info.getValue() && (
            <Flex justifyContent="center">
              <Box
                borderRadius="full"
                px={2}
                py={1}
                w="fit-content"
                backgroundColor="primary"
                color="white"
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAllocationTeam(`${info.row.original.name}`);
                  allocationModal.onOpen();
                }}
                _hover={{ opacity: 0.8 }}
                _active={{ opacity: 0.9 }}
                transition="opacity 0.2s"
                fontWeight="bold"
              >
                {info.getValue()}
              </Box>
            </Flex>
          ),
        header: 'Allocation',
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('contributors', {
        cell: (info) => info.getValue(),
        header: 'Contributors',
        meta: {
          isNumeric: true,
        },
      }),
    ],
    [teams?.teams]
  );

  return (
    <>
      <DataGrid
        showLoader={teamsLoading}
        columns={columns}
        data={rows}
        maxH="26rem"
        sticky="firstCol"
      />
      {allocationModal.isOpen && (
        <AllocatioInitiativenModal
          isOpen={allocationModal.isOpen}
          onClose={allocationModal.onClose}
          allocationId={selectedProjectId}
          allocationTeam={allocationTeam}
        />
      )}
    </>
  );
};

export default TeamsTable;
