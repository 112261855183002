import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';

export const QUERY_KEYS = {
  timeline: 'timeline',
};

export const useFetchTimeline = (id: string, apiParam: string) => {
  return useQuery(
    [QUERY_KEYS.timeline, id],
    () => ENDPOINTS.getTimeline(id, apiParam),
    {
      enabled: !!id,
    }
  );
};
