import { useTheme } from '@chakra-ui/react';
import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';

interface DonutChartProps {
  percentage: number;
}

export const DonutChart = ({ percentage }: DonutChartProps) => {
  const { colors } = useTheme();
  const total = percentage < 100 ? 100 : percentage;
  const valueToShow = percentage || 0;
  const remainingValue = total - valueToShow;

  const data = [
    { name: 'Value', value: valueToShow, color: colors.primary },
    { name: 'Remaining', value: remainingValue, color: colors.primary2Bg },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={16}
        >
          {valueToShow}%
        </text>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={35}
          outerRadius={50}
          style={{ outline: 'none' }}
          fill="#8884d8"
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChart;
