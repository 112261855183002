import {
  Box,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { FC, useRef, useEffect } from 'react';

type TabListType = {
  tab: string;
  TabIcon: any;
  tabLabel?: string;
  tooltipText: string;
};

interface ViewSwitcherProps {
  tabList: TabListType[];
  currentTab: string;
  handleSwitch: (item: string) => void;
  height?: string;
}

export const ViewSwitcher: FC<ViewSwitcherProps> = ({
  tabList,
  currentTab,
  handleSwitch,
  height,
}) => {
  const { colors } = useTheme();

  const tabEl = useRef<any>(null);

  useEffect(() => {
    if (tabEl?.current && currentTab === 'Detail view') {
      tabEl?.current?.click();
      tabEl?.current?.blur();
    }
  }, [currentTab]);

  return (
    <Tabs variant="unstyled">
      <TabList
        border="0.5px solid rgba(0, 0, 0, 0.1)"
        borderRadius="4px"
        overflow="hidden"
        height={height ? height : '32px'}
      >
        {tabList?.map(({ TabIcon, tab, tabLabel, tooltipText }: TabListType) =>
          tab === currentTab ? (
            <Tooltip key={tab} label={tooltipText} hasArrow>
              <Tab
                ref={tabEl}
                color="white"
                bg="primary"
                onClick={() => {
                  handleSwitch(tab);
                }}
              >
                <Flex align="center">
                  <Text
                    as="h3"
                    fontFamily="heading"
                    fontSize="sm"
                    fontWeight="medium"
                  >
                    {tabLabel ?? tab}
                  </Text>

                  {TabIcon && (
                    <Box ml={1}>
                      <TabIcon height="16px" width="16px" color="white" />
                    </Box>
                  )}
                </Flex>
              </Tab>
            </Tooltip>
          ) : (
            <Tooltip key={tab} label={tooltipText} hasArrow>
              <Tab
                ref={tabEl}
                color="text.primary"
                _hover={{ bg: '#F6F7F9', color: 'text.primary' }}
                _active={{ bg: 'primary', color: 'white' }}
                _focus={{ bg: 'primary', color: 'white' }}
                onClick={() => {
                  handleSwitch(tab);
                }}
              >
                <Flex align="center">
                  <Text
                    as="h3"
                    fontFamily="heading"
                    fontSize="sm"
                    fontWeight="medium"
                  >
                    {tabLabel ?? tab}
                  </Text>

                  {TabIcon && (
                    <Box ml={1}>
                      <TabIcon
                        height="16px"
                        width="16px"
                        color={colors.text.primary}
                      />
                    </Box>
                  )}
                </Flex>
              </Tab>
            </Tooltip>
          )
        )}
      </TabList>
    </Tabs>
  );
};

export default ViewSwitcher;
