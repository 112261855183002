import { apiClient } from '@devd-client/api';

export const getCustomMetrics = (): Promise<any> =>
  apiClient(`/v1/custom-metric/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const addCustomMetrics = (params: any): Promise<any> =>
  apiClient(`/v1/custom-metric`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ ...params, orgId: localStorage.getItem('orgId') }),
  });

export const updateCustomMetrics = (params: any): Promise<any> =>
  apiClient(`/v1/custom-metric`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ ...params, orgId: localStorage.getItem('orgId') }),
  });

export const deleteCustomMetrics = (params: any): Promise<any> =>
  apiClient(`/v1/custom-metric`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({ ...params, orgId: localStorage.getItem('orgId') }),
  });

export const getCustomField = (): Promise<any> =>
  apiClient(
    `/v1/integration/org/${localStorage.getItem('orgId')}/customFields`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getMeasure = (): Promise<any> =>
  apiClient(`/v1/metrics-metadata?type=measure`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getCustomMetricsByName = (metric: string): Promise<any> =>
  apiClient(
    `/v1/custom-metric/${localStorage.getItem('orgId')}/metrics/${metric}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
