import {
  StyledTooltipHeading,
  StyledToopTipText,
  TooltipContainer,
} from './CustomTooltip.styled';

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <StyledTooltipHeading>{label}</StyledTooltipHeading>
        {payload.map((item: any, index: number) => (
          <StyledToopTipText key={index}>
            {item.name} : {item.value}
          </StyledToopTipText>
        ))}
      </TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
