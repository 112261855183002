export function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M513.6 62.1c-153.7 0-279.5 125.8-279.5 279.5v144.7c0 153.7 125.8 279.5 279.5 279.5S793.1 640 793.1 486.3V341.7c0-153.8-125.7-279.6-279.5-279.6z m174.2 426.5c0 95.8-78.4 174.2-174.2 174.2-95.8 0-174.2-78.4-174.2-174.2V333.5c0-95.8 78.4-174.2 174.2-174.2 95.8 0 174.2 78.4 174.2 174.2v155.1z"
        fill="#9BCFDD"
      />
      <path
        d="M513.6 778.9c-77.8 0-151.2-30.5-206.6-85.9-26.9-26.9-48-58.2-62.8-93-15.3-36.1-23.1-74.3-23.1-113.6V341.7c0-77.8 30.5-151.2 85.9-206.6 55.4-55.4 128.8-85.9 206.6-85.9 77.8 0 151.2 30.5 206.6 85.9 55.4 55.4 85.9 128.8 85.9 206.6v144.7c0 39.3-7.8 77.6-23.1 113.6-14.8 34.8-35.9 66.1-62.8 93-55.4 55.4-128.8 85.9-206.6 85.9z m0-703.8c-70.9 0-137.7 27.8-188.2 78.3s-78.3 117.3-78.3 188.2v144.7c0 70.9 27.8 137.7 78.3 188.2s117.3 78.3 188.2 78.3 137.7-27.8 188.2-78.3 78.3-117.3 78.3-188.2V341.7c0-70.9-27.8-137.7-78.3-188.2S584.5 75.1 513.6 75.1z m0 600.7c-49.8 0-96.8-19.5-132.2-55-35.5-35.5-55-82.4-55-132.2V333.5c0-49.8 19.5-96.8 55-132.2 35.5-35.5 82.4-55 132.2-55s96.8 19.5 132.2 55 55 82.4 55 132.2v155.1c0 49.8-19.5 96.8-55 132.2s-82.4 55-132.2 55z m0-503.5c-42.9 0-83.3 16.8-113.9 47.4-30.5 30.5-47.4 71-47.4 113.9v155.1c0 42.9 16.8 83.3 47.4 113.9 30.5 30.6 71 47.4 113.9 47.4 42.9 0 83.3-16.8 113.9-47.4 30.5-30.5 47.4-71 47.4-113.9V333.5c0-42.9-16.8-83.3-47.4-113.9s-71-47.3-113.9-47.3z"
        fill="#154B8B"
      />
      <path
        d="M845.4 960.2H181.8c-18.8 0-34-15.2-34-34V488.7c0-18.8 15.2-34 34-34h663.6c18.8 0 34 15.2 34 34v437.6c0 18.7-15.2 33.9-34 33.9z"
        fill="#FCFCFC"
      />
      <path
        d="M845.4 973.2H181.8c-25.9 0-47-21.1-47-47V488.7c0-25.9 21.1-47 47-47h663.6c25.9 0 47 21.1 47 47v437.6c0 25.9-21.1 46.9-47 46.9zM181.8 467.7c-11.6 0-21 9.4-21 21v437.6c0 11.6 9.4 21 21 21h663.6c11.6 0 21-9.4 21-21V488.7c0-11.6-9.4-21-21-21H181.8z"
        fill="#10457E"
      />
      <path
        d="M513.6 615.3m-50.8 0a50.8 50.8 0 1 0 101.6 0 50.8 50.8 0 1 0-101.6 0Z"
        fill="#154B8B"
      />
      <path
        d="M513.6 853.3c-9.9 0-18-8.1-18-18V662.8c0-9.9 8.1-18 18-18s18 8.1 18 18v172.5c0 10-8 18-18 18z"
        fill="#144884"
      />
    </svg>
  );
}

export default LockIcon;
