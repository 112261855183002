import { useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  IssueFilterModal,
  Loader,
  PageHeader,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { useNavigate } from 'react-router';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { getFormattedDate, propsConfig } from '../helpers/Initiatives.utils';
import { usePostInitiative } from '../apis';

const initialValues: any = {
  name: '',
  desc: '',
  startDate: undefined,
  plannedDate: undefined,
  contributionTracking: 'jira',
};

export const CreateInitiative = () => {
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const issueFilterModal = useDisclosure();
  const [issueFilterConditions, setIssueFilterConditions] = useState({
    conditions: null,
  });

  const {
    mutate: handlePostInitiative,
    isLoading: handlePostInitiativeLoading,
  } = usePostInitiative();

  const onSubmit = (values: any) => {
    handlePostInitiative(
      {
        name: values.name,
        desc: values.desc,
        startDate: getFormattedDate(values.startDate),
        plannedDate: getFormattedDate(values.plannedDate),
        contributionTracking: values.contributionTracking,
        issueFilterConditions: issueFilterConditions,
      },
      {
        onSuccess: () => {
          newToast({
            message: 'Initiative created successfully!',
            status: 'success',
          });
          setTimeout(() => {
            navigate('/initiatives');
          }, 2000);
        },
        onError: (error) => {
          newToast({
            message: 'Something went wrong!',
            status: 'error',
          });
        },
      }
    );
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.'),
    desc: Yup.string().required('This field is required.'),
    startDate: Yup.date().required('This field is required.'),
    plannedDate: Yup.date()
      .required('This field is required.')
      .min(Yup.ref('startDate'), 'End date must be after start date'),
    dateRangeStart: Yup.date().nullable(),
    dateRangeEnd: Yup.date()
      .nullable()
      .min(Yup.ref('dateRangeStart'), 'End date must be after start date'),
  });

  return (
    <>
      <Flex direction="column" width="100%" minH="100%">
        <PageHeader headingText="Create Initiatives" />
        <Flex mb={4}>
          <Button onClick={() => navigate(-1)} size="sm" variant="link">
            Back
          </Button>
        </Flex>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(props) => {
            const {
              isSubmitting,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
              values,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} w="60%">
                  <GridItem colSpan={2}>
                    <TextInput
                      name="name"
                      label="Name"
                      placeholder="Initiative Name"
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <TextInput
                      name="desc"
                      label="Description"
                      inputType="textarea"
                      placeholder="Initiative Description"
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Start Date
                      </Text>

                      <SingleDatepicker
                        name="startDate"
                        date={values.startDate}
                        onDateChange={(date) =>
                          setFieldValue('startDate', date)
                        }
                        propsConfigs={propsConfig}
                      />
                      {errors['startDate'] && touched['startDate'] && (
                        <Text color="red.500">
                          {errors['startDate'].toString()}
                        </Text>
                      )}
                    </Stack>
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        End Date
                      </Text>
                      <SingleDatepicker
                        name="plannedDate"
                        date={values.plannedDate}
                        onDateChange={(date) =>
                          setFieldValue('plannedDate', date)
                        }
                        propsConfigs={propsConfig}
                      />
                      {errors['plannedDate'] && touched['plannedDate'] && (
                        <Text color="red.500">
                          {errors['plannedDate'].toString()}
                        </Text>
                      )}
                    </Stack>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <Field name={'contributionTracking'}>
                      {({ field }: any) => (
                        <FormControl id={'contributionTracking'}>
                          <RadioGroup
                            {...field}
                            bg="gray.50"
                            borderRadius="4px"
                            p={3}
                            value={values.contributionTracking}
                            onChange={(value) => {
                              setFieldValue('contributionTracking', value);
                            }}
                            id={'contributionTracking'}
                            {...props}
                          >
                            <Radio value="jira">
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                color="text.primary"
                                fontWeight="medium"
                              >
                                Issue Management{' '}
                                <Text
                                  as="span"
                                  fontFamily="heading"
                                  fontSize="sm"
                                  color="text.secondary"
                                  fontWeight="normal"
                                >
                                  - Track Contribution from issue management
                                  assignee.
                                </Text>
                              </Text>
                            </Radio>
                            <Radio value="git">
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                color="text.primary"
                                fontWeight="medium"
                              >
                                Git{' '}
                                <Text
                                  as="span"
                                  fontFamily="heading"
                                  fontSize="sm"
                                  color="text.secondary"
                                  fontWeight="normal"
                                >
                                  - Track Contribution from git pull request.
                                  Works only if issue id mention in PR title.
                                </Text>
                              </Text>
                            </Radio>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>

                  <GridItem colSpan={2}>
                    <Text
                      fontFamily="heading"
                      fontSize="lg"
                      fontWeight="semibold"
                      color="text.primary"
                      mb={2}
                    >
                      Issue Filter Conditions
                    </Text>

                    <Button
                      variant="primary"
                      size="sm"
                      onClick={issueFilterModal.onOpen}
                    >
                      {`Configure >`}
                    </Button>
                  </GridItem>
                </Grid>

                <Button mt={4} mb={4} type="submit" variant={'filled'}>
                  Submit
                </Button>
              </form>
            );
          }}
        </Formik>
      </Flex>
      {handlePostInitiativeLoading && <Loader />}

      {issueFilterModal.isOpen && (
        <IssueFilterModal
          isOpen={issueFilterModal.isOpen}
          onClose={issueFilterModal.onClose}
          loading={false}
          buttonText="Save"
          forInitiatives
          issueFilterConditions={issueFilterConditions}
          onSubmitAction={(payload) => {
            setIssueFilterConditions({ ...payload });
            issueFilterModal.onClose();
          }}
        />
      )}
    </>
  );
};
