import { FC } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useGoalsChartGql } from '../../../../../../graphql';
import {
  ChartContainer,
  ChartHeading,
  ChartSectionContainer,
  ChartSubheading,
  Name,
  NameContainer,
} from './ChartSection.styled';
import { Loader, ISelect, PeriodType } from '@devd-client/devd/components';
import GoalChart from './GoalChart';

interface ChartSelectionProps {
  chartName: string;
  selectedTeam: ISelect;
  selected: PeriodType;
}

const ChartSection: FC<ChartSelectionProps> = ({
  chartName,
  selectedTeam,
  selected,
}) => {
  const { data, isLoading } = useGoalsChartGql(
    chartName,
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    selectedTeam.value
  );

  return (
    <>
      <ChartSectionContainer>
        <ChartHeading>
          <NameContainer>
            <Name>{data?.chartMetadata?.chartTitle}</Name>
            {/* <AiOutlineInfoCircle fontSize="16px" color="#718096" /> */}
          </NameContainer>
        </ChartHeading>

        <ChartSubheading>
          {data?.chartMetadata?.chartTitle &&
            `About the ${data?.chartMetadata?.chartTitle}`}
        </ChartSubheading>
        <ChartContainer>
          <GoalChart data={data} selectedTeam={selectedTeam.value} showLabel />
        </ChartContainer>
      </ChartSectionContainer>
      {isLoading && <Loader />}
    </>
  );
};

export default ChartSection;
