import { defineMessage, useIntl } from 'react-intl';

import { useMemo } from 'react';

export type IntegrationCardStrings = {
  Active: string;
  Integration: string;
  LastSync: string;
  SuccessfullConnection: string;
  ConnectTo: string;
  ConnectInfoOne: string;
  ConnectInfoTwo: string;
  Organization: string;
  Next: string;
  WarningFooter: string;
  Gotogit: string;
  Gotogooglemeet: string;
  Gotojira: string;
};

export const useStrings = () => {
  const active = defineMessage({
    description: 'Active',
    defaultMessage: 'Active',
  });
  const lastSync = defineMessage({
    description: 'Last synced at',
    defaultMessage: 'Last synced at',
  });
  const integration = defineMessage({
    description: 'Integrate',
    defaultMessage: 'Integrate',
  });
  const successfullConnection = defineMessage({
    description: 'Successfully Connected to',
    defaultMessage: 'Successfully Connected to',
  });
  const connectTo = defineMessage({
    description: 'Connect to',
    defaultMessage: 'Connect to',
  });
  const connectInfoOne = defineMessage({
    description: 'In order to connect to',
    defaultMessage: 'In order to connect to',
  });
  const connectInfoTwo = defineMessage({
    description: 'please provide a valid organization name',
    defaultMessage: 'please provide a valid organization name',
  });
  const organization = defineMessage({
    description: 'Organization name :',
    defaultMessage: 'Organization name:',
  });

  const next = defineMessage({
    description: 'Next',
    defaultMessage: 'Next',
  });
  const warningFooter = defineMessage({
    description:
      'This pop-up cannot be escaped ,kindly enter a reason or navigate away using keys or use the escape key.',
    defaultMessage:
      'This pop-up cannot be escaped ,kindly enter a reason or navigate away using keys or use the escape key.',
  });
  const gotogit = defineMessage({
    description: 'Install our GitHub app',
    defaultMessage: 'Install our GitHub app',
  });
  const gotogooglemeet = defineMessage({
    description: 'Install GoogleMeet',
    defaultMessage: 'Install GoogleMeet',
  });
  const gotojira = defineMessage({
    description: 'Install our Jira app',
    defaultMessage: 'Install our Jira app',
  });

  const intl = useIntl();
  const Active = intl.formatMessage(active);
  const LastSync = intl.formatMessage(lastSync);
  const Integration = intl.formatMessage(integration);
  const SuccessfullConnection = intl.formatMessage(successfullConnection);
  const ConnectTo = intl.formatMessage(connectTo);
  const ConnectInfoOne = intl.formatMessage(connectInfoOne);
  const ConnectInfoTwo = intl.formatMessage(connectInfoTwo);
  const Organization = intl.formatMessage(organization);
  const Next = intl.formatMessage(next);
  const WarningFooter = intl.formatMessage(warningFooter);
  const Gotogit = intl.formatMessage(gotogit);
  const Gotogooglemeet = intl.formatMessage(gotogooglemeet);
  const Gotojira = intl.formatMessage(gotojira);

  return useMemo(
    (): IntegrationCardStrings => ({
      Active,
      LastSync,
      Integration,
      SuccessfullConnection,
      ConnectTo,
      ConnectInfoOne,
      ConnectInfoTwo,
      Organization,
      Next,
      WarningFooter,
      Gotogit,
      Gotogooglemeet,
      Gotojira,
    }),
    []
  );
};
