import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { InitiativesActionTypes } from '../reducers/initiativesReducer';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  initiativeUpdates: 'initiativeUpdates',
  initiativesPage: 'initiativesPage',
  initiative: 'initiative',
  initiativeArchive: 'initiativeArchive',
  allocation: 'allocation',
  allocationUpdate: 'allocationUpdate',
  initiativeDetails: 'initiativeDetails',
  initiativePost: 'initiativePost',
};

export const useInitiativeUpdates = (
  initiative: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.initiativeUpdates, initiative],
    () => ENDPOINTS.getInitiativeUpdates(initiative),
    {
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_UPDATES,
          payload: data,
        });
      },
    }
  );
};

export const usePostInitiativeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativeUpdates],
    ENDPOINTS.postInvestmentUpdates
  );
};

export const useInitiativesPage = () => {
  return useQuery([QUERY_KEYS.initiativesPage], ENDPOINTS.getInitiativesPage);
};

export const useAddUpdateInitiativeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiative],
    ENDPOINTS.addUpdateInitiative
  );
};
export const useArchiveInitiativeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativeArchive],
    ENDPOINTS.initiativeArchive
  );
};

export const useAllocationDetail = (id: string) => {
  return useQuery([QUERY_KEYS.allocation, id], () =>
    ENDPOINTS.getAllocationDetail(id)
  );
};

export const useAddUpdateAllocationMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.allocationUpdate],
    ENDPOINTS.addUpdateAllocation
  );
};

export const useInitiativeDetail = (initiative: string) => {
  return useQuery([QUERY_KEYS.initiativeDetails, initiative], () =>
    ENDPOINTS.getInitiativeDetails(initiative)
  );
};

export const usePostInitiative = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativePost],
    ENDPOINTS.postInitiative
  );
};

export const useRemoveInitiative = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativePost],
    ENDPOINTS.deleteInitiative
  );
};

export const useEditInitiative = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativePost],
    ENDPOINTS.editInitiative
  );
};
