import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';

export function CustomNumberInput({ name, prefix, label, ...props }: any) {
  const [field, meta] = useField(name);
  const formikProps = useFormikContext();

  return (
    <FormControl isInvalid={meta.touched && (meta.error as any)}>
      {label && (
        <FormLabel
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}

      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          color="text.primary"
          fontSize="1.1em"
          left="-11px"
          top="-1px"
        >
          {prefix}
        </InputLeftElement>
        <NumberInput
          precision={2}
          step={0.01}
          value={field.value}
          onChange={(val) => {
            formikProps.setFieldValue(name, val);
          }}
          onBlur={field.onBlur}
          {...props}
        >
          <NumberInputField
            id={name}
            {...field}
            {...props}
            _placeholder={{
              color: '#A0AEC0',
              fontSize: 'sm',
            }}
            _hover={{
              border: '1px solid gray.400',
            }}
            _focus={{
              border: '1px solid gray.600',
              boxShadow: '0px 0px 0px 1px #CBD5E0',
            }}
            _active={{
              border: '1px solid gray.600',
              boxShadow: '0px 0px 0px 1px #CBD5E0',
            }}
            _disabled={{
              bg: '#F9F9F9',
            }}
            _invalid={{
              border: '1px solid #B10707',
              boxShadow: 'none',
            }}
          />
        </NumberInput>
      </InputGroup>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
