import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InvestmentsActionTypes } from '../reducer/investmentReducer';
import { ItemMetaType } from '../helpers/Investments.types';
import { convertToStackChartData } from '../helpers/Investments.utils';
import {
  getInvestmentReportDayGraphql,
  getInvestmentReportWeekGraphql,
  getDayTicketReportGraphql,
} from './query';

export const QUERY_KEYS = {
  investmentRptWeek: 'investmentRptWeek',
  investmentRptDay: 'investmentRptDay',
  investmentTicket: 'investmentTicket',
};

export const useInvestmentReportWeekGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  aggInterval: string,
  aggType: string,
  selectedTeam: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [
      QUERY_KEYS.investmentRptWeek,
      startDate,
      endDate,
      sprintId,
      aggInterval,
      aggType,
      selectedTeam,
    ],
    () =>
      getInvestmentReportWeekGraphql(
        startDate,
        endDate,
        sprintId,
        aggInterval,
        aggType,
        selectedTeam
      ),
    {
      select: (data) => ({
        barChartData: convertToStackChartData(data.InvestmentReport.columns),
        // TODO: addCustomFillColor   function to be removed
        chartMetaData: data.InvestmentReport.itemMeta,
      }),
      enabled: !!startDate && !!endDate,
      onSuccess: (data) => {
        dispatch({
          type: InvestmentsActionTypes.BAR_CHART_DATA,
          payload: data.barChartData,
        });

        dispatch({
          type: InvestmentsActionTypes.CHART_META,
          payload: data.chartMetaData,
        });
      },
    }
  );
};

export const useInvestmentReportDayGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  aggInterval: string,
  aggType: string,
  selectedTeam: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [
      QUERY_KEYS.investmentRptDay,
      startDate,
      endDate,
      sprintId,
      aggInterval,
      aggType,
      selectedTeam,
    ],
    () =>
      getInvestmentReportDayGraphql(
        startDate,
        endDate,
        sprintId,
        aggInterval,
        aggType,
        selectedTeam
      ),
    {
      onSuccess: (data) => {
        dispatch({
          type: InvestmentsActionTypes.WEEKLY_DATA,
          payload: data.InvestmentReport.columns,
        });
      },
      enabled: !!startDate || !!endDate,
    }
  );
};

export const useInvestmentReportOverallGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  aggInterval: string,
  aggType: string,
  selectedTeam: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [
      QUERY_KEYS.investmentRptDay,
      startDate,
      endDate,
      sprintId,
      aggInterval,
      aggType,
      selectedTeam,
    ],
    () =>
      getInvestmentReportDayGraphql(
        startDate,
        endDate,
        sprintId,
        aggInterval,
        aggType,
        selectedTeam
      ),
    {
      select: (data) =>
        data.InvestmentReport.columns[0]?.rows.map((row: any) => ({
          ...row,
          value: Number(row.value),
          fill: data.InvestmentReport?.itemMeta
            .filter((item: ItemMetaType) => item.label === row.name)
            .map((el: ItemMetaType) => el.fillColor)[0],
        })),
      enabled: !!startDate && !!endDate,
      onSuccess: (data) => {
        dispatch({
          type: InvestmentsActionTypes.PIE_CHART_DATA,
          payload: data,
        });
      },
    }
  );
};
export const useDayTicketReportGraphql = (
  endDate: string,
  sprintId: string,
  pageNumber: number,
  pageSize: number,
  category: string,
  aggType: string,
  selectedTeam: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.investmentTicket, endDate, category, pageNumber, aggType],
    () =>
      getDayTicketReportGraphql(
        endDate,
        sprintId,
        pageNumber,
        pageSize,
        category,
        aggType,
        selectedTeam
      ),
    {
      onSuccess: (data) => {
        dispatch({
          type: InvestmentsActionTypes.TICKET_DATA,
          payload: data.InvestmentReportDetail,
        });
      },
      enabled: !!endDate,
    }
  );
};
