import React, { useState } from 'react';
import Quarter from './quarter';

interface QuartersProps {
  setSelectedQuarterAndYear: (quarter: string) => void;
}

const Quarters: React.FC<QuartersProps> = ({ setSelectedQuarterAndYear }) => {
  const [year, setYear] = useState(new Date().getFullYear());

  const goToPreviousYear = () => setYear(year - 1);
  const goToNextYear = () => setYear(year + 1);

  const handleQuarterSelect = (quarter: number, year: number) => {
    setSelectedQuarterAndYear(`${quarter}-${year}`);
  };

  return (
    <Quarter
      year={year}
      goToPreviousYear={goToPreviousYear}
      goToNextYear={goToNextYear}
      onSelectQuarter={handleQuarterSelect}
    />
  );
};

export default Quarters;
