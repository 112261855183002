export const ProfileIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.90604 8.20037C7.80604 8.20037 6.86437 7.808 6.08104 7.02326C5.2977 6.23852 4.90604 5.29516 4.90604 4.19319C4.90604 3.09121 5.2977 2.14785 6.08104 1.36311C6.86437 0.578375 7.80604 0.186005 8.90604 0.186005C10.006 0.186005 10.9477 0.578375 11.731 1.36311C12.5144 2.14785 12.906 3.09121 12.906 4.19319C12.906 5.29516 12.5144 6.23852 11.731 7.02326C10.9477 7.808 10.006 8.20037 8.90604 8.20037ZM0.906036 16.2147V13.4097C0.906036 12.842 1.05204 12.3201 1.34404 11.8439C1.63537 11.3684 2.0227 11.0054 2.50604 10.7549C3.53937 10.2374 4.58937 9.84899 5.65604 9.58986C6.7227 9.33139 7.80604 9.20216 8.90604 9.20216C10.006 9.20216 11.0894 9.33139 12.156 9.58986C13.2227 9.84899 14.2727 10.2374 15.306 10.7549C15.7894 11.0054 16.1767 11.3684 16.468 11.8439C16.76 12.3201 16.906 12.842 16.906 13.4097V16.2147H0.906036ZM2.90604 14.2111H14.906V13.4097C14.906 13.226 14.8604 13.0591 14.769 12.9088C14.677 12.7585 14.556 12.6417 14.406 12.5582C13.506 12.1074 12.5977 11.7691 11.681 11.5434C10.7644 11.3183 9.83937 11.2058 8.90604 11.2058C7.9727 11.2058 7.0477 11.3183 6.13104 11.5434C5.21437 11.7691 4.30604 12.1074 3.40604 12.5582C3.25604 12.6417 3.13537 12.7585 3.04404 12.9088C2.95204 13.0591 2.90604 13.226 2.90604 13.4097V14.2111ZM8.90604 6.19678C9.45604 6.19678 9.92704 6.00042 10.319 5.60772C10.7104 5.21568 10.906 4.74417 10.906 4.19319C10.906 3.6422 10.7104 3.17069 10.319 2.77865C9.92704 2.38595 9.45604 2.1896 8.90604 2.1896C8.35604 2.1896 7.88537 2.38595 7.49404 2.77865C7.10204 3.17069 6.90604 3.6422 6.90604 4.19319C6.90604 4.74417 7.10204 5.21568 7.49404 5.60772C7.88537 6.00042 8.35604 6.19678 8.90604 6.19678Z"
        fill={props.color || '#1C1B1F'}
      />
    </svg>
  );
};
