import { Box, BoxProps, Circle, Flex } from '@chakra-ui/react';
import { BsCheckLg } from 'react-icons/bs';

interface MilestoneItemProps extends BoxProps {
  icon?: any;
  boxProps?: BoxProps;
  skipTrail?: boolean;
}

const MilestoneItem: React.FC<MilestoneItemProps> = ({
  icon = BsCheckLg,
  boxProps = {},
  skipTrail = false,
  children,
  ...props
}) => {
  return (
    <Flex minH={24} {...props}>
      <Flex flexDir="column" alignItems="center" mr={4} pos="relative">
        <Circle size={12} bg={'gray.500'} opacity={0.07} />
        <Box
          as={icon}
          size="1.25rem"
          color={'gray.500'}
          pos="absolute"
          left="0.875rem"
          top="0.875rem"
        />
        {!skipTrail && (
          <Box w="1px" minH={'80px'} flex={1} bg={'gray.400'} my={1} />
        )}
      </Flex>
      <Box pt={{ base: 1, sm: 3 }} {...boxProps}>
        {children}
      </Box>
    </Flex>
  );
};

export default MilestoneItem;
