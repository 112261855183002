import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { GoalsActionTypes } from '../reducer/goals.reducer';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  goals: 'goals',
  goalsPage: 'goalsPage',
};

export const useGetGoals = (team: string, dispatch: Dispatch<any>) => {
  return useQuery([QUERY_KEYS.goals, team], () => ENDPOINTS.getAllGoals(team), {
    onSuccess: (data) => {
      dispatch({
        type: GoalsActionTypes.GET_GOALS_LIST,
        payload: data?.dto,
      });
    },
  });
};

export const useGoalSettingsMutation = () => {
  return useCustomMutation([QUERY_KEYS.goals], ENDPOINTS.updateGoalSettings);
};

export const useGoalsPage = () => {
  return useQuery([QUERY_KEYS.goalsPage], ENDPOINTS.getGoalsPage);
};
