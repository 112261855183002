export function IgnoreIcon(
  props: React.SVGProps<SVGSVGElement> & { color?: string }
) {
  const { color = 'currentColor', ...rest } = props;

  return (
    <svg
      fill="none"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      style={{ fill: color }}
    >
      <path d="M511.675127 0C229.086213 0 0 229.084914 0 511.675127 0 794.26664 229.086213 1023.350254 511.675127 1023.350254S1023.350254 794.26534 1023.350254 511.675127C1023.350254 229.087513 794.264041 0 511.675127 0z m0 921.015228c-226.07269 0-409.338802-183.267411-409.338802-409.340101s183.267411-409.340102 409.338802-409.340102c226.07399 0 409.340102 183.267411 409.340101 409.340102s-183.267411 409.340102-409.340101 409.340101zM292.385787 438.57868h438.57868V584.771574H292.385787V438.57868z" />
    </svg>
  );
}

export default IgnoreIcon;
