import { Box, Button, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import ErrorImg from '../../../assets/status/500.svg';
import { useNavigate } from 'react-router';
import { getWithExpiry, setWithExpiry } from '../../../utils/utils';

interface FallbackProps {
  componentStack?: string;
  error?: Error;
}

const Fallback: FC<FallbackProps> = ({ componentStack, error }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <Stack h="100vh" w="full" align="center" justify="center">
      <Image src={ErrorImg} height="300px" width="300px" />
      <Text
        fontFamily="heading"
        fontSize="2xl"
        fontWeight="bold"
        color="text.primary"
      >
        Oops! There was an error, Please try again later.
      </Text>
      {error && (
        <Text
          color="error"
          fontFamily="heading"
          fontSize="md"
          fontWeight="medium"
        >
          {error.toString()}
        </Text>
      )}

      {componentStack && (
        <Text
          color="error"
          fontFamily="heading"
          fontSize="md"
          fontWeight="medium"
        >
          StackTrace: {componentStack}
        </Text>
      )}
      <Box>
        <Button
          onClick={() => {
            setTimeout(() => {
              window.location.reload();
            }, 500);

            navigate('/');
          }}
          variant="primary"
          size="sm"
        >
          Go Back
        </Button>
      </Box>
    </Stack>
  );
};

export default Fallback;
