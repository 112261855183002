import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import { components } from 'react-select';
export const CustomizedOption = (props: any) => {
  return (
    <Box borderBottom={'0.5px solid rgba(0, 0, 0, 0.1)'}>
      <components.Option {...props}>
        <Box minH="16px">
          <Text mr={3} fontFamily="heading" fontSize="sm" color="text.primary">
            {props.label}
          </Text>
          {props.label && (
            <Text color="primary" fontSize="xxs" fontFamily="heading">
              {moment(props.data.startDate).format('DD MMM')} -{' '}
              {moment(props.data.endDate).format('DD MMM')}
            </Text>
          )}
        </Box>
      </components.Option>
    </Box>
  );
};
