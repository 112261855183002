import { FC } from 'react';
import styled from 'styled-components';
import ChartViewCard from './ChartViewCard';
import InsightCard from './InsightCard';
import Toolbar from './Toolbar';
import { ISelect } from '../../../customSelect';
import { PeriodType } from '../../../shared/typings';

const BottomSectionContainer = styled.div`
  margin-top: 12px;
  width: 100%;
  margin-bottom: 40px;
`;

interface BottomSectionProps {
  breakdown: ISelect;
  setBreakdown: (br: ISelect) => void;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  selectedTeam: ISelect;
  setSelectedTeam: (team: ISelect) => void;
  isLoading: boolean;
  isFetching: boolean;
  data: any;
  viewToggle: string;
  setViewToggle: (toggle: string) => void;
  repos: string[];
  setRepos: (repos: string[]) => void;
  filterBy: { value: string; label: string };
  setFilterBy: (filter: { value: string; label: string }) => void;
  handelReloadClick?: () => void;
}

const BottomSection: FC<BottomSectionProps> = ({
  breakdown,
  setBreakdown,
  selected,
  setSelected,
  selectedTeam,
  setSelectedTeam,
  isLoading,
  isFetching,
  data,
  viewToggle,
  setViewToggle,
  repos,
  setRepos,
  filterBy,
  setFilterBy,
  handelReloadClick,
}) => {
  return (
    <BottomSectionContainer>
      <Toolbar
        breakdown={breakdown}
        setBreakdown={setBreakdown}
        selected={selected}
        setSelected={setSelected}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        data={data}
        repos={repos}
        setRepos={setRepos}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
      />

      {/* <InsightCard data={data} isLoading={isLoading} /> */}

      <ChartViewCard
        data={data}
        breakdown={breakdown.value}
        isLoading={isLoading}
        isFetching={isFetching}
        selected={selected}
        setSelected={setSelected}
        selectedTeam={filterBy.value === 'repo' ? '' : selectedTeam.value}
        viewToggle={viewToggle}
        setViewToggle={setViewToggle}
        repos={
          filterBy.value === 'team' ? [] : repos.map((el: any) => el.value)
        }
        handelReloadClick={handelReloadClick}
      />
    </BottomSectionContainer>
  );
};

export default BottomSection;
