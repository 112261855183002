export const BookMarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 2.5C2 1.83696 2.26339 1.20107 2.73223 0.732233C3.20108 0.263392 3.83696 0 4.5 0L13.25 0C13.4489 0 13.6397 0.0790176 13.7803 0.21967C13.921 0.360322 14 0.551088 14 0.75V13.25C14 13.4489 13.921 13.6397 13.7803 13.7803C13.6397 13.921 13.4489 14 13.25 14H10.75C10.5511 14 10.3603 13.921 10.2197 13.7803C10.079 13.6397 10 13.4489 10 13.25C10 13.0511 10.079 12.8603 10.2197 12.7197C10.3603 12.579 10.5511 12.5 10.75 12.5H12.5V10.5H4.5C4.30308 10.5 4.11056 10.5582 3.94657 10.6672C3.78257 10.7762 3.65442 10.9312 3.57816 11.1128C3.50191 11.2943 3.48096 11.4943 3.51793 11.6878C3.5549 11.8812 3.64816 12.0594 3.786 12.2C3.85495 12.2704 3.90935 12.3537 3.94611 12.4451C3.98287 12.5365 4.00126 12.6343 4.00024 12.7328C3.99922 12.8313 3.9788 12.9287 3.94016 13.0193C3.90151 13.1099 3.84539 13.1921 3.775 13.261C3.70461 13.3299 3.62133 13.3843 3.52992 13.4211C3.43851 13.4579 3.34075 13.4763 3.24222 13.4752C3.1437 13.4742 3.04634 13.4538 2.95571 13.4152C2.86508 13.3765 2.78295 13.3204 2.714 13.25C2.25571 12.7829 1.99929 12.1544 2 11.5V2.5ZM12.5 1.5H4.5C4.23479 1.5 3.98043 1.60536 3.79289 1.79289C3.60536 1.98043 3.5 2.23478 3.5 2.5V9.208C3.81535 9.0702 4.15586 8.99937 4.5 9H12.5V1.5ZM5 12.25C5 12.1837 5.02634 12.1201 5.07323 12.0732C5.12011 12.0263 5.1837 12 5.25 12H8.75C8.81631 12 8.8799 12.0263 8.92678 12.0732C8.97366 12.1201 9 12.1837 9 12.25V15.5C9 15.5464 8.98707 15.5919 8.96267 15.6314C8.93826 15.6709 8.90333 15.7028 8.86181 15.7236C8.82028 15.7444 8.77379 15.7532 8.72755 15.749C8.68131 15.7448 8.63715 15.7279 8.6 15.7L7.15 14.613C7.10679 14.5804 7.05414 14.5627 7 14.5627C6.94587 14.5627 6.89321 14.5804 6.85 14.613L5.4 15.7C5.36286 15.7279 5.31869 15.7448 5.27245 15.749C5.22621 15.7532 5.17972 15.7444 5.1382 15.7236C5.09667 15.7028 5.06175 15.6709 5.03734 15.6314C5.01293 15.5919 5 15.5464 5 15.5V12.25Z"
        fill={props.color || '#2A2A2F'}
      />
    </svg>
  );
};
