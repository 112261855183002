import { defineMessage, useIntl } from 'react-intl';

import { useMemo } from 'react';

export type MetricsLabelStrings = {
  throughput: string;
  flow: string;
  cycleTime: string;
  dora: string;
  favourite: string;
  productivity: string;
  velocity: string;
  updated: string;
  quality: string;
  wellbeing: string;
};

export const useStrings = () => {
  const throughput = defineMessage({
    defaultMessage: 'Throughput',
    description: 'Text for throughput section',
  });

  const flow = defineMessage({
    defaultMessage: 'Flow',
    description: 'Text for Flow section',
  });

  const cycleTime = defineMessage({
    defaultMessage: 'Cycle Time',
    description: 'Text for cycle time section',
  });

  const dora = defineMessage({
    defaultMessage: 'DORA',
    description: 'Text for dora section',
  });

  const favourite = defineMessage({
    defaultMessage: 'Favorite',
    description: 'Text for favourite section',
  });

  const productivity = defineMessage({
    defaultMessage: 'Productivity',
    description: 'Text for productivity section',
  });
  const velocity = defineMessage({
    defaultMessage: 'Velocity',
    description: 'Text for velocity section',
  });

  const updated = defineMessage({
    defaultMessage: 'Last updated 9 hours ago',
    description: 'Text for last updated section',
  });

  const quality = defineMessage({
    defaultMessage: 'Quality',
    description: 'Text for throughput section',
  });
  const wellbeing = defineMessage({
    defaultMessage: 'Wellbeing',
    description: 'Text for wellbeing section',
  });

  const intl = useIntl();
  const throughputMessage = intl.formatMessage(throughput);
  const flowMessage = intl.formatMessage(flow);
  const cycleTimeMessage = intl.formatMessage(cycleTime);
  const doraMessage = intl.formatMessage(dora);
  const favouriteMessage = intl.formatMessage(favourite);
  const productivityMessage = intl.formatMessage(productivity);
  const velocityMessage = intl.formatMessage(velocity);
  const updatedMessage = intl.formatMessage(updated);
  const qualityMessage = intl.formatMessage(quality);
  const wellbeingMessage = intl.formatMessage(wellbeing);

  return useMemo(
    (): MetricsLabelStrings => ({
      throughput: throughputMessage,
      flow: flowMessage,
      cycleTime: cycleTimeMessage,
      dora: doraMessage,
      favourite: favouriteMessage,
      productivity: productivityMessage,
      velocity: velocityMessage,
      updated: updatedMessage,
      quality: qualityMessage,
      wellbeing: wellbeingMessage,
    }),
    []
  );
};
