import styled from 'styled-components';

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const SwitchLabel = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 11px;
  border-radius: 100px;
  position: relative;
  background-color: rgb(113, 128, 150, 0.3);

  ${(props) =>
    props.disabled &&
    `
  cursor: not-allowed;
  background-color: rgba(113, 128, 150, 0.1);
  `}

  ${SwitchInput}:checked + & {
    background: ${({ theme }) => theme.colors.primary2Bg};
  }
  transition: background-color 0.3s;
`;

const SwitchButton = styled.span<{ disabled?: boolean }>`
  content: '';
  position: absolute;
  top: -3px;
  left: -2px;
  width: 18px;
  height: 18px;
  border-radius: 45px;
  transition: 0.1s;
  background: #718096;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${SwitchInput}:checked + ${SwitchLabel} & {
    background: ${({ theme }) => theme.colors.primary};
  }

  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% + 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 45px;
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    background-color: #b0b0b0;
  `}
`;

interface SwitchProps {
  id?: string;
  toggled: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Switch = ({ id, toggled, disabled, onChange }: SwitchProps) => {
  return (
    <>
      <SwitchInput
        id={id}
        type="checkbox"
        checked={toggled || false}
        onChange={onChange}
      />
      <SwitchLabel htmlFor={id} disabled={disabled}>
        <SwitchButton disabled={disabled} />
      </SwitchLabel>
    </>
  );
};

export default Switch;
