import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';

const getPath = (x: number, y: number, width: number, height: number) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${
    y + height / 3
  } 
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${
    x + width
  }, ${y + height}
  Z`;
};

interface SprintBarChartProps {
  data: any;
}

export default function SprintBarChart({ data }: SprintBarChartProps) {
  const invokeFormat = [
    { name: 'Scope', committed: 0, uncommitted: 0 },

    {
      name: 'Completed',
      committed: 0,
      uncommitted: 0,
    },

    {
      name: 'Spilled',
      committed: 0,
      uncommitted: 0,
    },
  ];

  data?.forEach((item: any) => {
    if (item.name === 'Committed Completed') {
      invokeFormat[1].committed = item.value;
    } else if (item.name === 'Uncommitted Completed') {
      invokeFormat[1].uncommitted = item.value;
    } else if (item.name === 'Committed') {
      invokeFormat[0].committed = item.value;
    } else if (item.name === 'Uncommitted') {
      invokeFormat[0].uncommitted = item.value;
    } else if (item.name === 'Committed Spilled') {
      invokeFormat[2].committed = item.value;
    } else if (item.name === 'Uncommitted Spilled') {
      invokeFormat[2].uncommitted = item.value;
    }
  });

  return (
    <ResponsiveContainer height={'100%'} width={'100%'}>
      <BarChart
        data={invokeFormat}
        margin={{
          top: 20,
          right: 0,
          left: -20,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey="name"
          style={{
            fontSize: 10,
            fontWeight: 500,
          }}
        />
        <YAxis
          style={{
            fontSize: 10,
            fontWeight: 500,
          }}
          axisLine={false}
        />

        <Legend
          iconType="circle"
          iconSize={11}
          align="right"
          wrapperStyle={{
            right: -8,
            fontSize: 11.5,
          }}
        />

        <Tooltip wrapperStyle={{ outline: 'none', borderRadius: '2px' }} />

        <Bar
          dataKey="committed"
          fill="#C2528B"
          name="Planned"
          barSize={16}
          stackId="a"
        ></Bar>

        <Bar
          dataKey="uncommitted"
          fill="#5F50A9"
          barSize={16}
          stackId="a"
          name="Unplanned"
        ></Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
