import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import React from 'react';
import { useActivityLogPage } from '../../api';
import ActivityLogPage from './devd-activity-log';

export const ActivityLog = () => {
  const { data } = useActivityLogPage();

  return (
    <>
      <MetaTags title="Activity log | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <ActivityLogPage data={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default ActivityLog;
