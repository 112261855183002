import * as ENDPOINTS from './endpoints';

import { useMutation } from '@tanstack/react-query';

const QUERY_KEYS = {
  handleSignup: 'handleSignup',
};

export type SignUpProps = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  organizationName: string;
  type: 'EMAIL' | 'GOOGLE' | 'GITHUB';
};

export const useSignUp = () => {
  return useMutation([QUERY_KEYS.handleSignup], ENDPOINTS.handleSignup);
};
