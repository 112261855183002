import { Box, Button, Flex, Icon, Input, Text } from '@chakra-ui/react';
import {
  eliminateHtmlTags,
  useDebounce,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import { useEffect, useState } from 'react';
import { HiMagnifyingGlass } from 'react-icons/hi2';
interface Values {
  searchValue: string;
}
export function SearchBar({
  clearSearchBar,
}: {
  clearSearchBar: null | boolean;
}) {
  const metricFilterBy = useMetricsTableStore();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    if (metricFilterBy.tableType === 'PR') {
      metricFilterBy.setSearchQuery(debouncedSearchTerm);
    } else {
      metricFilterBy.setIssueIds(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (clearSearchBar !== null) {
      setSearchTerm('');
    }
  }, [clearSearchBar]);

  return (
    <Box w={250} alignSelf={'end'}>
      <Flex justify={'center'} align={'center'}>
        <Input
          id="searchValue"
          name="searchValue"
          borderRightRadius={0}
          placeholder={
            metricFilterBy.tableType === 'PR'
              ? 'Search by PR Title'
              : 'Search by Issue ID'
          }
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(eliminateHtmlTags(e.target.value));
          }}
        />
        <Button
          w={10}
          alignSelf={'end'}
          onClick={() => setSearchTerm(searchTerm)}
          borderLeftRadius={0}
        >
          <Icon as={HiMagnifyingGlass} color={'grey'} />
        </Button>
      </Flex>
    </Box>
  );
}

export default SearchBar;
