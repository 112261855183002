import { BiGitPullRequest, BiRocket } from 'react-icons/bi';
import { VscIssueReopened } from 'react-icons/vsc';
import { FiGitCommit } from 'react-icons/fi';
import { BsCheckLg } from 'react-icons/bs';
import { DeployEnum, DeployTypes } from './deploymentDetails.types';
import { CgDanger } from 'react-icons/cg';
import { FaRegCommentDots } from 'react-icons/fa';

export function convertToAllTitleCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

export function getProperIcon(type: string) {
  return type === DeployEnum.issue
    ? VscIssueReopened
    : type === DeployEnum.pr
    ? BiGitPullRequest
    : type === DeployEnum.commit
    ? FiGitCommit
    : type === DeployEnum.comment
    ? FaRegCommentDots
    : type === DeployEnum.incident
    ? CgDanger
    : type === DeployEnum.deployment
    ? BiRocket
    : BsCheckLg;
}

type DeployTypesType = typeof DeployTypes;

export function getValueByKey(
  key: keyof DeployTypesType
): DeployTypesType[keyof DeployTypesType] | null {
  if (key in DeployTypes) {
    return DeployTypes[key];
  }
  return null;
}
