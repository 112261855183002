import { useContext, useEffect, useState } from 'react';
import {
  durationData,
  ISelect,
  Loader,
  PageHeader,
  PageToolbar,
  PeriodType,
  removeEmptyQueryParams,
  useQueryState,
} from '@devd-client/devd/components';
import { Box } from '@chakra-ui/react';
import MainContent from '../components/MainContent';
import { GoalContainer } from '../helpers/goals.styled';
import { useGetGoals } from '../apis';
import { WorkflowAutomationContext } from '../context/workflowAutomation.context';
import { TeamMenu } from '@devd-client/devd/components';
import { Text } from '@chakra-ui/react';
import { useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';
import { useGetWorkflows } from '../apis';
import { GoalsActionTypes } from '../reducer/goals.reducer';

export function GoalsPage() {
  const [appState, ,] = useAuth();
  const { selected, selectedTeam, setSelected, setSelectedTeam } =
    useDashboardStore();

  const [selectedAction, setSelectedAction] = useState<string>('');

  const { dispatch } = useContext(WorkflowAutomationContext);

  const { isLoading } = useGetWorkflows(selectedTeam.value, dispatch);

  const [action, setAction] = useQueryState('action');
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');

  const [periodOptions, setPeriodOptions] = useState(durationData);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Org'
        ? setTeam('Org')
        : setTeam(selectedTeam.value);
    }

    if (typeof selectedAction !== 'undefined') {
      selectedAction === ''
        ? removeEmptyQueryParams({ goal: '' })
        : setAction(selectedAction);
    }
  }, [selected, selectedTeam.value, selectedAction]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Org'
          ? { value: '', label: 'Org' }
          : { value: team, label: team }
      );

    action && setSelectedAction(action);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <>
      <GoalContainer>
        <PageHeader headingText="Automation" />

        <Text
          fontFamily="heading"
          fontSize="sm"
          color="text.secondary"
          mb={'10px'}
        >
          Smart automation that helps your team.
        </Text>

        <PageToolbar
          mb={'20px'}
          leftComp={
            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              hideShowDetails
            />
          }
        />

        <MainContent
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
          selectedAction={selectedAction}
          setSelectedAction={setSelectedAction}
          selected={selected}
          setSelected={setSelected}
          periodOptions={periodOptions}
          setPeriodOptions={setPeriodOptions}
        />
      </GoalContainer>
      {isLoading && <Loader />}
    </>
  );
}

export default GoalsPage;
