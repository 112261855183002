import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

interface DistributionProgressProps {
  data: any;
  showNumber?: boolean;
}

const DistributionProgress: React.FC<DistributionProgressProps> = ({
  data,
  showNumber,
}) => {
  const progressData = data?.keys?.map((item: any) => ({
    name: item.name,
    value: Object.entries(data?.data)
      .filter(([key, _]) => key === item.key)
      .map((el: any) => el[1])[0],
    total: Object.values(data?.data).reduce((a: any, b: any) => a + b, 0),
    color: item.color,
  }));

  return (
    <Box mt={2}>
      <Flex direction="column">
        <Flex
          w="full"
          h="24px"
          borderRadius="2px"
          mb={2}
          overflow="hidden"
          bg="#f1f1f1"
        >
          {progressData?.map((item: any, index: number) => (
            <Tooltip
              hasArrow
              key={`progress-${index}`}
              label={
                showNumber
                  ? `${Number(item.value)}`
                  : `${Number((item.value / item.total) * 100).toFixed(0)}%`
              }
            >
              <Flex
                key={index}
                align="center"
                w={`${Number((item.value / item.total) * 100).toFixed(0)}%`}
                h="100%"
                bg={item.color}
                mr="1px"
              ></Flex>
            </Tooltip>
          ))}
        </Flex>

        <Flex mt="5px" flexWrap="wrap" fontSize="12px">
          {progressData?.map((item: any, index: number) => (
            <Box key={index} display="flex" alignItems="flex-start" mr={3}>
              <Box
                bg={item.color}
                borderRadius="full"
                boxSize="8px"
                mr="4px"
                mt={1}
              />
              <Box>
                <Text color="text.secondary" fontFamily="heading" fontSize="xs">
                  {item.name}
                </Text>
                {item.value && item.value > 0 && (
                  <Text
                    color="text.secondary"
                    fontFamily="heading"
                    fontWeight="semibold"
                    fontSize="xs"
                  >
                    {showNumber
                      ? `${Number(item.value)}`
                      : `${Number((item.value / item.total) * 100).toFixed(
                          0
                        )}%`}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default DistributionProgress;
