const TicketTypeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M5.5 2H18.5C20.43 2 22 3.57 22 5.5v13c0 1.93-1.57 3.5-3.5 3.5H5.5C3.57 22 2 20.43 2 18.5v-13C2 3.57 3.57 2 5.5 2zm0 2C4.67 4 4 4.67 4 5.5v13C4 19.33 4.67 20 5.5 20h13c.83 0 1.5-.67 1.5-1.5v-13c0-.83-.67-1.5-1.5-1.5h-13zM8 17h8v-2H8v2zm0-4h8v-2H8v2zm0-4h8V7H8v2z"
        fill={props.color || '#777'}
      />
    </svg>
  );
};

export default TicketTypeIcon;
