import { Spinner, useTheme } from '@chakra-ui/react';

import { FC } from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
`;

export const Loader: FC = () => {
  const { colors } = useTheme();
  return (
    <LoaderContainer>
      <Spinner
        thickness="6px"
        speed="0.8s"
        emptyColor="gray.200"
        size="xl"
        color={colors.primary}
      />
    </LoaderContainer>
  );
};

export default Loader;
