import { Box, Button, Flex, Icon } from '@chakra-ui/react';
import { MetaTags, PageHeader } from '@devd-client/devd/components';
import CustomMetricsTable from '../components/CustomMetricsTable';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router';

export const CustomMetrics = () => {
  const navigate = useNavigate();
  return (
    <>
      <MetaTags title="Custom Metrics | Settings | Devdynamics" />
      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box mb={4}>
          <PageHeader headingText="Custom Metrics" />
        </Box>

        <Flex width="100%" justify="flex-end">
          <Button
            onClick={() => navigate('add')}
            size="xs"
            variant="primary"
            mb={4}
          >
            <Icon as={FaPlus} mr={2} /> Add Custom Metric
          </Button>
        </Flex>
        <CustomMetricsTable />
      </Box>
    </>
  );
};

export default CustomMetrics;
