import {
  Avatar,
  Box,
  Tooltip,
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Skeleton,
  Button,
} from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip as RechartsTooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import {
  useBurnoutContributorDetail,
  useInsightMetricGql,
} from '../../../graphql';
import {
  AppTooltip,
  InsightIcon,
  PeriodType,
} from '@devd-client/devd/components';
import DetailModal from './DetailModal';
import { useNavigate } from 'react-router';
import { Link as NavLink } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { beforeStyles, hoverBeforeStyles } from '../InsightCards.utils';

interface BurnoutRiskCardProps {
  team: string;
  selected: PeriodType;
}

const BurnoutRiskCard: React.FC<BurnoutRiskCardProps> = ({
  team,
  selected,
}) => {
  const navigate = useNavigate();
  const detailmodal = useDisclosure();
  const { data, isFetching } = useInsightMetricGql(
    'AGGREGATED_ACTIVITY',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const { data: burnOutData } = useBurnoutContributorDetail(
    'AGGREGATED_ACTIVITY',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const itemsToShowInitially = 3;
  const displayedData = data?.data
    ?.slice(0, itemsToShowInitially)
    .map((item: any, index: number) => ({
      ...item,
    }));

  const params = new URLSearchParams(window.location.search);
  const teamId = params.get('team');

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isFetching ? (
        <Skeleton h="248px" w="100%" />
      ) : (
        <Flex
          direction="column"
          p={3}
          w="full"
          minW="340px"
          minH="248px"
          borderRadius="4px"
          border="1px solid #dde0e4"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
        >
          <Flex direction="column" mb={4}>
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {data?.chartMetadata?.chartTitle}
              </Text>

              {data?.chartDataState !== 'ERROR' &&
                data?.chartDataState !== 'NOT_CONFIGURED' && (
                  <Flex
                    align="center"
                    justify="space-between"
                    color="text.secondary"
                    cursor="pointer"
                    position="relative"
                    fontSize="sm"
                    _hover={{ _before: hoverBeforeStyles }}
                    _before={beforeStyles}
                    onClick={() =>
                      navigate(
                        `/metrics/wellbeing?${
                          window.location.href.split('?')[1]
                        }`
                      )
                    }
                  >
                    <Text
                      fontSize="xs"
                      fontWeight="semibold"
                      transition="all 300ms linear"
                      mr={1}
                    >
                      View Details
                    </Text>
                    <BsArrowRight />
                  </Flex>
                )}

              {(data?.chartDataState === 'ERROR' ||
                data?.chartDataState === 'NOT_CONFIGURED') && (
                <AppTooltip
                  trigger="hover"
                  content={
                    <Flex>
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        color="text.primary"
                        mr={1}
                      >
                        {data?.chartError?.message}
                      </Text>
                      <NavLink to={`${data?.chartError?.link}`}>
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </NavLink>
                    </Flex>
                  }
                >
                  <Box>
                    <Button
                      size={'xs'}
                      color={'error'}
                      border={'1px'}
                      borderColor={'error'}
                      backgroundColor={'white'}
                      fontFamily="heading"
                    >
                      {'why no data?'}
                    </Button>
                  </Box>
                </AppTooltip>
              )}
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {data?.chartMetadata?.info}
            </Text>
          </Flex>
          <Box flex={1}>
            {displayedData?.length !== 0 ? (
              <Box>
                <Table borderWidth="1px" borderRadius="md">
                  <Thead>
                    <Tr>
                      <Th px={'12px'} textAlign={'center'}>
                        Name
                      </Th>
                      <Th
                        width="176px"
                        px={'12px'}
                        justifyContent={'space-around'}
                        display={'flex'}
                      >
                        <Tooltip label="Sunday">
                          <Text>S</Text>
                        </Tooltip>
                        <Tooltip label="Monday">
                          <Text>M</Text>
                        </Tooltip>
                        <Tooltip label="Tuesday">
                          <Text>T</Text>
                        </Tooltip>
                        <Tooltip label="Wednesday">
                          <Text>W</Text>
                        </Tooltip>
                        <Tooltip label="Thursday">
                          <Text>T</Text>
                        </Tooltip>
                        <Tooltip label="Friday">
                          <Text>F</Text>
                        </Tooltip>
                        <Tooltip label="Saturday">
                          <Text>S</Text>
                        </Tooltip>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayedData?.map((item: any) => (
                      <Tr key={item.name}>
                        <Td px={'12px'}>
                          <Flex align="center" width={'100%'}>
                            <Avatar
                              size="xs"
                              name={item.name.charAt(0)}
                              bg="gray.500"
                              color="#fff"
                              fontWeight="semibold"
                              fontSize="xs"
                            />
                            <NavLink to={`/contributors/${item.name}`}>
                              <Text
                                ml={2}
                                textDecoration="underline"
                                _hover={{
                                  color: 'primary',
                                  cursor: 'pointer',
                                }}
                                color="text.secondary"
                                fontWeight="medium"
                                fontSize="xs"
                                wordBreak="break-all"
                                cursor="pointer"
                                _after={{
                                  content: 'attr(data-break-chars)',
                                  display: 'inline-block',
                                  wordBreak: 'normal',
                                }}
                              >
                                {item.name}
                              </Text>
                            </NavLink>
                          </Flex>
                        </Td>
                        <Td px={'12px'} width="155px">
                          <ResponsiveContainer width="100%" height={50}>
                            <BarChart
                              data={item.activityCounts}
                              stackOffset="sign"
                              margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                              }}
                            >
                              <XAxis dataKey="label" hide />
                              <RechartsTooltip
                                wrapperStyle={{
                                  outline: 'none',
                                }}
                                position={{ y: -100 }}
                                content={({ payload, label, active }) => {
                                  if (active && payload && payload.length) {
                                    return (
                                      <Box
                                        backgroundColor="#fff"
                                        p={2.5}
                                        borderRadius={4}
                                        boxShadow="0 2px 6px rgba(0, 0, 0, 0.15)"
                                      >
                                        <Text
                                          fontSize="xs"
                                          fontFamily="heading"
                                          color="text.primary"
                                          fontWeight="semibold"
                                        >
                                          {payload[0].payload.day}
                                        </Text>
                                        <Text
                                          fontFamily="heading"
                                          fontSize="11px"
                                          fontWeight="medium"
                                          color="text.secondary"
                                        >
                                          Cumulative Office Hours Activities:{' '}
                                          <Text
                                            as="span"
                                            color="text.primary"
                                            fontWeight="semibold"
                                          >
                                            {payload[0].payload.whActivity}
                                          </Text>
                                        </Text>
                                        <Text
                                          fontFamily="heading"
                                          fontSize="11px"
                                          fontWeight="medium"
                                          color="text.secondary"
                                        >
                                          Cumulative Non-Office Hours
                                          Activities:{' '}
                                          <Text
                                            as="span"
                                            color="text.primary"
                                            fontWeight="semibold"
                                          >
                                            {Math.abs(
                                              payload[0].payload.nwhActivity
                                            )}{' '}
                                          </Text>
                                        </Text>
                                      </Box>
                                    );
                                  }
                                  return null;
                                }}
                              />
                              <ReferenceLine y={0} stroke="#E3E6EA" isFront />
                              <Bar
                                dataKey="whActivity"
                                stackId="stack"
                                fill="#5F50A9"
                                barSize={10}
                                radius={[2, 2, 0, 0]}
                              />
                              <Bar
                                dataKey="nwhActivity"
                                stackId="stack"
                                fill="#FF8D70"
                                barSize={10}
                                radius={[2, 2, 0, 0]}
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </Td>
                      </Tr>
                    ))}
                    {burnOutData?.length > 3 && (
                      <Tr>
                        <Td colSpan={2} textAlign="center">
                          <Box textAlign="center">
                            <Text
                              fontSize="sm"
                              color="primary"
                              cursor="pointer"
                              fontWeight={'semibold'}
                              onClick={detailmodal.onOpen}
                            >
                              {'View more'}
                            </Text>
                          </Box>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>

                <Flex mt={3} flexWrap="wrap" fontSize="12px">
                  <Flex alignItems="center" mr={2}>
                    <Box
                      bg={'#5F50A9'}
                      borderRadius="full"
                      boxSize="8px"
                      mr="2px"
                    />
                    <Text
                      color="text.secondary"
                      fontFamily="heading"
                      fontSize="xs"
                    >
                      Working in office hours
                    </Text>
                  </Flex>
                  <Flex alignItems="center" mr={2}>
                    <Box
                      bg={'#FF8D70'}
                      borderRadius="full"
                      boxSize="8px"
                      mr="2px"
                    />
                    <Text
                      color="text.secondary"
                      fontFamily="heading"
                      fontSize="xs"
                    >
                      Working in non office hours
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            ) : (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Text
                  fontSize="md"
                  fontWeight="semibold"
                  color="text.primary"
                  fontFamily={'heading'}
                  mb={2}
                >
                  No contributor in team {team}.
                </Text>
                <Button
                  onClick={() => navigate(`/settings/teams/${teamId}/member`)}
                >
                  Add Contributors
                </Button>
              </Box>
            )}

            {data?.insights && <Divider m={1} />}

            {data?.insights && (
              <Flex direction="column">
                <Flex align="center">
                  <InsightIcon width="12px" height="12px" />
                  <Text
                    ml={1}
                    fontFamily="heading"
                    fontSize="md"
                    color="text.primary"
                    fontWeight="medium"
                  >
                    Insight
                  </Text>
                </Flex>
                <Flex
                  direction="column"
                  fontSize="sm"
                  fontFamily="heading"
                  color="text.secondary"
                >
                  {data?.insights}
                </Flex>
              </Flex>
            )}
          </Box>

          {detailmodal.isOpen && (
            <DetailModal
              isOpen={detailmodal.isOpen}
              onClose={detailmodal.onClose}
              team={team}
              selected={selected}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default BurnoutRiskCard;
