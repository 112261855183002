import { BottleNeckIcon } from '../icons/BottleNeckIcon';
import { BurnoutIcon } from '../icons/BurnoutIcon';
import { GrowthIcon } from '../icons/GrowthIcon';
import { HygieneIcon } from '../icons/HygieneIcon';
import { PerformanceIcon } from '../icons/PerformanceIcon';
import { ProductivityIcon } from '../icons/ProductivityIcon';
import { RocketIcon } from '../icons/RocketIcon';
import { WinIcon } from '../icons/WinIcon';
import { IconType } from './report.types';

export const getQuestionIcon = (icon: string) => {
  return icon === IconType.rocket ? (
    <RocketIcon height="20px" width="20px" />
  ) : icon === IconType.productivity ? (
    <ProductivityIcon height="20px" width="20px" />
  ) : icon === IconType.burnout ? (
    <BurnoutIcon height="20px" width="20px" />
  ) : icon === IconType.win ? (
    <WinIcon height="20px" width="20px" />
  ) : icon === IconType.bottleneck ? (
    <BottleNeckIcon height="20px" width="20px" />
  ) : icon === IconType.growth ? (
    <GrowthIcon height="20px" width="20px" />
  ) : icon === IconType.hygiene ? (
    <HygieneIcon height="20px" width="20px" />
  ) : icon === IconType.performance ? (
    <PerformanceIcon height="20px" width="20px" />
  ) : undefined;
};

export const fillColors = [
  '#C2528B',
  '#5F50A9',
  '#0D7EA0',
  '#EC4899',
  '#49CC93',
  '#BBBBBC',
  '#FFC532',
  '#80E1D9',
];
