import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  throughputPage: 'throughputPage',
  cycletimePage: 'cycletimePage',
  flowPage: 'flowPage',
  doraPage: 'doraPage',
  qualityPage: 'qualityPage',
  favouritePage: 'favouritePage',
  productivityPage: 'productivityPage',
  velocityPage: 'velocityPage',
  wellbeingPage: 'wellbeingPage',
};

enum MetricPage {
  throughput = 'Throughput',
  cycleTime = 'CycleTime',
  flow = 'Flow',
  dora = 'Dora',
  quality = 'Quality',
  favourite = 'Favourite',
  productivity = 'Productivity',
  velocity = 'Velocity',
  wellbeing = 'Wellbeing',
}

export const useThroughputPage = () => {
  return useQuery([QUERY_KEYS.throughputPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.throughput)
  );
};

export const useCycletimePage = () => {
  return useQuery([QUERY_KEYS.cycletimePage], () =>
    ENDPOINTS.getMetricPages(MetricPage.cycleTime)
  );
};

export const useFlowPage = () => {
  return useQuery([QUERY_KEYS.flowPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.flow)
  );
};

export const useDoraPage = () => {
  return useQuery([QUERY_KEYS.doraPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.dora)
  );
};

export const useQualityPage = () => {
  return useQuery([QUERY_KEYS.qualityPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.quality)
  );
};

export const useFavouritePage = () => {
  return useQuery([QUERY_KEYS.favouritePage], () =>
    ENDPOINTS.getMetricPages(MetricPage.favourite)
  );
};

export const useProductivityPage = () => {
  return useQuery([QUERY_KEYS.productivityPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.productivity)
  );
};

export const useVelocityPage = () => {
  return useQuery([QUERY_KEYS.velocityPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.velocity)
  );
};

export const useWellbeingPage = () => {
  return useQuery([QUERY_KEYS.wellbeingPage], () =>
    ENDPOINTS.getMetricPages(MetricPage.wellbeing)
  );
};
