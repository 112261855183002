import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InitiativesActionTypes } from '../reducers/initiativesReducer';
import {
  getInitiativeChangeLogGraphql,
  getInitiativeContributorsGraphql,
  getInitiativeDetailGraphql,
  getInitiativesGraphql,
  getInitiativeSprintGraphql,
  getInitiativeTaskGraphql,
  getInitiativeTeamsGraphql,
  initiativeProgressGraphql,
  initiativeTaskDistGraphql,
  teamAllocationGraphql,
} from './query';
import { camelCaseToCapitalize } from '../helpers/Initiatives.utils';

export const QUERY_KEYS = {
  initiativeGql: 'initiativeGql',
  initiativeDetailGql: 'initiativeDetailGql',
  initiativeTaskGql: 'initiativeTaskGql',
  initiativeChangeLog: 'initiativeChangeLog',
  initiativeContributos: 'initiativeContributos',
  initiativeSprint: 'initiativeSprint',
  initiativeteams: 'initiativeteams',
  teamAllocationDetail: 'teamAllocationDetail',
  initiativeProgress: 'initiativeProgress',
  initiativeStatus: 'initiativeStatus',
};

export const useInitiativesGql = (
  status: string,
  pageNumber: number,
  pageSize: number,
  searchInput: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.initiativeGql, status, pageNumber, pageSize, searchInput],
    () => getInitiativesGraphql(status, pageNumber, pageSize, searchInput),
    {
      select: (data) => data.Initiatives,
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_DATA,
          payload: data,
        });
      },
    }
  );
};

export const useInitiativesDetailGql = (
  initiative: string,
  pageNumber: number,
  pageSize: number,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.initiativeDetailGql, initiative, pageNumber, pageSize],
    () => getInitiativeDetailGraphql(initiative, pageNumber, pageSize),
    {
      select: (data) => ({
        ...data.InitiativeDetails,
        epicDetails: data.InitiativeDetails.epicDetails.map((el: any) => ({
          ...el,
          name: el.id,
          id: el.id,
          start: new Date(el.startDate),
          end: el.endDate === null ? new Date(el.startDate) : new Date(),
          type: el.type,
          project: el.project,
          displayOrder: el.displayOrder,
          description: el.description,
          url: el.url,
          dependencies: el.dependencies,
          progress: Number(
            (
              (Number(el.totalTasksClosed) / Number(el.totalTasks)) *
              100
            ).toFixed(0)
          ),
        })),
      }),
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_DETAILS,
          payload: data,
        });
      },
      enabled: !!initiative,
    }
  );
};

export const useTaskListGql = (
  initiative: string,
  pageNumber: number,
  pageSize: number,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.initiativeTaskGql, initiative, pageNumber, pageSize],
    () => getInitiativeTaskGraphql(initiative, pageNumber, pageSize),
    {
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_TASK_LIST,
          payload: data?.AllTaskForInitiative,
        });
      },
      enabled: !!initiative,
    }
  );
};

export const useChangeLogGql = (
  initiative: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.initiativeChangeLog, initiative],
    () => getInitiativeChangeLogGraphql(initiative),
    {
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_CHANGE_LOG,
          payload: data?.TicketUpdateLog,
        });
      },
      enabled: !!initiative,
    }
  );
};

export const useContributorsGql = (
  initiative: string,
  dispatch: Dispatch<any>
) => {
  return useQuery(
    [QUERY_KEYS.initiativeContributos, initiative],
    () => getInitiativeContributorsGraphql(initiative),
    {
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_CONTRIBUTORS,
          payload: data?.ContributorsForInitiative,
        });
      },
      enabled: !!initiative,
    }
  );
};

export const useSprintGql = (initiative: string, dispatch: Dispatch<any>) => {
  return useQuery(
    [QUERY_KEYS.initiativeSprint, initiative],
    () => getInitiativeSprintGraphql(initiative),
    {
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_SPRINT,
          payload: data?.SprintsForInitiative,
        });
      },
      enabled: !!initiative,
    }
  );
};

export const useTeamsGql = (initiative: string, dispatch: Dispatch<any>) => {
  return useQuery(
    [QUERY_KEYS.initiativeteams, initiative],
    () => getInitiativeTeamsGraphql(initiative),
    {
      onSuccess: (data) => {
        dispatch({
          type: InitiativesActionTypes.INITIATIVE_TEAMS,
          payload: data?.TeamsForInitiative,
        });
      },
      enabled: !!initiative,
    }
  );
};

export const useTeamAllocationDetailGql = (initiative: any, team: any) => {
  return useQuery(
    [QUERY_KEYS.teamAllocationDetail, initiative, team],
    () => teamAllocationGraphql(initiative, team),
    {
      select: (data) => ({
        ...data,
      }),
      enabled: !!initiative,
    }
  );
};

export const useInitiativeProgressGql = (initiativeId: any) => {
  return useQuery(
    [QUERY_KEYS.initiativeProgress, initiativeId],
    () => initiativeProgressGraphql(initiativeId),
    {
      select: (data) => ({
        ...data.InitiativeTaskProgress,
      }),
      enabled: !!initiativeId,
    }
  );
};

export const useInitiativeTaskDistGql = (initiativeId: any) => {
  return useQuery(
    [QUERY_KEYS.initiativeStatus, initiativeId],
    () => initiativeTaskDistGraphql(initiativeId),
    {
      select: (data) => ({
        ...data.InitiativeTaskStatusDistribution,
      }),
      enabled: !!initiativeId,
    }
  );
};
