import { Box, Image } from '@chakra-ui/react';
import { ISelect, PeriodType } from '@devd-client/devd/components';
import { FC } from 'react';
import styled from 'styled-components';
import PieChartData from './PieChartData';
import StackBarChart from './StackBarChart';
import { useReportsStore } from '../../../../../../store/index';

const ChartCardContainer = styled.div`
  display: flex;
  height: 284px;
  width: 100%;
  background-color: #fff;
  padding: 12px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 16px;
`;

const ChartHeading = styled.h2`
  font-family: 'Inter';
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
`;

interface ChartCardProps {
  selected: PeriodType;
  item: any;
  selectedTeam: string;
}

const ChartCard: FC<ChartCardProps> = ({ selected, item, selectedTeam }) => {
  const { zeroStates } = useReportsStore();

  return (
    <ChartCardContainer>
      {item.zeroState ? (
        zeroStates
          .filter(
            (el: { type: string; url: string }) => el.type === item.zeroState
          )
          .map((item: { type: string; url: string }) => (
            <Image
              loading="lazy"
              boxSize="100%"
              objectFit="contain"
              src={item.url}
              alt="Metric images"
            />
          ))
      ) : (
        <>
          <Box w={'70%'}>
            <ChartHeading>Investment distribution</ChartHeading>
            <StackBarChart selected={selected} selectedTeam={selectedTeam} />
          </Box>

          <Box w={'30%'} h={'100%'}>
            <PieChartData selected={selected} selectedTeam={selectedTeam} />
          </Box>
        </>
      )}
    </ChartCardContainer>
  );
};

export default ChartCard;
