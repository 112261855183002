import { Navigate, useNavigate } from 'react-router';
import { apiClient } from '@devd-client/api';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Img,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  GoogleIcon,
  Loader,
  PasswordInput,
  useToastHook,
} from '@devd-client/devd/components';
import {
  GoogleAuthProvider,
  getRedirectResult,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { auth } from '../../helpers/auth';
import { useAuth } from '../../context/devd-auth';
import { FIREBASE_ERRORS } from '../../helpers/firebaseErrors';
import { useSearchParams } from 'react-router-dom';
import { FormLink } from './register.styles';

const initialValues = {
  email: '',
  orgId: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(12, 'Password must be at least 12 characters.')
    .max(128, 'Password must be lower than 128 characters.')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*?\b(?:password|admin|user)\b).*$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    )
    .required('Password is required.'),
});

export function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [newToast] = useToastHook();

  const [data, setData] = useState<any>('');
  const [message, setMessage] = useState<any>('');

  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('code');

  const [appState] = useAuth();
  const navigate = useNavigate();

  const handleLogin = (email: string, password: string) => {
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoading(false);
        setRedirect(true);
      })
      .catch((error) => {
        setIsLoading(false);
        newToast({
          message:
            FIREBASE_ERRORS[error?.message as keyof typeof FIREBASE_ERRORS] ||
            error?.message,
          status: 'error',
        });
      });
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      apiClient(`/v1/org/${orgId}/root-user`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res: any) => {
          setIsLoading(false);
          if (res) {
            setData(res);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.code === '302 FOUND') {
            navigate('/account/login');
          }
          setMessage(err.message ?? 'Error in getting email/orgId');
        });
    } catch (err) {
      setIsLoading(false);
    }
  }, [orgId]);

  const OnSignUp = (email: any, password: any, orgId: any) => {
    try {
      setIsLoading(true);
      apiClient(`/v1/org/${orgId}/root-user`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailId: email,
          orgCode: orgId,
          password: password,
        }),
      })
        .then((res: any) => {
          setIsLoading(false);
          newToast({
            message: 'Signup successful.',
            status: 'success',
          });
          setTimeout(() => {
            handleLogin(email, password);
          }, 1000);
        })
        .catch((err) => {
          setIsLoading(false);
          newToast({
            message:
              err.message ??
              'Set password failed. Contact DevDynamice support.',
            status: 'error',
          });
        });
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleGoogleLoginWithOutRootUserCall = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider)
      .then(() => {
        setIsLoading(false);
        newToast({
          message: `Login successful`,
          status: 'success',
        });
        setRedirect(true);
      })
      .catch((err) => {
        setIsLoading(false);
        newToast({
          message: `${err.message}`,
          status: 'error',
        });
      });
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider).then((result) => {
      apiClient(`/v1/org/${orgId}/root-user`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailId: data?.dto?.emailId,
          orgCode: orgId,
          authId: result?.user?.uid,
        }),
      })
        .then((res: any) => {
          setIsLoading(false);
          newToast({
            message: 'Signup successful.',
            status: 'success',
          });
          auth?.currentUser?.getIdToken(true);
          setTimeout(() => {
            navigate('/account/login');
          }, 1000);
        })
        .catch((err) => {
          setIsLoading(false);
          newToast({
            message: err.message ?? 'Signup Fail. Contact DevDynamice support.',
            status: 'error',
          });
        });
    });
  };

  return (
    <>
      {appState.user && <Navigate to="/" />}
      {redirect === true && !appState.user && <Loader />}

      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          OnSignUp(
            (values.email = data?.dto?.emailId),
            values.password,
            (values.orgId = data?.dto?.orgCode)
          );
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { isSubmitting, handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit as any}>
              <Flex align="center" justify="center">
                <Text fontFamily="heading" fontSize="sm" color="text.primary">
                  Hi
                </Text>
                <Text
                  ml={2}
                  as="span"
                  color="text.primary"
                  fontWeight="semibold"
                  fontFamily="heading"
                >
                  {data && data?.dto?.firstName}{' '}
                </Text>
              </Flex>

              <Text
                textAlign={'center'}
                fontFamily="heading"
                fontSize="sm"
                color="text.primary"
                mb={4}
              >
                Please set account password to finish your registration.
              </Text>

              {message && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  <AlertDescription>{message}</AlertDescription>
                </Alert>
              )}

              <Stack spacing={4}>
                <PasswordInput
                  name="password"
                  label="Password"
                  placeholder="Password"
                />

                <Button
                  isDisabled={isSubmitting || !data?.dto?.emailId}
                  variant="tertiary"
                  w="full"
                  fontSize="sm"
                  type="submit"
                  onClick={() => {
                    window.analytics.track('user_auth', {
                      status: 'init',
                    });
                  }}
                >
                  Sign Up
                </Button>

                <Button
                  color={'text.primary'}
                  isDisabled={isSubmitting}
                  spinnerPlacement="start"
                  variant={'secondary'}
                  w="full"
                  mb={5}
                  onClick={handleGoogleLogin}
                >
                  <GoogleIcon height="20px" width="20px" />
                  &nbsp; Login With Google
                </Button>

                <Box>
                  <Text
                    fontFamily="heading"
                    fontSize="md"
                    fontWeight="normal"
                    textAlign={'center'}
                    color="text.primary"
                    mt={4}
                  >
                    Already have an account?
                    <FormLink to={'/account/login'}> Login</FormLink>
                  </Text>

                  <Text
                    align={'center'}
                    fontSize={'xs'}
                    fontFamily="heading"
                    color="text.secondary"
                    mt={'12px'}
                  >
                    By continuing, you agree to our{' '}
                    <Link
                      href={'https://devdynamics.ai/terms'}
                      target="_blank"
                      color="primary"
                    >
                      Terms{' '}
                    </Link>{' '}
                    of Service and{' '}
                    <Link
                      href={'https://devdynamics.ai/privacy'}
                      target="_blank"
                      color="primary"
                    >
                      Privacy{' '}
                    </Link>{' '}
                    Policy.
                  </Text>
                </Box>
              </Stack>
            </form>
          );
        }}
      </Formik>

      {isLoading && <Loader />}
    </>
  );
}

export default Register;
