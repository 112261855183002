import { createContext, useReducer } from 'react';
import {
  ThroughputAction,
  throughputReducer,
  ThroughputState,
} from '../reducers/thoughputReducer';

const initialState = {
  dataOpenPR: [],
  dataMergedPR: [],
  dataPRChange: [],
  dataPRReview: [],
  dataTasks: [],
  dataBugs: [],
};
export const ThroughputContext = createContext<{
  state: ThroughputState;
  dispatch: React.Dispatch<ThroughputAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const ThroughputProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useReducer(throughputReducer, initialState);

  return (
    <ThroughputContext.Provider value={{ state, dispatch }}>
      {children}
    </ThroughputContext.Provider>
  );
};
