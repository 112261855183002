import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import { FC } from 'react';
import { IconButton, useTheme } from '@chakra-ui/react';
import moment from 'moment';
import { Week, WeekNavigatorContainer } from './WeekNavigator.styled';

interface WeekNavigator {
  handlePrevClick: () => void;
  handleNextClick: () => void;
  weekArray: string[];
}
const WeekNavigator: FC<WeekNavigator> = ({
  handlePrevClick,
  handleNextClick,
  weekArray,
}) => {
  const {
    colors: { text },
  } = useTheme();
  return (
    <WeekNavigatorContainer>
      <IconButton
        bg="transparent"
        isRound
        size="xs"
        aria-label="left icon"
        color="#2A4365"
        icon={<AiOutlineLeft />}
        onClick={handlePrevClick}
      />

      <Week color={text.primary}>{`${moment(weekArray[0]).format(
        'DD MMM'
      )} - ${moment(weekArray[13]).format('DD MMM')}`}</Week>

      <IconButton
        bg="transparent"
        isRound
        size="xs"
        aria-label="left icon"
        color="#2A4365"
        icon={<AiOutlineRight />}
        onClick={handleNextClick}
      />
    </WeekNavigatorContainer>
  );
};

export default WeekNavigator;
