import { FC } from 'react';
import styled from 'styled-components';
import DetailCards from './DetailCards';
import GanttChart from './GanttChart';

const OverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface OverviewProps {
  selectedProjectId: string;
}

const Overview: FC<OverviewProps> = ({ selectedProjectId }) => {
  return (
    <OverviewContainer>
      {/* <DetailCards /> */}
      <GanttChart selectedProjectId={selectedProjectId} />
    </OverviewContainer>
  );
};

export default Overview;
