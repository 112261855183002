import { useCustomMutation } from '../../hooks/useCustomMutation';
import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEYS = {
  metricsList: 'metricsList',
  customDashboards: 'customDashboards',
};

export const useMetricsListData = (teamName: any) => {
  const queryKey = teamName
    ? [QUERY_KEYS.metricsList, teamName]
    : [QUERY_KEYS.metricsList];

  return useQuery(queryKey, () => {
    if (teamName) {
      return ENDPOINTS.getTeamMetricsList(teamName);
    } else {
      return ENDPOINTS.getMetricsList();
    }
  });
};

export const useUpdateCustomDashboard = () =>
  useCustomMutation(
    [QUERY_KEYS.customDashboards],
    ENDPOINTS.updateCustomDashboard
  );
