import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface ReportsState {
  zeroStates: { type: string; url: string }[];
  setZeroStates: (zeroState: { type: string; url: string }[]) => void;
}

export const useReportsStore = create<ReportsState>()(
  persist(
    (set) => ({
      zeroStates: [],
      setZeroStates: (zeroStates: { type: string; url: string }[]) =>
        set({ zeroStates }),
    }),
    {
      name: 'reports-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
