import { Flex, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface InsightCardItemProps {
  insightItem: {
    count: number;
    title: string;
    subTitle: string;
    link: string;
    category: string;
  };
}

const InsightCardItem: React.FC<InsightCardItemProps> = ({ insightItem }) => {
  return (
    <>
      <Flex align="center" gap={6} justify="space-between">
        <Flex align="center">
          {insightItem?.link ? (
            <Tooltip
              aria-label="tooltip"
              hasArrow
              placement="top"
              label={insightItem?.subTitle}
            >
              <Text
                onClick={(e) => e.stopPropagation()}
                as={Link}
                target="_blank"
                to={insightItem?.link}
                fontSize="10pt"
                fontFamily="body"
                color="primary"
                fontWeight="semibold"
                textDecoration="underline"
              >
                {insightItem?.title}
              </Text>
            </Tooltip>
          ) : (
            <Text
              fontSize="10pt"
              fontFamily="body"
              color="text.secondary"
              fontWeight="semibold"
            >
              {insightItem?.title}
            </Text>
          )}
        </Flex>

        <Text
          fontSize="sm"
          fontWeight="semibold"
          fontFamily="body"
          color="primary"
        >
          {insightItem?.count}
        </Text>
      </Flex>
    </>
  );
};

export default InsightCardItem;
