export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52" cy="52" r="52" fill="#E2B93B" fill-opacity="0.2" />
      <circle cx="52" cy="52" r="39" fill="#E2B93B" fill-opacity="0.4" />
      <circle cx="52" cy="52" r="27" fill="#E2B93B" />
      <g clipPath="url(#clip0_35_11)">
        <path
          d="M62.1156 64.6344H41.8844C40.6859 64.6344 39.7516 64.1672 39.3047 63.2938C38.8375 62.4204 38.9187 61.3235 39.5281 60.186L49.6437 41.1532C50.2531 40.0157 51.0859 39.386 52 39.386C52.9141 39.386 53.7672 40.0157 54.3562 41.1532L64.4719 60.186C65.0812 61.3235 65.1625 62.4204 64.6953 63.2938C64.2484 64.1469 63.3344 64.6344 62.1156 64.6344ZM52 40.6047C51.4922 40.6047 51.0047 41.011 50.6187 41.7625L40.5031 60.7954C40.1172 61.5469 40.0359 62.1969 40.2797 62.6844C40.5234 63.1516 41.1125 63.4157 41.8844 63.4157H62.1156C62.9078 63.4157 63.4766 63.1516 63.7203 62.6844C63.9641 62.2172 63.8828 61.5469 63.4969 60.7954L53.3812 41.7422C52.9953 41.011 52.5078 40.6047 52 40.6047ZM50.9437 47.9579C50.9437 47.9579 50.1109 55.3516 52 55.3516C53.8891 55.3516 53.0562 47.9579 53.0562 47.9579C53.0562 47.3282 52.5891 46.8204 52 46.8204C51.4312 46.8 50.9437 47.3282 50.9437 47.9579ZM52 59.9625C52.8125 59.9625 53.4828 59.2516 53.4828 58.3579C53.4828 57.4641 52.8328 56.7532 52 56.7532C51.1875 56.7532 50.5172 57.4641 50.5172 58.3579C50.5172 59.2516 51.1875 59.9625 52 59.9625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_11">
          <rect
            width="26"
            height="26"
            fill="white"
            transform="translate(39 39)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
