export function cursorClick(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_8001_366)">
        <path
          d="M12.7407 6.94601C12.7407 3.74571 10.1463 1.15137 6.94601 1.15137C3.74571 1.15137 1.15137 3.74571 1.15137 6.94601C1.15137 10.1463 3.74571 12.7407 6.94601 12.7407"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.36119 5.89286C8.95475 4.96261 8.02651 4.3125 6.94643 4.3125C5.49175 4.3125 4.3125 5.49175 4.3125 6.94643C4.3125 8.02651 4.96261 8.95475 5.89286 9.36119"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.5671 10.2683C14.6557 10.2314 14.7129 10.215 14.7655 10.1624C14.818 10.1099 14.8477 10.0386 14.8477 9.96423C14.8477 9.88988 14.818 9.81857 14.7655 9.766C14.7129 9.71343 14.6951 9.68192 14.5671 9.64439C14.4391 9.60684 8.2155 7.50289 8.2155 7.50289C8.11619 7.46891 8.00932 7.46345 7.90705 7.48713C7.80479 7.51083 7.71123 7.56271 7.63699 7.63688C7.56277 7.71105 7.51085 7.80454 7.48714 7.90673C7.46345 8.00892 7.4689 8.1157 7.5029 8.21494C7.5029 8.21494 9.65662 14.4822 9.68293 14.5673C9.70924 14.6525 9.74319 14.713 9.79579 14.7656C9.84841 14.8182 9.91976 14.8477 9.99418 14.8477C10.0686 14.8477 10.1399 14.8182 10.1926 14.7656C10.2452 14.713 10.2572 14.6832 10.2966 14.5673C10.3361 14.4514 11.3969 11.3939 11.3969 11.3939C11.3969 11.3939 14.4786 10.3053 14.5671 10.2683Z"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8001_366">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
