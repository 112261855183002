import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useDoraPage } from '../../api';
import DoraPage from './devd-dora';

export const Dora = () => {
  const { data } = useDoraPage();
  return (
    <>
      <MetaTags title="DORA | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <DoraPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Dora;
