import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getIssueDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueDistribution(
        $metric: String!
        $granularity: String!
        $breakdown: String
        $filter: Filter!
      ) {
        IssueDistribution(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
          }
          averageWip
          previousPeriodStat {
            percentChange
            improved
            reason
          }
          data {
            date
            open
            closed
            inProgress
          }
        }
      }
    `,
    {
      metric: 'CUMULATIVE_FLOW',
      granularity: '',
      breakdown: '',
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        project: '',
        searchTerm: '',
        reviewer: '',
        author: '',
        sprintId: sprintId,
      },
    }
  );

export const getIssueCycleTimeGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueCycleTime(
        $metric: String!
        $granularity: String!
        $breakdown: String
        $filter: Filter!
      ) {
        IssueCycleTime(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartTitle
            xlabel
            ylabel
          }
          units {
            label
            value
            unitId
          }
        }
      }
    `,
    {
      metric: 'ISSUE_CYCLE_TIME_SCATTER',
      granularity: '',
      breakdown: '',
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        project: '',
        searchTerm: '',
        reviewer: '',
        author: '',
        sprintId: sprintId,
      },
    }
  );

export const getAgingDistributionGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAgingDistribution(
        $metric: String!
        $granularity: String!
        $breakdown: String
        $filter: Filter!
      ) {
        AgingDistribution(
          metric: $metric
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
          }
          average
          units {
            label
            value
            unitId
          }
        }
      }
    `,
    {
      metric: '',
      granularity: '',
      breakdown: '',
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        project: '',
        searchTerm: '',
        reviewer: '',
        author: '',
        sprintId: sprintId,
      },
    }
  );

export const cycleTimeProgressGraphql = async (
  startDate: string,
  endDate: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAllCycleTimeMetricsByType(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
      ) {
        AllCycleTimeMetricsByType(
          metric: "CYCLE_TIME_ALL"
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
          }
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
          }
          data
          keys {
            key
            name
            color
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      teamId,
    }
  );
