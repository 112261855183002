import styled from 'styled-components';

export type TabProps = {
  borderB?: string;
  selected?: boolean;
};

export type DatePickerContainerProps = {
  width?: string;
};

export const Wrapper = styled.div`
  position: relative;
`;

export const DayTabsContainer = styled.div`
  display: flex;
`;

export const Tab = styled.p<TabProps>`
  padding: 1px;
  font-size: 12px;
  font-family: 'Inter';
  color: ${({ selected }) => (selected ? '#000' : '#999999')};
  border-bottom: ${({ selected, borderB }) =>
    selected && `2px solid ${borderB}`};
  margin-left: 16px;
  margin-right: 8px;
  cursor: pointer;
  align-self: center;
`;

export const DatePickerContainer = styled.div<DatePickerContainerProps>`
  position: absolute;
  top: -120;
  right: 0;
  z-index: 1000;
  width: ${({ width }) => width || '330px'};
  border-radius: 4px;
`;
