export function Home2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.99998 14V9.06667C5.99998 8.6933 5.99998 8.50661 6.07264 8.36401C6.13656 8.23857 6.23855 8.13658 6.36399 8.07266C6.5066 8 6.69328 8 7.06665 8H8.93332C9.30668 8 9.49337 8 9.63598 8.07266C9.76142 8.13658 9.8634 8.23857 9.92732 8.36401C9.99998 8.50661 9.99998 8.6933 9.99998 9.06667V14M1.33331 6.33333L7.35998 1.81333C7.58949 1.6412 7.70424 1.55514 7.83027 1.52196C7.94152 1.49268 8.05845 1.49268 8.16969 1.52196C8.29572 1.55514 8.41048 1.6412 8.63998 1.81333L14.6666 6.33333M2.66665 5.33333V11.8667C2.66665 12.6134 2.66665 12.9868 2.81197 13.272C2.9398 13.5229 3.14378 13.7268 3.39466 13.8547C3.67988 14 4.05325 14 4.79998 14H11.2C11.9467 14 12.3201 14 12.6053 13.8547C12.8562 13.7268 13.0602 13.5229 13.188 13.272C13.3333 12.9868 13.3333 12.6134 13.3333 11.8667V5.33333L9.27998 2.29333C8.82097 1.94907 8.59147 1.77695 8.33941 1.71059C8.11691 1.65202 7.88305 1.65202 7.66056 1.71059C7.4085 1.77695 7.17899 1.94907 6.71998 2.29333L2.66665 5.33333Z"
        stroke={props.color || '#4D4DFF'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
