import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { QUERY_KEYS, useFavouritePage } from '../../api';
import FavouritePage from './devd-favourite';

export const Favourite = () => {
  const { data } = useFavouritePage();
  const favouriteMetrics = data?.dto?.metrics;

  return (
    <>
      <MetaTags title="Favourites | Metrics | Devdynamics" />
      {data && data?.dto?.enabled && favouriteMetrics?.length !== 0 ? (
        <FavouritePage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
          queryKeys={QUERY_KEYS}
        />
      )}
    </>
  );
};

export default Favourite;
