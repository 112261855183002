export const sourceOptions = [{ label: 'Jira', value: 'jira' }];

export const unitOptions = [
  { label: 'Number', value: 'number' },
  { label: 'Time', value: 'time' },
];

export const functionOptions = [
  { label: 'Sum', value: 'SUM' },
  { label: 'Average', value: 'AVERAGE' },
  { label: 'Count', value: 'COUNT' },
];

export const chartTypeOptions = [
  { label: 'Line Chart', value: 'LINE_CHART' },
  { label: 'Bar Chart', value: 'BAR_CHART' },
];
