import styled from 'styled-components';

type PageToolbarContainerProps = {
  mb?: string;
  mt?: string;
};

export const PageToolbarContainer = styled.div<PageToolbarContainerProps>`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => mb};
  margin-top: ${({ mt }) => mt};
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: flex-end;
`;
