import { apiClient } from '@devd-client/api';
import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export interface TeammenuResponse {
  currentPage: number;
  dto: string[];
  error: any;
  totalItems: number;
  totalPages: number;
}

export const getIntegrationDetails = (): Promise<any> =>
  apiClient(`/v1/integration/org/${localStorage.getItem('orgId')}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getTeammenu = (): Promise<any> =>
  apiClient(`/v2/team/${localStorage.getItem('orgId')}/lite`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const postFavTeam = (params: any) => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/team`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const deleteFavTeam = (params: any) => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/team/${params.name}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const getAllRepos = (source: string): Promise<any> =>
  apiClient(
    `/v1/integration/${source}/org/${localStorage.getItem(
      'orgId'
    )}/projects/lite?active=true`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getContributorDetails = (email: string): Promise<any> =>
  apiClient(`/v1/org/${localStorage.getItem('orgId')}/contributor/${email}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });
export const getIssueTypePriorityOptions = (): Promise<any> =>
  apiClient(`/v1/issue/org/${localStorage.getItem('orgId')}/metadata`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getContributorGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorDetails(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
      ) {
        ContributorDetails(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
        ) {
          data {
            name
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      teamId,
    }
  );

export const getPrDetailsPage = (): Promise<any> =>
  apiClient(`/v2/account/org/${localStorage.getItem('orgId')}/page/PRDetail`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getIssueDetailsPage = (): Promise<any> =>
  apiClient(
    `/v2/account/org/${localStorage.getItem('orgId')}/page/IssueDetail`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
