export const IntegrationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_7401_4220)">
        <path
          d="M3.77049 12.2298C4.8805 13.3398 6.68017 13.3398 7.79017 12.2298L8.78937 11.2307L4.76967 7.21094L3.77049 8.21014C2.66049 9.32014 2.66049 11.1198 3.77049 12.2298Z"
          stroke={props.color || '#626266'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.2766 3.72313C11.1666 2.61312 9.36695 2.61312 8.25695 3.72313L7.25781 4.7223L11.2775 8.74199L12.2766 7.74279C13.3867 6.63281 13.3867 4.83314 12.2766 3.72313Z"
          stroke={props.color || '#626266'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.37137 5.83594L7.30811 6.89925"
          stroke={props.color || '#626266'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.1639 7.62891L9.10059 8.69217"
          stroke={props.color || '#626266'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.77014 12.2305L0.617188 15.3835"
          stroke={props.color || '#626266'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.3827 0.617188L12.2769 3.72306"
          stroke={props.color || '#626266'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7401_4220">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
