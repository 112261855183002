import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
} from '@chakra-ui/react';
import TableBarChart from './TableBarChart';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  pipeline?: string;
};

const TableBarChartModal = ({ isOpen, onClose, data, pipeline }: Props) => {
  return (
    <Modal
      size="6xl"
      isCentered
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Stages Avg Duration - {pipeline}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex height="460px" width="1152">
            <TableBarChart data={data} showLabel />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TableBarChartModal;
