import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const useSetUrl = (
  selected?: any,
  selectedTeam?: string,
  open?: string,
  breakdown?: string,
  show?: string,
  projectTab?: string,
  status?: string,
  search?: string,
  week?: string,
  manager?: string,
  id?: string,
  goal?: string
): null => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchBarParams, setSearchBarParams] = useSearchParams();
  useEffect(() => {
    handleURLChange();
  }, [
    selected,
    selectedTeam,
    open,
    breakdown,
    show,
    projectTab,
    status,
    search,
    week,
    manager,
    id,
    goal,
  ]);

  const handleURLChange = () => {
    navigate(location.pathname);

    if (typeof selected !== 'undefined') {
      selected.startDate && searchBarParams.set('start', selected.startDate);

      selected.endDate && searchBarParams.set('end', selected.endDate);

      selected.duration && searchBarParams.set('duration', selected.duration);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam === ''
        ? searchBarParams.delete('team')
        : searchBarParams.set('team', selectedTeam);
    }

    if (typeof open !== 'undefined') {
      open && searchBarParams.set('open', open);
    }

    if (typeof breakdown !== 'undefined') {
      breakdown === ''
        ? searchBarParams.delete('b')
        : searchBarParams.set('b', breakdown);
    }

    if (typeof show !== 'undefined') {
      show && searchBarParams.set('show', show);
    }

    if (typeof projectTab !== 'undefined') {
      projectTab === ''
        ? searchBarParams.delete('tab')
        : searchBarParams.set('tab', projectTab);
    }

    if (typeof status !== 'undefined') {
      status === ''
        ? searchBarParams.delete('status')
        : searchBarParams.set('status', status);
    }

    if (typeof search !== 'undefined') {
      search === ''
        ? searchBarParams.delete('search')
        : searchBarParams.set('search', search);
    }

    if (typeof week !== 'undefined') {
      week && searchBarParams.set('week', week);
    }

    if (typeof manager !== 'undefined') {
      manager === ''
        ? searchBarParams.delete('manager')
        : searchBarParams.set('manager', manager);
    }

    if (typeof id !== 'undefined') {
      searchBarParams.set('id', id);
    }

    if (typeof goal !== 'undefined') {
      goal === ''
        ? searchBarParams.delete('goal')
        : searchBarParams.set('goal', goal);
    }

    setSearchBarParams(searchBarParams);
  };
  return null;
};

export default useSetUrl;
