export function OutlookIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 190 165"
      id="outlook"
      {...props}
    >
      <defs>
        <filter
          id="d"
          width="100.7%"
          height="101%"
          x="-.4%"
          y="-.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.16 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="h"
          width="101.6%"
          height="101.2%"
          x="-.8%"
          y="-.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dx="1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.17 0"
          ></feColorMatrix>
          <feOffset
            dx="2"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner3"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner3"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner3"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner3"
            result="shadowMatrixInner3"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.01 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
            <feMergeNode in="shadowMatrixInner3"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="m"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.08 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="q"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dx="2"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.01 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="u"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dx="2"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.01 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="y"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dx="2"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.01 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="C"
          width="102.4%"
          height="102.7%"
          x="-1.2%"
          y="-1.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.11 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="G"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation=".5"
          ></feGaussianBlur>
          <feOffset
            dy="1"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.21 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="K"
          width="102.4%"
          height="102.7%"
          x="-1.2%"
          y="-1.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="O"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.19 0"
          ></feColorMatrix>
          <feOffset
            dx="2"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.01 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="S"
          width="104.8%"
          height="105.4%"
          x="-2.4%"
          y="-2.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="2"
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          ></feColorMatrix>
        </filter>
        <filter
          id="W"
          width="118.7%"
          height="117.6%"
          x="-9.3%"
          y="-8.8%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation="5"
          ></feGaussianBlur>
          <feOffset
            dx="4"
            dy="-3"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.37 0"
          ></feColorMatrix>
          <feOffset
            dx="-1"
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.16 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="aa"
          width="102.9%"
          height="104.9%"
          x="-1.4%"
          y="-2.5%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dx="-4"
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.17 0"
          ></feColorMatrix>
          <feOffset
            dx="-1"
            dy="-1"
            in="SourceAlpha"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.16 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="ac"
          width="117%"
          height="117%"
          x="-8.5%"
          y="-8.5%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="3"></feGaussianBlur>
        </filter>
        <filter
          id="aj"
          width="103.1%"
          height="103.1%"
          x="-1.6%"
          y="-1.5%"
          filterUnits="objectBoundingBox"
        >
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner1"
            stdDeviation=".5"
          ></feGaussianBlur>
          <feOffset
            dx="-1"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
            values="0 0 0 0 0   0 0 0 0 0.466666667   0 0 0 0 0.88627451  0 0 0 1 0"
          ></feColorMatrix>
          <feGaussianBlur
            in="SourceAlpha"
            result="shadowBlurInner2"
            stdDeviation=".5"
          ></feGaussianBlur>
          <feOffset
            dx="2"
            in="shadowBlurInner2"
            result="shadowOffsetInner2"
          ></feOffset>
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
            result="shadowInnerInner2"
          ></feComposite>
          <feColorMatrix
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
            values="0 0 0 0 0   0 0 0 0 0.4   0 0 0 0 0.737254902  0 0 0 1 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixInner1"></feMergeNode>
            <feMergeNode in="shadowMatrixInner2"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          id="al"
          width="139.6%"
          height="137.5%"
          x="-19.8%"
          y="-16.8%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          ></feColorMatrix>
          <feOffset
            dy="1"
            in="SourceAlpha"
            result="shadowOffsetOuter2"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter2"
            result="shadowBlurOuter2"
            stdDeviation=".5"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
          </feMerge>
        </filter>
        <linearGradient id="a" x1="83.848%" y1="17.27%" y2="29.467%">
          <stop offset="0%" stop-color="#0052B2"></stop>
          <stop offset="100%" stop-color="#0052B2"></stop>
        </linearGradient>
        <linearGradient
          id="f"
          x1="104.505%"
          x2="5.953%"
          y1="2.109%"
          y2="2.109%"
        >
          <stop offset="0%" stop-color="#004CA9"></stop>
          <stop offset="100%" stop-color="#003D88"></stop>
        </linearGradient>
        <linearGradient id="j" x1="3.848%" x2="103.648%" y1="0%" y2="100%">
          <stop offset="3.008%" stop-color="#004DA6"></stop>
          <stop offset="100%" stop-color="#005ACB"></stop>
        </linearGradient>
        <linearGradient id="n" x1="6.85%" x2="103.648%" y1="3.008%" y2="100%">
          <stop offset="0%" stop-color="#05448D"></stop>
          <stop offset="97.561%" stop-color="#004BB7"></stop>
        </linearGradient>
        <linearGradient
          id="r"
          x1="11.155%"
          x2="100.481%"
          y1="7.321%"
          y2="96.827%"
        >
          <stop offset="0%" stop-color="#012557"></stop>
          <stop offset="100%" stop-color="#0040A7"></stop>
        </linearGradient>
        <linearGradient id="v" x1="103.648%" x2="4.908%" y1="100%" y2="1.061%">
          <stop offset="0%" stop-color="#009CE9"></stop>
          <stop offset="100%" stop-color="#007FC4"></stop>
        </linearGradient>
        <linearGradient id="z" x1="3.848%" x2="103.648%" y1="0%" y2="100%">
          <stop offset="0%" stop-color="#0057C8"></stop>
          <stop offset="100%" stop-color="#0071E8"></stop>
        </linearGradient>
        <linearGradient id="D" x1="3.848%" x2="103.648%" y1="0%" y2="100%">
          <stop offset="0%" stop-color="#004CB7"></stop>
          <stop offset="100%" stop-color="#0062D1"></stop>
        </linearGradient>
        <linearGradient id="H" x1="103.648%" x2="3.848%" y1="100%" y2="0%">
          <stop offset="0%" stop-color="#00DFFF"></stop>
          <stop offset="100%" stop-color="#00B3E8"></stop>
        </linearGradient>
        <linearGradient id="L" x1="103.648%" x2="3.848%" y1="100%" y2="0%">
          <stop offset="0%" stop-color="#00C2FF"></stop>
          <stop offset="100%" stop-color="#00A1ED"></stop>
        </linearGradient>
        <linearGradient id="P" x1="3.848%" x2="103.648%" y1="0%" y2="100%">
          <stop offset="0%" stop-color="#0061CC"></stop>
          <stop offset="100%" stop-color="#0173EF"></stop>
        </linearGradient>
        <linearGradient
          id="T"
          x1="96.375%"
          x2="-2.72%"
          y1="87.174%"
          y2="39.981%"
        >
          <stop offset="0%" stop-color="#00E2FF"></stop>
          <stop offset="100%" stop-color="#00B9FF"></stop>
          <stop offset="100%" stop-color="#00E3FF"></stop>
        </linearGradient>
        <linearGradient id="X" x1="12.047%" y1="39.087%" y2="100%">
          <stop offset="0%" stop-color="#009CE6"></stop>
          <stop offset="100%" stop-color="#00F3FF"></stop>
        </linearGradient>
        <linearGradient
          id="af"
          x1="2.151%"
          x2="113.177%"
          y1="9.713%"
          y2="104.673%"
        >
          <stop offset="0%" stop-color="#00438D"></stop>
          <stop offset="96.954%" stop-color="#0071D5"></stop>
        </linearGradient>
        <linearGradient id="an" x1="29.468%" x2="97.963%" y1="50%" y2="50%">
          <stop offset="0%" stop-color="#F0F0F0"></stop>
          <stop offset="100%" stop-color="#FFF"></stop>
        </linearGradient>
        <pattern
          id="c"
          width="512"
          height="512"
          x="-511"
          y="-454"
          patternUnits="userSpaceOnUse"
        >
          <use href="#a"></use>
        </pattern>
        <pattern
          id="g"
          width="512"
          height="512"
          x="-512"
          y="-512"
          patternUnits="userSpaceOnUse"
        >
          <use href="#b"></use>
        </pattern>
        <pattern
          id="l"
          width="512"
          height="512"
          x="-512"
          y="-499"
          patternUnits="userSpaceOnUse"
        >
          <use href="#c"></use>
        </pattern>
        <pattern
          id="p"
          width="512"
          height="512"
          x="-512"
          y="-462"
          patternUnits="userSpaceOnUse"
        >
          <use href="#d"></use>
        </pattern>
        <pattern
          id="t"
          width="512"
          height="512"
          x="-512"
          y="-425"
          patternUnits="userSpaceOnUse"
        >
          <use href="#e"></use>
        </pattern>
        <pattern
          id="x"
          width="512"
          height="512"
          x="-470"
          y="-499"
          patternUnits="userSpaceOnUse"
        >
          <use href="#f"></use>
        </pattern>
        <pattern
          id="B"
          width="512"
          height="512"
          x="-470"
          y="-462"
          patternUnits="userSpaceOnUse"
        >
          <use href="#g"></use>
        </pattern>
        <pattern
          id="F"
          width="512"
          height="512"
          x="-470"
          y="-425"
          patternUnits="userSpaceOnUse"
        >
          <use href="#h"></use>
        </pattern>
        <pattern
          id="J"
          width="512"
          height="512"
          x="-428"
          y="-499"
          patternUnits="userSpaceOnUse"
        >
          <use href="#i"></use>
        </pattern>
        <pattern
          id="N"
          width="512"
          height="512"
          x="-428"
          y="-462"
          patternUnits="userSpaceOnUse"
        >
          <use href="#j"></use>
        </pattern>
        <pattern
          id="R"
          width="512"
          height="512"
          x="-428"
          y="-425"
          patternUnits="userSpaceOnUse"
        >
          <use href="#k"></use>
        </pattern>
        <pattern
          id="V"
          width="512"
          height="512"
          x="-443.923"
          y="-512"
          patternUnits="userSpaceOnUse"
        >
          <use href="#l"></use>
        </pattern>
        <pattern
          id="Z"
          width="512"
          height="512"
          x="-511.2"
          y="-512"
          patternUnits="userSpaceOnUse"
        >
          <use href="#m"></use>
        </pattern>
        <pattern
          id="ah"
          width="512"
          height="512"
          x="-512"
          y="-512"
          patternUnits="userSpaceOnUse"
        >
          <use href="#n"></use>
        </pattern>
        <rect id="e" width="126" height="165" rx="8"></rect>
        <rect id="k" width="42" height="37" y="13"></rect>
        <rect id="o" width="42" height="37" y="50"></rect>
        <rect id="s" width="42" height="37" y="87"></rect>
        <rect id="w" width="42" height="37" x="42" y="13"></rect>
        <rect id="A" width="42" height="37" x="42" y="50"></rect>
        <rect id="E" width="42" height="37" x="42" y="87"></rect>
        <rect id="I" width="42" height="37" x="84" y="13"></rect>
        <rect id="M" width="42" height="37" x="84" y="50"></rect>
        <rect id="Q" width="42" height="37" x="84" y="87"></rect>
        <rect id="ae" width="96" height="96" rx="8"></rect>
        <rect id="ak" width="96" height="98" y="-1" rx="8"></rect>
        <path
          id="b"
          d="M6.83089478,76.3574944 L72,58 L137.169105,76.3574944 C140.617783,77.3289531 143,80.4749263 143,84.057818 L143,153.45459 L1,153.45459 L1,84.057818 C1,80.4749263 3.38221667,77.3289531 6.83089478,76.3574944 Z"
        ></path>
        <path
          id="U"
          d="M139.001618,79.7135614 L68.0768433,38.8391724 C70.5536209,40.2677574 73.6257262,40.1283034 76.100745,38.6966732 L143,-1.42108547e-14 L143,72.9044369 C143,77.3227149 139.071808,79.675766 139.001618,79.7135614 Z"
        ></path>
        <path
          id="Y"
          d="M0.8,-1.687539e-14 L139.001618,79.7135614 C137.780536,80.4686426 136.34116,80.9044369 134.8,80.9044369 L8.8,80.9044369 C4.381722,80.9044369 0.8,77.3227149 0.8,72.9044369 L0.8,-1.42108547e-14 Z"
        ></path>
        <path
          id="ab"
          d="M8,75.7464789 L8,8 C8,3.581722 11.581722,0 16,0 L126,0 C130.418278,0 134,3.581722 134,8 L134,75.7464789 L136.169105,76.3574944 C139.617783,77.3289531 142,80.4749263 142,84.057818 L142,157 C142,161.418278 138.418278,165 134,165 L8,165 C3.581722,165 8.42798054e-14,161.418278 8.52651283e-14,157 L8.52651283e-14,84.057818 C8.52651283e-14,80.4749263 2.38221667,77.3289531 5.83089478,76.3574944 L8,75.7464789 Z"
        ></path>
        <path
          id="am"
          d="M47.7890625,73.6884766 C40.7707982,73.6884766 35.0514544,71.404157 30.6308594,66.8354492 C26.2102644,62.2667415 24,56.3138388 24,48.9765625 C24,41.2291279 26.2444437,34.9629146 30.7333984,30.1777344 C35.2223532,25.3925542 41.1695593,23 48.5751953,23 C55.570673,23 61.2273547,25.2900162 65.5454102,29.8701172 C69.8634656,34.4502182 72.0224609,40.4885693 72.0224609,47.9853516 C72.0224609,55.687213 69.7837138,61.8964608 65.3061523,66.6132812 C60.8285909,71.3301017 54.9896193,73.6884766 47.7890625,73.6884766 Z M48.2882678,31.5019531 C44.1019053,31.5019531 40.7774908,33.0463671 38.3149246,36.1352413 C35.8523584,39.2241154 34.6210938,43.31227 34.6210938,48.3998275 C34.6210938,53.5600644 35.8523584,57.6421624 38.3149246,60.6462439 C40.7774908,63.6503255 44.0034041,65.1523438 47.9927613,65.1523438 C52.1052469,65.1523438 55.3680981,63.6927212 57.781413,60.7734322 C60.1947279,57.8541433 61.4013672,53.8023279 61.4013672,48.6178646 C61.4013672,43.215363 60.2316658,39.0121345 57.8922279,36.0080529 C55.55279,33.0039714 52.351502,31.5019531 48.2882678,31.5019531 Z"
        ></path>
        <radialGradient
          id="ag"
          cx="86.601%"
          cy="84.21%"
          r="62.398%"
          fx="86.601%"
          fy="84.21%"
        >
          <stop offset="0%" stop-color="#004F9D"></stop>
          <stop offset="100%" stop-color="#004F9D" stop-opacity="0"></stop>
        </radialGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(47)">
          <use fill="url(#a)" href="#b"></use>
          <use fill="url(#c)" fill-opacity=".012" href="#b"></use>
          <use fill="#000" filter="url(#d)" href="#b"></use>
          <g transform="translate(9)">
            <mask id="i" fill="#fff">
              <use href="#e"></use>
            </mask>
            <use fill="url(#f)" href="#e"></use>
            <use fill="url(#g)" fill-opacity=".012" href="#e"></use>
            <use fill="#000" filter="url(#h)" href="#e"></use>
            <g mask="url(#i)">
              <use fill="url(#j)" href="#k"></use>
              <use fill="url(#l)" fill-opacity=".012" href="#k"></use>
              <use fill="#000" filter="url(#m)" href="#k"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#n)" href="#o"></use>
              <use fill="url(#p)" fill-opacity=".012" href="#o"></use>
              <use fill="#000" filter="url(#q)" href="#o"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#r)" href="#s"></use>
              <use fill="url(#t)" fill-opacity=".012" href="#s"></use>
              <use fill="#000" filter="url(#u)" href="#s"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#v)" href="#w"></use>
              <use fill="url(#x)" fill-opacity=".012" href="#w"></use>
              <use fill="#000" filter="url(#y)" href="#w"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#z)" href="#A"></use>
              <use fill="url(#B)" fill-opacity=".012" href="#A"></use>
              <use fill="#000" filter="url(#C)" href="#A"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#D)" href="#E"></use>
              <use fill="url(#F)" fill-opacity=".012" href="#E"></use>
              <use fill="#000" filter="url(#G)" href="#E"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#H)" href="#I"></use>
              <use fill="url(#J)" fill-opacity=".012" href="#I"></use>
              <use fill="#000" filter="url(#K)" href="#I"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#L)" href="#M"></use>
              <use fill="url(#N)" fill-opacity=".012" href="#M"></use>
              <use fill="#000" filter="url(#O)" href="#M"></use>
            </g>
            <g mask="url(#i)">
              <use fill="url(#P)" href="#Q"></use>
              <use fill="url(#R)" fill-opacity=".012" href="#Q"></use>
              <use fill="#000" filter="url(#S)" href="#Q"></use>
            </g>
          </g>
          <g transform="translate(0 84)">
            <use fill="url(#T)" href="#U"></use>
            <use fill="url(#V)" fill-opacity=".012" href="#U"></use>
            <use fill="#000" filter="url(#W)" href="#U"></use>
            <g>
              <use fill="url(#X)" href="#Y"></use>
              <use fill="url(#Z)" fill-opacity=".012" href="#Y"></use>
              <use fill="#000" filter="url(#aa)" href="#Y"></use>
            </g>
          </g>
        </g>
        <g transform="translate(48)">
          <mask id="ad" fill="#fff">
            <use href="#ab"></use>
          </mask>
          <path
            fill="#000"
            fill-opacity=".05"
            d="M-40,33 L40,33 C44.418278,33 48,36.581722 48,41 L48,121 C48,125.418278 34.418278,139 30,139 L-50,139 C-54.418278,139 -58,135.418278 -58,131 L-58,51 C-58,46.581722 -44.418278,33 -40,33 Z"
            filter="url(#ac)"
            mask="url(#ad)"
          ></path>
        </g>
        <g transform="translate(0 34)">
          <mask id="ai" fill="#fff">
            <use href="#ae"></use>
          </mask>
          <use fill="url(#af)" href="#ae"></use>
          <use fill="url(#ag)" href="#ae"></use>
          <use fill="url(#ah)" fill-opacity=".013" href="#ae"></use>
          <g fill="#000" mask="url(#ai)">
            <use filter="url(#aj)" href="#ak"></use>
          </g>
          <g mask="url(#ai)">
            <use fill="#000" filter="url(#al)" href="#am"></use>
            <use fill="url(#an)" href="#am"></use>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OutlookIcon;
