import styled from 'styled-components';

export const ChartSectionContainer = styled.div`
  min-height: fit-content;
  width: 100%;
  background-color: #fff;
`;

export const ChartHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Name = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 19px;
  margin-right: 5px;
`;

export const ChartSubheading = styled.p`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const ChartContainer = styled.div`
  margin-top: 8px;
  width: 100%;
  height: 180px;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const CalendarContainer = styled.div``;
