import styled from 'styled-components';

type WeekProps = {
  color?: string;
};

export const WeekNavigatorContainer = styled.div`
  width: 160px;
  height: 33px;
  border-radius: 6px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Week = styled.div<WeekProps>`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.color};
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.colors.primary};
`;
