import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useProductivityPage } from '../../api';
import ProductivityPage from './devd-productivity';

export const Productivity = () => {
  const { data } = useProductivityPage();

  return (
    <>
      <MetaTags title="Productivity | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <ProductivityPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Productivity;
