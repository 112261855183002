export const chartTypeOptions1 = [
  { label: 'Line Chart', value: 'LINE_CHART' },
  { label: 'Bar Chart', value: 'BAR_CHART' },
];

export const chartTypeOptions2 = [
  { label: 'Stacked Bar Chart', value: 'STACKED_BAR_AND_LINE_CHART' },
];

export const operatorOptions = [{ value: 'EQUAL', label: 'Equal' }];

export const valueOptions = [
  { value: 'completed', label: 'Completed' },
  { value: 'failed', label: 'Failed' },
];

export const typeOptions = [{ value: 'string', label: 'String' }];
