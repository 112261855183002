import styled from 'styled-components';

type TableRightItemProps = {
  color?: string;
};

type TableLeftHeadingProps = {
  color?: string;
};

type ChartContainerProps = {
  bgColor?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 4px;
`;
export const TableLeft = styled.div`
  min-width: 180px;
  width: 15%;
  display: flex;
  justify-content: space-between;
`;

export const TableMiddle = styled.div`
  min-width: 80px;
  display: flex;
  justify-content: space-between;
`;

export const TableLeftHeading = styled.p<TableLeftHeadingProps>`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const TableRight = styled.div`
  width: 85%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(14, 1fr);
`;

export const TableRightItem = styled.p<TableRightItemProps>`
  font-size: 10px;
  font-family: 'Inter';
  font-weight: 400;
  color: ${({ color }) => color || '#333'};
`;

export const Date = styled.span`
  font-size: 14px;
  margin-right: 4px;
`;

export const TableBody = styled.div`
  width: 100%;
  min-height: 72px;
  display: flex;
`;

export const TableBodyLeft = styled.div`
  min-width: 180px;
  width: 15%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
`;

export const TableBodyMiddle = styled.div`
  min-width: 80px;
  padding: 6px;
  display: grid;
  place-items: center;
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
`;

export const TableBodyRight = styled.div`
  width: 85%;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
`;

export const ChartContainer = styled.div<ChartContainerProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
  background-color: ${({ bgColor }) => bgColor && '#F7FAFC'};
`;

export const Divider = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;
