import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface TeamDetailsState {
  memberCount: number;
  setMemberCount: (membercount: number) => void;
  apiTrigger: boolean;
  triggerApiCall: () => void;
}

export const useTeamDetailsStore = create<TeamDetailsState>()(
  persist(
    (set) => ({
      memberCount: 0,
      setMemberCount: (memberCount: number) => set({ memberCount }),

      apiTrigger: false,
      triggerApiCall: () => set((state) => ({ apiTrigger: !state.apiTrigger })),
    }),
    {
      name: 'team-details-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
