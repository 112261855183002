import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  investmentCategory: 'investmentCategory',
};

export const useInvestmentCategories = () => {
  return useQuery(
    [QUERY_KEYS.investmentCategory],
    ENDPOINTS.getInvestmentCategories
  );
};

export const useAddUpdateInvestmentMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.investmentCategory],
    ENDPOINTS.addUpdateInvestmentCategories
  );
};

export const useDeleteInvestmentMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.investmentCategory],
    ENDPOINTS.deleteInvestmentCategories
  );
};
